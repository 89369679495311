import Op from 'quill-delta/dist/Op';
import { StagingAuthorData } from './author.types';
import {
  BprRecord,
  RelatedDocRecord,
  Tag,
} from './full-document-revision-object.types';
import { PendingCriterionData } from './criterions.types';

export enum CplusDocumentType {
  EvidenceSummary = 'EvidenceSummary',
  RecommendedPractice = 'RecommendedPractice',
  BestPracticeInformationSheet = 'BestPracticeInformationSheet',
  SystematicReview = 'SystematicReview',
  SystematicReviewProtocol = 'SystematicReviewProtocol',
}

export enum CplusDocumentAction {
  Draft = 'Draft',
  Publish = 'Publish',
  Archive = 'Archive',
  UnArchive = 'Unarchive',
  Withdraw = 'Withdraw',
  Restore = 'Restore',
}

export enum CplusDocumentTypeShortHand {
  EvidenceSummary = 'ES',
  RecommendedPractice = 'RP',
  BestPracticeInformationSheet = 'BPIS',
  SystematicReview = 'SR',
  SystematicReviewProtocol = 'SRP',
}

export enum CplusDocumentTypeDisplayName {
  EvidenceSummary = 'Evidence Summary',
  RecommendedPractice = 'Evidence Based Recommended Practice',
  BestPracticeInformationSheet = 'Best Practice Information Sheet',
  SystematicReview = 'Systematic Review',
  SystematicReviewProtocol = 'Systematic Review Protocol',
}

export enum CplusDocumentTypeShorterDisplayName {
  EvidenceSummary = 'Evidence Summary',
  RecommendedPractice = 'Recommended Practice',
  BestPracticeInformationSheet = 'Information Sheet',
  SystematicReview = 'Systematic Review',
  SystematicReviewProtocol = 'Systematic Review Protocol',
}

export enum CRUD_PERMISSIONS {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}

// TODO: Rename all viewer key and collaborator key after demo
export enum ParticipantRoles {
  owner = 'Owner',
  reviewer = 'Reviewer',
  author = 'Author',
}

export interface Keyword {
  id: number;
  keyword: string;
}

export interface Mesh {
  id: number;
  path: string;
  keyword: string;
}

export enum TagEntityTypeEnum {
  document = 'Document',
  section = 'Section',
  bpr_section = 'Best Practice Recommendation',
  criterion = 'Criterion',
}

export interface TagMap {
  id: number;
  entityType: TagEntityTypeEnum;
  revisionId: number;
  entityId?: number;
  tagType: TagType;
  keyword?: Keyword;
  keywordId?: number;
  mesh?: Mesh;
  meshId?: number;
  isEntityIdTemp?: boolean;
}

export enum TagType {
  Keyword = 'Keyword',
  MeSH = 'MeSH',
}

export enum SectionType {
  TEXT = 'text',
  REFERENCE = 'reference',
  OHS_SECTION = 'ohs-section',
  RELATED_DOC_SECTION = 'related-doc-section',
  RELATED_RP_DOC_SECTION = 'related-rp-doc-section',
  AUTHOR_SECTION = 'author-section',
  BPR_LIST_SECTION = 'bpr-list-section',
  CRITERION_LIST_SECTION = 'criterion-list-section',
}

export enum TextSectionDefaultTitle {
  Question = 'Question',
  ClinicBottomLine = 'Clinical Bottom Line',
  CharacteristicsEvidence = 'Characteristics of the Evidence',
  Equipment = 'Equipment',
  RecommendedPractice = 'Recommended Practice',
}

export interface PendingDocument {
  id: number;
  content: PendingDocumentContent;
  isValid: boolean;
  isTrackChangesOn: boolean;
  documentId?: null | number;
  projectId: number;
  isPublished: boolean;
  type: CplusDocumentType;
  createdAt: Date;
  uploadedAt: Date;
}

export interface PendingDocumentContent {
  documentTitle?: string;
  searchDate?: string;
  authorSection?: StagingAuthorData[];
  textSections?: PendingTextSectionData[];
  bprSection?: PendingBprSectionData[];
  criterionSection?: PendingCriterionData[];
  referenceSection?: PendingReferenceSectionData;
  relatedDocSection?: RelatedDocRecord[];
  ohsSection?: any[];
  citations?: PendingCitationData[];
  activeCitations?: PendingCitationData[];
  archivedCitations?: PendingCitationData[];
  tags?: Tag[];
  tdrUri?: string;
}

export interface PendingBprSectionData extends BprRecord {
  isValid?: boolean;
}

export interface PendingReferenceSectionData {
  documentSectionId?: number;
  citationIds: string[];
  notices?: PendingDocumentNotice[];
  errors?: PendingDocumentError[];
}

export interface PendingCitationData {
  id: any;
  content: { [s: string]: string | any };
}

export interface PendingTextSectionData {
  isValid?: boolean;
  errors?: PendingDocumentError[];
  notices?: PendingDocumentNotice[];
  content: PendingDocumentRawTextData;
}

export interface PendingDocumentError {
  code: string;
  message: string;
  sectionId?: string | number;
}

export interface PendingDocumentNotice {
  code?: string;
  message: string;
  sectionId?: string | number;
}

export interface PendingDocumentRawTextData {
  tempId?: string;
  documentSectionId?: number;
  sectionTitle: Op[];
  sectionValue: Op[];
}

export interface PendingDocumentCitationReference {
  citationId: string;
  label: string;
}
export interface PendingDocumentReferences {
  citationIds: string[];
  errors?: PendingDocumentError[];
  notices?: PendingDocumentNotice[];
}

export interface PendingDocumentReferenceUpdate {
  mode: 'replace' | 'merge';
  label: string;
  previousCitationId: string;
  newCitationId: string;
}

export enum PendingSectionError {
  SECTION_VALUE_EMPTY = 'section-value-empty',
  SECTION_MISSING_INFO = 'section-missing-info',
  SECTION_INVALID_DATE = 'section-invalid-date',
  AUDIT_CRITERIA_INCOMPLETE = 'audit-criteria-incomplete',
  AUDIT_CRITERIA_MISSING_LINKED_BPR = 'audit-criteria-missing-linked-bpr',
}

export enum PendingSectionNotice {
  SECTION_TITLE_NOT_MATCH = 'section-title-not-match',
  NEW_BPR_FOUND = 'new-bpr-found',
  NEW_CRITERIA_FOUND = 'new-criteria-found',
  UNCITED_REFERENCE_REMOVED = 'uncited-reference-removed',
}
