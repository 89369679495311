















import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { OhsAsset } from '../../../../store/modules/documents/types/ohs.types';

@Component({})
export default class OhsDisplaySection extends mixins(ViewModeMixin) {
  @Prop()
  public ohsAssets!: OhsAsset[];
  @Prop()
  public dirtyOhs!: number[];

  get selectedOhsAssets() {
    if (this.ohsAssets) {
      return this.ohsAssets.filter((value) => {
        if (value.id) {
          return this.dirtyOhs.includes(value.id);
        }
      });
    }
  }
}
