





















import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { isEqual as _isEqual } from 'lodash';
import { InputOption } from '@/jbi-shared/types/form.types';

interface ResearchNodeInputOption extends InputOption {
  isDeleted: boolean;
}

@Component({
  components: {
    Multiselect
  }
})
export default class ActiveMultiSelect extends Vue {
  @Prop({ required: true })
  public value!: ResearchNodeInputOption | ResearchNodeInputOption[];
  @Prop({ type: Array, required: true })
  public options!: ResearchNodeInputOption[];
  @Prop({ type: String, default: '' })
  public placeholder!: string;
  @Prop({ type: Boolean, default: false })
  public disabled!: string;
  @Prop(String) public openDirection!: string;
  @Prop(Number) public maxHeight!: number;

  // if the selected value prop is represented as an array, this component allows selection of multiple nodes
  get isMultiSelect() {
    return Array.isArray(this.selectedValue);
  }

  // if the selected value prop is not an array, then this component only allows one node to be selected
  get isSingleSelect() {
    return !this.isMultiSelect;
  }

  get selectedValue(): ResearchNodeInputOption | ResearchNodeInputOption[] {
    return this.value;
  }

  // used to filter out inactive values from this component's dropdown, only allowing active nodes to be added to the selection
  get activeValues(): ResearchNodeInputOption | ResearchNodeInputOption[] {
    return this.options.filter((value) => !value.isDeleted);
  }

  set selectedValue(
    values: ResearchNodeInputOption | ResearchNodeInputOption[]
  ) {
    if (this.isSingleSelect && !values) {
      return;
    }
    this.$emit('input', values);
  }
}
