
















import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import PendingDocCreationHistoryList from './CreationHistoryList.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { PendingDocumentUploadHistoryResponsePayload } from '@/store/modules/admin/types/admin.types';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedPendingDocCreationHistoryList extends mixins(
  PaginationMixin
) {
  @Prop() public items!: PendingDocumentUploadHistoryResponsePayload[];
  @Prop() public totalCount!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  get PendingDocCreationHistoryList() {
    return PendingDocCreationHistoryList;
  }
}
