




import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import UploadDocumentForm from '@/views/AdminDashboard/components/UploadDocumentForm.vue';

@Component({
  components: {
    UploadDocumentForm,
    BaseModal,
    ValidationObserver
  }
})
export default class UploadHistoryButton extends Vue {
  public goToUploadHistoryPage() {
    this.$router.push({
      name: 'admin-upload-history'
    });
  }
}
