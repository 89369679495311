import {
  DocumentDetail,
  ExportDocumentRequestPayload,
  ExportWkResourceRequestPayload,
  GetDocumentByPublicationIdRequestPayload,
  GetDocumentByRevisionIdRequestPayload,
  GetDocumentByVersionIdRequestPayload,
  GetDocumentParticipantsRequestPayload,
  GetMyAssignedDocumentsRequestPayload,
  GetRevisionCitationsRequestPayload,
  GetUserDocumentsRequestPayload,
  InviteDocumentParticipantsRequestPayload,
  LegacySearchFilters,
  RemoveRevisionCitationRequestPayload,
  RestoreRevisionPayload,
  SearchDocumentPayload,
  SearchEsForRpRealignmentPayload,
  SearchKeywordPayload,
  UpdateBPISRequestPayload,
  UpdateDocAssigneeRequestPayload,
  UpdateEvidenceSummaryRequestPayload,
  UpdateRecommendedPracticeRequestPayload,
  UpdateRevisionNameRequestPayload,
  UpdateSRRequestPayload
} from '@/store/modules/documents/types/documents.types';
import { PublishDocumentPayload } from '@/store/modules/projects/types/projects.types';
import { SearchCriterionPrimitive } from '@/store/types/search.types';
import dayjs from 'dayjs';
import { timeoutDuration } from '@/utils/timeout-duration.types';
import { axios } from './axios';
import { FetchWkExportResourceDetailRequestPayload } from '@/jbi-shared/types/wk-export.types';
import { MeshDto } from '@/jbi-shared/util/mesh.util';
import { AxiosResponse } from 'axios';
interface SearchCriterionSingle {
  prop: string;
  condition: string;
  // could be tags or mesh tags
  keyword: string;
}

export const documentsApi = {
  async getDocumentDetail(id: number) {
    return axios.get(`documents/${id}`);
  },
  async getOhsAssets() {
    return axios.get(`ohs`);
  },
  async updateDocument(
    payload:
      | UpdateSRRequestPayload
      | UpdateBPISRequestPayload
      | UpdateEvidenceSummaryRequestPayload
      | UpdateRecommendedPracticeRequestPayload
  ) {
    return axios.put(
      `documents/${payload.document?.id}/revisions/${payload.revision?.id}`,
      {
        ...payload
      },
      // Adding a long timeout duration here in case updating document takes too long
      { timeout: timeoutDuration.LONG_TIMEOUT }
    );
  },
  async deleteDocument(payload: Partial<DocumentDetail>) {
    return axios.delete(`documents/${payload!.document!.id}`);
  },
  async exportSearchResult(payload: SearchDocumentPayload) {
    return axios.post(`search/export-result`, payload);
  },
  async exportDocument({
    documentId,
    revisionId,
    exportType,
    includeAuditCriteria
  }: ExportDocumentRequestPayload) {
    if (includeAuditCriteria) {
      return axios.post(
        `documents/${documentId}/revisions/${revisionId}/export?type=${exportType}&includeAuditCriteria=${includeAuditCriteria}`
      );
    } else {
      return axios.post(
        `documents/${documentId}/revisions/${revisionId}/export?type=${exportType}`
      );
    }
  },
  async exportLegacyDocument({
    baseDocumentId,
    exportType
  }: ExportDocumentRequestPayload) {
    return axios.post(
      `legacy-contents/${baseDocumentId}/export?type=${exportType}`
    );
  },
  async getDocumentByRevisionId({
    revisionId,
    documentId
  }: GetDocumentByRevisionIdRequestPayload) {
    return axios.get(`documents/${documentId}/revisions/${revisionId}`);
  },
  async getDocumentByVersionId({
    versionId,
    documentId
  }: GetDocumentByVersionIdRequestPayload) {
    return axios.get(`documents/${documentId}/versions/${versionId}`);
  },
  async updateRevisionName(payload: UpdateRevisionNameRequestPayload) {
    return axios.put(
      `documents/${payload.documentId}/revisions/${payload.revisionId}/revision_name`,
      {
        name: payload.name
      }
    );
  },
  async removeRevisionCitation(payload: RemoveRevisionCitationRequestPayload) {
    return axios.put(
      `documents/${payload.documentId}/revisions/${payload.revisionId}/citations`,
      {
        citationId: payload.citationId
      }
    );
  },
  async getRevisionCitations(payload: GetRevisionCitationsRequestPayload) {
    return axios.get(
      `documents/${payload.documentId}/revisions/${payload.revisionId}/citations`
    );
  },
  async getParticipantRoles() {
    return axios.get('documents/roles');
  },

  async inviteDocumentUserAsParticipant(
    payload: InviteDocumentParticipantsRequestPayload
  ) {
    const { documentId, ...trimmedPayload } = payload;
    return axios.post(`/documents/${documentId}/participants`, {
      ...trimmedPayload
    });
  },

  async updateDocumentParticipant(
    payload: InviteDocumentParticipantsRequestPayload
  ) {
    const { documentId, ...trimmedPayload } = payload;
    return axios.put(`/documents/${documentId}/participants`, {
      ...trimmedPayload
    });
  },

  async deleteDocumentParticipant(
    payload: InviteDocumentParticipantsRequestPayload
  ) {
    const { documentId, ...trimmedPayload } = payload;
    return axios.delete(`/documents/${documentId}/participants`, {
      data: { ...trimmedPayload }
    });
  },

  async getDocumentByPublicationId({
    publicationId,
    documentId
  }: GetDocumentByPublicationIdRequestPayload) {
    return axios.get(`documents/${documentId}/publications/${publicationId}`);
  },
  async restoreRevision(params: RestoreRevisionPayload) {
    const { documentId, revisionId } = params;
    return axios.post(
      `/documents/${documentId}/revisions/${revisionId}/restores`
    );
  },
  async getRevisionsByDocumentId(documentId: number) {
    return axios.get(`documents/${documentId}/revisions`);
  },
  async getAllLegacyDocVersionsByBaseDocumentId(baseDocumentId: number) {
    return axios.get(`legacy-contents/${baseDocumentId}/revisions`);
  },
  async getLegacyContentByBaseDocumentId(baseDocumentId: number) {
    return axios.get(`legacy-contents/${baseDocumentId}`);
  },

  async searchDocument(payload: SearchDocumentPayload) {
    return axios.post(`search/search-result`, payload);
  },

  createCriteria(tokens: string[]) {
    const criteria: SearchCriterionPrimitive[] = [];
    const template: SearchCriterionPrimitive = {
      condition: 'AND',
      props: ['All fields'],
      keyword: ''
    };
    tokens.forEach((token) => {
      template.keyword = token;
      template.condition = 'AND';
      criteria.push({ ...template });
    });
    return [...criteria];
  },

  matchConstructor(criterionTags: string[]) {
    const terms: any[] = [];
    criterionTags.forEach((tag) => {
      terms.push({ match: { title: tag } });
    });
    return terms;
  },

  criterionPropResolver(criterion: SearchCriterionSingle) {
    const criterionTag: string = criterion.keyword.toLowerCase();

    switch (criterion.prop) {
      case 'Title':
        return { match: { title: criterionTag } };
      case 'All fields':
        return {
          multi_match: {
            query: [criterion.keyword],
            fields: [
              'uniquePublicationId',
              'body',
              'title',
              'documentOwner',
              'collaborators'
            ]
          }
        };
      case 'Full text':
        return { match: { body: criterionTag } };
      case 'Keywords':
        return { wildcard: { plainTags: '*' + criterionTag + '*' } };
      case 'MeSH':
        return { wildcard: { tags: '*' + criterionTag + '*' } };
      default:
        break;
    }
  },
  async searchRelatedDocuments(payload: SearchDocumentPayload) {
    return axios.post(`search/search-result`, payload);
  },
  async searchNewRelatedDocuments(payload: SearchDocumentPayload) {
    return axios.post(`search/search-related-document`, payload);
  },
  async searchEsForRpRealignment(payload: SearchEsForRpRealignmentPayload) {
    const { text } = payload;
    const requestPayload = {
      text: String(text),
      legacyFilter: 'All Documents',
      documentTypes: ['EvidenceSummary'],
      isAdvance: false,
      publicationStatusFilter: 'Published',
      page: 1,
      perPage: 1000
    };
    return axios.post(`search/search-result`, requestPayload);
  },
  async updateDocumentPublishStatus(payload: PublishDocumentPayload) {
    const url = `documents/${payload.documentId}/publication-status`;
    const data = {
      status: payload.publishStatus,
      revisionId: payload.revisionId,
      archivedNote: payload.archivedNote,
      withdrawnNote: payload.withdrawnNote
    };
    // Adding a long timeout duration here in case publishing document takes too long
    return axios.put(url, data, { timeout: timeoutDuration.LONG_TIMEOUT });
  },

  async exportWkResource(payload: ExportWkResourceRequestPayload) {
    if (payload.documentId) {
      return axios.post(`documents/resource-export/document-id`, payload);
    } else {
      return axios.post(`documents/resource-export/date-range`, payload);
    }
  },
  async getWkExportStatus(wkExportId: number) {
    return axios.get(`documents/resource-export/status/${wkExportId}`);
  },
  async getWkExportdetails(payload: FetchWkExportResourceDetailRequestPayload) {
    return axios.get(
      `documents/resource-export/export-details/${payload.wkExportId}?page=${payload.page}&perPage=${payload.perPage}&documentId=${payload.documentId}&projectName=${payload.projectName}&status=
      ${payload.status}`
    );
  },
  async getAllWkExports(params: { page: number; perPage: number }) {
    return axios.get(`documents/resource-export`, {
      params
    });
  },

  async updateDocAssignee(payload: UpdateDocAssigneeRequestPayload) {
    return axios.put(`documents/${payload.documentId}/assignee`, {
      assigneeId: payload.assigneeId
    });
  },
  async searchKeywordTag(payload: SearchKeywordPayload) {
    const { text, limit = 50 } = payload;
    const size = limit;
    const source: any = text
      ? {
          size,
          query: {
            bool: {
              must: { wildcard: { keyword: `*${text}*` } }
            }
          }
        }
      : {
          size,
          query: {
            match_all: {}
          }
        };
    return axios.get(`/keyword/_search`, {
      params: {
        source: JSON.stringify(source),
        source_content_type: 'application/json'
      }
    });
  },
  async getMeshTree() {
    return axios.get(`tags/mesh-tree`);
  },
  async getRdfMeshTags(keyword: string) {
    return axios.post(`tags/rdf-mesh-tags`, { searchTerm: keyword });
  },
  async getDocumentParticipants(
    payload: GetDocumentParticipantsRequestPayload
  ) {
    return axios.get(`documents/${payload.documentId}/participants`);
  },
  async getMyAssignedDocuments(params: GetMyAssignedDocumentsRequestPayload) {
    return axios.get(`/documents/me/assigned`, { params });
  },
  async getUserDocuments(params?: GetUserDocumentsRequestPayload) {
    return axios.get(`/documents/me`, { params });
  },
  async getAlignedEsByEsId(esDocId: number) {
    const requestPayload = {
      text: String(esDocId),
      legacyFilter: 'All Documents',
      documentTypes: ['EvidenceSummary'],
      isAdvance: false,
      publicationStatusFilter: 'Published',
      page: 1,
      perPage: 10000
    };
    return axios.post(`search/search-result`, requestPayload);
  }
};
