























































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { useGetter } from '@/utils/store.util';
import {
  ProjectIndexingPayload,
  DocumentIndexingPayload
} from '@/jbi-shared/types/search.types';
import truncate from 'truncate';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin.ts';
import { DATE_FORMAT } from '../../../utils/date.util';
import relativeTime from 'dayjs/plugin/relativeTime';

@Component({})
export default class AssignedWorkItem extends mixins(ScreenSizeMixin) {
  @Prop() public document!: DocumentIndexingPayload;

  public today = new Date();

  get formatTime() {
    dayjs.extend(relativeTime);
    return dayjs().from(dayjs(this.document.updatedAt), true) + ' ' + 'ago';
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  get documentTitle(): string {
    if (!this.document) {
      return '';
    }
    return this.document.title;
  }

  get authors() {
    if (
      !this.document ||
      !this.document.authors ||
      !this.document.authors.length
    ) {
      return '';
    }
    return this.document.authors.join(', ');
  }
}
