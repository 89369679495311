
























import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class BaseNav extends Vue {
  @Prop({ type: String, required: true })
  public logoMain!: string;
  @Prop({ type: String, required: true })
  public logoApp!: string;
}
