













































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { RangeCriterionContent } from '@/jbi-shared/types/criterions.types';
import {
  isTruthy,
  isDifferentDeep
} from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class RangeCriterionInput extends Vue {
  @Prop({
    default() {
      return [1, 10];
    }
  })
  public range!: RangeCriterionContent['range'];

  public start = this.range[0];
  public end = this.range[1];

  @Watch('start')
  @Watch('end')
  public onChanged() {
    this.$emit('update:range', [this.start, this.end]);
  }

  @isTruthy
  @Watch('range')
  public onRangeUpdated() {
    this.start = this.range[0];
    this.end = this.range[1];
  }
}
