export enum EditorRole {
  AllPositions = 'All Positions',
  ResearchFellow = 'Research Fellow',
  ScientificWriter = 'Scientific Writer',
}

export interface EditorRoleDetails {
  id: number;
  name: string;
}

export interface EditorDetails {
  userId: string;
  email: string;
  name: string;
  roles: string[];
  assignedProjectCount: {
    overdue: number;
    inProgress: number;
  };
}

export interface EditorOptionDetails {
  userId: string;
  email: string;
  name: string;
  roles: string[];
  assignedProjectCount: {
    overdue: number;
    inProgress: number;
  };
}
