







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MeshDto } from '../../../jbi-shared/util/mesh.util';
import { useDocumentsState } from '../../../utils/store.util';
import { DirtyTagMap } from '../../../store/modules/documents/types/documents.types';
import { result } from 'lodash';

@Component({ components: {} })
export default class MeshSearcher extends Vue {
  @Prop() public meshSearch!: string;

  public mounted() {
    setTimeout(() => {
      result(this.$refs, 'input.$refs.input.focus');
    }, 200);
    setTimeout(() => {
      result(this.$refs, 'input.$refs.input.focus');
    }, 500);
  }
}
