import { axios } from './axios';
import {
  CreateProjectRequestPayload,
  UpdateProjectRequestPayload,
  CreateSubDocumentRequestPayload,
  AcceptInvitationRequestPayload,
  InviteParticipantsRequestPayload,
  GetMyProjectsRequestPayload,
  AddNoteToProjectRequestPayload,
  RemoveUserFromProjectPayload,
  CreateProjectFromLegacyDocumentRequestPayload,
  GetPreMigrationStatusRequestPayload,
  GetAllProjectsRequestPayload,
  PaginatedResearchNodesPayload
} from '@/store/modules/projects/types/projects.types';
import {
  CreateCitationRequestPayload,
  EditCitationRequestPayload,
  UploadCitationRequestPayload
} from '@/store/modules/documents/types/citations.types';
import { ImportResearchNodePayload } from '@/store/modules/documents/types/nodes.types';

export const projectsApi = {
  async getMyProjects(params?: GetMyProjectsRequestPayload) {
    return await axios.get(`/projects/me`, { params });
  },
  async getAllProjects(params?: GetAllProjectsRequestPayload) {
    return await axios.get(`/projects`, { params });
  },
  async getProjectCountByProjectStatus() {
    return await axios.get('/projects/count-by-project-status');
  },
  async addNote(payload: AddNoteToProjectRequestPayload) {
    return axios.post(`projects/${payload.projectId}/note`, payload);
  },
  async getNoteHistory(projectId: number) {
    return axios.get(`projects/${projectId}/note/history`);
  },
  async createProject(payload: CreateProjectRequestPayload) {
    return axios.post(`projects`, {
      ...payload
    });
  },
  async updateProject(payload: UpdateProjectRequestPayload) {
    const { projectId, ...trimmedPayload } = payload;
    return axios.put(`projects/${projectId}`, {
      ...trimmedPayload
    });
  },
  async getProject(projectId: number) {
    return axios.get(`/projects/${projectId}`);
  },
  async createSubDocument(payload: CreateSubDocumentRequestPayload) {
    const { projectId, ...trimmedPayload } = payload;
    return axios.post(`projects/${projectId}/documents`, {
      ...trimmedPayload
    });
  },
  async getAllResearchNodes() {
    return axios.get(`research-nodes/all`);
  },
  async getActiveResearchNodes() {
    return axios.get(`research-nodes/active`);
  },
  async getResearchNodes(params?: PaginatedResearchNodesPayload) {
    return axios.get(`research-nodes`, { params });
  },
  async validateResearchNodeByName(name: string) {
    return axios.get(`research-nodes/validate`, { params: { name } });
  },
  async getCitations(projectId: number) {
    return axios.get(`projects/${projectId}/citations`);
  },
  async deleteCitation(projectId: number, citationId: number) {
    return axios.delete(`projects/${projectId}/citations/${citationId}`);
  },
  async createCitation(payload: CreateCitationRequestPayload) {
    return axios.post(
      `projects/${payload.projectId}/${payload.revisionId}/citations`,
      {
        ...payload.citation
      }
    );
  },
  async editCitation(payload: EditCitationRequestPayload) {
    return axios.put(
      `projects/${payload.projectId}/citations/${payload.citation.id}`,
      {
        ...payload.citation
      }
    );
  },
  async uploadCitation(payload: UploadCitationRequestPayload) {
    const formData = new FormData();
    formData.append('file', payload.file);

    return axios.post(
      `projects/${payload.projectId}/revisions/${payload.revisionId}/citations/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  async getUploadCitationStatus(jobId: number) {
    return axios.get(`jobs/${jobId}`);
  },
  async importResearchNodes(payload: ImportResearchNodePayload) {
    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('email', payload.email);

    return axios.post(`import-research-nodes`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async acceptInvitation({ token }: AcceptInvitationRequestPayload) {
    return axios.put(`/projects/participants/accept`, {
      token
    });
  },
  async inviteUser(payload: InviteParticipantsRequestPayload) {
    const { projectId, ...trimmedPayload } = payload;
    return axios.post(`/projects/${projectId}/participants`, {
      ...trimmedPayload
    });
  },
  async resendUserInvitation(payload: InviteParticipantsRequestPayload) {
    const { projectId, ...trimmedPayload } = payload;
    return axios.post(`/projects/${projectId}/participants/resend`, {
      ...trimmedPayload
    });
  },
  async removeUserFromProject(payload: RemoveUserFromProjectPayload) {
    const { projectId, userId } = payload;
    return axios.delete(`/projects/participants/${userId}/`);
  },
  async createProjectFromLegacyDocument(
    payload: CreateProjectFromLegacyDocumentRequestPayload
  ) {
    return axios.post(
      `/projects/legacy-documents/${payload.documentType}/${payload.baseDocumentId}`,
      {
        ...payload
      }
    );
  },
  async getPreMigrationStatus(payload: GetPreMigrationStatusRequestPayload) {
    return axios.get(
      `/legacy-contents/${payload.baseDocumentId}/pre-migration-status`
    );
  }
};
