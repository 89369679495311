















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import CreateProjectForm, {
  CreateProjectFormValues
} from '@/components/form/CreateProjectForm.vue';
import { RootState } from '@/store/store';
import { ResearchNode } from '@/store/modules/documents/types/documents.types';
import { CreateProjectRequestPayload } from '@/store/modules/projects/types/projects.types';
import { ValidationObserver } from 'vee-validate';
import { CreateProjectResponsePayload } from '@/store/modules/projects/types/projects.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';

@Component({
  components: {
    BaseModal,
    CreateProjectForm,
    ValidationObserver
  }
})
export default class CreateProjectButton extends Vue {
  @Action('projects/createProject')
  public createProject!: (payload: CreateProjectRequestPayload) => void;

  @Action('projects/getActiveResearchNodes')
  public getResearchNodes!: () => void;

  @State((state: RootState) => state.projects.createdProject)
  public createdProject!: CreateProjectResponsePayload;

  @State((state: RootState) => state.projects.apiState.createProject.success)
  public createProjectSuccess!: boolean;

  @State((state: RootState) => state.projects.apiState.createProject.loading)
  public createProjectLoading!: boolean;

  @State((state: RootState) => state.projects.activeResearchNodes)
  public researchNodes!: ResearchNode[];

  public handleCreateProject(values: CreateProjectFormValues) {
    const {
      projectTitle,
      title,
      primaryResearchNode,
      researchNodes,
      storageUri,
      tdrStorageUri
    } = values;

    const documentType: CplusDocumentType = values?.documentType
      ?.id as CplusDocumentType;

    // Copying projectTitle into DocumentTitle based on the new design. TODO: confirm this approach.
    this.createProject({
      projectTitle,
      primaryResearchNodeId: primaryResearchNode!.id as number,
      researchNodeIds: researchNodes.map((node) => node.id as number),
      documentType,
      documentTitle: projectTitle,
      storageUri,
      tdrStorageUri
    });
  }

  public created() {
    if (!this.researchNodes) {
      this.getResearchNodes();
    }
  }

  @Watch('createProjectSuccess')
  @isDifferent
  @isTruthy
  public onCreateProjectSuccess() {
    const { documentId, projectId } = this.createdProject;

    this.$router.push({
      name: 'editor',
      params: {
        documentId: String(documentId),
        projectId: String(projectId)
      }
    });
  }
}
