






















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { useDocumentsState, useAction, useApiState } from '@/utils/store.util';
import {
  DirtyTagMap,
  SearchKeywordPayload,
  MeshTree
} from '@/store/modules/documents/types/documents.types';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { Action } from 'vuex-class';
import { getAllMeshDto, MeshDto } from '@/jbi-shared/util/mesh.util';
import {
  TagType,
  Keyword,
  TagEntityTypeEnum,
  TagMap
} from '../../../jbi-shared/types/document.types';
import {
  flatten,
  startCase,
  get as _get,
  result as _result,
  uniq
} from 'lodash';
import TextHighlight from 'vue-text-highlight';
import Taginput from 'buefy/src/components/taginput/Taginput.vue';
import TagTypeIndicator from '@/components/editor/SectionEditor/TagTypeIndicator.vue';
import MeshConceptModal from '@/components/editor/SectionEditor/MeshConceptModal.vue';
import MeshSearcher from '@/components/editor/SectionEditor/MeshSearcher.vue';
import { mixins } from 'vue-class-component';
import {
  uniqTagMap,
  removeTagMapFromArr
} from '../../../jbi-shared/util/document.utils';
import { parseJsonIfNecessary } from '@/utils/json.util';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';

enum TagFilter {
  all = 'all',
  plain = 'plain',
  mesh = 'mesh',
  pico = 'pico'
}

@Component({
  inject: [],
  components: {
    TextHighlight,
    Taginput,
    TagTypeIndicator,
    MeshConceptModal,
    MeshSearcher
  }
})
export default class TagsEditor extends Vue {
  @Prop() public entityId!: number;
  @Prop() public entityType!: TagEntityTypeEnum;
  @Prop(Boolean) public noBorder!: boolean;
  @Prop(Boolean) public disabled!: boolean;
  @Prop(Boolean) public isEntityIdTemp!: boolean;
  public search = '';
  public tagFilter: TagFilter = TagFilter.all;

  @Action('documents/getRdfMeshTags')
  public getRdfMeshTags!: (payload: string) => void;

  get value(): DirtyTagMap[] {
    const allTm: DirtyTagMap[] = parseJsonIfNecessary(this.$attrs.value);
    return allTm
      .filter(
        ({ entityType, entityId }) =>
          entityType === this.entityType && entityId === this.entityId
      )
      .map((tm) => {
        return {
          ...tm,
          entityId: this.entityId,
          entityType: this.entityType,
          value:
            tm.tagType === TagType.Keyword
              ? tm.keyword!.keyword
              : tm.tagType === TagType.MeSH
              ? `[MeSH] ${tm.mesh!.keyword}`
              : undefined
        };
      });
  }
  set value(v: DirtyTagMap[]) {
    this.$emit('input', v);
  }

  get searchKeywordTag(): (
    payload: SearchKeywordPayload
  ) => Promise<Keyword[]> {
    return useAction.call(this, 'documents/searchKeywordTag');
  }

  get searchKeywordTagState() {
    return useApiState.call(this, 'documents/searchKeywordTag');
  }

  get getMeshTree(): () => Promise<any> {
    return useAction.call(this, 'documents/getMeshTree');
  }

  get getMeshTreeState() {
    return useApiState.call(this, 'documents/getMeshTree');
  }

  get getRdfMeshTagsState() {
    return useApiState.call(this, 'documents/getRdfMeshTags');
  }

  // Get MESH tags from MESH xml file
  get meshTags(): Readonly<MeshDto[]> {
    return this.meshTree ? Object.freeze(this.meshTree.flatContent) : [];
  }

  get selectedMesh(): DirtyTagMap[] {
    return this.value.filter((tm) => tm.tagType === TagType.MeSH);
  }

  get keywordTags() {
    return useDocumentsState.call(this).keywordTags || [];
  }

  get meshTree() {
    return useDocumentsState.call(this).meshTree;
  }

  get rdfMeshTags() {
    return useDocumentsState.call(this).rdfMeshTags || [];
  }

  get allKeywordOptions(): DirtyTagMap[] {
    return this.keywordTags.map(({ keyword }) => ({
      entityId: this.entityId,
      entityType: this.entityType,
      tagType: TagType.Keyword,
      keyword: { keyword }
    }));
  }

  /**
   * This function is used for meshTags getting from MESH xml file
   */
  get allMeshOptions(): DirtyTagMap[] {
    return this.meshTags.map((mesh) => ({
      entityId: this.entityId,
      entityType: this.entityType,
      tagType: TagType.MeSH,
      mesh
    }));
  }

  get allRdfMeshOptions(): DirtyTagMap[] {
    return this.rdfMeshTags.map((mesh) => ({
      entityId: this.entityId,
      entityType: this.entityType,
      tagType: TagType.MeSH,
      mesh
    }));
  }

  public onSelectMeshMenu(e: any, menu: TagFilter) {
    e.stopPropagation();
    this.tagFilter = menu;
  }

  public filterTag(
    tagMaps: DirtyTagMap[],
    search = this.search,
    limit = 50 // Show all search results form MESH API
  ): DirtyTagMap[] {
    let count = 0;
    return tagMaps.filter((tagMap) => {
      // for performance reason, don't return more than 20
      if (count >= limit) {
        return false;
      }
      let valid = false;
      switch (tagMap.tagType) {
        case TagType.Keyword: {
          const tagKey = tagMap.keyword!.keyword;
          const selected = this.value
            .filter((m) => m.tagType === TagType.Keyword)
            .map((o) => o.keyword!.keyword);

          // Only return tags which have not been selected
          valid = !selected.includes(tagKey);
          break;
        }
        case TagType.MeSH: {
          const isSelected = this.value
            .filter((m) => m.tagType === TagType.MeSH)
            .map((o) => o.mesh!.keyword)
            .includes(tagMap.mesh!.keyword);
          const isMatched =
            tagMap.mesh!.keyword.toLowerCase().includes(search.toLowerCase()) ||
            tagMap.mesh!.path.toLowerCase().includes(search.toLowerCase());

          // Only return tags which have not been selected and match keyword or path
          valid = !isSelected && isMatched;

          break;
        }
        default:
          // tslint:disable-next-line
          console.error(new Error('Unhandled Tag Type'));
          break;
      }
      if (valid) {
        count++;
      }
      return valid;
    });
  }

  get filteredKeywordOptions(): DirtyTagMap[] {
    return this.filterTag(this.allKeywordOptions);
  }

  get filteredMeshOptions(): DirtyTagMap[] {
    return this.filterTag(this.allMeshOptions);
  }

  get filteredRdfMeshOptions(): DirtyTagMap[] {
    return this.filterTag(this.allRdfMeshOptions);
  }

  get TagType() {
    return TagType;
  }

  get startCase() {
    return startCase;
  }

  get hasFooter() {
    return this.tagFilter === TagFilter.mesh;
  }

  get allFilteredOptions(): DirtyTagMap[] {
    let tags: DirtyTagMap[] = [];

    switch (this.tagFilter) {
      case TagFilter.all:
        tags = [...this.filteredKeywordOptions, ...this.filteredRdfMeshOptions];
        break;
      case TagFilter.plain:
        tags = [...this.filteredKeywordOptions];
        break;
      case TagFilter.mesh:
        tags = [...this.filteredRdfMeshOptions];
        break;
      default:
        break;
    }

    return tags;
  }

  public handleTagTyping($event: any) {
    this.search = $event;
  }

  public handleSearch() {
    this.searchKeywordTag({ text: this.search });
    this.getRdfMeshTags(this.search);
  }

  @isDifferent
  @Watch('search')
  @Debounce(500)
  public onSearch() {
    this.handleSearch();
  }

  public created() {
    if (!this.searchKeywordTagState!.loading && !this.keywordTags.length) {
      this.searchKeywordTag({ text: '' });
    }
    if (!this.getMeshTreeState!.loading && !this.meshTree) {
      this.getMeshTree();
    }
    if (!this.getRdfMeshTagsState!.loading && this.rdfMeshTags) {
      this.getRdfMeshTags('');
    }
  }

  public sanitizedAddRemoveEventPayload(e: string | DirtyTagMap) {
    if (typeof e === 'string') {
      e = {
        entityId: this.entityId,
        entityType: this.entityType,
        tagType: TagType.Keyword,
        keyword: {
          keyword: e
        }
      } as DirtyTagMap;
    }
    return e;
  }

  public resetSearchValue() {
    this.search = '';
  }

  public addTagMap(e: string | DirtyTagMap) {
    const allTm: DirtyTagMap[] = parseJsonIfNecessary(this.$attrs.value);
    const tm = this.sanitizedAddRemoveEventPayload(e);
    tm.isEntityIdTemp = this.isEntityIdTemp;
    this.value = uniqTagMap([...allTm, tm]);
    this.resetSearchValue();
  }

  public removeTagMap(e: string | DirtyTagMap) {
    const allTm: DirtyTagMap[] = parseJsonIfNecessary(this.$attrs.value);
    const tm = this.sanitizedAddRemoveEventPayload(e);
    this.value = removeTagMapFromArr(allTm, tm);
    this.resetSearchValue();
  }

  public selectPath(path: string) {
    const tm = this.allMeshOptions.find((op) => op?.mesh?.path === path);
    if (tm) {
      this.addTagMap(tm);
    }
  }

  public openMeshModal() {
    const myModal = this.$buefy.modal.open({
      parent: this,
      component: MeshConceptModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        addTagMap: this.addTagMap,
        selectedPaths: this.selectedMesh.map((tm) => tm.mesh!.path),
        selectPath: this.selectPath,
        allMeshOptions: this.allMeshOptions,
        filterTag: this.filterTag
      },
      events: {}
    });
  }

  public handleFocus() {
    if (this.search) {
      this.handleSearch();
    }
    let dropdown: Element = _get(
      this,
      '$refs.input.$refs.autocomplete.$refs.dropdown'
    );
    if (dropdown) {
      dropdown = dropdown.querySelector('.dropdown-content')!;
    }
    if (dropdown) {
      this.$nextTick(() => {
        dropdown.scrollTop = 0;
      });
    }
  }
}
