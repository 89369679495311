// tslint:disable:max-classes-per-file

import Op from 'quill-delta/dist/Op';

export enum CRITERION_TYPE {
  BOOLEAN = 'BOOLEAN',
  RANGE = 'RANGE',
  CHECKBOXES = 'CHECKBOXES',
}

export enum CRITERION_TYPE_DISPLAY_NAME {
  BOOLEAN = 'Boolean (Yes/No)',
  RANGE = 'Range (1-10)',
  CHECKBOXES = 'Checkboxes (Multiple Selection)',
}

export interface PendingCriterionData {
  isValid?: boolean;
  tempId?: string;
  documentSectionId?: number;
  criterionSubSectionId?: number;
  bprs?: PendingLinkedBpr[];
  content: CriterionContent;
}

export interface PendingLinkedBpr {
  bprSubSectionId?: number;
  tempId?: string;
  content?: Op[];
}

class BaseCriterionContent {
  public type: CRITERION_TYPE = CRITERION_TYPE.BOOLEAN;
  public title: string;

  constructor({ title }: Partial<BaseCriterionContent>) {
    this.title = title || '';
  }
}

export class BooleanCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.BOOLEAN;
  public booleanOptions: [string, string] = ['Yes', 'No'];

  constructor(params: Partial<BooleanCriterionContent>) {
    super(params);
    this.booleanOptions = params.booleanOptions || this.booleanOptions;
  }
}

export class RangeCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.RANGE;
  public range: [number, number] = [1, 10];

  constructor(params: Partial<RangeCriterionContent>) {
    super(params);
    this.range = params.range || this.range;
  }
}

export class CheckboxesCriterionContent extends BaseCriterionContent {
  public type = CRITERION_TYPE.CHECKBOXES;
  public checkboxesOptions: string[] = [];

  constructor(params: Partial<CheckboxesCriterionContent>) {
    super(params);
    this.checkboxesOptions = params.checkboxesOptions || this.checkboxesOptions;
  }
}

export type CriterionContent =
  | BooleanCriterionContent
  | RangeCriterionContent
  | CheckboxesCriterionContent;

export class Criterion {
  public id?: number;
  public tempId?: number | string;
  public content: CriterionContent;
  public subSections?: Array<{ id?: number; tempId?: number | string }> = [];

  constructor(params: Criterion) {
    this.id = params.id || this.id;
    this.tempId = params.tempId || this.tempId;
    this.content = params.content;
    this.subSections = params.subSections || this.subSections;
  }
}
