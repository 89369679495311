







































import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ProjectEditorHeaderTabTag from '@/views/DocumentEditor/components/ProjectEditorHeaderTabTag.vue';
import { PendingDocument } from '@/jbi-shared/types/document.types';
import { CplusDocumentTypeShortHand } from '@/jbi-shared/types/document.types';
import { StagingEditorCommonMixin } from '../mixins/staging-editor-common.mixin';

@Component({
  components: {
    ProjectEditorHeaderTabTag
  }
})
export default class DocumentNavigationTabs extends mixins(
  StagingEditorCommonMixin
) {
  @Prop() public documents!: Array<Partial<PendingDocument>>;
  @Prop({ default: true }) public multiple!: boolean;

  public hoveredItemIndex = -1;

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  public isActive(doc: PendingDocument) {
    if (!this.documentId) {
      return this.isTemplateRP(doc);
    }
    return doc.documentId === this.documentId;
  }

  public getDocTypeDisplayNameFromDoc(doc: PendingDocument) {
    return CplusDocumentTypeShortHand[doc.type];
  }
}
