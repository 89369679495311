



































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import VersionHistory from '@/views/DocumentVersions/components/VersionHistory.vue';
import VersionStickyNavbar from '@/views/DocumentVersions/components/VersionStickyNavbar.vue';
import Editor from '@/components/editor/Editor.vue';
import {
  DocumentDetail,
  GetDocumentByRevisionIdRequestPayload,
  UpdateRevisionNameRequestPayload,
  RestoreRevisionPayload,
  DocumentExportType,
  DirtyTagMap,
  GetDocumentByVersionIdRequestPayload,
  ExportDocumentRequestPayload
} from '@/store/modules/documents/types/documents.types';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import Delta from 'quill-delta';
import { get as _get, last as _last } from 'lodash';
import { textToOps } from '@/utils/quill-delta.util';
import { Revision } from '@/store/modules/documents/types/revisions.types';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { getReferenceSection } from '@/utils/editor.util';
import {
  useDocumentsState,
  useAction,
  useApiState,
  useProjectsState
} from '@/utils/store.util';
import { handleDocumentExporting } from '@/utils/export-docx.util';
import { Watch, Provide } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { EditorViewMode } from '@/utils/viewMode.mixin';
import { isTruthy, isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { saveAs } from 'file-saver';
import { Criterion } from '../../jbi-shared/types/criterions.types';
import { Job } from 'bull';
import { FullDocumentRevisionObject } from '../../jbi-shared/types/full-document-revision-object.types';
import { EditorCommonMixin } from '../DocumentEditor/mixins/editor-common.mixin';
import { RootState } from '../../store/store';
import { ConvertedLegacyDocumentPayload } from '../../jbi-shared/types/legacy-document.types';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '../../utils/date.util';
import {
  getCompiledMessage,
  showStickyErrorNotification
} from '@/utils/error.util';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import ExportDocumentAsDocxDialog from '../DocumentEditor/components/ExportDocumentAsDocxDialog.vue';
import { Action } from 'vuex-class';

@Component({
  components: {
    VersionHistory,
    VersionStickyNavbar,
    Editor
  }
})
export default class DocumentVersionsPage extends mixins(EditorCommonMixin) {
  public editingRevisionId: number | null = null;

  @Provide() public viewMode = EditorViewMode.version;

  @Action('documents/getRevisionsByDocumentId')
  public getRevisionsByDocumentId!: (documentId: number) => void;

  get revisionsByDocumentId() {
    return (this.$store.state as RootState).documents.revisionsByDocumentId;
  }

  get citationsOfProject() {
    return useProjectsState.call(this).citationsOfProject;
  }

  get getRevisionsState() {
    return useApiState.call(this, 'documents/getRevisionsByDocumentId');
  }

  // get getRevisionsByDocumentId(): (documentId: number) => void {
  //   return useAction.call(this, 'documents/getRevisionsByDocumentId');
  // }

  get getDocumentByVersionIdState() {
    return useApiState.call(this, 'documents/getDocumentByVersionId');
  }

  get getDocumentByVersionId(): (
    params: GetDocumentByVersionIdRequestPayload
  ) => void {
    return useAction.call(this, 'documents/getDocumentByVersionId');
  }

  get getCitations(): (documentId: number) => void {
    return useAction.call(this, 'projects/getCitations');
  }

  get updateRevisionName(): (params: UpdateRevisionNameRequestPayload) => void {
    return useAction.call(this, 'documents/updateRevisionName');
  }

  get getLegacyContentByBaseDocumentId(): (
    baseDocumentId: number
  ) => Promise<ConvertedLegacyDocumentPayload[]> {
    return useAction.call(this, 'documents/getLegacyContentByBaseDocumentId');
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get versionId(): number {
    return +this.$route.params.versionId;
  }

  get baseDocumentId(): number {
    return +this.$route.params.baseDocumentId;
  }

  get relatedDocs():
    | FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections']
    | undefined {
    return this.documentDetail?.revision?.sections?.relatedDocSubSections;
  }

  get referenceSection(): Partial<TextSection> {
    const citationIds = (this.documentDetail?.revision.sections.referenceSection
      .citationIds || []) as number[];
    const { allCitations } = this;
    return getReferenceSection(citationIds, allCitations);
  }

  get resourceUrl() {
    if (this.documentDetail) {
      const storageUri =
        this.documentDetail?.revision?.resource?.storageUri || '';
      return storageUri.replace('gs://', 'https://storage.googleapis.com/');
    }
    return '';
  }

  get restoreRevision(): (payload: RestoreRevisionPayload) => void {
    return useAction.call(this, 'documents/restoreRevision');
  }

  get restoreRevisionState() {
    return useApiState.call(this, 'documents/restoreRevision');
  }

  get originalTagMaps(): DirtyTagMap[] {
    return this.documentDetail ? this.documentDetail.revision.tags : [];
  }

  get sectionsTagMaps(): DirtyTagMap[] {
    return this.originalTagMaps.filter(
      ({ entityType }) => entityType === 'Section'
    );
  }

  get shouldGetRevisions(): boolean {
    if (!this.revisionsByDocumentId) {
      return true;
    }
    if (this.versionId && this.documentId) {
      return this.revisionsByDocumentId.revisions.every(
        (r) => r.projectDocument.id !== this.documentId
      );
    }
    if (this.baseDocumentId) {
      return this.revisionsByDocumentId.legacyDocuments.every(
        (obj) => obj.baseDocumentId !== this.baseDocumentId
      );
    }
    return true;
  }

  get isLoadingDocument() {
    const rootState = this.$store.state as RootState;
    return (
      rootState.projects.apiState.getCitations.loading ||
      rootState.documents.apiState.getDocumentByVersionId.loading ||
      rootState.documents.apiState.getLegacyContentByBaseDocumentId.loading
    );
  }

  @Watch('getDocumentByVersionIdState.error')
  public onGetRevisionError(error: any) {
    const errorPayload = _get(error, 'response.data.errors');
    let errorMsg;
    if (errorPayload) {
      const { errorCode, errorId } = errorPayload[0];
      if (errorCode === 'InvalidVersionId') {
        errorMsg = 'There is no existing document for this version ID';
      } else {
        errorMsg = 'Error retrieving document by version ID';
      }
      const compiledErrorMessage = getCompiledMessage(
        errorCode,
        errorMsg,
        errorId
      );
      showStickyErrorNotification(compiledErrorMessage);
    }
  }

  public handleDownload() {
    const filename = `${this.documentTitle}-${new Date().toISOString()}.pdf`;

    saveAs(this.resourceUrl, filename);
  }

  public async handleExport(exportType: DocumentExportType) {
    if (
      exportType === DocumentExportType.docx &&
      this.documentType === CplusDocumentType.EvidenceSummary
    ) {
      this.$buefy.modal.open({
        parent: this,
        component: ExportDocumentAsDocxDialog,
        hasModalCard: true,
        trapFocus: true,
        props: {
          title: this.documentTitle
        },
        events: {
          exportDocx: this.handleDocxExport
        }
      });
      return;
    }

    const workerJob = this.isViewingLegacyDocument
      ? await this.exportDocument({
          baseDocumentId: this.baseDocumentId,
          exportType
        })
      : await this.exportDocument({
          documentId: this.documentId,
          revisionId: this.revisionId as number,
          exportType
        });
    handleDocumentExporting.call(
      this,
      workerJob,
      this.documentDetail!,
      exportType
    );
  }

  public async handleDocxExport(value: { includeAuditCriteria: boolean }) {
    const { includeAuditCriteria } = value;
    const workerJob = await this.exportDocument({
      documentId: this.documentId,
      revisionId: this.revisionId,
      exportType: DocumentExportType.docx,
      includeAuditCriteria
    });
    handleDocumentExporting.call(
      this,
      workerJob,
      this.documentDetail!,
      DocumentExportType.docx
    );
  }

  get exportDocument(): (p: ExportDocumentRequestPayload) => Promise<Job> {
    return useAction.call(this, 'documents/exportDocument');
  }

  public created() {
    const {
      documentId,
      revisionsByDocumentId,
      projectId,
      versionId,
      baseDocumentId
    } = this;

    this.getCitations(projectId);

    if (this.isViewingRevision) {
      this.getDocumentByVersionId({ versionId, documentId });
    } else if (this.isViewingLegacyDocument) {
      this.getLegacyContentByBaseDocumentId(baseDocumentId);
    }
  }

  public getVersionIdFromRevisionId(revisionId: number) {
    const revisions = this.revisionsByDocumentId?.revisions || [];
    const versionId =
      revisions.findIndex((revision) => revision.id === revisionId) + 1;
    return versionId || 1;
  }

  get documentTitle(): string {
    return this.documentDetail?.document?.title || '';
  }

  get searchDate(): string {
    return this.documentDetail?.revision?.searchDate || '';
  }

  get pageTitle(): string {
    return this.documentTitle || `View Versions`;
  }

  get isViewingRevision() {
    return this.versionId;
  }

  get isViewingLegacyDocument() {
    return this.baseDocumentId;
  }

  public handleRevisionUpdate(params: { id: number; name: string }) {
    const { id: revisionId, name } = params;
    const { documentId } = this;
    this.updateRevisionName({
      documentId,
      revisionId,
      name
    });
  }

  public handleRestoreRevision(revisionId: number, revisionName: string) {
    this.restoreRevision({
      documentId: this.documentId,
      revisionId
    });

    Toast.open(`Version '${revisionName}' restored`);
  }

  @Watch('restoreRevisionState.error')
  @isTruthy
  public onRestoreRevisionError(error: AxiosError) {
    const message: string =
      error.response!.data.message || `Unknown Error. Please try again.`;

    Toast.open({
      message,
      duration: 3000,
      type: 'is-danger'
    });
  }

  @Watch('restoreRevisionState.success')
  @isDifferent
  @isTruthy
  public onRestoreRevisionSuccess(v: boolean, prevV: boolean) {
    this.getRevisionsByDocumentId(this.documentId);
  }

  @Watch('getRevisionsState.success')
  @isDifferent
  @isTruthy
  public onGetRevisionsSuccess(v: boolean, prevV: boolean) {
    const { revisions } = this.revisionsByDocumentId!;
    const { versionId } = this;
    const revision = versionId ? revisions[versionId - 1] : _last(revisions);
  }

  @Watch('shouldGetRevisions', { immediate: true })
  @isDifferent
  @isTruthy
  public onShouldGetRevisions() {
    this.getRevisionsByDocumentId(this.documentId);
  }

  get EditorViewMode() {
    return EditorViewMode;
  }

  public mounted() {
    document.body.classList.add('has-project-top-bar');
  }

  public beforeDestroy() {
    document.body.classList.remove('has-project-top-bar');
  }
}
