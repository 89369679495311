














































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';

@Component({})
export default class ListSectionValueEditor extends mixins(ViewModeMixin) {
  @Prop(Number) public index!: number;
  @Prop(String) public content!: string;
  @Prop({
    default: false
  })
  public isInvalidContent!: boolean;
  @Prop({
    default: false
  })
  public isNewContent!: boolean;
  @Prop({
    validator(value) {
      return ['ol', 'ul'].includes(value);
    },
    required: true
  })
  public listType!: 'ol' | 'ul';
  @Prop(Boolean) public editable!: boolean;
  @Prop({ type: Boolean, default: true }) public showEdit!: boolean;
  @Prop({ type: Boolean, default: false }) public isStaging!: boolean;
}
