import Delta from 'quill-delta';
import Quill from 'quill';
import Op from 'quill-delta/dist/Op';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { PendingTextSectionData } from '@/jbi-shared/types/document.types';

export const deltaToQuillDom: (input: Delta) => Element | null = (
  inputDelta,
) => {
  const tempElem = document.createElement('div');
  new Quill(tempElem).setContents(inputDelta);
  return tempElem.querySelector('.ql-editor');
};

export const deltaToText: (input: Delta) => string = (inputDelta) =>
  (deltaToQuillDom(inputDelta)?.textContent || '') as string;

export const opsToText: (input: Op[]) => string = (inputOp) =>
  deltaToText(new Delta(inputOp));

export const textToDelta: (input: string) => Delta = (inputText) =>
  new Delta().insert(inputText);

export const textToOps: (input: string) => Op[] = (inputText) =>
  textToDelta(inputText).ops;

export const hasSectionTitleTextChanged: (n: Op[], p: Op[]) => boolean = (
  newSectionTitle,
  prevSectionTitle,
) => opsToText(newSectionTitle!).trim() !== opsToText(prevSectionTitle!).trim();

export const hasPendingTextSectionChanged: (
  newTextSection: PendingTextSectionData[],
  oldTextSection: PendingTextSectionData[],
) => boolean = (newTextSection, oldTextSection) => {
  return (
    // check array order
    JSON.stringify(newTextSection.map((ts) => ts?.content.tempId)) !==
      JSON.stringify(oldTextSection.map((ts) => ts?.content.tempId)) ||
    // check section title
    JSON.stringify(newTextSection.map((ts) => ts?.content.sectionTitle)) !==
      JSON.stringify(oldTextSection.map((ts) => ts?.content.sectionTitle)) ||
    JSON.stringify(
      newTextSection.map((ts) => opsToText(ts?.content.sectionTitle!).trim()),
    ) !==
      JSON.stringify(
        oldTextSection.map((ts) => opsToText(ts?.content.sectionTitle!).trim()),
      ) ||
    // check section value
    JSON.stringify(newTextSection.map((ts) => ts?.content.sectionValue)) !==
      JSON.stringify(oldTextSection.map((ts) => ts?.content.sectionValue)) ||
    JSON.stringify(
      newTextSection.map((ts) => opsToText(ts?.content.sectionValue!).trim()),
    ) !==
      JSON.stringify(
        oldTextSection.map((ts) => opsToText(ts?.content.sectionValue!).trim()),
      )
  );
};

export const textSectionHasChanged: (
  newAndOldTsArr: Array<
    FullDocumentRevisionObject['revision']['sections']['textSections']
  >,
) => boolean = ([newTsArr, oldTsArr]) => {
  return (
    // check length
    newTsArr.length !== oldTsArr.length ||
    // check array order
    JSON.stringify(newTsArr.map((ts) => ts?.documentSectionId)) !==
      JSON.stringify(oldTsArr.map((ts) => ts?.documentSectionId)) ||
    // check section title
    JSON.stringify(newTsArr.map(({ sectionTitle }) => sectionTitle)) !==
      JSON.stringify(oldTsArr.map(({ sectionTitle }) => sectionTitle)) ||
    JSON.stringify(
      newTsArr.map(({ sectionTitle }) => opsToText(sectionTitle!).trim()),
    ) !==
      JSON.stringify(
        oldTsArr.map(({ sectionTitle }) => opsToText(sectionTitle!).trim()),
      ) ||
    // check section value
    JSON.stringify(newTsArr.map(({ sectionValue }) => sectionValue)) !==
      JSON.stringify(oldTsArr.map(({ sectionValue }) => sectionValue)) ||
    // check deleted status
    JSON.stringify(newTsArr.map(({ deleted }) => deleted)) !==
      JSON.stringify(oldTsArr.map(({ deleted }) => deleted))
  );
};

export const hasBprContentChanged: (
  newAndOldTsArr: Array<
    FullDocumentRevisionObject['revision']['sections']['bprSection']
  >,
) => boolean = ([newSsArr, oldSsArr]) => {
  return (
    // check length
    newSsArr.length !== oldSsArr.length ||
    // check array order
    JSON.stringify(newSsArr.map((ss) => ss.documentSectionId)) !==
      JSON.stringify(oldSsArr.map((ss) => ss.documentSectionId)) ||
    // check section value
    JSON.stringify(newSsArr.map(({ content }) => content)) !==
      JSON.stringify(oldSsArr.map(({ content }) => content)) ||
    // check deleted status
    JSON.stringify(newSsArr.map(({ deleted }) => deleted)) !==
      JSON.stringify(oldSsArr.map(({ deleted }) => deleted))
  );
};

export function sanitizeSingleLineContent(ops: Op[]) {
  return ops.map((op) =>
    op.insert && typeof op.insert === 'string'
      ? {
          ...op,
          insert: (op.insert as string).replace(/(\r\n|\n|\r)/gm, ''),
        }
      : op,
  );
}
