





































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import dayjs from 'dayjs';
import { useUserName } from '@/utils/user.util';
import { useDocumentsState } from '@/utils/store.util';
import { DATE_TIME_FORMAT } from '@/utils/date.util';
import EditDocumentForm from '@/components/form/EditDocumentForm.vue';
import AssigneeInput from '@/views/DocumentEditor/components/AssigneeInput.vue';
import DocumentActions from '@/views/DocumentEditor/components/DocumentActions.vue';
import PdfUploadModal from './PdfUploadModal.vue';
import { EditorComputedValuesMixin } from '../mixins/editor-computed-values.mixin';
import { CplusDocumentAction } from '@/jbi-shared/types/document.types';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';

@Component({
  components: {
    EditDocumentForm,
    AssigneeInput,
    DocumentActions
  }
})
export default class PdfHeaderDocInfo extends mixins(
  EditorComputedValuesMixin
) {
  public form = {
    storageUri: ''
  };

  @Prop(Boolean) public isUploading!: boolean;
  @Prop(String) public defaultDocDisplayId!: string;

  get documentDetail() {
    return useDocumentsState.call(this).documentDetail;
  }

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  get lastEditedDate(): string {
    if (!this.documentDetail) {
      return '';
    }
    return dayjs(this.documentDetail.revision.createdAt).format(
      DATE_TIME_FORMAT
    );
  }

  get publishedStatus() {
    if (useDocumentsState.call(this).documentDetail) {
      let action;

      switch (this.documentPublicationStatus) {
        case RevisionPublicationStatus.Draft:
          action = CplusDocumentAction.Publish;
          break;
        case RevisionPublicationStatus.Withdrawn:
          action = CplusDocumentAction.Restore;
          break;
        case RevisionPublicationStatus.Published:
          action = CplusDocumentAction.Withdraw;
          break;
      }

      return action;
    }

    return CplusDocumentAction.Publish;
  }

  get updatePublishStatusLoading(): boolean {
    return useDocumentsState.call(this).apiState.updatePublishStatus.loading;
  }

  public handleReplaceModal() {
    this.$buefy.modal.open({
      parent: this,
      component: PdfUploadModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Replace Document'
      },
      events: {
        uploaded: ({ storageUri }: { storageUri: string }) => {
          this.form.storageUri = storageUri;
        },
        save: () => {
          this.$emit('uploadDocument', this.form);
        }
      }
    });
  }

  public openDocSettingForm() {
    this.$buefy.modal.open({
      parent: this,
      component: EditDocumentForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        documentDetail: this.documentDetail
      }
    });
  }
}
