import { defaultApiState } from '@/store/types/general.types';
import {
  AcceptUserInvite,
  UsersState,
  VerifyUserInviteResponse
} from './types/users.types';

export const usersState: UsersState = {
  oicPayload: undefined,
  apiState: {
    inviteNewUser: defaultApiState,
    getUsers: defaultApiState,
    getUserInvitations: defaultApiState,
    removeUserInvitation: defaultApiState,
    resendUserInvitation: defaultApiState,
    searchUsersByText: defaultApiState,
    getMyRoles: defaultApiState,
    acceptUserInvite: defaultApiState,
    verifyUserInvite: defaultApiState,
    updateStatus: defaultApiState
  },
  users: undefined,
  userInvitations: undefined,
  searchUsersByTextResults: undefined,
  myRoles: undefined,
  acceptUserInvite: undefined,
  verifyUserInvite: undefined
};
