
































import { Component, Watch, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import { mixins } from 'vue-class-component';
import { SingleUserSearchResult } from '@/store/modules/users/types/users.types';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import {
  UpdateDocAssigneeRequestPayload,
  UpdateDocAssigneeResponsePayload
} from '@/store/modules/documents/types/documents.types';
import { RootState } from '@/store/store';
import Multiselect from 'vue-multiselect';
import { isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { capitalize } from '@/utils/typography';

type User = Pick<SingleUserSearchResult, 'id' | 'fullName' | 'email'>;

@Component({ components: { Multiselect } })
export default class AssigneeSearch extends mixins(EditorCommonMixin) {
  public searchText = '';
  public isLoading = false;
  public options: User[] | string[] = [];

  @Prop(Array)
  public value!: User[];

  @Action('users/searchUsersByText')
  public searchUsersByText!: (text: string) => SingleUserSearchResult[];

  get users() {
    return (
      (this.$store.state as RootState).users.searchUsersByTextResults || []
    );
  }

  @Debounce(500)
  public async searchUser(query: any) {
    this.isLoading = true;

    const response: User[] = await this.searchUsersByText(query);

    this.options = response.map((user) => capitalize(user.fullName));

    this.isLoading = false;
  }

  public created() {
    this.options = _cloneDeep(this.users).map((user) =>
      capitalize(user.fullName)
    );
  }

  // Update assignee options with the latest users value
  // Prevent case the options are not updated when the query changes
  @Watch('users')
  public updateOptions() {
    this.options = _cloneDeep(this.users).map((user) =>
      capitalize(user.fullName)
    );
  }
}
