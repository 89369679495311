














import { Component, Prop } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { ResearchNodeEntity } from '@/store/modules/documents/types/documents.types';
import ResearchNodeList from './ResearchNodeList.vue';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedResearchNodeList extends mixins(PaginationMixin) {
  @Prop() public items!: ResearchNodeEntity[];
  @Prop() public totalCount!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';

  get ResearchNodeList() {
    return ResearchNodeList;
  }
}
