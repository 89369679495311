





























































































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { useAction, useAuthState, useApiState } from '../../utils/store.util';
import {
  DocumentExportType,
  ExportWkResourceRequestPayload
} from '../../store/modules/documents/types/documents.types';
import { get as _get, uniq as _uniq, isInteger as _isInteger } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import {
  isDifferent,
  isTruthy
} from '../../jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { DialogProgrammatic as Dialog } from 'buefy';
import { AxiosError } from 'axios';
import { Job } from 'bull';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import dayjs from 'dayjs';
import PaginatedWkResourceExportsHistoryItems from './components/PaginatedWkResourceExportsHistoryItems.vue';
import { AddWkExportEventLogJobPayload } from '@/jbi-shared/types/wk-export.types';
import { Pagination } from '../../store/modules/users/types/users.types';
import { DATE_FORMAT_CUSTOM } from '@/utils/date.util';
import { CplusDocumentTypeShortHand } from '@/jbi-shared/types/document.types';
import {
  isLegacyDocId,
  isStandardDocId
} from '@/jbi-shared/util/document.utils';
import Container from '@/components/Container.vue';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';

@Component({
  components: {
    PaginatedWkResourceExportsHistoryItems,
    DashboardHeader,
    Container
  }
})
export default class WkResourceExports extends Vue {
  public page: number = 1;
  public perPage: number = 50;
  public updatedWkExports: string[] = [];

  public dates: Date[] = window.location.host.includes('localhost')
    ? [
        // new Date('2020-10-01T00:00:00.000Z'),
        // new Date('2020-10-30T00:00:00.000Z'),
      ]
    : [];
  public documentId: string = '';

  @Action('documents/getAllWkExports')
  public getAllWkResourceExports!: ({
    page,
    perPage
  }: {
    page: number;
    perPage: number;
  }) => Promise<void>;

  @State(({ documents }: RootState) => documents.allWkResourceExports)
  public allWkResourceExports!: Pagination<AddWkExportEventLogJobPayload>;

  public exportType = this.DocumentExportType.pdf;
  public showExportOptionsDropdown = false;

  get exportTypeLabel() {
    return this.exportType.toUpperCase();
  }

  get wkExports() {
    const wkExportList = this.filteredWkExports;
    return wkExportList;
  }

  get DocumentExportType() {
    return DocumentExportType;
  }

  get filteredWkExports() {
    const updatedWkExports = this.allWkResourceExports;
    if (updatedWkExports) {
      return updatedWkExports;
    }
    return { items: [], totalItems: 0 };
  }

  get exportTotalCount(): number {
    // FIXME: fix the type of wkExports.totalItems
    // @ts-ignore
    return +this.wkExports.totalItems;
  }

  get hasDates(): boolean {
    return this.dates.some(Boolean);
  }

  get hasDocId(): boolean {
    return Boolean(this.documentId);
  }

  get hasValidDocId(): boolean {
    if (!this.hasDocId) {
      return false;
    }

    return isStandardDocId(this.documentId) || isLegacyDocId(this.documentId);
  }

  get exportWkResource(): (
    d: ExportWkResourceRequestPayload
  ) => Promise<number> {
    return useAction.call(this, 'documents/exportWkResource');
  }

  get exportWkResourceLoading() {
    return useApiState.call(this, 'documents/exportWkResource')!.loading;
  }

  get authInfo() {
    return useAuthState.call(this).authInfo;
  }

  get docIdErrorsMsg(): string | undefined {
    if (this.hasDocId && !this.hasValidDocId) {
      return `Invalid Document ID.`;
    }
    return undefined;
  }

  get disablingSubmit(): boolean {
    return !(this.hasDates || this.hasValidDocId);
  }

  public selectExportType(exportType: DocumentExportType) {
    this.exportType = exportType;
    this.showExportOptionsDropdown = false;
  }

  public handleSubmit() {
    const { dates } = this;
    if (dates.some((d) => !d)) {
      alert(`Invalid Date Range ${JSON.stringify(dates)}`);
      return;
    }
    const startDate = dates[0]
      ? dayjs(dates[0]).format(DATE_FORMAT_CUSTOM)
      : undefined;
    const endDate = dates[1]
      ? dayjs(dates[1]).format(DATE_FORMAT_CUSTOM)
      : undefined;
    const documentId = this.documentId || undefined;
    this.exportWkResource({
      startDate,
      endDate,
      documentId,
      exportType: this.exportType
    })
      .then((wkExportId) => {
        this.$router.push({
          name: 'wk-resource-export-status',
          params: {
            wkExportId: String(wkExportId)
          }
        });
      })
      .catch((error) => {
        Toast.open({
          message: _get(
            error,
            'response.data.message',
            `Error. Please try again later.`
          ),
          duration: 5000,
          type: 'is-danger',
          queue: false
        });
      });
  }

  public handleGetAllWkResourceExports() {
    this.getAllWkResourceExports({
      page: this.page,
      perPage: this.perPage
    });
  }

  public updateRouteQuery() {
    this.$router.push({
      query: {
        page: String(this.page),
        perPage: String(this.perPage)
      }
    });
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    const { page, perPage } = value;
    this.perPage = +perPage || 50;
    this.page = +page || 1;
    this.handleGetAllWkResourceExports();
  }

  public async created() {
    await this.allWkResourceExports;
  }
}
