







import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';

@Component({
  components: {}
})
export default class IconButton extends Vue {
  public size = '';
  public icon = '';
  public name = '';
  public className = '';

  public mounted() {
    const { size, icon, name } = this.$attrs;

    this.size = size ? `${size}px` : '24px';
    this.icon = icon || 'mdi';
    this.name = name || 'close';

    this.className = `${this.icon} ${this.icon}-${this.size} ${this.icon}-${this.name}`;
  }
}
