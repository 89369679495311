var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (e) { return (_vm.isVisible = e); }),expression:"(e) => (isVisible = e)"}],class:_vm.$style.citation,attrs:{"draggable":_vm.draggable,"data-citation-id":_vm.citation.id},on:{"dragstart":function($event){return _vm.$emit('dragstart', $event)},"dragend":function($event){return _vm.$emit('dragend', $event)}}},[(_vm.isVisible)?_c('div',{class:_vm.$style.detail},[(_vm.citation.content.type === _vm.CitationTypes.PLAIN)?[_c('span',{class:_vm.$style.refNumber},[_vm._v(_vm._s(_vm.citationReferenceNumber ? _vm.citationReferenceNumber + ' ' : '')+" ")]),_c('span',[_vm._v(_vm._s(_vm.citation.content.plainText))])]:[(_vm.citation.content.author)?_c('p',{class:_vm.$style.citationAuthors},[_c('span',{class:_vm.$style.refNumber},[_vm._v(_vm._s(_vm.citationReferenceNumber))]),_vm._v(" "+_vm._s(_vm.citation.content.author)+" ")]):_vm._e(),(_vm.citation.content.issueDate)?_c('p',{class:_vm.$style.citationDate},[(!_vm.citationHasAuthor)?_c('span',{class:_vm.$style.refNumber},[_vm._v(" "+_vm._s(_vm.citationReferenceNumber)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.citation.content.issueDate)+" ")]):_vm._e(),(_vm.citation.content.title)?_c('p',{class:_vm.$style.citationTitle},[(!_vm.citationHasAuthor && !_vm.citationHasIssueDate)?_c('span',{class:_vm.$style.refNumber},[_vm._v(" "+_vm._s(_vm.citationReferenceNumber)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.citation.content.title)+" ")]):_vm._e()]],2):_vm._e(),_c('div',{class:_vm.$style.buttonGroup},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
            validator.validate().then(function (validated) {
              if (validated === false) { return; }
              if (_vm.$refs.CitationFormForEdit) {
                var citationFields = _vm.$refs.CitationFormForEdit.transformFieldToCitation();
                _vm.saveCitation(citationFields);
                _vm.$refs.CitationFormForEdit.$emit('closeModal');
              }
            });
          })($event)}}},[_c('BaseModal',{attrs:{"title":"Edit Citation"},on:{"open":function($event){_vm.draggable = false},"close":function($event){_vm.draggable = true}},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
          var openModal = ref.openModal;
return [(_vm.showArchiveButton)?_c('button',{class:_vm.$style.actionButton,on:{"click":_vm.toggleCitationStatus}},[_c('i',{staticClass:"mdi mdi-archive is-block"})]):_vm._e(),_c('button',{class:_vm.$style.actionButton,on:{"click":function($event){$event.preventDefault();return openModal($event)}}},[_c('i',{staticClass:"mdi mdi-pencil is-block"})])]}},{key:"modalBody",fn:function(ref){
          var closeModal = ref.closeModal;
return [_c('StagingCitationForm',{ref:"CitationFormForEdit",attrs:{"citation":_vm.citation,"isPendingCitation":true,"referencedCitationIds":_vm.referencedCitationIds,"pendingDocumentRevisionId":_vm.pendingDocumentRevisionId,"pendingEditor":true},on:{"closeModal":closeModal}})]}},{key:"modalFoot",fn:function(){return [_c('button',{staticClass:"button is-primary",class:{
                'is-loading': _vm.editCitationLoading
              },attrs:{"type":"submit","disabled":validator.invalid}},[_vm._v(" Save ")])]},proxy:true}],null,true)})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }