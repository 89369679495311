import dayjs from 'dayjs';
import {
  AuthState,
  ExchangeGrantForTokenResponsePayload,
  JaasAccountDetail,
  OicPayload,
  GetMeResponsePayload
} from './types/auth.types';
import { defaultApiState } from '@/store/types/general.types';
import jwtDecode from 'jwt-decode';
import { AxiosError } from 'axios';

export const authMutations = {
  /*
   ** exchangeGrantForToken
   */
  EXCHANGE_GRANT_FOR_TOKEN_LOADING(state: AuthState) {
    state.apiState.exchangeGrantForToken = {
      ...defaultApiState,
      loading: true
    };
    state.apiState.signOut = {
      ...defaultApiState,
      loading: true
    };
  },
  EXCHANGE_GRANT_FOR_TOKEN_SUCCESS(
    state: AuthState,
    payload: ExchangeGrantForTokenResponsePayload
  ) {
    state.apiState.exchangeGrantForToken = {
      ...defaultApiState,
      success: true
    };
    const { id_token: idToken, roles } = payload;
    const oicPayload = idToken ? (jwtDecode(idToken) as OicPayload) : undefined;
    state.authInfo = {
      ...state.authInfo,
      idToken,
      oicPayload
    };
    state.apiState.signOut = {
      ...defaultApiState
    };
    state.isLoggedIn = true;
  },
  EXCHANGE_GRANT_FOR_TOKEN_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.exchangeGrantForToken = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** signOut
   */
  SIGN_OUT_LOADING(state: AuthState) {
    state.apiState.signOut = {
      ...defaultApiState,
      loading: true
    };
  },
  SIGN_OUT_SUCCESS(state: AuthState) {
    state.apiState.signOut = {
      ...defaultApiState,
      success: true
    };
    state.isLoggedIn = false;
    state.authInfo = null;
    state.jaasAccountDetail = null;
    state.myInfo = null;
  },
  SIGN_OUT_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.signOut = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** checkSessionHealth
   */
  CHECK_SESSION_HEALTH_LOADING(state: AuthState) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      loading: true
    };
  },
  CHECK_SESSION_HEALTH_SUCCESS(state: AuthState) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      success: true
    };
    state.isLoggedIn = true;
  },
  CHECK_SESSION_HEALTH_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.checkSessionHealth = {
      ...defaultApiState,
      error
    };
    state.isLoggedIn = false;
    state.authInfo = null;
    state.jaasAccountDetail = null;
    state.myInfo = null;
  },
  /*
   ** getJaasAccountDetail
   */
  GET_JAAS_ACCOUNT_DETAIL_LOADING(state: AuthState) {
    state.apiState.getJaasAccountDetail = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_JAAS_ACCOUNT_DETAIL_SUCCESS(
    state: AuthState,
    payload: JaasAccountDetail
  ) {
    state.apiState.getJaasAccountDetail = {
      ...defaultApiState,
      success: true
    };
    state.jaasAccountDetail = payload;
  },
  GET_JAAS_ACCOUNT_DETAIL_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.getJaasAccountDetail = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getMe
   */
  GET_ME_LOADING(state: AuthState) {
    state.apiState.getMe = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ME_SUCCESS(state: AuthState, payload: GetMeResponsePayload) {
    state.apiState.getMe = {
      ...defaultApiState,
      success: true
    };
    state.myInfo = payload;
  },
  GET_ME_ERROR(state: AuthState, error: AxiosError) {
    state.apiState.getMe = {
      ...defaultApiState,
      error
    };
  }
};
