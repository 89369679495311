


























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UploadComplete extends Vue {
  @Prop(String) private fileName!: number;
  @Prop(Number) private successImport!: number;
  @Prop(Number) private failedImport!: number;
}
