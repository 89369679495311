
















































































import { ValidationObserver, ValidationProvider } from 'vee-validate';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { useApiState } from '@/utils/store.util';
import {
  InviteDocumentParticipantsRequestPayload,
  InvitedParticipants
} from '@/store/modules/documents/types/documents.types';
import { Action, State } from 'vuex-class';
import { ParticipantRoles } from '../../jbi-shared/types/document.types';
import {
  isDifferent,
  isTruthy
} from '../../jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { isEqual, get as _get } from 'lodash';
import { AxiosError } from 'axios';

type MyForm = Omit<InvitedParticipants, 'invitePending'>;

const initialInviteUserValues: MyForm = {
  email: '',
  role: ParticipantRoles.reviewer
};

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseMultiSelect
  }
})
export default class InvitationInputFields extends mixins() {
  @Prop(String) public label!: string;
  @Prop(Object) public participant!: InvitedParticipants;

  public invite: MyForm = { ...initialInviteUserValues, ...this.participant };
  public loading = false;

  @Action('documents/inviteDocumentUserAsParticipant')
  public inviteDocumentUserAsParticipant!: (
    payload: InviteDocumentParticipantsRequestPayload
  ) => Promise<void>;

  @Action('documents/updateDocumentParticipant')
  public updateDocumentParticipant!: (
    payload: InviteDocumentParticipantsRequestPayload
  ) => Promise<void>;

  @Action('documents/deleteDocumentParticipant')
  public deleteDocumentParticipant!: (
    payload: InviteDocumentParticipantsRequestPayload
  ) => Promise<void>;

  public handleInviteUser() {
    const { email, role } = this.invite;
    this.loading = true;
    this.inviteDocumentUserAsParticipant({
      documentId: this.documentId,
      userEmail: email,
      participantRole: role!
    })
      .then(() => {
        Toast.open({
          message: `Invited successfully`,
          position: 'is-top',
          type: 'is-dark'
        });
      })
      .catch((error: AxiosError) => {
        Toast.open({
          type: 'is-danger',
          position: 'is-top',
          message:
            _get(error.response, 'data.message') ||
            `Error updating user. Please try again later.`
        });
      })
      .finally(() => (this.loading = false));
  }

  public handleUpdateUser() {
    const { email, role } = this.invite;
    this.loading = true;
    this.updateDocumentParticipant({
      documentId: this.documentId,
      userEmail: email,
      participantRole: role!
    })
      .then(() => {
        Toast.open({
          message: `Updated successfully`,
          position: 'is-top',
          type: 'is-dark'
        });
      })
      .catch((error: AxiosError) => {
        Toast.open({
          type: 'is-danger',
          position: 'is-top',
          message:
            _get(error.response, 'data.message') ||
            `Error updating user. Please try again later.`
        });
      })
      .finally(() => (this.loading = false));
  }

  public handleDeleteUser() {
    const { email, role } = this.invite;
    this.loading = true;
    this.deleteDocumentParticipant({
      documentId: this.documentId,
      userEmail: email,
      participantRole: role!
    })
      .then(() => {
        Toast.open({
          message: `Deleted successfully`,
          position: 'is-top',
          type: 'is-dark'
        });
      })
      .catch((error: AxiosError) => {
        Toast.open({
          type: 'is-danger',
          position: 'is-top',
          message:
            _get(error.response, 'data.message') ||
            `Error updating user. Please try again later.`
        });
      })
      .finally(() => (this.loading = false));
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }
  get participantRoles(): string[] {
    const potentialParticipantList = Object.values(ParticipantRoles);
    return potentialParticipantList.slice(1, 3);
  }

  get isAddingNew() {
    return !this.participant;
  }
  get isUpdating() {
    return !!this.participant;
  }
  get canUpdate() {
    return this.isUpdating && !isEqual(this.invite, this.participant);
  }
  get userIsPending() {
    return !!_get(this.participant, 'invitePending');
  }
}
