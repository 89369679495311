





































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import dayjs from 'dayjs';
import { Prop, Watch } from 'vue-property-decorator';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { useDocumentsState } from '@/utils/store.util';
import {
  CplusDocumentType,
  CplusDocumentTypeShortHand
} from '@/jbi-shared/types/document.types';
import { DATE_TIME_FORMAT } from '@/utils/date.util';
import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog
} from 'buefy';
import { generateDefaultUniquePublicationId } from '@/jbi-shared/util/document.utils';
import { DateOrString } from '@/jbi-shared/types/full-document-revision-object.types';
import normalizeUrl from 'normalize-url';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import ViewArchivedNoteModal from '../../DocumentEditor/components/ViewArchivedNoteModal.vue';

@Component({})
export default class VersionHistoryItem extends Vue {
  @Prop(Boolean) public isSelected!: boolean;
  @Prop(Boolean) public isEditing!: boolean;
  @Prop(Number) public index!: number;
  @Prop(Number) public latestVersionId!: number;
  @Prop(Number) public versionId!: number;
  @Prop(Number) public publicationId!: number;
  @Prop({ required: true }) public createdAt!: string | Date;
  @Prop(String) public revisionName!: string;
  @Prop({ required: true }) public documentType!: CplusDocumentType;
  @Prop(Number) public revisionId!: number;
  @Prop(String) public userName!: string;
  @Prop(Boolean) public isLegacy!: boolean;
  @Prop(Number) public legacyBaseDocumentId!: number;
  @Prop(String) public publicationStatus!: RevisionPublicationStatus;
  @Prop(String) public publicationUser: string | undefined;
  @Prop(String) public publicationDate!: DateOrString;
  @Prop(String) public assignmentContractUrl!: string | undefined;
  @Prop(String) public assignmentContractFileName!: string | undefined;
  @Prop(String) public tdrUrl!: string | undefined;
  @Prop(String) public tdrFileName!: string | undefined;
  @Prop(String) public archivedNote!: string | undefined;
  @Prop(String) public withdrawnNote!: string | undefined;

  public value: string = this.currentVersionName;
  public showDropdown: boolean = false;
  public TRUNCATE_LENGTH: number = 32;

  public viewContract() {
    window.open(this.assignmentContractUrl, '_blank');
  }

  public downloadTdr() {
    window.open(this.tdrUrl, '_blank');
  }

  public viewNote(note: string) {
    this.$buefy.modal.open({
      parent: this,
      component: ViewArchivedNoteModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        note
      }
    });
  }

  get tdrDisplayName() {
    if (!this.tdrFileName) {
      return '';
    }

    /*
      Extract the TDR file name
      For example: the TDR file name is JBI-TDR-Competition Authorities and Their Role-12-2.docx
      We will extract the part "-12-2.docx" (-<esId>-<doc publicationId>.docx) from the file name. The esDoc Id and doc version in the TDR file name is important
      It helps client knows about the current version TDR that link to the current doc so we should show them in case of TDR file has a long name
     */
    const fileExtensionRegex = /\d+-\d+.docx/g;
    const result = this.tdrFileName.match(fileExtensionRegex);
    const fileExtension = result ? result[0] : '';

    const truncatedFileName = this.tdrFileName.replace(fileExtensionRegex, '');

    return this.tdrFileName.length <= this.TRUNCATE_LENGTH
      ? this.tdrFileName
      : truncatedFileName.substring(0, this.TRUNCATE_LENGTH) +
          `...${fileExtension}`;
  }

  get assignmentContractDisplayName() {
    if (!this.assignmentContractFileName) {
      return '';
    }

    const fileExtension = this.assignmentContractFileName.substring(
      this.assignmentContractFileName.lastIndexOf('.') + 1
    );
    const truncatedFileName = this.assignmentContractFileName.replace(
      fileExtension,
      ''
    );

    return this.assignmentContractFileName.length <= this.TRUNCATE_LENGTH
      ? this.assignmentContractFileName
      : truncatedFileName.substring(0, this.TRUNCATE_LENGTH) +
          `...${fileExtension}`;
  }

  public onCopySuccess(value: string, event: Event) {
    Toast.open({
      message: `Link copied successfully`,
      position: 'is-top',
      type: 'is-dark'
    });
  }

  public onCopyError(value: string, event: Event) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error copying link. Please try again later.`
    });
  }

  public formatDate(date: DateOrString): string {
    return dayjs(date).format(DATE_TIME_FORMAT);
  }

  public formatCreatedAtDate(date: DateOrString): string {
    return dayjs(date).format('DD MMM YYYY');
  }

  get defaultVersionName(): string {
    return this.formatDate(this.createdAt);
  }

  get revisionCreatedDate(): string {
    return this.formatCreatedAtDate(this.createdAt);
  }

  get revisionPublicationDate(): string {
    return this.formatDate(this.publicationDate);
  }

  get currentVersionName(): string {
    return this.revisionName || this.defaultVersionName;
  }

  get documentByRevisionId() {
    return useDocumentsState.call(this).documentByRevisionId;
  }

  get uniquePublicationId() {
    return (
      this.$attrs.uniquePublicationId ||
      generateDefaultUniquePublicationId({
        documentId: this.documentId,
        publicationId: this.publicationId,
        type: this.documentType as CplusDocumentType
      })
    );
  }

  @Watch('isEditing')
  @isTruthy
  public async onEditing(v: boolean) {
    await this.$nextTick();
    this.showDropdown = false;
    const elem = this.$refs.versionNameInput as HTMLInputElement;
    elem.focus();
    elem.setSelectionRange(0, elem.value.length);
  }

  @Watch('revision')
  public onRevisionChange() {
    this.value = this.currentVersionName;
  }

  public handleInputBlur() {
    if (!this.isEditing) {
      return;
    }
    this.$emit('doneEditing', this.value);
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get baseUrl(): string {
    return window.location.protocol + '//' + window.location.host;
  }

  get previewRouteInfo() {
    if (this.isLegacy) {
      return this.$router.resolve({
        name: 'preview-legacy-documents',
        params: {
          baseDocumentId: String(this.legacyBaseDocumentId)
        }
      });
    } else if (this.publicationId) {
      return this.$router.resolve({
        name: 'preview-publication',
        params: {
          typeShorthand: CplusDocumentTypeShortHand[this.documentType],
          documentId: String(this.documentId),
          publicationId: String(this.publicationId)
        }
      });
    } else {
      return this.$router.resolve({
        name: 'preview-revision',
        params: {
          documentId: String(this.documentId),
          revisionId: String(this.revisionId)
        }
      });
    }
  }

  get previewRoute() {
    return this.previewRouteInfo.route;
  }

  get previewUrl() {
    return normalizeUrl(window.location.host + this.previewRouteInfo.href);
  }

  get isArchived() {
    return this.publicationStatus === RevisionPublicationStatus.Archived;
  }

  get isPublished() {
    return this.publicationStatus === RevisionPublicationStatus.Published;
  }

  get isWithdrawn() {
    return this.publicationStatus === RevisionPublicationStatus.Withdrawn;
  }

  public onClickRestoreVersion() {
    const message =
      `<h1 class="title">Restore this version?</h1>` +
      `<p>Your current document will revert to the version named '${this.value}'.<p>`;

    return Dialog.confirm({
      message,
      confirmText: 'Restore Version',
      cancelText: 'Cancel',
      onConfirm: () => {
        this.$emit('restore', this.value);
      }
    });
  }

  public toggleDropdown(payload: boolean) {
    this.showDropdown = payload;
  }
}
