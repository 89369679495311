import { Store } from 'vuex';
import {
  AssignProjectEditorRequestPayload,
  AdminState,
  GetAssignmentZipUrlRequestPayload,
  ChangeDueDateRequestPayload,
  ExportProjectListRequestPayload,
  StaticUploadedFile,
  ResendDownloadLinkRequestPayload,
  DownloadDocumentRequestPayload,
  UnassignProjectEditorRequestPayload,
  FetchPendingDocumentRequestPayload,
  PendingDocumentUploadHistoryResponsePayload
} from './types/admin.types';
import { adminApi } from '@/api/admin.api';
import { Job } from 'bull';
import {
  FetchPendingDocCreationHistoryRequestPayload,
  ImportedEditorDocumentPayload,
  UpdatePendingDocumentRequestPayload
} from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import { PaginatedApiResponse } from '@/store/types/general.types';

export const adminActions = {
  async assignProjectEditor(
    store: Store<AdminState>,
    payload: AssignProjectEditorRequestPayload
  ) {
    try {
      store.commit('ASSIGN_PROJECT_EDITOR_LOADING');
      const { data } = await adminApi.assignProjectEditor(payload);
      store.commit('ASSIGN_PROJECT_EDITOR_SUCCESS', data);
    } catch (error) {
      store.commit('ASSIGN_PROJECT_EDITOR_ERROR', error);
    }
  },
  async unassignProjectEditor(
    store: Store<AdminState>,
    payload: UnassignProjectEditorRequestPayload
  ) {
    try {
      store.commit('UNASSIGN_PROJECT_EDITOR_LOADING');
      const { data } = await adminApi.unassignProjectEditor(payload);
      store.commit('UNASSIGN_PROJECT_EDITOR_SUCCESS', data);
    } catch (error) {
      store.commit('UNASSIGN_PROJECT_EDITOR_ERROR', error);
    }
  },
  async resendDownloadLink(
    store: Store<AdminState>,
    payload: ResendDownloadLinkRequestPayload
  ) {
    try {
      store.commit('RESEND_DOWNLOAD_LINK_LOADING');
      await adminApi.resendDownloadLink(payload);
      store.commit('RESEND_DOWNLOAD_LINK_SUCCESS');
    } catch (error) {
      store.commit('RESEND_DOWNLOAD_LINK_ERROR', error);
    }
  },
  async downloadDocument(
    store: Store<AdminState>,
    payload: DownloadDocumentRequestPayload
  ) {
    try {
      store.commit('DOWNLOAD_DOCUMENT_LOADING');
      const response = await adminApi.downloadDocument(payload);
      store.commit('DOWNLOAD_DOCUMENT_SUCCESS');
      const workerJob = response.data.job;
      store.dispatch('websocket/addWorkerJob', workerJob, {
        root: true
      });
      return workerJob;
    } catch (error) {
      store.commit('DOWNLOAD_DOCUMENT_ERROR', error);
    }
  },
  async exportProjectList(
    store: Store<AdminState>,
    payload: ExportProjectListRequestPayload
  ) {
    try {
      store.commit('EXPORT_PROJECT_LIST_LOADING');
      const response = await adminApi.exportProjectList(payload);
      store.commit('EXPORT_PROJECT_LIST_SUCCESS');

      const workerJob = response.data.job;
      store.dispatch('websocket/addWorkerJob', workerJob, {
        root: true
      });
      return workerJob;
    } catch (error) {
      store.commit('EXPORT_PROJECT_LIST_ERROR', error);
    }
  },
  async changeDueDate(
    store: Store<AdminState>,
    payload: ChangeDueDateRequestPayload
  ) {
    try {
      store.commit('CHANGE_DUE_DATE_LOADING');
      await adminApi.changeDueDate(payload);
      store.commit('CHANGE_DUE_DATE_SUCCESS');
    } catch (err) {
      store.commit('CHANGE_DUE_DATE_ERROR', err);
    }
  },
  async getAssignmentZipUrl(
    store: Store<AdminState>,
    payload: GetAssignmentZipUrlRequestPayload
  ) {
    try {
      store.commit('GET_ASSIGNMENT_ZIP_URL_LOADING');

      const { data } = await adminApi.getAssignmentZipUrl(payload);

      store.commit('GET_ASSIGNMENT_ZIP_URL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ASSIGNMENT_ZIP_URL_ERROR', error);
    }
  },

  async getUploadedDocumentDetails(
    store: Store<AdminState>,
    payload: StaticUploadedFile[]
  ) {
    try {
      store.commit('GET_UPLOADED_DOCUMENT_DETAILS_LOADING');
      const response = await adminApi.getUploadedDocumentDetails(payload);
      store.commit('GET_UPLOADED_DOCUMENT_DETAILS_SUCCESS', response.data);
      const workerJob = response.data as Job;

      store.dispatch('websocket/addWorkerJob', workerJob, {
        root: true
      });

      return workerJob;
    } catch (error) {
      store.commit('GET_UPLOADED_DOCUMENT_DETAILS_ERROR', error);
    }
  },

  async createPendingDocuments(
    store: Store<AdminState>,
    payload: ImportedEditorDocumentPayload[]
  ) {
    try {
      store.commit('CREATE_PENDING_DOCUMENTS_LOADING');
      const response = await adminApi.createPendingDocuments(payload);

      store.commit('CREATE_PENDING_DOCUMENTS_SUCCESS', response.data);
      const pendingDocumentsCreationId = response.data;

      return pendingDocumentsCreationId;
    } catch (error) {
      store.commit('CREATE_PENDING_DOCUMENTS_ERROR', error);
    }
  },

  async fetchPendingDocumentUploadHistory(
    store: Store<AdminState>,
    params: FetchPendingDocCreationHistoryRequestPayload
  ) {
    try {
      store.commit('FETCH_PENDING_DOCUMENT_UPLOAD_HISTORY_LOADING');
      const { data } = await adminApi.fetchPendingDocumentUploadHistory(params);
      const result: PaginatedApiResponse<PendingDocumentUploadHistoryResponsePayload> = {
        total_count: data?.meta?.totalItems || 0,
        incomplete_results: false,
        items: data?.items || []
      };
      store.commit('FETCH_PENDING_DOCUMENT_UPLOAD_HISTORY_SUCCESS', result);
    } catch (error) {
      store.commit('FETCH_PENDING_DOCUMENT_UPLOAD_HISTORY_ERROR', error);
    }
  },

  async fetchPendingDocumentsCreationStatus(
    store: Store<AdminState>,
    payload: number
  ) {
    try {
      store.commit('FETCH_PENDING_DOC_CREATION_STATUS_LOADING');
      const response = await adminApi.fetchPendingDocumentsCreationStatus(
        payload
      );

      store.commit('FETCH_PENDING_DOC_CREATION_STATUS_SUCCESS');

      return response.data;
    } catch (error) {
      store.commit('FETCH_PENDING_DOC_CREATION_STATUS_ERROR', error);
    }
  },

  async updatePendingDocument(
    store: Store<AdminState>,
    payload: UpdatePendingDocumentRequestPayload
  ) {
    try {
      store.commit('UPDATE_PENDING_DOCUMENT_LOADING');
      await adminApi.updatePendingDocument(payload);

      await store.dispatch('fetchPendingDocument', {
        projectId: payload.projectId,
        documentId: payload.documentId
      });
      store.commit('UPDATE_PENDING_DOCUMENT_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_PENDING_DOCUMENT_ERROR', error);
    }
  },

  async publishPendingDocument(store: Store<AdminState>, documentId: number) {
    try {
      store.commit('PUBLISH_PENDING_DOCUMENT_LOADING');
      await adminApi.publishPendingDocument(documentId);

      store.commit('PUBLISH_PENDING_DOCUMENT_SUCCESS');
    } catch (error) {
      store.commit('PUBLISH_PENDING_DOCUMENT_ERROR', error);
    }
  },

  async fetchPendingProjectDetails(
    store: Store<AdminState>,
    projectId: number
  ) {
    try {
      store.commit('FETCH_PENDING_PROJECT_DETAILS_LOADING');

      const { data } = await adminApi.fetchPendingProjectDetails(projectId);

      store.commit('FETCH_PENDING_PROJECT_DETAILS_SUCCESS', data);
    } catch (error) {
      store.commit('FETCH_PENDING_PROJECT_DETAILS_ERROR', error);
    }
  },

  async fetchPendingDocument(
    store: Store<AdminState>,
    payload: FetchPendingDocumentRequestPayload
  ) {
    try {
      store.commit('FETCH_PENDING_DOCUMENT_LOADING');
      const { documentId, projectId } = payload;
      let response;
      if (documentId) {
        response = await adminApi.fetchPendingDocument(documentId);
      } else {
        response = await adminApi.fetchPendingTemplateRP(projectId);
      }
      const { data } = response;

      store.commit('FETCH_PENDING_DOCUMENT_SUCCESS', data);
    } catch (error) {
      store.commit('FETCH_PENDING_DOCUMENT_ERROR', error);
    }
  },

  async fetchExistingDocumentDetail(
    store: Store<AdminState>,
    documentId: number
  ) {
    try {
      store.commit('FETCH_EXISTING_DOCUMENT_DETAIL_LOADING');

      const { data } = await adminApi.fetchExistingDocumentDetail(documentId);

      store.commit('FETCH_EXISTING_DOCUMENT_DETAIL_SUCCESS', data);
    } catch (error) {
      store.commit('FETCH_EXISTING_DOCUMENT_DETAIL_ERROR', error);
    }
  },

  async updateEditorDocumentUploadingResponse(
    store: Store<AdminState>,
    payload: any
  ) {
    store.commit('UPDATE_EDITOR_DOCUMENT_UPLOADING_RESPONSE', payload);
  },

  async upsertCreatePendingDocumentsResponse(
    store: Store<AdminState>,
    payload: any
  ) {
    store.commit('UPSERT_CREATE_PENDING_DOCUMENTS_RESPONSE', payload);
  }
};
