import { NavBarLinks } from '../jbi-shared/types/navbar.types';

export const navBarLinks: NavBarLinks[] = [
  {
    to: '/dashboard',
    label: 'DASHBOARD',
  },
  {
    to: '/documents',
    label: 'MY DOCUMENTS',
  },
  {
    to: '/search',
    label: 'SEARCH',
  },
  /* {
        to: '/',
        label: 'Profile',
      }, */
  // {
  //   to: '/',
  //   label: 'Help',
  // },
];
