

































import Component, { mixins } from 'vue-class-component';
import { StagingEditorCommonMixin } from '../mixins/staging-editor-common.mixin';
import { SidebarTabOption } from '@/views/DocumentEditor/mixins/editor-state.util';

interface SidebarItem {
  active: boolean;
  icon: string;
  iconSelected: string;
  label: string;
  onClick: () => void;
}

@Component({})
export default class StagingEditorSideBar extends mixins(
  StagingEditorCommonMixin
) {
  get sidebarItems(): SidebarItem[] {
    const result = [
      {
        active: this.activeSidebarTab === 'sections',
        icon: require('@/assets/images/editor/ic-section@2x.png'),
        iconSelected: require('@/assets/images/editor/ic-section-selected@2x.png'),
        label: `Sections`,
        onClick: () => {
          this.$emit('selectSidebarTab', 'sections');
          this.$router.replace({
            name: 'staging-area',
            params: {
              documentId: String(this.$route.params.documentId),
              projectId: this.$route.params.projectId
            },
            hash: '#sections'
          });
        }
      }
    ];
    if (this.isEs) {
      result.push({
        active: this.activeSidebarTab === 'citations',
        icon: require('@/assets/images/editor/ic-citation@2x.png'),
        iconSelected: require('@/assets/images/editor/ic-citation-selected@2x.png'),
        label: `Citations`,
        onClick: () => {
          this.$emit('selectSidebarTab', 'citations' as SidebarTabOption);
          this.$router.replace({
            name: 'staging-area',
            params: {
              documentId: String(this.$route.params.documentId),
              projectId: this.$route.params.projectId
            },
            hash: '#citations'
          });
        }
      });
    }
    return result;
  }
}
