

































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import Container from '@/components/Container.vue';
import { get as _get } from 'lodash';
import { ToastProgrammatic } from 'buefy';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  FetchPendingDocCreationHistoryRequestPayload,
  PendingDocCreationJobStatus
} from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import PaginatedCreationHistoryList from '@/components/listings/pending-document-creations/PaginatedCreationHistoryList.vue';
import PendingDocCreationHistoryFilterBox from './components/pending-document-creations/PendingDocCreationHistoryFilterBox.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import {
  FetchPendingDocumentRequestPayload,
  PendingDocumentUploadHistoryResponsePayload
} from '@/store/modules/admin/types/admin.types';
import { PaginatedApiResponse } from '@/store/types/general.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface FilteredPendingDocCreationHistoryParams {
  documentId: string;
  selectedStatus: string;
  createdAt: Date[];
  localTimezone: string;
}

interface PendingDocumentCreationStatus {
  projectDocumentId: number;
  uploadedDocInfo: {
    docType: string;
  };
  processJobStatus: PendingDocCreationJobStatus;
}

const initialState = {
  documentId: '',
  selectedStatus: '',
  createdAt: [],
  localTimezone: dayjs.tz.guess()
};

@Component({
  components: {
    DashboardHeader,
    Container,
    PendingDocCreationHistoryFilterBox,
    PaginatedCreationHistoryList
  }
})
export default class UploadHistoryPage extends Vue {
  @State((state: RootState) => state.admin.pendingDocumentUploadHistory)
  public pendingDocumentUploadHistory!: PaginatedApiResponse<
    PendingDocumentUploadHistoryResponsePayload
  >;

  @Action('admin/fetchPendingDocumentUploadHistory')
  public fetchPendingDocumentUploadHistory!: (
    params: FetchPendingDocCreationHistoryRequestPayload
  ) => Promise<void>;

  public filteredParams: FilteredPendingDocCreationHistoryParams = {
    ...initialState
  };
  public page = 1;
  public perPage = 50;

  get pendingDocumentCreationJobsPaginated() {
    return {
      items: _get(this.pendingDocumentUploadHistory, 'items', []),
      totalItems: _get(this.pendingDocumentUploadHistory, 'total_count', 0)
    };
  }

  get filterQueryParams() {
    const createdAt = this.filteredParams.createdAt;
    let createdAtParam;
    if (createdAt && createdAt.length === 2) {
      createdAtParam = createdAt
        .map((date) => new Date(date))
        .map((date) => this.dateToIsoDate(date));
    }
    return {
      ...this.filteredParams,
      createdAt: createdAtParam
    };
  }

  public goToAdminDashboard() {
    this.$router.push({
      name: 'admin-dashboard'
    });
  }

  public handleReset() {
    this.filteredParams = {
      ...this.filteredParams,
      ...initialState
    };
    this.debounceUpdate();
  }

  public handleFilter(
    filterParam: Partial<FilteredPendingDocCreationHistoryParams>
  ) {
    this.filteredParams = {
      ...this.filteredParams,
      ...filterParam
    };
    this.debounceUpdate();
  }

  @Debounce(500)
  public debounceUpdate() {
    this.page = 1;
    this.updateRouteQuery();
  }

  public updateRouteQuery() {
    this.$router.push({
      // @ts-ignore
      query: {
        page: String(this.page),
        perPage: String(this.perPage),
        ...this.filterQueryParams
      }
    });
  }

  public created() {
    this.handleFetchHistory();
  }

  public dateToIsoDate(d: Date) {
    return dayjs(d).format().split('T').shift()!;
  }

  public handleFetchHistory() {
    this.fetchPendingDocumentUploadHistory({
      page: this.page,
      perPage: this.perPage,
      documentId: this.filteredParams.documentId || undefined,
      selectedStatus:
        (this.filteredParams.selectedStatus as PendingDocCreationJobStatus) ||
        undefined,
      createdAt: this.filteredParams.createdAt
        ? this.filteredParams.createdAt.map((date) => this.dateToIsoDate(date))
        : undefined,
      localTimezone: this.filteredParams.localTimezone || undefined
    });
  }

  public updateFilteredParams(
    filterParams: FilteredPendingDocCreationHistoryParams
  ) {
    const createdAt = filterParams.createdAt
      ? filterParams.createdAt.map((date) => new Date(date))
      : [];

    this.filteredParams = {
      ...filterParams,
      createdAt
    };
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    if (value) {
      const {
        page,
        perPage,
        selectedStatus,
        documentId,
        createdAt,
        localTimezone
      } = value;
      this.perPage = +perPage || 50;
      this.page = +page || 1;

      const filterParams = {
        createdAt,
        selectedStatus,
        documentId,
        localTimezone
      };
      this.updateFilteredParams(filterParams);
    }
    this.handleFetchHistory();
  }
}
