import { axios } from './axios';
import {
  AssignProjectEditorRequestPayload,
  ChangeDueDateRequestPayload,
  DownloadDocumentRequestPayload,
  ExportProjectListRequestPayload,
  GetAssignmentZipUrlRequestPayload,
  StaticUploadedFile,
  ResendDownloadLinkRequestPayload,
  UnassignProjectEditorRequestPayload,
  FetchPendingDocumentRequestPayload
} from '@/store/modules/admin/types/admin.types';
import {
  FetchPendingDocCreationHistoryRequestPayload,
  ImportedEditorDocumentPayload,
  UpdatePendingDocumentRequestPayload
} from '@/jbi-shared/types/cplus-endpoints/admin/document.types';

export const adminApi = {
  async assignProjectEditor(payload: AssignProjectEditorRequestPayload) {
    return await axios.post('/admin/assign-project-editor', payload);
  },
  async unassignProjectEditor(payload: UnassignProjectEditorRequestPayload) {
    return await axios.post('/admin/unassign-project-editor', payload);
  },
  async resendDownloadLink(payload: ResendDownloadLinkRequestPayload) {
    return await axios.post('/admin/resend-download-link', payload);
  },
  async downloadDocument(payload: DownloadDocumentRequestPayload) {
    return await axios.post('/admin/download-document', payload);
  },
  async changeDueDate(payload: ChangeDueDateRequestPayload) {
    return await axios.post('/admin/change-due-date', payload);
  },
  async exportProjectList(payload: ExportProjectListRequestPayload) {
    return await axios.post('/admin/projects/export', payload);
  },
  async getAssignmentZipUrl(payload: GetAssignmentZipUrlRequestPayload) {
    return await axios.get(
      '/public/assignment/download?token=' + payload.token
    );
  },
  async getUploadedDocumentDetails(payload: StaticUploadedFile[]) {
    return await axios.post(
      '/admin/documents/fetch-uploaded-document-details',
      [...payload]
    );
  },
  async fetchPendingProjectDetails(projectId: number) {
    return axios.get(`/projects/${projectId}/pending`);
  },
  async fetchPendingDocument(documentId: number) {
    return axios.get(`/admin/documents/${documentId}/pending`);
  },
  async createPendingDocuments(payload: ImportedEditorDocumentPayload[]) {
    return axios.post('/admin/documents/pending', {
      documents: payload
    });
  },
  async fetchPendingDocumentsCreationStatus(
    pendingDocumentsCreationId: number
  ) {
    return axios.get(
      `/admin/documents/pending/create/status/${pendingDocumentsCreationId}`
    );
  },
  async fetchPendingDocumentUploadHistory(
    params: FetchPendingDocCreationHistoryRequestPayload
  ) {
    return axios.get(`/admin/documents/pending/create/status/history`, {
      params
    });
  },
  async updatePendingDocument(payload: UpdatePendingDocumentRequestPayload) {
    return axios.put(`/admin/documents/${payload.pendingDocumentId}/pending`, {
      ...payload
    });
  },
  async publishPendingDocument(documentId: number) {
    return axios.post(`/admin/documents/${documentId}/publish`);
  },
  async fetchExistingDocumentDetail(id: number) {
    return axios.get(`documents/${id}`);
  },
  async fetchPendingTemplateRP(projectId: number) {
    return axios.get(`/documents/${projectId}/pending/templateRP`);
  }
};
