import { Store, ActionContext } from 'vuex';
import { editorsState } from './editors.state';
import { editorsApi } from '@/api/editors.api';
import {
  EditorsState,
  FetchEditorOptionsRequestPayload
} from './types/editors.types';
import { GetEditorsRequestPayload } from './types/editors.types';
import { FetchEditorOptionsResponsePayload } from '@/jbi-shared/types/cplus-endpoints/editor.types';

export const editorsActions = {
  async getEditors(
    store: Store<EditorsState>,
    params: GetEditorsRequestPayload
  ) {
    try {
      store.commit('GET_EDITORS_LOADING');
      const { data } = await editorsApi.getEditors(params);
      store.commit('GET_EDITORS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_EDITORS_ERROR', error);
    }
  },
  async fetchEditorRoles(store: Store<EditorsState>) {
    try {
      store.commit('FETCH_EDITOR_ROLES_LOADING');
      const { data } = await editorsApi.fetchEditorRoles();
      store.commit('FETCH_EDITOR_ROLES_SUCCESS', data);
    } catch (error) {
      store.commit('FETCH_EDITOR_ROLES_ERROR', error);
    }
  },
  async fetchEditorOptions(
    store: Store<EditorsState>,
    params: FetchEditorOptionsRequestPayload
  ) {
    try {
      store.commit('FETCH_EDITOR_OPTIONS_LOADING');
      const { data } = await editorsApi.fetchEditorOptions(params);
      store.commit('FETCH_EDITOR_OPTIONS_SUCCESS', data);
    } catch (error) {
      store.commit('FETCH_EDITOR_OPTIONS_ERROR', error);
    }
  }
};
