





























































































































































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import {
  ConvertedLegacyDocumentPayload,
  LegacyDocumentPreMigrationErrorType
} from '@/jbi-shared/types/legacy-document.types';
import { Action } from 'vuex-class';
import { RootState } from '@/store/store';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  trim as _trim,
  escape as _escape,
  first as _first,
  last as _last,
  range as _range,
  get as _get,
  uniq as _uniq,
  uniqBy as _uniqBy,
  pick as _pick,
  isEqual as _isEqual
} from 'lodash';
import AuthorInput from '@/views/LegacyDocumentMigrationPage/AuthorInput.vue';
import { Author } from '../../jbi-shared/types/author.types';
import {
  CreateProjectFromLegacyDocumentRequestPayload,
  CreateProjectFromLegacyDocumentResponsePayload,
  GetPreMigrationStatusRequestPayload,
  GetPreMigrationStatusResponsePayload
} from '../../store/modules/projects/types/projects.types';
import {
  isSingleCitationFunc,
  isRangeCitationFunc,
  isCommaSeperatedCitationFunc
} from '../../jbi-shared/util/citation.util';
import {
  CplusDocumentType,
  CplusDocumentTypeDisplayName,
  CplusDocumentTypeShortHand
} from '../../jbi-shared/types/document.types';
import { from, Observable, merge } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import {
  ResearchNode,
  DirtyTagMap
} from '../../store/modules/documents/types/documents.types';
import { fromString as htmlToText } from 'html-to-text';
import MeshConceptModal from '@/components/editor/SectionEditor/MeshConceptModal.vue';
import { MeshDto } from '../../jbi-shared/util/mesh.util';
import { useAction } from '../../utils/store.util';
import { compareTwoStrings } from 'string-similarity';
import dayjs from 'dayjs';
import MigrationSuccessModal from './MigrationSuccessModal.vue';

type IncorrectCitationLabel = number; /* Partial<
  CreateProjectFromLegacyDocumentRequestPayload['otherCustomData']['fixedReferenceNumbers'][0]
>; */

@Component<LegacyDocumentMigrationPage>({
  components: { AuthorInput, BaseMultiSelect, MeshConceptModal },
  subscriptions() {
    const contentNearSup$ = merge(
      this.$reactiveValueObservable('dirtyReferenceSectionError'),
      this.$reactiveValueObservable('incorrectCitationLabels')
    ).pipe(
      switchMap(() => this.$nextTick()),
      map(() => {
        if (!this.incorrectCitationLabels) {
          return [];
        }
        return this.incorrectCitationLabels.map((__, i) => {
          const elem: any = document.querySelector(
            `[data-citation-index-${i + 1}]`
          );
          if (!elem) {
            return '';
          }
          const left = _get(elem, 'previousSibling.textContent', '').slice(-50);
          // const right = _get(elem, 'previousSibling.textContent', '').slice(
          //   0,
          //   30,
          // );
          const html = ' .... ' + left + elem.outerHTML;
          return html;
        });
      })
    );

    return { contentNearSup: contentNearSup$ };
  }
})
export default class LegacyDocumentMigrationPage extends Vue {
  public authors: Author[] = [new Author()];
  // tslint:disable-next-line:max-line-length
  public fixedReferenceNumbers: CreateProjectFromLegacyDocumentRequestPayload['otherCustomData']['fixedReferenceNumbers'] = [];
  public referenceSectionText: string = '';
  public primaryResearchNode: ResearchNode | null = null;
  public plainTextTagsToBeMigrated: string[] = [];
  public legacyMeshKeywords: string[] = [];
  public meshTagsToBeMigrated: MeshDto[] = [];
  public searchDate: Date | null = null;

  @Action('documents/getAllLegacyDocVersionsByBaseDocumentId')
  public getAllLegacyDocVersionsByBaseDocumentId!: (
    baseDocumentId: number
  ) => Promise<ConvertedLegacyDocumentPayload[]>;

  @Action('projects/createProjectFromLegacyDocument')
  public createProjectFromLegacyDocument!: (
    payload: CreateProjectFromLegacyDocumentRequestPayload
  ) => Promise<CreateProjectFromLegacyDocumentResponsePayload>;

  @Action('projects/getPreMigrationStatus')
  public getPreMigrationStatus!: (
    payload: GetPreMigrationStatusRequestPayload
  ) => Promise<GetPreMigrationStatusResponsePayload>;

  @Action('projects/getActiveResearchNodes')
  public getResearchNodes!: () => Promise<ResearchNode[]>;

  get researchNodes() {
    return (this.$store.state as RootState).projects.activeResearchNodes;
  }

  get allLegacyDocVersionsByBaseDocumentId() {
    return (this.$store.state as RootState).documents
      .allLegacyDocVersionsByBaseDocumentId;
  }

  get originalDocumentArr() {
    return this.allLegacyDocVersionsByBaseDocumentId
      ? this.allLegacyDocVersionsByBaseDocumentId.map((o) => o.originalDocument)
      : undefined;
  }
  get convertedDocumentArr() {
    return this.allLegacyDocVersionsByBaseDocumentId
      ? this.allLegacyDocVersionsByBaseDocumentId.map(
          (o) => o.convertedDocument
        )
      : undefined;
  }

  get preMigrationStatus() {
    return (this.$store.state as RootState).projects.preMigrationStatus;
  }

  get migrationError() /* : 'loading' | 'canMigrate' | 'cannotMigrate' */ {
    // if (!this.preMigrationStatus) {
    //   return 'loading';
    // }
    // if (this.preMigrationStatus.migratable) {
    //   return 'canMigrate';
    // }
    // if (!this.preMigrationStatus.migratable) {
    //   return 'cannotMigrate';
    // }
    // return 'loading';
    return this.preMigrationStatus
      ? this.preMigrationStatus.error_type
      : undefined;
  }

  get LegacyDocumentPreMigrationErrorType() {
    return LegacyDocumentPreMigrationErrorType;
  }

  get sourceEsIdOfRp(): number | undefined {
    if (
      !this.preMigrationStatus ||
      !this.preMigrationStatus.sourceEvidenceSummaryDocuments
    ) {
      return undefined;
    }
    const { sourceEvidenceSummaryDocuments } = this.preMigrationStatus;
    const ids = sourceEvidenceSummaryDocuments.map((d) => d.baseDocumentId);
    return Math.max(...ids) || undefined;
  }

  get sourceEsTitleOfRp(): string | undefined {
    if (
      !this.sourceEsIdOfRp ||
      !this.preMigrationStatus ||
      !this.preMigrationStatus.sourceEvidenceSummaryDocuments
    ) {
      return undefined;
    }
    const { sourceEvidenceSummaryDocuments } = this.preMigrationStatus;
    const doc = sourceEvidenceSummaryDocuments.find(
      (d) => d.baseDocumentId === this.sourceEsIdOfRp
    );
    if (!doc) {
      return;
    }
    return doc.convertedDocument.document.title;
  }

  get pageTitle() {
    return 'Migration';
  }

  get latestBaseDocumentId() {
    return +this.$route.params.latestBaseDocumentId;
  }

  get realLatestBaseDocumentId() {
    if (!this.allLegacyDocVersionsByBaseDocumentId) {
      return;
    }
    const idArr = this.allLegacyDocVersionsByBaseDocumentId.map(
      (o) => o.baseDocumentId
    );
    return Math.max(...idArr);
  }

  get providedBaseDocumentIdIsNotLatest(): boolean {
    return (
      Boolean(this.realLatestBaseDocumentId) &&
      this.latestBaseDocumentId !== this.realLatestBaseDocumentId
    );
  }

  get legacyContent() {
    if (!this.allLegacyDocVersionsByBaseDocumentId) {
      return;
    }
    return this.allLegacyDocVersionsByBaseDocumentId.find(
      (o) => o.baseDocumentId === this.latestBaseDocumentId
    );
  }

  get originalDocument() {
    return this.legacyContent ? this.legacyContent.originalDocument : undefined;
  }

  get originalDocumentRefSection(): string {
    if (!this.originalDocument) {
      return '';
    }
    if (this.originalDocument.ContentsModified.includes('<References/>')) {
      return '';
    }
    if (!this.originalDocument.ContentsModified.includes('</References>')) {
      return '';
    }
    let result: string = this.originalDocument!.ContentsModified.split(
      '<References>'
    ).pop() as string;
    result = result.split('</References>').shift() as string;
    // decoding HTML twice, because it encoded twice in legacy
    result = htmlToText(result, {
      wordwrap: null
    });
    result = htmlToText(result, {
      wordwrap: null
    });
    result = result.trim();
    return result;
  }

  get originalContentXml(): string {
    if (!this.originalDocument) {
      return '';
    }
    const div = document.createElement('div');
    div.innerHTML = this.originalDocument!.ContentsModified;
    const result = div.textContent || '';
    div.remove();
    return result;
  }

  get originalDocumentReferences(): string[] {
    return this.originalDocumentRefSection.split('\n');
  }

  public getReferenceSectionError(refSection: string): string {
    refSection = this.sanitizeRefSection(refSection);

    const numberArr = refSection
      .split('\n')
      .filter(Boolean)
      .map((s) => s.trim())
      .map((s) => parseInt(String(s), 10));
    const containNonNumber = numberArr.some((s) => isNaN(Number(s)));
    if (containNonNumber) {
      const erroredCitation = refSection
        .split('\n')
        .filter(Boolean)
        .map((s) => s.trim())
        .find((s) => isNaN(parseInt(String(s), 10)));
      return `There are reference(s) that does not start with a number. "${erroredCitation}"`;
    }
    const linesWithWrongNumberFormat = refSection
      .split('\n')
      .filter(Boolean)
      .map((s) => s.trim())
      .filter((str, i) => !str.startsWith(numberArr[i] + '.'));
    if (linesWithWrongNumberFormat.length) {
      // tslint:disable-next-line
      return `The format should be "1. reference", "2. reference", etc. Incorrect item: "${linesWithWrongNumberFormat[0]}"`;
    }
    const numberIsNotsequential = numberArr.some((str, i) => str !== i + 1);
    if (numberIsNotsequential) {
      return `The reference numbers "${numberArr.join(
        ','
      )}" are not sequential.`;
    }
    return '';
  }
  get referenceSectionError() {
    return this.getReferenceSectionError(this.originalDocumentRefSection);
  }
  get dirtyReferenceSectionError() {
    return this.getReferenceSectionError(this.referenceSectionText);
  }

  get availableCitationLabels() {
    let refSectionText =
      this.referenceSectionText || this.originalDocumentRefSection;
    refSectionText = this.sanitizeRefSection(refSectionText);
    return refSectionText
      .split('\n')
      .filter(Boolean)
      .map((s) => parseInt(String(s), 10))
      .map(Number);
  }

  get minCitationLabels() {
    return Math.min(...this.availableCitationLabels);
  }
  get maxCitationLabels() {
    return Math.max(...this.availableCitationLabels);
  }

  get isFixingReferenceSection() {
    return this.referenceSectionError && this.dirtyReferenceSectionError;
  }

  get hasFinishedFixingReferenceSection() {
    return this.referenceSectionError && !this.dirtyReferenceSectionError;
  }

  get incorrectCitationLabelsInfo() {
    if (!this.originalDocument) {
      return undefined;
    }
    const result: string = this.originalDocument!.ContentsModified;
    // decoding HTML
    const div = document.createElement('div');
    div.innerHTML = result;
    div.innerHTML = div.textContent!;
    // fix scenario like <sup>1</sup><sup>-3</sup>
    div.innerHTML = String(div.innerHTML).replace('</sup><sup>', '');

    let incorrectCitationLabels: IncorrectCitationLabel[] = [];
    let citationIndex = 0;

    [...div.querySelectorAll('sup')].forEach((sup, i) => {
      if (typeof sup.textContent === 'string' && !sup.textContent.trim()) {
        return;
      }
      const iIncorrectCitationLabels = this.extractIncorrectCitationLabelsFromSup(
        sup
      );

      const hasIncorrectLabel = iIncorrectCitationLabels.length > 0;
      incorrectCitationLabels = [
        ...incorrectCitationLabels,
        ...iIncorrectCitationLabels
      ];

      if (hasIncorrectLabel) {
        sup.setAttribute('data-invalid-label', 'true');
        iIncorrectCitationLabels.forEach((__) => {
          citationIndex++;
          sup.setAttribute(`data-citation-index-${citationIndex}`, 'true');
        });
      }
    });

    return { content: div.innerHTML, incorrectCitationLabels };
  }

  public extractIncorrectCitationLabelsFromSup(
    sup: HTMLElement
  ): IncorrectCitationLabel[] {
    const str = sup.textContent!;

    /// <sup>1</sup>
    const isSingleCitation = isSingleCitationFunc(String(str));
    // <sup>1-3</sup>
    const isRangeCitation = isRangeCitationFunc(String(str));
    // <sup>1, 2, 5</sup>
    const isCommaSeperatedCitation = isCommaSeperatedCitationFunc(String(str));

    let labels: number[] = [];
    if (isSingleCitation) {
      labels = [Number(str)];
    } else if (isRangeCitation) {
      let charArr = String(str).split('');
      charArr = charArr.map((s) => s.trim());
      charArr = charArr.filter(Boolean);
      const start = Number(_first(charArr));
      const end = Number(_last(charArr));
      labels = _range(start, end + 1);
    } else if (isCommaSeperatedCitation) {
      let charArr = String(str).split('');
      charArr = charArr.map((s) => s.trim());
      charArr = charArr.filter(Boolean);
      labels = charArr.join('').split(',').map(Number).filter(Boolean);
    }

    const incorrectCitationLabels = labels.filter((label) => {
      return (
        this.referenceSectionError ||
        this.referenceSectionTextHasChanged ||
        isNaN(Number(label)) ||
        Number(label) < 0 ||
        Number(label) > this.originalDocumentReferences.length
      );
    });
    return incorrectCitationLabels;
  }

  get incorrectCitationLabels() {
    return this.incorrectCitationLabelsInfo
      ? this.incorrectCitationLabelsInfo.incorrectCitationLabels
      : undefined;
  }
  get contentWithIncorrectCitationLabels() {
    return this.incorrectCitationLabelsInfo
      ? this.incorrectCitationLabelsInfo.content
      : undefined;
  }

  get convertedDocument() {
    return this.legacyContent
      ? this.legacyContent.convertedDocument
      : undefined;
  }

  get _trim() {
    return _trim;
  }

  get canMigrate() {
    return (
      !this.migrationError &&
      this.legacyContent &&
      !this.providedBaseDocumentIdIsNotLatest &&
      !this.getAllLegacyDocVersionsByBaseDocumentIdLoading
    );
  }

  get canSubmit() {
    const meshSelected =
      this.legacyMeshKeywords.filter(Boolean).length ===
      this.meshTagsToBeMigrated.filter(Boolean).length;
    const searchDateFilled = !!this.searchDate;
    if (this.isEs) {
      return (
        !this.dirtyReferenceSectionError && meshSelected && searchDateFilled
      );
    } else if (this.isRp) {
      return meshSelected && searchDateFilled;
    } else {
      return meshSelected;
    }
  }

  get createProjectFromLegacyDocumentLoading() {
    return (this.$store.state as RootState).projects.apiState
      .createProjectFromLegacyDocument.loading;
  }

  get createProjectFromLegacyDocumentError() {
    return (this.$store.state as RootState).projects.apiState
      .createProjectFromLegacyDocument.error;
  }

  get getAllLegacyDocVersionsByBaseDocumentIdLoading() {
    return (this.$store.state as RootState).documents.apiState
      .getAllLegacyDocVersionsByBaseDocumentId.loading;
  }

  get documentType() {
    return this.convertedDocument
      ? this.convertedDocument.document.documentType
      : undefined;
  }
  get isEs() {
    return this.documentType === CplusDocumentType.EvidenceSummary;
  }
  get isRp() {
    return this.documentType === CplusDocumentType.RecommendedPractice;
  }
  get isBpis() {
    return this.documentType === CplusDocumentType.BestPracticeInformationSheet;
  }
  get isSr() {
    return this.documentType === CplusDocumentType.SystematicReview;
  }
  get isSrp() {
    return this.documentType === CplusDocumentType.SystematicReviewProtocol;
  }

  get shouldHaveAuthorField() {
    return this.isEs;
  }

  get primaryNodesIsMissing() {
    return this.originalDocument
      ? !this.originalDocument.Node.trim()
      : undefined;
  }

  get CplusDocumentTypeDisplayName() {
    return CplusDocumentTypeDisplayName;
  }

  get resourceUrl() {
    if (!this.convertedDocument) {
      return '';
    }
    const storageUri =
      this.convertedDocument?.revision?.resource?.storageUri || '';
    return storageUri.replace('gs://', 'https://storage.googleapis.com/');
  }

  get meshRawMeshTree() {
    return (this.$store.state as RootState).documents.meshTree;
  }
  get meshTags(): Readonly<MeshDto[]> {
    const result = this.meshRawMeshTree
      ? this.meshRawMeshTree!.flatContent
      : [];
    return JSON.parse(JSON.stringify(result));
  }

  get getMeshTree(): () => Promise<any> {
    return useAction.call(this, 'documents/getMeshTree');
  }

  get referenceSectionTextHasChanged() {
    return !_isEqual(
      this.referenceSectionText,
      this.sanitizeRefSection(this.originalDocumentRefSection)
    );
  }

  get documentTypeShortHand() {
    return this.documentType
      ? CplusDocumentTypeShortHand[this.documentType]
      : undefined;
  }

  @Watch('latestBaseDocumentId', { immediate: true })
  @isTruthy
  public onLatestBaseDocumentIdLoaded(latestBaseDocumentId: number) {
    this.getAllLegacyDocVersionsByBaseDocumentId(latestBaseDocumentId);
    this.getPreMigrationStatus({ baseDocumentId: latestBaseDocumentId });
  }

  // @Watch('providedBaseDocumentIdIsNotLatest')
  // @isTruthy
  // public ifProvidedBaseDocumentIdIsNotLatest() {
  //   alert(
  //     `The provided ID is not the latest Unique ID. The latest unique ID is ${this.realLatestBaseDocumentId}.`,
  //   );
  // }

  @Watch('createProjectFromLegacyDocumentError')
  @isTruthy
  public handleCreateProjectFromLegacyDocumentError() {
    alert(
      _get(
        this.createProjectFromLegacyDocumentError,
        'response.data.message'
      ) || 'Unknown Error'
    );
  }

  @Watch('originalDocumentRefSection', { immediate: true })
  public onOriginalDocumentRefSectionLoaded() {
    this.referenceSectionText = this.sanitizeRefSection(
      this.originalDocumentRefSection
    );
  }

  @Watch('originalDocument', { immediate: true })
  @isTruthy
  @isDifferent
  public onLegacyKeywordsLoaded() {
    if (!this.originalDocument) {
      return [];
    }
    // get plainTextTagsToBeMigrated
    const KeywordsStr = _trim(this.originalDocument!.Keywords, '"');
    this.plainTextTagsToBeMigrated = KeywordsStr.split(',')
      .join(';')
      .split(';')
      .map((s) => s.trim())
      .filter(Boolean);
    this.plainTextTagsToBeMigrated = _uniq(this.plainTextTagsToBeMigrated);

    // get legacyMeshKeywords
    const MeshKeywords = this.originalDocument!.MeshKeywords || '';
    this.legacyMeshKeywords = MeshKeywords.split(';')
      .map((s) => s.trim())
      .filter(Boolean);
    this.legacyMeshKeywords = _uniq(this.legacyMeshKeywords);
  }

  public sanitizeRefSection(str: string) {
    return str
      .split('\n')
      .map((s) => s.trim())
      .filter(Boolean)
      .filter((s) => s !== 'Reference')
      .filter((s) => s !== 'References')
      .join('\n');
  }

  public handleAddAuthor() {
    this.authors = [...this.authors, new Author()];
  }

  public handleDeleteAuthor(author: Author) {
    this.authors = this.authors.filter((o) => o !== author);
  }

  public async handleStartMigration() {
    if (!confirm(`Are you sure that you want to migrate this document?`)) {
      return;
    }

    if (!this.originalDocument || !this.convertedDocument) {
      alert(`missing data for "originalDocument" & "convertedDocument"`);
      return;
    }

    // sanitize authors
    const authors = this.authors.filter(
      (author) =>
        // remove empty author object
        Object.values(author)
          .map((s) => s.trim())
          .filter(Boolean).length > 0
    );
    const {
      fixedReferenceNumbers,
      referenceSectionText,
      primaryResearchNode
    } = this;

    const res = await this.createProjectFromLegacyDocument({
      originalDocument: this.originalDocument,
      convertedDocument: this.convertedDocument,
      baseDocumentId: this.latestBaseDocumentId,
      documentType: this.convertedDocument.document.documentType,
      otherCustomData: {
        authors,
        fixedReferenceNumbers,
        referenceSectionText,
        primaryResearchNode,
        plainTextTagsToBeMigrated: this.plainTextTagsToBeMigrated,
        meshTagsToBeMigrated: this.meshTagsToBeMigrated,
        searchDate: this.searchDate
          ? dayjs(this.searchDate).format('YYYY-MM-DD')
          : ''
      }
    });
    const projectId = res.projectId;
    const documentId = res.documentId;

    const newDocId = `JBI-${
      CplusDocumentTypeShortHand[this.convertedDocument.document.documentType]
    }-${documentId}`;
    const oldId = `JBI${this.convertedDocument.document.id}`;
    this.$buefy.modal.open({
      parent: this,
      component: MigrationSuccessModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: `Migrated`,
        modalBody: `<b>${oldId}</b> has been migrated to <b>${newDocId}</b>`,
        route: {
          name: 'editor',
          params: {
            projectId: String(projectId),
            documentId: String(documentId)
          }
        },
        newDocId
      },
      events: {},
      canCancel: false
    });
  }

  public deleteByIndex(arr: string[], i: number, shouldConfirm = true) {
    arr = arr.filter((__, j) => j !== i);
    return arr;
  }

  // copied from src/views/Search/components/MeshTagSearch.vue
  public filterTag(tagMaps: any[], search = '', limit = 20) {
    return tagMaps
      .filter((option) => {
        if (option) {
          return (
            option.keyword
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
          );
        }
      })
      .sort((a, b) => {
        return (
          compareTwoStrings(b.keyword, search) -
          compareTwoStrings(a.keyword, search)
        );
      })
      .map((el) => {
        el.mesh = {
          ...el
        };
        return el;
      });
  }

  public selectMesh(initialMeshSearch: string, index: number) {
    const myModal = this.$buefy.modal.open({
      parent: this,
      component: MeshConceptModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        addTagMap: (e: any) => {
          const mesh: MeshDto = _pick(e.mesh, 'keyword', 'path', 'treeId');
          this.meshTagsToBeMigrated[index] = mesh;
          this.meshTagsToBeMigrated = [...this.meshTagsToBeMigrated];
        },
        selectedPaths: [],
        selectPath: (e: string) => {
          const meshTagObject = this.meshTags.find((op) => op?.path === e);
          if (meshTagObject) {
            this.meshTagsToBeMigrated[index] = meshTagObject;
            this.meshTagsToBeMigrated = [...this.meshTagsToBeMigrated];
          }
        },
        allMeshOptions: this.meshTags,
        filterTag: this.filterTag,
        initialMeshSearch
      },
      events: {}
    });
  }

  public dateToDisplayedDate(d: Date) {
    return dayjs(d).format('D MMMM YYYY');
  }

  public created() {
    this.getResearchNodes();
    this.getMeshTree();
  }
}
