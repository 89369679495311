import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  get as _get,
  throttle as _throttle,
  includes as _includes
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy, isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { Throttle } from '@/jbi-shared/util/throttle.vue-decorator';
import {
  InviteParticipantsRequestPayload,
  RemoveUserFromProjectPayload
} from '@/store/modules/projects/types/projects.types';
import { RootState } from '@/store/store';
import { Action, State, Getter } from 'vuex-class';

export interface InviteUserValues {
  email: string;
  roleId: string;
  projectId?: number;
}

@Component({})
export class InviteUserMixin extends Vue {
  @Getter('documents/revisionId')
  public revisionId!: number;

  @State((state: RootState) => state.projects.apiState.inviteUser.success)
  public inviteUserSuccess!: boolean;

  @State((state: RootState) => state.projects.apiState.inviteUser.error)
  public inviteUserError!: boolean;

  @State(
    (state: RootState) => state.projects.apiState.removeUserFromProject.error
  )
  public deleteInviteUserError!: boolean;

  @State(
    (state: RootState) => state.projects.apiState.removeUserFromProject.success
  )
  public deleteInviteUserSuccess!: boolean;

  @State(
    (state: RootState) => state.projects.apiState.resendUserInvitation.error
  )
  public resendUserInvitationError!: boolean;

  @State(
    (state: RootState) => state.projects.apiState.resendUserInvitation.success
  )
  public resendUserInvitationSuccess!: boolean;

  @Action('projects/inviteUser')
  public inviteUserAction!: (payload: InviteParticipantsRequestPayload) => void;

  @Action('projects/resendUserInvitation')
  public resendUserInvitationAction!: (
    payload: InviteParticipantsRequestPayload
  ) => void;

  @Action('projects/removeUserFromProject')
  public removeUserFromProjectAction!: (
    payload: RemoveUserFromProjectPayload
  ) => void;

  @Watch('inviteUserError')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public watchInviteUserError() {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error inviting user. Try again later`
    });
  }

  @Watch('inviteUserSuccess')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public watchInviteUserSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `User invited successfully.`
    });
  }

  @Watch('resendUserInvitationError')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public watchResendUserInvitationError() {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error resending invite. Try again later`
    });
  }

  @Watch('resendUserInvitationSuccess')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public watchResendUserInvitationSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `User invitation was resent successfully.`
    });
  }

  @Watch('deleteInviteUserError')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public watchDeleteInviteUserError() {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error deleting user. Try again later`
    });
  }

  @Watch('deleteInviteUserSuccess')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public watchDeleteInviteUserSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `User removed from document successfully.`
    });
  }

  public inviteUser(values: InviteUserValues & { projectId: number }) {
    const { projectId, email, roleId } = values;

    this.inviteUserAction({
      projectId,
      userEmail: email,
      participantRole: roleId!
    });
  }

  public resendUserInvitation({
    projectId,
    userEmail,
    participantRole
  }: InviteParticipantsRequestPayload) {
    this.resendUserInvitationAction({
      projectId,
      userEmail,
      participantRole
    });
  }

  public removeUserFromProject({
    projectId,
    userId
  }: RemoveUserFromProjectPayload) {
    this.removeUserFromProjectAction({
      projectId,
      userId
    });
  }
}
