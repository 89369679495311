

















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FilteredProjectPayload } from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';

@Component({
  components: {}
})
export default class ProjectFilterBox extends Vue {
  @Prop(Object) public filterParams!: FilteredProjectPayload;

  public dateToDisplayedDate(dates: string[]) {
    if (Array.isArray(dates) && dates.length > 1) {
      if (dates.some((d) => !d)) {
        alert(`Invalid Date Range ${JSON.stringify(dates)}`);
        return;
      }

      const startDate = dates?.[0]
        ? dayjs(dates?.[0]).format('DD MMM YYYY')
        : undefined;
      const endDate = dates?.[1]
        ? dayjs(dates?.[1]).format('DD MMM YYYY')
        : undefined;

      return `${startDate} - ${endDate}`;
    }
  }
}
