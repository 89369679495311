import { DocumentsState } from './types/documents.types';
import { last as _last, get as _get } from 'lodash';
import { RootState } from '@/store/store';
import { GetterTree } from 'vuex';
import {
  CPLUS_BULL_JOBS,
  CPLUS_BULL_QUEUES
} from '../../../jbi-shared/types/cplus-worker.types';

const DocumentJobs = [
  CPLUS_BULL_JOBS[CPLUS_BULL_QUEUES.EXPORT].ExportDocumentJob,
  CPLUS_BULL_JOBS[CPLUS_BULL_QUEUES.EXPORT].ExportPDFJob
];

export const documentsGetters: GetterTree<DocumentsState, RootState> = {
  revisionId: (state: DocumentsState) => {
    return state.documentDetail ? state.documentDetail.revision.id : null;
  },
  isExporting(state: DocumentsState, _: any, rootState: RootState): boolean {
    const isExportLoading = state.apiState.exportDocument.loading;
    const isJobPending = rootState.websocket.workerJobs.some((job) => {
      return (
        DocumentJobs.includes(job.name) && !job.finishedOn && !job.returnvalue
      );
    });

    return isExportLoading || isJobPending;
  }
};
