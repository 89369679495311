












import { Component, Prop } from 'vue-property-decorator';
import BprForm from '@/components/editor/SectionEditor/BprSectionEditor/BprForm.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import BprListValueDisplay from '@/components/editor/SectionEditor/BprSectionEditor/BprListValueDisplay.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    BprForm,
    BprListValueDisplay
  }
})
export default class SubSectionSectionValueEditor extends mixins(
  ViewModeMixin
) {
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public bprSectionId!: number;
  @Prop()
  public subSection!: FullDocumentRevisionObject['revision']['sections']['bprSection'][0];
  @Prop()
  public subSections!: FullDocumentRevisionObject['revision']['sections']['bprSection'];

  @Prop(Number) public index!: number;
  @Prop({ type: Boolean, default: false }) public isHighlighted!: boolean;
  @Prop({ type: Boolean, default: false }) public isStaging!: boolean;

  get content() {
    return this.subSection.content || [];
  }

  public openEditModal() {
    this.$buefy.modal.open({
      parent: this,
      component: BprForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Edit Best Practice Recommendation',
        subSection: this.subSection,
        subSections: this.subSections,
        dirtyTagMaps: this.dirtyTagMaps,
        bprSectionId: this.bprSectionId
      },
      events: {
        'update:bprs': (
          e: FullDocumentRevisionObject['revision']['sections']['bprSection']
        ) => this.$emit('update:bprs', e),
        'update:dirtyTagMaps': (e: DirtyTagMap[]) =>
          this.$emit('update:dirtyTagMaps', e)
      }
    });
  }
}
