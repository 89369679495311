



































import { Component, Prop } from 'vue-property-decorator';
import SectionEditorHeader from './SectionEditorHeader.vue';
import Op from 'quill-delta/dist/Op';
import { opsToText, textToOps } from '@/utils/quill-delta.util';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';

@Component({
  components: {
    SectionEditorHeader
  }
})
export default class SectionTitleEditor extends mixins(ViewModeMixin) {
  @Prop(Array)
  public readonly sectionTitle!: Op[];
  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isFocused!: boolean;
  @Prop(String)
  public type!: 'doc-title';
  @Prop({ type: Boolean, default: false })
  public hasError!: boolean;

  get titleBeingEdited() {
    return opsToText(this.sectionTitle);
  }
  set titleBeingEdited(v) {
    this.$emit('update:sectionTitle', textToOps(v));
  }

  public async sanitizeOnPaste(evt: any) {
    evt.preventDefault();
    let pastedData = evt.clipboardData.getData('Text');
    pastedData = pastedData.replace(/(\r\n|\n|\r)/gm, ' ');
    this.titleBeingEdited = this.titleBeingEdited + pastedData;
  }
}
