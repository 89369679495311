var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body",class:_vm.$style.modalBody},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Project Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{class:_vm.$style.fieldContainer,attrs:{"label":"Project Title","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],class:['textarea', _vm.$style.title],attrs:{"placeholder":"Eg: Cost benefit of controlled medicine","required":"","maxlength":"200","rows":"2"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Primary Field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{class:_vm.$style.fieldContainer,attrs:{"label":"Primary Field","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('ActiveMultiSelect',{attrs:{"options":_vm.researchNodes,"placeholder":"Select primary field","openDirection":"top","maxHeight":150},model:{value:(_vm.form.primaryResearchNode),callback:function ($$v) {_vm.$set(_vm.form, "primaryResearchNode", $$v)},expression:"form.primaryResearchNode"}})],1)]}}],null,true)}),_c('b-field',{class:_vm.$style.fieldContainer,attrs:{"label":"Secondary Fields"}},[_c('ActiveMultiSelect',{attrs:{"options":_vm.secondaryResearchNodesOptions,"disabled":!_vm.form.primaryResearchNode,"placeholder":"Select multiple fields","openDirection":"top","maxHeight":150},model:{value:(_vm.form.researchNodes),callback:function ($$v) {_vm.$set(_vm.form, "researchNodes", $$v)},expression:"form.researchNodes"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",class:{ 'is-loading': false },attrs:{"disabled":validator.invalid},on:{"click":function($event){validator.validate().then(function (validated) {
            if (validated === false) { return; }
            _vm.handleSubmit();
            _vm.$emit('close');
        })}}},[_vm._v(" Save Changes ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }