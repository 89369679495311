



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';

@Component({ name: 'MeshTreeItem' })
export default class MeshTreeItem extends Vue {
  @Prop() public tree!: any;
  @Prop() public level!: number;
  @Prop() public path!: string;
  @Prop(Array) public readonly activeMeshTreePaths!: string[];
  @Prop(Array) public readonly selectedPaths!: string[];

  get hasChild() {
    return Object.keys(this.cleanTree).length > 0;
  }

  get cleanTree() {
    const tree = { ...this.tree };
    delete tree.name;
    return tree;
  }

  get isActivePath() {
    return this.activeMeshTreePaths.includes(this.path);
  }

  get isActivePathsChild() {
    const splited = this.path.split('.');
    const parentPath = splited.slice(0, splited.length - 1).join('.');
    return this.activeMeshTreePaths.includes(parentPath);
  }

  get isRootPath() {
    return this.path.split('.').length === 1;
  }

  get isSelected() {
    return this.selectedPaths.includes(this.path);
  }

  get shouldShow() {
    return this.isActivePath || this.isActivePathsChild || this.isRootPath;
  }

  public activateMe() {
    this.$emit('activatePath', this.path);
  }
  public deactivateMe() {
    this.$emit('deactivatePath', this.path);
  }
  public toggleMe() {
    return this.hasChild
      ? this.isActivePath
        ? this.deactivateMe()
        : this.activateMe()
      : null;
  }
}
