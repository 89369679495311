













import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, State, namespace } from 'vuex-class';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({
  components: {}
})
export default class SortableTableHeader extends Vue {
  @Prop(Boolean) public showArrow!: boolean;
  @Prop(String) public column!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';
}
