











import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { InvitationList } from './';
import { Pagination, User } from '@/store/modules/users/types/users.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedInvitationList extends mixins(PaginationMixin) {
  @Prop() public items!: User[];
  @Prop() public totalCount!: number;

  get InvitationList() {
    return InvitationList;
  }
}
