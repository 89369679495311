import {
  PendingCitationData,
  PendingDocument
} from '@/jbi-shared/types/document.types';
import {
  CplusDocumentType,
  PendingDocumentNotice,
  PendingSectionError,
  PendingSectionNotice
} from '@/jbi-shared/types/document.types';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { RootState } from '@/store/store';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { StagingEditorStateMixin } from './staging-editor-state.mixin';
import { PendingDocumentError } from '@/jbi-shared/types/document.types';
import { opsToText } from '@/utils/quill-delta.util';
import dayjs from 'dayjs';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import { Citation } from '@/store/modules/documents/types/citations.types';
import Delta from 'quill-delta';
import {
  CitationTypes,
  PlainCitationFields
} from '@/jbi-shared/types/citation.fields';
import Cite from 'citation-js';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  doiToDoiLink,
  getDatePartsFromIssueDate
} from '@/jbi-shared/util/citation.util';
import {
  filter as fp_filter,
  flatten as fp_flatten,
  map as fp_map,
  pipe as fp_pipe
} from 'lodash/fp';
import { QuillCitationInsertionPayload } from '@/store/modules/documents/types/quill.types';
import { validateExistingCriterionLinkedBpr } from '@/jbi-shared/util/cplus-criterion.util';
import Op from 'quill-delta/dist/Op';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { PendingCriterionData } from '@/jbi-shared/types/criterions.types';
dayjs.extend(isSameOrBefore);

@Component({})
export class StagingEditorComputedValuesMixin extends mixins(
  StagingEditorStateMixin
) {
  get citationsOfProject() {
    return (this.$store.state as RootState).projects.citationsOfProject;
  }

  get citationsOfRevision() {
    return (this.$store.state as RootState).documents.citationsOfRevision || [];
  }

  get pendingDocumentRevisionId() {
    return this.existingDocumentDetail?.revision.id || 0;
  }

  get newlyCreatedCitation() {
    return (this.$store.state as RootState).projects.createdCitation;
  }

  get allCitations(): PendingCitationData[] {
    if (this.citationsOfProject?.citations) {
      const citationsOfProject: PendingCitationData[] = this.citationsOfProject?.citations.map(
        (citation) => {
          return {
            id: citation.id.toString(),
            content: { ...citation.content }
          };
        }
      );
      return this.dirtyCitations.concat(citationsOfProject);
    } else {
      return this.dirtyCitations;
    }
  }

  get updatedCitations(): PendingCitationData[] {
    if (this.activeCitations) {
      const completeCitations = this.activeCitations.concat(
        this.archivedCitations
      );
      const citationsOfRevision:
        | PendingCitationData[]
        | undefined = this.citationsOfRevision.map((citation) => {
        return {
          id: citation.id.toString(),
          content: { ...citation.content }
        };
      });
      const updatedCitations = citationsOfRevision
        ? this.dirtyCitations.concat(citationsOfRevision)
        : this.dirtyCitations;
      const updatedCitationIds: string[] = completeCitations.map((citation) =>
        citation.id.toString()
      );
      updatedCitations.map((citation) => {
        if (!updatedCitationIds.includes(citation.id.toString())) {
          completeCitations.push(citation);
        }
      });
      return completeCitations;
    } else {
      return this.dirtyCitations;
    }
  }

  get transparentImg(): Element {
    const transparentImg = new Image();
    transparentImg.src = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==`; // tslint:disable-line
    return transparentImg;
  }

  get documentDetailLoaded() {
    return this.documentDetail;
  }

  get documentDetail() {
    return (this.$store.state as RootState).admin.pendingDocument;
  }

  get existingDocumentDetail() {
    return (this.$store.state as RootState).admin.existingDocumentDetail;
  }

  get pendingDocuments() {
    const pendingProject = (this.$store.state as RootState).admin
      .pendingProjectDetails;
    return pendingProject?.pendingDocuments || [];
  }

  get pendingProject() {
    return (this.$store.state as RootState).admin.pendingProjectDetails;
  }

  get pendingProjectExistingProjectDocuments() {
    const pendingProject = (this.$store.state as RootState).admin
      .pendingProjectDetails;
    return pendingProject?.projectDocuments || [];
  }

  public isTemplateRP(doc: PendingDocument) {
    if (!doc) {
      return false;
    }
    return !!!doc.documentId;
  }

  get isEs() {
    return this.documentDetail?.type === CplusDocumentType.EvidenceSummary;
  }

  get isRp() {
    return this.documentDetail?.type === CplusDocumentType.RecommendedPractice;
  }

  get authorSectionId() {
    return 0;
  }

  get bprSectionId() {
    return 0;
  }

  get criterionSectionId() {
    return 0;
  }

  get ohsAssets() {
    return (this.$store.state as RootState).documents.ohsAssets;
  }

  get references(): Op[] {
    const citationIds: string[] = this.dirtyReferences?.citationIds || [];
    const sectionValue: Op[] = [];

    citationIds
      .map((id) => {
        if (id === '') {
          // Empty reference
          return {
            id: '',
            content: {
              plainText: '',
              type: CitationTypes.PLAIN
            }
          };
        } else {
          return this.updatedCitations.find(
            (citation) => id.toString() === citation.id?.toString()
          );
        }
      })
      .filter(Boolean)
      // @ts-ignore
      .forEach((citation: Citation) => {
        const rawCitation = _cloneDeep(citation);
        if (rawCitation.content.type === CitationTypes.PLAIN) {
          sectionValue.push({
            insert: `${(rawCitation.content as PlainCitationFields).plainText.trim()}`
          });
          return;
        }
        // @ts-ignore
        const rawCitationObject = new Cite(rawCitation.content);

        // Special Fix for Vancouver format to display date, and support multiple types of formats
        rawCitationObject.data[0].issued = getDatePartsFromIssueDate(
          rawCitationObject?.data[0]?.issued
        );

        const formattedCitation = rawCitationObject.format('bibliography', {
          template: 'vancouver'
        });
        // handle DOI
        // DOI usage reference :
        // https://library.uic.edu/help/article/1966/what-is-a-doi-and-how-do-i-use-them-in-citations
        const doi: string | undefined = rawCitation.content.DOI
          ? rawCitation.content.DOI
          : undefined;
        const link = doiToDoiLink(doi);
        sectionValue.push({
          insert: `${formattedCitation.trim().substr(3)}`,
          attributes: { link }
        });
      });
    return new Delta(sectionValue).ops;
  }

  get opsToText() {
    return opsToText;
  }

  get originalTagMaps(): DirtyTagMap[] {
    return this.documentDetail?.content.tags || [];
  }

  /*
   * Business rules require that the search date should not be:
   * - an invalid format/value
   * - same as or before the previous existing document's search date
   * - a later date than the uploaded date.
   */
  get isInvalidSearchDate(): boolean {
    const pendingSearchDate = dayjs(this.dirtySearchDate);
    const existingSearchDate = dayjs(this.existingDocumentSearchDate);
    return (
      !pendingSearchDate.isValid() ||
      pendingSearchDate.isSameOrBefore(existingSearchDate) ||
      pendingSearchDate.isAfter(dayjs(this.documentDetail?.uploadedAt))
    );
  }

  /*
   * We automatically re-use the previous version's bpr if it matches
   * the uploaded pending document bpr value.
   *
   * This returns a list of the remaining 'newly added' bpr(s) that
   * previously did not exist in the previous version's bpr list.
   */
  get newlyAddedBprs() {
    return this.dirtyBprs.filter(
      (bpr) =>
        !this.existingDocumentBprs
          .map((existingBpr) => JSON.stringify(existingBpr))
          .includes(JSON.stringify(bpr))
    );
  }

  /*
   * Returns a list of criterions with missing linked bpr(s).
   */
  get missingLinkedBprCriterions() {
    return this.dirtyCriterions.filter((criterion) => {
      return (
        criterion.isValid &&
        !validateExistingCriterionLinkedBpr(
          criterion as FullDocumentRevisionObject['revision']['sections']['criterionSection'][0],
          this.dirtyBprs
        )
      );
    });
  }

  get validCriterions(): PendingCriterionData[] {
    return this.dirtyCriterions.filter((criterion) => criterion.isValid);
  }

  get invalidCriterions(): PendingCriterionData[] {
    return this.dirtyCriterions.filter((criterion) => !criterion.isValid);
  }

  /*
   * We automatically re-use the previous version's criterion if it matches
   * the uploaded pending document criterion title AND linked BPR.
   *
   * This returns a list of the remaining 'newly added' criterion(s) that
   * previously did not exist in the previous version's criterion list.
   */
  get newlyAddedCriterions() {
    return this.dirtyCriterions.filter((criterion) => {
      const { isValid, ...criterionData } = criterion;
      return !this.existingDocumentCriterions
        .map((existingCriterion) => JSON.stringify(existingCriterion))
        .includes(JSON.stringify(criterionData));
    });
  }

  /*
   * Compilation of all section's errors and notices in document
   */
  get documentErrors() {
    const errors: PendingDocumentError[] = [];
    const notices: PendingDocumentNotice[] = [];

    /* Iterate all the dirty sections error */
    if (this.isInvalidSearchDate) {
      errors.push({
        code: PendingSectionError.SECTION_INVALID_DATE,
        message: 'Invalid Search Date',
        sectionId: 'searchDate'
      });
    }

    if (this.dirtyAuthors) {
      const invalidAuthors = this.dirtyAuthors.filter(
        (author) => !author.isValid
      );
      if (invalidAuthors.length > 0) {
        errors.push({
          code: PendingSectionError.SECTION_MISSING_INFO,
          message: `Missing information in Author`,
          sectionId: 'author'
        });
      }
    }

    if (this.dirtyTextSections) {
      this.dirtyTextSections.forEach((textSection) => {
        if (!textSection.isValid) {
          textSection.errors?.map((error) => {
            errors.push({
              ...error,
              sectionId:
                textSection.content.documentSectionId ||
                textSection.content.tempId
            });
          });
        }
        textSection.notices?.map((notice) => {
          notices.push({
            ...notice,
            sectionId:
              textSection.content.documentSectionId ||
              textSection.content.tempId
          });
        });
      });
    }

    if (this.dirtyBprs) {
      if (this.newlyAddedBprs.length > 0) {
        notices.push({
          code: PendingSectionNotice.NEW_BPR_FOUND,
          message: `New Best Practice Recommendation(s) found.`,
          sectionId: `bprSection`
        });
      }
    }

    if (this.dirtyCriterions) {
      if (this.invalidCriterions.length > 0) {
        errors.push({
          code: PendingSectionError.AUDIT_CRITERIA_INCOMPLETE,
          message: `Incomplete Audit Criteria(s) found.`,
          sectionId: `criterionSection`
        });
      }
      if (this.missingLinkedBprCriterions.length > 0) {
        errors.push({
          code: PendingSectionError.AUDIT_CRITERIA_MISSING_LINKED_BPR,
          message: `Audit Criteria(s) with missing linked best practice recommendation(s) found.`,
          sectionId: `criterionSection`
        });
      }
      if (this.newlyAddedCriterions.length > 0) {
        notices.push({
          code: PendingSectionNotice.NEW_CRITERIA_FOUND,
          message: `New Audit Criteria(s) found.`,
          sectionId: `criterionSection`
        });
      }
    }

    if (this.dirtyReferences) {
      for (const reference of this.references) {
        if (!reference.insert) {
          errors.push({
            code: PendingSectionError.SECTION_MISSING_INFO,
            message: `Missing information in References`,
            sectionId: `reference`
          });
          break;
        }
      }
    }

    if (this.dirtyRelatedDocs) {
      const relatedDocsTitle = this.isRp
        ? 'Supporting Evidence Summaries'
        : 'Related JBI Evidence Summaries';
      for (const i in this.dirtyRelatedDocs) {
        if (!this.dirtyRelatedDocs[i].relatedDocs.id) {
          errors.push({
            code: PendingSectionError.SECTION_MISSING_INFO,
            message: `Missing information in ${relatedDocsTitle}`,
            sectionId: 'relatedDoc'
          });
          break;
        }
      }
    }

    return { errors, notices };
  }

  public getExistingCplusDocumentFromPendingDocument(doc: PendingDocument) {
    if (!this.isTemplateRP(doc)) {
      return this.pendingProjectExistingProjectDocuments.find(
        (pendingDocument: any) => pendingDocument.id === doc.documentId
      );
    }
  }

  /*
   * Existing C+ document data
   */
  get existingDocumentPublishedAt() {
    return this.existingDocumentDetail?.revision?.publishedAt;
  }

  get existingDocumentPublishedAtTimezone() {
    return this.existingDocumentDetail?.revision?.publishedAtTimezone;
  }

  get existingDocumentAuthors(): FullDocumentRevisionObject['revision']['sections']['authorSubSections'] {
    return (
      this.existingDocumentDetail?.revision.sections?.authorSubSections || []
    );
  }
  get existingDocumentCriterions(): FullDocumentRevisionObject['revision']['sections']['criterionSection'] {
    return (
      this.existingDocumentDetail?.revision.sections?.criterionSection || []
    );
  }
  get existingDocumentBprs(): FullDocumentRevisionObject['revision']['sections']['bprSection'] {
    return this.existingDocumentDetail?.revision.sections?.bprSection || [];
  }
  get existingDocumentSearchDate() {
    return this.existingDocumentDetail?.revision.searchDate;
  }

  get usedCitationIds(): string[] {
    return fp_pipe(
      // don't check deleted
      fp_filter(({ deleted }: TextSection) => !deleted),
      fp_map('sectionValue'),
      fp_flatten,
      // check quill Delta content
      fp_map(
        // @ts-ignore
        (op) => op?.insert?.citation as QuillCitationInsertionPayload
      ),
      fp_filter(Boolean),
      // get citationId from Quill Delta content
      fp_map('citationId'),
      fp_map(String)
    )(this.dirtyTextSections.map((textSection) => textSection.content));
  }

  get uniqUsedCitationIds(): string[] {
    return [...new Set(this.usedCitationIds)];
  }
}
