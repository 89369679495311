












































import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { AddNoteToProjectRequestPayload } from '@/store/modules/projects/types/projects.types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import dayjs from 'dayjs';
import { ChangeDueDateRequestPayload } from '@/store/modules/admin/types/admin.types';
import { useUserEmail } from '@/utils/user.util';

@Component({})
export default class ChangeDueDateDialog extends Vue {
  @Prop()
  public project!: ProjectListItemParam;

  @Action('admin/changeDueDate')
  public changeDueDate!: (p: ChangeDueDateRequestPayload) => void;

  public dueDate = this.project.dueDate
    ? new Date(this.project.dueDate)
    : new Date();

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public async handleChangeDueDate(modal: any) {
    if (this.userEmail) {
      await this.changeDueDate({
        projectId: this.project.projectId,
        dueDate: this.dateToIsoDate(this.dueDate),
        adminEmail: this.userEmail
      });
    }

    modal.close();
  }

  public handleDateSelection(date: Date) {
    this.dueDate = date;
  }

  public dateToIsoDate(d: Date) {
    return dayjs(d).format().split('T').shift()!;
  }

  public dateToDisplayedDate(d: Date) {
    return dayjs(d).format('DD MMM YYYY');
  }
}
