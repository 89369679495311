import { DocumentsState } from './types/documents.types';
import { defaultApiState } from '@/store/types/general.types';

export const documentsState: DocumentsState = {
  apiState: {
    getDocumentDetail: defaultApiState,
    updateDocument: defaultApiState,
    deleteDocument: defaultApiState,
    getDocumentByRevisionId: defaultApiState,
    getDocumentByVersionId: defaultApiState,
    getDocumentByPublicationId: defaultApiState,
    updateRevisionName: defaultApiState,
    removeRevisionCitation: defaultApiState,
    getRevisionCitations: defaultApiState,
    exportSearchResult: defaultApiState,
    exportDocument: defaultApiState,
    restoreRevision: defaultApiState,
    getRevisionsByDocumentId: defaultApiState,
    getAllLegacyDocVersionsByBaseDocumentId: defaultApiState,
    getLegacyContentByBaseDocumentId: defaultApiState,
    searchDocument: defaultApiState,
    searchRelatedDocuments: defaultApiState,
    searchNewRelatedDocuments: defaultApiState,
    searchEsForRpRealignment: defaultApiState,
    updatePublishStatus: defaultApiState,
    getUserDocuments: defaultApiState,
    getUserCreatedDocuments: defaultApiState,
    getUserAssignedDocuments: defaultApiState,
    exportWkResource: defaultApiState,
    getWkExportStatus: defaultApiState,
    getWkExportDetails: defaultApiState,
    getAllWkExports: defaultApiState,
    inviteDocumentUserAsParticipant: defaultApiState,
    updateDocumentParticipant: defaultApiState,
    deleteDocumentParticipant: defaultApiState,
    searchKeywordTag: defaultApiState,
    getMeshTree: defaultApiState,
    getRdfMeshTags: defaultApiState,
    documentStatusAPIState: defaultApiState,
    updateDocStatus: defaultApiState,
    updateDocAssignee: defaultApiState,
    getDocumentParticipants: defaultApiState,
    getMyAssignedDocuments: defaultApiState,
    getOhsAssets: defaultApiState,
    getAlignedEsByEsId: defaultApiState
  },
  documents: undefined,
  createdDocument: undefined,
  documentDetail: undefined,
  documentByRevisionId: undefined,
  documentByVersionId: undefined,
  documentByPublicationId: undefined,
  revisionsByDocumentId: undefined,
  allLegacyDocVersionsByBaseDocumentId: undefined,
  legacyContentByBaseDocumentId: undefined,
  documentSearchResult: undefined,
  relatedDocumentSearchResult: undefined,
  esForAlignmentSearchResult: undefined,
  userDocuments: undefined,
  userCreatedDocuments: undefined,
  userAssignedDocuments: undefined,
  participantRoles: undefined,
  invitedDocumentUser: undefined,
  keywordTags: undefined,
  meshTree: undefined,
  rdfMeshTags: undefined,
  documentParticipants: undefined,
  myAssignedDocuments: undefined,
  ohsAssets: undefined,
  allWkResourceExports: undefined,
  alignedEsByEsId: undefined,
  citationsOfRevision: undefined
};
