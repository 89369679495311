import { FullDocumentRevisionObject } from './full-document-revision-object.types';
import { CplusDocumentType } from './document.types';

export interface LegacyDocumentObject {
  Node: string;
  BaseDocumentId: number;
  BaseDocumentTypeId: number;
  GlobalDocumentId: number;
  OrganisationId: null;
  ContentsModified: string;
  ContentsModifiedSize: string;
  Author: string;
  Version: number;
  Revision: number;
  SourceDocumentId: number | null;
  HistoricalId: null;
  PreviousDocumentId: null;
  BaseStatusId: number;
  IsMembersOnly: boolean;
  OrganisationFilterType: string;
  SubDomainFilterType: string;
  DocumentTemplateId: number;
  PublicDate: string;
  IsActive: boolean;
  Concurrency: string;
  DateCreated: string;
  DateUpdated: string;
  CreatedBy: string;
  UpdatedBy: string;
  LocalisedDocumentId: number;
  Culture: string;
  SourceFileType: 'pdf' | '' | null;
  // base64 string
  SourceFile?: string;
  SourceLinkInfo: null;
  TranslationStatusId: number;
  LocalisedIsActive: boolean;
  LocalisedConcurrency: string;
  LocalisedDateCreated: string;
  LocalisedDateUpdated: string;
  LocalisedCreatedBy: string;
  LocalisedUpdatedBy: string;
  Keywords: string;
  IsCopyOfPreviousVersion: boolean;
  ReviewStatusId: null;
  Issue: number;
  Volume: number;
  Pages: string;
  // BaseOhsGraphicIds will be a JSON string like "[1,2,3,4,5,8,10,11]"
  // (because I don't know how to parse string as JSON in MSSQL server)
  BaseOhsGraphicIds: null | string;
  // ; separated string. E.g. "Aged;Depression;Nursing Homes;Suicide"
  MeshKeywords?: string;
}

export interface ConvertedLegacyDocumentPayload {
  originalDocument: LegacyDocumentObject;
  baseDocumentId: number;
  convertedDocument: FullDocumentRevisionObject;
  baseDocumentTypeId: number;
  sourceEvidenceSummaryDocumentId?: number;
  // for BPIS
}

export interface RelatedDataForLegacyContentConversion {
  citations?: FullDocumentRevisionObject['project']['citations'];
  isLatestLegacyVersion?: boolean;
  primaryResearchNode?: FullDocumentRevisionObject['project']['primaryResearchNode'];
  researchNodes?: FullDocumentRevisionObject['project']['researchNodes'];
  fixedReferenceNumbers?: Array<{ from: number; to: number }>;
  referenceSectionText?: string;
  // for BPIS
  resourceStorageUri?: string;
}

export interface OptionsForLegacyConversion {
  [s: string]: any;
}

export interface MarkLegacyDocAsMigratedJobPayload {
  baseDocumentId: number;
  migratedToDocumentId: number;
  migratedAt: Date;
}

export enum LegacyDocumentPreMigrationErrorType {
  is_not_latest = 'is_not_latest',
  already_migrated = 'already_migrated',
  incomplete_data = 'incomplete_data',
  pending_source_es_migration = 'pending_source_es_migration',
}

export interface LegacyDocumentPreMigrationStatus {
  migratable: boolean;
  error_type?: LegacyDocumentPreMigrationErrorType;
  sourceEvidenceSummaryDocumentMigrated?: boolean;
  sourceEvidenceSummaryDocuments?: ConvertedLegacyDocumentPayload[];
  sourceEvidenceSummaryDocumentMigratedToDocumentId?: number;
  latestId?: number;
  migratedToProjectId?: number;
  migratedToDocumentId?: number;
}

export const LegacyBaseDocumentTypeId = {
  [CplusDocumentType.EvidenceSummary]: 1,
  [CplusDocumentType.RecommendedPractice]: 2,
  [CplusDocumentType.BestPracticeInformationSheet]: 3,
  [CplusDocumentType.SystematicReview]: 4,
  [CplusDocumentType.SystematicReviewProtocol]: 14,
};

export const LegacyBaseDocumentStatus = {
  1: 'Draft',
  2: 'Pending Approval',
  3: 'Approved',
  4: 'Pending Archival',
  5: 'Archived',
  6: 'In Progress',
  7: 'Completed',
  8: 'Abandoned',
};

/*
new OHS ID
1	Back Care
2	Clinical Competency
3	Attention! Cytotoxic Chemotheraphy
4	Attention! Electricity
5	Medication
6	Patient Education
7	Radiation Hazard
8	Resident Education
9	Safety Testing
10	Attention! Procedure or Area
11	Standard Precautions
12	Sharp Instruments
13	Hand Washing

legacy OHS ID
1 Patient Education
2 Wash Your Hands
3 Standard Precautions
4 Take Care With Sharps
5 Clinical Competency
6 Back Care Manual Handling
7 Spills Are Hazardous !
8 Maintain Electrical Safety !
9 Cytotoxic Chemotherapy
10 Radiation Hazard
11 Resident Education
12 Safety Testing
13 Medication Competent
 */
export const legacyOhsIdAndNewOhsId = {
  'Back Care': { legacyId: 6, newId: 1 },
  'Clinical Competency': { legacyId: 5, newId: 2 },
  'Attention! Cytotoxic Chemotheraphy': { legacyId: 9, newId: 3 },
  'Attention! Electricity': { legacyId: 8, newId: 4 },
  // prettier-ignore
  'Medication': { legacyId: 13, newId: 5 },
  'Patient Education': { legacyId: 1, newId: 6 },
  'Radiation Hazard': { legacyId: 10, newId: 7 },
  'Resident Education': { legacyId: 11, newId: 8 },
  'Safety Testing': { legacyId: 12, newId: 9 },
  'Attention! Procedure or Area': { legacyId: 7, newId: 10 },
  'Standard Precautions': { legacyId: 3, newId: 11 },
  'Sharp Instruments': { legacyId: 4, newId: 12 },
  'Hand Washing': { legacyId: 2, newId: 13 },
};
