export enum InviteStatus {
  Accepted = 'Accepted',
  Expired = 'Expired',
  Pending = 'Pending',
}

// Ordering matters as it affects the sorting order
export enum ProjectStatus {
  Default = 'Default',
  InProgress = 'In Progress',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Unassigned = 'Unassigned',
}
