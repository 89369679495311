import { Throttle } from '@/jbi-shared/util/throttle.vue-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  hasBprContentChanged,
  hasPendingTextSectionChanged
} from '@/utils/quill-delta.util';
import { isEqual as _isEqual } from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { StagingEditorComputedValuesMixin } from './staging-editor-computed-values.mixin';
import { StagingEditorStateMixin } from './staging-editor-state.mixin';
import { tagMapToUniqueKey } from '@/jbi-shared/util/document.utils';
import { uniq as _uniq } from 'lodash';
import { TagMap } from '../../../jbi-shared/types/document.types';

const state = Vue.observable({
  authorsHasChanged: false,
  bprHasChanged: false,
  criterionHasChanged: false,
  textHasChanged: false,
  searchDateHasChanged: false,
  relatedDocHasChanged: false,
  citationHasChanged: false,
  tagsHasChanged: false,
  tdrHasChanged: false
});

@Component({})
export class StagingEditorChangeDetectorMixin extends mixins(
  StagingEditorStateMixin,
  StagingEditorComputedValuesMixin
) {
  get contentHasChanged(): boolean {
    // TODO: add more hasChange states and watchers for each section
    return (
      state.authorsHasChanged ||
      state.bprHasChanged ||
      state.criterionHasChanged ||
      state.textHasChanged ||
      state.searchDateHasChanged ||
      state.relatedDocHasChanged ||
      state.tagsHasChanged ||
      state.citationHasChanged ||
      state.tdrHasChanged
    );
  }

  get canPublish(): boolean {
    return !this.contentHasChanged && this.documentErrors.errors.length === 0;
  }

  @isDifferent
  @isTruthy
  @Watch('tdrUri')
  @Watch('documentDetail')
  @Throttle(300)
  public onTdrChange() {
    state.tdrHasChanged = this.tdrUri !== this.documentDetail?.content.tdrUri;
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyTagMaps')
  @Throttle(300)
  public onTMChanged() {
    let a = this.dirtyTagMaps!.map((tm) => tagMapToUniqueKey(tm)).sort();
    a = _uniq(a);
    let b = ((this.documentDetail?.content.tags || []) as TagMap[])
      .map((tm) => tagMapToUniqueKey(tm))
      .sort();
    b = _uniq(b);
    state.tagsHasChanged = JSON.stringify(a) !== JSON.stringify(b);
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyAuthors')
  @Throttle(300)
  public onAChanged() {
    const a = this.dirtyAuthors;
    const b = this.documentDetail?.content.authorSection || [];
    state.authorsHasChanged =
      a.length !== b.length ||
      !_isEqual(JSON.parse(JSON.stringify(a)), JSON.parse(JSON.stringify(b)));
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyBprs')
  @Throttle(300)
  public onBprChanged() {
    const newBprs = this.dirtyBprs;
    const oldBprs = this.documentDetail?.content.bprSection || [];
    state.bprHasChanged = hasBprContentChanged([newBprs, oldBprs]);
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyCriterions')
  @Throttle(300)
  public onCChanged() {
    const newCriterions = this.dirtyCriterions;
    const oldCriterions = this.documentDetail?.content.criterionSection || [];
    state.criterionHasChanged =
      newCriterions.length !== oldCriterions.length ||
      !_isEqual(
        JSON.parse(JSON.stringify(newCriterions)),
        JSON.parse(JSON.stringify(oldCriterions))
      );
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyTextSections')
  @Throttle(300)
  public onTextChanged() {
    const currentText = this.dirtyTextSections;
    const savedText = this.documentDetail?.content.textSections || [];
    state.textHasChanged = hasPendingTextSectionChanged(currentText, savedText);
  }

  @isDifferent
  @Watch('dirtySearchDate')
  @Watch('documentDetail')
  @Throttle(300)
  public onSearchDateChanged() {
    const newDate = this.dirtySearchDate;
    const oldDate = this.documentDetail?.content.searchDate;
    state.searchDateHasChanged = newDate !== oldDate;
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyRelatedDocs')
  @Throttle(300)
  public onRelatedDocChanged() {
    const currentRelatedDoc = this.dirtyRelatedDocs;
    const savedRelatedDoc =
      this.documentDetail?.content.relatedDocSection || [];
    state.relatedDocHasChanged =
      currentRelatedDoc.length !== savedRelatedDoc.length ||
      JSON.stringify(currentRelatedDoc) !== JSON.stringify(savedRelatedDoc);
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyCitations')
  @Throttle(300)
  public onCitationChanged() {
    const currentCitation = this.dirtyCitations;
    const savedCitation = this.documentDetail?.content.citations || [];
    state.citationHasChanged = !_isEqual(currentCitation, savedCitation);
  }
}
