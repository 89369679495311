<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title is-1">403</h1>
        <h2 class="subtitle is-2">Unauthorized Access</h2>
      </div>
    </div>
  </section>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class Unauthorized extends Vue {}
</script>
