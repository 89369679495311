

















































































import { uniq, cloneDeep as _cloneDeep } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DropdownMultiselect extends Vue {
  private selectedValues: string[] = [];
  @Prop(Array)
  private options!: string[];
  @Prop()
  private firstOptionText!: string;
  @Prop()
  private keyword!: string;
  @Prop(String)
  private position!: 'is-top-right' | 'is-top-left' | 'is-bottom-left';
  @Prop(Array)
  private preSelectedData!: any[];

  private isAllOptionSelected: boolean = true;
  private allOptionIds: any[] = [];

  private mounted() {
    if (this.preSelectedData) {
      this.populateFilteredData();
    } else {
      this.populateData();
    }
  }

  private populateFilteredData() {
    this.options.map((option: any) => {
      this.allOptionIds.push(option.id);
    });
    this.selectedValues = _cloneDeep(this.preSelectedData);
    if (this.allOptionIds.length !== this.selectedValues.length) {
      this.isAllOptionSelected = false;
    }
  }

  private populateData() {
    this.options.map((option: any) => {
      this.selectedValues.push(option.id);
    });
    this.allOptionIds = _cloneDeep(this.selectedValues);
  }

  private updateSelection(label: boolean = false, id: string) {
    if (label) {
      const selectionIndex = this.selectedValues.indexOf(id);
      if (selectionIndex === -1) {
        this.selectedValues.push(id);
      } else {
        this.selectedValues.splice(selectionIndex, 1);
      }
    }
    this.isAllOptionSelected =
      this.allOptionIds.length === this.selectedValues.length;
    this.emitSelectedResult();
  }

  private updateAllSelection(label: boolean = false) {
    if (label) {
      this.isAllOptionSelected = !this.isAllOptionSelected;
    }
    if (this.isAllOptionSelected) {
      this.selectedValues = _cloneDeep(this.allOptionIds);
    } else {
      this.selectedValues = [];
    }
    this.emitSelectedResult();
  }

  private emitSelectedResult() {
    this.$emit('applyMultiSelectFilter', [...this.selectedValues]);
  }
}
