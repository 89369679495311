import { useRoute } from './route.util';
import Vue from 'vue';
import { DocumentIndexingPayload } from '../jbi-shared/types/search.types';
import { startCase } from 'lodash';

export const useCurrentDocumentId = function (this: Vue) {
  return +useRoute.call(this).params.documentId;
};

export const useCurrentProjectId = function (this: Vue) {
  return +useRoute.call(this).params.projectId;
};

// TODO: not sure if this is still needed
export const getCollaborators = (project: DocumentIndexingPayload) => {
  return [];
  // const owner = project.documentOwner.split(' ');
  // const collaborators = project.collaborators.map(collaborator => {
  //   if (!collaborator) {
  //     return;
  //   }
  //   const nameArr = collaborator.split(' ');
  //   return (
  //     nameArr[0].substr(0, 1).toUpperCase() +
  //     nameArr[1].substr(0, 1).toUpperCase()
  //   );
  // });
  // collaborators.push(
  //   owner[0].substr(0, 1).toUpperCase() + owner[1].substr(0, 1).toUpperCase(),
  // );
  // return collaborators as string[];
};

export const getOwner = (item: DocumentIndexingPayload) => {
  return startCase(item.documentOwner).trim();
};
