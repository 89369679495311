export interface BookFields {
  type: string;
  author: any[];
  title: string;
  edition?: string;
  publisherPlace?: string;
  publisher?: string;
  issued?: string;
  numberOfPages?: string;
  seriesEditor?: string;
}

export interface JournalArticleFields {
  type: string;
  author: any[];
  title: string;
  'container-title'?: string;
  issued?: string;
  volume?: string;
  issue?: string;
  page?: string;
  DOI?: string;
}

export interface GenericFields {
  type: string;
  author: any[];
  title: string;
  edition?: string;
  publisherPlace?: string;
  publisher?: string;
  issued?: string;
  pages?: string;
  accessed?: string;
  url?: string;
}

export interface BookSectionFields {
  type: string;
  author: any[];
  title: string;
  edition?: string;
  publisherPlace?: string;
  publisher?: string;
  issued?: string;
  page?: string;
  bookSectionTitle?: string;
  editors?: string;
  volume?: string;
}

export interface ConferencePaperFields {
  type: string;
  author: any[];
  title: string;
  publisherPlace?: string;
  publisherName?: string;
  issued?: string;
  page?: string;
  editors?: string;
  eventName?: string;
  eventPlace?: string;
}

export interface ReportFields {
  type: string;
  author: any[];
  title: string;
  genre?: string;
  publisherPlace?: string;
  publisher?: string;
  issued?: string;
  number?: string;
  documentNumber?: string;
  reportNumber?: string;
}

export interface WebpageFields {
  type: string;
  author: any[];
  title: string;
  typeOfMedium?: string;
  publisherPlace?: string;
  publisher?: string;
  issued?: string;
  accessed?: string;
  edition?: string;
  note?: string;
  url?: string;
}

export interface PlainCitationFields {
  type: string;
  plainText: string;
}

export enum CitationTypes {
  BOOK = 'book',
  GENERIC = 'Generic',
  BOOK_SECTION = 'chapter',
  CONFERENCE_PAPER = 'paper-conference',
  JOURNAL_ARTICLE = 'article-journal',
  REPORT = 'report',
  WEBPAGE = 'webpage',
  PLAIN = 'plain',
}
