import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  get as _get,
  throttle as _throttle,
  includes as _includes
} from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy, isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { Throttle } from '@/jbi-shared/util/throttle.vue-decorator';
import {
  UpdateProjectRequestPayload,
  ProjectsApiState
} from '@/store/modules/projects/types/projects.types';
import { RootState } from '@/store/store';
import { Action, State, Getter } from 'vuex-class';
import { EditDocumentFormValues } from '@/components/form/editProjectForm.types';
import { InputOption } from '@/jbi-shared/types/form.types';

@Component({})
export class UpdateProjectMixin extends Vue {
  @Getter('documents/revisionId')
  public revisionId!: number;

  @Action('projects/updateProject')
  public updateProjectAction!: (payload: UpdateProjectRequestPayload) => void;

  @Action('documents/getDocumentDetail')
  public getDocumentDetail!: (id: number) => void;

  @State((state: RootState) => state.projects.apiState.updateProject.success)
  public updateProjectSuccess!: ProjectsApiState;

  @State((state: RootState) => state.projects.apiState.updateProject.error)
  public updateProjectError!: ProjectsApiState;

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  @Watch('updateProjectSuccess')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public onUpdateProjectSuccess() {
    this.getDocumentDetail(this.documentId);
    Toast.open({
      message: `Project Sucessfully Updated`,
      position: 'is-top',
      type: 'is-dark'
    });
  }

  @Watch('updateProjectError')
  @isDifferent
  @isTruthy
  @Throttle(500)
  public onUpdateProjectError() {
    Toast.open({
      message: `Fail to Update Document Setting. Please try again!`,
      position: 'is-top',
      type: 'is-danger'
    });
  }

  public editProject(values: EditDocumentFormValues & { projectId: number }) {
    const { title, primaryResearchNode, researchNodes, projectId } = values;

    this.updateProjectAction({
      projectId,
      projectTitle: title,
      primaryResearchNodeId: primaryResearchNode!.id as number,
      researchNodeIds: researchNodes.map(
        (node: InputOption) => node.id as number
      )
    });
  }
}
