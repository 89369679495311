var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs",class:_vm.$style.tabs},[_c('ul',_vm._l((_vm.documents),function(doc,i){return _c('li',{key:doc.id,class:[
        {
          'is-active': _vm.isActive(doc)
        },
        !_vm.multiple ? _vm.$style.singleTabStyle : ''
      ],attrs:{"data-test":"project-document-tab"},on:{"mouseover":function($event){_vm.hoveredItemIndex = i},"mouseleave":function($event){_vm.hoveredItemIndex = -1}}},[_c('router-link',{attrs:{"to":{
          name: 'staging-area',
          params: {
            documentId: doc.documentId || null,
            projectId: _vm.projectId
          }
        },"title":doc.tabDisplayTitle,"exact":""}},[_c('ProjectEditorHeaderTabTag',{attrs:{"active":_vm.hoveredItemIndex === i || _vm.isActive(doc)}},[_vm._v(" "+_vm._s(_vm.getDocTypeDisplayNameFromDoc(doc))+" ")]),_vm._v(" "+_vm._s(doc.tabDisplayTitle)+" ")],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }