import Vue, { PluginObject } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import { axios } from './api/axios';
import frag from 'vue-frag';
import Buefy from 'buefy';
import * as VueQuillEditor from 'vue-quill-editor';
import VeeValidate from 'vee-validate';
import Fragment from 'vue-fragment';
import VueRx from 'vue-rx';
import VueHeadful from 'vue-headful';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VueObserveVisibility from 'vue-observe-visibility';
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import dayjs from 'dayjs';

Vue.use(VueQuillEditor as any);
Vue.use(Fragment.Plugin);
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { getPageTitle } from './utils/page-title.util';
import { DATE_TIME_FORMAT, DATE_FORMAT } from './utils/date.util';
import { pluckNewValue } from './jbi-shared/util/pluck.rx-operator';

// for temporary staging's remote error logging. this account is under jacob@snappymob.com
const stagingDomain = 'connectplus-staging.jbi.global';
if (
  process.env.VUE_APP_FRONTEND_URL!.includes(stagingDomain) ||
  window.location.host.includes(stagingDomain) ||
  process.env.NODE_ENV === 'production'
) {
  Sentry.init({
    dsn: 'https://499df5fac0a44a5a9e5f7bd9fe3d6b71@sentry.io/1887905',
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
    ],
  });
}

Vue.config.productionTip = false;

Vue.prototype.JAAS_PUBLIC_URL = process.env.VUE_APP_JAAS_PUBLIC_URL;
Vue.prototype.BACKEND_HOSTNAME = process.env.VUE_APP_BACKEND_HOSTNAME;

// axios for C+ backend
axios.defaults.withCredentials = true;
axios.defaults.baseURL = Vue.prototype.BACKEND_HOSTNAME;
Vue.prototype.axios = axios;

// log for debugging
// tslint:disable-next-line
Vue.prototype.log = console.log;

// utilify for page title
Vue.prototype.getPageTitle = getPageTitle;

// utility for vue-rx
// tslint:disable-next-line
Vue.prototype.$reactiveValueObservable = function (s: string) {
  return this.$watchAsObservable(s, { immediate: true }).pipe(pluckNewValue());
};

Vue.directive('frag', frag);
Vue.use(Buefy);
Vue.use(VeeValidate);
Vue.use(VueRx);
Vue.component('VueHeadful', VueHeadful);
Vue.use(VueTextareaAutosize as PluginObject<any>);
Vue.use(VueObserveVisibility as PluginObject<any>);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(PortalVue);

Vue.filter('datetime', (value: string) => {
  return dayjs(value).format(DATE_TIME_FORMAT);
});
Vue.filter('date', (value: string) => {
  return dayjs(value).format(DATE_FORMAT);
});

export const vueInstance = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
