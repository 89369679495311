






















































import {
  DocumentExportType,
  DocumentDetail
} from '@/store/modules/documents/types/documents.types';
import { useGetter } from '@/utils/store.util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { useDocumentsState } from '@/utils/store.util';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';

interface BtnData {
  ext: string;
  label: string;
  callback: () => any;
  disabled?: boolean;
}

@Component({})
export default class DocumentActions extends Vue {
  @Prop(Boolean) public disabled!: boolean;
  @Prop(String) public lastEditedDate!: string;
  @Prop(String) public userName!: string | undefined;
  @Prop(Boolean) public contentHasChanged!: boolean;
  @Prop({ default: () => ['pdf', 'docx'] })
  public actions!: string[];

  get defaultActions() {
    return {
      pdf: {
        ext: '.pdf',
        label: 'Export as PDF',
        callback: () => this.$emit('export', 'pdf' as DocumentExportType)
      },
      docx: {
        ext: '.docx',
        label: 'Export as MS Word',
        callback: () => this.$emit('export', 'docx' as DocumentExportType)
      },
      replace: {
        ext: '',
        label: 'Replace document',
        callback: () => this.$emit('replace')
      }
    };
  }

  get documentDetail() {
    return useDocumentsState.call(this).documentDetail;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get exportBtns() {
    return this.actions.map((action) => {
      // @ts-ignore
      return this.defaultActions[action] as BtnData;
    });
  }

  public redirectToVersionHistory() {
    this.$router.push({
      name: 'document-versions-redirect',
      params: {
        documentId: this.$route.params.documentId,
        projectId: this.$route.params.projectId
      }
    });
  }
}
