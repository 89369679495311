import { setRedirectDestination } from './localstorage.util';
import { JAAS_OAUTH2_SCOPES } from '../jbi-shared/types/jaas-oauth2.types';

// prettier-ignore
const scope = `${JAAS_OAUTH2_SCOPES.openid} ${JAAS_OAUTH2_SCOPES.profile} ${
  JAAS_OAUTH2_SCOPES.email
} ${JAAS_OAUTH2_SCOPES.cplus_permissions}`;

// prettier-ignore
export const JAAS_LOGIN_URL =
  process.env.VUE_APP_JAAS_PUBLIC_URL +
  // tslint:disable-next-line
  `/dialog/authorize?response_type=code&client_id=${process.env.VUE_APP_JAAS_OAUTH2_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_FRONTEND_URL}/oauth2/callback&scope=${scope}`;

export const redirectToJaasLogin = async ({
  destination,
  loginUrl = JAAS_LOGIN_URL,
}: {
  destination: string;
  loginUrl?: string;
}) => {
  // exlcude home page
  if (destination !== '/') {
    await setRedirectDestination(destination);
  }
  window.location.href = loginUrl;
};
