import {
  FetchEditorOptionsResponsePayload,
  FetchEditorRolesResponsePayload
} from '@/jbi-shared/types/cplus-endpoints/editor.types';
import {
  FetchEditorOptionsRequestPayload,
  GetEditorsRequestPayload
} from '@/store/modules/editors/types/editors.types';
import { AxiosResponse } from 'axios';
import { axios } from './axios';

export const editorsApi = {
  async getEditors(params: GetEditorsRequestPayload) {
    return await axios.get(`editors`, { params });
  },
  async fetchEditorRoles(): Promise<
    AxiosResponse<FetchEditorRolesResponsePayload>
  > {
    return await axios.get<FetchEditorRolesResponsePayload>(`editors/roles`);
  },
  async fetchEditorOptions(
    params: FetchEditorOptionsRequestPayload
  ): Promise<AxiosResponse<FetchEditorOptionsResponsePayload>> {
    return await axios.get<FetchEditorOptionsResponsePayload>(
      `editors/options`,
      { params }
    );
  }
};
