





















import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import {
  SearchBox,
  InviteUserButton,
  PaginatedInvitationList
} from './components';
import {
  Pagination,
  GetUserPayload,
  FilteredUserPayload,
  User,
  FilteredUserQueryParam
} from '@/store/modules/users/types/users.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { ApiState } from '../../store/types/general.types';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import Container from '@/components/Container.vue';
import { initialState } from './components/filter-state.util';

@Component({
  components: {
    Container,
    DashboardHeader,
    SearchBox,
    InviteUserButton,
    PaginatedInvitationList
  }
})
export default class AdminUserManagement extends Vue {
  public page: number = 1;
  public perPage = 50;
  public filteredParams: FilteredUserPayload = { ...initialState };

  @Action('users/getUsers')
  public getUsers!: (params: GetUserPayload) => void;

  @State(({ users }: RootState) => users.users)
  public users!: Pagination<User>;

  @State(({ users }: RootState) => users.apiState.inviteNewUser)
  public inviteNewUserState!: ApiState;
  @State(({ users }: RootState) => users.apiState.updateStatus)
  public updateStatusState!: ApiState;
  @State(({ users }: RootState) => users.apiState.removeUserInvitation)
  public removeUserInvitationState!: ApiState;

  public dateToIsoDate(d: Date) {
    return dayjs(d).format().split('T').shift()!;
  }

  get filterQueryParams() {
    const searchDate = this.filteredParams.searchDate;
    let searchDateParam;
    if (searchDate && searchDate.length === 2) {
      searchDateParam = searchDate
        .map((date) => new Date(date))
        .map((date) => this.dateToIsoDate(date));
    }
    return {
      ...this.filteredParams,
      searchDate: searchDateParam
    };
  }

  public handleFilter(filterParam: Partial<FilteredUserPayload>) {
    this.filteredParams = {
      ...this.filteredParams,
      ...filterParam
    };
    this.debounceUpdate();
  }

  @Debounce(500)
  public debounceUpdate() {
    this.page = 1;
    this.updateRouteQuery();
  }

  get userPaginated() {
    return {
      items: _get(this.users, 'items', []),
      totalItems: _get(this.users, 'meta.totalItems', 0)
    };
  }

  public updateFilteredParams(filterParams: FilteredUserQueryParam) {
    const searchDate = filterParams.searchDate
      ? filterParams.searchDate.map((date) => new Date(date))
      : [];
    this.filteredParams = {
      ...filterParams,
      searchDate
    };
  }

  public updateRouteQuery() {
    this.$router.push({
      // @ts-ignore
      query: {
        ...this.filterQueryParams,
        page: String(this.page),
        perPage: String(this.perPage)
      }
    });
  }

  public updateUserList() {
    // Update the query parameters
    this.updateFilteredParams(this.filterQueryParams);

    // Fetch the updated user list
    this.getUsers({
      username: this.filteredParams.username || undefined,
      firstName: this.filteredParams.firstName || undefined,
      lastName: this.filteredParams.lastName || undefined,
      email: this.filteredParams.email || undefined,
      selectedStatus: this.filteredParams.selectedStatus || undefined,
      searchDate: this.filteredParams.searchDate
        ? this.filteredParams.searchDate.map((date) => this.dateToIsoDate(date))
        : undefined,
      limit: this.perPage,
      page: this.page
    });
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    const {
      page,
      perPage,
      username,
      firstName,
      lastName,
      email,
      selectedStatus,
      searchDate
    } = value;
    this.perPage = +perPage || 50;
    this.page = +page || 1;

    const filterParams = {
      username,
      firstName,
      lastName,
      email,
      selectedStatus,
      searchDate
    };
    this.updateFilteredParams(filterParams);

    this.getUsers({
      username: this.filteredParams.username || undefined,
      firstName: this.filteredParams.firstName || undefined,
      lastName: this.filteredParams.lastName || undefined,
      email: this.filteredParams.email || undefined,
      selectedStatus: this.filteredParams.selectedStatus || undefined,
      searchDate: this.filteredParams.searchDate
        ? this.filteredParams.searchDate.map((date) => this.dateToIsoDate(date))
        : undefined,
      limit: this.perPage,
      page: this.page
    });
  }

  @Watch('inviteNewUserState.success')
  @isDifferent
  @isTruthy
  public watchInviteUserSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `User invited successfully.`
    });
    this.updateUserList();
  }

  @Watch('inviteNewUserState.error')
  @isDifferent
  @isTruthy
  public watchInviteUserError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Create User Invitation. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }

  @Watch('updateStatusState.success')
  @isDifferent
  @isTruthy
  public updateStatusSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `User status successfully changed.`
    });
    this.updateUserList();
  }

  @Watch('updateStatusState.error')
  @isDifferent
  @isTruthy
  public updateStatusError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Update User Status. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
  }

  @Watch('removeUserInvitationState.success')
  @isDifferent
  @isTruthy
  public onRemoveInvitationSuccess() {
    this.page = 1;
    this.perPage = 50;
    this.updateRouteQuery();
  }
}
