



















import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  PendingDocumentError,
  PendingDocumentNotice
} from '@/jbi-shared/types/document.types';

@Component({ components: {} })
export default class SectionError extends Vue {
  @Prop({
    type: Array,
    default: () => []
  })
  public pendingErrors!: PendingDocumentError[];
  @Prop({
    type: Array,
    default: () => []
  })
  public pendingNotices!: PendingDocumentNotice[];
}
