import { PendingDocument } from '@/jbi-shared/types/document.types';
import {
  isDifferent,
  isDifferentDeep,
  isTruthy
} from '@/jbi-shared/util/watcher.vue-decorator';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { StagingEditorComputedValuesMixin } from './staging-editor-computed-values.mixin';
import { StagingEditorStateMixin } from './staging-editor-state.mixin';
import { DocumentDetail } from '@/store/modules/documents/types/documents.types';
import Op from 'quill-delta/dist/Op';
import { QuillCitationInsertionPayload } from '@/store/modules/documents/types/quill.types';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';

@Component({})
export class StagingEditorWatcherMixin extends mixins(
  StagingEditorStateMixin,
  StagingEditorComputedValuesMixin
) {
  public resetEditorContent(doc: PendingDocument) {
    // TODO: Implement more state update logic here as we build more sections
    // in the staging editor.
    this.dirtyAuthors = doc.content.authorSection || [];
    this.dirtyCriterions = doc.content.criterionSection || [];
    this.dirtyDocumentTitle = doc.content.documentTitle || '';
    this.dirtySearchDate = doc.content.searchDate || '';
    this.dirtyTextSections = doc.content.textSections || [];
    this.dirtyTagMaps = _cloneDeep(this.originalTagMaps);
    this.dirtyBprs = doc.content.bprSection || [];
    this.dirtyRelatedDocs = doc.content.relatedDocSection || [];
    this.dirtyReferences = doc.content.referenceSection;
    this.dirtyCitations = doc.content.citations || [];
    this.activeCitations =
      doc.content.activeCitations || doc.content.citations || [];
    this.archivedCitations = doc.content.archivedCitations || [];
    this.tdrUri = doc.content.tdrUri;
    this.updateCitationNumbers();
  }

  public setEditorExistingContent(doc: DocumentDetail) {
    this.dirtyOhs = doc.revision.sections.ohsSection?.ohsAssetIds || [];
  }

  @isDifferent
  @isTruthy
  @Watch('documentDetail')
  public onDocumentLoaded(doc: PendingDocument) {
    this.resetEditorContent(doc);
  }

  @isDifferent
  @isTruthy
  @Watch('existingDocumentDetail')
  public onExistingDocumentLoaded(doc: DocumentDetail) {
    this.setEditorExistingContent(doc);
  }

  @Watch('usedCitationIds')
  @Watch('documentDetail')
  @isDifferentDeep
  @isTruthy
  public onUsedCitationIdsChanged() {
    this.updateCitationNumbers();
    this.sortCitationReference();
  }

  public sortCitationReference() {
    const sortedCitation = [...new Set(this.usedCitationIds)];
    this.dirtyReferences = {
      ...this.dirtyReferences,
      citationIds: sortedCitation
    };
  }

  public updateCitationNumbers() {
    this.dirtyTextSections = this.dirtyTextSections.map((textSection) => {
      const updatedTextSection = JSON.parse(JSON.stringify(textSection));
      const { sectionValue } = textSection.content;
      updatedTextSection.content.sectionValue = this.handleCitationLabelInOps(
        sectionValue || [],
        this.uniqUsedCitationIds
      );
      return updatedTextSection;
    });
  }

  public handleCitationLabelInOps(
    ops: Op[],
    uniqUsedCitationIds: string[]
  ): Op[] {
    return ops!.map((op) => {
      let citation: QuillCitationInsertionPayload | undefined = _get(
        op,
        'insert.citation'
      );
      if (citation) {
        citation = {
          ...citation,
          label: String(
            uniqUsedCitationIds.findIndex(
              (id) => id === String(citation!.citationId)
            ) + 1
          )
        };
        return {
          ...op,
          insert: {
            citation
          }
        };
      }
      return op;
    });
  }
}
