import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import {
  isDifferent,
  isTruthy,
} from '../jbi-shared/util/watcher.vue-decorator';
import { isEqual } from 'lodash';
import { InputOption } from '../jbi-shared/types/form.types';

interface Form {
  researchNodes: InputOption[];
  primaryResearchNode: InputOption;
  documentType: InputOption;
}

@Component({})
export class DocumentFormMixin extends Vue {
  // typing
  public form!: Form | unknown;

  @Watch('form.researchNodes')
  @Watch('form.primaryResearchNode')
  @isDifferent
  @isTruthy
  public onDocumentFormResearchNodesChange() {
    const { primaryResearchNode, researchNodes } = this.form as Form;
    const hasDuplicates = researchNodes.some(n =>
      isEqual(n, primaryResearchNode),
    );

    if (hasDuplicates) {
      // remove secondary node that is the same as primary node
      // @ts-ignore
      this.form.researchNodes = this.form.researchNodes.filter(
        (node: InputOption) => !isEqual(node, primaryResearchNode),
      );
    }
  }
}
