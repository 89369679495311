import { OicPayload } from '@/jbi-shared/types/jaas-oauth2.types';
import { ApiState } from '@/store/types/general.types';

export interface UsersApiState {
  inviteNewUser: ApiState;
  getUsers: ApiState;
  getUserInvitations: ApiState;
  removeUserInvitation: ApiState;
  resendUserInvitation: ApiState;
  searchUsersByText: ApiState;
  getMyRoles: ApiState;
  acceptUserInvite: ApiState;
  verifyUserInvite: ApiState;
  updateStatus: ApiState;
}

export interface User {
  userId: number;
  username: string;
  name: string;
  firstName: string;
  lastName: string;
  invitationId: number;
  acceptedAt: Date;
  expiredAt: Date;
  email: string;
  blocked: boolean;
  status: string;
}

export interface Pagination<T> {
  items: T[];
  meta: {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: 2;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
}

export interface UsersState {
  oicPayload: OicPayload | undefined;
  apiState: UsersApiState;
  users: Pagination<User> | undefined;
  userInvitations: Invitation[] | undefined;
  searchUsersByTextResults: SingleUserSearchResult[] | undefined;
  myRoles: string[] | undefined;
  acceptUserInvite: AccceptSystemInvitationResponsePayload | undefined;
  verifyUserInvite: VerifyUserInviteResponse | undefined;
}

export interface Invitation {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  link: string;
  expiredAt: string;
  createdAt: string;
  acceptedAt: string;
}

export interface UserInvitationPayload {
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
}

export interface GetUserPayload {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  selectedStatus?: string;
  searchDate?: string[];
  page: number;
  limit: number;
}

export interface FilteredUserPayload {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  selectedStatus?: string;
  selectedType?: string;
  perPage?: number;
  page?: number;
  searchDate?: Date[];
}

export interface FilteredUserQueryParam {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  selectedStatus?: string;
  searchDate?: string[];
}

export interface UserDetail {
  blocked: boolean;
  createdAt: string;
  createdBy: null;
  email: string;
  emailVerified: boolean;
  id: number;
  passwordHash: string;
  updatedAt: string;
  updatedBy: null;
  userProfile: UserProfile;
  username: string;
}

export interface UserProfile {
  birthdate: null;
  country: null;
  createdAt: string;
  createdBy: null;
  firstName: string;
  gender: null;
  id: number;
  lastName: string;
  organisation: null;
  phoneNumber: null;
  researchgate: null;
  shortbio: null;
  source: null;
  specialty: null;
  updatedAt: string;
  updatedBy: null;
  username: string;
}

export interface SingleUserSearchResult {
  id: number;
  createdAt: string;
  updatedAt: string;
  email: string;
  username: string;
  fullName: string;
}

export interface AcceptUserInvite {
  status: string;
  message: string;
}

export interface VerifyUserInviteResponse {
  status: VerifyInvitationTokenStatus;
  description: VerifyInvitationDescription;
}

export interface AccceptSystemInvitationResponsePayload {
  status: VerifyInvitationTokenStatus;
  description: AcceptInvitationDescription;
}

export enum AcceptInvitationDescription {
  FAILED = 'Failed to accept invitation',
  SUCCESS = 'Invitation accepted successfully',
  INVITE_NOT_FOUND = 'Invitation not found'
}

export interface AcceptSystemInvitationRequestPayload {
  token: string;
}

export interface VerifyUserInvitePayload {
  email: string;
  token: string;
}

export enum VerifyInvitationTokenStatus {
  FAILED = 'failed',
  SUCCESS = 'success'
}

export enum VerifyInvitationDescription {
  INVALID_TOKEN = 'Token is invalid',
  EXPIRED_TOKEN = 'Token is expired',
  VALID_TOKEN = 'Token is valid'
}
