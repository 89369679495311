



































































































































import { Component, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { useUserName } from '@/utils/user.util';
import { useDocumentsState } from '@/utils/store.util';
import ExportBtn from '@/views/DocumentEditor/components/ProjectEditorHeaderDocExportBtn.vue';
import DocumentActions from '@/views/DocumentEditor/components/DocumentActions.vue';
import EditDocumentForm from '@/components/form/EditDocumentForm.vue';
import AssigneeInput from '@/views/DocumentEditor/components/AssigneeInput.vue';
import { DATE_TIME_FORMAT } from '@/utils/date.util';
import { mixins } from 'vue-class-component';
import { PermissionMixin } from '@/utils/permission.mixin';
import { EditorCommonMixin } from '../mixins/editor-common.mixin';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import { CplusDocumentAction } from '@/jbi-shared/types/document.types';
import UploadTdrForm from '@/components/form/UploadTdrForm.vue';
import { T } from 'lodash/fp';

@Component({
  components: {
    DocumentActions,
    ExportBtn,
    EditDocumentForm,
    AssigneeInput
  }
})
export default class ProjectEditorHeaderDocInfo extends mixins(
  PermissionMixin,
  EditorCommonMixin
) {
  public dirtyRevisionStatus: RevisionPublicationStatus | null = null;

  get revisionPublicationStatus() {
    return this.dirtyRevisionStatus ? this.dirtyRevisionStatus : '';
  }

  get documentDetail() {
    return useDocumentsState.call(this).documentDetail;
  }

  get lastEditedDate(): string {
    if (!this.documentDetail) {
      return '';
    }
    return dayjs(this.documentDetail.revision.createdAt).format(
      DATE_TIME_FORMAT
    );
  }

  get defaultDocDisplayId() {
    if (this.documentDetail) {
      return this.documentDetail!.document.defaultDocDisplayId;
    }
  }

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get publishedStatus() {
    if (useDocumentsState.call(this).documentDetail) {
      if (this.isWithdrawn) {
        return CplusDocumentAction.Restore;
      } else if (this.isPublished) {
        return CplusDocumentAction.Withdraw;
      }
      // return this.isArchived
      //   ? 'Unarchive'
      //   : this.isPublished
      //   ? 'Archive'
      //   : 'Publish';
    }

    return CplusDocumentAction.Publish;
  }

  get updatePublishStatusLoading(): boolean {
    return useDocumentsState.call(this).apiState.updatePublishStatus.loading;
  }

  get resourceUrl() {
    if (this.tdrUri) {
      return this.tdrUri.replace('gs://', 'https://storage.googleapis.com/');
    }
    return '';
  }

  public openUploadTdr() {
    this.$buefy.modal.open({
      parent: this,
      component: UploadTdrForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Edit Technical Development Reports'
      },
      events: {
        'update:tdr': this.handleUpdateTdr
      }
    });
  }

  public openDocSettingForm() {
    this.$buefy.modal.open({
      parent: this,
      component: EditDocumentForm,
      hasModalCard: true,
      trapFocus: true
    });
  }

  public goBack() {
    return this.$router.go(-1);
  }

  public handleUpdateTdr(tdrUri: string) {
    this.tdrUri = tdrUri;
  }

  @isDifferent
  @isTruthy
  @Watch('documentDetail', { immediate: true })
  public onDocLoaded() {
    this.dirtyRevisionStatus = this.documentDetail!.revision.publicationStatus;
  }

  public isPublishedDoc(status: RevisionPublicationStatus) {
    return status === RevisionPublicationStatus.Published;
  }

  public isDraftDoc(status: RevisionPublicationStatus) {
    return status === RevisionPublicationStatus.Draft;
  }

  public isArchivedDoc(status: RevisionPublicationStatus) {
    return status === RevisionPublicationStatus.Archived;
  }

  public isWithdrawnDoc(status: RevisionPublicationStatus) {
    return status === RevisionPublicationStatus.Withdrawn;
  }
}
