
























































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import Container from '@/components/Container.vue';
import { get as _get } from 'lodash';
import { RootState } from '@/store/store';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import PaginatedProjectList from '@/components/listings/projects/PaginatedProjectList.vue';
import PaginatedEditorList from '@/components/listings/editors/PaginatedEditorList.vue';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import AssignEditorButton from '@/views/AdminDashboard/components/AssignEditorButton.vue';
import { ProjectStatus } from '@/jbi-shared/types/project.types';
import { ApiState, PaginatedApiResponse } from '@/store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  GetAllProjectsRequestPayload,
  GetAllProjectResponsePayload,
  GetProjectCountByProjectStatusResponsePayload
} from '@/store/modules/projects/types/projects.types';
import { GetEditorsRequestPayload } from '@/store/modules/editors/types/editors.types';
import { EditorDetails } from '@/jbi-shared/types/editor.types';
import UploadDocumentButton from '@/views/AdminDashboard/components/UploadDocumentButton.vue';
import UploadHistoryButton from '@/views/AdminDashboard/components/pending-document-creations/UploadHistoryButton.vue';
import { GetEditorsResponsePayload } from '@/jbi-shared/types/cplus-endpoints/editor.types';
import { CreatePendingDocumentPayload } from '@/jbi-shared/types/cplus-worker.types';

@Component({
  components: {
    Container,
    DashboardHeader,
    PaginatedProjectList,
    PaginatedEditorList,
    AssignEditorButton,
    UploadDocumentButton,
    UploadHistoryButton
  }
})
export default class AdminDashboardPage extends Vue {
  public page = 1;
  public perPage = 5;
  public selectedProjects: ProjectListItemParam[] = [];
  public sortCriteria = 'searchDate';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';

  public sortCriteriaEditor: keyof EditorDetails = 'name';
  public sortOrderEditor: 'ASC' | 'DESC' = 'ASC';
  public assignee: string = '';
  public countAssignedProjects: number = 0;

  @Action('editors/getEditors')
  public getEditors!: (p: GetEditorsRequestPayload) => void;

  @Action('projects/getProjectCountByProjectStatus')
  public getProjectCountByProjectStatus!: () => void;

  @Action('projects/getAllProjects')
  public getAllProjects!: (p: GetAllProjectsRequestPayload) => void;

  @State((state: RootState) => _get(state, 'projects.allProjects'))
  public projects!: PaginatedApiResponse<GetAllProjectResponsePayload>;

  @State((state: RootState) => state.admin.pendingDocumentsCreated)
  public pendingDocumentsCreated!: CreatePendingDocumentPayload[];

  @State((state: RootState) => _get(state, 'editors.editors'))
  public editors!: GetEditorsResponsePayload | undefined;

  @State((state: RootState) => state?.admin?.apiState?.changeDueDate)
  public changeDueDate!: ApiState;

  @State((state: RootState) =>
    _get(state, 'projects.projectCountByProjectStatus')
  )
  public projectCountByProjectStatus!: GetProjectCountByProjectStatusResponsePayload;

  @State((state: RootState) =>
    _get(state, 'projects.apiState.getProjectCountByProjectStatus')
  )
  public getProjectCountByProjectStatusApi!: ApiState;

  @State((state: RootState) =>
    _get(state, 'projects.apiState.addNoteToProject')
  )
  public addNoteToProject!: ApiState;

  @State((state: RootState) =>
    _get(state, 'projects.apiState.updateProjectNote')
  )
  public updateProjectNote!: ApiState;

  @State((state: RootState) =>
    _get(state, 'admin.apiState.assignProjectEditor')
  )
  public assignProjectEditor!: ApiState;

  @State((state: RootState) =>
    _get(state, 'admin.apiState.unassignProjectEditor')
  )
  public unassignProjectEditor!: ApiState;

  get projectStatus() {
    return ProjectStatus;
  }

  get projectsPaginated() {
    return {
      items: _get(this.projects, 'items', []),
      totalItems: _get(this.projects, 'total_count', 0)
    };
  }

  get editorsPaginated() {
    return {
      items: this.editors?.items || [],
      totalItems: this.editors?.totalCount || 0
    };
  }

  public updateAssignee(val: string) {
    this.assignee = val;
  }

  public countAssignedProjectsAssigned(val: number) {
    this.countAssignedProjects = val;
  }

  public directToAdminProjects(status: ProjectStatus) {
    this.$router.push({
      name: 'admin-dashboard-projects',
      query: {
        selectedStatus: status
      }
    });
  }

  public handleGetProjects() {
    this.getAllProjects({
      page: 1,
      perPage: 5,
      sortCriteria: this.sortCriteria,
      sortOrder: this.sortOrder
    });
  }

  public handleGetEditors() {
    this.getEditors({
      page: 1,
      perPage: 5,
      sortCriteria: this.sortCriteriaEditor,
      sortOrder: this.sortOrderEditor
    });
  }

  public handleSort(val: { sortCriteria: string; sortOrder: 'ASC' | 'DESC' }) {
    const { sortCriteria, sortOrder } = val;
    this.sortCriteria = sortCriteria;
    this.sortOrder = sortOrder;
    this.handleGetProjects();
  }

  public handleSortEditor(val: {
    sortCriteria: keyof EditorDetails;
    sortOrder: 'ASC' | 'DESC';
  }) {
    const { sortCriteria, sortOrder } = val;
    this.sortCriteriaEditor = sortCriteria;
    this.sortOrderEditor = sortOrder;
    this.handleGetEditors();
  }

  @isDifferent
  @isTruthy
  @Watch('addNoteToProject.success')
  public onAddNoteSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Note added`
    });
    this.handleGetProjects();
  }

  @isTruthy
  @Watch('pendingDocumentsCreated')
  public onPendingDocumentsCreated() {
    this.handleGetProjects();
  }

  @isDifferent
  @isTruthy
  @Watch('updateProjectNote.success')
  public onUpdateProjectNoteSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Changes saved`
    });
    this.handleGetProjects();
  }

  @isDifferent
  @isTruthy
  @Watch('changeDueDate.success')
  public onChangeDueDate() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Changes Saved`
    });
    this.handleGetProjects();
  }

  @isDifferent
  @isTruthy
  @Watch('assignProjectEditor.success')
  public onAssignProjectEditorSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `${this.countAssignedProjects} successfully assigned to ${this.assignee}`
    });
    this.handleGetProjects();
    this.handleGetEditors();
    this.getProjectCountByProjectStatus();
  }

  @isDifferent
  @isTruthy
  @Watch('unassignProjectEditor.success')
  public onUnassignProjectEditorSuccess() {
    this.handleGetProjects();
    this.handleGetEditors();
    this.getProjectCountByProjectStatus();
  }

  public async mounted() {
    this.handleGetProjects();
    this.handleGetEditors();
    this.getProjectCountByProjectStatus();
  }
}
