import { WebSocketState } from './types/websocket.types';

export enum EVENTS {
  JOIN_ROOM = 'JOIN_ROOM',
  LEAVE_ROOM = 'LEAVE_ROOM',
  UPLOAD_CITATIONS_EVENT = 'UPLOAD_CITATIONS_EVENT',
  EXPORT_DOCUMENT_EVENT = 'EXPORT_DOCUMENT_EVENT',
  EXPORT_PDF_EVENT = 'EXPORT_PDF_EVENT',
  EXPORT_CSV_EVENT = 'EXPORT_CSV_EVENT',
  EXPORT_ADMIN_PROJECT_LIST_XLSX = 'EXPORT_ADMIN_PROJECT_LIST_XLSX',
  EXPORT_ADMIN_PROJECT_LIST_PDF = 'EXPORT_ADMIN_PROJECT_LIST_PDF',
  UPLOAD_EDITOR_DOCUMENTS_EVENT = 'UPLOAD_EDITOR_DOCUMENTS_EVENT',
  CREATE_PENDING_DOCUMENTS_EVENT = 'CREATE_PENDING_DOCUMENTS_EVENT',
  ADMIN_DOWNLOAD_DOCUMENT = 'ADMIN_DOWNLOAD_DOCUMENT'
}

export const websocketState: WebSocketState = {
  isConnected: false,
  error: null,
  workerJobs: [],
  usageCount: 0
};
