import {
  AdminState,
  GetAssignmentZipUrlResponsePayload,
  PendingDocumentUploadHistoryResponsePayload
} from './types/admin.types';
import {
  defaultApiState,
  PaginatedApiResponse
} from '@/store/types/general.types';
import { AxiosError } from 'axios';
import { CreatePendingDocumentPayload } from '@/jbi-shared/types/cplus-worker.types';
import { ImportedEditorDocumentPayload } from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import { PendingDocument } from '@/jbi-shared/types/document.types';
import { PendingProjectDetails } from '@/jbi-shared/types/cplus-endpoints/admin/project.types';
import { DocumentDetail } from '../documents/types/documents.types';

export const adminMutations = {
  /*
   ** assign projects to editor
   */
  ASSIGN_PROJECT_EDITOR_LOADING(state: AdminState) {
    state.apiState.assignProjectEditor = {
      ...defaultApiState,
      loading: true
    };
  },
  ASSIGN_PROJECT_EDITOR_SUCCESS(state: AdminState, payload: any) {
    state.apiState.assignProjectEditor = {
      ...defaultApiState,
      success: true
    };
  },
  ASSIGN_PROJECT_EDITOR_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.assignProjectEditor = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** unassign projects to editor
   */
  UNASSIGN_PROJECT_EDITOR_LOADING(state: AdminState) {
    state.apiState.unassignProjectEditor = {
      ...defaultApiState,
      loading: true
    };
  },
  UNASSIGN_PROJECT_EDITOR_SUCCESS(state: AdminState, payload: any) {
    state.apiState.unassignProjectEditor = {
      ...defaultApiState,
      success: true
    };
  },
  UNASSIGN_PROJECT_EDITOR_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.unassignProjectEditor = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** resend download link
   */
  RESEND_DOWNLOAD_LINK_LOADING(state: AdminState) {
    state.apiState.resendDownloadLink = {
      ...defaultApiState,
      loading: true
    };
  },
  RESEND_DOWNLOAD_LINK_SUCCESS(state: AdminState) {
    state.apiState.resendDownloadLink = {
      ...defaultApiState,
      success: true
    };
  },
  RESEND_DOWNLOAD_LINK_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.resendDownloadLink = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** download project documents
   */
  DOWNLOAD_DOCUMENT_LOADING(state: AdminState) {
    state.apiState.downloadDocument = {
      ...defaultApiState,
      loading: true
    };
  },
  DOWNLOAD_DOCUMENT_SUCCESS(state: AdminState) {
    state.apiState.downloadDocument = {
      ...defaultApiState,
      success: true
    };
  },
  DOWNLOAD_DOCUMENT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.downloadDocument = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** export project list
   */
  EXPORT_PROJECT_LIST_LOADING(state: AdminState) {
    state.apiState.exportProjectList = {
      ...defaultApiState,
      loading: true
    };
  },
  EXPORT_PROJECT_LIST_SUCCESS(state: AdminState) {
    state.apiState.exportProjectList = {
      ...defaultApiState,
      success: true
    };
  },
  EXPORT_PROJECT_LIST_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.exportProjectList = {
      ...defaultApiState,
      error
    };
  },
  /*
   * change due date
   */
  CHANGE_DUE_DATE_LOADING(state: AdminState) {
    state.apiState.changeDueDate = {
      ...defaultApiState,
      loading: true
    };
  },
  CHANGE_DUE_DATE_SUCCESS(state: AdminState) {
    state.apiState.changeDueDate = {
      ...defaultApiState,
      success: true
    };
  },
  CHANGE_DUE_DATE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.changeDueDate = {
      ...defaultApiState,
      error
    };
  },
  /*
   * get assignment zip file url
   */
  GET_ASSIGNMENT_ZIP_URL_LOADING(state: AdminState) {
    state.apiState.getAssignmentZipUrl = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ASSIGNMENT_ZIP_URL_SUCCESS(
    state: AdminState,
    payload: GetAssignmentZipUrlResponsePayload
  ) {
    state.apiState.getAssignmentZipUrl = {
      ...defaultApiState,
      success: true
    };
    state.assignmentZipUrl = payload;
  },
  GET_ASSIGNMENT_ZIP_URL_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getAssignmentZipUrl = {
      ...defaultApiState,
      error
    };
  },
  /*
   * get uploaded document details
   */
  GET_UPLOADED_DOCUMENT_DETAILS_LOADING(state: AdminState) {
    state.apiState.getUploadedDocumentDetails = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_UPLOADED_DOCUMENT_DETAILS_SUCCESS(state: AdminState) {
    state.apiState.getUploadedDocumentDetails = {
      ...defaultApiState,
      success: true
    };
  },
  GET_UPLOADED_DOCUMENT_DETAILS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getUploadedDocumentDetails = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** fetch pending project details
   */
  FETCH_PENDING_PROJECT_DETAILS_LOADING(state: AdminState) {
    state.apiState.fetchPendingProjectDetails = {
      ...defaultApiState,
      loading: true
    };
    state.pendingProjectDetails = undefined;
  },
  FETCH_PENDING_PROJECT_DETAILS_SUCCESS(
    state: AdminState,
    payload: PendingProjectDetails
  ) {
    state.apiState.fetchPendingProjectDetails = {
      ...defaultApiState,
      success: true
    };
    state.pendingProjectDetails = payload;
  },
  FETCH_PENDING_PROJECT_DETAILS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.fetchPendingProjectDetails = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** fetch pending document
   */
  FETCH_PENDING_DOCUMENT_LOADING(state: AdminState) {
    state.apiState.fetchPendingDocument = {
      ...defaultApiState,
      loading: true
    };
    state.pendingDocument = undefined;
  },
  FETCH_PENDING_DOCUMENT_SUCCESS(state: AdminState, payload: PendingDocument) {
    state.apiState.fetchPendingDocument = {
      ...defaultApiState,
      success: true
    };
    state.pendingDocument = payload;
  },
  FETCH_PENDING_DOCUMENT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.fetchPendingDocument = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** fetch existing document detail
   */
  FETCH_EXISTING_DOCUMENT_DETAIL_LOADING(state: AdminState) {
    state.apiState.fetchExistingDocumentDetail = {
      ...defaultApiState,
      loading: true
    };
    state.existingDocumentDetail = undefined;
  },
  FETCH_EXISTING_DOCUMENT_DETAIL_SUCCESS(
    state: AdminState,
    payload: DocumentDetail
  ) {
    state.apiState.fetchExistingDocumentDetail = {
      ...defaultApiState,
      success: true
    };
    state.existingDocumentDetail = payload;
  },
  FETCH_EXISTING_DOCUMENT_DETAIL_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.fetchExistingDocumentDetail = {
      ...defaultApiState,
      error
    };
  },
  /*
   * create pending documents
   */
  CREATE_PENDING_DOCUMENTS_LOADING(state: AdminState) {
    state.apiState.createPendingDocuments = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_PENDING_DOCUMENTS_SUCCESS(state: AdminState) {
    state.apiState.createPendingDocuments = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_PENDING_DOCUMENTS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createPendingDocuments = {
      ...defaultApiState,
      error
    };
  },
  /*
   * fetch pending document creation status
   */
  FETCH_PENDING_DOC_CREATION_STATUS_LOADING(state: AdminState) {
    state.apiState.fetchPendingDocumentCreationStatus = {
      ...defaultApiState,
      loading: true
    };
  },
  FETCH_PENDING_DOC_CREATION_STATUS_SUCCESS(state: AdminState) {
    state.apiState.fetchPendingDocumentCreationStatus = {
      ...defaultApiState,
      success: true
    };
  },
  FETCH_PENDING_DOC_CREATION_STATUS_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.fetchPendingDocumentCreationStatus = {
      ...defaultApiState,
      error
    };
  },
  /*
   * fetch pending document upload history
   */
  FETCH_PENDING_DOCUMENT_UPLOAD_HISTORY_LOADING(state: AdminState) {
    state.apiState.fetchPendingDocumentUploadHistory = {
      ...defaultApiState,
      loading: true
    };
  },
  FETCH_PENDING_DOCUMENT_UPLOAD_HISTORY_SUCCESS(
    state: AdminState,
    payload: PaginatedApiResponse<PendingDocumentUploadHistoryResponsePayload>
  ) {
    state.apiState.fetchPendingDocumentUploadHistory = {
      ...defaultApiState,
      success: true
    };
    state.pendingDocumentUploadHistory = payload;
  },
  FETCH_PENDING_DOCUMENT_UPLOAD_HISTORY_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.fetchPendingDocumentUploadHistory = {
      ...defaultApiState,
      error
    };
  },
  /*
   * update pending documents
   */
  UPDATE_PENDING_DOCUMENT_LOADING(state: AdminState) {
    state.apiState.updatePendingDocument = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PENDING_DOCUMENT_SUCCESS(state: AdminState) {
    state.apiState.updatePendingDocument = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_PENDING_DOCUMENT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updatePendingDocument = {
      ...defaultApiState,
      error
    };
  },
  /*
   * publish pending documents
   */
  PUBLISH_PENDING_DOCUMENT_LOADING(state: AdminState) {
    state.apiState.publishPendingDocument = {
      ...defaultApiState,
      loading: true
    };
  },
  PUBLISH_PENDING_DOCUMENT_SUCCESS(state: AdminState) {
    state.apiState.publishPendingDocument = {
      ...defaultApiState,
      success: true
    };
  },
  PUBLISH_PENDING_DOCUMENT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.publishPendingDocument = {
      ...defaultApiState,
      error
    };
  },
  UPDATE_EDITOR_DOCUMENT_UPLOADING_RESPONSE(
    state: AdminState,
    payload: ImportedEditorDocumentPayload[]
  ) {
    state.uploadedDocumentDetails = payload;
  },
  UPSERT_CREATE_PENDING_DOCUMENTS_RESPONSE(
    state: AdminState,
    payload: CreatePendingDocumentPayload[]
  ) {
    state.pendingDocumentsCreated = payload;
  },
  PENDING_DOCUMENT_DETAIL_RESET(state: AdminState, error: AxiosError) {
    state.apiState.fetchPendingDocument = {
      ...defaultApiState
    };
    state.pendingDocument = undefined;
  }
};
