import { Store } from 'vuex';
import { staticFileApi } from '@/api/static-file.api';
import {
  StaticApiState,
  GetSignedUrlForUploadResponsePayload,
  GetSignedUrlForUploadRequestPayload
} from './types/static-file.types';

export const staticFileActions = {
  async getSignedUrlForUploads(
    store: Store<StaticApiState>,
    payload: GetSignedUrlForUploadRequestPayload
  ) {
    try {
      store.commit('GET_SIGNED_URL_FOR_UPLOAD_LOADING');

      const {
        data
      }: {
        data: GetSignedUrlForUploadResponsePayload;
      } = await staticFileApi.getSignedUrlForUpload(payload);

      store.commit('GET_SIGNED_URL_FOR_UPLOAD_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_SIGNED_URL_FOR_UPLOAD_ERROR', error);
    }
  }
};
