import dayjs from 'dayjs';
import { generateDefaultUniquePublicationId } from '../jbi-shared/util/document.utils';
import { FullDocumentRevisionObject } from '../jbi-shared/types/full-document-revision-object.types';

export interface Publication {
  uniquePublicationId: string;
  url: string;
  publishedAt: Date;
  publishedAtTimezone: string;
}

export const getPublications: (
  arr: FullDocumentRevisionObject['document'],
) => Publication[] = (document) => {
  const { publishedRevisions, documentType } = document;
  return publishedRevisions
    .map((r) => {
      const { publicationId } = r;
      const publishedAt = r.publishedAt;
      const publishedAtTimezone = r.publishedAtTimezone;
      const documentId = r.projectDocumentId;

      const uniquePublicationId = generateDefaultUniquePublicationId({
        documentId,
        publicationId: publicationId!,
        type: documentType,
      })!;
      return {
        uniquePublicationId,
        publishedAt: dayjs(publishedAt!).toDate(),
        publishedAtTimezone,
        url: `${window.location.protocol}//${window.location.host}/documents/${uniquePublicationId}/readonly`,
      };
    })
    .sort((p1, p2) => Number(p2.publishedAt) - Number(p1.publishedAt));
};

export const getArchivedPublications: (
  arr: FullDocumentRevisionObject['document'],
) => Publication[] = (doc) => getPublications(doc).slice(1);
