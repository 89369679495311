import {
  CplusDocumentStatus,
  RevisionPublicationStatus
} from '@/jbi-shared/types/document-status.types';
import {
  CplusDocumentType,
  CplusDocumentTypeDisplayName
} from '@/jbi-shared/types/document.types';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import {
  LegacySearchFilters,
  ResearchNode
} from '@/store/modules/documents/types/documents.types';
import { useProjectsState } from '@/utils/store.util';
import { Component, Vue } from 'vue-property-decorator';

export const legacyFilterOptions = Object.values(LegacySearchFilters).map(
  (type) => ({
    name: type,
    id: type
  })
);

@Component
export default class OptionsMixin extends Vue {
  get documentTypeOptions(): StringInputOption[] {
    return Object.values(CplusDocumentType).map((type) => ({
      name: CplusDocumentTypeDisplayName[type].toString(),
      id: type.toString()
    }));
  }

  get documentStatusOptions(): StringInputOption[] {
    return Object.values(CplusDocumentStatus).map((type) => ({
      name: type,
      id: type
    }));
  }

  get legacyFilterOptions(): StringInputOption[] {
    return Object.values(LegacySearchFilters).map((type) => ({
      name: type,
      id: type
    }));
  }

  get allResearchNodeOptions(): ResearchNode[] {
    return useProjectsState.call(this).allResearchNodes || [];
  }

  get publicationStatusOptions(): StringInputOption[] {
    return [
      {
        id: '',
        name: 'All Statuses'
      },
      {
        id: RevisionPublicationStatus.Draft,
        name: RevisionPublicationStatus.Draft
      },
      {
        id: RevisionPublicationStatus.Published,
        name: RevisionPublicationStatus.Published
      },
      {
        id: RevisionPublicationStatus.Archived,
        name: RevisionPublicationStatus.Archived
      },
      {
        id: RevisionPublicationStatus.Withdrawn,
        name: RevisionPublicationStatus.Withdrawn
      }
    ];
  }
}
