
















import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import PendingDocCreationJobList from './CreationList.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { PendingDocumentCreationStatus } from '@/views/AdminDashboard/PendingDocumentsCreationStatusPage.vue';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedPendingDocCreationJobList extends mixins(
  PaginationMixin
) {
  @Prop() public items!: PendingDocumentCreationStatus[];
  @Prop() public totalCount!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  get PendingDocCreationJobList() {
    return PendingDocCreationJobList;
  }
}
