import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EditorStateMixin } from './editor-state.mixin';
import { EditorComputedValuesMixin } from './editor-computed-values.mixin';
import { distinctUntilDeepChanged } from '../../../jbi-shared/util/distinctUntilDeepChanged.rx-operator';
import { merge } from 'rxjs';
import {
  mapTo,
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  filter
} from 'rxjs/operators';
import { pick } from 'lodash';
import { isDifferent } from '../../../jbi-shared/util/watcher.vue-decorator';

const maxHistorySize = 300;

@Component<EditorUndoRedoRootMixin>({})
export class EditorUndoRedoRootMixin extends mixins(
  EditorStateMixin,
  EditorComputedValuesMixin
) {
  // value defined in DocumentEditorPage
  public isRootPage!: boolean;

  public created() {
    if (!this.isRootPage) {
      return;
    }

    // handle states for undo/redo
    const changed$ = merge(
      ...this.contentKeys.map((key) =>
        this.$reactiveValueObservable(key).pipe(
          distinctUntilDeepChanged(),
          mapTo(key)
        )
      )
    );
    changed$
      .pipe(
        filter(() => !this.isUndoingOrRedoing),
        debounceTime(200),
        map(() => {
          return JSON.stringify(pick(this, this.contentKeys));
        }),
        tap(
          (serializedContent) => (this.serializedContent = serializedContent)
        ),
        distinctUntilChanged()
      )
      .subscribe((serializedContent) => {
        this.contentUndoStack.push(serializedContent);
        this.contentRedoStack = [];
      });
  }

  @isDifferent
  @Watch('contentUndoStack')
  public onHistoryUpdated() {
    if (this.contentUndoStack.length > maxHistorySize) {
      this.contentUndoStack.shift();
    }
  }
}
