import { UI_STATES } from './ui-state-machine.util';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { InjectReactive } from 'vue-property-decorator';

@Component({})
export class UIStateMachineChildComponentMixin extends Vue {
  @InjectReactive('uiState') public uiState!: UI_STATES;

  get ui_isStateLoading() {
    return this.uiState === UI_STATES.LOADING;
  }

  get ui_isStateInitialize() {
    return this.uiState === UI_STATES.INITIALIZE;
  }

  get ui_isStateSuccessWithData() {
    return this.uiState === UI_STATES.FETCHSUCCESS_WITHDATA;
  }

  get ui_isStateSuccessWithNoData() {
    return this.uiState === UI_STATES.FETCHSUCCESS_NODATA;
  }
}
