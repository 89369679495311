import { createDecorator, VueDecorator } from 'vue-class-component';
import { throttle as _throttle } from 'lodash';

export const Throttle = (timeout: number): VueDecorator => {
  return createDecorator((options, key) => {
    if (!options.methods) {
      throw new Error(`Throttle Decorator Error`);
    }

    options.methods[key] = _throttle(options.methods[key], timeout);
  });
};
