






















































































import Component, { mixins } from 'vue-class-component';
import { Vue, Watch, Prop } from 'vue-property-decorator';
import { Author } from '../../jbi-shared/types/author.types';

@Component({
  components: {}
})
export default class AuthorInput extends Vue {
  @Prop() public value!: Author;

  get title() {
    return this.value.title;
  }
  set title(title: string) {
    this.$emit('input', { ...this.value, title });
  }

  get firstName() {
    return this.value.firstName;
  }
  set firstName(firstName: string) {
    this.$emit('input', { ...this.value, firstName });
  }

  get lastName() {
    return this.value.lastName;
  }
  set lastName(lastName: string) {
    this.$emit('input', { ...this.value, lastName });
  }

  get qualification() {
    return this.value.qualification;
  }
  set qualification(qualification: string) {
    this.$emit('input', { ...this.value, qualification });
  }

  public handleDelete() {
    return this.$emit('delete');
  }
}
