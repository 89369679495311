





































import { Component, Vue, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import BprSectionValueEditor from '@/components/editor/SectionEditor/BprSectionEditor/BprSectionValueEditor.vue';
import BprForm from '@/components/editor/SectionEditor/BprSectionEditor/BprForm.vue';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import Op from 'quill-delta/dist/Op';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    draggable,
    BprSectionValueEditor,
    BprForm
  }
})
export default class BprEditor extends mixins(ViewModeMixin) {
  public drag = false;

  @Prop()
  public dirtyBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyCriterions!: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public bprSectionId!: number;

  get subSections(): FullDocumentRevisionObject['revision']['sections']['bprSection'] {
    return this.dirtyBprs;
  }

  set subSections(
    v: FullDocumentRevisionObject['revision']['sections']['bprSection']
  ) {
    this.$emit('update:bprs', v);
  }

  public handleDelete(
    subSection: FullDocumentRevisionObject['revision']['sections']['bprSection'][number],
    index: number
  ) {
    const { bprSubSectionId, tempId } = subSection;
    this.subSections = this.subSections.filter((_, j) => j !== index);
    const updatedCriterions = this.dirtyCriterions.map((criterion) => {
      return {
        ...criterion,
        bprs: criterion.bprs.filter((bpr) =>
          bprSubSectionId
            ? bpr.bprSubSectionId !== bprSubSectionId
            : bpr.tempId !== tempId
        )
      };
    });
    this.$emit('update:criterions', updatedCriterions);
  }

  public openAddNewModal() {
    this.$buefy.modal.open({
      parent: this,
      component: BprForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Add Best Practice Recommendation',
        subSections: this.subSections,
        dirtyTagMaps: this.dirtyTagMaps,
        bprSectionId: this.bprSectionId
      },
      events: {
        'update:bprs': (
          e: FullDocumentRevisionObject['revision']['sections']['bprSection']
        ) => this.$emit('update:bprs', e),
        'update:dirtyTagMaps': (e: DirtyTagMap[]) =>
          this.$emit('update:dirtyTagMaps', e)
      }
    });
  }
}
