import { defaultApiState } from '@/store/types/general.types';
import { EditorsState } from './types/editors.types';
import {
  GetEditorsResponsePayload,
  FetchEditorOptionsResponsePayload,
  FetchEditorRolesResponsePayload
} from '@/jbi-shared/types/cplus-endpoints/editor.types';
import { AxiosError } from 'axios';

export const editorsMutations = {
  /*
   ** get all editors
   */
  GET_EDITORS_LOADING(state: EditorsState) {
    state.apiState.getEditors = {
      ...defaultApiState,
      loading: true
    };
    state.editors = undefined;
  },
  GET_EDITORS_SUCCESS(state: EditorsState, payload: GetEditorsResponsePayload) {
    state.apiState.getEditors = {
      ...defaultApiState,
      success: true
    };
    state.editors = payload;
  },
  GET_EDITORS_ERROR(state: EditorsState, error: AxiosError) {
    state.apiState.getEditors = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** fetch editor roles
   */
  FETCH_EDITOR_ROLES_LOADING(state: EditorsState) {
    state.apiState.fetchEditorRoles = {
      ...defaultApiState,
      loading: true
    };
    state.editorRoles = undefined;
  },
  FETCH_EDITOR_ROLES_SUCCESS(
    state: EditorsState,
    payload: FetchEditorRolesResponsePayload
  ) {
    state.apiState.fetchEditorRoles = {
      ...defaultApiState,
      success: true
    };
    state.editorRoles = payload.roles;
  },
  FETCH_EDITOR_ROLES_ERROR(state: EditorsState, error: AxiosError) {
    state.apiState.fetchEditorRoles = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** fetch editor options
   */
  FETCH_EDITOR_OPTIONS_LOADING(state: EditorsState) {
    state.apiState.fetchEditorOptions = {
      ...defaultApiState,
      loading: true
    };
    state.editorOptions = undefined;
  },
  FETCH_EDITOR_OPTIONS_SUCCESS(
    state: EditorsState,
    payload: FetchEditorOptionsResponsePayload
  ) {
    state.apiState.fetchEditorOptions = {
      ...defaultApiState,
      success: true
    };
    state.editorOptions = payload.editors;
  },
  FETCH_EDITOR_OPTIONS_ERROR(state: EditorsState, error: AxiosError) {
    state.apiState.fetchEditorOptions = {
      ...defaultApiState,
      error
    };
  }
};
