import { get, get as _get, isEmpty, uniqBy } from 'lodash';

import { Author } from '../types/author.types';
import { RevisionPublicationStatus } from '../types/document-status.types';
import {
  CplusDocumentType,
  CplusDocumentTypeShortHand,
  TagMap,
} from '../types/document.types';
import { FullDocumentRevisionObject } from '../types/full-document-revision-object.types';

/**
 * Termilogy
 *
 * DefaultDocDisplayId: JBI-ES-1
 * UniquePublicationId: JBI-ES-1-1,JBI-ES-1-2
 */

interface GenerateDefaultDocDisplayIdPayload {
  documentId: number;
  type: CplusDocumentType;
}
export const generateDefaultDocDisplayId: (
  p: GenerateDefaultDocDisplayIdPayload,
) => string = ({ documentId, type }) => {
  const typeShortHand = CplusDocumentTypeShortHand[type];
  if (!typeShortHand) {
    throw new Error(`Invalid typeShortHand in generateDefaultDocDisplayId`);
  }
  return `JBI-${typeShortHand}-${documentId}`;
};

interface GenerateDefaultUniqueDocIdPayload {
  documentId: number;
  publicationId?: number;
  type: CplusDocumentType;
}
export const generateDefaultUniquePublicationId: (
  p: GenerateDefaultUniqueDocIdPayload,
) => string | undefined = ({ documentId, type, publicationId }) => {
  if (!publicationId) {
    return;
  }
  return `${generateDefaultDocDisplayId({
    documentId,
    type,
  })}-${publicationId}`;
};

interface GenerateUniquePublicationIdPayload {
  displayId: string;
  publicationId: number;
}
export const generateUniquePublicationId: (
  p: GenerateUniquePublicationIdPayload,
) => string = ({ displayId, publicationId }) => {
  return `${displayId}-${publicationId}`;
};

export const generateLegacyDocDisplayId = (baseDocumentId: number) =>
  `JBI${baseDocumentId}`;

export const getDocTypeFromShorthand: (
  s: CplusDocumentTypeShortHand,
) => CplusDocumentType = (s) => {
  const key = Object.entries(CplusDocumentTypeShortHand)
    .find(([_, value]) => value === s)!
    .shift() as keyof typeof CplusDocumentType;
  if (!key) {
    throw new Error(`Invalid CplusDocumentTypeShortHand`);
  }
  return CplusDocumentType[key];
};

export const parseUniquePublicationId: (
  s: string,
) => GenerateUniquePublicationIdPayload = (s: string) => {
  const arr = s.split('-');
  const publicationId = +arr.pop()!;
  const displayId = arr.join('-');
  return { publicationId, displayId };
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>> // tslint:disable-next-line
    : T[P] extends ReadonlyArray<infer U> // tslint:disable-next-line
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

export function tagMapToUniqueKey(tm: DeepPartial<TagMap>) {
  return `${tm.entityType}_${tm.entityId}_${tm.tagType}_${get(
    tm,
    'keyword.keyword',
  )}_${get(tm, 'mesh.path')}`;
}
export function uniqTagMap<T>(tms: T[]): T[] {
  return uniqBy(tms, (tm) => tagMapToUniqueKey(tm));
}
export function removeTagMapFromArr<T>(tmArr: T[], tm: T) {
  return tmArr.filter(
    (iTm) => tagMapToUniqueKey(iTm) !== tagMapToUniqueKey(tm),
  );
}

export const generateRandomId = () => {
  return String(Math.random() + +new Date());
};

export const generateDraftAuthor = (documentSectionId: number) =>
  ({
    documentSectionId,
    authorSubSectionId: 0,
    tempId: generateRandomId(),
    content: {
      title: '',
      firstName: '',
      lastName: '',
      qualification: '',
    },
  } as FullDocumentRevisionObject['revision']['sections']['authorSubSections'][0]);

export const formatAuthorForDisplay = (author: Author) => {
  const { title, firstName, lastName, qualification } = author;
  return `${title} ${firstName} ${lastName} ${qualification}`;
};

export const formatAuthorsForDisplay = (authors: Author[]) => {
  return authors.map(formatAuthorForDisplay).join(', ').trim();
};

export const formatAuthors = (authors: Author[]) => {
  return authors.map(formatAuthorForDisplay);
};

export const formatAuthorsForCitedDisplay = (authors: Author[]) => {
  return authors
    .map((author) => {
      const { lastName, firstName } = author;
      if (isEmpty(lastName) && isEmpty(firstName)) {
        return '';
      } else if (isEmpty(firstName) && lastName) {
        return lastName;
      } else if (isEmpty(lastName) && firstName) {
        return firstName;
      }
      return `${lastName}, ${firstName[0]}`;
    })
    .join(', ');
};

export const getPublicationStatusFromDocument = (
  doc: FullDocumentRevisionObject,
) => {
  return (
    _get(doc, 'revision.publicationStatus') ||
    getPublicationStatusFromDate({
      archivedAt: doc.revision.archivedAt,
      publishedAt: doc.revision.publishedAt,
      withdrawnAt: doc.revision.withdrawnAt,
    })
  );
};

export const getPublicationStatusFromDate: (
  d: Pick<FullDocumentRevisionObject['revision'], 'archivedAt' | 'publishedAt' | 'withdrawnAt'>,
) => RevisionPublicationStatus = ({ archivedAt, publishedAt, withdrawnAt }) => {
  if (!archivedAt && !publishedAt && !withdrawnAt) {
    return RevisionPublicationStatus.Draft;
  } else if (withdrawnAt) {
    /**
     * NOTE: Use for workflows where we have withdrawn status.
     * We only have archived status when user publish a document then the
     * previous published version will become archived.
     * We have a case where revision has publishedAt, archivedAt, withdrawnAt.
     * That's because we migrate the revision which has the latest archived
     * status to 'withdrawn' status.
     */
    if (!archivedAt) {
      if (new Date(String(withdrawnAt)) >= new Date(String(publishedAt))) {
        return RevisionPublicationStatus.Withdrawn;
      } else {
        return RevisionPublicationStatus.Published;
      }
    } else {
      if (
        (new Date(String(withdrawnAt)) >= new Date(String(publishedAt))) &&
        (new Date(String(withdrawnAt)) >= new Date(String(archivedAt)))
      ) {
        return RevisionPublicationStatus.Withdrawn;
      } else if (
        (new Date(String(archivedAt)) >= new Date(String(publishedAt))) &&
        (new Date(String(archivedAt)) >= new Date(String(withdrawnAt)))
      ) {
        return RevisionPublicationStatus.Archived;
      } else {
        return RevisionPublicationStatus.Published;
      }
    }
  } else if (!withdrawnAt) {
    // NOTE: We have a case for legacy document
    // There are some revision doesn't have publishedAt but they have archivedAt
    if (archivedAt && !publishedAt) {
      return RevisionPublicationStatus.Archived;
    } else if (!archivedAt && publishedAt) {
      return RevisionPublicationStatus.Published;
    } else if (new Date(String(archivedAt)) >= new Date(String(publishedAt))) {
      return RevisionPublicationStatus.Archived;
    } else if (new Date(String(archivedAt)) < new Date(String(publishedAt))) {
      return RevisionPublicationStatus.Published;
    }
  }

  return RevisionPublicationStatus.Draft;
};

export const getSourceEvidenceSummaryFromDocument = (
  doc: FullDocumentRevisionObject | undefined,
) => {
  if (!doc) {
    return;
  }
  if (doc.document.documentType !== CplusDocumentType.RecommendedPractice) {
    return;
  }
  const projectDocuments: FullDocumentRevisionObject['project']['projectDocuments'] = _get(
    doc,
    'project.projectDocuments',
    [],
  );
  return projectDocuments.find(
    (d) => d.documentType === CplusDocumentType.EvidenceSummary,
  );
};
export const getSourceEvidenceSummaryTitleFromDocument = (
  doc: FullDocumentRevisionObject | undefined,
) => {
  const es = getSourceEvidenceSummaryFromDocument(doc);
  return es ? es.title : undefined;
};
export const getSourceEvidenceSummaryIdFromDocument = (
  doc: FullDocumentRevisionObject | undefined,
) => {
  const es = getSourceEvidenceSummaryFromDocument(doc);
  return es ? es.id : undefined;
};
export const getRelatedDocFromSourceEvidenceSummary = (
  es: FullDocumentRevisionObject['project']['projectDocuments'][0],
) =>
  es
    ? ({
        documentSectionId: 0,
        relatedDocSubSectionId: 0,
        relatedDocs: {
          id: es.id,
          title: es.title,
        },
      } as FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0])
    : undefined;

export enum SuggestedDocumentType {
  relatedDocuments = 'relatedDocuments',
  supportingEvidenceSummaries = 'supportingEvidenceSummaries',
  esForRpRealignment = 'esForRpRealignment',
}

// check for JBI-ES-1-1
const standardDocRegExp = /^JBI\-(ES|RP|BPIS|SR|SRP)\-[0-9]+\-[0-9]+$/;
export const isStandardDocId = (str: string) =>
  standardDocRegExp.test(str) && str.length < 21;

// check for JBI12345
const legacyDocRegExp = /^JBI[0-9]+$/;
export const isLegacyDocId = (str: string) =>
  legacyDocRegExp.test(str) && str.length < 9;
