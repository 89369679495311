


































import Component, { mixins } from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { DATE_FORMAT } from '@/utils/date.util';

@Component({})
export default class SuggestedDocumentCard extends Vue {
  @Prop() public document!: DocumentIndexingPayload;

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  get documentTitle(): string {
    if (!this.document) {
      return '';
    }
    return this.document.title;
  }

  get primaryResearchNode() {
    return this.document.primaryNode;
  }
  get secondaryResearchNodes() {
    if (!this.document.secondaryNodes) {
      return '';
    }
    return this.document.secondaryNodes.filter(Boolean).join(', ').trim();
  }

  get authors() {
    return (this.document.authors || []).join(', ');
  }

  get docDisplayId() {
    return (
      this.document.uniquePublicationId || this.document.defaultDocDisplayId
    );
  }
}
