






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TextSectionDefaultTitle } from '../../../../jbi-shared/types/document.types';

@Component({})
export default class SectionCard extends Vue {
  @Prop(String) public sectionId!: string;
  @Prop(String) public text!: string;
  @Prop(Boolean) public draggable!: boolean;

  public handleClick() {
    // scroll section into view
    const target = document.getElementById(this.sectionId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // Allow deletion of non default section titles
  public checkIfDeletable() {
    const defaultTitles = (Object.values(
      TextSectionDefaultTitle
    ) as string[]).map((title) => title.toLowerCase());
    if (!defaultTitles.includes(this.text.toLocaleLowerCase())) {
      return true;
    }
    return false;
  }
}
