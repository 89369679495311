








































import { Component, Vue, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import CriterionSectionValueEditor from './CriterionSectionValueEditor.vue';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import Op from 'quill-delta/dist/Op';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import {
  CriterionContent,
  Criterion
} from '@/jbi-shared/types/criterions.types';
// tslint:disable-next-line
import CriterionForm from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm.vue';
import { EditorCommonMixin } from '../../../../views/DocumentEditor/mixins/editor-common.mixin';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    draggable,
    CriterionSectionValueEditor,
    CriterionForm
  }
})
export default class CriterionSectionEditor extends mixins(ViewModeMixin) {
  @Prop()
  public dirtyBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyCriterions!: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public criterionSectionId!: number;

  public drag = false;

  get criterions() {
    return this.dirtyCriterions;
  }

  set criterions(
    v: FullDocumentRevisionObject['revision']['sections']['criterionSection']
  ) {
    this.$emit('update:criterions', v);
  }

  get subSections(): FullDocumentRevisionObject['revision']['sections']['bprSection'] {
    return this.dirtyBprs;
  }

  public openAddNewModal() {
    this.$buefy.modal.open({
      parent: this,
      component: CriterionForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Add Audit Criteria',
        subSections: this.subSections,
        dirtyTagMaps: this.dirtyTagMaps,
        dirtyCriterions: this.dirtyCriterions,
        criterionSectionId: this.criterionSectionId
      },
      events: {
        'update:criterions': (
          e: FullDocumentRevisionObject['revision']['sections']['criterionSection']
        ) => this.$emit('update:criterions', e),
        'update:dirtyTagMaps': (e: DirtyTagMap[]) =>
          this.$emit('update:dirtyTagMaps', e)
      }
    });
  }
}
