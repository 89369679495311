import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EditorStateMixin } from './editor-state.mixin';
import { EditorComputedValuesMixin } from './editor-computed-values.mixin';
import { EditorChangeDetectorMixin } from './editor-change-detector.mixin';
import { EditorWatcherMixin } from './editor-watcher.mixin';
import { EditorUndoRedoChildMixin } from './editor-undo-redo-child.mixin';

@Component({})
export class EditorCommonMixin extends mixins(
  EditorStateMixin,
  EditorComputedValuesMixin,
  EditorWatcherMixin,
  EditorChangeDetectorMixin,
  EditorUndoRedoChildMixin
) {}
