import { staticFileState } from './static-file.state';
import { staticFileGetters } from './static-file.getters';
import { staticFileActions } from './static-file.actions';
import { staticFileMutations } from './static-file.mutations';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: staticFileState,
  getters: staticFileGetters,
  actions: staticFileActions,
  mutations: staticFileMutations
} as ModuleTree<RootState>;
