







































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ExportDocumentAsDocxDialog extends Vue {
  @Prop()
  public title!: string;

  public includeAuditCriteria = false;

  public handleExportDocx(modal: any) {
    this.$emit('exportDocx', {
      includeAuditCriteria: this.includeAuditCriteria
    });
    modal.close();
  }
}
