

























































import { Component, Vue, Watch } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import PaginatedResearchNodeList from '@/views/AdminSetting/components/PaginatedResearchNodeList.vue';
import { Action, State } from 'vuex-class';
import { ResearchNodeEntity } from '@/store/modules/documents/types/documents.types';
import { RootState } from '@/store/store';
import {
  PaginatedResearchNodesPayload,
  Pagination
} from '@/store/modules/projects/types/projects.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import ImportResearchNodeForm from './components/ImportResearchNodeForm.vue';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@/utils/date.util';
import { getFullNameFromUser } from '@/jbi-shared/util/user.utils';
import axios from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';

@Component({
  components: {
    Container,
    DashboardHeader,
    PaginatedResearchNodeList
  }
})
export default class AdminSettingsPage extends Vue {
  public currentTab = 0;
  public page: number = 1;
  public perPage = 50;
  public filteredName = '';
  public sortCriteria = 'name';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';

  @Action('projects/getResearchNodes')
  public getResearchNodes!: (params: PaginatedResearchNodesPayload) => void;

  @Action('projects/getActiveResearchNodes')
  public getActiveResearchNodes!: () => void;

  @State(({ projects }: RootState) => projects.paginatedResearchNode)
  public researchNodes!: Pagination<ResearchNodeEntity>;

  @Debounce(500)
  public debounceUpdate() {
    this.page = 1;
    this.updateRouteQuery();
  }

  get nodePaginated() {
    return {
      items: this.researchNodes?.items,
      totalItems: this.researchNodes?.meta?.totalItems
    };
  }

  get uploadedAt() {
    return this.researchNodes?.items[0]?.updatedAt
      ? dayjs(this.researchNodes?.items[0]?.updatedAt).format(DATE_TIME_FORMAT)
      : '';
  }

  get uploadedBy() {
    return this.researchNodes?.items?.[0].creator
      ? getFullNameFromUser(this.researchNodes?.items?.[0].creator)
      : '';
  }

  public handleUpload() {
    this.$buefy.modal.open({
      parent: this,
      component: ImportResearchNodeForm,
      hasModalCard: true,
      events: {
        refresh: () => {
          this.getResearchNodes({
            page: this.page,
            limit: this.perPage,
            sortCriteria: 'name',
            sortOrder: 'ASC'
          });
          this.getActiveResearchNodes();
        }
      }
    });
  }

  public async handleDownload() {
    try {
      const { data: exportedNodes } = await axios.get<ResearchNodeEntity[]>(
        `export-research-nodes`
      );
      const csvContent = this.convertNodesToCSV(
        exportedNodes.map((item) => item.name) || []
      );
      const blob = new Blob([csvContent], { type: 'text/csv' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'research-nodes.csv';
      link.click();
    } catch (error) {
      console.error(error);
      Toast.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `Cannot download research nodes.`
      });
    }
  }

  private convertNodesToCSV(nodes: string[]) {
    let csv = 'Title\n';
    nodes.forEach((node) => {
      csv += node + '\n';
    });
    return csv;
  }

  public handleSort(val: { sortCriteria: string; sortOrder: 'ASC' | 'DESC' }) {
    const { sortCriteria, sortOrder } = val;
    this.sortCriteria = sortCriteria;
    this.sortOrder = sortOrder;
    this.updateRouteQuery();
  }

  public updateRouteQuery() {
    this.$router.push({
      query: {
        name: this.filteredName,
        page: String(this.page),
        perPage: String(this.perPage),
        sortCriteria: this.sortCriteria,
        sortOrder: this.sortOrder
      }
    });
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    const { name, page, perPage } = value;
    this.filteredName = name;
    this.perPage = +perPage || 50;
    this.page = +page || 1;

    this.getResearchNodes({
      name,
      page: this.page,
      limit: this.perPage,
      sortCriteria: this.sortCriteria,
      sortOrder: this.sortOrder
    });
  }
}
