




















































































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
  ValidationProvider,
  ValidationObserver,
  ValidationObserverInstance
} from 'vee-validate';
import { get as _get, isEqual as _isEqual } from 'lodash';
import { State, Action } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  ResearchNode,
  DocumentDetail,
  User
} from '@/store/modules/documents/types/documents.types';
import ActiveMultiSelect from './ActiveMultiSelect.vue';
import {
  useDocumentsState,
  useProjectsState,
  useAction
} from '@/utils/store.util';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { DocumentFormMixin } from '@/utils/documentForm.mixin';
import { ProjectsApiState } from '../../store/modules/projects/types/projects.types';
import { EditorCommonMixin } from '../../views/DocumentEditor/mixins/editor-common.mixin';
import { UpdateProjectMixin } from '../../views/DocumentEditor/mixins/update-project.mixin';
import { EditDocumentFormValues } from './editProjectForm.types';

const initialFormValue = {
  title: '',
  type: '',
  primaryResearchNode: null,
  researchNodes: []
};

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ActiveMultiSelect
  }
})
export default class EditProjectForm extends mixins(
  DocumentFormMixin,
  EditorCommonMixin,
  UpdateProjectMixin
) {
  @Prop(String) public modalTitle!: string;

  @State((state: RootState) => state.projects.allResearchNodes)
  public researchNodes!: ResearchNode[];

  public form: EditDocumentFormValues | null = null;

  get secondaryResearchNodesOptions(): ResearchNode[] {
    if (!this.form || !this.form.primaryResearchNode) {
      return [];
    }
    return this.researchNodes.filter(
      (node: ResearchNode) => node.id !== this.form!.primaryResearchNode!.id
    );
  }

  @Watch('projectDetail', { deep: true, immediate: true })
  @Watch('researchNodes', { immediate: true })
  @isDifferent
  @isTruthy
  public onDetailLoaded() {
    if (!this.projectDetail || !this.researchNodes.length) {
      return;
    }
    // initialize form
    this.form = {
      title: this.projectTitle,
      primaryResearchNode: this.researchNodes.find(
        (node) => node.id === this.projectDetail!.primaryResearchNode.id
      ) as ResearchNode,
      researchNodes: this.researchNodes.filter((node) =>
        this.projectDetail!.researchNodes.find((n) => n.id === node.id)
      )
    };
  }

  public handleSubmit() {
    this.editProject({
      ...(this.form as EditDocumentFormValues),
      projectId: this.projectId
    });
  }
}
