



































import SuggestedDocumentModal from '@/components/form/SuggestedDocumentModal.vue';
import EsAlignerFormSearchResultItem from '@/components/form/EsAlignerFormSearchResultItem.vue';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { formatAuthorForDisplay } from '@/jbi-shared/util/document.utils';

@Component({
  components: { SuggestedDocumentModal, EsAlignerFormSearchResultItem }
})
export default class EsAlignerForm extends mixins(EditorCommonMixin) {
  @Prop(String) public modalTitle!: string;
  public selected: DocumentIndexingPayload | null = null;

  get currentDocNodes() {
    return [
      this.documentDetail?.project.primaryResearchNode,
      ...(this.documentDetail?.project.researchNodes || [])
    ]
      .filter(Boolean)
      .map((n) => n?.name)
      .filter(Boolean) as string[];
  }

  get currentDocAuthors() {
    return (this.documentDetail?.revision.sections.authorSubSections || [])
      .map((d) => d.content)
      .map(formatAuthorForDisplay);
  }

  get currentDocumentId() {
    return this.documentDetail?.document.id;
  }
}
