/**
 * Add your local component route name and specific vuex action error mutation types
 * to the following code to disable the global api error handler when the user
 * is on the specific route page and is encountering the specified error types.
 */
export const overridedErrorComponentRoute = [
  {
    routeName: 'document-versions',
    errorTypes: ['documents/GET_DOCUMENT_BY_VERSION_ID_ERROR'],
  },
];
