



























































































import { Component, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { AxiosError } from 'axios';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { RootState } from '@/store/store';
import { User, Invitation } from '@/store/modules/users/types/users.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog
} from 'buefy';
import { get as _get } from 'lodash';
import BaseTable from '@/components/base/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import TableActionDropdown from '@/components/base/TableActionDropdown.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    BaseTable,
    BaseLoading,
    BaseModal,
    BasePagination,
    TableActionDropdown
  }
})
export default class InvitationList extends mixins(PaginationMixin) {
  @Prop() public items!: User[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  @State(({ users }: RootState) => users.apiState.getUsers.success)
  public getUserListingSuccess!: boolean;

  @Action('users/updateStatus')
  public updateStatus!: (userId: number) => void;

  @Action('users/getUserInvitations')
  public getUserInvitations!: (email: string) => void;

  @Action('users/resendInvitation')
  public resendInvitation!: (id: number) => Promise<void>;

  @Action('users/removeInvitation')
  public removeInvitation!: (id: number) => Promise<void>;

  @State(({ users }: RootState) => users.userInvitations)
  public invitations!: Invitation[];

  public formatDate(date: string) {
    return date ? dayjs(date).format('DD MMM YYYY') : '-';
  }

  public toggleStatus({ username, userId, email, status }: User) {
    const isInactive = status === 'Inactive';
    const titleStatus = isInactive
      ? `Activate ${username}`
      : `Deactivate ${username}`;
    const msg = isInactive
      ? `Are you sure you want to activate user '${username}' ?`
      : `Are you sure you want to deactivate user '${username}' ?`;

    return Dialog.confirm({
      title: titleStatus,
      message: msg,
      confirmText: isInactive ? 'Activate' : 'Deactivate',
      type: 'is-primary',
      onConfirm: () => this.updateStatus(userId)
    });
  }

  public handleResend(id: number) {
    if (confirm(`Are you sure that you want to send an email?`)) {
      this.resendInvitation(id)
        .then(() => {
          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `User invitation successfully resend.`
          });
        })
        .catch((error: AxiosError) => {
          Toast.open({
            queue: true,
            type: 'is-danger',
            position: 'is-top',
            message: `Error Resend User Invitation. Error: ${_get(
              error.response,
              'data.message'
            )}`
          });
        });
    }
  }

  public async handleCopy(email: string) {
    await this.getUserInvitations(email);
    const invitations = this.invitations.map((o) => o.link);
    const invitationUrl = invitations?.[0];
    await this.$copyText(invitationUrl)
      .then((e) => {
        Toast.open({
          message: `Link copied successfully`,
          position: 'is-top',
          type: 'is-dark'
        });
      })
      .catch((e) => {
        Toast.open({
          queue: true,
          type: 'is-danger',
          position: 'is-top',
          message: `Error copying link. Please try again later.`
        });
      });
  }

  public async handleRemove(email: string) {
    await this.getUserInvitations(email);
    const invitations = this.invitations.map((o) => o.id);
    const invitationId = invitations?.[0];
    if (confirm(`Are you sure that you want to remove this record?`)) {
      this.removeInvitation(invitationId)
        .then(async () => {
          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `User invitation successfully remove.`
          });
          await this.getUserInvitations(email);
        })
        .catch((error: AxiosError) => {
          Toast.open({
            queue: true,
            type: 'is-danger',
            position: 'is-top',
            message: `Error Remove User Invitation. Error: ${_get(
              error.response,
              'data.message'
            )}`
          });
        });
    }
  }
}
