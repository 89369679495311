































import PdfHeaderDocInfo from './PdfHeaderDocInfo.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { State } from 'vuex-class';
import { PermissionMixin } from '../../../utils/permission.mixin';
import IconButton from '@/components/IconButton.vue';
import { useDocumentsState, useProjectsState } from '@/utils/store.util';
import DocumentNavigationTabs from './DocumentNavigationTabs.vue';
import { GetProjectResponsePayload } from '../../../store/modules/projects/types/project-details.types';
import { RootState } from '@/store/store';
import { useUserName } from '@/utils/user.util';
import EditProjectForm from '@/components/form/EditProjectForm.vue';

@Component({
  components: {
    IconButton,
    PdfHeaderDocInfo,
    DocumentNavigationTabs
  }
})
export default class PdfViewerHeader extends mixins(PermissionMixin) {
  @Prop(Boolean) public isUploading!: boolean;

  @State((state: RootState) => state.projects.projectDetail)
  public project!: GetProjectResponsePayload;

  get projectDocuments() {
    return this.project ? this.project!.projectDocuments : [];
  }

  get documentDetail() {
    return useDocumentsState.call(this).documentDetail;
  }

  get defaultDocDisplayId() {
    if (this.documentDetail) {
      return this.documentDetail!.document.defaultDocDisplayId;
    }
    return '';
  }

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  public handleProjectSetting() {
    this.$buefy.modal.open({
      parent: this,
      component: EditProjectForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Project Settings'
      }
    });
  }
}
