





















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { get as _get } from 'lodash';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import BaseTable from '@/components/base/BaseTable.vue';
import SortableTableHeader from '@/components/base/SortableTableHeader.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import TableActionDropdown from '@/components/base/TableActionDropdown.vue';
import { ApiState } from '@/store/types/general.types';
import { EditorDetails } from '@/jbi-shared/types/editor.types';
import EditorPositionsDialog from '@/views/AdminDashboard/components/EditorPositionsDialog.vue';

@Component({
  components: {
    BaseTable,
    SortableTableHeader,
    BaseLoading,
    BaseModal,
    BasePagination,
    TableActionDropdown
  }
})
export default class EditorList extends mixins(PaginationMixin) {
  @Prop() public items!: EditorDetails[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  @State((state: RootState) => _get(state, 'editors.apiState.getEditors'))
  public getEditors!: ApiState;

  public getTruncatedRoles(item: EditorDetails) {
    if (item.roles.length <= 2) {
      return item.roles;
    } else {
      return item.roles.slice(0, 2);
    }
  }

  public showAllRoles(item: EditorDetails) {
    this.$buefy.modal.open({
      parent: this,
      component: EditorPositionsDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        item
      }
    });
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortCriteria === columnName) {
      return this.$emit('sort', {
        sortCriteria: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortCriteria: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortCriteria === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }
}
