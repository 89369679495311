
































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class WkResourceExportStatusFilterBox extends Vue {
  @Prop(Object) public filterParams!: {
    documentId: string;
    projectName: string;
    status: string;
  };
}
