


























































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { AssignEditorFormValues } from '@/store/modules/admin/types/admin.types';
import DropdownMultiselect from '@/components/DropdownMultiselect.vue';
import { AssignProjectEditorRequestPayload } from '@/store/modules/admin/types/admin.types';
import { useUserEmail } from '@/utils/user.util';
import {
  EditorOptionDetails,
  EditorRole,
  EditorRoleDetails
} from '@/jbi-shared/types/editor.types';
import { FetchEditorOptionsRequestPayload } from '@/store/modules/editors/types/editors.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import EditorPositionsDialog from './EditorPositionsDialog.vue';
import BaseTable from '@/components/base/BaseTable.vue';
import GenericFileUploader, {
  UploadFileFormat
} from '@/components/form/GenericFileUploader.vue';
import { projectHasRp } from '@/utils/project.util';
import MissingContractWarningModal from './MissingContractWarningModal.vue';

const initialFormValue = {
  assignee: null,
  allDueDate: new Date(),
  projects: [],
  zipFileName: undefined,
  includedRps: [],
  includedAuditCriteria: [],
  note: undefined,
  contractStorageUri: undefined
};

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    DropdownMultiselect,
    BaseTable,
    GenericFileUploader
  }
})
export default class AssignEditorForm extends Vue {
  @Prop()
  public projects!: ProjectListItemParam[];

  @Action('admin/assignProjectEditor')
  public assignProjectEditor!: (
    payload: AssignProjectEditorRequestPayload
  ) => void;

  @Action('editors/fetchEditorRoles')
  public fetchEditorRoles!: () => void;

  @Action('editors/fetchEditorOptions')
  public fetchEditorOptions!: (p: FetchEditorOptionsRequestPayload) => void;

  @State((state: RootState) => state.editors.editorRoles)
  public editorRoles!: EditorRoleDetails[];

  @State((state: RootState) => state.editors.editorOptions)
  public editorOptions!: EditorOptionDetails[];

  public form: AssignEditorFormValues = {
    ...initialFormValue
  };

  public editorSearchTerm = '';
  public editorFilterRole: string = EditorRole.AllPositions;
  public contractFormats: UploadFileFormat[] = [
    {
      fileExtension: '.pdf',
      contentType: 'application/pdf'
    },
    {
      fileExtension: '.docx',
      contentType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
  ];

  get filteredEditors() {
    return this.editorOptions;
  }

  get rpDropdownText() {
    if (!this.projectOptionsWithRp.length) {
      return `No Recommended Practice Documents Available`;
    }

    return this.form.includedRps.length === 0
      ? `Do Not Add Recommended Practice Document`
      : `Selected (${this.form.includedRps.length})`;
  }

  get auditCriteriaDropdownText() {
    return this.form.includedAuditCriteria.length === 0
      ? `Do Not Include Audit Criteria`
      : `Selected (${this.form.includedAuditCriteria.length})`;
  }

  get editorRoleOptions() {
    if (!this.editorRoles) {
      return [];
    }
    return ['All Positions', ...this.editorRoles.map((role) => role.name)];
  }

  get isMultipleProjects() {
    return this.projects.length > 1;
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public differentDueDate = false;

  get projectOptions() {
    return this.projects.map((project) => ({
      name: project.projectTitle,
      id: project.projectId
    }));
  }

  get projectOptionsWithRp() {
    return this.projects
      .filter((project) => this.projectHasRp(project))
      .map((project) => ({
        name: project.projectTitle,
        id: project.projectId
      }));
  }

  public setUploadedUri(uri: string) {
    this.form.contractStorageUri = uri;
  }

  public projectHasRp(project: ProjectListItemParam) {
    return projectHasRp(project);
  }

  public getTruncatedRoles(item: EditorOptionDetails) {
    if (item.roles.length <= 3) {
      return item.roles;
    } else {
      return item.roles.slice(0, 3);
    }
  }

  public showAllRoles(item: EditorOptionDetails) {
    this.$buefy.modal.open({
      parent: this,
      component: EditorPositionsDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        item
      }
    });
  }

  public convertToISODate(d: Date) {
    return dayjs(d).format().split('T').shift()!;
  }

  public dateToDisplayedDate(d: Date) {
    return dayjs(d).format('DD MMM YYYY');
  }

  public handleDateSelection(date: Date, projectId: number) {
    const project = this.form.projects.find(
      (proj) => proj.projectId === projectId
    );
    if (project) {
      project.dueDate = this.convertToISODate(date);
    }
  }

  public handleAllDateSelection(date: Date) {
    this.form.allDueDate = date;
  }

  public validateContractAndSubmit() {
    if (!this.form.contractStorageUri) {
      this.$buefy.modal.open({
        parent: this,
        component: MissingContractWarningModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          'proceed-anyway': () => {
            this.handleSubmit();
          }
        }
      });
      return;
    }
    this.handleSubmit();
  }

  public handleSubmit() {
    if (!this.form.assignee) {
      return;
    }
    if (this.isMultipleProjects) {
      if (!this.differentDueDate) {
        this.form.projects.forEach((proj) => {
          proj.dueDate = this.convertToISODate(this.form.allDueDate);
        });
      }
    }

    this.validateEmptyNote();

    // Get the latest selected projectIds
    const projectIds: number[] = this.projects.map(
      (project) => project.projectId
    );

    // Filter out the selected projects from form projects object
    this.form.projects = this.form.projects.filter((project) => {
      return projectIds.includes(project.projectId);
    });

    // Emit assignee name
    this.$emit('updateAssignee', this.form?.assignee?.name);
    this.$emit('countAssignedProjects', this.form?.projects?.length);

    if (this.userEmail) {
      this.assignProjectEditor({
        adminEmail: this.userEmail,
        editorId: this.form.assignee.userId,
        editorEmail: this.form.assignee.email,
        editorName: this.form.assignee.name,
        projects: this.form.projects,
        zipFileName: this.form.zipFileName
          ? this.form.zipFileName.trimEnd()
          : undefined,
        includedRps: this.form.includedRps.map((rp) => rp.id),
        includedAuditCriteria: this.form.includedAuditCriteria.map(
          (ac) => ac.id
        ),
        note: this.form.note,
        contractStorageUri: this.form.contractStorageUri
      });
    }
    this.$emit('close');
  }

  public validateEmptyNote() {
    if (this.form.note) {
      if (this.form.note.trim().length === 0) {
        this.form.note = undefined;
      }
    }
  }

  public handleFetchEditorOptions() {
    this.fetchEditorOptions({
      name: this.editorSearchTerm,
      role: this.editorFilterRole
    });
  }

  @Debounce(500)
  public handleEditorSearch(val: string) {
    this.editorSearchTerm = val;
    this.handleFetchEditorOptions();
  }

  public handleEditorFilterRole(val: string) {
    this.editorFilterRole = val;
    this.handleFetchEditorOptions();
  }

  public mounted() {
    this.handleFetchEditorOptions();
    this.fetchEditorRoles();
    this.form.projects = [];
    this.projects.forEach((proj) => {
      this.form.projects.push({
        projectId: proj.projectId,
        dueDate: this.convertToISODate(new Date()),
        title: proj.projectTitle
      });
    });
    this.form.includedRps = [...this.projectOptionsWithRp];
  }
}
