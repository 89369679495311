











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TagType } from '../../../jbi-shared/types/document.types';

@Component({ components: {} })
export default class TagTypeIndicator extends Vue {
  @Prop() public type!: TagType;

  get TagType() {
    return TagType;
  }
}
