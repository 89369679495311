
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { ExchangeGrantForTokenRequestPayload } from '@/store/modules/auth/types/auth.types';
import { ApiState } from '@/store/types/general.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { getLocationFromCookie } from '@/utils/localstorage.util';

@Component({})
export default class Oauth2Callback extends Vue {
  @Action('auth/exchangeGrantForToken')
  public exchangeGrantForToken!: (
    payload: ExchangeGrantForTokenRequestPayload
  ) => void;

  @Action('auth/getMe')
  public getMe!: () => void;

  @State((state: RootState) => state.auth.apiState.exchangeGrantForToken)
  public exchangeGrantForTokenState!: ApiState;

  public async mounted() {
    const { code } = this.$route.query as { code: string };

    await this.exchangeGrantForToken({
      grant_type: 'authorization_code',
      code,
      client_id: process.env.VUE_APP_JAAS_OAUTH2_CLIENT_ID!,
      client_secret: process.env.VUE_APP_JAAS_OAUTH2_CLIENT_SECRET!,
      redirect_uri: process.env.VUE_APP_FRONTEND_URL + '/oauth2/callback'
    });
  }

  @Watch('exchangeGrantForTokenState.success')
  @isDifferent
  @isTruthy
  public async onExchangeGrantForTokenStateSuccessChanged() {
    const location = await getLocationFromCookie();
    if (location && location.includes('/invitations/accept')) {
      this.$router.push(location);
    } else {
      this.$router.push('/');
    }
  }

  @Watch('exchangeGrantForTokenState.error')
  @isDifferent
  @isTruthy
  public onExchangeGrantForTokenStateErrorChanged() {
    alert('Permission Denied. Please Try Again.');
    this.$router.push('/');
  }

  public render() {
    return null;
  }
}
