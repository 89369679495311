















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import {
  LegacySearchFilters,
  SearchDocumentPayload,
  SearchEsForRpRealignmentPayload
} from '@/store/modules/documents/types/documents.types';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';
import { ApiState } from '@/store/types/general.types';
import { uniqBy } from 'lodash';
import { SuggestedDocumentType } from '@/jbi-shared/util/document.utils';
import { of } from 'rxjs';
import {
  concatMap,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  pairwise,
  startWith,
  take
} from 'rxjs/operators';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {},
  subscriptions() {
    const searchLoadedAtLeastOnce$ = this.$reactiveValueObservable<boolean>(
      'searchSuccess'
    ).pipe(
      pairwise(),
      filter((pair) => !pair[0] && pair[1]),
      take(1)
    );
    const searchTxt$ = this.$reactiveValueObservable<string>('searchTxt').pipe(
      distinctUntilChanged(),
      startWith('')
    );
    const debouncedSearchTxt$ = searchTxt$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    );
    const isLoading$ = this.$reactiveValueObservable<boolean>(
      'searchLoading'
    ).pipe(
      distinctUntilChanged(),
      // anti-flickering
      // if loading is true, display loading for at least 300 milliseconds
      pairwise(),
      concatMap(([previousLoading, currentLoading]) =>
        previousLoading
          ? of(currentLoading).pipe(delay(300))
          : of(currentLoading)
      )
    );
    return {
      searchLoadedOnce: searchLoadedAtLeastOnce$,
      debouncedSearchTxt: debouncedSearchTxt$,
      isLoading: isLoading$
    };
  }
})
export default class SuggestedDocumentModal extends mixins() {
  @Prop(String) public modalTitle!: string;
  @Prop(String) public suggestedDocumentsLabel!: string;
  @Prop(Array) public selected!: DocumentIndexingPayload[];
  @Prop(Function) public closeModal!: () => void;
  @Prop(String) public type!: SuggestedDocumentType;
  @Prop(Array) public currentDocNodes!: string[];
  @Prop(Array) public currentDocAuthors!: string[];
  @Prop(Number) public currentDocumentId!: number;
  @Prop(String) public suggestInput?: string;
  @Prop()
  public dirtyRelatedDocs!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];

  public searchTxt = '';

  @Action('documents/searchNewRelatedDocuments')
  public searchNewRelatedDocuments!: (
    params: SearchDocumentPayload
  ) => Promise<DocumentIndexingPayload[]>;

  @Action('documents/searchEsForRpRealignment')
  public searchEsForRpRealignment!: (
    params: SearchEsForRpRealignmentPayload
  ) => Promise<DocumentIndexingPayload[]>;

  @State(
    (state: RootState) => state.documents.apiState.searchNewRelatedDocuments
  )
  public searchNewRelatedDocumentsState!: ApiState;

  @State(
    (state: RootState) => state.documents.apiState.searchEsForRpRealignment
  )
  public searchEsForRpRealignmentState!: ApiState;

  get searchLoading() {
    switch (this.type) {
      case SuggestedDocumentType.relatedDocuments:
      case SuggestedDocumentType.supportingEvidenceSummaries:
        return this.searchNewRelatedDocumentsState.loading;
      case SuggestedDocumentType.esForRpRealignment:
        return this.searchEsForRpRealignmentState.loading;
    }
    return false;
  }
  get searchSuccess() {
    switch (this.type) {
      case SuggestedDocumentType.relatedDocuments:
      case SuggestedDocumentType.supportingEvidenceSummaries:
        return this.searchNewRelatedDocumentsState.success;
      case SuggestedDocumentType.esForRpRealignment:
        return this.searchEsForRpRealignmentState.success;
    }
    return false;
  }

  get searchResults(): DocumentIndexingPayload[] {
    switch (this.type) {
      case SuggestedDocumentType.relatedDocuments:
      case SuggestedDocumentType.supportingEvidenceSummaries:
        return (
          (this.$store.state as RootState).documents
            .relatedDocumentSearchResult || []
        );
      case SuggestedDocumentType.esForRpRealignment:
        return (
          (this.$store.state as RootState).documents
            .esForAlignmentSearchResult || []
        );
    }
    return [];
  }

  get filteredSearchResult() {
    let relatedDocumentIds: number[] = [];

    if (this.dirtyRelatedDocs) {
      relatedDocumentIds = this.dirtyRelatedDocs.map((relatedDoc) => {
        return relatedDoc.relatedDocs.id;
      });
    }
    let result = this.searchResults;
    // remove self
    result = result.filter((doc) => doc.documentId !== this.currentDocumentId);
    result = result.filter(
      (doc) => !relatedDocumentIds.includes(doc.documentId)
    );

    // remove dupes
    result = uniqBy(result, (doc) => doc.documentId);
    return result;
  }

  // previously selected items that is not in search result
  get otherSelectedDocs() {
    if (!this.selected) {
      return [];
    }
    return this.selected.filter(({ documentId }) =>
      this.filteredSearchResult.every(({ documentId: id }) => id !== documentId)
    );
  }

  get hasSuggestedDocument() {
    return !this.searchTxt && this.filteredSearchResult.length;
  }

  public created() {
    if (this.suggestInput && this.suggestInput.length > 0) {
      this.searchTxt = this.suggestInput;
    }
    this.$observables.debouncedSearchTxt.subscribe((text: string) => {
      switch (this.type) {
        case SuggestedDocumentType.relatedDocuments:
          // This is used for Related JBI Evidence Summaries section in ES doc
          this.searchNewRelatedDocuments({
            text,
            revisionStatus: RevisionPublicationStatus.Published,
            legacyFilter: LegacySearchFilters['All Documents']
          });
          break;
        case SuggestedDocumentType.supportingEvidenceSummaries:
          // This is used for Supporting Evidence Summaries section in RP doc
          this.searchNewRelatedDocuments({
            text,
            revisionStatus: RevisionPublicationStatus.Published,
            legacyFilter: LegacySearchFilters['All Documents'],
            documentTypes: [CplusDocumentType.EvidenceSummary]
          });
          break;
        case SuggestedDocumentType.esForRpRealignment:
          this.searchEsForRpRealignment({
            text,
            nodes: this.currentDocNodes,
            authors: this.currentDocAuthors
          });
          break;
      }
    });
  }

  @isDifferent
  @isTruthy
  @Watch('filteredSearchResult')
  public onSearchSuccess() {
    this.$emit('resultUpdated');
  }
}
