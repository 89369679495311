































































































import { CplusDocumentTypeShortHand } from '@/jbi-shared/types/document.types';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { StagingEditorCommonMixin } from '../mixins/staging-editor-common.mixin';
import AssigneeInput from '@/views/DocumentEditor/components/AssigneeInput.vue';
import UploadTdrForm from '@/components/form/UploadTdrForm.vue';

@Component({
  components: {
    AssigneeInput
  }
})
export default class StagingEditorHeaderDocInfo extends mixins(
  StagingEditorCommonMixin
) {
  get defaultDocDisplayId() {
    if (!this.documentDetail) {
      return;
    }
    const existingCplusDocument = this.getExistingCplusDocumentFromPendingDocument(
      this.documentDetail!
    );
    if (!existingCplusDocument) {
      return `New`;
    } else {
      return `JBI-${
        CplusDocumentTypeShortHand[existingCplusDocument.documentType]
      }-${existingCplusDocument.id}`;
    }
  }

  get updatePublishStatusLoading() {
    return false;
  }

  get resourceUrl() {
    if (this.tdrUri) {
      return this.tdrUri.replace('gs://', 'https://storage.googleapis.com/');
    }
    return '';
  }

  public openUploadTdr() {
    this.$buefy.modal.open({
      parent: this,
      component: UploadTdrForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Edit Technical Development Reports'
      },
      events: {
        'update:tdr': this.handleUpdateTdr
      }
    });
  }

  public handleUpdateTdr(tdrUri: string) {
    this.tdrUri = tdrUri;
  }
}
