










import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import AssignEditorForm from '@/views/AdminDashboard/components/AssignEditorForm.vue';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';

@Component({
  components: {
    BaseModal,
    ValidationObserver,
    AssignEditorForm
  }
})
export default class AssignEditorButton extends Vue {
  @Prop(Array)
  public selectedProjects!: ProjectListItemParam[];

  public openAssignEditorForm() {
    this.$buefy.modal.open({
      parent: this,
      component: AssignEditorForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        projects: this.selectedProjects
      },
      events: {
        removeSelected: (value: ProjectListItemParam) => {
          this.$emit('removeSelected', value);
        },
        updateAssignee: (value: string) => {
          this.$emit('updateAssignee', value);
        },
        countAssignedProjects: (value: number) => {
          this.$emit('countAssignedProjects', value);
        }
      }
    });
  }
}
