















































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  SearchCheckboxOptions,
  StringInputOption
} from '@/jbi-shared/types/form.types';
import { differenceBy } from 'lodash';

const DEFAULT_LISTING_SIZE = 5;

@Component({})
export default class SearchFilterCheckboxes extends Vue {
  @Prop(String) public label!: string;
  @Prop(Array) public options!: SearchCheckboxOptions[];
  @Prop(Array) public value!: SearchCheckboxOptions[];

  public showingAll = false;

  get selected() {
    return this.value.map((v) => v.id!);
  }

  set selected(value: string[]) {
    this.$emit('input', [
      ...value
        .map((str) => this.options.find((o) => o.id === str))
        .filter(Boolean)
    ]);
  }

  get selectedOptions(): SearchCheckboxOptions[] {
    return this.value;
  }

  get nonSelectedOptions(): SearchCheckboxOptions[] {
    return differenceBy(this.options, this.selectedOptions, 'id');
  }

  get displayedOptions(): SearchCheckboxOptions[] {
    if (this.showingAll) {
      return this.options;
    }
    if (this.selectedOptions.length >= DEFAULT_LISTING_SIZE) {
      return this.selectedOptions;
    }
    return [...this.selectedOptions, ...this.nonSelectedOptions].filter(
      (_, i) => i < DEFAULT_LISTING_SIZE
    );
  }

  get showViewAllOption() {
    if (this.displayedOptions.length < 5) {
      return false;
    }
    return true;
  }

  public isDisabled(optionValue: string) {
    if (optionValue === 'None') {
      return true;
    }
    return false;
  }

  public clearFilter() {
    this.$emit('input', []);
  }
}
