var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.container]},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:_vm.$style.colLeft},[_c('div',{class:_vm.$style.roundedImage},[_c('span',[_vm._v(_vm._s(_vm.initials))])])]),_c('div',{staticClass:"column",class:_vm.$style.colRight},[_c('div',[_c('p',{class:_vm.$style.logReviseDate},[(
              _vm.formatDate(this.eventLog.eventOccuredAt) === _vm.formatDate(_vm.today)
            )?_c('span',[_vm._v(" "+_vm._s(_vm.formatTime)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(this.eventLog.eventOccuredAt))+" ")])])]),_c('div',{class:[_vm.$style.projectTitle]},[(
            _vm.eventLog.eventType === _vm.EVENT_LOG_TYPE.UPDATE_DOCUMENT_ASSIGNEE
          )?[_c('router-link',{class:['is-block'],attrs:{"to":{
              name: 'editor',
              params: {
                documentId: _vm.eventLog.eventPayload.documentId,
                projectId: _vm.eventLog.eventPayload.projectId
              }
            }}},[(_vm.eventLog.eventPayload.assignee)?[_c('b',[_vm._v(_vm._s(_vm.getFullNameFromUser(_vm.eventLog.originator)))]),_vm._v(" changed the Assignee to "),_c('b',[_vm._v(_vm._s(_vm.getFullNameFromUser(_vm.eventLog.eventPayload.assignee)))]),_vm._v(" on "),_c('b',[_vm._v(_vm._s(_vm.CplusDocumentTypeDisplayName[ _vm.eventLog.eventPayload.documentType ]))]),_vm._v(" - "),_c('b',[_vm._v(_vm._s(_vm.eventLog.eventPayload.documentTitle))])]:[_c('b',[_vm._v(_vm._s(_vm.getFullNameFromUser(_vm.eventLog.originator)))]),_vm._v(" removed the Assignee of "),_c('b',[_vm._v(_vm._s(_vm.CplusDocumentTypeDisplayName[ _vm.eventLog.eventPayload.documentType ]))]),_vm._v(" - "),_c('b',[_vm._v(_vm._s(_vm.eventLog.eventPayload.documentTitle))])]],2)]:(
            _vm.eventLog.eventType === _vm.EVENT_LOG_TYPE.UPDATE_DOCUMENT_STATUS
          )?[_c('router-link',{class:['is-block'],attrs:{"to":{
              name: 'editor',
              params: {
                documentId: _vm.eventLog.eventPayload.documentId,
                projectId: _vm.eventLog.eventPayload.projectId
              }
            }}},[_c('b',[_vm._v(_vm._s(_vm.getFullNameFromUser(_vm.eventLog.originator)))]),_vm._v(" updated the status of "),_c('b',[_vm._v(_vm._s(_vm.CplusDocumentTypeDisplayName[_vm.eventLog.eventPayload.documentType]))]),_vm._v(" for "),_c('b',[_vm._v(_vm._s(_vm.eventLog.eventPayload.documentTitle))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(_vm.eventLog.eventPayload.documentStatus))])])]:_vm._e()],2),_c('div',{class:_vm.$style.documentUniqueId},[_c('p',{class:_vm.$style.uniqueId},[_vm._v(" "+_vm._s(_vm.uniqueDocId)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }