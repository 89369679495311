import { Component, Watch } from 'vue-property-decorator';
import { pairwise, map, filter } from 'rxjs/operators';
import { cloneDeep as _cloneDeep, get as _get, debounce } from 'lodash';
import { mixins } from 'vue-class-component';
import { EditorStateMixin } from './editor-state.mixin';
import { EditorComputedValuesMixin } from './editor-computed-values.mixin';
import {
  isDifferent,
  isTruthy,
  isDifferentDeep
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { DocumentDetail } from '@/store/modules/documents/types/documents.types';
import { handleCitationLabelInOps } from '../../../utils/editor.util';
import { FullDocumentRevisionObject } from '../../../jbi-shared/types/full-document-revision-object.types';
import { Action } from 'vuex-class';
import { DocumentIndexingPayload } from '../../../jbi-shared/types/search.types';

@Component({})
export class EditorWatcherMixin extends mixins(
  EditorStateMixin,
  EditorComputedValuesMixin
) {
  @Action('documents/getAlignedEsByEsId')
  public getAlignedEsByEsId!: (
    esId: number
  ) => Promise<DocumentIndexingPayload>;

  @isDifferent
  @isTruthy
  @Watch('documentDetail')
  @Watch('documentByRevisionId')
  @Watch('documentByPublicationId')
  public onDocumentLoaded(doc: DocumentDetail) {
    this.resetEditorContent(doc);
    this.updateRouteAfterFetch();
  }

  @Watch('usedCitationIds')
  @isDifferentDeep
  @isTruthy
  public onUsedCitationIdsChanged() {
    this.updateCitationNumbers();
  }

  @Watch('alignedEsId')
  @isDifferent
  @isTruthy
  public onAlignedEsIdChanged(alignedEsId: number) {
    this.getAlignedEsByEsId(alignedEsId);
  }

  public created() {
    // rerender editor after projectId changed
    const projectIdChanged$ = this.$reactiveValueObservable<
      FullDocumentRevisionObject
    >('documentDetail').pipe(
      map((d) => d?.project.id),
      pairwise(),
      map(([prev, curr]) => prev !== curr),
      filter(Boolean)
    );
    projectIdChanged$.subscribe(() => {
      this.editorContainerKey = Math.random();
    });
  }

  public resetEditorContent(doc: DocumentDetail) {
    if (!doc.revision.sections) {
      return;
    }
    this.resetEditorUndoRedoState();
    this.dirtyTextSections = _cloneDeep(doc.revision.sections.textSections);
    this.dirtyBprs = _cloneDeep(doc.revision.sections.bprSection || []);
    this.dirtyDocumentTitle = doc.document.title;
    this.dirtySearchDate = doc.revision.searchDate;
    this.dirtyCriterions = doc.revision.sections.criterionSection;
    this.dirtyRelatedDocs = doc.revision.sections.relatedDocSubSections;
    this.dirtyTagMaps = _cloneDeep(this.originalTagMaps) as any;
    this.dirtyAuthors = doc?.revision.sections?.authorSubSections || [];
    this.dirtyOhs = doc?.revision?.sections.ohsSection?.ohsAssetIds || [];
    this.tdrUri = doc.revision.tdrUri;
    this.updateCitationNumbers();
  }

  public updateCitationNumbers() {
    this.dirtyTextSections = this.dirtyTextSections.map((textSection) => {
      let { sectionValue } = textSection;
      sectionValue = handleCitationLabelInOps({
        ops: sectionValue || [],
        uniqUsedCitationIds: this.uniqUsedCitationIds
      });
      return { ...textSection, sectionValue };
    });
  }

  public updateRouteAfterFetch() {
    const projectId = this.documentDetail?.project.id;
    if (this.$route.name === 'editor') {
      // Fix projectId in route after RP realign to a different ES
      if (projectId && +this.$route.params.projectId !== projectId) {
        this.$router.replace({
          ...this.$route,
          params: { ...this.$route.params, projectId: String(projectId) }
        });
      }
    }
  }
}
