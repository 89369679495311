import { useAction, useGetter } from '@/utils/store.util';
import { useWebsocket } from '@/utils/websocket.util';
import { EVENTS } from '../store/modules/websocket/websocket.state';
import { useCitations } from './citations.util';
import Vue from 'vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { Job } from 'bull';
import { WorkerJobStatus } from '../jbi-shared/types/cplus-worker.types';

const displayToastError = () => {
  Toast.open({
    message: `Error processing. Please try again later.`,
    duration: 3000,
    type: 'is-danger',
  });
};

export const handleCitationUploading = async function(this: Vue, job: Job) {
  if (!job) {
    displayToastError();
  }

  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);
  const { getCitationsForCurrentProject } = useCitations.call(this);
  const { id: jobId } = job;

  const markWorkerJobAsCompleted: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsCompleted',
  );
  const markWorkerJobAsErrored: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsErrored',
  );
  const resetUploadCitationState = useAction.call(
    this,
    'projects/resetUploadCitationState',
  );

  await connectToWs();

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(EVENTS.UPLOAD_CITATIONS_EVENT)) as Job;
  } catch (error) {
    markWorkerJobAsErrored(job);
    displayToastError();
    await disconnectWs();
    return;
  }

  const { status, message, metadata } = job.returnvalue;

  if (status === WorkerJobStatus.PROCESSED) {
    markWorkerJobAsCompleted(job);
    Toast.open({
      queue: true,
      position: 'is-top',
      message: `Citation import success`,
    });
    getCitationsForCurrentProject();
  } else if (status === WorkerJobStatus.INVALID_TYPE) {
    markWorkerJobAsErrored(job);
    Toast.open({
      message,
      duration: 3000,
      type: 'is-danger',
    });
  } else {
    markWorkerJobAsErrored(job);
    displayToastError();
  }
  resetUploadCitationState();
  await disconnectWs();

  return {};
};
