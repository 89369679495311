import Component, { mixins } from 'vue-class-component';
import { StagingEditorChangeDetectorMixin } from './staging-editor-change-detector.mixin';
import { StagingEditorComputedValuesMixin } from './staging-editor-computed-values.mixin';
import { StagingEditorStateMixin } from './staging-editor-state.mixin';
import { StagingEditorUndoRedoChildMixin } from './staging-editor-undo-redo-child.mixin';
import { StagingEditorWatcherMixin } from './staging-editor-watcher.mixin';

@Component({})
export class StagingEditorCommonMixin extends mixins(
  StagingEditorStateMixin,
  StagingEditorComputedValuesMixin,
  StagingEditorChangeDetectorMixin,
  StagingEditorWatcherMixin,
  StagingEditorUndoRedoChildMixin
) {}
