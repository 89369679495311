




























































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import {
  GetSignedUrlForUploadRequestPayload,
  GetSignedUrlForUploadResponsePayload
} from '../../store/modules/static-file/types/static-file.types';
import { Action } from 'vuex-class';
import UploadError from '@/views/DocumentEditor/components/tab/citations/UploadError.vue';
import UploadLoading from '@/views/DocumentEditor/components/tab/citations/UploadLoading.vue';
import UploadTimeout from '@/views/DocumentEditor/components/tab/citations/UploadTimeout.vue';
import { RootState } from '../../store/store';
import { DocumentsApiState } from '../../store/modules/documents/types/documents.types';

export interface UploadFileFormat {
  fileExtension: string;
  contentType: string;
}

@Component({
  components: {
    UploadError,
    UploadLoading,
    UploadTimeout
  }
})
export default class GenericFileUploader extends Vue {
  @Prop({
    default: () => {
      return [
        {
          fileExtension: '.pdf',
          contentType: 'application/pdf'
        }
      ];
    }
  })
  public acceptedFormats!: UploadFileFormat[];

  public file: File | Blob | null = null;
  public isLoading: boolean = false;
  public isSuccess: boolean = false;
  public isFailed: boolean = false;
  public error = '';

  @Action('staticFile/getSignedUrlForUploads')
  public getSignedUrlForUploads!: (
    d: GetSignedUrlForUploadRequestPayload
  ) => Promise<GetSignedUrlForUploadResponsePayload>;

  get acceptedFileExtensions() {
    return this.acceptedFormats.map((format) => format.fileExtension);
  }

  @Watch('file')
  public async onFileSelected() {
    if (!this.file) {
      return;
    }
    const contentType = (this.file as File).type;
    const fileSize = (this.file as File).size;

    // TODO: find better way to implement this or use vuex for this
    // investigate input with accept="application/pdf is cause browser hang
    if (
      !this.acceptedFormats
        .map((format) => format.contentType)
        .includes(contentType)
    ) {
      this.error = 'Unsupported document type';
      this.isFailed = true;
      this.$emit('uploaded', null);
      return;
    }

    if (fileSize > 50000000) {
      this.error = 'File size exceeded the 50MB limit';
      this.isFailed = true;
      this.$emit('uploaded', null);
      return;
    }

    const fileName = (this.file as File).name;
    // Check if the file name contains special characters

    const format: RegExp = /[~`!#$%\^&*+=\\[\]\\';,/{}|\\"<>\?]/g;
    if (format.test(fileName)) {
      this.error = 'Invalid file name,contains special characters';
      this.isFailed = true;
      this.$emit('uploaded', null);
      return;
    }

    const signedUrlData = await this.getSignedUrlForUploads({
      fileName,
      contentType
    });
    const {
      signedUrl: signedUrlForUpload,
      storageUrl,
      storageUri
    } = signedUrlData;

    const formData = new FormData();
    formData.append('file', this.file as Blob);

    await this.uploadToGcs(this.file, signedUrlForUpload);

    this.$emit('uploaded', signedUrlData);
  }

  public handleRetry() {
    this.isFailed = false;
    this.file = null;
    this.isSuccess = false;
  }

  public uploadToGcs(blobOrFile: File | Blob, url: string) {
    return new Promise((resolve, reject) => {
      this.isLoading = true;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);

      xhr.onload = (e) => {
        // tslint:disable-next-line
        console.log(`Upload Completed for ${(blobOrFile as File).name}`);
        this.isLoading = false;
        this.isSuccess = true;
        return resolve(true);
      };

      xhr.onerror = (error) => {
        // console.log({error })
        this.isFailed = true;
        return reject(error);
      };

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
          // tslint:disable-next-line
          console.log(
            `${progress}% Upload Progress for ${(blobOrFile as File).name}`
          );
        }
      };

      xhr.send(blobOrFile);
    });
  }
}
