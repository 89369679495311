
































import { Component, Vue, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import OhsMultiSelect from '@/components/editor/SectionEditor/OhsSectionEditor/OhsMultiSelect.vue';
import OhsDisplaySection from '@/components/editor/SectionEditor/OhsSectionEditor/OhsDisplaySection.vue';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import Op from 'quill-delta/dist/Op';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { useDocumentsState, useAction, useApiState } from '@/utils/store.util';
import { OhsAsset } from '@/store/modules/documents/types/ohs.types';

@Component({
  components: {
    draggable,
    OhsMultiSelect,
    OhsDisplaySection
  }
})
export default class OhsEditor extends mixins(ViewModeMixin) {
  @Prop()
  public ohsAssets!: OhsAsset[];
  @Prop()
  public dirtyOhs!: number[];

  public drag = false;

  get getOhs(): () => Promise<any> {
    return useAction.call(this, 'documents/getOhsAssets');
  }

  get getOhsState() {
    return useApiState.call(this, 'documents/getOhsAssets');
  }

  public openAddNewModal() {
    this.$buefy.modal.open({
      parent: this,
      component: OhsMultiSelect,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Occupational Health and Safety Considerations',
        dirtyOhs: this.dirtyOhs,
        ohsAssets: this.ohsAssets
      },
      events: {
        'update:dirtyOhs': (e: number[]) => this.$emit('update:dirtyOhs', e)
      }
    });
  }

  public async mounted() {
    if (!this.ohsAssets || !this.ohsAssets.length) {
      await this.getOhs();
    }
  }
}
