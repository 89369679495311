




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import MeshSearcher from '@/components/editor/SectionEditor/MeshSearcher.vue';
import MeshTree from '@/components/editor/SectionEditor/MeshTree.vue';
import MeshSearchResult from '@/components/editor/SectionEditor/MeshSearchResult.vue';
import { uniq } from 'lodash';

@Component({ components: { MeshSearcher, MeshTree, MeshSearchResult } })
export default class MeshConceptModal extends Vue {
  @Prop() public addTagMap!: (e: string | DirtyTagMap) => void;
  @Prop() public selectedPaths!: string[];
  @Prop() public selectPath!: (e: string) => void;
  @Prop() public filterTag!: any;
  @Prop() public allMeshOptions!: DirtyTagMap[];
  @Prop() public initialMeshSearch!: string;

  public meshUiType: 'search' | 'tree' | null = 'search';
  public meshSearch: string = this.initialMeshSearch || '';
  public activeMeshTreePaths: string[] = [];

  get filteredMeshSearchResult(): DirtyTagMap[] {
    return this.meshSearch
      ? this.filterTag(
          this.allMeshOptions,
          this.meshSearch,
          Number.MAX_SAFE_INTEGER
        )
      : [];
  }
  public activateMeshTreePath(path: string) {
    this.activeMeshTreePaths.push(path);
    this.activeMeshTreePaths = uniq(this.activeMeshTreePaths);
  }
  public deactivateMeshTreePath(path: string) {
    this.activeMeshTreePaths = this.activeMeshTreePaths.filter(
      (p) => p !== path && !p.startsWith(path)
    );
    this.activeMeshTreePaths = uniq(this.activeMeshTreePaths);
  }
}
