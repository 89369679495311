import { Store } from 'vuex';
import { authApi } from '@/api/auth.api';
import {
  ExchangeGrantForTokenRequestPayload,
  AuthState,
  ExchangeGrantForTokenResponsePayload,
  GetMeResponsePayload
} from './types/auth.types';

export const authActions = {
  async exchangeGrantForToken(
    store: Store<AuthState>,
    payload: ExchangeGrantForTokenRequestPayload
  ) {
    try {
      store.commit('EXCHANGE_GRANT_FOR_TOKEN_LOADING');

      const {
        data
      }: {
        data: ExchangeGrantForTokenResponsePayload;
      } = await authApi.exchangeGrantForToken(payload);

      store.commit('EXCHANGE_GRANT_FOR_TOKEN_SUCCESS', data);
    } catch (error) {
      store.commit('EXCHANGE_GRANT_FOR_TOKEN_ERROR', error);
    }
  },
  async signOut(store: Store<AuthState>) {
    try {
      store.commit('SIGN_OUT_LOADING');

      await authApi.signOut();

      store.commit('SIGN_OUT_SUCCESS');
      store.dispatch('users/resetMyRoles', {}, { root: true });
    } catch (error) {
      store.commit('SIGN_OUT_ERROR', error);
    }
  },
  async checkSessionHealth(store: Store<AuthState>) {
    try {
      store.commit('CHECK_SESSION_HEALTH_LOADING');

      await authApi.checkSessionHealth();

      store.commit('CHECK_SESSION_HEALTH_SUCCESS');
    } catch (error) {
      store.commit('CHECK_SESSION_HEALTH_ERROR', error);
    }
  },
  async getJaasAccountDetails(store: Store<AuthState>) {
    try {
      store.commit('GET_JAAS_ACCOUNT_DETAIL_LOADING');

      const {
        data
      }: {
        data: ExchangeGrantForTokenResponsePayload;
      } = await authApi.getJaasAccountDetails();

      store.commit('GET_JAAS_ACCOUNT_DETAIL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_JAAS_ACCOUNT_DETAIL_ERROR', error);
    }
  },
  async getMe(store: Store<AuthState>) {
    try {
      store.commit('GET_ME_LOADING');

      const { data }: { data: GetMeResponsePayload } = await authApi.getMe();

      store.commit('GET_ME_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ME_ERROR', error);
    }
  }
};
