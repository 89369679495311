








































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { StringInputOption, InputOption } from '@/jbi-shared/types/form.types';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {} from 'lodash';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import {
  CplusDocumentType,
  CplusDocumentTypeDisplayName
} from '@/jbi-shared/types/document.types';
import { CreateSubDocumentRequestPayload } from '../../../store/modules/projects/types/projects.types';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../store/store';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '../mixins/editor-common.mixin';

interface Form {
  type: StringInputOption | null;
  title: string;
}

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseMultiSelect
  }
})
export default class NewSubDocForm extends mixins(EditorCommonMixin) {
  @Prop(String) public modalTitle!: string;

  public form: Form = {
    type: null,
    title: ''
  };

  @Action('projects/createSubDocument')
  public createSubDocument!: (payload: CreateSubDocumentRequestPayload) => void;

  get createSubDocumentLoading() {
    return (this.$store.state as RootState).projects.apiState.createSubDocument
      .loading;
  }

  get createSubDocumentSuccess() {
    return (this.$store.state as RootState).projects.apiState.createSubDocument
      .success;
  }

  get createSubDocumentError() {
    return (this.$store.state as RootState).projects.apiState.createSubDocument
      .error;
  }

  get documentTypeOptions(): StringInputOption[] {
    return Object.values(CplusDocumentType)
      .filter((type) => [CplusDocumentType.RecommendedPractice].includes(type))
      .map((type) => ({
        name: CplusDocumentTypeDisplayName[type],
        id: type
      }));
  }

  public handleSubmit() {
    this.createSubDocument({
      projectId: this.projectId,
      title: this.form.title
    });
  }

  @Watch('createSubDocumentSuccess')
  public onSuccess() {
    this.$emit('close');
  }

  @Watch('createSubDocumentError')
  public onError() {
    Toast.open({
      type: 'is-danger',
      position: 'is-top',
      message: `Error adding document. Please try again later.`
    });
  }
}
