


































import Component, { mixins } from 'vue-class-component';
import dayjs from 'dayjs';
import { Prop, Watch } from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import EmptyListingBox from '@/components/listings/EmptyListingBox.vue';
import { DocumentExportType } from '@/store/modules/documents/types/documents.types';
import { useUserName } from '@/utils/user.util';
import { useGetter } from '@/utils/store.util';
import SearchResultListItem, {
  SearchResultListItemParam
} from '@/components/listings/search/SearchResultListItem.vue';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

import { ToastProgrammatic as Toast } from 'buefy';
import { RootState } from '@/store/store';

@Component({
  components: {
    BasePagination,
    SearchResultListItem,
    EmptyListingBox
  }
})
export default class SearchResultList extends mixins(PaginationMixin) {
  @Prop(Array) public items!: SearchResultListItemParam[];
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  get dayjs() {
    return dayjs;
  }

  public handleExport(
    type: DocumentExportType,
    item: SearchResultListItemParam
  ) {
    this.$emit('export', { type, item });
  }

  get updateDocAssigneeSuccess() {
    return (this.$store.state as RootState).documents.apiState.updateDocAssignee
      .success;
  }
  get updateDocAssigneeError() {
    return (this.$store.state as RootState).documents.apiState.updateDocAssignee
      .error;
  }

  @isDifferent
  @isTruthy
  @Watch('updateDocAssigneeSuccess')
  public onDocAssigneeUpdateSuccess(value: any) {
    const message = `Assigned user changed successfully.`;
    Toast.open({
      message,
      position: 'is-top',
      type: 'is-dark'
    });
  }

  @isDifferent
  @isTruthy
  @Watch('updateDocAssigneeError')
  public onDocAssigneeUpdateError() {
    const message = `Failed to change assigned user.`;
    Toast.open({
      message,
      position: 'is-top',
      type: 'is-danger'
    });
  }
}
