


















































import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { UnassignProjectEditorRequestPayload } from '@/store/modules/admin/types/admin.types';
import { Action } from 'vuex-class';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { useUserEmail } from '@/utils/user.util';

@Component({})
export default class UnassignEditorForm extends Vue {
  @Prop()
  public item!: ProjectListItemParam;

  @Action('admin/unassignProjectEditor')
  public unassignProjectEditor!: (
    payload: UnassignProjectEditorRequestPayload
  ) => void;

  public note = '';

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public handleUnassign(modal: any) {
    this.$emit('unassignProject', {
      ...this.item,
      unassignNote: this.note
    });
    modal.close();
  }
}
