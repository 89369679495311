
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DocumentPublishValidationModal extends Vue {
  @Prop(String) public modalTitle!: string;
}
