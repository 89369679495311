























































import { Component } from 'vue-property-decorator';
import { useUserName } from '@/utils/user.util';
import ExportBtn from '@/views/DocumentEditor/components/ProjectEditorHeaderDocExportBtn.vue';
import { getInitialFromName } from '@/utils/project.util';
import CollaboratorsThumbnails from '@/components/CollaboratorsThumbnails.vue';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { mixins } from 'vue-class-component';
import { PermissionMixin } from '../../../utils/permission.mixin';
import { EditorCommonMixin } from '../../DocumentEditor/mixins/editor-common.mixin';

@Component({
  components: { ExportBtn, CollaboratorsThumbnails }
})
export default class VersionStickyNavbar extends mixins(
  PermissionMixin,
  EditorCommonMixin
) {
  public modalIsOpened = false;

  get CplusDocumentType() {
    return CplusDocumentType;
  }

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get getInitialFromName() {
    return getInitialFromName;
  }

  get documentOwner() {
    return this.documentDetail?.document?.owner;
  }
}
