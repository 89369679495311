var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body",class:_vm.$style.modalBody},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Document Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Document Type","data-test":"document-type-selector","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('BaseMultiSelect',{attrs:{"options":_vm.documentTypeOptions,"placeholder":"Select document type","maxHeight":130},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Document Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Document Title","data-test":"sub-document-title-input","type":errors[0] ? 'is-danger' : undefined,"message":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"input",attrs:{"placeholder":"Enter document title","maxlength":"200"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}})])]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.createSubDocumentLoading },attrs:{"disabled":validator.invalid,"data-text":"add-sub-document-button"},on:{"click":function($event){validator.validate().then(function (validated) {
            if (validated === false) { return; }
            _vm.handleSubmit();
          })}}},[_vm._v(" Add Document ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }