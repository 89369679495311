












import { Component, Vue, Watch, Provide } from 'vue-property-decorator';
import { Action, State, Getter } from 'vuex-class';
import {
  useDocumentsState,
  useProjectsState,
  useAction
} from '@/utils/store.util';
import { ResearchNode } from '@/store/modules/documents/types/documents.types';
import { get as _get } from 'lodash';
import DocumentEditor from './EditorContainer.vue';
import PdfViewer from './PdfViewerContainer.vue';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { RootState } from '@/store/store';
import { UserInvitationPayload } from '@/store/modules/users/types/users.types';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from './mixins/editor-common.mixin';
import { Route } from 'vue-router';
import { EditorUndoRedoRootMixin } from './mixins/editor-undo-redo-root.mixin';
import { DocumentDetail } from '../../store/modules/documents/types/documents.types';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';

// hooks only available on view that map to router
Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    DocumentEditor,
    PdfViewer
  }
})
export default class DocumentEditorPage extends mixins(
  EditorCommonMixin,
  EditorUndoRedoRootMixin
) {
  // @ts-ignore
  get isRootPage() {
    return true;
  }

  get CplusDocumentType() {
    return CplusDocumentType;
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get documentTitle(): string {
    return this.documentDetail?.document?.title || '';
  }

  get pageTitle(): string {
    return this.documentTitle || `Editor`;
  }

  get isEditorMode() {
    return [
      CplusDocumentType.EvidenceSummary,
      CplusDocumentType.RecommendedPractice
    ].includes(this.documentType);
  }

  @Action('documents/getDocumentDetail')
  public getDocumentDetail!: (id: number) => Promise<DocumentDetail>;

  @Action('documents/resetDocumentDetail')
  public resetDocumentDetail!: () => void;

  @Action('projects/getAllResearchNodes')
  public getResearchNodes!: () => void;

  @Action('projects/getProject')
  public getProject!: (projectId: number) => void;

  get message() {
    return 'There are unsaved changes in your document. You will lose your changes if you leave.';
  }

  public async created() {
    await this.resetDocumentDetail();

    this.getDocumentDetail(this.documentId);
    this.getResearchNodes();

    this.$reactiveValueObservable<number>('projectId')
      .pipe(distinctUntilChanged())
      .subscribe((projectId) => this.getProject(this.projectId));

    // @ts-ignore // check before close tab
    window.onbeforeunload = this.onBeforeUnload;
  }

  public destroyed() {
    window.onbeforeunload = null;
  }

  public onBeforeUnload(
    windowEventHandlers: WindowEventHandlers,
    beforeUnloadEvent: BeforeUnloadEvent
  ) {
    // @ts-ignore
    if (this.isEditorMode && this.contentHasChanged) {
      return this.message;
    }
  }

  public beforeRouteLeave(
    to: Route,
    from: Route,
    next: (value?: boolean) => void
  ) {
    if (this.isEditorMode && this.contentHasChanged) {
      return next(confirm(this.message));
    }
    next();
  }
}
