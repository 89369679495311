



































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import { StringInputOption, InputOption } from '@/jbi-shared/types/form.types';
import { cloneDeep } from 'lodash';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { set as _set } from 'lodash';
import { uniq as _uniq, isEqual as _isEqual, pick as _pick } from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { opsToText, textToOps } from '@/utils/quill-delta.util';
import { mixins } from 'vue-class-component';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { useDocumentsState, useAction, useApiState } from '@/utils/store.util';
import { OhsAsset } from '../../../../store/modules/documents/types/ohs.types';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseMultiSelect,
    TagsEditor
  }
})
export default class OhsMultiSelect extends mixins() {
  @Prop(String) public modalTitle!: string;
  @Prop()
  public dirtyOhs!: number[];
  @Prop()
  public ohsAssets!: OhsAsset[];

  public tempDirtyOhs: number[] = [];
  public ohsSearch = '';

  public addOhs() {
    const updatedOhs = this.tempDirtyOhs;
    this.$emit('update:dirtyOhs', updatedOhs);
    this.$emit('close');
  }

  public handleClose() {
    // @ts-ignore
    this.$parent.close();
  }

  get addOhsButtonText() {
    return this.dirtyOhs.length > 0 ? 'Save' : 'Add Considerations';
  }

  get filteredOhs() {
    if (this.ohsAssets) {
      return this.ohsAssets.filter((ohsAsset) => {
        const ohsDescription: string = ohsAsset?.description || '';
        return ohsDescription
          .toLowerCase()
          .includes(this.ohsSearch.toLocaleLowerCase());
      });
    }
  }

  public created() {
    // set the temp array to the values that is previously added
    this.tempDirtyOhs = this.dirtyOhs;
  }
}
