import { AxiosError } from 'axios';

export interface ApiState {
  loading: boolean;
  success: boolean;
  error: AxiosError | Error | null;
}

export const defaultApiState: ApiState = {
  loading: false,
  success: false,
  error: null
};

export interface PaginatedApiResponse<T> {
  total_count: number;
  incomplete_results: boolean;
  items: T[];
  facets?: Facet[];
}

export interface PaginationQuery {
  sort?: string;
  order?: 'asc' | 'desc';
  page?: number;
  per_page?: number;
}

export interface Facet {
  name: FacetTypes;
  keysAndCount: FacetKeyAndCount[];
}

export interface FacetKeyAndCount {
  key: string;
  doc_count: number;
}

export enum FacetTypes {
  Author = 'author',
  DocumentType = 'documentType',
  Nodes = 'nodes',
  AssignedUser = 'assignedUser'
}

// tslint:disable-next-line:ban-types
export type AnyFunction = Function;
