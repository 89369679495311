

















import SuggestedDocumentCard from '@/components/editor/SectionEditor/SuggestedDocumentCard.vue';
import EsAlignerForm from '@/components/form/EsAlignerForm.vue';
import { Component, PropSync, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { PermissionMixin } from '@/utils/permission.mixin';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { RootState } from '@/store/store';
import { filter, take } from 'rxjs/operators';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';

@Component({
  components: {
    SuggestedDocumentCard,
    EsAlignerForm
  }
})
export default class EsAligner extends mixins(
  PermissionMixin,
  EditorCommonMixin
) {
  @Prop()
  public dirtyAlignedEs!: DocumentIndexingPayload | null;

  public openModal() {
    const modalTitle = 'Realign Evidence Summary';
    this.$buefy.modal.open({
      parent: this,
      component: EsAlignerForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle
      },
      events: {
        select: (selected: DocumentIndexingPayload) => {
          this.$emit('update:dirtyAlignedEs', selected || null);
        }
      },
      canCancel: false
    });
  }
}
