


















































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { useDocumentsState, useApiState } from '@/utils/store.util';
import {
  GetDocumentParticipantsRequestPayload,
  DirtyTagMap,
  UpdateRecommendedPracticeRequestPayload,
  UpdateEvidenceSummaryRequestPayload
} from '@/store/modules/documents/types/documents.types';
import { Action } from 'vuex-class';
import InvitationInputFields from '@/components/form/InvitationInputFields.vue';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { orderBy, isEqual, cloneDeep } from 'lodash';
import { PermissionMixin } from '@/utils/permission.mixin';
import { TagEntityTypeEnum } from '@/jbi-shared/types/document.types';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { cloneDeep as _cloneDeep } from 'lodash';
import EsAligner from '@/components/form/EsAligner.vue';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import {
  UpdateSRRequestPayload,
  UpdateBPISRequestPayload
} from '@/store/modules/documents/types/documents.types';
import dayjs from 'dayjs';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {
    InvitationInputFields,
    TagsEditor,
    EsAligner
  }
})
export default class EditDocumentForm extends mixins(
  PermissionMixin,
  EditorCommonMixin
) {
  public search = '';
  public containerMinHeight = 250;
  // FIXME:
  // @ts-ignore
  public dirtyTagMaps: DirtyTagMap[] = [];
  public originalDirtyTags: DirtyTagMap[] = [];
  public volume: string | undefined = undefined;
  public issueNumber: string | undefined = undefined;
  public page: string | undefined = undefined;
  public author: string | undefined = undefined;
  public date: string | undefined = undefined;
  public dirtyAlignedEs: DocumentIndexingPayload | null = null;

  @Action('documents/getDocumentParticipants')
  public getDocumentParticipants!: (
    payload: GetDocumentParticipantsRequestPayload
  ) => void;

  @Action('documents/updateDocument')
  public updateDocument!: (
    payload:
      | UpdateRecommendedPracticeRequestPayload
      | UpdateEvidenceSummaryRequestPayload
  ) => void;

  @Action('documents/updatePDFDocument')
  public updatePDFDocument!: (
    payload: UpdateSRRequestPayload | UpdateBPISRequestPayload
  ) => void;

  get documentParticipants() {
    const documentParticipants =
      useDocumentsState.call(this).documentParticipants || [];
    return orderBy(documentParticipants, ['role', 'email']);
  }

  get hasPublishedRevisions(): boolean {
    if (useDocumentsState.call(this).documentDetail) {
      return Boolean(
        useDocumentsState.call(this).documentDetail?.document
          ?.publishedRevisions?.length || 0 > 0
      );
    }
    return false;
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get inviteApiState() {
    return useApiState.call(this, 'documents/inviteDocumentUserAsParticipant')!;
  }

  get updateUserApiState() {
    return useApiState.call(this, 'documents/updateDocumentParticipant')!;
  }

  get deleteUserApiState() {
    return useApiState.call(this, 'documents/deleteDocumentParticipant')!;
  }
  get TagEntityTypeEnum() {
    return TagEntityTypeEnum;
  }

  get tagsHasChanged() {
    return !isEqual(this.originalDirtyTags, this.dirtyTagMaps);
  }

  get alignedEsHasChanged() {
    return (
      this.isRp &&
      this.dirtyAlignedEs &&
      this.alignedEs &&
      this.dirtyAlignedEs.documentId !== this.alignedEs.documentId
    );
  }

  @isDifferent
  @isTruthy
  @Watch('inviteApiState.success')
  public onInviteSuccess() {
    this.getDocumentParticipants({ documentId: this.documentId });
  }

  @Watch('deleteUserApiState.success')
  public onDeleteUserSuccess() {
    this.getDocumentParticipants({ documentId: this.documentId });
  }

  @Watch('updateUserApiState.success')
  @isDifferent
  @isTruthy
  public onInviteUpdateSuccess() {
    this.getDocumentParticipants({ documentId: this.documentId });
  }

  @isDifferent
  @isTruthy
  @Watch('documentDetail', { immediate: true })
  public onDocLoaded() {
    this.dirtyTagMaps = this.documentDetail!.revision.tags;
    this.originalDirtyTags = _cloneDeep(this.documentDetail!.revision.tags);
    if (this.isBpis || this.isSr || this.isSrp) {
      this.volume = this.documentDetail!.revision.resource.volume;
      this.issueNumber = this.documentDetail!.revision.resource.issueNumber;
      this.page = this.documentDetail!.revision.resource.page;
    }
    if (this.isSr || this.isSrp) {
      this.author = this.documentDetail!.revision.resource.author;
      this.date = this.documentDetail!.revision.resource.date;
    }
  }

  public created() {
    this.getDocumentParticipants({ documentId: this.documentId });
  }

  public handleSave() {
    const payload = cloneDeep(this.documentDetail!);
    if (this.isBpis || this.isSr || this.isSrp) {
      payload.revision.resource = {
        storageUri: this.documentDetail?.revision?.resource?.storageUri || '',
        volume: this.volume as string,
        issueNumber: this.issueNumber as string,
        page: this.page as string,
        date: this.date as string,
        author: this.author as string
      };
      payload.revision.tags = this
        .dirtyTagMaps as FullDocumentRevisionObject['revision']['tags'];
      this.updatePDFDocument(payload);
    } else {
      if (this.tagsHasChanged || this.alignedEsHasChanged) {
        payload.revision.tags = this
          .dirtyTagMaps as FullDocumentRevisionObject['revision']['tags'];

        if (this.alignedEsHasChanged) {
          payload.project!.id = this.dirtyAlignedEs!.projectId;
        }

        this.updateDocument(payload);
      }
    }

    this.$emit('close');
  }

  public dateToDisplayedDate(d: Date) {
    return dayjs(d).format('D MMMM YYYY');
  }

  public dateToString(d: Date) {
    return dayjs(d).format().split('T').shift();
  }
}
