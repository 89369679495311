























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { State, Action } from 'vuex-class';
import { RootState } from '@/store/store';
import CitationForm from '@/views/DocumentEditor/components/tab/citations/CitationForm.vue';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { ValidationObserver } from 'vee-validate';
import draggable from 'vuedraggable';
import CitationUploader from '@/views/DocumentEditor/components/tab/citations/CitationUploader.vue';
import { ApiState } from '@/store/types/general.types';
import { get as _get } from 'lodash';
import CitationCard from '@/views/DocumentEditor/components/tab/EditorCitationsManagementTab_CitationCard.vue';
import { useGetter } from '@/utils/store.util';
import { cloneDeep as _cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { EditorComputedValuesMixin } from '../../mixins/editor-computed-values.mixin';
import { getCitationReferenceNumber } from '@/utils/citation-reference.util';

@Component({
  // @ts-ignore
  components: {
    BaseModal,
    CitationForm,
    ValidationObserver,
    CitationUploader,
    CitationCard
  }
})
export default class EditorCitationsManagementTab extends mixins(
  EditorComputedValuesMixin
) {
  @Prop(String) public citationSearchValue!: string;

  @State((state: RootState) => state.projects.apiState.createCitation.loading)
  public createCitationLoading!: boolean;

  @State((state: RootState) => state.projects.apiState.editCitation.loading)
  public editCitationLoading!: boolean;

  @State((state: RootState) => state.projects.apiState.uploadCitation)
  public uploadCitationApiState!: ApiState;

  get referencedCitationIds() {
    return this.uniqUsedCitationIds;
  }

  get citations(): Citation[] {
    const citations: Citation[] = this.citationsOfProject?.citations || [];
    return citations.map((citation) => {
      const transformedCitation = _cloneDeep(citation);
      transformedCitation.content.author = this.transformAuthorArrayToString(
        transformedCitation.content.author
      );
      return transformedCitation;
    });
  }

  get revisionCitations(): Citation[] {
    const citations: Citation[] = this.citationsOfRevision;
    return citations.map((citation) => {
      const transformedCitation = _cloneDeep(citation);
      transformedCitation.content.author = this.transformAuthorArrayToString(
        transformedCitation.content.author
      );
      return transformedCitation;
    });
  }
  get filteredCitations(): Citation[] {
    return (
      this.revisionCitations
        // filter based on search
        .filter((citation) => {
          const author: string = _get(citation, 'content.author', '');
          const title: string = _get(citation, 'content.title', '');
          const content: string = _get(citation, 'content.plainText', ''); // Plain Citation content
          return (
            (citation.isDeleted !== true &&
              (author
                .toLowerCase()
                .includes(this.citationSearchValue.toLowerCase()) ||
                title
                  .toLowerCase()
                  .includes(this.citationSearchValue.toLowerCase()))) ||
            content
              .toLowerCase()
              .includes(this.citationSearchValue.toLowerCase())
          );
        })
        // format date
        .map((c) => ({
          ...c,
          issueDate: this.getYearFromDate(c.content.issueDate)
        }))
    );
  }

  get uploadCitationCompleted() {
    return useGetter.call(this, 'projects/uploadCitationCompleted');
  }

  public citationReferenceNumber(citation: Citation): string | undefined {
    const citationReferenceNumber = getCitationReferenceNumber(
      citation,
      this.uniqUsedCitationIds || []
    );
    if (!citationReferenceNumber) {
      return;
    }
    return citationReferenceNumber + '.';
  }

  public transformAuthorArrayToString(authors: any[]) {
    let authorString: string = '';
    if (Array.isArray(authors)) {
      for (const author of authors) {
        const authorValue =
          (author.given ? author.given + ' ' : '') +
          (author.family ? author.family : '');
        authorString =
          authorString !== ''
            ? authorString + ', ' + authorValue
            : authorString + authorValue;
      }
    }
    return authorString;
  }

  public getYearFromDate(date: string | number): string {
    if (String(date).length === 4) {
      return String(date);
    }
    return dayjs(date).format('YYYY');
  }
}
