






import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class RecentDocumentsItemContainer extends Vue {}
