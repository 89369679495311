




























































































































import Component, { mixins } from 'vue-class-component';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import CitationForm from '@/views/DocumentEditor/components/tab/citations/CitationForm.vue';
import { ValidationObserver } from 'vee-validate';
import { Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { CitationTypes } from '@/jbi-shared/types/citation.fields';
import { StagingEditorComputedValuesMixin } from '../../../StagingArea/mixins/staging-editor-computed-values.mixin';
import { StagingEditorStateMixin } from '../../../StagingArea/mixins/staging-editor-state.mixin';
import ArchiveCitationValidation from '../../../StagingArea/components/tab/ArchiveCitationValidation.vue';
import { PendingCitationData } from '../../../../jbi-shared/types/document.types';
import StagingCitationForm from './citations/StagingCitationForm.vue';

@Component({
  components: {
    BaseModal,
    CitationForm,
    StagingCitationForm,
    ValidationObserver,
    ArchiveCitationValidation
  }
})
export default class CitationCard extends mixins(
  StagingEditorComputedValuesMixin,
  StagingEditorStateMixin
) {
  public draggable = true;

  @Prop(Object) public citation!: Citation;
  @Prop(String) public citationReferenceNumber?: string;
  @Prop(Boolean) public activeCitation!: boolean;
  @Prop({ type: Boolean, default: false }) public showArchiveButton!: boolean;
  @Prop() public referencedCitationIds!: number[];
  @Prop({ default: false }) public pendingEditor!: boolean;
  public isVisible: boolean = false;

  @Prop({
    type: Boolean,
    default: () => false
  })
  public isStaging!: boolean;

  @State((state: RootState) => state.projects.apiState.createCitation.loading)
  public createCitationLoading!: boolean;

  @State((state: RootState) => state.projects.apiState.editCitation.loading)
  public editCitationLoading!: boolean;

  get citationLoading() {
    return !this.referencedCitationIds.includes(this.pendingDocumentRevisionId)
      ? this.createCitationLoading
      : this.editCitationLoading;
  }

  get CitationTypes() {
    return CitationTypes;
  }

  get getAuthor(): string {
    return this.citation.content.author
      ? this.citation.content.author.split(' ').shift()
      : '';
  }

  get citationHasAuthor(): boolean {
    return !!this.citation.content.author;
  }

  get citationHasIssueDate(): boolean {
    return !!this.citation.content.issueDate;
  }

  get citationHasTitle(): boolean {
    return !!this.citation.content.title;
  }

  public toggleCitationStatus() {
    if (this.activeCitation) {
      const citationObject:
        | PendingCitationData
        | undefined = this.activeCitations.find(
        (data) => data.id.toString() === this.citation.id.toString()
      );
      if (
        citationObject &&
        !this.usedCitationIds.includes(citationObject.id.toString())
      ) {
        this.activeCitations = this.activeCitations.filter((el) => {
          return el.id !== citationObject.id;
        });
        this.archivedCitations.push(citationObject);
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: ArchiveCitationValidation,
          hasModalCard: true,
          trapFocus: true,
          events: {
            'archive-anyway': () => {
              if (citationObject) {
                this.activeCitations = this.activeCitations.filter((el) => {
                  return el.id !== citationObject.id;
                });
                this.archivedCitations.push(citationObject);
              }
            }
          }
        });
      }
    } else {
      const citationObject:
        | PendingCitationData
        | undefined = this.archivedCitations.find(
        (data) => data.id.toString() === this.citation.id.toString()
      );
      if (citationObject) {
        this.archivedCitations = this.archivedCitations.filter((el) => {
          return el.id !== citationObject.id;
        });
        this.activeCitations.push(citationObject);
      }
    }
  }
}
