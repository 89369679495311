














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UploadTimeout extends Vue {
  @Prop(String) private fileName!: string;
}
