










































import { Component, Vue, Prop } from 'vue-property-decorator';
import { useProjectsState, useDocumentsState } from '@/utils/store.util';
import {
  GetProjectResponsePayload,
  ProjectDocument
} from '@/store/modules/projects/types/project-details.types';
import {
  CplusDocumentType,
  CplusDocumentTypeDisplayName,
  CplusDocumentTypeShorterDisplayName,
  CplusDocumentTypeShortHand
} from '@/jbi-shared/types/document.types';
import { get as _get, last, orderBy } from 'lodash';
import { mixins } from 'vue-class-component';
import { PermissionMixin } from '@/utils/permission.mixin';
import {
  DocumentDetail,
  Project
} from '@/store/modules/documents/types/documents.types';
import { RootState } from '@/store/store';
import { EditorCommonMixin } from '../mixins/editor-common.mixin';
import NewSubDocForm from '@/views/DocumentEditor/components/NewSubDocForm.vue';
import ProjectEditorHeaderTabTag from '@/views/DocumentEditor/components/ProjectEditorHeaderTabTag.vue';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';

@Component({
  components: {
    ProjectEditorHeaderTabTag
  }
})
export default class DocumentNavigationTabs extends mixins(
  PermissionMixin,
  EditorCommonMixin,
  NewSubDocForm
) {
  @Prop() public documents!: ProjectDocument[];

  @Prop({ default: true }) public multiple!: boolean;

  public hoveredItemIndex = -1;

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  public isActive(docId: number) {
    return this.documentId === docId;
  }

  getDocumentStatus(doc: ProjectDocument) {
    return (
      _get(last(doc.revisions), 'revisionPublicationStatus.status') ||
      RevisionPublicationStatus.Draft
    );
  }

  public isPublishedDoc(doc: ProjectDocument) {
    return this.getDocumentStatus(doc) === RevisionPublicationStatus.Published;
  }

  public isDraftDoc(doc: ProjectDocument) {
    return this.getDocumentStatus(doc) === RevisionPublicationStatus.Draft;
  }

  public isArchivedDoc(doc: ProjectDocument) {
    return this.getDocumentStatus(doc) === RevisionPublicationStatus.Archived;
  }

  public getDocTypeDisplayNameFromDoc(
    doc: GetProjectResponsePayload['projectDocuments'][0]
  ) {
    const { documentType } = doc;
    return CplusDocumentTypeShortHand[documentType];
  }
}
