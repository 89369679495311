








































































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import {
  CriterionContent,
  CRITERION_TYPE_DISPLAY_NAME,
  CRITERION_TYPE,
  RangeCriterionContent,
  BooleanCriterionContent,
  Criterion,
  CheckboxesCriterionContent
} from '@/jbi-shared/types/criterions.types';
import { StringInputOption, InputOption } from '@/jbi-shared/types/form.types';
import { cloneDeep } from 'lodash';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { set as _set } from 'lodash';
// tslint:disable-next-line
import RangeCriterionInput from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm/RangeCriterionInput.vue';
// tslint:disable-next-line
import BooleanCriterionInput from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm/BooleanCriterionInput.vue';
// tslint:disable-next-line
import CheckboxesCriterionInput from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm/CheckboxesCriterionInput.vue';
import { uniq as _uniq, isEqual as _isEqual, pick as _pick } from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { opsToText } from '../../../../utils/quill-delta.util';
import { mixins } from 'vue-class-component';
import { generateRandomId } from '../../../../jbi-shared/util/document.utils';
import { TagEntityTypeEnum } from '../../../../jbi-shared/types/document.types';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { EditorCommonMixin } from '../../../../views/DocumentEditor/mixins/editor-common.mixin';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    RangeCriterionInput,
    BooleanCriterionInput,
    CheckboxesCriterionInput,
    BaseMultiSelect,
    TagsEditor
  }
})
export default class CriterionForm extends mixins() {
  @Prop(String) public modalTitle!: string;
  @Prop({
    default() {
      return {
        criterionSubSectionId: 0,
        content: {
          type: CRITERION_TYPE.BOOLEAN,
          title: '',
          booleanOptions: ['Yes', 'No']
        },
        tempId: generateRandomId(),
        bprs: [],
        documentSectionId: 0
      } as FullDocumentRevisionObject['revision']['sections']['criterionSection'][0];
    }
  })
  public criterion!: FullDocumentRevisionObject['revision']['sections']['criterionSection'][0];

  @Prop({ required: true })
  public subSections!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public criterionSectionId!: number;
  @Prop()
  public dirtyCriterions!: FullDocumentRevisionObject['revision']['sections']['criterionSection'];

  public dirtyCriterion = cloneDeep(this.criterion);
  public criterionFormDirtyTagMaps = cloneDeep(this.dirtyTagMaps) || [];

  get CRITERION_TYPE() {
    return CRITERION_TYPE;
  }

  get options(): StringInputOption[] {
    return Object.entries(CRITERION_TYPE_DISPLAY_NAME).map(([key, value]) => {
      return {
        id: key,
        name: value
      };
    });
  }

  get bprOptions(): StringInputOption[] {
    return this.subSections.map((ss) => ({
      id: String(ss.bprSubSectionId || ss.tempId!),
      name: opsToText(ss.content || [])
    }));
  }

  /*
   * Every criterion has a property 'bprs' that contains an array of
   * bpr id(s) that the criterion is linked to.
   *
   * In order to display the details of the linked bprs, we need to retrieve
   * the bprsOption data via find by id.
   */
  get dirtyCSubSections(): StringInputOption[] {
    if (!this.dirtyCriterion.bprs) {
      return [];
    }
    return this.dirtyCriterion.bprs
      .map(
        (bpr) =>
          this.bprOptions.find(
            ({ id }) =>
              String(bpr.bprSubSectionId) === String(id) ||
              String(bpr.tempId) === String(id)
          )!
      )
      .filter(Boolean);
  }
  /*
   * Every criterion has a property 'bprs' that contains an array of
   * bpr id(s) that the criterion is linked to.
   *
   * When setting the linked BPR of a specific criterion, we need to
   * convert the bprOption value to its respective id (bprSubSectionId or tempId)
   * in order to signify its linkage.
   */
  set dirtyCSubSections(values: StringInputOption[]) {
    this.dirtyCriterion.bprs = values
      .map(
        (o) =>
          this.subSections.find(
            ({ bprSubSectionId, tempId }) =>
              String(bprSubSectionId) === String(o.id) ||
              String(tempId) === String(o.id)
          )!
      )
      .filter(Boolean)
      .map((ss) => ({
        bprSubSectionId: ss.bprSubSectionId,
        tempId: ss.tempId
      }));
    this.dirtyCriterion = { ...this.dirtyCriterion };
  }

  public reset() {
    this.dirtyCriterion = cloneDeep(this.criterion);
  }

  public async addCriterion() {
    if (!(await this.validatePreSave())) {
      return false;
    }
    const dirtyCriterion = this.sanitizeCriterion(this.dirtyCriterion);
    const updatedCriterions = [
      ...this.dirtyCriterions,
      {
        tempId: generateRandomId(),
        ...dirtyCriterion,
        documentSectionId: this.criterionSectionId,
        criterionSubSectionId: 0
      }
    ];
    const updatedDirtyTagMaps = this.criterionFormDirtyTagMaps;
    this.$emit('update:criterions', updatedCriterions);
    this.$emit('update:dirtyTagMaps', updatedDirtyTagMaps);
    this.$emit('close');
  }
  public async editCriterion() {
    if (!(await this.validatePreSave())) {
      return false;
    }
    const dirtyCriterion = this.sanitizeCriterion(this.dirtyCriterion);

    /* Searches the dirtyCriterion array for the current criterion element
     * and replaces it with the updated data. */
    const updatedCriterions = this.dirtyCriterions.map((dc) =>
      (dc.criterionSubSectionId &&
        dc.criterionSubSectionId === dirtyCriterion.criterionSubSectionId) ||
      (dc.tempId && dc.tempId === dirtyCriterion.tempId)
        ? {
            ...dirtyCriterion
          }
        : dc
    );
    const updatedDirtyTagMaps = this.criterionFormDirtyTagMaps;
    this.$emit('update:criterions', updatedCriterions);
    this.$emit('update:dirtyTagMaps', updatedDirtyTagMaps);
    this.$emit('close');
  }

  /*
   * Validates the contents of the criterion to ensure that it follows
   * the required format/rules.
   * This function should be run before attempting to save updates (add/modify)
   * to ensure that the criterion data is not invalid or corrupted.
   */
  public async validatePreSave() {
    // check duplicated checkboxed options
    if (this.dirtyCriterion.content instanceof CheckboxesCriterionContent) {
      const { checkboxesOptions } = this.dirtyCriterion.content;
      const sanitized = this.sanitizeCriterionCheckboxesOptions(
        checkboxesOptions
      );
      if (!_isEqual(checkboxesOptions, sanitized)) {
        const confirm = () =>
          new Promise((resolve) => {
            return Dialog.confirm({
              message: `Empty values and duplicated values will be removed.`,
              confirmText: 'Confirm',
              cancelText: 'Cancel',
              onConfirm() {
                resolve(true);
              },
              onCancel() {
                resolve(false);
              }
            });
          });
        if (!(await confirm())) {
          return false;
        }
      }
    }
    return true;
  }

  public sanitizeCriterion(
    criterion: FullDocumentRevisionObject['revision']['sections']['criterionSection'][0]
  ): FullDocumentRevisionObject['revision']['sections']['criterionSection'][0] {
    criterion = cloneDeep(criterion);
    if (criterion.content.type === CRITERION_TYPE.RANGE) {
      const content = criterion.content as RangeCriterionContent;
      content.range = [Number(content.range[0]), Number(content.range[1])];
    } else if (criterion.content.type === CRITERION_TYPE.CHECKBOXES) {
      const content = criterion.content as CheckboxesCriterionContent;
      content.checkboxesOptions = this.sanitizeCriterionCheckboxesOptions(
        content.checkboxesOptions
      );
    }
    return criterion;
  }

  public sanitizeCriterionCheckboxesOptions(arr: string[]) {
    arr = arr.map((s) => String(s).trim()).filter(Boolean);
    arr = _uniq(arr);
    return arr;
  }

  /*
   * The content of the criterion is strongly dependent on the type
   * of the criterion.
   *
   * Therefore, if the criterion type changes, we have to update the
   * criterion's content accordingly.
   */
  @isDifferent
  @isTruthy
  @Watch('dirtyCriterion.content.type')
  public onTypeChange(type: CRITERION_TYPE) {
    switch (type) {
      case CRITERION_TYPE.BOOLEAN:
        this.dirtyCriterion.content = new BooleanCriterionContent(
          this.dirtyCriterion.content
        );
        break;
      case CRITERION_TYPE.RANGE:
        this.dirtyCriterion.content = new RangeCriterionContent({
          ...this.dirtyCriterion.content
        });
        break;
      case CRITERION_TYPE.CHECKBOXES:
        this.dirtyCriterion.content = new CheckboxesCriterionContent({
          ...this.dirtyCriterion.content
        });
        break;
    }
  }

  @Watch('dirtyTagMaps', { immediate: true })
  public onDirtyTagMaps() {
    this.criterionFormDirtyTagMaps = cloneDeep(this.dirtyTagMaps);
  }

  get isNew() {
    return this.dirtyCriterions.every((dc) => !_isEqual(dc, this.criterion));
  }

  get TagEntityTypeEnum() {
    return TagEntityTypeEnum;
  }

  get criterionId(): number | string {
    return this.criterion?.criterionSubSectionId! || this.criterion?.tempId!;
  }
}
