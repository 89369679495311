var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.mainWrapper,attrs:{"data-test":"citation-management-container"}},[_c('h1',{class:_vm.$style.citationTabTitle},[_vm._v("Citation Management")]),_c('div',{class:_vm.$style.citationCount},[_vm._v(" Citation(s) Imported: "+_vm._s(_vm.filteredCitations.length)+" ")]),_c('div',{class:[_vm.$style.citationCount, _vm.$style.citationReferenced]},[_vm._v(" Citation(s) Referenced: "+_vm._s(_vm.uniqUsedCitationIds.length)+" ")]),_c('b-input',{class:['searchInput', _vm.$style.citationSearchInput],attrs:{"placeholder":"","type":"search","icon":"magnify","value":_vm.citationSearchValue},on:{"input":function($event){return _vm.$emit('update:citationSearchValue', $event)}}}),_c('div',{class:_vm.$style.citationButtonContainer},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
            validator.validate().then(function (validated) {
              if (validated === false) { return; }
              _vm.$refs.CitationFormForCreate.handleCitationFormSubmit();
            });
          })($event)}}},[_c('div',{class:_vm.$style.newCitationContainer},[_c('BaseModal',{attrs:{"title":"New Citation"},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
          var openModal = ref.openModal;
return [_c('b-button',{on:{"click":openModal}},[_vm._v(" Add Citation ")])]}},{key:"modalBody",fn:function(ref){
          var closeModal = ref.closeModal;
return [_c('CitationForm',{ref:"CitationFormForCreate",attrs:{"referencedCitationIds":_vm.referencedCitationIds},on:{"closeModal":closeModal}})]}},{key:"modalFoot",fn:function(){return [_c('button',{staticClass:"button is-primary",class:{
                  'is-loading': _vm.createCitationLoading
                },attrs:{"type":"submit","disabled":validator.invalid}},[_vm._v(" Add Citation ")])]},proxy:true}],null,true)})],1)])]}}])}),_c('BaseModal',{attrs:{"title":"Import Citations","noFooter":!_vm.uploadCitationCompleted},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
                var openModal = ref.openModal;
return [_c('b-button',{attrs:{"data-test":"import-citation-btn"},on:{"click":openModal}},[_vm._v(" Import Citation ")])]}},{key:"modalBody",fn:function(ref){
                var isOpen = ref.isOpen;
return [_c('CitationUploader',{key:isOpen,attrs:{"revisionId":_vm.revisionId}})]}},{key:"modalFoot",fn:function(ref){
                var closeModal = ref.closeModal;
return [(_vm.uploadCitationCompleted)?_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.createCitationLoading },attrs:{"data-test":"import-citation-done-btn"},on:{"click":closeModal}},[_vm._v(" Done ")]):_vm._e()]}}])})],1),_c('div',{class:("is-divider m-t-0 " + (_vm.$style.divider))}),(!_vm.citations || !_vm.citations.length)?_c('div',{class:_vm.$style.noCitation},[_c('img',{class:_vm.$style.noCitationIcon,attrs:{"src":require("@/assets/images/editor/placeholder-citation@2x.png")}}),_c('div',{class:_vm.$style.noCitationTitle},[_vm._v(" You have not added any citations to this document. ")]),_c('div',{class:_vm.$style.noCitationDesc},[_vm._v(" Start by adding citations manually or importing citation files (.cff, .xml, .ris, .csl). ")])]):_c('div',{class:_vm.$style.citationListContainer},[(!_vm.filteredCitations || !_vm.filteredCitations.length)?_c('div',[_c('div',{class:_vm.$style.noCitationDesc},[_vm._v("No result found")])]):_vm._l((_vm.filteredCitations),function(citation){return _c('CitationCard',{key:citation.id || citation.tempId,attrs:{"citation":citation,"citationReferenceNumber":_vm.citationReferenceNumber(citation),"referencedCitationIds":_vm.uniqUsedCitationIds},on:{"dragstart":function($event){return _vm.$emit('dragstart', $event)},"dragend":function($event){return _vm.$emit('dragend', $event)},"deleteCitation":function($event){return _vm.$emit('deleteCitation', $event)}}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }