import { WebSocketState } from './types/websocket.types';
import { Job } from 'bull';
import { uniqBy } from 'lodash';

export const websocketMutations = {
  SOCKET_CONNECT(state: WebSocketState) {
    state.isConnected = true;
  },
  SOCKET_DISCONNECT(state: WebSocketState) {
    state.isConnected = false;
  },
  SOCKET_EXCEPTION(state: WebSocketState, payload: Error) {
    state.error = payload;
  },
  ADD_WORKER_JOB(state: WebSocketState, payload: Job) {
    state.workerJobs = [...state.workerJobs, payload];
  },
  REMOVE_WORKER_JOB(state: WebSocketState, jobId?: number | null) {
    state.workerJobs = state.workerJobs.filter((job) => job.id === jobId);
  },
  // TODO: merge MARK_WORKER_JOB_AS_COMPLETED MARK_WORKER_JOB_AS_ERRORED
  MARK_WORKER_JOB_AS_COMPLETED(state: WebSocketState, job: Job) {
    state.workerJobs = [job, ...state.workerJobs];
    state.workerJobs = uniqBy(state.workerJobs, 'id');
  },
  MARK_WORKER_JOB_AS_CLOSED(state: WebSocketState, job: Job) {
    state.workerJobs = state.workerJobs.filter(({ id }) => id !== job.id);
  },
  MARK_WORKER_JOB_AS_ERRORED(state: WebSocketState, job: Job) {
    state.workerJobs = [job, ...state.workerJobs];
    state.workerJobs = uniqBy(state.workerJobs, 'id');
  },
  ADD_1_USAGE_COUNT(state: WebSocketState) {
    state.usageCount++;
  },
  MINUS_1_USAGE_COUNT(state: WebSocketState) {
    state.usageCount--;
  }
};
