import { AdminState } from './types/admin.types';
import { defaultApiState } from '@/store/types/general.types';

export const adminState: AdminState = {
  apiState: {
    assignProjectEditor: defaultApiState,
    unassignProjectEditor: defaultApiState,
    exportProjectList: defaultApiState,
    getAssignmentZipUrl: defaultApiState,
    changeDueDate: defaultApiState,
    getUploadedDocumentDetails: defaultApiState,
    createPendingDocuments: defaultApiState,
    resendDownloadLink: defaultApiState,
    updatePendingDocument: defaultApiState,
    publishPendingDocument: defaultApiState,
    fetchPendingProjectDetails: defaultApiState,
    fetchPendingDocumentCreationStatus: defaultApiState,
    fetchPendingDocumentUploadHistory: defaultApiState,
    fetchPendingDocument: defaultApiState,
    fetchExistingDocumentDetail: defaultApiState,
    downloadDocument: defaultApiState
  },
  assignmentZipUrl: undefined,
  uploadedDocumentDetails: undefined,
  pendingDocumentsCreated: undefined,
  pendingProjectDetails: undefined,
  pendingDocument: undefined,
  existingDocumentDetail: undefined,
  pendingDocumentUploadHistory: undefined
};
