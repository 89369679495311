import {
  PaginatedApiResponse,
  defaultApiState
} from '@/store/types/general.types';
import {
  ProjectsState,
  CreateSubDocumentResponsePayload,
  UpdateProjectRequestPayload,
  CitationsOfProject,
  CreateProjectResponsePayload,
  InviteParticipantsRequestPayload,
  InviteParticipantsResponsePayload,
  RemoveUserFromProjectPayload,
  AcceptInvitationResponsePayload,
  CreateProjectFromLegacyDocumentResponsePayload,
  GetPreMigrationStatusResponsePayload,
  GetProjectNoteHistoryResponsePayload,
  GetAllProjectResponsePayload,
  GetProjectCountByProjectStatusResponsePayload
} from './types/projects.types';
import { get as _get, uniqBy } from 'lodash';
import { AxiosError } from 'axios';
import {
  GetProjectResponsePayload,
  PatchProjectDetailDocumentTitlePayload
} from './types/project-details.types';
import {
  ResearchNode,
  ResearchNodeEntity
} from '../documents/types/documents.types';
import { Pagination } from './types/projects.types';
import { Citation } from '../documents/types/citations.types';
import { ProjectIndexingPayload } from '@/jbi-shared/types/search.types';

export const projectsMutations = {
  /*
   ** get user projects
   */
  GET_MY_PROJECTS_LOADING(state: ProjectsState) {
    state.apiState.getMyProjects = {
      ...defaultApiState,
      loading: true
    };
    state.projects = undefined;
  },
  GET_MY_PROJECTS_SUCCESS(
    state: ProjectsState,
    payload: PaginatedApiResponse<ProjectIndexingPayload>
  ) {
    state.apiState.getMyProjects = {
      ...defaultApiState,
      success: true
    };
    state.projects = payload;
  },
  GET_MY_PROJECTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getMyProjects = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** get all projects
   */
  GET_ALL_PROJECTS_LOADING(state: ProjectsState) {
    state.apiState.getAllProjects = {
      ...defaultApiState,
      loading: true
    };
    state.allProjects = undefined;
  },
  GET_ALL_PROJECTS_SUCCESS(
    state: ProjectsState,
    payload: PaginatedApiResponse<GetAllProjectResponsePayload>
  ) {
    state.apiState.getAllProjects = {
      ...defaultApiState,
      success: true
    };
    state.allProjects = payload;
  },
  GET_ALL_PROJECTS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getAllProjects = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** get project count by project status
   */
  GET_PROJECT_COUNT_BY_PROJECT_STATUS_LOADING(state: ProjectsState) {
    state.apiState.getProjectCountByProjectStatus = {
      ...defaultApiState,
      loading: true
    };
    state.projectCountByProjectStatus = undefined;
  },
  GET_PROJECT_COUNT_BY_PROJECT_STATUS_SUCCESS(
    state: ProjectsState,
    payload: GetProjectCountByProjectStatusResponsePayload
  ) {
    state.apiState.getProjectCountByProjectStatus = {
      ...defaultApiState,
      success: true
    };
    state.projectCountByProjectStatus = payload;
  },
  GET_PROJECT_COUNT_BY_PROJECT_STATUS_ERROR(
    state: ProjectsState,
    error: AxiosError
  ) {
    state.apiState.getProjectCountByProjectStatus = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** create project
   */
  CREATE_PROJECT_LOADING(state: ProjectsState) {
    state.apiState.createProject = {
      ...defaultApiState,
      loading: true
    };
    state.createdProject = undefined;
  },
  CREATE_PROJECT_SUCCESS(
    state: ProjectsState,
    payload: CreateProjectResponsePayload
  ) {
    state.apiState.createProject = {
      ...defaultApiState,
      success: true
    };
    state.createdProject = payload;
  },
  CREATE_PROJECT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createProject = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** add note to project
   */
  ADD_NOTE_TO_PROJECT_LOADING(state: ProjectsState) {
    state.apiState.addNoteToProject = {
      ...defaultApiState,
      loading: true
    };
  },
  ADD_NOTE_TO_PROJECT_SUCCESS(state: ProjectsState) {
    state.apiState.addNoteToProject = {
      ...defaultApiState,
      success: true
    };
  },
  ADD_NOTE_TO_PROJECT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.addNoteToProject = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** update project note
   */
  UPDATE_PROJECT_NOTE_LOADING(state: ProjectsState) {
    state.apiState.updateProjectNote = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PROJECT_NOTE_SUCCESS(state: ProjectsState) {
    state.apiState.updateProjectNote = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_PROJECT_NOTE_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProjectNote = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** get project note history
   */
  GET_PROJECT_NOTE_HISTORY_LOADING(state: ProjectsState) {
    state.apiState.getNoteHistory = {
      ...defaultApiState,
      loading: true
    };
    state.projectNoteHistory = undefined;
  },
  GET_PROJECT_NOTE_HISTORY_SUCCESS(
    state: ProjectsState,
    payload: GetProjectNoteHistoryResponsePayload
  ) {
    state.apiState.getNoteHistory = {
      ...defaultApiState,
      success: true
    };
    state.projectNoteHistory = payload;
  },
  GET_PROJECT_NOTE_HISTORY_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getNoteHistory = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** get user project
   */
  GET_PROJECT_LOADING(state: ProjectsState) {
    state.apiState.getProjectDetail = {
      ...defaultApiState,
      loading: true
    };
    state.projectDetail = undefined;
  },
  GET_PROJECT_SUCCESS(
    state: ProjectsState,
    payload: GetProjectResponsePayload
  ) {
    state.apiState.getProjectDetail = {
      ...defaultApiState,
      success: true
    };
    state.projectDetail = payload;
  },
  GET_PROJECT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getProjectDetail = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** updateProject
   */
  UPDATE_PROJECT_LOADING(state: ProjectsState) {
    state.apiState.updateProject = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PROJECT_SUCCESS(
    state: ProjectsState,
    requestPayload: UpdateProjectRequestPayload
  ) {
    state.apiState.updateProject = {
      ...defaultApiState,
      success: true
    };
    const {
      projectTitle,
      projectId,
      primaryResearchNodeId,
      researchNodeIds
    } = requestPayload;
    const primaryResearchNode = state.allResearchNodes!.find(
      ({ id }) => id === primaryResearchNodeId
    )!;
    const researchNodes = state.allResearchNodes!.filter(({ id }) =>
      researchNodeIds.includes(id)
    );
    if (_get(state.projectDetail, 'id') === projectId) {
      state.projectDetail = {
        ...state.projectDetail!,
        projectTitle,
        primaryResearchNode,
        researchNodes
      };
    }
  },
  UPDATE_PROJECT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.updateProject = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** createSubDocument
   */
  CREATE_SUB_DOCUMENT_LOADING(state: ProjectsState) {
    state.apiState.createSubDocument = {
      ...defaultApiState,
      loading: true
    };
    state.createdSubDocument = undefined;
  },
  CREATE_SUB_DOCUMENT_SUCCESS(
    state: ProjectsState,
    payload: CreateSubDocumentResponsePayload
  ) {
    state.apiState.createSubDocument = {
      ...defaultApiState,
      success: true
    };
    state.createdSubDocument = payload;
  },
  CREATE_SUB_DOCUMENT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createSubDocument = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getAllResearchNodes
   */
  GET_ALL_RESEARCH_NODES_LOADING(state: ProjectsState) {
    state.allResearchNodes = undefined;
    state.apiState.getAllResearchNodes = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ALL_RESEARCH_NODES_SUCCESS(
    state: ProjectsState,
    payload: ResearchNode[]
  ) {
    state.allResearchNodes = payload;
    state.apiState.getAllResearchNodes = {
      ...defaultApiState,
      success: true
    };
  },
  GET_ALL_RESEARCH_NODES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getAllResearchNodes = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getActiveResearchNodes
   */
  GET_ACTIVE_RESEARCH_NODES_LOADING(state: ProjectsState) {
    state.activeResearchNodes = undefined;
    state.apiState.getActiveResearchNodes = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ACTIVE_RESEARCH_NODES_SUCCESS(
    state: ProjectsState,
    payload: ResearchNode[]
  ) {
    state.activeResearchNodes = payload;
    state.apiState.getActiveResearchNodes = {
      ...defaultApiState,
      success: true
    };
  },
  GET_ACTIVE_RESEARCH_NODES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getActiveResearchNodes = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getResearchNodes
   */
  GET_RESEARCH_NODES_LOADING(state: ProjectsState) {
    state.paginatedResearchNode = undefined;
    state.apiState.getResearchNodes = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_RESEARCH_NODES_SUCCESS(
    state: ProjectsState,
    payload: Pagination<ResearchNodeEntity>
  ) {
    state.paginatedResearchNode = payload;
    state.apiState.getResearchNodes = {
      ...defaultApiState,
      success: true
    };
  },
  GET_RESEARCH_NODES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getResearchNodes = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getCitations
   */
  GET_CITATIONS_LOADING(state: ProjectsState, projectId: number) {
    state.apiState.getCitations = {
      ...defaultApiState,
      loading: true
    };
    if (_get(state, 'citationsOfProject.projectId') !== projectId) {
      state.citationsOfProject = undefined;
    }
  },
  GET_CITATIONS_SUCCESS(state: ProjectsState, payload: CitationsOfProject) {
    state.apiState.getCitations = {
      ...defaultApiState,
      success: true
    };
    state.citationsOfProject = payload;
  },
  GET_CITATIONS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getCitations = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** deleteCitation
   */
  DELETE_CITATION_LOADING(state: ProjectsState) {
    state.apiState.deleteCitation = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_CITATION_SUCCESS(
    state: ProjectsState,
    { documentId, citationId }: { documentId: number; citationId: number }
  ) {
    state.apiState.deleteCitation = {
      ...defaultApiState,
      success: true
    };
    // update existing citations
    if (state.citationsOfProject) {
      state.citationsOfProject!.citations = state.citationsOfProject!.citations!.filter(
        (citation) => citation.id !== citationId
      );
      state.citationsOfProject = { ...state.citationsOfProject };
    }
  },
  DELETE_CITATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.deleteCitation = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** createCitation
   */
  CREATE_CITATION_LOADING(state: ProjectsState) {
    state.apiState.createCitation = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_CITATION_SUCCESS(state: ProjectsState, payload: Citation) {
    state.apiState.createCitation = {
      ...defaultApiState,
      success: true
    };
    // update existing citations
    if (state.citationsOfProject) {
      const currentCitations = _get(state, 'citationsOfProject.citations', []);
      const newCitations = [...currentCitations, payload];
      state.citationsOfProject.citations = newCitations;
      state.citationsOfProject = { ...state.citationsOfProject };
    }
    state.createdCitation = payload;
  },
  CREATE_CITATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.createCitation = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** editCitation
   */
  EDIT_CITATION_LOADING(state: ProjectsState) {
    state.apiState.editCitation = {
      ...defaultApiState,
      loading: true
    };
  },
  EDIT_CITATION_SUCCESS(state: ProjectsState, payload: Citation) {
    state.apiState.editCitation = {
      ...defaultApiState,
      success: true
    };
    // update existing citations
    if (state.citationsOfProject) {
      let { citations } = state.citationsOfProject;
      citations = [payload, ...citations];
      citations = uniqBy(citations, (c) => c.id);
      state.citationsOfProject.citations = citations;
    }
  },
  EDIT_CITATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.editCitation = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** uploadCitation
   */
  UPLOAD_CITATION_LOADING(state: ProjectsState) {
    state.apiState.uploadCitation = {
      ...defaultApiState,
      loading: true
    };
  },
  UPLOAD_CITATION_SUCCESS(state: ProjectsState) {
    state.apiState.uploadCitation = {
      ...defaultApiState,
      success: true
    };
  },
  UPLOAD_CITATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.uploadCitation = {
      ...defaultApiState,
      error
    };
  },
  UPLOAD_CITATION_RESET(state: ProjectsState) {
    state.apiState.uploadCitation = {
      ...defaultApiState
    };
  },
  IMPORT_RESEARCH_NODES_LOADING(state: ProjectsState) {
    state.apiState.importResearchNodes = {
      ...defaultApiState,
      loading: true
    };
  },
  IMPORT_RESEARCH_NODES_SUCCESS(state: ProjectsState) {
    state.apiState.importResearchNodes = {
      ...defaultApiState,
      success: true
    };
  },
  IMPORT_RESEARCH_NODES_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.importResearchNodes = {
      ...defaultApiState,
      error
    };
  },
  IMPORT_RESEARCH_NODES_RESET(state: ProjectsState) {
    state.apiState.importResearchNodes = {
      ...defaultApiState
    };
  },
  PATCH_PROJECT_DETAIL_DOCUMENT_TITLE(
    state: ProjectsState,
    payload: PatchProjectDetailDocumentTitlePayload
  ) {
    if (!state.projectDetail) {
      return;
    }
    state.projectDetail!.projectDocuments = state.projectDetail.projectDocuments.map(
      (projDoc) => {
        if (payload.documentId === projDoc.id) {
          projDoc.title = payload.documentTitle;
        }
        return projDoc;
      }
    );
    state.projectDetail = { ...state.projectDetail };
  },
  /*
   ** acceptInvitation
   */
  ACCEPT_INVITATION_LOADING(state: ProjectsState) {
    state.apiState.acceptInvitation = {
      ...defaultApiState,
      loading: true
    };
  },
  ACCEPT_INVITATION_SUCCESS(
    state: ProjectsState,
    payload: AcceptInvitationResponsePayload
  ) {
    state.apiState.acceptInvitation = {
      ...defaultApiState,
      success: true
    };
    state.acceptedInvitedProject = payload;
  },
  ACCEPT_INVITATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.acceptInvitation = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** inviteUser
   */
  INVITE_USER_LOADING(state: ProjectsState) {
    state.apiState.inviteUser = {
      ...defaultApiState,
      loading: true
    };
    state.invitedUser = undefined;
  },
  INVITE_USER_SUCCESS(
    state: ProjectsState,
    payload: InviteParticipantsResponsePayload
  ) {
    state.apiState.inviteUser = {
      ...defaultApiState,
      success: true
    };
    state.invitedUser = payload.user;
    // state.projectDetail!.collaborators = [
    //   ...state.projectDetail!.collaborators,
    //   {
    //     collaborator: payload.user,
    //     inviteStatus: InviteStatus.Pending,
    //     token: payload.token,
    //   },
    // ];
  },
  INVITE_USER_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.inviteUser = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** resendUserInvitation
   */
  RESEND_USER_INVITATION_LOADING(state: ProjectsState) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      loading: true
    };
  },
  RESEND_USER_INVITATION_SUCCESS(state: ProjectsState) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      success: true
    };
  },
  RESEND_USER_INVITATION_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      error
    };
  },
  /**
   * Delete user invite from the project
   */
  REMOVE_USER_FROM_PROJECT_LOADING(state: ProjectsState) {
    state.apiState.removeUserFromProject = {
      ...defaultApiState,
      loading: true
    };
  },
  REMOVE_USER_FROM_PROJECT_SUCCESS(
    state: ProjectsState,
    payload: RemoveUserFromProjectPayload
  ) {
    state.apiState.removeUserFromProject = {
      ...defaultApiState,
      success: true
    };
    const { userId } = payload;
    // state.projectDetail!.collaborators = (
    //   state.projectDetail!.collaborators || []
    // ).filter(collanoratorInfo => collanoratorInfo.collaborator.id !== userId);
  },
  REMOVE_USER_FROM_PROJECT_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.removeUserFromProject = {
      ...defaultApiState,
      error
    };
  },
  /**
   * createProjectFromLegacyDocument
   */
  CREATE_PROJECT_FROM_LEGACY_DOCUMENT_LOADING(state: ProjectsState) {
    state.apiState.createProjectFromLegacyDocument = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_PROJECT_FROM_LEGACY_DOCUMENT_SUCCESS(
    state: ProjectsState,
    payload: CreateProjectFromLegacyDocumentResponsePayload
  ) {
    state.apiState.createProjectFromLegacyDocument = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_PROJECT_FROM_LEGACY_DOCUMENT_ERROR(
    state: ProjectsState,
    error: AxiosError
  ) {
    state.apiState.createProjectFromLegacyDocument = {
      ...defaultApiState,
      error
    };
  },
  /**
   * getPreMigrationStatus
   */
  GET_PRE_MIGRATION_STATUS_LOADING(state: ProjectsState) {
    state.apiState.getPreMigrationStatus = {
      ...defaultApiState,
      loading: true
    };
    state.preMigrationStatus = undefined;
  },
  GET_PRE_MIGRATION_STATUS_SUCCESS(
    state: ProjectsState,
    payload: GetPreMigrationStatusResponsePayload
  ) {
    state.apiState.getPreMigrationStatus = {
      ...defaultApiState,
      success: true
    };
    state.preMigrationStatus = payload;
  },
  GET_PRE_MIGRATION_STATUS_ERROR(state: ProjectsState, error: AxiosError) {
    state.apiState.getPreMigrationStatus = {
      ...defaultApiState,
      error
    };
  }
};
