





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import AssignEditorButton from '@/views/AdminDashboard/components/AssignEditorButton.vue';
import ProjectSelectedModal from './ProjectSelectedModal.vue';

@Component({
  components: {
    AssignEditorButton,
    ProjectSelectedModal
  }
})
export default class ProjectSelected extends Vue {
  public hasMore: boolean = false;
  public moreCount: number = 0;

  @Prop({ required: true })
  public selected!: ProjectListItemParam[];

  get projectTotal() {
    return this.selected.length;
  }

  get projectList() {
    if (this.selected.length > 3) {
      this.hasMore = true;
      this.moreCount = this.selected.length - 3;

      return this.selected.slice(0, 3);
    } else {
      this.hasMore = false;
      this.moreCount = 0;

      return this.selected;
    }
  }

  public removeProject(project: ProjectListItemParam) {
    this.$emit('removeSelected', project);
  }

  public updateAssignee(value: string) {
    this.$emit('updateAssignee', value);
  }

  public countAssignedProjects(value: string) {
    this.$emit('countAssignedProjects', value);
  }

  public showMore() {
    this.$buefy.modal.open({
      parent: this,
      component: ProjectSelectedModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        selectedProjects: this.selected
      },
      events: {
        removeSelected: (value: ProjectListItemParam) => {
          this.$emit('removeSelected', value);
        },
        updateSelected: (value: ProjectListItemParam[]) => {
          this.$emit('updateSelected', value);
        }
      }
    });
  }
}
