























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import {
  CplusDocumentTypeDisplayName,
  CplusDocumentType
} from '@/jbi-shared/types/document.types';
import { ProjectIndexingPayload } from '@/jbi-shared/types/search.types';
import truncate from 'truncate';

@Component({
  components: {}
})
export default class DocumentListItem extends Vue {
  @Prop() public project!: ProjectIndexingPayload;

  @Prop(String) public readonly title!: string;
  @Prop(String) public readonly documentType!: CplusDocumentType;
  @Prop(String) public readonly owner!: string;
  @Prop(String) public readonly uniquePublicationId!: string;
  @Prop(String) public readonly defaultDocDisplayId!: string;
  @Prop(String) public readonly publicationStatus!: string;
  @Prop(String) public readonly assigneeFullName!: string;
  @Prop(String) public readonly date!: string;
  @Prop({ type: Array })
  public readonly collaborators!: string[];
  @Prop(String) public readonly primaryResearchNode!: string;
  @Prop(Array) public readonly secondaryResearchNodes!: string[];
  @Prop(Object) public readonly to!: Route;

  get docTypeDisplayName(): string {
    return CplusDocumentTypeDisplayName[this.documentType].toUpperCase();
  }

  get truncatedTitle(): string {
    return truncate(this.title, 165);
  }

  public getSecondaryResearchNodes() {
    if (this.secondaryResearchNodes) {
      return this.secondaryResearchNodes.join(', ');
    }
    return '';
  }
}
