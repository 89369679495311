
















import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import EditorList from './EditorList.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { EditorDetails } from '@/jbi-shared/types/editor.types';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedEditorList extends mixins(PaginationMixin) {
  @Prop() public items!: EditorDetails[];
  @Prop() public totalCount!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  get EditorList() {
    return EditorList;
  }
}
