import { AuthState } from './types/auth.types';
import { defaultApiState } from '@/store/types/general.types';

export const authState: AuthState = {
  apiState: {
    exchangeGrantForToken: defaultApiState,
    signOut: defaultApiState,
    checkSessionHealth: defaultApiState,
    getJaasAccountDetail: defaultApiState,
    getMe: defaultApiState
  },
  authInfo: null,
  isLoggedIn: false,
  jaasAccountDetail: null,
  roles: [],
  myInfo: null
};
