
































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { get as _get } from 'lodash';
import ChangeDueDateDialog from '@/views/AdminDashboard/components/ChangeDueDateDialog.vue';
import AddNoteDialog from '@/views/AdminDashboard/components/AddNoteDialog.vue';
import NoteHistoryDialog from '@/views/AdminDashboard/components/NoteHistoryDialog.vue';
import dayjs from 'dayjs';
import ExportProjectAsDocxDialog from '@/views/AdminDashboard/components/ExportProjectAsDocxDialog.vue';
import {
  ExportProjectAsDocxDialogPayload,
  ResendDownloadLinkRequestPayload
} from '@/store/modules/admin/types/admin.types';
import TableActionDropdown from '@/components/base/TableActionDropdown.vue';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import { State, Action } from 'vuex-class';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { useUserEmail } from '@/utils/user.util';
import { ProjectStatus } from '@/jbi-shared/types/project.types';

@Component({
  components: {
    TableActionDropdown
  },
  name: 'ProjectRow'
})
export default class ProjectRow extends Vue {
  @Prop() public item!: ProjectListItemParam;
  @Prop() public selectedItems!: ProjectListItemParam[];
  @Prop() public selectIdList!: number[];
  @Prop() public selectable!: boolean;

  @Action('admin/resendDownloadLink')
  public resendDownloadLink!: (
    payload: ResendDownloadLinkRequestPayload
  ) => void;

  @State((state: RootState) => _get(state, 'projects.apiState.getAllProjects'))
  public getAllProjects!: ApiState;

  public itemSelected: ProjectListItemParam[] = [];
  public expanded = false;

  get isUnassigned() {
    return (
      this.item.status !== ProjectStatus.Overdue &&
      this.item.status !== ProjectStatus.InProgress
    );
  }

  get documents() {
    return this.item.documents;
  }

  /**
   * If a project contains a withdrawn ES document, we don't allow user to assign/download it
   */
  get hasWithdrawnDocument() {
    return this.esDocument &&
      this.esDocument.status === RevisionPublicationStatus.Withdrawn
      ? true
      : false;
  }

  get esDocument() {
    return this.item.documents.find(
      (doc) => doc.documentType === CplusDocumentType.EvidenceSummary
    );
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public getDocumentEditorRouterLink(projectId: number, documentId: number) {
    if (this.item.status !== ProjectStatus.Pending) {
      return {
        name: 'editor',
        params: {
          projectId,
          documentId
        }
      };
    }
    return {
      name: 'staging-area',
      params: {
        projectId,
        documentId
      }
    };
  }

  public isSelected(item: ProjectListItemParam): boolean {
    const selected = this.selectedItems.find(
      (project) => project.projectId === item.projectId
    );
    return !!selected;
  }

  public updateSelected(item: ProjectListItemParam, selected: boolean) {
    if (selected) {
      this.$emit('addSelected', item);
    } else {
      this.$emit('removeSelected', item);
    }
  }

  public itemSearchDate(item: ProjectListItemParam) {
    const esDoc = item.documents.find(
      (doc) => doc.documentType === CplusDocumentType.EvidenceSummary
    );
    if (!esDoc) {
      return undefined;
    }
    return esDoc.searchDate;
  }

  public formatDate(date: string) {
    return date ? dayjs(date).format('DD MMM YYYY') : '-';
  }

  public formatNote(note: string | undefined) {
    if (!note) {
      return '-';
    } else {
      return note.length < 26 ? note : note.slice(0, 25) + '...';
    }
  }

  public changeDueDate(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: ChangeDueDateDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        project
      }
    });
  }

  public handleResendDownloadLink(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: ExportProjectAsDocxDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        project
      },
      events: {
        exportDocx: this.resendDownloadLinkCb
      }
    });
  }

  public resendDownloadLinkCb(value: ExportProjectAsDocxDialogPayload) {
    const { includeAuditCriteria, includeRp, projectId } = value;

    if (this.userEmail) {
      this.resendDownloadLink({
        projectId,
        adminEmail: this.userEmail,
        includeAuditCriteria,
        includeRp
      });
    }
  }

  public showAddNoteDialog(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: AddNoteDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        project
      }
    });
  }

  public viewContract(project: ProjectListItemParam) {
    if (project.contractStorageUri) {
      window.open(
        project.contractStorageUri.replace(
          'gs://',
          'https://storage.googleapis.com/'
        ),
        '_blank'
      );
    }
  }

  public showNoteHistoryDialog(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: NoteHistoryDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        project
      }
    });
  }
}
