









import { Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import StagingEditorContainer from './components/StagingEditorContainer.vue';
import { FetchPendingDocumentRequestPayload } from '@/store/modules/admin/types/admin.types';

@Component({
  components: {
    StagingEditorContainer
  }
})
export default class StagingAreaPage extends Vue {
  @Action('admin/fetchPendingDocument')
  public fetchPendingDocument!: (
    payload: FetchPendingDocumentRequestPayload
  ) => void;

  @Action('admin/fetchPendingProjectDetails')
  public fetchPendingProjectDetails!: (projectId: number) => void;

  @Action('admin/fetchExistingDocumentDetail')
  public fetchExistingDocumentDetail!: (documentId: number) => void;

  get documentId() {
    return +this.$route.params.documentId;
  }

  get projectId() {
    return +this.$route.params.projectId;
  }

  public async created() {
    this.fetchPendingProjectDetails(this.projectId);

    this.fetchPendingDocument({
      projectId: this.projectId,
      documentId: this.documentId
    });

    if (this.documentId) {
      this.fetchExistingDocumentDetail(this.documentId);
    }
  }
}
