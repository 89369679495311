






















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import {
  ValidationObserver,
  ValidationProvider,
  ValidationProviderInstance
} from 'vee-validate';
import GenericFileUploader from '@/components/form/GenericFileUploader.vue';
import GenericMultipleFileUploader from '@/components/uploader/GenericMultipleFileUploader.vue';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { StaticUploadedFile } from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { chain as _chain } from 'lodash';
import { Job } from 'bull';
import { useAction } from '@/utils/store.util';
import { handleDocumentExporting } from '@/utils/export-docx.util';
import {
  handleEditorDocumentUploading,
  handlePendingDocumentsCreation
} from '@/utils/editorDocuments.util';
import { ImportedEditorDocumentPayload } from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import { ToastProgrammatic } from 'buefy';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    GenericMultipleFileUploader,
    GenericFileUploader
  }
})
export default class UploadDocumentForm extends Vue {
  public fileAttachments = [];
  public isLoadingDocx: boolean = false;
  public groupedFileList: any = [];
  public showAddNewFileUploader: boolean = false;

  get getUploadedDocumentDetails(): (
    payload: StaticUploadedFile[]
  ) => Promise<Job> {
    return useAction.call(this, 'admin/getUploadedDocumentDetails');
  }

  @Action('admin/createPendingDocuments')
  public createPendingDocuments!: (
    payload: ImportedEditorDocumentPayload[]
  ) => Promise<Job>;

  @State((state: RootState) => state.admin.uploadedDocumentDetails)
  public uploadedDocumentDetails!: ImportedEditorDocumentPayload[];

  get validDocuments() {
    return this.groupedFileList.reduce(
      (documentList: ImportedEditorDocumentPayload[], currentGroup: any) => {
        const documents = currentGroup.documents;
        documents.forEach((doc: any) => {
          documentList.push(doc);
        });
        return documentList;
      },
      []
    );
  }

  @Watch('fileAttachments')
  @isTruthy
  public async watchFileAttachments(fileList: any) {
    this.showAddNewFileUploader = false;
    this.isLoadingDocx = true;
    const fileDataToPass = fileList.map((data: any) => {
      return {
        name: data.name,
        signedUrl: data.signedUrl,
        storageUrl: data.storageUrl,
        storageUri: data.storageUri
      };
    });
    const workerJob = await this.getUploadedDocumentDetails(fileDataToPass);
    handleEditorDocumentUploading.call(this, workerJob);
  }

  @Watch('uploadedDocumentDetails')
  @isTruthy
  public watchUploadedDocumentDetails(newValue: any) {
    this.isLoadingDocx = false;
    const groupByProjectId = (array: any[]) => {
      return array.reduce((grouped, el) => {
        if (el.project) {
          (grouped[el.project.id] = grouped[el.project.id] || []).push(el);
        } else {
          (grouped.undefined = grouped.undefined || []).push(el);
        }
        return grouped;
      }, {});
    };
    let groupedDocuments = groupByProjectId(newValue);

    groupedDocuments = Object.keys(groupedDocuments).map((key: any) => {
      const value = groupedDocuments[key];
      return {
        projectTitle: value[0].project
          ? value[0].project.projectTitle
          : undefined,
        projectId: value[0].project ? value[0].project.id : undefined,
        documents: value
      };
    });
    this.addToGroupedFileList(groupedDocuments);
  }

  /*
   * Adds grouped documents to the groupedFileList
   * We need to handle the following cases:
   * 1. If user uploads new files multiple times
   *    - we want to append those files to the correct group.
   * 2. If user uploads files that match the same C+ document multiple times
   *    - we want to replace the uploaded file with the latest uploaded file.
   */
  public addToGroupedFileList(groupedDocuments: any) {
    groupedDocuments.forEach((group: any) => {
      const existingGroupIndex = this.groupedFileList.findIndex(
        (projectGroup: any) => group.projectId === projectGroup.projectId
      );
      if (existingGroupIndex < 0) {
        this.groupedFileList.push(group);
      } else {
        group.documents.forEach((newDoc: any) => {
          // If user uploads docx to same document id multiple times, we want
          // to replace the docx with the latest upload.
          const existingDocumentIndex = this.groupedFileList[
            existingGroupIndex
          ].documents.findIndex((groupDoc: any) => {
            if (newDoc.document && groupDoc.document) {
              return newDoc.document.id === groupDoc.document.id;
            }
            return false;
          });
          if (existingDocumentIndex < 0) {
            this.groupedFileList[existingGroupIndex].documents.push(newDoc);
          } else {
            // Direct replacement does not trigger watch
            // We need to use splice which modifies the array.
            this.groupedFileList[existingGroupIndex].documents.splice(
              existingDocumentIndex,
              1,
              newDoc
            );
          }
        });
      }
    });
  }

  @Watch('groupedFileList', {
    deep: true
  })
  public onGroupedFileListChange() {
    let canUpload = true;
    this.groupedFileList.forEach((group: any) => {
      const documents = group.documents;
      documents.forEach((doc: any) => {
        if (doc.errorMessage || doc.trackRevisionStatus) {
          canUpload = false;
        }
      });
    });
    const provider = this.$refs
      .uploadValidationProvider as ValidationProviderInstance;
    if (canUpload) {
      provider.validate({});
    } else {
      provider.reset();
    }
  }

  public openAddFileUploader() {
    this.showAddNewFileUploader = true;
  }

  public removeDocument(groupFileListIndex: number, documentListIndex: number) {
    const group = this.groupedFileList[groupFileListIndex];
    group.documents.splice(documentListIndex, 1);
    if (group.documents.length === 0) {
      this.groupedFileList.splice(groupFileListIndex, 1);
    }
  }

  public async handleUpload(modal: any) {
    try {
      const creationId = await this.createPendingDocuments(this.validDocuments);
      this.$router.push({
        name: 'pending-document-creation-status',
        params: {
          creationId: String(creationId)
        }
      });
    } catch (error) {
      ToastProgrammatic.open({
        message: `Error. Please try again later.`,
        duration: 5000,
        type: 'is-danger',
        queue: false
      });
    }
    modal.close();
  }
}
