import { GetterTree } from 'vuex';
import { last as _last, get as _get } from 'lodash';
import { ProjectsState } from './types/projects.types';
import { RootState } from '@/store/store';
import { ProjectIndexingPayload } from '@/jbi-shared/types/search.types';
import { getProjectMainDocument } from '@/utils/project.util';
import { Job } from 'bull';
import {
  CPLUS_BULL_JOBS,
  CPLUS_BULL_QUEUES
} from '../../../jbi-shared/types/cplus-worker.types';

export const projectsGetters: GetterTree<ProjectsState, RootState> = {
  getDocumentIdFromProjectSummary: (
    state: ProjectsState,
    _: any,
    rootState: RootState
  ) => (project: ProjectIndexingPayload) => {
    return getProjectMainDocument(project).documentId;
  },
  currentUploadCitationJob(
    state: ProjectsState,
    _: any,
    rootState: RootState
  ): Job | undefined {
    const jobs = rootState.websocket.workerJobs.filter(
      (job) =>
        job.name ===
          CPLUS_BULL_JOBS[CPLUS_BULL_QUEUES.IMPORT].ImportCitationJob &&
        !job.finished
    );
    return _last(jobs);
  },
  processingUploadedCitation(state: ProjectsState, getters): boolean {
    const job: Job | undefined = getters.currentUploadCitationJob;
    const completed = Boolean(job && job.finishedOn);

    const error = Boolean(
      job && Array.isArray(job.stacktrace) && job.stacktrace.length
    );
    const { uploadCitation } = state.apiState;

    // Add log to track upload citation processing
    console.log('Upload Processing', JSON.stringify(job));
    if (job) {
      if (error) {
        console.log('Upload citation error', JSON.stringify(job.stacktrace));
      } else if (job.returnvalue) {
        console.log('Upload citation finish', JSON.stringify(job.returnvalue));
      }
    }

    return job
      ? !(completed || error)
      : uploadCitation.loading || uploadCitation.success;
  },
  uploadCitationCompleted(state: ProjectsState, getters): boolean {
    const job: Job | undefined = getters.currentUploadCitationJob;
    const completed = Boolean(job && job.finishedOn);
    return completed;
  }
  // TODO: uploadCitationError handling?
};
