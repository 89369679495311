import { websocketState } from './websocket.state';
import { websocketGetters } from './websocket.getters';
import { websocketActions } from './websocket.actions';
import { websocketMutations } from './websocket.mutations';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: websocketState,
  getters: websocketGetters,
  actions: websocketActions,
  mutations: websocketMutations
} as ModuleTree<RootState>;
