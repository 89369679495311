

















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BooleanCriterionContent } from '@/jbi-shared/types/criterions.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class BooleanCriterionInput extends Vue {
  @Prop({
    default() {
      return ['True', 'False'];
    }
  })
  public options!: BooleanCriterionContent['booleanOptions'];

  public truthy = this.options[0];
  public falsy = this.options[1];

  @Watch('truthy')
  @Watch('falsy')
  public onChanged() {
    this.$emit('update:options', [this.truthy, this.falsy]);
  }

  @isTruthy
  @Watch('options')
  public onRangeUpdated() {
    this.truthy = this.options[0];
    this.falsy = this.options[1];
  }
}
