




























































import Component, { mixins } from 'vue-class-component';
import dayjs from 'dayjs';
import { Prop } from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import RecentProjectsEmptyItem from '@/views/Dashboard/components/RecentProjectsEmptyItem.vue';
import { useUserName } from '@/utils/user.util';
import DocumentListItem from '@/components/listings/documents/DocumentListItem.vue';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import {
  CplusDocumentType,
  CplusDocumentTypeDisplayName
} from '@/jbi-shared/types/document.types';
import { UIStateMachineChildComponentMixin } from '@/utils/ui-state-machine-child.mixin';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';

@Component({
  components: {
    BasePagination,
    DocumentListItem,
    RecentProjectsEmptyItem
  }
})
export default class DocumentList extends mixins(
  PaginationMixin,
  UIStateMachineChildComponentMixin
) {
  @Prop(Array) public items!: string[] | undefined;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public filter!: CplusDocumentType;
  @Prop(String) public filterStatus!: RevisionPublicationStatus;
  @Prop(String) public type!: string;

  get DocumentStatus() {
    return RevisionPublicationStatus;
  }

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  get dayjs() {
    return dayjs;
  }

  get documentDisplayName() {
    return CplusDocumentTypeDisplayName[this.filter] || 'document';
  }

  get documentDisplayStatus() {
    return RevisionPublicationStatus[
      this.filterStatus as keyof typeof RevisionPublicationStatus
    ];
  }
}
