





























































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import RecentDocumentsItems from './RecentDocumentsItems.vue';
import DashboardListingHeader from '@/components/dashboard/DashboardListingHeader.vue';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin';
import { GetUIStateMachineParentComponentMixin } from '../../../utils/ui-state-machine-parent.mixin';
import { RootState } from '../../../store/store';
import { useAction, useDocumentsState } from '@/utils/store.util';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { PaginatedApiResponse } from '@/store/types/general.types';

@Component({
  components: {
    RecentDocumentsItems,
    DashboardListingHeader
  }
})
export default class RecentDocuments extends mixins(
  ScreenSizeMixin,
  GetUIStateMachineParentComponentMixin(
    'documents/getUserDocuments',
    'documents.userDocuments'
  )
) {
  public showAll: boolean = false;

  get getUserDocuments(): () => void {
    return useAction.call(this, 'documents/getUserDocuments');
  }

  get userDocuments():
    | PaginatedApiResponse<DocumentIndexingPayload>
    | undefined {
    return useDocumentsState.call(this).userDocuments;
  }

  public async mounted() {
    await this.getUserDocuments();
  }

  get documents() {
    if (this.userDocuments) {
      return this.userDocuments.items;
    }
    return [];
  }

  get displayedDocuments() {
    if (this.showAll) {
      return this.documents;
    }
    return this.documents.slice(0, this.columnsPerRow);
  }

  get columnsPerRow(): number {
    if (this.windowWidth < 1100) {
      return 3;
    }
    if (this.windowWidth < 1280) {
      return 4;
    }
    if (this.windowWidth < 2000) {
      return 4;
    } else {
      return 3;
    }
  }

  get columnSizeClass() {
    if (this.windowWidth < 1100) {
      return 'is-4';
    }
    if (this.windowWidth < 1280) {
      return 'is-4';
    }
    if (this.windowWidth < 2500) {
      return 'is-3';
    } else {
      return 'is-2';
    }
  }

  public fillerColumnsCount() {
    return this.displayedDocuments.length % this.columnsPerRow;
  }
}
