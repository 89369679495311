import Vue from 'vue';
import Component from 'vue-class-component';
import { throttle } from 'lodash';

@Component({})
export class ScreenSizeMixin extends Vue {
  public windowWidth = window.innerWidth;
  public windowHeight = window.innerHeight;
  public created() {
    window.addEventListener(
      'resize',
      throttle(() => {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      }, 1000),
    );
  }
}
