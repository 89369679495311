import { Store, ActionContext } from 'vuex';
import { projectsApi } from '@/api/projects.api';
import { documentsApi } from '@/api/documents.api';
import {
  ProjectsState,
  CreateProjectRequestPayload,
  UpdateProjectRequestPayload,
  CreateSubDocumentRequestPayload,
  CitationsOfProject,
  AcceptInvitationRequestPayload,
  InviteParticipantsRequestPayload,
  GetMyProjectsRequestPayload,
  GetAllProjectsRequestPayload,
  RemoveUserFromProjectPayload,
  AcceptInvitationResponsePayload,
  CreateProjectFromLegacyDocumentRequestPayload,
  CreateProjectFromLegacyDocumentResponsePayload,
  GetPreMigrationStatusResponsePayload,
  GetPreMigrationStatusRequestPayload,
  AddNoteToProjectRequestPayload,
  GetAllProjectResponsePayload,
  PaginatedResearchNodesPayload
} from './types/projects.types';
import {
  GetProjectResponsePayload,
  PatchProjectDetailDocumentTitlePayload
} from './types/project-details.types';
import {
  Citation,
  CreateCitationRequestPayload,
  CreateCitationResponsePayload,
  EditCitationRequestPayload,
  UploadCitationRequestPayload
} from '../documents/types/citations.types';
import {
  ResearchNode,
  DocumentsState,
  DocumentDetail
} from '../documents/types/documents.types';
import { RootState } from '@/store/store';
import { PaginatedApiResponse } from '@/store/types/general.types';
import { ProjectIndexingPayload } from '@/jbi-shared/types/search.types';
import { get as _get } from 'lodash';
import retry from 'async-retry';
import { Job } from 'bull';
import { ImportResearchNodePayload } from '../documents/types/nodes.types';

export const projectsActions = {
  async getMyProjects(
    store: Store<ProjectsState>,
    params: GetMyProjectsRequestPayload
  ) {
    try {
      store.commit('GET_MY_PROJECTS_LOADING');

      const { data } = await projectsApi.getMyProjects(params);

      const result: PaginatedApiResponse<ProjectIndexingPayload> = {
        total_count: _get(data, 'hits.total.value'),
        incomplete_results: false,
        items: _get(data, 'hits.hits').map((d: any) => d._source)
      };
      store.commit('GET_MY_PROJECTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_MY_PROJECTS_ERROR', error);
    }
  },
  async getAllProjects(
    store: Store<ProjectsState>,
    params: GetAllProjectsRequestPayload
  ) {
    try {
      store.commit('GET_ALL_PROJECTS_LOADING');

      const { data } = await projectsApi.getAllProjects(params);
      const result: PaginatedApiResponse<GetAllProjectResponsePayload> = {
        total_count: _get(data, 'meta.totalItems'),
        incomplete_results: false,
        items: _get(data, 'items')
      };
      store.commit('GET_ALL_PROJECTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_ALL_PROJECTS_ERROR', error);
    }
  },
  async getProjectCountByProjectStatus(store: Store<ProjectsState>) {
    try {
      store.commit('GET_PROJECT_COUNT_BY_PROJECT_STATUS_LOADING');

      const { data } = await projectsApi.getProjectCountByProjectStatus();
      store.commit('GET_PROJECT_COUNT_BY_PROJECT_STATUS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_COUNT_BY_PROJECT_STATUS_ERROR', error);
    }
  },
  async addNote(
    store: Store<ProjectsState>,
    payload: AddNoteToProjectRequestPayload
  ) {
    try {
      store.commit('ADD_NOTE_TO_PROJECT_LOADING');
      await projectsApi.addNote(payload);
      store.commit('ADD_NOTE_TO_PROJECT_SUCCESS');
    } catch (error) {
      store.commit('ADD_NOTE_TO_PROJECT_ERROR', error);
    }
  },
  async updateNote(
    store: Store<ProjectsState>,
    payload: AddNoteToProjectRequestPayload
  ) {
    try {
      store.commit('UPDATE_PROJECT_NOTE_LOADING');
      await projectsApi.addNote(payload);
      store.commit('UPDATE_PROJECT_NOTE_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_PROJECT_NOTE_ERROR', error);
    }
  },
  async getNoteHistory(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('GET_PROJECT_NOTE_HISTORY_LOADING');
      const { data } = await projectsApi.getNoteHistory(projectId);
      store.commit('GET_PROJECT_NOTE_HISTORY_SUCCESS', {
        noteHistory: data
      });
    } catch (error) {
      store.commit('GET_PROJECT_NOTE_HISTORY_ERROR', error);
    }
  },
  async createProject(
    store: Store<ProjectsState>,
    payload: CreateProjectRequestPayload
  ) {
    try {
      store.commit('CREATE_PROJECT_LOADING');

      const { data } = await projectsApi.createProject(payload);

      store.commit('CREATE_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_PROJECT_ERROR', error);
    }
  },
  async updateProject(
    store: Store<ProjectsState>,
    payload: UpdateProjectRequestPayload
  ) {
    try {
      store.commit('UPDATE_PROJECT_LOADING');

      await projectsApi.updateProject(payload);

      store.commit('UPDATE_PROJECT_SUCCESS', payload);
    } catch (error) {
      store.commit('UPDATE_PROJECT_ERROR', error);
    }
  },
  async createSubDocument(
    store: Store<ProjectsState>,
    payload: CreateSubDocumentRequestPayload
  ) {
    try {
      store.commit('CREATE_SUB_DOCUMENT_LOADING');

      const { data } = await projectsApi.createSubDocument(payload);

      store.commit('CREATE_SUB_DOCUMENT_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_SUB_DOCUMENT_ERROR', error);
    }
  },
  async getProject(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('GET_PROJECT_LOADING');

      const { data } = await projectsApi.getProject(projectId);

      store.commit('GET_PROJECT_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PROJECT_ERROR', error);
    }
  },
  async getResearchNodes(
    store: Store<ProjectsState>,
    params: PaginatedResearchNodesPayload
  ) {
    try {
      store.commit('GET_RESEARCH_NODES_LOADING');

      const { data } = await retry(() => projectsApi.getResearchNodes(params), {
        retries: 3
      });

      store.commit('GET_RESEARCH_NODES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_RESEARCH_NODES_ERROR', error);
    }
  },
  async getAllResearchNodes(store: Store<ProjectsState>) {
    try {
      store.commit('GET_ALL_RESEARCH_NODES_LOADING');

      const res = await retry(() => projectsApi.getAllResearchNodes(), {
        retries: 3
      });
      const researchNodes: ResearchNode[] = res.data.researchNodes;

      store.commit('GET_ALL_RESEARCH_NODES_SUCCESS', researchNodes);
      return researchNodes;
    } catch (error) {
      store.commit('GET_ALL_RESEARCH_NODES_ERROR', error);
    }
  },
  async getActiveResearchNodes(store: Store<ProjectsState>) {
    try {
      store.commit('GET_ACTIVE_RESEARCH_NODES_LOADING');

      const res = await retry(() => projectsApi.getActiveResearchNodes(), {
        retries: 3
      });
      const researchNodes: ResearchNode[] = res.data.researchNodes;

      store.commit('GET_ACTIVE_RESEARCH_NODES_SUCCESS', researchNodes);
    } catch (error) {
      store.commit('GET_ACTIVE_RESEARCH_NODES_ERROR', error);
    }
  },
  async getCitations(store: Store<ProjectsState>, projectId: number) {
    try {
      store.commit('GET_CITATIONS_LOADING', projectId);

      const { data } = await projectsApi.getCitations(projectId);
      const citations: Citation[] = data;
      store.commit('GET_CITATIONS_SUCCESS', {
        projectId,
        citations
      } as CitationsOfProject);
    } catch (error) {
      store.commit('GET_CITATIONS_ERROR', error);
    }
  },
  async deleteCitation(
    store: Store<ProjectsState>,
    { projectId, citationId }: { projectId: number; citationId: number }
  ) {
    try {
      store.commit('DELETE_CITATION_LOADING');

      await projectsApi.deleteCitation(projectId, citationId);

      store.commit('DELETE_CITATION_SUCCESS', {
        projectId,
        citationId
      });
    } catch (error) {
      store.commit('DELETE_CITATION_ERROR', error);
    }
  },

  async createCitation(
    store: Store<ProjectsState>,
    payload: CreateCitationRequestPayload
  ) {
    try {
      store.commit('CREATE_CITATION_LOADING');

      const {
        data
      }: {
        data: CreateCitationResponsePayload;
      } = await projectsApi.createCitation(payload);

      store.commit('CREATE_CITATION_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_CITATION_ERROR', error);
    }
  },
  async editCitation(
    store: Store<ProjectsState>,
    payload: EditCitationRequestPayload
  ) {
    try {
      store.commit('EDIT_CITATION_LOADING');

      const editResponse = await projectsApi.editCitation(payload);
      store.commit('EDIT_CITATION_SUCCESS', editResponse.data);
    } catch (error) {
      store.commit('EDIT_CITATION_ERROR', error);
    }
  },
  async uploadCitation(
    store: ActionContext<ProjectsState, RootState>,
    payload: UploadCitationRequestPayload
  ) {
    try {
      store.commit('UPLOAD_CITATION_LOADING');

      const response = await projectsApi.uploadCitation(payload);

      store.commit('UPLOAD_CITATION_SUCCESS');

      const workerJob = response.data.job as Job;

      store.dispatch('websocket/addWorkerJob', workerJob, {
        root: true
      });

      return workerJob;
    } catch (error) {
      store.commit('UPLOAD_CITATION_ERROR', error);
    }
  },
  async resetUploadCitationState(
    store: ActionContext<ProjectsState, RootState>
  ) {
    store.commit('UPLOAD_CITATION_RESET');
  },
  async patchProjectDetailDocumentTitle(
    store: ActionContext<ProjectsState, RootState>,
    payload: PatchProjectDetailDocumentTitlePayload
  ) {
    store.commit('PATCH_PROJECT_DETAIL_DOCUMENT_TITLE', payload);
  },
  async importResearchNodes(
    store: ActionContext<ProjectsState, RootState>,
    payload: ImportResearchNodePayload
  ) {
    try {
      store.commit('IMPORT_RESEARCH_NODES_LOADING');

      const response = await projectsApi.importResearchNodes(payload);

      store.commit('IMPORT_RESEARCH_NODES_SUCCESS');

      const workerJob = response.data.job as Job;

      store.dispatch('websocket/addWorkerJob', workerJob, {
        root: true
      });

      return workerJob;
    } catch (error) {
      store.commit('IMPORT_RESEARCH_NODES_ERROR', error);
    }
  },
  async resetImportResearchNodesState(
    store: ActionContext<ProjectsState, RootState>
  ) {
    store.commit('IMPORT_RESEARCH_NODES_RESET');
  },
  async acceptInvitation(
    store: ActionContext<ProjectsState, RootState>,
    payload: AcceptInvitationRequestPayload
  ) {
    try {
      store.commit('ACCEPT_INVITATION_LOADING');

      const { data } = await projectsApi.acceptInvitation(payload);

      store.commit(
        'ACCEPT_INVITATION_SUCCESS',
        data as AcceptInvitationResponsePayload
      );
    } catch (error) {
      store.commit('ACCEPT_INVITATION_ERROR', error);
    }
  },
  async inviteUser(
    store: Store<ProjectsState>,
    payload: InviteParticipantsRequestPayload
  ) {
    try {
      store.commit('INVITE_USER_LOADING');

      const { data } = await projectsApi.inviteUser(payload);

      store.commit('INVITE_USER_SUCCESS', data);
    } catch (error) {
      store.commit('INVITE_USER_ERROR', error);
    }
  },
  async resendUserInvitation(
    store: Store<ProjectsState>,
    payload: InviteParticipantsRequestPayload
  ) {
    try {
      store.commit('RESEND_USER_INVITATION_LOADING');

      await projectsApi.resendUserInvitation(payload);

      store.commit('RESEND_USER_INVITATION_SUCCESS');
    } catch (error) {
      store.commit('RESEND_USER_INVITATION_ERROR', error);
    }
  },
  async removeUserFromProject(
    store: Store<ProjectsState>,
    payload: RemoveUserFromProjectPayload
  ) {
    try {
      store.commit('REMOVE_USER_FROM_PROJECT_LOADING');

      await projectsApi.removeUserFromProject(payload);

      store.commit('REMOVE_USER_FROM_PROJECT_SUCCESS', payload);
    } catch (error) {
      store.commit('REMOVE_USER_FROM_PROJECT_ERROR', error);
    }
  },
  async createProjectFromLegacyDocument(
    store: Store<ProjectsState>,
    payload: CreateProjectFromLegacyDocumentRequestPayload
  ) {
    try {
      store.commit('CREATE_PROJECT_FROM_LEGACY_DOCUMENT_LOADING');

      const res = await projectsApi.createProjectFromLegacyDocument(payload);
      const data: CreateProjectFromLegacyDocumentResponsePayload = res.data;

      store.commit('CREATE_PROJECT_FROM_LEGACY_DOCUMENT_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('CREATE_PROJECT_FROM_LEGACY_DOCUMENT_ERROR', error);
    }
  },
  async getPreMigrationStatus(
    store: Store<ProjectsState>,
    payload: GetPreMigrationStatusRequestPayload
  ) {
    try {
      store.commit('GET_PRE_MIGRATION_STATUS_LOADING');

      const res = await projectsApi.getPreMigrationStatus(payload);
      const data: GetPreMigrationStatusResponsePayload = res.data;

      store.commit('GET_PRE_MIGRATION_STATUS_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('GET_PRE_MIGRATION_STATUS_ERROR', error);
    }
  }
};
