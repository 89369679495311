import { EventLogsState } from './types/event-logs.types';
import { Store } from 'vuex';
import { get as _get } from 'lodash';
import { eventLogsApi } from '../../../api/event-logs.api';

export const eventLogsActions = {
  async getRecentEventLogs(store: Store<EventLogsState>) {
    try {
      store.commit('GET_RECENT_EVENT_LOGS_LOADING');

      const { data } = await eventLogsApi.getRecentEventLogs();

      store.commit('GET_RECENT_EVENT_LOGS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_RECENT_EVENT_LOGS_ERROR', error);
    }
  }
};
