














































































































































































import Component, { mixins } from 'vue-class-component';
import draggable from 'vuedraggable';
import { Prop, Watch } from 'vue-property-decorator';
import { opsToText } from '@/utils/quill-delta.util';
import SectionCard from '@/views/DocumentEditor/components/tab/EditorSectionsManagementTab_SectionCard.vue';
import { Publication, getArchivedPublications } from '@/utils/publication.util';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { EditorCommonMixin } from '../../mixins/editor-common.mixin';
import Op from 'quill-delta/dist/Op';
import { DocumentData } from '../../../../jbi-shared/types/full-document-revision-object.types';

@Component({
  // @ts-ignore
  components: {
    draggable,
    SectionCard
  }
})
export default class EditorSectionsManagementTab extends mixins(
  EditorCommonMixin
) {
  @Prop(String) public refSecTitle!: string;
  @Prop(String) public bprSecTitle!: string;

  public drag = false;

  public dragOptions = {
    animation: 200,
    group: 'description',
    disabled: false,
    ghostClass: 'draggable__ghost',
    chosenClass: 'draggable__chosen',
    dragClass: 'draggable__drag',
    forceFallback: true
  };

  public updateSectionSortOrder() {
    this.dirtyTextSections = this.dirtyTextSections.map((section, index) => {
      return {
        ...section,
        sortOrder: index
      };
    });
  }

  get filteredSections(): FullDocumentRevisionObject['revision']['sections']['textSections'] {
    return (
      this.dirtyTextSections
        // filter based on search
        .filter((sections) => {
          return opsToText(sections.sectionTitle as Op[])
            .toLowerCase()
            .includes(this.sectionSearchValue.toLowerCase());
        })
    );
  }

  get isSearching(): boolean {
    return !!this.sectionSearchValue;
  }

  get searchResultIsEmpty(): boolean {
    return (
      this.isSearching &&
      (!this.filteredSections || !this.filteredSections.length)
    );
  }

  get draggableSections() {
    return this.filteredSections;
  }

  set draggableSections(
    sections: FullDocumentRevisionObject['revision']['sections']['textSections']
  ) {
    this.dirtyTextSections = sections;
  }

  get publishedRevisions(): FullDocumentRevisionObject['document']['publishedRevisions'] {
    return this.documentDetail?.document?.publishedRevisions || [];
  }

  get archivedPublications(): Publication[] {
    return getArchivedPublications(this.documentDetail!.document);
  }

  get relatedRPDocs(): DocumentData[] {
    return this.documentDetail?.document.relatedRPDocumentData
      ? this.documentDetail?.document.relatedRPDocumentData
      : [];
  }

  @Watch('isSearching')
  public watchSectionSearchValue(isSearching: boolean) {
    this.dragOptions.disabled = isSearching;
  }
}
