

































import { Component, Vue, Prop } from 'vue-property-decorator';
import GenericFileUploader from '@/components/form/GenericFileUploader.vue';

@Component({
  components: {
    GenericFileUploader
  }
})
export default class PdfUploadModal extends Vue {
  @Prop() public modalTitle!: string;
  public storageUri: string = '';
}
