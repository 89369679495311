// TODO: rename all these variable to CplusSomething, because all these are purely for Cplus
export enum CasbinResource {
  Projects = 'projects',
  Documents = 'documents',
  Revisions = 'revisions',
  ResearchNodes = 'research-nodes',
}

export enum CasbinAction {
  GET = 'read',
  POST = 'write',
  PUT = 'update',
  DELETE = 'delete',
}

export type CasbinRoles = GeneralRoles | DocumentRoles | ProjectRoles;

export enum GeneralRoles {
  Admin = 'admin',
  User = 'user',
  WkAdmin = 'wk-admin',
}

export enum DocumentRoles {
  DocumentOwner = 'document_owner_',
  DocumentAuthor = 'document_author_',
  DocumentReviewer = 'document_reviewer_',
}

export enum ProjectRoles {
  ProjectOwner = 'project_owner_',
}

export interface RoleGuardMetadata {
  role: CasbinRoles;
  param?: string;
}
