






















import { Component, Vue, Prop } from 'vue-property-decorator';
import CreateProjectButton from '@/components/form/CreateProjectButton.vue';
import InviteUserButton from '@/views/AdminUserManagement/components/InviteUserButton.vue';

@Component({
  components: {
    CreateProjectButton,
    InviteUserButton
  }
})
export default class DashboardHeader extends Vue {
  @Prop(String) public pageTitle!: string;
  @Prop(Boolean) public withCreateProject!: boolean;
  @Prop(Boolean) public withInviteUser!: boolean;
}
