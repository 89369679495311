import Vue from 'vue';
import { useAuthState } from './store.util';

export const useOicPayload = function (this: Vue) {
  const authInfo = useAuthState.call(this).authInfo;
  return authInfo ? authInfo.oicPayload : undefined;
};

export const useUserName = function (this: Vue) {
  const oicPayload = useOicPayload.call(this);
  return oicPayload ? oicPayload.preferred_username : undefined;
};

export const useUserEmail = function (this: Vue) {
  const oicPayload = useOicPayload.call(this);
  return oicPayload ? oicPayload!.email : undefined;
};
