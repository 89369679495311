
































import { Component, Watch, Provide } from 'vue-property-decorator';
import { isEqual as _isEqual } from 'lodash';
import PreviewDocument from '@/views/DocumentPreview/components/PreviewDocument.vue';
import PreviewHeader from '@/views/DocumentPreview/components/PreviewHeader.vue';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { Action } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import slugify from 'slugify';
import { EditorViewMode } from '@/utils/viewMode.mixin';
import { getArchivedPublications, Publication } from '@/utils/publication.util';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '../DocumentEditor/mixins/editor-common.mixin';
import {
  DocumentExportType,
  ExportDocumentRequestPayload,
  GetDocumentByPublicationIdRequestPayload,
  GetDocumentByRevisionIdRequestPayload,
  RevisionsOfDocument
} from '@/store/modules/documents/types/documents.types';
import { Job } from 'bull';
import { handlePrintPdf } from '@/utils/print-pdf.util';
import { CplusDocumentTypeShortHand } from '@/jbi-shared/types/document.types';
import { filter, mergeMap, take, distinctUntilChanged } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ConvertedLegacyDocumentPayload } from '@/jbi-shared/types/legacy-document.types';
import printJS from 'print-js';

@Component({
  components: {
    PreviewDocument,
    PreviewHeader
  }
})
export default class DocumentPreviewPage extends mixins(EditorCommonMixin) {
  @Provide() public viewMode = EditorViewMode.public;

  @Action('documents/getRevisionsByDocumentId')
  public getRevisionsByDocumentId!: (
    docId: number
  ) => Promise<Partial<RevisionsOfDocument>>;

  @Action('documents/getDocumentByPublicationId')
  public getDocumentByPublicationId!: (
    params: GetDocumentByPublicationIdRequestPayload
  ) => void;

  @Action('documents/getDocumentByRevisionId')
  public getDocumentByRevisionId!: (
    params: GetDocumentByRevisionIdRequestPayload
  ) => void;

  @Action('documents/getLegacyContentByBaseDocumentId')
  public getLegacyContentByBaseDocumentId!: (
    baseDocumentId: number
  ) => Promise<ConvertedLegacyDocumentPayload[]>;

  @Action('documents/exportDocument')
  public exportDocument!: ({
    documentId,
    revisionId,
    exportType
  }: ExportDocumentRequestPayload) => Promise<Job>;

  public created() {
    /**
     * if isPreviewingUnknownType,
     * call getRevisionsByDocumentId,
     * decides what to do after `revisionsByDocumentId` is returned
     */
    const isPreviewingUnknownType$ = this.$reactiveValueObservable<boolean>(
      'isPreviewingUnknownType'
    );
    isPreviewingUnknownType$
      .pipe(
        filter(Boolean),
        take(1),
        mergeMap(() => this.getRevisionsByDocumentId(this.documentId))
      )
      .subscribe(this.handleRedirectionForUnknownPreviewType.bind(this));

    const documentId$ = this.$reactiveValueObservable<number>(
      'documentId'
    ).pipe(filter<number>(Boolean));
    const publicationId$ = this.$reactiveValueObservable<number>(
      'publicationId'
    ).pipe(filter<number>(Boolean));
    const revisionId$ = this.$reactiveValueObservable<number>(
      'revisionId'
    ).pipe(filter<number>(Boolean));
    const baseDocumentId$ = this.$reactiveValueObservable<number>(
      'baseDocumentId'
    ).pipe(filter<number>(Boolean));

    /**
     * call getDocumentByPublicationId when needed
     */
    combineLatest(documentId$, publicationId$)
      .pipe(distinctUntilChanged(_isEqual))
      .subscribe(([documentId, publicationId]) => {
        if (
          _isEqual(
            [documentId, publicationId],
            [
              this.documentDetail?.document?.id,
              this.documentDetail?.revision?.publicationId
            ]
          )
        ) {
          return;
        }
        this.getDocumentByPublicationId({
          publicationId,
          documentId
        });
      });

    /**
     * call getDocumentByRevisionId when needed
     */
    combineLatest(documentId$, revisionId$)
      .pipe(distinctUntilChanged(_isEqual))
      .subscribe(([documentId, revisionId]) => {
        if (
          _isEqual(
            [documentId, revisionId],
            [
              this.documentDetail?.document?.id,
              this.documentDetail?.revision?.id
            ]
          )
        ) {
          return;
        }
        this.getDocumentByRevisionId({
          revisionId,
          documentId
        });
      });

    /**
     * call getLegacyContentByBaseDocumentId when needed
     */
    baseDocumentId$.pipe(distinctUntilChanged()).subscribe((baseDocumentId) => {
      if (
        this.legacyContentByBaseDocumentId &&
        this.legacyContentByBaseDocumentId.baseDocumentId === baseDocumentId
      ) {
        return;
      }
      this.getLegacyContentByBaseDocumentId(baseDocumentId);
    });
  }

  get isPreviewingPublication() {
    return this.$route.name === 'preview-publication';
  }

  get isPreviewingRevision() {
    return this.$route.name === 'preview-revision';
  }

  get isPreviewingLegacyDocument() {
    return this.$route.name === 'preview-legacy-documents';
  }

  get isPreviewingUnknownType() {
    return !(
      this.isPreviewingPublication ||
      this.isPreviewingRevision ||
      this.isPreviewingLegacyDocument
    );
  }

  public handleRedirectionForUnknownPreviewType({
    revisions
  }: Partial<RevisionsOfDocument>) {
    if (!revisions) {
      return;
    }
    const sortedAsc = revisions.slice().sort((a, b) => a.id - b.id);
    const latestPublicationId = sortedAsc.filter(({ publishedAt }) =>
      Boolean(publishedAt)
    ).length;
    const latestPublishedRevision = sortedAsc[latestPublicationId - 1];
    const latestRevision = sortedAsc.slice().pop()!;
    const latestRevisionId = latestRevision.id;
    /**
     *  go to 'preview-publication'
     */
    if (latestPublicationId) {
      const typeShorthand =
        CplusDocumentTypeShortHand[
          latestPublishedRevision.projectDocument.documentType
        ];
      this.$router.replace({
        name: 'preview-publication',
        params: {
          typeShorthand,
          documentId: String(latestPublishedRevision.projectDocument.id),
          publicationId: String(latestPublicationId)
        }
      });
    } else if (latestRevisionId) {
      /**
       *  go to 'preview-revision',
       */
      this.$router.replace({
        name: 'preview-revision',
        params: {
          documentId: String(latestRevision.projectDocument.id),
          revisionId: String(latestRevisionId)
        }
      });
    }
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get publicationId(): number {
    return this.isPreviewingPublication ? +this.$route.params.publicationId : 0;
  }

  get revisionId(): number {
    return this.isPreviewingRevision ? +this.$route.params.revisionId : 0;
  }

  get baseDocumentId(): number {
    return this.isPreviewingLegacyDocument
      ? +this.$route.params.baseDocumentId
      : 0;
  }

  get citations(): Citation[] {
    return this.documentDetail?.project?.citations || [];
  }

  get pageTitle() {
    if (!this.documentDetail) {
      return '';
    }
    return `${this.documentDetail!.document.title} (Read Only)`;
  }

  get relatedDocs() {
    return this.documentDetail?.revision?.sections?.relatedDocSubSections || [];
  }

  get publishedRevisions() {
    return this.documentDetail?.document?.publishedRevisions || [];
  }

  get archivedPublications(): Publication[] {
    return getArchivedPublications(this.documentDetail!.document);
  }

  get resourceUrl() {
    if (this.documentDetail) {
      const storageUri =
        this.documentDetail?.revision?.resource?.storageUri || '';
      return storageUri.replace('gs://', 'https://storage.googleapis.com/');
    }
  }

  public async handlePrint() {
    // handle BPIS print
    if (this.resourceUrl) {
      printJS({
        printable: this.resourceUrl
      });
      return;
    }

    const workerJob = this.isPreviewingLegacyDocument
      ? await this.exportDocument({
          baseDocumentId: this.baseDocumentId,
          exportType: DocumentExportType.pdf
        })
      : await this.exportDocument({
          documentId: this.documentId,
          revisionId: this.documentDetail!.revision.id,
          exportType: DocumentExportType.pdf
        });

    handlePrintPdf.call(this, workerJob);
  }

  @isTruthy
  @Watch('documentDetail', { immediate: true })
  public onDocLoaded() {
    /**
     * this automatically fixes the URL, and add hash to the url, if necessary
     */
    const { publicationId } = this;
    const {
      title: documentTitle,
      documentType
    } = this.documentDetail!.document!;
    const titleSlug = slugify(documentTitle);
    if (this.isPreviewingPublication) {
      this.$router.replace({
        name: 'preview-publication',
        params: {
          documentId: this.$route.params.documentId,
          publicationId: publicationId.toString(),
          typeShorthand: CplusDocumentTypeShortHand[documentType]
        },
        hash: `/${titleSlug}`
      });
    } else if (this.isPreviewingRevision) {
      this.$router.replace({
        name: 'preview-revision',
        params: {
          documentId: String(this.documentDetail!.document.id),
          revisionId: String(this.documentDetail!.revision.id)
        },
        hash: `/${titleSlug}`
      });
    }
  }
}
