


















































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { useGetter } from '@/utils/store.util';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import truncate from 'truncate';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin';
import RecentDocumentsItemContainer from './RecentDocumentsItemContainer.vue';
import { DATE_FORMAT } from '../../../utils/date.util';
import relativeTime from 'dayjs/plugin/relativeTime';

@Component({
  components: { RecentDocumentsItemContainer }
})
export default class RecentDocumentsItems extends mixins(ScreenSizeMixin) {
  @Prop(Number) public columnsPerRow!: number;

  @Prop(String) public readonly title!: string;
  @Prop(String) public readonly documentOwner!: string;
  @Prop(String) public readonly publicationStatus!: string;
  @Prop(String) public readonly assigneeFullName!: string;
  @Prop(String) public readonly defaultDocDisplayId!: string;
  @Prop(String) public readonly uniquePublicationId!: string;
  @Prop(String) public readonly updatedAt!: string;
  @Prop(Number) public readonly documentId!: number;
  @Prop(Number) public readonly projectId!: number;
  @Prop({ type: Array })
  public readonly collaborators!: string[];

  public today = new Date();

  get formatTime() {
    dayjs.extend(relativeTime);
    return dayjs().from(dayjs(this.updatedAt), true) + ' ' + 'ago';
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  get truncatedTitle(): string {
    return truncate(this.title, 100);
  }
}
