



































import { Component, Prop } from 'vue-property-decorator';
import DocumentList from '@/components/listings/documents/DocumentList.vue';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import DashboardListingHeader from '@/components/dashboard/DashboardListingHeader.vue';
import Dropdown from '@/components/base/Dropdown.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import RecentProjectsEmptyItem from '@/views/Dashboard/components/RecentProjectsEmptyItem.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import OptionsMixin from '@/components/form/options.mixin';

@Component({
  components: {
    BasePaginatorHoc,
    DashboardListingHeader,
    Dropdown,
    RecentProjectsEmptyItem,
    BaseLoading
  }
})
export default class PaginatedDocumentList extends mixins(
  PaginationMixin,
  OptionsMixin
) {
  @Prop(Array) public items!: string[] | undefined;
  @Prop(Number) public totalCount!: number;
  @Prop(String) public filter!: CplusDocumentType;
  @Prop(String) public filterStatus!: RevisionPublicationStatus;
  @Prop(String) public type!: string;
  @Prop(Boolean) public loading!: boolean;

  get DocumentList() {
    return DocumentList;
  }

  get filterOptions(): StringInputOption[] {
    return [
      {
        id: '',
        name: 'All Document Types'
      },
      ...this.documentTypeOptions
    ];
  }
}
