import { Store } from 'vuex';
import { documentsApi } from '@/api/documents.api';
import {
  CheckPermissionRequestPayload,
  CheckPermissionResponsePayload,
  DocumentDetail,
  DocumentsState,
  ExportDocumentRequestPayload,
  ExportWkResourceRequestPayload,
  GetDocumentByRevisionIdRequestPayload,
  GetDocumentParticipantsRequestPayload,
  GetUserDocumentsRequestPayload,
  GetDocumentByPublicationIdRequestPayload,
  InviteDocumentParticipantsRequestPayload,
  RestoreRevisionPayload,
  RevisionsOfDocument,
  SearchDocumentPayload,
  SearchKeywordPayload,
  UpdateBPISRequestPayload,
  UpdateEvidenceSummaryRequestPayload,
  UpdateRecommendedPracticeRequestPayload,
  UpdateRevisionNameRequestPayload,
  RemoveRevisionCitationRequestPayload,
  GetRevisionCitationsRequestPayload,
  UpdateDocAssigneeRequestPayload,
  UpdateDocAssigneeResponsePayload,
  GetMyAssignedDocumentsRequestPayload,
  GetDocumentByVersionIdRequestPayload,
  UpdateSRRequestPayload
} from './types/documents.types';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import {
  Facet,
  FacetTypes,
  PaginatedApiResponse
} from '@/store/types/general.types';
import { PatchProjectDetailDocumentTitlePayload } from '../projects/types/project-details.types';
import { PublishDocumentPayload } from '@/store/modules/projects/types/projects.types';
import { Keyword } from '@/jbi-shared/types/document.types';
import { AxiosResponse } from 'axios';
import { Job } from 'bull';
import { ConvertedLegacyDocumentPayload } from '@/jbi-shared/types/legacy-document.types';
import {
  FetchWkExportResourceDetailRequestPayload,
  WkExportResourceDetailPayload,
  WkExportStatus
} from '@/jbi-shared/types/wk-export.types';
import { MeshDto } from '@/jbi-shared/util/mesh.util';

export const documentsActions = {
  async getDocumentDetail(store: Store<DocumentsState>, id: number) {
    try {
      store.commit('GET_DOCUMENT_DETAIL_LOADING');

      const {
        data
      }: {
        data: DocumentDetail;
      } = await documentsApi.getDocumentDetail(id);

      store.commit('GET_DOCUMENT_DETAIL_SUCCESS', data);
    } catch (error) {
      store.commit('GET_DOCUMENT_DETAIL_ERROR', error);
    }
  },
  async resetDocumentDetail(store: Store<DocumentsState>) {
    store.commit('DOCUMENT_DETAIL_RESET');
  },
  async updatePDFDocument(
    store: Store<DocumentsState>,
    payload: UpdateBPISRequestPayload | UpdateSRRequestPayload
  ) {
    try {
      store.commit('UPDATE_DOCUMENT_LOADING');

      await documentsApi.updateDocument({ ...payload });

      // retrieve all document detail again
      await store.dispatch('getDocumentDetail', payload.document?.id);

      store.commit('UPDATE_DOCUMENT_SUCCESS', payload);
    } catch (error) {
      store.commit('UPDATE_DOCUMENT_ERROR', error);
    }
  },
  async updateDocument(
    store: Store<DocumentsState>,
    payload:
      | UpdateRecommendedPracticeRequestPayload
      | UpdateEvidenceSummaryRequestPayload
  ) {
    try {
      store.commit('UPDATE_DOCUMENT_LOADING');

      const sections = payload?.revision?.sections;

      // get document text section
      const textSections = sections ? sections.textSections : undefined;

      await documentsApi.updateDocument({
        ...payload
      });

      // patch current project state
      if (payload.document?.title) {
        await store.dispatch(
          'projects/patchProjectDetailDocumentTitle',
          {
            documentId: payload.document.id,
            documentTitle: payload.document?.title
          } as PatchProjectDetailDocumentTitlePayload,
          { root: true }
        );
      }

      // retrieve all document detail again
      await store.dispatch('getDocumentDetail', payload.document?.id);

      store.commit('UPDATE_DOCUMENT_SUCCESS', payload);
    } catch (error) {
      store.commit('UPDATE_DOCUMENT_ERROR', error);
    }
  },
  async deleteDocument(
    store: Store<DocumentsState>,
    payload: Partial<DocumentDetail>
  ) {
    try {
      store.commit('DELETE_DOCUMENT_LOADING');

      await documentsApi.deleteDocument(payload);

      store.commit('DELETE_DOCUMENT_SUCCESS');
    } catch (error) {
      store.commit('DELETE_DOCUMENT_ERROR', error);
    }
  },
  async exportSearchResult(
    store: Store<DocumentsState>,
    queryParams: SearchDocumentPayload
  ) {
    try {
      store.commit('EXPORT_SEARCH_LOADING');

      let response: AxiosResponse<any>;
      response = await documentsApi.exportSearchResult(queryParams);

      store.commit('EXPORT_SEARCH_SUCCESS');

      const workerJob = response.data.job as Job;

      store.dispatch('websocket/addWorkerJob', workerJob, {
        root: true
      });

      return workerJob;
    } catch (error) {
      store.commit('EXPORT_SEARCH_ERROR', error);
    }
  },
  async exportDocument(
    store: Store<DocumentsState>,
    {
      documentId,
      revisionId,
      baseDocumentId,
      exportType,
      includeAuditCriteria
    }: ExportDocumentRequestPayload
  ) {
    try {
      store.commit('EXPORT_DOC_LOADING');

      let response: AxiosResponse<any>;
      if (documentId && revisionId) {
        response = await documentsApi.exportDocument({
          documentId,
          revisionId,
          exportType,
          includeAuditCriteria
        });
      } else if (baseDocumentId) {
        response = await documentsApi.exportLegacyDocument({
          baseDocumentId,
          exportType
        });
      } else {
        throw new Error(
          `Invalid Action Payload ${JSON.stringify(arguments[1])}`
        );
      }

      store.commit('EXPORT_DOC_SUCCESS');

      const workerJob = response.data.job as Job;

      store.dispatch('websocket/addWorkerJob', workerJob, {
        root: true
      });

      return workerJob;
    } catch (error) {
      store.commit('EXPORT_DOC_ERROR', error);
    }
  },
  async getDocumentByRevisionId(
    store: Store<DocumentsState>,
    params: GetDocumentByRevisionIdRequestPayload
  ) {
    try {
      store.commit('GET_DOCUMENT_BY_REVISION_ID_LOADING');

      const { data } = await documentsApi.getDocumentByRevisionId(params);

      const documentByRevisionId: DocumentDetail = data;

      store.commit('GET_DOCUMENT_BY_REVISION_ID_SUCCESS', documentByRevisionId);
    } catch (error) {
      store.commit('GET_DOCUMENT_BY_REVISION_ID_ERROR', error);
    }
  },
  async getDocumentByVersionId(
    store: Store<DocumentsState>,
    params: GetDocumentByVersionIdRequestPayload
  ) {
    try {
      store.commit('GET_DOCUMENT_BY_VERSION_ID_LOADING');

      const { data } = await documentsApi.getDocumentByVersionId(params);

      const documentByVersionId: DocumentDetail = data;

      store.commit('GET_DOCUMENT_BY_VERSION_ID_SUCCESS', documentByVersionId);
    } catch (error) {
      store.commit('GET_DOCUMENT_BY_VERSION_ID_ERROR', error);
    }
  },
  async updateRevisionName(
    store: Store<DocumentsState>,
    payload: UpdateRevisionNameRequestPayload
  ) {
    try {
      store.commit('UPDATE_REVISION_NAME_LOADING');

      await documentsApi.updateRevisionName({
        ...payload
      });

      store.commit('UPDATE_REVISION_NAME_SUCCESS', payload);
    } catch (error) {
      store.commit('UPDATE_REVISION_NAME_ERROR', error);
    }
  },
  async removeRevisionCitation(
    store: Store<DocumentsState>,
    payload: RemoveRevisionCitationRequestPayload
  ) {
    try {
      store.commit('REMOVE_REVISION_CITATION_LOADING');

      await documentsApi.removeRevisionCitation({
        ...payload
      });

      store.commit('REMOVE_REVISION_CITATION_SUCCESS', payload);
    } catch (error) {
      store.commit('REMOVE_REVISION_CITATION_ERROR', error);
    }
  },
  async getRevisionCitations(
    store: Store<DocumentsState>,
    payload: GetRevisionCitationsRequestPayload
  ) {
    try {
      store.commit('GET_REVISION_CITATIONS_LOADING');

      const revisionCitations = await documentsApi.getRevisionCitations({
        ...payload
      });

      store.commit('GET_REVISION_CITATIONS_SUCCESS', revisionCitations.data);
    } catch (error) {
      store.commit('GET_REVISION_CITATIONS_ERROR', error);
    }
  },
  async restoreRevision(
    store: Store<DocumentsState>,
    payload: RestoreRevisionPayload
  ) {
    try {
      store.commit('RESTORE_REVISION_LOADING');

      await documentsApi.restoreRevision({
        ...payload
      });

      // retrieve all document detail again
      await store.dispatch('getDocumentDetail', payload.documentId);

      store.commit('RESTORE_REVISION_SUCCESS', payload);
    } catch (error) {
      store.commit('RESTORE_REVISION_ERROR', error);
    }
  },
  async getRevisionsByDocumentId(
    store: Store<DocumentsState>,
    documentId: number
  ) {
    try {
      store.commit('GET_REVISIONS_LOADING');

      const { data } = await documentsApi.getRevisionsByDocumentId(documentId);

      store.commit('GET_REVISIONS_SUCCESS', {
        documentId,
        revisions: data.revisions,
        legacyDocuments: data.legacyDocuments,
        latestVersionId: data.latestVersionId,
        nonDraftRevisions: data.nonDraftRevisions
      } as RevisionsOfDocument);

      return data;
    } catch (error) {
      store.commit('GET_REVISIONS_ERROR', error);
    }
  },
  /*
   ** getAllLegacyDocVersionsByBaseDocumentId
   */
  async resetRevisionsState(store: Store<DocumentsState>) {
    store.commit('GET_REVISIONS_RESET');
  },
  async getAllLegacyDocVersionsByBaseDocumentId(
    store: Store<DocumentsState>,
    baseDocumentId: number
  ) {
    try {
      store.commit('GET_ALL_LEGACY_DOC_VERSIONS_BY_BASE_DOCUMENT_ID_LOADING');

      const res = await documentsApi.getAllLegacyDocVersionsByBaseDocumentId(
        baseDocumentId
      );
      const data: ConvertedLegacyDocumentPayload[] = res.data;

      store.commit(
        'GET_ALL_LEGACY_DOC_VERSIONS_BY_BASE_DOCUMENT_ID_SUCCESS',
        data
      );

      return data;
    } catch (error) {
      store.commit(
        'GET_ALL_LEGACY_DOC_VERSIONS_BY_BASE_DOCUMENT_ID_ERROR',
        error
      );
    }
  },
  async getLegacyContentByBaseDocumentId(
    store: Store<DocumentsState>,
    baseDocumentId: number
  ) {
    try {
      store.commit('GET_LEGACY_CONTENT_BY_BASE_DOCUMENT_ID_LOADING');

      const res = await documentsApi.getLegacyContentByBaseDocumentId(
        baseDocumentId
      );
      const data: ConvertedLegacyDocumentPayload[] = res.data;

      store.commit('GET_LEGACY_CONTENT_BY_BASE_DOCUMENT_ID_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_LEGACY_CONTENT_BY_BASE_DOCUMENT_ID_ERROR', error);
    }
  },
  async getDocumentByPublicationId(
    store: Store<DocumentsState>,
    params: GetDocumentByPublicationIdRequestPayload
  ) {
    try {
      store.commit('GET_DOCUMENT_BY_PUBLICATION_ID_LOADING');

      const { data } = await documentsApi.getDocumentByPublicationId(params);

      const documentByPublicationId: DocumentDetail = data;

      store.commit(
        'GET_DOCUMENT_BY_PUBLICATION_ID_SUCCESS',
        documentByPublicationId
      );
    } catch (error) {
      store.commit('GET_DOCUMENT_BY_PUBLICATION_ID_ERROR', error);
    }
  },
  async searchDocument(
    store: Store<DocumentsState>,
    params: SearchDocumentPayload
  ) {
    try {
      store.commit('SEARCH_DOCUMENT_LOADING');

      const { data } = await documentsApi.searchDocument(params);
      const facets: Facet[] = [];
      const authorFacet: Facet = {
        name: FacetTypes.Author,
        keysAndCount: [
          {
            key: '',
            doc_count: 0
          }
        ]
      };
      const documentTypeFacet: Facet = {
        name: FacetTypes.DocumentType,
        keysAndCount: [
          {
            key: '',
            doc_count: 0
          }
        ]
      };
      const allNodesFacet: Facet = {
        name: FacetTypes.Nodes,
        keysAndCount: [
          {
            key: '',
            doc_count: 0
          }
        ]
      };
      const assignedUserFacet: Facet = {
        name: FacetTypes.AssignedUser,
        keysAndCount: [
          {
            key: '',
            doc_count: 0
          }
        ]
      };
      const authorFacetKeys = data.aggregations?.authors?.buckets;
      const documentTypeFacetKeys = data.aggregations?.documentType?.buckets;
      const primaryNodeFacetKeys = data.aggregations?.primaryNode?.buckets;
      const secondaryNodeFacetKeys = data.aggregations?.secondaryNodes?.buckets;
      const assignedUserFacetKeys = data.aggregations?.assignedUser?.buckets;

      const allNodeKeysWithDuplicate = primaryNodeFacetKeys.concat(
        secondaryNodeFacetKeys
      );
      let allNodeKeys: any[] = [];
      let duplicateFound = false;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < allNodeKeysWithDuplicate.length; i = i + 1) {
        duplicateFound = false;
        allNodeKeys = allNodeKeys.map((node: any) => {
          if (node && node.key === allNodeKeysWithDuplicate[i].key) {
            node.doc_count =
              node.doc_count + allNodeKeysWithDuplicate[i].doc_count;
            duplicateFound = true;
          }
          return node;
        });
        if (!duplicateFound) {
          allNodeKeys.push(allNodeKeysWithDuplicate[i]);
        }
      }
      if (allNodeKeys.length > 0) {
        allNodesFacet.keysAndCount = allNodeKeys;
        facets.push(allNodesFacet);
      }
      if (authorFacetKeys.length > 0) {
        authorFacet.keysAndCount = authorFacetKeys;
        facets.push(authorFacet);
      }
      if (documentTypeFacetKeys.length > 0) {
        documentTypeFacet.keysAndCount = documentTypeFacetKeys;
        facets.push(documentTypeFacet);
      }
      if (assignedUserFacetKeys.length > 0) {
        assignedUserFacet.keysAndCount = assignedUserFacetKeys;
        facets.push(assignedUserFacet);
      }

      const result: PaginatedApiResponse<DocumentIndexingPayload> = {
        total_count: data.hits?.total?.value,
        incomplete_results: false,
        items: data.hits?.hits.map((document: any) => document._source),
        facets
      };
      store.commit('SEARCH_DOCUMENT_SUCCESS', result);
      return result;
    } catch (error) {
      store.commit('SEARCH_DOCUMENT_ERROR', error);
    }
  },
  async resetSearchDocument(store: Store<DocumentsState>) {
    store.commit('SEARCH_DOCUMENT_RESET');
  },
  async searchRelatedDocuments(
    store: Store<DocumentsState>,
    params: SearchDocumentPayload
  ) {
    try {
      store.commit('SEARCH_RELATED_DOCUMENTS_LOADING');

      const { data } = await documentsApi.searchRelatedDocuments(params);
      const result: DocumentIndexingPayload[] = data.hits?.hits.map(
        (document: any) => document._source
      );

      store.commit('SEARCH_RELATED_DOCUMENTS_SUCCESS', result);
      return result;
    } catch (error) {
      store.commit('SEARCH_RELATED_DOCUMENTS_ERROR', error);
    }
  },
  async searchNewRelatedDocuments(
    store: Store<DocumentsState>,
    params: SearchDocumentPayload
  ) {
    try {
      store.commit('SEARCH_NEW_RELATED_DOCUMENTS_LOADING');

      const { data } = await documentsApi.searchNewRelatedDocuments(params);
      const result: DocumentIndexingPayload[] = data.hits?.hits.map(
        (document: any) => document._source
      );

      store.commit('SEARCH_NEW_RELATED_DOCUMENTS_SUCCESS', result);
      return result;
    } catch (error) {
      store.commit('SEARCH_NEW_RELATED_DOCUMENTS_ERROR', error);
    }
  },
  async searchEsForRpRealignment(
    store: Store<DocumentsState>,
    params: SearchDocumentPayload
  ) {
    try {
      store.commit('SEARCH_ES_FOR_REALIGNMENT_LOADING');

      const { data } = await documentsApi.searchEsForRpRealignment(params);
      const result: DocumentIndexingPayload[] = data.hits?.hits.map(
        (document: any) => document._source
      );

      store.commit('SEARCH_ES_FOR_REALIGNMENT_SUCCESS', result);
      return result;
    } catch (error) {
      store.commit('SEARCH_ES_FOR_REALIGNMENT_ERROR', error);
    }
  },
  async updatePublishStatus(
    store: Store<DocumentsState>,
    payload: PublishDocumentPayload
  ) {
    try {
      store.commit('UPDATE_PUBLISH_STATUS_LOADING');
      await documentsApi.updateDocumentPublishStatus(payload);
      store.commit('UPDATE_PUBLISH_STATUS_SUCCESS', payload);

      // retrieve all document detail again
      await store.dispatch('getDocumentDetail', payload.documentId);
    } catch (error) {
      store.commit('UPDATE_PUBLISH_STATUS_ERROR', error);
    }
  },
  async exportWkResource(
    store: Store<DocumentsState>,
    payload: ExportWkResourceRequestPayload
  ) {
    try {
      store.commit('EXPORT_WK_RESOURCE_LOADING');

      const response = await documentsApi.exportWkResource(payload);

      store.commit('EXPORT_WK_RESOURCE_SUCCESS');

      const wkExportId = response.data;

      return wkExportId;
    } catch (error) {
      store.commit('EXPORT_WK_RESOURCE_ERROR', error);
    }
  },

  async getWkExportStatus(store: Store<DocumentsState>, payload: number) {
    try {
      store.commit('GET_WK_EXPORT_STATUS_LOADING');

      const response = await documentsApi.getWkExportStatus(payload);

      store.commit('GET_WK_EXPORT_STATUS_SUCCESS');

      return response.data as WkExportStatus;
    } catch (error) {
      store.commit('GET_WK_EXPORT_STATUS_ERROR', error);
    }
  },

  async getWkExportDetails(
    store: Store<DocumentsState>,
    payload: FetchWkExportResourceDetailRequestPayload
  ) {
    try {
      store.commit('GET_WK_EXPORT_DETAILS_LOADING');
      const response = await documentsApi.getWkExportdetails(payload);

      store.commit('GET_WK_EXPORT_DETAILS_SUCCESS');

      return response.data as WkExportResourceDetailPayload;
    } catch (error) {
      store.commit('GET_WK_EXPORT_DETAILS_ERROR', error);
    }
  },

  async getAllWkExports(
    store: Store<DocumentsState>,
    params: { page: number; perPage: number }
  ) {
    try {
      store.commit('GET_ALL_WK_EXPORT_LOADING');

      const response = await documentsApi.getAllWkExports(params);

      store.commit('GET_ALL_WK_EXPORT_SUCCESS', response.data);
    } catch (error) {
      store.commit('GET_ALL_WK_EXPORT_ERROR', error);
    }
  },

  async getParticipantRoles(store: Store<DocumentsState>) {
    try {
      store.commit('PARTICIPANT_ROLES_LOADING');

      await documentsApi.getParticipantRoles();

      store.commit('PARTICIPANT_ROLES_SUCCESS');
    } catch (error) {
      store.commit('PARTICIPANT_ROLES_ERROR', error);
    }
  },

  async inviteDocumentUserAsParticipant(
    store: Store<DocumentsState>,
    payload: InviteDocumentParticipantsRequestPayload
  ) {
    try {
      store.commit('DOCUMENT_PARTICIPANT_INVITE_LOADING');

      const response = await documentsApi.inviteDocumentUserAsParticipant(
        payload
      );

      store.commit('DOCUMENT_PARTICIPANT_INVITE_SUCCESS', response.data);
    } catch (error) {
      store.commit('DOCUMENT_PARTICIPANT_INVITE_ERROR', error);
      throw error;
    }
  },

  async updateDocumentParticipant(
    store: Store<DocumentsState>,
    payload: InviteDocumentParticipantsRequestPayload
  ) {
    try {
      store.commit('UPDATE_DOCUMENT_PARTICIPANT_LOADING');

      const response = await documentsApi.updateDocumentParticipant(payload);

      store.commit('UPDATE_DOCUMENT_PARTICIPANT_SUCCESS', response.data);
    } catch (error) {
      store.commit('UPDATE_DOCUMENT_PARTICIPANT_ERROR', error);
      throw error;
    }
  },

  async deleteDocumentParticipant(
    store: Store<DocumentsState>,
    payload: InviteDocumentParticipantsRequestPayload
  ) {
    try {
      store.commit('DELETE_DOCUMENT_PARTICIPANT_LOADING');

      const response = await documentsApi.deleteDocumentParticipant(payload);

      store.commit('DELETE_DOCUMENT_PARTICIPANT_SUCCESS', response.data);
    } catch (error) {
      store.commit('DELETE_DOCUMENT_PARTICIPANT_ERROR', error);
      throw error;
    }
  },

  async searchKeywordTag(
    store: Store<DocumentsState>,
    payload: SearchKeywordPayload
  ) {
    try {
      store.commit('SEARCH_KEYWORD_TAG_LOADING');

      const response = await documentsApi.searchKeywordTag(payload);
      const data: Keyword[] = response.data.hits?.hits.map(
        (document: any) => document._source
      );
      store.commit('SEARCH_KEYWORD_TAG_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('SEARCH_KEYWORD_TAG_ERROR', error);
    }
  },

  // Get MESH tree from our database (csv based)
  async getMeshTree(store: Store<DocumentsState>) {
    try {
      store.commit('GET_MESH_TREE_LOADING');

      const response = await documentsApi.getMeshTree();
      store.commit('GET_MESH_TREE_SUCCESS', response.data);

      return response.data;
    } catch (error) {
      store.commit('GET_MESH_TREE_ERROR', error);
    }
  },

  // Get MESH tag by using RDF MESH API
  async getRdfMeshTags(store: Store<DocumentsState>, payload: string) {
    try {
      store.commit('GET_RDF_MESH_TAGS_LOADING');

      const response = await documentsApi.getRdfMeshTags(payload);
      const meshData: MeshDto[] = response.data;

      store.commit('GET_RDF_MESH_TAGS_SUCCESS', meshData);

      return response.data;
    } catch (error) {
      store.commit('GET_RDF_MESH_TAGS_ERROR', error);
    }
  },

  async getOhsAssets(store: Store<DocumentsState>) {
    try {
      store.commit('GET_OHS_ASSETS_LOADING');

      const response = await documentsApi.getOhsAssets();
      store.commit('GET_OHS_ASSETS_SUCCESS', response.data);

      return response.data;
    } catch (error) {
      store.commit('GET_OHS_ASSETS_ERROR', error);
    }
  },

  async updateDocAssignee(
    store: Store<DocumentsState>,
    payload: UpdateDocAssigneeRequestPayload
  ) {
    try {
      store.commit('UPDATE_DOC_ASSIGNEE_LOADING');

      const { data } = await documentsApi.updateDocAssignee(payload);

      store.commit(
        'UPDATE_DOC_ASSIGNEE_SUCCESS',
        data as UpdateDocAssigneeResponsePayload
      );
      return data;
    } catch (error) {
      store.commit('UPDATE_DOC_ASSIGNEE_ERROR', error);
    }
  },
  async getDocumentParticipants(
    store: Store<DocumentsState>,
    payload: GetDocumentParticipantsRequestPayload
  ) {
    try {
      store.commit('GET_DOCUMENT_PARTICIPANTS_LOADING');

      const response = await documentsApi.getDocumentParticipants(payload);

      store.commit('GET_DOCUMENT_PARTICIPANTS_SUCCESS', response.data);
    } catch (error) {
      store.commit('GET_DOCUMENT_PARTICIPANTS_ERROR', error);
    }
  },
  async getMyAssignedDocuments(
    store: Store<DocumentsState>,
    payload: GetMyAssignedDocumentsRequestPayload
  ) {
    try {
      store.commit('GET_MY_ASSIGNED_DOCS_LOADING');
      const { data } = await documentsApi.getMyAssignedDocuments(payload);
      const result: PaginatedApiResponse<DocumentIndexingPayload> = {
        total_count: data.hits?.total?.value,
        incomplete_results: false,
        items: data.hits?.hits.map((document: any) => document._source)
      };
      store.commit('GET_MY_ASSIGNED_DOCS_SUCCESS', result);
      return result;
    } catch (error) {
      store.commit('GET_MY_ASSIGNED_DOCS_ERROR', error);
    }
  },
  async getUserDocuments(
    store: Store<DocumentsState>,
    payload: GetUserDocumentsRequestPayload
  ) {
    try {
      store.commit('GET_ALL_DOCUMENTS_LOADING');
      const { data } = await documentsApi.getUserDocuments(payload);
      const result: PaginatedApiResponse<DocumentIndexingPayload> = {
        total_count: data.hits?.total?.value,
        incomplete_results: false,
        items: data.hits?.hits.map((document: any) => document._source)
      };
      store.commit('GET_ALL_DOCUMENTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_ALL_DOCUMENTS_ERROR', error);
    }
  },
  async getUserCreatedDocuments(
    store: Store<DocumentsState>,
    payload: GetUserDocumentsRequestPayload
  ) {
    try {
      store.commit('GET_CREATED_DOCUMENTS_LOADING');
      const { data } = await documentsApi.getUserDocuments(payload);
      const result: PaginatedApiResponse<DocumentIndexingPayload> = {
        total_count: data.hits?.total?.value,
        incomplete_results: false,
        items: data.hits?.hits.map((document: any) => document._source)
      };
      store.commit('GET_CREATED_DOCUMENTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_CREATED_DOCUMENTS_ERROR', error);
    }
  },
  async getUserAssignedDocuments(
    store: Store<DocumentsState>,
    payload: GetUserDocumentsRequestPayload
  ) {
    try {
      store.commit('GET_ASSIGNED_DOCUMENTS_LOADING');
      const { data } = await documentsApi.getUserDocuments(payload);
      const result: PaginatedApiResponse<DocumentIndexingPayload> = {
        total_count: data.hits?.total?.value,
        incomplete_results: false,
        items: data.hits?.hits.map((document: any) => document._source)
      };
      store.commit('GET_ASSIGNED_DOCUMENTS_SUCCESS', result);
    } catch (error) {
      store.commit('GET_ASSIGNED_DOCUMENTS_ERROR', error);
    }
  },
  async getAlignedEsByEsId(store: Store<DocumentsState>, esId: number) {
    try {
      store.commit('GET_ALIGNED_ES_BY_ES_ID_LOADING');
      const { data } = await documentsApi.getAlignedEsByEsId(esId);
      const result: DocumentIndexingPayload = data.hits?.hits
        .map((document: any) => document._source)
        .shift();
      store.commit('GET_ALIGNED_ES_BY_ES_ID_SUCCESS', result);
      return result;
    } catch (error) {
      store.commit('GET_ALIGNED_ES_BY_ES_ID_ERROR', error);
    }
  }
};
