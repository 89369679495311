import { editorsState } from './editors.state';
import { editorsActions } from './editors.actions';
import { editorsMutations } from './editors.mutation';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: editorsState,
  actions: editorsActions,
  mutations: editorsMutations
} as ModuleTree<RootState>;
