







































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class PendingDocumentvalidationErrorModal extends Vue {
  @Prop({ type: String, default: '' }) public tdrError!: string;
}
