var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {}, _obj[_vm.$style.item] = true, _obj[_vm.$style.item__editable] = _vm.editable, _obj ),attrs:{"disabled":_vm.editorIsDisabled}},[_c('div',{class:_vm.$style.itemContent},[_c('div',{class:( _obj$1 = {}, _obj$1[_vm.$style.itemDragIcon] = true, _obj$1 )},[_c('img',{attrs:{"src":require("@/assets/images/editor/ic-drag.png")}})]),_c('div',{class:[
        _vm.isVersionMode ? _vm.$style.isVersionMode : '',
        _vm.$style.itemContentContainer
      ]},[_c('div',{staticClass:"position-relative",class:_vm.isInvalidContent
            ? _vm.$style.invalidContent
            : _vm.isNewContent
            ? _vm.$style.newContent
            : ''},[_c('div',{staticClass:"is-flex",class:_vm.$style.quillEditorWrapper},[_c('div',{staticClass:"ql-container ql-snow"},[_c('div',{staticClass:"ql-editor",class:_vm.$style.itemContentBullet},[(_vm.listType === 'ul')?_c('ul',{style:(("counter-reset: mycounter " + _vm.index))},[_c('li')]):(_vm.listType === 'ol')?_c('ol',{style:(("counter-reset: mycounter " + _vm.index))},[_c('li')]):_vm._e()])]),_c('div',{staticClass:"quill-editor"},[_c('div',{staticClass:"ql-container ql-snow ql-disabled"},[_c('div',{staticClass:"ql-editor",attrs:{"contenteditable":"false"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.content)}})])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editable && _vm.showEdit && !_vm.isStaging),expression:"editable && showEdit && !isStaging"}],class:_vm.$style.itemEditIcon,attrs:{"data-print-hidden":""},on:{"click":function($event){return _vm.$emit('edit')}}},[_c('b-icon',{attrs:{"icon":"pencil","size":"is-small"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isStaging),expression:"!isStaging"}],class:_vm.$style.itemCloseIcon,attrs:{"data-print-hidden":""},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('b-icon',{attrs:{"icon":"close","size":"is-small"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }