






import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { useAction, useAuthState, useUsersState } from '@/utils/store.util';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  AcceptSystemInvitationRequestPayload,
  VerifyInvitationTokenStatus,
  VerifyUserInvitePayload
} from '@/store/modules/users/types/users.types';
import retry from 'async-retry';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { getLocationFromCookie } from '@/utils/localstorage.util';
import { redirectToJaasLogin } from '../../utils/login.util';

@Component({})
export default class AcceptSystemInvitationToken extends Vue {
  get acceptInvitation(): (
    payload: AcceptSystemInvitationRequestPayload
  ) => void {
    return useAction.call(this, 'users/acceptUserInvite');
  }
  public awaitingApiResponse: boolean = true;

  get verifyToken(): (payload: VerifyUserInvitePayload) => void {
    return useAction.call(this, 'users/verifyUserInvite');
  }

  get isLoading() {
    return this.awaitingApiResponse;
  }

  get acceptInvitationSuccess() {
    return useUsersState.call(this).apiState.acceptUserInvite.success;
  }

  get acceptInvitationError() {
    return useUsersState.call(this).apiState.acceptUserInvite.error;
  }

  get acceptedInvitedProject() {
    return useUsersState.call(this).acceptUserInvite;
  }

  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  get verifyInviteTokenSuccess() {
    return useUsersState.call(this).apiState.verifyUserInvite.success;
  }

  get verifyInviteTokenError() {
    return useUsersState.call(this).apiState.verifyUserInvite.error;
  }

  get verifyInviteTokenState() {
    return useUsersState.call(this).verifyUserInvite;
  }

  get verifyTokenPayload(): VerifyUserInvitePayload {
    return {
      email: this.$route.query.email.toString(),
      token: this.$route.query.token.toString()
    };
  }

  get invitationToken(): string {
    return this.$route.query.token.toString();
  }

  get loginUrl(): string {
    return this.$route.query['login-url'].toString();
  }

  public async mounted() {
    setTimeout(async () => {
      if (this.isLoggedIn) {
        await this.acceptInvitationFlow();
        await getLocationFromCookie();
      } else {
        this.verifyToken(this.verifyTokenPayload);
      }
    }, 3000);
  }

  public async acceptInvitationFlow() {
    this.acceptInvitation({ token: this.invitationToken });
  }

  @Watch('verifyInviteTokenSuccess')
  @isDifferent
  @isTruthy
  public async verifyTokenSuccess() {
    if (
      this.verifyInviteTokenState!.status ===
      VerifyInvitationTokenStatus.SUCCESS
    ) {
      redirectToJaasLogin({
        destination: this.$route.fullPath,
        loginUrl: this.loginUrl
      });
    }
  }

  @Watch('verifyInviteTokenError')
  @isDifferent
  @isTruthy
  public verifyTokenError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `${_get(error.response, 'data.message')}`,
      duration: 5000
    });
    this.$router.replace('/');
  }

  @Watch('acceptInvitationSuccess')
  @isDifferent
  @isTruthy
  public onSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Invitation Accepted Successfully`
    });
    this.$router.replace('/');
  }

  @Watch('acceptInvitationError')
  @isDifferent
  @isTruthy
  public onError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `${_get(error.response, 'data.message')}`
    });
    this.$router.replace('/');
  }
}
