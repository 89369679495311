























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { StringInputOption } from '@/jbi-shared/types/form.types';

@Component({})
export default class DropdownMultiselect extends Vue {
  @Prop(Array) public value!: StringInputOption[];
  @Prop(Array) public options!: StringInputOption[];
  @Prop(Array) public fixedOptions!: StringInputOption[];
  @Prop(String) public allOptionsText!: string;
  @Prop(String) public btnTxt!: string;
  @Prop(String) public position!:
    | 'is-top-right'
    | 'is-top-left'
    | 'is-bottom-left';
  @Prop(Array) public triggers!: string[];

  get selectedIds() {
    return this.value.map((o) => o.id!);
  }

  set selectedIds(ids: string[]) {
    this.$emit(
      'input',
      this.options.filter((o) => ids.includes(o.id!))
    );
  }

  get allSelected() {
    return this.value.length === this.options.length;
  }

  set allSelected(value: boolean) {
    if (value) {
      this.$emit('input', this.options);
    } else {
      this.$emit('input', []);
    }
  }

  public toggleOption(id: string) {
    if (this.isSelected(id)) {
      this.selectedIds = this.selectedIds.filter((sId) => sId !== id);
    } else {
      this.selectedIds = [...new Set([...this.selectedIds, id])];
    }
  }

  public isSelected(id: string) {
    return this.selectedIds.find((sId) => sId === id);
  }

  public isFixed(id: string) {
    return !!this.fixedOptions?.find((o) => o.id === id);
  }
}
