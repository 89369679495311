

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import { differenceBy } from 'lodash';

const DEFAULT_LISTING_SIZE = 5;

@Component({})
export default class SearchFilterCheckboxes extends Vue {
  @Prop(String) public label!: string;
  @Prop(Array) public value!: string[] | number[];
}
