





















































import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { Component, Prop } from 'vue-property-decorator';
import BaseTable from '@/components/base/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import SortableTableHeader from '@/components/base/SortableTableHeader.vue';
import { State } from 'vuex-class';
import { ResearchNodeEntity } from '@/store/modules/documents/types/documents.types';
import { RootState } from '@/store/store';

@Component({
  components: {
    BaseTable,
    BaseLoading,
    BaseModal,
    BasePagination,
    SortableTableHeader
  }
})
export default class ResearchNodeList extends mixins(PaginationMixin) {
  @Prop() public items!: ResearchNodeEntity[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';

  @State(
    ({ projects }: RootState) => projects.apiState.getResearchNodes.success
  )
  public getResearchNodeListingSuccess!: boolean;

  public onClickHeaderColumn(columnName: string) {
    if (this.sortCriteria === columnName) {
      return this.$emit('sort', {
        sortCriteria: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortCriteria: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortCriteria === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }
}
