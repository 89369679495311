import {
  UserInvitationPayload,
  UsersState,
  SingleUserSearchResult,
  VerifyUserInvitePayload
} from './types/users.types';
import { Store } from 'vuex';
import { usersApi } from '@/api/users.api';
import { GetUserPayload } from '@/store/modules/users/types/users.types';
import { get as _get } from 'lodash';
import { OicPayload } from '@/jbi-shared/types/jaas-oauth2.types';

export const usersActions = {
  async inviteNewUser(
    store: Store<UsersState>,
    payload: UserInvitationPayload
  ) {
    try {
      store.commit('INVITE_NEW_USER_LOADING');

      const { data } = await usersApi.inviteNewUser(payload);

      store.commit('INVITE_NEW_USER_SUCCESS', data);
    } catch (error) {
      store.commit('INVITE_NEW_USER_ERROR', error);
    }
  },
  async getUsers(store: Store<UsersState>, params: GetUserPayload) {
    try {
      store.commit('GET_USERS_LOADING');
      const { data } = await usersApi.getUsers(params);
      store.commit('GET_USERS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USERS_ERROR', error);
    }
  },
  async getUserInvitations(store: Store<UsersState>, email: string) {
    try {
      store.commit('GET_USER_INVITATIONS_LOADING');
      const { data } = await usersApi.getUserInvitations(email);
      store.commit('GET_USER_INVITATIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USER_INVITATIONS_ERROR', error);
    }
  },
  async resendInvitation(store: Store<UsersState>, id: number) {
    try {
      store.commit('RESEND_USER_INVITATION_LOADING');
      await usersApi.resendUserInvitations(id);
      store.commit('RESEND_USER_INVITATION_SUCCESS');
    } catch (error) {
      store.commit('RESEND_USER_INVITATION_ERROR', error);
    }
  },
  async removeInvitation(store: Store<UsersState>, id: number) {
    try {
      store.commit('REMOVE_USERS_INVITATION_LOADING');
      await usersApi.removeUserInvitation(id);
      store.commit('REMOVE_USERS_INVITATION_SUCCESS');
    } catch (error) {
      store.commit('REMOVE_USERS_INVITATION_ERROR', error);
    }
  },
  async searchUsersByText(store: Store<UsersState>, text: string) {
    try {
      store.commit('SEARCH_USER_BY_TEXT_LOADING');

      const { data } = await usersApi.searchUsersByText(text);
      const items: SingleUserSearchResult[] = _get(data, 'hits.hits').map(
        (d: any) => d._source
      );

      store.commit('SEARCH_USER_BY_TEXT_SUCCESS', items);
      return items;
    } catch (error) {
      store.commit('SEARCH_USER_BY_TEXT_ERROR', error);
    }
  },
  async getMyRoles(store: Store<UsersState>) {
    try {
      store.commit('GET_MY_ROLES_LOADING');

      const data: string[] = await usersApi
        .getMyRoles()
        .then((res) => res.data);

      store.commit('GET_MY_ROLES_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('GET_MY_ROLES_ERROR', error);
    }
  },
  async getUserOicPayload(store: Store<UsersState>) {
    try {
      store.commit('GET_OIC_PAYLOAD_LOADING');

      const data: {
        id: number;
        oicPayload: OicPayload;
      } = await usersApi.getUserOicPayload().then((res) => res.data);

      store.commit('GET_OIC_PAYLOAD_SUCCESS', data?.oicPayload);
      return data;
    } catch (error) {
      store.commit('GET_OIC_PAYLOAD_ERROR', error);
    }
  },
  async resetMyRoles(store: Store<UsersState>) {
    store.commit('RESET_MY_ROLES');
  },
  async updateStatus(store: Store<UsersState>, id: number) {
    try {
      store.commit('UPDATE_STATUS_LOADING');
      await usersApi.updateStatus(id);
      store.commit('UPDATE_STATUS_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_STATUS_ERROR', error);
    }
  },
  async acceptUserInvite(store: Store<UsersState>, token: string) {
    try {
      store.commit('ACCEPT_USER_INVITE_LOADING');

      const data = await usersApi.acceptUserInvite(token);

      store.commit('ACCEPT_USER_INVITE_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('ACCEPT_USER_INVITE_ERROR', error);
    }
  },
  async verifyUserInvite(
    store: Store<UsersState>,
    payload: VerifyUserInvitePayload
  ) {
    try {
      store.commit('VERIFY_USER_INVITE_LOADING');

      const data = await usersApi.verifyUserInvite(payload);

      store.commit('VERIFY_USER_INVITE_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('VERIFY_USER_INVITE_ERROR', error);
    }
  }
};
