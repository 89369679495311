import { render, staticRenderFns } from "./AssignEditorButton.vue?vue&type=template&id=668bc1b1&scoped=true&"
import script from "./AssignEditorButton.vue?vue&type=script&lang=ts&"
export * from "./AssignEditorButton.vue?vue&type=script&lang=ts&"
import style0 from "./AssignEditorButton.vue?vue&type=style&index=0&id=668bc1b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668bc1b1",
  null
  
)

export default component.exports