
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { StringInputOption } from '@/jbi-shared/types/form.types';

type SelectedValue = string | number | null;

@Component({})
export default class BorderlessDropdown extends Vue {
  @Prop(String) public position!: string;
  @Prop(Array) public options!: StringInputOption[];
  @Prop([String, Number]) public value!: SelectedValue;

  get selectedName(): string {
    const option = this.options.find((o) => o.id === this.value);
    return option ? option.name! : 'Select an Option';
  }

  get selected(): SelectedValue {
    return this.value;
  }

  set selected(value: SelectedValue) {
    this.$emit('input', value);
  }
}
