import Vue from 'vue';
import Component from 'vue-class-component';
import { get as _get, throttle } from 'lodash';

@Component({})
export class SectionValueEditorToolbarHandlingMixin extends Vue {
  public toolbarSticky = false;
  public throttledHandleScrollAndStickness: any = null;

  // add/remove box shadow when it's sticky/non-sticky
  public handleScrollAndStickness() {
    const editorToolbar = _get(this, '$refs.editorToolbar.$el');
    const editor = _get(this, '$refs.editor.$el');
    if (!editorToolbar || !editor) {
      return;
    }
    const rect1 = editorToolbar.getBoundingClientRect();
    const rect2 = editor.getBoundingClientRect();
    const overlap = rect1.top > rect2.top;
    this.toolbarSticky = overlap;
  }

  public mounted() {
    const scrolledElem = document.getElementById('editor-container');
    const editorToolbar: Vue['$el'] = _get(this, '$refs.editorToolbar.$el');
    const editor = _get(this, '$refs.editor.$el');
    this.throttledHandleScrollAndStickness = throttle(
      this.handleScrollAndStickness,
      100
    );
    this.throttledHandleScrollAndStickness = this.throttledHandleScrollAndStickness.bind(
      this
    );
    if (scrolledElem && editorToolbar && editor) {
      scrolledElem.addEventListener(
        'scroll',
        this.throttledHandleScrollAndStickness
      );
    }
    // fix chrome jumping bug, based on https://stackoverflow.com/a/45823484/5599288
    if (editorToolbar) {
      editorToolbar.addEventListener('mousedown', (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
    }
  }

  public beforeDestroy() {
    const scrolledElem = document.getElementById('editor-container');
    if (scrolledElem) {
      scrolledElem.removeEventListener(
        'scroll',
        this.throttledHandleScrollAndStickness
      );
    }
  }

  public updated() {
    this.throttledHandleScrollAndStickness();
  }
}
