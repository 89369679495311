













































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import { RootState } from '@/store/store';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import InviteUserForm, {
  InviteUserFormValues
} from '@/views/AdminUserManagement/components/InviteUserForm.vue';
import { UserInvitationPayload } from '@/store/modules/users/types/users.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {
    BaseModal,
    InviteUserForm,
    ValidationObserver
  }
})
export default class InviteUserButton extends Vue {
  @Action('users/inviteNewUser')
  public inviteNewUser!: (payload: UserInvitationPayload) => void;

  @State((state: RootState) => state.users.apiState.inviteNewUser.loading)
  public inviteNewUserLoading!: boolean;

  public handleInviteUser(values: InviteUserFormValues) {
    const { email, username, firstName, lastName } = values;

    this.inviteNewUser({
      email,
      username,
      firstName,
      lastName
    });
  }
}
