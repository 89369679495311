// Deprecated
export enum CplusDocumentStatus {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived',
  Withdrawn = 'Withdrawn',
}

// Front end refer this as document status
export enum RevisionPublicationStatus {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived',
  Withdrawn = 'Withdrawn',
  NotPublished = 'NotPublished',
  // For Legacy Document after being migrated
  Migrated = 'Migrated',
}
