












































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { get as _get, isEqual as _isEqual } from 'lodash';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { DocumentFormMixin } from '@/utils/documentForm.mixin';
import GenericFileUploader, {
  UploadFileFormat
} from '@/components/form/GenericFileUploader.vue';

export interface UploadTdrFormValues {
  tdrStorageUri: string;
}

const initialFormValue: UploadTdrFormValues = {
  tdrStorageUri: ''
};

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseMultiSelect,
    GenericFileUploader
  }
})
export default class UploadTdrForm extends mixins(DocumentFormMixin) {
  @Prop(String) public modalTitle!: string;

  public form: UploadTdrFormValues = {
    ...initialFormValue
  };

  public TdrFormats: UploadFileFormat[] = [
    {
      fileExtension: '.docx',
      contentType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
  ];

  public setUploadedTdrStorageUri(uri: string) {
    this.form.tdrStorageUri = uri;
  }

  public handleSubmit() {
    this.$emit('update:tdr', this.form.tdrStorageUri);
    this.$emit('close');
  }
}
