









































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import AssignedWorkItem from './AssignedWorkItem.vue';
import DashboardListingHeader from '@/components/dashboard/DashboardListingHeader.vue';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin';
import { Action } from 'vuex-class';
import {
  GetMyAssignedDocumentsRequestPayload,
  GetMyAssignedDocumentsResponsePayload
} from '../../../store/modules/documents/types/documents.types';
import { RootState } from '../../../store/store';
import { GetUIStateMachineParentComponentMixin } from '../../../utils/ui-state-machine-parent.mixin';
import { get as _get } from 'lodash';
import BaseLoading from '@/components/base/BaseLoading.vue';

@Component({
  components: {
    AssignedWorkItem,
    DashboardListingHeader,
    BaseLoading
  }
})
export default class AssignedWorks extends mixins(ScreenSizeMixin) {
  public showAll: boolean = false;

  @Action('documents/getMyAssignedDocuments')
  public getMyAssignedDocuments!: (
    data: GetMyAssignedDocumentsRequestPayload
  ) => GetMyAssignedDocumentsResponsePayload;

  get getMyAssignedDocumentsLoading() {
    return (this.$store.state as RootState).documents.apiState
      .getMyAssignedDocuments.loading;
  }

  get myAssignedDocuments() {
    return (
      _get(
        (this.$store.state as RootState).documents.myAssignedDocuments,
        'items'
      ) || []
    );
  }

  get displayedAssignedDocs() {
    if (this.showAll) {
      return this.myAssignedDocuments || [];
    }
    return this.myAssignedDocuments.slice();
  }

  get emptyText() {
    return `You don't have any assigned work yet.`;
  }

  public created() {
    this.getMyAssignedDocuments({});
  }
}
