








import { Prop, Vue, Component } from 'vue-property-decorator';
import CreateProjectButton from '@/components/form/CreateProjectButton.vue';
import RecentDocumentsItemContainer from './RecentDocumentsItemContainer.vue';

@Component({
  components: { CreateProjectButton, RecentDocumentsItemContainer }
})
export default class RecentProjectsEmptyItem extends Vue {
  @Prop(String) public text!: string;
  @Prop(Boolean) public withCreateProject!: boolean;
}
