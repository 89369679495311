

























import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({
  components: {}
})
export default class MigrationSuccessModal extends Vue {
  @Prop(String) public modalTitle!: string;
  @Prop(String) public modalBody!: string;
  @Prop(String) public newDocId!: string;
  @Prop(Object) public route!: Route;
}
