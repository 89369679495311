


















import { User } from '@/store/modules/documents/types/documents.types';
import { getInitialFromName } from '@/utils/project.util';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CollaboratorsThumbnails extends Vue {
  @Prop({ type: Object, required: true })
  public documentOwner!: User;

  @Prop({ type: Array, required: true })
  public collaborators!: User[];

  get documentOwnerName() {
    return `${this.documentOwner?.oicPayload?.name} ${this.documentOwner?.oicPayload?.family_name}`;
  }

  get collaboratorsName() {
    return this.collaborators.map((u: User) => {
      return `${u?.oicPayload?.name} ${u?.oicPayload?.family_name}`;
    });
  }

  get getInitialFromName() {
    return getInitialFromName;
  }
}
