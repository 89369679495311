





























import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class MissingContractWarningModal extends Vue {}
