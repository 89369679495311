var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative section-title-editor",class:[
    _vm.$style.container,
    _vm.isVersionMode && _vm.$style.isVersionMode,
    _vm.isPublicMode && _vm.$style.isPublicMode
  ]},[_c('SectionEditorHeader'),_c('div',{on:{"click":function($event){return _vm.$emit('focus')}}},[_c('textarea-autosize',{ref:"sectionTitle",class:[
        _vm.$style.sectionTitleEditor,
        _vm.isFocused && !_vm.disabled ? _vm.$style.sectionTitleEditor__editing : '',
        _vm.type === 'doc-title' ? _vm.$style.sectionTitleEditor__docTitle : '',
        _vm.hasError ? _vm.$style.sectionTitleEditor_error : ''
      ],attrs:{"disabled":_vm.disabled,"placeholder":"Insert Title Here ...","rows":"1","data-test":_vm.type},on:{"focus":function($event){return _vm.$emit('focus')},"dragover":function($event){$event.preventDefault();}},nativeOn:{"blur":function($event){return _vm.$emit('blur')},"drop":function($event){$event.preventDefault();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"paste":function($event){return _vm.sanitizeOnPaste($event)}},model:{value:(_vm.titleBeingEdited),callback:function ($$v) {_vm.titleBeingEdited=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"titleBeingEdited"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }