

































































import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import Container from '@/components/Container.vue';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import PaginatedSearchResultList from '@/components/listings/search/PaginatedSearchResultList.vue';
import { SearchResultListItemParam } from '@/components/listings/search/SearchResultListItem.vue';
import {
  SearchCheckboxOptions,
  StringInputOption
} from '@/jbi-shared/types/form.types';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import {
  DocumentExportType,
  ExportDocumentRequestPayload,
  LegacySearchFilters,
  SearchDocumentPayload,
  SearchFacetsParams,
  SearchResultExportType
} from '@/store/modules/documents/types/documents.types';
import { FacetTypes, PaginatedApiResponse } from '@/store/types/general.types';
import { getCollaborators } from '@/utils/document.util';
import { handleCsvExporting } from '@/utils/export-csv.util';
import { handleDocumentExporting } from '@/utils/export-docx.util';
import {
  useAction,
  useDocumentsState,
  useWebsocketState
} from '@/utils/store.util';
import ExportBtn from '@/views/Search/components/ExportBtn.vue';
import SearchFacets from '@/views/Search/components/SearchFacets.vue';
import SearchResultHeader from '@/views/Search/components/SearchResultHeader.vue';
import { Job, JobId } from 'bull';
import dayjs from 'dayjs';
import {
  cloneDeep as _cloneDeep,
  debounce,
  findLast,
  get as _get,
  isEqual,
  isEmpty
} from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ResearchNode } from '@/store/modules/documents/types/documents.types';
import { DATE_FORMAT } from '@/utils/date.util';
import SearchBox from './components/SearchBox.vue';
import OptionsMixin, {
  legacyFilterOptions
} from '../../components/form/options.mixin';
import {
  searchConditions,
  SearchCriterion,
  searchProps
} from '@/store/types/search.types';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import { startCase } from 'lodash';

interface ItemInExport {
  uniquePublicationId: string;
  jobId: JobId;
}

interface SearchParams {
  text: string;
  nodes: StringInputOption[] | any;
  authors: string[];
  publicationYearFrom: string;
  publicationYearTo: string;
  assignedUsers: string[];
  documentTypes: StringInputOption[] | any;
  documentId?: string;
  revisionStatuses: StringInputOption[] | any;
  legacyFilter: StringInputOption;
  meshTags: string[];
  criteria?: SearchCriterion[];
  facets?: SearchFacetsParams;
  hasCriteria?: boolean;
}

export interface Facets {
  facetDocumentTypes: SearchCheckboxOptions[];
  facetAuthors: SearchCheckboxOptions[];
  facetNodes: SearchCheckboxOptions[];
  facetAssignedUsers: SearchCheckboxOptions[];
}

interface FacetsQueryParam {
  facetDocumentTypes: string[];
  facetAuthors: string[];
  facetNodes: string[];
  facetAssignedUsers: string[];
}

@Component({
  components: {
    SearchBox,
    BasePaginatorHoc,
    PaginatedSearchResultList,
    SearchResultHeader,
    SearchFacets,
    DashboardHeader,
    Container,
    ExportBtn
  }
})
export default class SearchPage extends mixins(OptionsMixin) {
  public $refs!: {
    SearchResultHeader: Vue;
  };

  public itemsInExport: ItemInExport[] = [];
  public draftSearchParams: SearchParams = _cloneDeep({
    ...this.getInitialSearchParams()
  });
  public isAdvance = false;
  public perPage = 50;
  public page = 1;
  public facetKey = 0;
  public facetQueryParam: FacetsQueryParam = {
    facetDocumentTypes: [],
    facetAuthors: [],
    facetNodes: [],
    facetAssignedUsers: []
  };

  @Action('documents/exportDocument')
  public exportDocument!: (p: ExportDocumentRequestPayload) => Promise<Job>;

  @Action('documents/exportSearchResult')
  public exportSearchResult!: (p: SearchDocumentPayload) => Promise<Job>;
  public query: any;

  public getInitialSearchParams(): SearchParams {
    return {
      text: '',
      nodes: [],
      authors: [],
      publicationYearFrom: '',
      publicationYearTo: '',
      assignedUsers: [],
      documentTypes: [],
      hasCriteria: false,
      revisionStatuses: [
        {
          id: RevisionPublicationStatus.Published,
          name: RevisionPublicationStatus.Published
        }
      ],
      documentId: '',
      legacyFilter: legacyFilterOptions[1],
      meshTags: [],
      criteria: [
        {
          props: [...searchProps],
          condition: searchConditions[0],
          keyword: ''
        }
      ],
      facets: {
        documentTypes: [],
        authors: [],
        nodes: [],
        assignedUsers: []
      }
    };
  }

  get searchDocument(): (
    d: SearchDocumentPayload
  ) => Promise<PaginatedApiResponse<DocumentIndexingPayload>> {
    const func = useAction.call(this, 'documents/searchDocument');
    return debounce(func, 300);
  }

  get getMeshTree(): () => Promise<any> {
    return useAction.call(this, 'documents/getMeshTree');
  }

  get getAllResearchNodes() {
    return useAction.call(this, 'projects/getAllResearchNodes');
  }

  get resetSearchDocument() {
    return useAction.call(this, 'documents/resetSearchDocument');
  }

  get searchUsersByText() {
    return useAction.call(this, 'users/searchUsersByText');
  }

  get searchDocumentLoading(): boolean {
    return useDocumentsState.call(this).apiState.searchDocument.loading;
  }

  get documentSearchResult() {
    return useDocumentsState.call(this).documentSearchResult;
  }

  get formattedDocumentItems(): SearchResultListItemParam[] {
    if (!this.documentSearchResult) {
      return [];
    }
    return this.documentSearchResult!.items.map((item) => {
      const to: SearchResultListItemParam['to'] = item.isLegacy
        ? {
            name: 'preview-legacy-documents',
            params: {
              baseDocumentId: String(item.legacyBaseDocumentId)
            }
          }
        : {
            name: 'document-versions',
            params: {
              versionId: String(item.versionId),
              documentId: String(item.documentId),
              projectId: String(item.projectId)
            }
          };
      const date = (() => {
        if (item.searchDate) {
          return dayjs(item.searchDate).format(DATE_FORMAT);
        }
        return dayjs(item.publishedAt).format(DATE_FORMAT);
      })();
      return {
        key: item.revisionId,
        title: item.title || '(Title Not Provided)',
        body: item.body,
        documentType: item.documentType,
        date,
        primaryResearchNode: item.primaryNode,
        secondaryResearchNodes: item.secondaryNodes,
        collaborators: getCollaborators(item),
        owner: item.authors!.length > 0 ? item.authors!.toString() : null,
        uniquePublicationId: item.uniquePublicationId,
        to,
        isExporting: this.isDocExporting(item.uniquePublicationId),
        authors: item.authors,
        assigneeFullName: item.assigneeFullName,
        plainTags: item.plainTags,
        meshTags: item.tags,
        isLegacy: item.isLegacy,
        legacyBaseDocumentId: item.legacyBaseDocumentId,
        isLatestLegacyVersion: item.isLatestLegacyVersion,
        sourceLegacyDocumentLatestBaseDocumentId:
          item.sourceLegacyDocumentLatestBaseDocumentId,
        sourceLegacyDocumentGlobalBaseDocumentId:
          item.sourceLegacyDocumentGlobalBaseDocumentId,
        migratedFromLegacyBaseDocumentId: item.migratedFromLegacyBaseDocumentId,
        migratedToNewDocumentId: item.migratedToNewDocumentId,
        resourceUrl: item.resourceUrl,
        publishedAt: item.publishedAt,
        archivedAt: item.archivedAt,
        publicationStatus: item.publicationStatus
      };
    });
  }

  /**
   * This function is used to get facet from search param and pass them to SearchFacets
   * Use case: Search and filter facets then refresh page (with current facets criteria in search url)
   * Facets checked box should be enabled to match to search param
   */
  get selectedFacets(): Facets {
    const selectedFacets: Facets = {
      facetDocumentTypes: [],
      facetAuthors: [],
      facetNodes: [],
      facetAssignedUsers: []
    };

    if (!this.documentSearchResult) {
      return selectedFacets;
    }

    // Get selected facet documentTypes
    this.draftSearchParams.facets!.documentTypes.forEach(
      (facetDocumentType) => {
        const documentType = this.documentTypes.find(
          (documentType) => documentType.id === facetDocumentType.id
        );
        if (documentType) {
          selectedFacets.facetDocumentTypes.push(documentType);
        }
      }
    );

    // Get selected facet author
    this.draftSearchParams.facets!.authors.forEach((facetAuthor) => {
      const author = this.documentAuthors.find(
        (author) => author.id === facetAuthor.id
      );
      if (author) {
        selectedFacets.facetAuthors.push({
          ...author,
          name: startCase(author.name || '')
        });
      }
    });

    // Get selected facet nodes
    this.draftSearchParams.facets!.nodes.forEach((facetNode) => {
      const node = this.nodes.find((node) => node.id === facetNode.id);
      if (node) {
        selectedFacets.facetNodes.push(node);
      }
    });

    // Get selected facet assignedUsers
    this.draftSearchParams.facets!.assignedUsers.forEach(
      (facetAssignedUser) => {
        const assignedUser = this.assignedUsers.find(
          (assignedUser) => assignedUser.id === facetAssignedUser.id
        );
        if (assignedUser) {
          selectedFacets.facetAssignedUsers.push(assignedUser);
        }
      }
    );

    return selectedFacets;
  }

  // TODO:See if you can merge the documentList Facet and Author List facet fetching
  //  to reduce the additional looping
  get documentAuthors(): SearchCheckboxOptions[] {
    if (!this.documentSearchResult) {
      return [];
    }

    const authorList: SearchCheckboxOptions[] = [];
    this.documentSearchResult.facets!.map((facet) => {
      if (facet.name === FacetTypes.Author) {
        facet.keysAndCount.map((keyObject) => {
          authorList.push({
            id: keyObject.key,
            name: keyObject.key,
            count: keyObject.doc_count
          });
        });
      }
    });
    return authorList;
  }

  get documentTypes(): SearchCheckboxOptions[] {
    if (!this.documentSearchResult) {
      return [];
    }

    const documentTypeList: SearchCheckboxOptions[] = [];
    this.documentSearchResult.facets!.map((facet) => {
      if (facet.name === FacetTypes.DocumentType) {
        facet.keysAndCount.map((keyObject) => {
          documentTypeList.push({
            id: keyObject.key,
            name: keyObject.key,
            count: keyObject.doc_count
          });
        });
      }
    });
    return documentTypeList;
  }

  get assignedUsers(): SearchCheckboxOptions[] {
    if (!this.documentSearchResult) {
      return [];
    }

    const assignedUserList: SearchCheckboxOptions[] = [];
    this.documentSearchResult.facets!.map((facet) => {
      if (facet.name === FacetTypes.AssignedUser) {
        facet.keysAndCount.map((keyObject) => {
          assignedUserList.push({
            id: keyObject.key,
            name: keyObject.key,
            count: keyObject.doc_count
          });
        });
      }
    });
    return assignedUserList;
  }

  get nodes(): SearchCheckboxOptions[] {
    if (!this.documentSearchResult) {
      return [];
    }

    const nodesList: SearchCheckboxOptions[] = [];
    this.documentSearchResult.facets!.map((facet) => {
      if (facet.name === FacetTypes.Nodes) {
        facet.keysAndCount.map((keyObject) => {
          nodesList.push({
            id: keyObject.key,
            name: keyObject.key,
            count: keyObject.doc_count
          });
        });
      }
    });
    return nodesList;
  }

  get sortOptions() {
    return [
      {
        id: 'Relevance',
        name: 'Relevance'
      }
    ];
  }

  get workerJobs(): Job[] {
    return useWebsocketState.call(this).workerJobs;
  }

  public async mounted() {
    await this.getAllResearchNodes();
    await this.getMeshTree();
    await this.searchUsersByText('');

    // NOTE: this condition is needed in case reload the page which already contained data in url
    // If has data, the function handleSearch runs again to make sure the search results are updated with the latest query
    if (!isEmpty(this.$route.query)) {
      const searchParam = this.getSearchParamFromQueryParam(this.$route.query);
      await this.searchDocument(searchParam);
    }
  }

  private normalizeFacets(facetCriteria: SearchCheckboxOptions[]): string[] {
    return facetCriteria.map((criteria) => criteria.id!);
  }

  private handleFacetsForSearchParam(
    draftSearchParams: SearchParams
  ): FacetsQueryParam {
    const facetQuery: FacetsQueryParam = {
      facetDocumentTypes: [],
      facetAuthors: [],
      facetNodes: [],
      facetAssignedUsers: []
    };

    facetQuery.facetDocumentTypes = this.normalizeFacets(
      draftSearchParams.facets!.documentTypes
    );

    facetQuery.facetAuthors = this.normalizeFacets(
      draftSearchParams.facets!.authors
    );

    facetQuery.facetNodes = this.normalizeFacets(
      draftSearchParams.facets!.nodes
    );
    facetQuery.facetAssignedUsers = this.normalizeFacets(
      draftSearchParams.facets!.assignedUsers
    );

    return facetQuery;
  }

  private getFacetFromQueryParam(
    facetParam: string | string[]
  ): SearchCheckboxOptions[] {
    if (Array.isArray(facetParam)) {
      return facetParam.map((item) => {
        return {
          id: item
        };
      });
    } else {
      return [{ id: facetParam }];
    }
  }

  private updateResearchNodes() {
    const { nodes } = this.$route.query;
    if (nodes) {
      if (Array.isArray(nodes)) {
        this.draftSearchParams.nodes = this.allResearchNodeOptions.filter(
          (option: ResearchNode) => nodes.find((o) => o === option.name)
        );
      } else {
        const node = this.allResearchNodeOptions.find(
          (o: ResearchNode) => o.name === nodes
        );
        if (node) {
          this.draftSearchParams.nodes = [node];
        }
      }
    } else {
      this.draftSearchParams.nodes = [];
    }
  }

  /**
   * Return SearchDocumentPayload from Query Param
   * @param routeQuery
   *
   * @return SearchDocumentPayload
   */
  private getSearchParamFromQueryParam(
    routeQuery: any
  ): Omit<SearchDocumentPayload, 'researchNodes'> {
    const {
      assignedUsers,
      documentTypes,
      documentId,
      revisionStatuses,
      legacyFilter,
      authors,
      nodes,
      meshTags,
      page,
      perPage,
      text,
      publicationYearFrom,
      publicationYearTo,
      mode,
      facetDocumentTypes,
      facetAuthors,
      facetNodes,
      facetAssignedUsers,
      criteria,
      showResult,
      hasCriteria
    } = routeQuery;

    // NOTE: When we search facets on backend, we only need StringInputOption id of a facet
    // Therefore when we handle searching facet, we push facet StringInputOption id to search param
    // When we get facet from url param, we only need to get facet StringInputOption id, we can omit facet name and facet count
    // Will get facet name and facet count from documentSearchResult later
    // Handle facet from route query

    if (criteria) {
      const normalizedCriteria = JSON.parse(criteria).map((element: any) => {
        element.condition = searchConditions.find(
          (searchCondition) =>
            searchCondition.id.toUpperCase() === element.condition
        );
        element.props = searchProps.filter((searchProp) =>
          element.props.includes(searchProp.id)
        );
        return element;
      });
      this.draftSearchParams.criteria = normalizedCriteria;
    }

    if (facetDocumentTypes) {
      this.draftSearchParams.facets!.documentTypes = this.getFacetFromQueryParam(
        facetDocumentTypes
      );
    } else {
      this.draftSearchParams.facets!.documentTypes = [];
    }

    if (facetAuthors) {
      this.draftSearchParams.facets!.authors = this.getFacetFromQueryParam(
        facetAuthors
      );
    } else {
      this.draftSearchParams.facets!.authors = [];
    }

    if (facetNodes) {
      this.draftSearchParams.facets!.nodes = this.getFacetFromQueryParam(
        facetNodes
      );
    } else {
      this.draftSearchParams.facets!.nodes = [];
    }

    if (facetAssignedUsers) {
      this.draftSearchParams.facets!.assignedUsers = this.getFacetFromQueryParam(
        facetAssignedUsers
      );
    } else {
      this.draftSearchParams.facets!.assignedUsers = [];
    }

    // Handle basic/advanced search from route query
    if (documentTypes) {
      if (Array.isArray(documentTypes)) {
        this.draftSearchParams.documentTypes = this.documentTypeOptions.filter(
          (option) => documentTypes.find((o) => o === option.id)
        );
      } else {
        this.draftSearchParams.documentTypes = [
          this.documentTypeOptions.find((type) => type.id === documentTypes) ||
            ''
        ];
      }
    } else {
      this.draftSearchParams.documentTypes = [];
    }

    if (hasCriteria && hasCriteria === 'true') {
      this.draftSearchParams.hasCriteria = true;
    }

    if (revisionStatuses !== undefined) {
      if (Array.isArray(revisionStatuses)) {
        this.draftSearchParams.revisionStatuses = this.publicationStatusOptions.filter(
          (option) => revisionStatuses.find((o) => o === option.id)
        );
      } else {
        this.draftSearchParams.revisionStatuses = [
          this.publicationStatusOptions.find(
            (type) => type.id === revisionStatuses
          ) || ''
        ];
      }
    }

    if (legacyFilter) {
      const filter:
        | StringInputOption
        | undefined = this.legacyFilterOptions.find(
        (option) => option.id === legacyFilter
      );
      if (filter) {
        this.draftSearchParams.legacyFilter = this.legacyFilterOptions.find(
          ({ id }) => id === filter.id
        )!;
      }
    }

    if (authors) {
      this.draftSearchParams.authors = [authors as string];
    } else {
      this.draftSearchParams.authors = [];
    }

    if (assignedUsers) {
      if (Array.isArray(assignedUsers)) {
        this.draftSearchParams.assignedUsers = assignedUsers;
      } else {
        this.draftSearchParams.assignedUsers = [assignedUsers];
      }
    } else {
      this.draftSearchParams.assignedUsers = [];
    }

    if (meshTags) {
      if (Array.isArray(meshTags)) {
        this.draftSearchParams.meshTags = meshTags;
      } else {
        this.draftSearchParams.meshTags = [meshTags];
      }
    } else {
      this.draftSearchParams.meshTags = [];
    }

    this.updateResearchNodes();

    this.draftSearchParams.publicationYearFrom = String(
      publicationYearFrom || ''
    );
    this.draftSearchParams.documentId = String(documentId || '');
    this.draftSearchParams.publicationYearTo = String(publicationYearTo || '');
    this.draftSearchParams.text = (text || '') as string;
    this.isAdvance = mode === 'advance';
    const params = this.searchSchema;

    if (this.isAdvance) {
      params.text = '';
    }
    this.page = +page || 1;
    this.perPage = +perPage || 50;

    const searchParam = {
      ...params,
      page: this.page,
      perPage: this.perPage,
      isAdvance: this.isAdvance,
      facets: {
        documentTypes: this.draftSearchParams.facets!.documentTypes,
        authors: this.draftSearchParams.facets!.authors,
        nodes: this.draftSearchParams.facets!.nodes,
        assignedUsers: this.draftSearchParams.facets!.assignedUsers
      }
    };

    return searchParam;
  }

  private async handleExportCSV() {
    if (this.documentSearchResult) {
      const workerJob = await this.exportSearchResult({
        ...this.searchSchema,
        perPage: this.documentSearchResult.total_count || 0,
        isAdvance: this.isAdvance
      });

      handleCsvExporting.call(this, workerJob, SearchResultExportType.csv);
    }
  }

  private get searchSchema(): Omit<SearchDocumentPayload, 'researchNodes'> {
    if (!this.isAdvance) {
      return {
        text: this.draftSearchParams.text || '',
        legacyFilter: LegacySearchFilters['Standard Documents'],
        revisionStatuses: [RevisionPublicationStatus.Published]
      };
    } else {
      const payload: SearchDocumentPayload = {
        text: this.draftSearchParams.text || '',
        authors: this.draftSearchParams.authors,
        documentTypes: this.draftSearchParams.documentTypes.map(
          ({ id }: StringInputOption) => id
        ),
        hasCriteria: this.draftSearchParams.hasCriteria,
        nodes: this.draftSearchParams.nodes.map(
          ({ name }: StringInputOption) => name!
        ),
        publicationYearFrom:
          +this.draftSearchParams.publicationYearFrom || undefined,
        publicationYearTo:
          +this.draftSearchParams.publicationYearTo || undefined,
        assignedUsers: this.draftSearchParams.assignedUsers,
        meshTags: this.draftSearchParams.meshTags,
        revisionStatuses: this.draftSearchParams.revisionStatuses.map(
          ({ name }: StringInputOption) => name!
        ),
        documentId: this.draftSearchParams.documentId || '',
        legacyFilter: this.draftSearchParams.legacyFilter
          .id as LegacySearchFilters
      };
      if (this.draftSearchParams.criteria) {
        // advanced search in effect
        payload.criteria = this.draftSearchParams.criteria.map((criterion) => {
          // necessary as parent is not a primitive type
          const props = criterion.props.map((prop) => prop.name);
          return {
            props: [...props],
            condition: criterion.condition.name.toUpperCase(),
            keyword: criterion.keyword.toLowerCase()
          };
        });
      }
      return payload;
    }
  }

  private handleFacets(facets: SearchFacetsParams) {
    let key: keyof SearchFacetsParams;

    for (key in facets) {
      this.draftSearchParams.facets![key] = [...facets[key]];
    }

    const params = this.searchSchema;

    // Handle search query param for facets
    this.facetQueryParam = this.handleFacetsForSearchParam(
      this.draftSearchParams
    );

    // NOTE: this code to solve the problem in CPLUS2-763: Resolve pagination issue on last page
    // We have to reset to page 1 when search or filter
    // Use case: Navigate to the last page, search by criteria or filter by facets, it will show "No results for your search term." if we didn't reset page
    this.page = 1;

    // NOTE: Old code - this code to solve the problem in CPLUS2-763: Resolve pagination issue on last page
    // if (JSON.stringify(params) !== JSON.stringify(this.query)) {
    //   this.page = 1;
    //   this.query = { ...params };
    // }

    if (this.isAdvance) {
      params.text = '';
    }
    // @ts-ignore
    this.$router.push({
      // @ts-ignore
      query: {
        ...params,
        ...this.facetQueryParam,
        criteria: JSON.stringify(params.criteria),
        page: String(this.page),
        perPage: String(this.perPage),
        mode: this.isAdvance ? 'advance' : 'basic',
        showResult: 'true'
      }
    });
  }

  private handleClickBtnSearch() {
    // NOTE: this code to solve the problem in CPLUS2-763: Resolve pagination issue on last page
    // We have to reset to page 1 when search or filter
    // Use case: Navigate to the last page, search by criteria or filter by facets, it will show "No results for your search term." if we didn't reset page
    this.page = 1;
    this.handleSearch();
  }

  private handleSearch() {
    const params = this.searchSchema;

    // Handle search query param for facets
    this.facetQueryParam = this.handleFacetsForSearchParam(
      this.draftSearchParams
    );

    // NOTE: Old code - this code to solve the problem in CPLUS2-763: Resolve pagination issue on last page
    // if (JSON.stringify(params) !== JSON.stringify(this.query)) {
    //   this.page = 1;
    //   this.query = { ...params };
    // }

    if (this.isAdvance) {
      params.text = '';
    }

    // @ts-ignore
    this.$router.push({
      // @ts-ignore
      query: {
        ...params,
        ...this.facetQueryParam,
        criteria: JSON.stringify(params.criteria),
        page: String(this.page),
        perPage: String(this.perPage),
        mode: this.isAdvance ? 'advance' : 'basic',
        showResult: 'true'
      }
    });
  }

  private handleClear() {
    this.page = 1;
    this.query = {};
    this.draftSearchParams = {
      ..._cloneDeep(this.getInitialSearchParams()),
      criteria: [
        {
          props: searchProps,
          condition: searchConditions[0],
          keyword: ''
        }
      ],
      meshTags: [] // seem like clone deep cant clone a deep nested object
    };
  }

  private async handleSwitch() {
    if (!this.isAdvance) {
      this.draftSearchParams = {
        ..._cloneDeep(this.getInitialSearchParams()),
        text: this.draftSearchParams.criteria?.[0]?.keyword || '',
        facets: this.draftSearchParams.facets
      };
    }
    if (this.draftSearchParams.text) {
      if (
        !(
          this.draftSearchParams.criteria &&
          this.draftSearchParams.criteria.length > 0
        )
      ) {
        this.draftSearchParams.criteria = [];
      }
      const tokens = this.draftSearchParams.text.match(/[^\s]+/g);
      if (tokens) {
        tokens.forEach((token, index) => {
          if (index === 0 && this.draftSearchParams.criteria) {
            this.draftSearchParams.criteria[0].keyword = token;
          } else {
            this.draftSearchParams.criteria?.push({
              props: searchProps,
              condition: searchConditions[0],
              keyword: token
            });
          }
        });
      }
    }
  }

  private async handlePaginator({
    perPage,
    page
  }: Partial<SearchDocumentPayload>) {
    await this.handleSearch();

    const el: Vue['$el'] | undefined = _get(this.$refs, 'container.$el');

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private async handleExport({
    type,
    item
  }: {
    type: DocumentExportType;
    item: SearchResultListItemParam;
  }) {
    const { isLegacy } = item;
    switch (type) {
      case DocumentExportType.pdf:
        const doc: DocumentIndexingPayload = this.documentSearchResult!.items.find(
          (o) => item.uniquePublicationId === o.uniquePublicationId
        )!;

        const workerJob = isLegacy
          ? await this.exportDocument({
              baseDocumentId: item.legacyBaseDocumentId,
              exportType: type
            })
          : await this.exportDocument({
              documentId: doc.documentId,
              revisionId: doc.revisionId,
              exportType: type
            });

        this.itemsInExport.push({
          uniquePublicationId: item.uniquePublicationId,
          jobId: workerJob.id
        });

        handleDocumentExporting.call(this, workerJob, doc, type);
        break;
    }
  }

  /**
   * This Watch function run when the route query change (even when we reload the page)
   * @param value this value is the same to this.$route.query
   * @param prevValue
   */
  @Watch('$route.query', { immediate: true })
  private onRoute(value: any, prevValue: any) {
    if (Object.keys(value).length === 0) {
      this.resetSearchDocument();

      this.draftSearchParams = _cloneDeep({
        ...this.getInitialSearchParams()
      });
      // default variable
      this.draftSearchParams.legacyFilter = legacyFilterOptions[1];

      this.isAdvance = false;
      this.perPage = 50;
      this.page = 1;
    } else {
      const searchParam = this.getSearchParamFromQueryParam(value);
      this.searchDocument(searchParam);
    }
  }

  // NOTE: this function is used to update research node param from query and research node options
  // It runs when researchNodeOptions is updated to prevent the race condition
  // Which means in the first time the component is loaded, research node options does not get in time
  // Watch researchNodeOptions makes sure the updating process runs after getting research node options
  @Watch('researchNodeOptions')
  public updateResearchNodeValue() {
    this.updateResearchNodes();
  }

  get activeSearchParams(): SearchParams {
    const legacyFilter =
      legacyFilterOptions.find(
        ({ id }) => id === (this.$route.query.legacyFilter as string)
      ) || legacyFilterOptions[1];
    return {
      text: this.$route.query.text as string,
      nodes: this.$route.query.nodes,
      authors: this.$route.query.authors as string[],
      assignedUsers: this.$route.query.assignedUsers as string[],
      documentTypes: this.$route.query.documentTypes as string[],
      documentId: this.$route.query.documentId as string,
      revisionStatuses: this.$route.query.revisionStatuses as string[],
      legacyFilter,
      publicationYearFrom: this.$route.query.publicationYearFrom as string,
      publicationYearTo: this.$route.query.publicationYearTo as string,
      meshTags: this.$route.query.publicationYearTo as string[]
    };
  }

  @Watch('activeSearchParams', { deep: true })
  private onSearchParamsChanged(value: any, prevValue: any) {
    if (isEqual(value, prevValue)) {
      return;
    }
  }

  private isDocExporting(uniquePublicationId: string): boolean {
    const itemInExport = findLast(
      this.itemsInExport,
      (o) => o.uniquePublicationId === uniquePublicationId
    );
    if (!itemInExport) {
      return false;
    }
    const jobId = _get(itemInExport, 'jobId');
    const job = findLast(this.workerJobs, (o) => o.id === jobId);
    const completed = !!_get(job, 'finishedOn');
    return Boolean(job) && !completed;
  }
}
