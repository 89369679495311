







































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import Container from '@/components/Container.vue';
import { get as _get } from 'lodash';
import { RootState } from '@/store/store';
import PaginatedProjectList from '@/components/listings/projects/PaginatedProjectList.vue';
import AssignEditorButton from '@/views/AdminDashboard/components/AssignEditorButton.vue';
import {
  GetAllProjectsRequestPayload,
  GetAllProjectResponsePayload,
  FilteredProjectQueryParam
} from '@/store/modules/projects/types/projects.types';
import { ApiState, PaginatedApiResponse } from '@/store/types/general.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import ProjectFilterBox from './components/ProjectFilterBox.vue';
import { FilteredProjectPayload } from '@/store/modules/projects/types/projects.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { handleAdminExporting } from '@/utils/admin.util';
import ExportProjectDataButton from './components/ExportProjectDataButton.vue';
import {
  AdminExportType,
  ExportProjectListRequestPayload,
  ResendDownloadLinkRequestPayload
} from '@/store/modules/admin/types/admin.types';
import dayjs from 'dayjs';
import { Job } from 'bull';
import UploadDocumentButton from '@/views/AdminDashboard/components/UploadDocumentButton.vue';
import UploadHistoryButton from '@/views/AdminDashboard/components/pending-document-creations/UploadHistoryButton.vue';
import { CreatePendingDocumentPayload } from '@/jbi-shared/types/cplus-worker.types';
import ProjectSelected from '@/views/AdminDashboard/components/ProjectSelected.vue';

const initialState = {
  projectName: '',
  documentId: '',
  assignee: '',
  selectedStatus: '',
  primaryField: '',
  dueDate: [],
  searchDate: [],
  showProjectsWithRpOnly: false
};

@Component({
  components: {
    UploadDocumentButton,
    UploadHistoryButton,
    Container,
    DashboardHeader,
    PaginatedProjectList,
    AssignEditorButton,
    ProjectFilterBox,
    ExportProjectDataButton,
    ProjectSelected
  }
})
export default class ProjectsPage extends Vue {
  public page = 1;
  public perPage = 50;
  public sortCriteria = 'projectTitle';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public selectedProjects: ProjectListItemParam[] = [];
  public selectAll: boolean = false;
  public filteredParams: FilteredProjectPayload = { ...initialState };
  public assignee: string = '';
  public countAssignedProjects: number = 0;

  @Action('projects/getAllProjects')
  public getAllProjects!: (p: GetAllProjectsRequestPayload) => void;

  @Action('admin/exportProjectList')
  public exportProjectList!: (
    p: ExportProjectListRequestPayload
  ) => Promise<Job>;

  @State((state: RootState) => _get(state, 'projects.allProjects'))
  public projects!: PaginatedApiResponse<GetAllProjectResponsePayload>;

  @State((state: RootState) => _get(state, 'admin.apiState.changeDueDate'))
  public changeDueDate!: ApiState;

  @State((state: RootState) => state.admin.pendingDocumentsCreated)
  public pendingDocumentsCreated!: CreatePendingDocumentPayload[];

  @State((state: RootState) =>
    _get(state, 'projects.apiState.addNoteToProject')
  )
  public addNoteToProject!: ApiState;

  @State((state: RootState) =>
    _get(state, 'projects.apiState.updateProjectNote')
  )
  public updateProjectNote!: ApiState;

  @State((state: RootState) =>
    _get(state, 'admin.apiState.assignProjectEditor')
  )
  public assignProjectEditor!: ApiState;

  @State((state: RootState) =>
    _get(state, 'admin.apiState.unassignProjectEditor')
  )
  public unassignProjectEditor!: ApiState;

  get projectsPaginated() {
    return {
      items: _get(this.projects, 'items', []),
      totalItems: _get(this.projects, 'total_count', 0)
    };
  }

  get filterQueryParams() {
    const searchDate = this.filteredParams.searchDate;
    let searchDateParam;
    if (searchDate && searchDate.length === 2) {
      searchDateParam = searchDate
        .map((date) => new Date(date))
        .map((date) => this.dateToIsoDate(date));
    }

    const dueDate = this.filteredParams.dueDate;
    let dueDateParam;
    if (dueDate && dueDate.length === 2) {
      dueDateParam = dueDate
        .map((date) => new Date(date))
        .map((date) => this.dateToIsoDate(date));
    }
    return {
      ...this.filteredParams,
      searchDate: searchDateParam,
      dueDate: dueDateParam
    };
  }

  public updateAssignee(val: string) {
    this.assignee = val;
  }

  public countAssignedProjectsAssigned(val: number) {
    this.countAssignedProjects = val;
  }

  public dateToIsoDate(d: Date) {
    return dayjs(d).format().split('T').shift()!;
  }

  public handleReset() {
    this.filteredParams = {
      ...this.filteredParams,
      ...initialState
    };
    this.debounceUpdate();
  }

  public handleFilter(filterParam: Partial<FilteredProjectPayload>) {
    this.filteredParams = {
      ...this.filteredParams,
      ...filterParam
    };
    this.debounceUpdate();
  }

  @Debounce(500)
  public debounceUpdate() {
    this.page = 1;
    this.updateRouteQuery();
  }

  public updateSelectedProjects(val: ProjectListItemParam[]) {
    const beforeUpdate = [...this.selectedProjects];
    if (val.length === 0 || beforeUpdate.length > val.length) {
      this.selectAll = false;
    }
    this.selectedProjects = val;
  }

  public allSelectedProjects(selected: boolean) {
    this.selectAll = selected;
  }

  public addSelectedProjects(val: ProjectListItemParam) {
    const existingProject = this.selectedProjects.find(
      (project) => project.projectId === val.projectId
    );
    if (!existingProject) {
      this.selectedProjects.push(val);
    }
  }

  public removeSelectedProjects(val: ProjectListItemParam) {
    const index = this.selectedProjects.findIndex(
      (project) => project.projectId === val.projectId
    );
    this.selectedProjects.splice(index, 1);
    this.selectAll = false;
  }

  public resetSelectedProjects() {
    this.selectedProjects = [];
    this.selectAll = false;
  }

  public updateFilteredParams(filterParams: FilteredProjectQueryParam) {
    const searchDate = filterParams.searchDate
      ? filterParams.searchDate.map((date) => new Date(date))
      : [];
    const dueDate = filterParams.dueDate
      ? filterParams.dueDate.map((date) => new Date(date))
      : [];

    this.filteredParams = {
      ...filterParams,
      searchDate,
      dueDate
    };
  }

  public async handleExportPdf() {
    const workerJob = await this.exportProjectList({
      sortCriteria: this.sortCriteria,
      sortOrder: this.sortOrder,
      exportType: 'pdf',
      filterParams: this.filterQueryParams
    });
    handleAdminExporting.call(this, workerJob, AdminExportType.pdf);
  }

  public async handleExportXlsx() {
    const workerJob = await this.exportProjectList({
      sortCriteria: this.sortCriteria,
      sortOrder: this.sortOrder,
      exportType: 'xlsx',
      filterParams: this.filterQueryParams
    });
    handleAdminExporting.call(this, workerJob, AdminExportType.xlsx);
  }

  public handleSort(val: { sortCriteria: string; sortOrder: 'ASC' | 'DESC' }) {
    const { sortCriteria, sortOrder } = val;
    this.sortCriteria = sortCriteria;
    this.sortOrder = sortOrder;
    this.updateRouteQuery();
  }

  public updateRouteQuery() {
    this.$router.push({
      // @ts-ignore
      query: {
        ...this.filterQueryParams,
        sortCriteria: this.sortCriteria,
        sortOrder: this.sortOrder,
        page: String(this.page),
        perPage: String(this.perPage)
      }
    });
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    const {
      page,
      perPage,
      projectName,
      documentId,
      assignee,
      selectedStatus,
      primaryField,
      searchDate,
      dueDate,
      showProjectsWithRpOnly,
      sortCriteria,
      sortOrder
    } = value;
    this.perPage = +perPage || 50;
    this.page = +page || 1;

    this.sortCriteria = sortCriteria || 'projectTitle';
    this.sortOrder = sortOrder || 'ASC';

    const filterParams = {
      projectName,
      documentId,
      assignee,
      selectedStatus,
      primaryField,
      searchDate,
      dueDate,
      showProjectsWithRpOnly
    };

    this.updateFilteredParams(filterParams);
    this.handleGetProjects();
  }

  @isTruthy
  @Watch('pendingDocumentsCreated')
  public onPendingDocumentsCreated() {
    this.handleGetProjects();
  }

  public handleGetProjects() {
    this.getAllProjects({
      page: this.page,
      perPage: this.perPage,
      sortCriteria: this.sortCriteria,
      sortOrder: this.sortOrder,
      projectName: this.filteredParams.projectName || undefined,
      documentId: this.filteredParams.documentId || undefined,
      assignee: this.filteredParams.assignee || undefined,
      selectedStatus: this.filteredParams.selectedStatus || undefined,
      primaryField: this.filteredParams.primaryField || undefined,
      dueDate: this.filteredParams.dueDate
        ? this.filteredParams.dueDate.map((date) => this.dateToIsoDate(date))
        : undefined,
      searchDate: this.filteredParams.searchDate
        ? this.filteredParams.searchDate.map((date) => this.dateToIsoDate(date))
        : undefined,
      showProjectsWithRpOnly:
        this.filteredParams.showProjectsWithRpOnly || undefined
    });
  }

  @isDifferent
  @isTruthy
  @Watch('changeDueDate.success')
  public onChangeDueDate() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Changes Saved`
    });
    this.handleGetProjects();
  }

  @isDifferent
  @isTruthy
  @Watch('addNoteToProject.success')
  public onAddNoteSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Note added`
    });
    this.handleGetProjects();
  }

  @isDifferent
  @isTruthy
  @Watch('updateProjectNote.success')
  public onUpdateProjectNoteSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Changes saved`
    });
    this.handleGetProjects();
  }

  @isDifferent
  @isTruthy
  @Watch('assignProjectEditor.success')
  public onAssignProjectEditorSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `${this.countAssignedProjects} successfully assigned to ${this.assignee}`
    });
    this.handleGetProjects();
  }

  @isDifferent
  @isTruthy
  @Watch('unassignProjectEditor.success')
  public onUnassignProjectEditorSuccess() {
    this.handleGetProjects();
  }
}
