
















import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TableActionDropdown extends Vue {
  @Prop({ type: String, default: 'is-bottom-left' }) public position!: string;
}
