

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { get as _get } from 'lodash';
import { EditorDetails } from '@/jbi-shared/types/editor.types';

@Component({
  components: {}
})
export default class EditorPositionsDialog extends Vue {
  @Prop()
  public item!: EditorDetails;
}
