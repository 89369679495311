var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        validator.validate().then(function (validated) {
          if (validated === false) { return; }
          _vm.$refs.CreateProjectForm.handleSubmit();
        });
      })($event)}}},[_c('BaseModal',{attrs:{"title":"Create New Project"},scopedSlots:_vm._u([{key:"openBtn",fn:function(ref){
      var openModal = ref.openModal;
return [_vm._t("default",[_c('span',{class:['button', 'is-primary'],attrs:{"data-test":"open-create-project"},on:{"click":openModal}},[_vm._v(" Create Project ")])],{"openModal":openModal})]}},{key:"modalBody",fn:function(ref){
      var closeModal = ref.closeModal;
return [_c('CreateProjectForm',{ref:"CreateProjectForm",on:{"closeModal":closeModal,"submit":_vm.handleCreateProject}})]}},{key:"modalFoot",fn:function(){return [_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.createProjectLoading },attrs:{"type":"submit","data-test":"create-project","disabled":!_vm.researchNodes || !validator.valid}},[_vm._v(" Create Project ")])]},proxy:true}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }