import Vue from 'vue';
import Component from 'vue-class-component';
import { useApiState, useAction, useAuthState } from './store.util';
import { Watch } from 'vue-property-decorator';
import {
  isDifferent,
  isTruthy
} from '../jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';
import {
  get as _get,
  throttle as _throttle,
  includes as _includes
} from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import { Throttle } from '../jbi-shared/util/throttle.vue-decorator';
import { JAAS_LOGIN_URL } from '@/utils/login.util';
import { AuthInfo, OicPayload } from '@/store/modules/auth/types/auth.types';
import { RootState } from '@/store/store';
import { State } from 'vuex-class';
import { NotificationProgrammatic as Notification } from 'buefy';

// FIXME: this is messy but it works for now
export let userIsInactive = false;

@Component({})
export class SessionHandlerMixin extends Vue {
  public showingSessionDialog: boolean = false;
  public showEmailUpdatedNotification: boolean = false;

  get signOutSuccess() {
    return useApiState.call(this, 'auth/signOut')!.success;
  }

  get getMyRoles() {
    return useAction.call(this, 'users/getMyRoles');
  }

  get checkSessionHealth() {
    return useAction.call(this, 'auth/checkSessionHealth');
  }

  get authInfo() {
    return useAuthState.call(this).authInfo;
  }

  get shouldCheckSession() {
    const excludedPath = [
      'home',
      'oauth2callback',
      'accept-invitations',
      'assignment-download'
    ];
    return !_includes(excludedPath, this.$route.name);
  }

  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  get getUserOicPayload() {
    return useAction.call(this, 'users/getUserOicPayload');
  }

  @State((state: RootState) => state.users.oicPayload)
  public oicPayload!: OicPayload;

  @Watch('signOutSuccess')
  @isDifferent
  @isTruthy
  public watchSignoutSuccess(value: boolean) {
    this.logoutFromJaas(() => this.$router.push('/'));
  }

  @Watch('$route', { immediate: true })
  @isDifferent
  @isTruthy
  public onRouteChange() {
    if (this.shouldCheckSession) {
      this.checkSessionHealth();
    }
  }

  /**
   * This watcher will listen only on the parent route changes
   */
  @Watch('$route.name', { immediate: true })
  @isDifferent
  @isTruthy
  public onParentRouteChange() {
    if (this.shouldCheckSession) {
      this.getUserOicPayload();
    }
  }

  public created() {
    this.setupApiResponseHandler();
  }

  public setupApiResponseHandler() {
    const store = this.$store;
    store.subscribe((mutation) => {
      const { type } = mutation;
      if (type.endsWith('_ERROR')) {
        const error: AxiosError = mutation.payload;
        const status = _get(error, 'response.status') as number;
        const isUnauthorized = status === 401;
        const hasNoSession =
          status === 400 && type.includes('CHECK_SESSION_HEALTH_ERROR');
        const isInactive =
          status === 403 &&
          _get(error.response, 'data.message') === 'User is inactive';

        if (userIsInactive === true) {
          return;
        }

        if (isInactive) {
          userIsInactive = true;
          this.logoutFromJaas();
          Dialog.alert({
            message: 'Please ask the administrator to enable your account.',
            onConfirm: () => {
              window.location.href = '/';
            }
          });
        } else if (hasNoSession) {
          // this.loginAlertAndRedirect(
          //   'You are not logged in. You cannot view this URL as a guest.',
          // );
          window.location.href = JAAS_LOGIN_URL;
        } else if (isUnauthorized) {
          // this.loginAlertAndRedirect(
          //   'Your session has expired. Please login again.',
          // );
          window.location.href = JAAS_LOGIN_URL;
        }
      }
    });
  }

  public loginAlertCb() {
    this.showingSessionDialog = false;
    this.$router.push('/');
  }

  public loginAlertAndRedirect(alertMsg: string) {
    if (this.showingSessionDialog) {
      return;
    }
    this.showingSessionDialog = true;
    Dialog.alert({
      message: alertMsg,
      onConfirm: this.loginAlertCb,
      onCancel: this.loginAlertCb
    });
  }

  public logoutFromJaas(callback?: () => void): void {
    // logout from JAAS by loading JASS logout url in an iframe
    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', process.env.VUE_APP_JAAS_PUBLIC_URL + '/logout');
    ifrm.style.display = 'none';
    document.body.appendChild(ifrm);
    ifrm.onload = () => {
      callback && callback();
      ifrm.remove();
    };
  }

  @Watch('isLoggedIn', { immediate: true })
  @Watch('authInfo', { immediate: true })
  @isTruthy
  @Throttle(100)
  public onLoggedIn() {
    return this.getMyRoles();
  }

  @Watch('oicPayload', { immediate: true })
  public checkIfEmailIsSynced(oicPayload: OicPayload) {
    const authInfo: AuthInfo | null = this.authInfo;
    if (
      !this.showEmailUpdatedNotification &&
      authInfo &&
      authInfo.oicPayload &&
      authInfo.oicPayload.email &&
      oicPayload &&
      authInfo.oicPayload.email !== oicPayload.email
    ) {
      this.showEmailUpdatedNotification = true;
      Notification.open({
        type: 'is-danger',
        position: 'is-top',
        message: `Email has been updated, kindly logout and login again to view the update.`,
        indefinite: true
        // @ts-ignore
      }).$on('close', () => {
        this.showEmailUpdatedNotification = false;
      });
    }
  }
}
