










































import { Component, Watch, Vue } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import { Action, State } from 'vuex-class';
import PaginatedEditorList from '@/components/listings/editors/PaginatedEditorList.vue';
import EditorFilterBox from './components/EditorFilterBox.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import {
  FilteredEditorPayload,
  GetEditorsRequestPayload
} from '@/store/modules/editors/types/editors.types';
import { EditorDetails } from '@/jbi-shared/types/editor.types';
import { RootState } from '@/store/store';
import { GetEditorsResponsePayload } from '@/jbi-shared/types/cplus-endpoints/editor.types';

const initialState = {
  name: '',
  email: '',
  assignedProjectCount: '',
  overdueProjectCount: '',
  role: ''
};

@Component({
  components: {
    Container,
    PaginatedEditorList,
    EditorFilterBox
  }
})
export default class EditorsPage extends Vue {
  public page = 1;
  public perPage = 50;
  public sortCriteria: keyof EditorDetails = 'name';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredParams: FilteredEditorPayload = { ...initialState };

  @Action('editors/getEditors')
  public getEditors!: (p: GetEditorsRequestPayload) => void;

  @Action('editors/fetchEditorRoles')
  public fetchEditorRoles!: () => void;

  @State((state: RootState) => state?.editors?.editors)
  public editors!: GetEditorsResponsePayload | undefined;

  get editorsPaginated() {
    return {
      items: this.editors?.items || [],
      totalItems: this.editors?.totalCount || 0
    };
  }

  public handleSortEditor(val: {
    sortCriteria: keyof EditorDetails;
    sortOrder: 'ASC' | 'DESC';
  }) {
    const { sortCriteria, sortOrder } = val;
    this.sortCriteria = sortCriteria;
    this.sortOrder = sortOrder;
    this.updateRouteQuery();
  }

  public handleReset() {
    this.filteredParams = {
      ...this.filteredParams,
      ...initialState
    };
    this.debounceUpdate();
  }

  public handleFilter(filterParam: Partial<FilteredEditorPayload>) {
    this.filteredParams = {
      ...this.filteredParams,
      ...filterParam
    };
    this.debounceUpdate();
  }

  @Debounce(500)
  public debounceUpdate() {
    this.page = 1;
    this.updateRouteQuery();
  }

  public updateRouteQuery() {
    this.$router.push({
      // @ts-ignore
      query: {
        ...this.filteredParams,
        sortCriteria: this.sortCriteria,
        sortOrder: this.sortOrder,
        page: String(this.page),
        perPage: String(this.perPage)
      }
    });
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    const {
      page,
      perPage,
      name,
      email,
      role,
      assignedProjectCount,
      overdueProjectCount,
      sortCriteria,
      sortOrder
    } = value;
    this.perPage = +perPage || 50;
    this.page = +page || 1;

    this.sortCriteria = sortCriteria || 'name';
    this.sortOrder = sortOrder || 'ASC';

    const filterParams = {
      name,
      email,
      role,
      assignedProjectCount,
      overdueProjectCount
    };

    this.filteredParams = {
      ...filterParams
    };

    this.handleGetEditors();
  }

  public handleGetEditors() {
    this.getEditors({
      page: this.page,
      perPage: this.perPage,
      sortCriteria: this.sortCriteria,
      sortOrder: this.sortOrder,
      name: this.filteredParams.name || undefined,
      email: this.filteredParams.email || undefined,
      assignedProjectCount:
        this.filteredParams.assignedProjectCount || undefined,
      overdueProjectCount: this.filteredParams.overdueProjectCount || undefined,
      role: this.filteredParams.role || undefined
    });
  }

  public mounted() {
    this.fetchEditorRoles();
  }
}
