




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DocumentNavigationTabs from './DocumentNavigationTabs.vue';
import ProjectEditorHeaderDocInfo from './ProjectEditorHeaderDocInfo.vue';
import ProjectEditorHeaderCollapseableTab from './ProjectEditorHeaderCollapseableTab.vue';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '../mixins/editor-common.mixin';
import { UpdateProjectMixin } from '../mixins/update-project.mixin';
import EditProjectForm from '@/components/form/EditProjectForm.vue';
import IconButton from '@/components/IconButton.vue';
import NewSubDocForm from './NewSubDocForm.vue';
import { get as _get, orderBy, cloneDeep, last } from 'lodash';
import {
  CplusDocumentType,
  CplusDocumentTypeShortHand
} from '@/jbi-shared/types/document.types';
import { RootState } from '@/store/store';
import { State, Mutation } from 'vuex-class';
import { GetProjectResponsePayload } from '@/store/modules/projects/types/project-details.types';
import { ResearchNode } from '@/store/modules/documents/types/documents.types';
import { DocumentDetail } from '../../../store/modules/documents/types/documents.types';
import { Action } from 'vuex-class';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';

@Component({
  components: {
    IconButton,
    DocumentNavigationTabs,
    ProjectEditorHeaderDocInfo,
    ProjectEditorHeaderCollapseableTab
  }
})
export default class ProjectEditorHeader extends mixins(
  EditorCommonMixin,
  UpdateProjectMixin
) {
  @State((state: RootState) => state.projects.projectDetail)
  public project!: GetProjectResponsePayload;

  public windowWidth = 0;
  public tabContainerWidth = 0;

  get projectId() {
    return +this.$route.params.projectId;
  }

  get displayTab() {
    // width of <a> tag 150 and marginRight 15
    // -2 for reserved 2 slot for add document and collape tab buttons
    const tab = Math.floor(this.tabContainerWidth / (150 + 15)) - 2;

    // min document tab display = 1
    if (tab === -1 || tab === 0) {
      return 1;
    }
    return tab;
  }

  getDocumentPriority(status: RevisionPublicationStatus) {
    switch (status) {
      case RevisionPublicationStatus.Published:
        return 0;
      case RevisionPublicationStatus.Draft:
        return 1;
      case RevisionPublicationStatus.Archived:
        return 2;
      default:
        return 3;
    }
  }

  get projectDocuments() {
    // prettier-ignore
    const projectDocuments = this.project ? cloneDeep(this.project!.projectDocuments) : [];

    const data = orderBy(projectDocuments, (doc) => {
      const status =
        _get(last(doc.revisions), 'revisionPublicationStatus.status') ||
        RevisionPublicationStatus.Draft;
      return [
        // ES should appear in front of the array
        _get(doc, 'documentType') === CplusDocumentType.EvidenceSummary ? 0 : 1,
        this.getDocumentPriority(status)
      ];
    });

    // verify document position, if actived document is out of display tab range, re-order the array again
    const index = data.findIndex((o) => o.id === this.documentId);
    if (index >= this.displayTab) {
      return orderBy(projectDocuments, (doc) => {
        const status: RevisionPublicationStatus =
          _get(last(doc.revisions), 'revisionPublicationStatus.status') ||
          RevisionPublicationStatus.Draft;
        return [
          _get(doc, 'documentType') === CplusDocumentType.EvidenceSummary
            ? 0
            : 1,
          _get(doc, 'id') === this.documentId ? 0 : 1,
          this.getDocumentPriority(status)
        ];
      });
    }

    return data;
  }

  get displayedDocuments() {
    return this.projectDocuments.slice(0, this.displayTab);
  }

  get collapsedDocuments() {
    return this.projectDocuments.slice(this.displayTab);
  }

  public openProjectSetting() {
    this.$buefy.modal.open({
      parent: this,
      component: EditProjectForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Project Settings'
      }
    });
  }

  public addDocument() {
    this.$buefy.modal.open({
      parent: this,
      component: NewSubDocForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Add Document'
      }
    });
  }

  public handleResize() {
    this.windowWidth = window.innerWidth;
    this.tabContainerWidth = this.windowWidth - (80 + 60);
  }

  public mounted() {
    this.windowWidth = window.innerWidth;
    // pading left + right = 80px
    // setting icon = 60px
    this.tabContainerWidth = this.windowWidth - (80 + 60);

    window.addEventListener('resize', this.handleResize.bind(this));
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  get isLoaded() {
    return this.displayedDocuments.length > 0;
  }
}
