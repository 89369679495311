


















import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { cloneDeep } from 'lodash';
import { RootState } from '@/store/store';
import {
  ResearchNode,
  PublishDocAction
} from '@/store/modules/documents/types/documents.types';
import { useDocumentsState } from '@/utils/store.util';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  CplusDocumentType,
  CplusDocumentAction
} from '@/jbi-shared/types/document.types';
import PdfViewerHeader from './components/PdfViewerHeader.vue';
import { mixins } from 'vue-class-component';
import { InviteUserMixin } from './mixins/invite-user.mixin';
import { UpdateProjectMixin } from './mixins/update-project.mixin';
import { ToastProgrammatic as Toast } from 'buefy';
import { EditDocumentFormValues } from '@/components/form/editProjectForm.types';
import { PermissionMixin } from '@/utils/permission.mixin';
import filenamify from 'filenamify';
import { PublishDocumentPayload } from '../../store/modules/projects/types/projects.types';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import DocumentPublishModal from './components/DocumentPublishModal.vue';

@Component({
  components: {
    PdfViewerHeader
  }
})
export default class PdfViewer extends mixins(
  InviteUserMixin,
  UpdateProjectMixin,
  PermissionMixin
) {
  public isUploading = false;

  @State((state: RootState) => state.documents.apiState.updateDocument.success)
  public updateDocumentSuccess!: boolean;

  @State((state: RootState) => state.documents.apiState.updateDocument.error)
  public updateDocumentError!: boolean;

  @State((state: RootState) => state.projects.activeResearchNodes)
  public researchNodes!: ResearchNode[];

  @Getter('documents/revisionId')
  public revisionId!: number;

  @Action('documents/getDocumentDetail')
  public getDocumentDetail!: (id: number) => void;

  @Action('documents/updatePDFDocument')
  public updatePDFDocument!: (payload: any) => void;

  @Action('projects/getActiveResearchNodes')
  public getResearchNodes!: () => void;

  @Action('documents/updatePublishStatus')
  public updatePublishStatus!: (payload: PublishDocumentPayload) => void;

  @Watch('updateDocumentSuccess')
  @isDifferent
  @isTruthy
  public onUpdateSuccess() {
    this.isUploading = false;

    Toast.open({
      message: `Document sucessfully updated.`,
      position: 'is-top',
      type: 'is-dark'
    });
  }

  @Watch('updateDocumentError')
  @isDifferent
  @isTruthy
  public onUpdateError() {
    this.isUploading = false;
    Toast.open({
      message: `Failed to update document. Please try again!`,
      position: 'is-top',
      type: 'is-danger'
    });
  }

  get documentDetail() {
    return useDocumentsState.call(this).documentDetail;
  }

  get CplusDocumentType() {
    return CplusDocumentType;
  }

  get documentType() {
    return this.documentDetail
      ? this.documentDetail.document.documentType
      : null;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get resourceUrl() {
    if (this.documentDetail) {
      const storageUri =
        this.documentDetail?.revision?.resource?.storageUri || '';
      return storageUri.replace('gs://', 'https://storage.googleapis.com/');
    }
    return '';
  }

  // TODO: rename this after demo
  public async uploadDocument(event: any) {
    this.isUploading = true;

    const payload = cloneDeep(this.documentDetail!);
    payload.revision.resource.storageUri = event.storageUri;
    await this.updatePDFDocument(payload);
    await this.getDocumentDetail(this.documentDetail!.document.id);
  }

  public editProjectSetting(values: EditDocumentFormValues) {
    this.editProject({
      ...values,
      projectId: this.projectId
    });
  }

  public async handleExport() {
    let fileName = this.resourceUrl.split('/').pop() || this.resourceUrl;
    fileName = filenamify(fileName);
    saveAs(this.resourceUrl, fileName);
  }

  /**
   * NOTE: The way we handle publishDocument for BPIS (Best Practice Information Sheet) and Systematic Review
   * is different to the way we handle publishDocument for ES, RP document
   * We don't create a new draft version when we restore BPIS document (because we can't create a new Draft version for these doc)
   */
  public publishDocument(text: PublishDocAction) {
    let message: string = `Publishing this document will make it available to external users and systems. Are you sure?`;
    let publishStatus: RevisionPublicationStatus =
      RevisionPublicationStatus.Published;
    let optionalMessage: string = '';
    let archivedNote: string;
    let withdrawnNote: string;

    switch (text) {
      case CplusDocumentAction.Archive:
        message = `This document will no longer be available for general users of the system once archived. Are you sure you want to archive this document?`;
        publishStatus = RevisionPublicationStatus.Archived;
        optionalMessage =
          'Optional: For better collaboration, add a note to clarify with other team members on why you archived this document';
        break;
      case CplusDocumentAction.UnArchive:
        message = `Unarchiving this document will make it available to external users and systems. Are you sure?`;
        publishStatus = RevisionPublicationStatus.Published;
        break;
      case CplusDocumentAction.Withdraw:
        message = `This document will no longer be available for general users of the system once withdrawn. Are you sure you want to withdraw this document?`;
        publishStatus = RevisionPublicationStatus.Withdrawn;
        optionalMessage =
          'Optional: For better collaboration, add a note to clarify with other team members on why you withdraw this document';
        break;
      case CplusDocumentAction.Restore:
        // We update the current version from Publish to Draft when we restore BPIS
        message =
          'This document is in End-of-life status (Withdrawn). \nAre you sure you want to restore this document? A new draft version of the document will be created upon restoration';
        publishStatus = RevisionPublicationStatus.Draft;
        break;
    }

    this.$buefy.modal.open({
      parent: this,
      component: DocumentPublishModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        headerTitle: `${text} This Document?`,
        state: text,
        message,
        optionalMessage
      },
      events: {
        'publish-anyway': async (data: { messageNote: string | undefined }) => {
          if (text === CplusDocumentAction.Archive && data.messageNote) {
            archivedNote = data.messageNote;
          } else if (
            text === CplusDocumentAction.Withdraw &&
            data.messageNote
          ) {
            withdrawnNote = data.messageNote;
          }

          await this.updatePublishStatus({
            documentId: this.documentDetail!.document.id,
            publishStatus,
            revisionId: this.revisionId,
            archivedNote,
            withdrawnNote
          });
        }
      }
    });
  }
}
