








































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ApiState } from '@/store/types/general.types';
import { RootState } from '@/store/store';
import { UploadCitationRequestPayload } from '@/store/modules/documents/types/citations.types';
import { DocumentDetail } from '@/store/modules/documents/types/documents.types';
import RadialProgressBar from '@/jbi-shared/vue-components/RadialProgressBar.vue';
import UploadError from './UploadError.vue';
import UploadComplete from './UploadComplete.vue';
import UploadLoading from './UploadLoading.vue';
import UploadTimeout from './UploadTimeout.vue';
import { handleCitationUploading } from '@/utils/upload-citation.util';
import {
  useAction,
  useDocumentsState,
  useApiState,
  useGetter
} from '@/utils/store.util';
import { useCitations } from '@/utils/citations.util';
import { UploadCitationWorkerJobMetadata } from '@/store/modules/documents/types/worker-job.types';
import { useRouteParams } from '@/utils/route.util';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ToastProgrammatic as Toast, DialogProgrammatic } from 'buefy';
import { Job } from 'bull';
import { get as _get } from 'lodash';
import { GetRevisionCitationsRequestPayload } from '../../../../../store/modules/documents/types/documents.types';
import { Citation } from '../../../../../store/modules/documents/types/citations.types';

@Component({
  components: {
    RadialProgressBar,
    UploadError,
    UploadComplete,
    UploadLoading,
    UploadTimeout
  }
})
export default class CitationUploader extends Vue {
  public dropFiles: File | null = null;
  @Prop() public revisionId!: number;

  @Action('documents/getRevisionCitations')
  public getRevisionCitations!: (
    payload: GetRevisionCitationsRequestPayload
  ) => Citation[];

  get uploadCitationApiState() {
    return useApiState.call(this, 'projects/uploadCitation');
  }

  get uploadCitation(): (
    payload: UploadCitationRequestPayload
  ) => Promise<Job> {
    return useAction.call(this, 'projects/uploadCitation');
  }

  get markWorkerJobAsClosed(): (payload: Job) => void {
    return useAction.call(this, 'websocket/markWorkerJobAsClosed');
  }

  get currentUploadCitationJob(): Job {
    return useGetter.call(this, 'projects/currentUploadCitationJob');
  }
  get processingUploadedCitation() {
    return useGetter.call(this, 'projects/processingUploadedCitation');
  }
  get uploadCitationCompleted() {
    // Fetch citations of revision
    this.getRevisionCitations({
      documentId: this.documentId,
      revisionId: this.revisionId
    });
    return useGetter.call(this, 'projects/uploadCitationCompleted');
  }

  get getCitationsForCurrentProject() {
    return useCitations.call(this).getCitationsForCurrentProject;
  }

  get currentUploadCitationJobMetadata() {
    return this.currentUploadCitationJob
      ? (_get(
          this.currentUploadCitationJob,
          'returnvalue.metadata'
        ) as UploadCitationWorkerJobMetadata)
      : undefined;
  }

  get successImport() {
    return this.currentUploadCitationJobMetadata
      ? this.currentUploadCitationJobMetadata.success
      : 0;
  }

  get failedImport() {
    return this.currentUploadCitationJobMetadata
      ? this.currentUploadCitationJobMetadata.failed
      : 0;
  }

  get documentDetail() {
    return useDocumentsState.call(this).documentDetail;
  }

  get documentId() {
    return +useRouteParams.call(this).documentId;
  }

  get projectId() {
    return +useRouteParams.call(this).projectId;
  }

  @Watch('dropFiles')
  @isTruthy
  public async onFilesAdded(file: File) {
    const isTooLarge = file.size > 1e7;
    if (isTooLarge) {
      DialogProgrammatic.alert({
        message: `The file "${file.name}" is too large.`,
        onConfirm: () => {
          this.dropFiles = null;
        }
      });
      return;
    }

    const fileName = (file as File).name;

    // Check if the file name contains special characters
    const format: RegExp = /[~`!#$%\^&*+=\\[\]\\';,/{}|\\"<>\?]/g;
    if (format.test(fileName)) {
      DialogProgrammatic.alert({
        message: `The file "${file.name}" contains special characters`,
        onConfirm: () => {
          this.dropFiles = null;
        }
      });
      return;
    }
    const workerJob = await this.uploadCitation({
      projectId: this.projectId,
      revisionId: this.revisionId,
      file
    });
    handleCitationUploading.call(this, workerJob);
  }

  // cleanup previous state on mounted
  public mounted() {
    if (
      !this.processingUploadedCitation.value &&
      this.currentUploadCitationJob
    ) {
      this.markWorkerJobAsClosed(this.currentUploadCitationJob);
    }
  }

  public destroyed() {
    if (this.processingUploadedCitation.value) {
      Toast.open({
        queue: true,
        message: `Citation import is running in the background`
      });
    }
  }
}
