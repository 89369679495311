

















import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SuggestedDocumentCard from '@/components/editor/SectionEditor/SuggestedDocumentCard.vue';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';

@Component({ components: { SuggestedDocumentCard } })
export default class EsAlignerFormSearchResultItem extends mixins(
  ScreenSizeMixin
) {
  @Prop() public document!: DocumentIndexingPayload;
  @Prop() public selected!: boolean;
}
