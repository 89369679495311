import { ModuleTree } from 'vuex';
import { eventLogsState } from './event-logs.state';
import { eventLogsActions } from './event-logs.actions';
import { eventLogsMutations } from './event-logs.mutations';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: eventLogsState,
  actions: eventLogsActions,
  mutations: eventLogsMutations
} as ModuleTree<RootState>;
