


















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import BasePagination from '@/components/base/BasePagination.vue';
import { AddWkExportEventLogJobPayload } from '@/jbi-shared/types/wk-export.types.ts';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { DATE_TIME_FORMAT, DATE_FORMAT_CUSTOM } from '@/utils/date.util.ts';
import BaseTable from '@/components/base/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { RootState } from '@/store/store';

@Component({
  components: {
    BaseTable,
    BaseLoading,
    BasePagination
  }
})
export default class WkResourceExportsHistoryItems extends mixins(
  PaginationMixin
) {
  @Prop() public items!: AddWkExportEventLogJobPayload;
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  @State(
    ({ documents }: RootState) => documents.apiState.getAllWkExports.success
  )
  public getAllWkExportsSuccess!: boolean;

  public formatDateTime(s: string) {
    return dayjs(s).format(DATE_TIME_FORMAT);
  }

  public formatStartEndDate(s: string) {
    const d = new Date(
      new Date(s).toLocaleString('en-US', { timeZone: 'Australia/Adelaide' })
    );

    return dayjs()
      .set('year', d.getFullYear())
      .set('month', d.getMonth())
      .set('date', d.getDate())
      .format(DATE_FORMAT_CUSTOM);
  }
}
