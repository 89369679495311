













































































































































































import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import {
  searchConditions,
  SearchCriterion,
  searchProps
} from '@/store/types/search.types';
import { useDocumentsState } from '@/utils/store.util';
import AssigneeSearch from '@/views/Search/components/AssigneeSearch.vue';
import MeshTagSearch from '@/views/Search/components/MeshTagSearch.vue';
import SearchTerm from '@/views/Search/components/SearchTerm.vue';
import Component, { mixins } from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';

import { StringInputOption } from '@/jbi-shared/types/form.types';
import OptionsMixin from '../../../components/form/options.mixin';
import { CplusDocumentTypeDisplayName } from '@/jbi-shared/types/document.types';
import InactiveGreyedMultiSelect from './InactiveGreyedMultiSelect.vue';

@Component({
  components: {
    BaseMultiSelect,
    AssigneeSearch,
    SearchTerm,
    MeshTagSearch,
    InactiveGreyedMultiSelect
  }
})
export default class SearchBox extends mixins(OptionsMixin) {
  @PropSync('isAdvance', { type: Boolean })
  public syncIsAdvance!: boolean;
  @PropSync('text', { type: String })
  public syncText!: string;
  @PropSync('documentTypes', { type: Array })
  public syncDocumentTypes!: { name: string; id: string }[];
  @PropSync('hasCriteria', { type: Boolean })
  public syncHasCriteria!: boolean;
  @PropSync('documentId', { type: String })
  public syncDocumentId!: string;
  @PropSync('publicationYearFrom', { type: String })
  public syncPublicationYearFrom!: string;
  @PropSync('publicationYearTo', { type: String })
  public syncPublicationYearTo!: string;
  @PropSync('assignedUsers', { type: Array })
  public syncAssignedUsers!: string[];
  @PropSync('revisionStatuses', { type: Array })
  public syncRevisionStatuses!: string[];
  @PropSync('legacyFilter')
  public syncLegacyFilter!: StringInputOption;
  @PropSync('nodes', { type: Array })
  public syncNodes!: string[];
  @PropSync('meshTags', { type: Array })
  public syncMeshTags!: string[];
  @Prop(Array)
  public authors!: string;
  @Prop(Array)
  public criteria!: SearchCriterion[];

  public MeshTagSearchComponentKey: number = Math.floor(
    100000 + Math.random() * 900000
  );

  get getCriteria() {
    return this.criteria;
  }

  public handleClear() {
    this.$emit('clear');
    this.MeshTagSearchComponentKey++;
  }

  public handleSwitch() {
    this.syncIsAdvance = !this.syncIsAdvance;
    this.$emit('switch');
  }

  public handleSubmit() {
    this.$emit('search');
  }

  public handleAddCriterion() {
    this.criteria.push({
      props: searchProps,
      condition: searchConditions[0],
      keyword: ''
    });
  }

  get isLoading(): boolean {
    return useDocumentsState.call(this).apiState.searchDocument.loading;
  }

  get isSearchingEvidenceSummary(): boolean {
    return Boolean(
      this.syncDocumentTypes.find(
        (element) =>
          element.name === CplusDocumentTypeDisplayName.EvidenceSummary
      )
    );
  }

  public onChangeDocumentTypes() {
    if (!this.isSearchingEvidenceSummary) {
      this.syncHasCriteria = false;
    }
  }
}
