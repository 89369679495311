































import { Component, Prop } from 'vue-property-decorator';
import SectionTitleEditor from '@/components/editor/SectionEditor/SectionTitleEditor.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { textToOps } from '@/utils/quill-delta.util';
import dayjs from 'dayjs';
import { DateOrString } from '@/jbi-shared/types/full-document-revision-object.types';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  PendingDocumentError,
  PendingSectionError
} from '@/jbi-shared/types/document.types';
import SectionError from './SectionError.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  components: {
    SectionTitleEditor,
    SectionError
  }
})
export default class DateSectionEditor extends mixins(ViewModeMixin) {
  @Prop(String) public value!: DateOrString;
  @Prop(String) public timezone!: string;
  @Prop(String) public title!: string;
  @Prop(Boolean) public disabled!: boolean;
  @Prop({
    default: () => {
      return false;
    }
  })
  public isInvalidDate!: boolean;
  @Prop(Date)
  public minDate!: Date;
  @Prop({
    default: () => {
      return new Date();
    }
  })
  public maxDate!: Date;

  get sectionTitle() {
    return textToOps(this.title);
  }

  get dayjs() {
    return dayjs;
  }

  public dateToIsoDate(d: Date) {
    return dayjs(d).format().split('T').shift();
  }

  public dateToDisplayedDate(d: Date) {
    return dayjs(d).tz(this.timezone).format('D MMMM YYYY');
  }

  get dateValue() {
    if (!this.value || this.isInvalidDate) {
      return;
    }
    if (dayjs(this.value).isValid()) {
      return dayjs(this.value).toDate();
    }
    return;
  }

  get pendingDateError(): PendingDocumentError[] {
    return this.isInvalidDate
      ? [
          {
            code: PendingSectionError.SECTION_INVALID_DATE,
            message: `Invalid ${this.title}`
          }
        ]
      : [];
  }
}
