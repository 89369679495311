























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { NavBarLinks } from '../types/navbar.types';
@Component({})
export default class CplusBaseNav extends Vue {
  @Prop({ type: Array })
  public links!: NavBarLinks[];

  get navBarLinks(): NavBarLinks[] {
    // @ts-ignore
    return this.links || this.$root.sidebarLinks || [];
  }

  // public mounted() {
  //   // force b-autocomplete to use right aligned icon
  //   // @ts-ignore
  //   let elem = (this.$refs.autocomplete.$el as Element).querySelector(
  //     '.has-icons-left',
  //   )!;
  //   elem.className = elem.className.replace(
  //     'has-icons-left',
  //     'has-icons-right',
  //   );
  //   // @ts-ignore
  //   elem = (this.$refs.autocomplete.$el as Element).querySelector('.is-left')!;
  //   elem.className = elem.className.replace('is-left', 'is-right');
  // }
}
