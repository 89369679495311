import { axios } from './axios';
import { ExchangeGrantForTokenRequestPayload } from '@/store/modules/auth/types/auth.types';

export const authApi = {
  async exchangeGrantForToken(payload: ExchangeGrantForTokenRequestPayload) {
    return axios.post('/oauth/token', {
      ...payload
    });
  },
  async signOut() {
    return axios.delete('sessions');
  },
  async checkSessionHealth() {
    return axios.get('sessions/health');
  },
  async getJaasAccountDetails() {
    return axios.get('jaas/account/details');
  },
  async getMe() {
    return axios.get('me');
  }
};
