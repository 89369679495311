import Vue from 'vue';

export const useRouter = function(this: Vue) {
  return this.$router;
};

export const useRoute = function(this: Vue) {
  return this.$router.currentRoute;
};

export const useRouteParams = function(this: Vue) {
  return useRoute.call(this).params;
};

export const useDocumentId = function(this: Vue) {
  return Number(useRouteParams.call(this).documentId);
};
