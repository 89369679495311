import {
  defaultApiState,
  PaginatedApiResponse
} from '@/store/types/general.types';
import {
  DocumentsState,
  DocumentDetail,
  UpdateRecommendedPracticeRequestPayload,
  UpdateEvidenceSummaryRequestPayload,
  UpdateRevisionNameRequestPayload,
  RemoveRevisionCitationRequestPayload,
  RestoreRevisionPayload,
  RevisionsOfDocument,
  InviteDocumentParticipantsResponsePayload,
  CheckPermissionResponsePayload,
  GetDocumentParticipantsResponsePayload,
  MeshTree,
  UpdateDocAssigneeResponsePayload,
  GetMyAssignedDocumentsResponsePayload,
  GetRevisionCitationsRequestPayload
} from './types/documents.types';
import { MeshDto } from '@/jbi-shared/util/mesh.util';
import { AxiosError } from 'axios';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { PublishDocumentPayload } from '@/store/modules/projects/types/projects.types';
import { Keyword } from '@/jbi-shared/types/document.types';
import { ConvertedLegacyDocumentPayload } from '@/jbi-shared/types/legacy-document.types';
import { OhsAsset } from './types/ohs.types';
import { AddWkExportEventLogJobPayload } from '@/jbi-shared/types/wk-export.types';
import { Citation } from './types/citations.types';

export const documentsMutations = {
  /*
   ** getDocumentDetail
   */
  GET_DOCUMENT_DETAIL_LOADING(state: DocumentsState) {
    state.apiState.getDocumentDetail = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_DOCUMENT_DETAIL_SUCCESS(state: DocumentsState, payload: DocumentDetail) {
    state.apiState.getDocumentDetail = {
      ...defaultApiState,
      success: true
    };
    state.documentDetail = payload;

    state.documentDetail.revision.sections.textSections = state.documentDetail.revision.sections.textSections.map(
      (textSection) => {
        textSection.sectionValue =
          typeof textSection.sectionValue === 'string'
            ? JSON.parse(textSection.sectionValue)
            : textSection.sectionValue;
        return textSection;
      }
    );
  },
  GET_DOCUMENT_DETAIL_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getDocumentDetail = {
      ...defaultApiState,
      error
    };
  },
  DOCUMENT_DETAIL_RESET(state: DocumentsState, error: AxiosError) {
    state.apiState.getDocumentDetail = {
      ...defaultApiState
    };
    state.documentDetail = undefined;
  },
  /*
   ** updateDocument
   */
  UPDATE_DOCUMENT_LOADING(state: DocumentsState) {
    state.apiState.updateDocument = {
      ...defaultApiState,
      loading: true
    };
    state.createdDocument = undefined;
  },
  UPDATE_DOCUMENT_SUCCESS(
    state: DocumentsState,
    requestPayload:
      | UpdateRecommendedPracticeRequestPayload
      | UpdateEvidenceSummaryRequestPayload
  ) {
    state.apiState.updateDocument = {
      ...defaultApiState,
      success: true
    };
    if (state.documentDetail) {
      // update current state
      state.documentDetail! = {
        ...state.documentDetail!,
        document: {
          ...state.documentDetail!.document,
          title:
            requestPayload.document?.title ||
            state.documentDetail!.document.title
        }
      };
    }
  },
  UPDATE_DOCUMENT_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.updateDocument = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** deleteDocument
   */
  DELETE_DOCUMENT_LOADING(state: DocumentsState) {
    state.apiState.deleteDocument = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_DOCUMENT_SUCCESS(state: DocumentsState) {
    state.apiState.deleteDocument = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_DOCUMENT_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.deleteDocument = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getDocumentByRevisionId
   */
  GET_DOCUMENT_BY_REVISION_ID_LOADING(state: DocumentsState) {
    state.apiState.getDocumentByRevisionId = {
      ...defaultApiState,
      loading: true
    };
    state.documentByRevisionId = undefined;
  },
  GET_DOCUMENT_BY_REVISION_ID_SUCCESS(
    state: DocumentsState,
    payload: DocumentDetail
  ) {
    state.apiState.getDocumentByRevisionId = {
      ...defaultApiState,
      success: true
    };
    state.documentByRevisionId = payload;
  },
  GET_DOCUMENT_BY_REVISION_ID_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getDocumentByRevisionId = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getDocumentByVersionId
   */
  GET_DOCUMENT_BY_VERSION_ID_LOADING(state: DocumentsState) {
    state.apiState.getDocumentByVersionId = {
      ...defaultApiState,
      loading: true
    };
    state.documentByVersionId = undefined;
  },
  GET_DOCUMENT_BY_VERSION_ID_SUCCESS(
    state: DocumentsState,
    payload: DocumentDetail
  ) {
    state.documentByVersionId = payload;
    state.apiState.getDocumentByVersionId = {
      ...defaultApiState,
      success: true
    };
  },
  GET_DOCUMENT_BY_VERSION_ID_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getDocumentByVersionId = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getDocumentByPublicationId
   */
  GET_DOCUMENT_BY_PUBLICATION_ID_LOADING(state: DocumentsState) {
    state.apiState.getDocumentByPublicationId = {
      ...defaultApiState,
      loading: true
    };
    state.documentByPublicationId = undefined;
  },
  GET_DOCUMENT_BY_PUBLICATION_ID_SUCCESS(
    state: DocumentsState,
    payload: DocumentDetail
  ) {
    state.apiState.getDocumentByPublicationId = {
      ...defaultApiState,
      success: true
    };
    state.documentByPublicationId = payload;
  },
  GET_DOCUMENT_BY_PUBLICATION_ID_ERROR(state: DocumentsState, error: Error) {
    state.apiState.getDocumentByPublicationId = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** updateRevisionName
   */
  UPDATE_REVISION_NAME_LOADING(state: DocumentsState) {
    state.apiState.updateRevisionName = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_REVISION_NAME_SUCCESS(
    state: DocumentsState,
    requestPayload: UpdateRevisionNameRequestPayload
  ) {
    state.apiState.updateRevisionName = {
      ...defaultApiState,
      success: true
    };
    // update current state
    const index = state.revisionsByDocumentId!.revisions.findIndex(
      ({ id }) => id === requestPayload.revisionId
    );
    const revision = state.revisionsByDocumentId!.revisions[index];
    state.revisionsByDocumentId!.revisions[index] = {
      ...revision,
      name: requestPayload.name
    };
    state.revisionsByDocumentId = {
      ...state.revisionsByDocumentId!
    };
  },
  UPDATE_REVISION_NAME_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.updateRevisionName = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** removeRevisionCitation
   */
  REMOVE_REVISION_CITATION_LOADING(state: DocumentsState) {
    state.apiState.removeRevisionCitation = {
      ...defaultApiState,
      loading: true
    };
  },
  REMOVE_REVISION_CITATION_SUCCESS(
    state: DocumentsState,
    requestPayload: RemoveRevisionCitationRequestPayload
  ) {
    state.apiState.removeRevisionCitation = {
      ...defaultApiState,
      success: true
    };
  },
  REMOVE_REVISION_CITATION_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.removeRevisionCitation = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getRevisionCitation
   */
  GET_REVISION_CITATIONS_LOADING(state: DocumentsState) {
    state.apiState.getRevisionCitations = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_REVISION_CITATIONS_SUCCESS(state: DocumentsState, payload: Citation[]) {
    state.apiState.getRevisionCitations = {
      ...defaultApiState,
      success: true
    };
    state.citationsOfRevision = payload;
  },
  GET_REVISION_CITATIONS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getRevisionCitations = {
      ...defaultApiState,
      error
    };
  },

  /**
   * export Search Results
   */
  EXPORT_SEARCH_LOADING(state: DocumentsState) {
    state.apiState.exportSearchResult = {
      ...defaultApiState,
      loading: true
    };
  },
  EXPORT_SEARCH_SUCCESS(state: DocumentsState) {
    state.apiState.exportSearchResult = {
      ...defaultApiState,

      success: true
    };
  },
  EXPORT_SEARCH_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.exportSearchResult = {
      ...defaultApiState,
      error
    };
  },
  EXPORT_SEARCH_RESET(state: DocumentsState) {
    state.apiState.exportSearchResult = {
      ...defaultApiState
    };
  },
  /*
   ** exportDocument
   */
  EXPORT_DOC_LOADING(state: DocumentsState) {
    state.apiState.exportDocument = {
      ...defaultApiState,
      loading: true
    };
  },
  EXPORT_DOC_SUCCESS(state: DocumentsState) {
    state.apiState.exportDocument = {
      ...defaultApiState,
      success: true
    };
  },
  EXPORT_DOC_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.exportDocument = {
      ...defaultApiState,
      error
    };
  },
  EXPORT_DOC_RESET(state: DocumentsState) {
    state.apiState.exportDocument = {
      ...defaultApiState
    };
  },
  /*
   ** restoreRevision
   */
  RESTORE_REVISION_LOADING(state: DocumentsState) {
    state.apiState.restoreRevision = {
      ...defaultApiState,
      loading: true
    };
  },
  RESTORE_REVISION_SUCCESS(
    state: DocumentsState,
    requestPayload: RestoreRevisionPayload
  ) {
    state.apiState.restoreRevision = {
      ...defaultApiState,
      success: true
    };
  },
  RESTORE_REVISION_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.restoreRevision = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getRevision
   */
  GET_REVISIONS_LOADING(state: DocumentsState) {
    state.apiState.getRevisionsByDocumentId = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_REVISIONS_SUCCESS(state: DocumentsState, data: RevisionsOfDocument) {
    state.apiState.getRevisionsByDocumentId = {
      ...defaultApiState,
      success: true
    };
    state.revisionsByDocumentId = data;
  },
  GET_REVISIONS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getRevisionsByDocumentId = {
      ...defaultApiState,
      error
    };
  },
  GET_REVISIONS_RESET(state: DocumentsState) {
    state.revisionsByDocumentId = undefined;
  },
  GET_ALL_LEGACY_DOC_VERSIONS_BY_BASE_DOCUMENT_ID_LOADING(
    state: DocumentsState
  ) {
    state.apiState.getAllLegacyDocVersionsByBaseDocumentId = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ALL_LEGACY_DOC_VERSIONS_BY_BASE_DOCUMENT_ID_SUCCESS(
    state: DocumentsState,
    data: ConvertedLegacyDocumentPayload[]
  ) {
    state.apiState.getAllLegacyDocVersionsByBaseDocumentId = {
      ...defaultApiState,
      success: true
    };
    state.allLegacyDocVersionsByBaseDocumentId = data;
  },
  GET_ALL_LEGACY_DOC_VERSIONS_BY_BASE_DOCUMENT_ID_ERROR(
    state: DocumentsState,
    error: AxiosError
  ) {
    state.apiState.getAllLegacyDocVersionsByBaseDocumentId = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getLegacyContentByBaseDocumentId
   */
  GET_LEGACY_CONTENT_BY_BASE_DOCUMENT_ID_LOADING(state: DocumentsState) {
    state.apiState.getLegacyContentByBaseDocumentId = {
      ...defaultApiState,
      loading: true
    };
    state.legacyContentByBaseDocumentId = undefined;
  },
  GET_LEGACY_CONTENT_BY_BASE_DOCUMENT_ID_SUCCESS(
    state: DocumentsState,
    data: ConvertedLegacyDocumentPayload
  ) {
    state.apiState.getLegacyContentByBaseDocumentId = {
      ...defaultApiState,
      success: true
    };
    state.legacyContentByBaseDocumentId = data;
  },
  GET_LEGACY_CONTENT_BY_BASE_DOCUMENT_ID_ERROR(
    state: DocumentsState,
    error: AxiosError
  ) {
    state.apiState.getLegacyContentByBaseDocumentId = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** searchDocument
   */
  SEARCH_DOCUMENT_LOADING(state: DocumentsState) {
    state.apiState.searchDocument = {
      ...defaultApiState,
      loading: true
    };
  },
  SEARCH_DOCUMENT_SUCCESS(
    state: DocumentsState,
    data: PaginatedApiResponse<DocumentIndexingPayload>
  ) {
    state.apiState.searchDocument = {
      ...defaultApiState,
      success: true
    };
    state.documentSearchResult = data;
  },
  SEARCH_DOCUMENT_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.searchDocument = {
      ...defaultApiState,
      error
    };
  },
  SEARCH_DOCUMENT_RESET(
    state: DocumentsState,
    data: PaginatedApiResponse<DocumentIndexingPayload>
  ) {
    state.apiState.searchDocument = {
      ...defaultApiState
    };
    state.documentSearchResult = undefined;
  },
  /*
   ** searchRelatedDocuments
   */
  SEARCH_RELATED_DOCUMENTS_LOADING(state: DocumentsState) {
    state.apiState.searchRelatedDocuments = {
      ...defaultApiState,
      loading: true
    };
  },
  SEARCH_RELATED_DOCUMENTS_SUCCESS(
    state: DocumentsState,
    data: DocumentIndexingPayload[]
  ) {
    state.apiState.searchRelatedDocuments = {
      ...defaultApiState,
      success: true
    };
    state.relatedDocumentSearchResult = data;
  },
  SEARCH_RELATED_DOCUMENTS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.searchRelatedDocuments = {
      ...defaultApiState,
      error
    };
  },
  SEARCH_NEW_RELATED_DOCUMENTS_LOADING(state: DocumentsState) {
    state.apiState.searchNewRelatedDocuments = {
      ...defaultApiState,
      loading: true
    };
  },
  SEARCH_NEW_RELATED_DOCUMENTS_SUCCESS(
    state: DocumentsState,
    data: DocumentIndexingPayload[]
  ) {
    state.apiState.searchNewRelatedDocuments = {
      ...defaultApiState,
      success: true
    };
    state.relatedDocumentSearchResult = data;
  },
  SEARCH_NEW_RELATED_DOCUMENTS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.searchNewRelatedDocuments = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** searchEsForRpRealignment
   */
  SEARCH_ES_FOR_REALIGNMENT_LOADING(state: DocumentsState) {
    state.apiState.searchEsForRpRealignment = {
      ...defaultApiState,
      loading: true
    };
  },
  SEARCH_ES_FOR_REALIGNMENT_SUCCESS(
    state: DocumentsState,
    data: DocumentIndexingPayload[]
  ) {
    state.apiState.searchEsForRpRealignment = {
      ...defaultApiState,
      success: true
    };
    state.esForAlignmentSearchResult = data;
  },
  SEARCH_ES_FOR_REALIGNMENT_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.searchEsForRpRealignment = {
      ...defaultApiState,
      error
    };
  },
  UPDATE_PUBLISH_STATUS_LOADING(state: DocumentsState) {
    state.apiState.updatePublishStatus = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PUBLISH_STATUS_SUCCESS(
    state: DocumentsState,
    payload: PublishDocumentPayload
  ) {
    state.apiState.updatePublishStatus = {
      ...defaultApiState,
      success: true
    };
    // TODO: fetch publishedAt from backend
    state.documentDetail!.revision.publishedAt = payload.publishStatus
      ? String(new Date())
      : undefined;
  },
  UPDATE_PUBLISH_STATUS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.updatePublishStatus = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** get user documents
   */
  GET_ALL_DOCUMENTS_LOADING(state: DocumentsState) {
    state.apiState.getUserDocuments = {
      ...defaultApiState,
      loading: true
    };
    state.userDocuments = undefined;
  },
  GET_ALL_DOCUMENTS_SUCCESS(
    state: DocumentsState,
    payload: PaginatedApiResponse<DocumentIndexingPayload>
  ) {
    state.apiState.getUserDocuments = {
      ...defaultApiState,
      success: true
    };
    state.userDocuments = payload;
  },
  GET_ALL_DOCUMENTS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getUserDocuments = {
      ...defaultApiState,
      error
    };
  },
  GET_CREATED_DOCUMENTS_LOADING(state: DocumentsState) {
    state.apiState.getUserCreatedDocuments = {
      ...defaultApiState,
      loading: true
    };
    state.userCreatedDocuments = undefined;
  },
  GET_CREATED_DOCUMENTS_SUCCESS(
    state: DocumentsState,
    payload: PaginatedApiResponse<DocumentIndexingPayload>
  ) {
    state.apiState.getUserCreatedDocuments = {
      ...defaultApiState,
      success: true
    };
    state.userCreatedDocuments = payload;
  },
  GET_CREATED_DOCUMENTS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getUserCreatedDocuments = {
      ...defaultApiState,
      error
    };
  },
  GET_ASSIGNED_DOCUMENTS_LOADING(state: DocumentsState) {
    state.apiState.getUserAssignedDocuments = {
      ...defaultApiState,
      loading: true
    };
    state.userAssignedDocuments = undefined;
  },
  GET_ASSIGNED_DOCUMENTS_SUCCESS(
    state: DocumentsState,
    payload: PaginatedApiResponse<DocumentIndexingPayload>
  ) {
    state.apiState.getUserAssignedDocuments = {
      ...defaultApiState,
      success: true
    };
    state.userAssignedDocuments = payload;
  },
  GET_ASSIGNED_DOCUMENTS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getUserAssignedDocuments = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** exportWkResource
   */
  EXPORT_WK_RESOURCE_LOADING(state: DocumentsState) {
    state.apiState.exportWkResource = {
      ...defaultApiState,
      loading: true
    };
  },
  EXPORT_WK_RESOURCE_SUCCESS(state: DocumentsState) {
    state.apiState.exportWkResource = {
      ...defaultApiState,
      success: true
    };
  },
  EXPORT_WK_RESOURCE_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.exportWkResource = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getWkExportStatus
   */
  GET_WK_EXPORT_STATUS_LOADING(state: DocumentsState) {
    state.apiState.getWkExportStatus = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_WK_EXPORT_STATUS_SUCCESS(state: DocumentsState) {
    state.apiState.getWkExportStatus = {
      ...defaultApiState,
      success: true
    };
  },
  GET_WK_EXPORT_STATUS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getWkExportStatus = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getWkExportDetails
   */
  GET_WK_EXPORT_DETAILS_LOADING(state: DocumentsState) {
    state.apiState.getWkExportDetails = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_WK_EXPORT_DETAILS_SUCCESS(state: DocumentsState) {
    state.apiState.getWkExportDetails = {
      ...defaultApiState,
      success: true
    };
  },
  GET_WK_EXPORT_DETAILS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getWkExportDetails = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getAllWkExportStatus
   */
  GET_ALL_WK_EXPORT_LOADING(state: DocumentsState) {
    state.apiState.getAllWkExports = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ALL_WK_EXPORT_SUCCESS(
    state: DocumentsState,
    payload: PaginatedApiResponse<AddWkExportEventLogJobPayload>
  ) {
    state.apiState.getAllWkExports = {
      ...defaultApiState,
      success: true
    };

    state.allWkResourceExports = payload;
  },
  GET_ALL_WK_EXPORT_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getAllWkExports = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** Participant Roles
   */
  PARTICIPANT_ROLES_LOADING(state: DocumentsState) {
    state.apiState.exportWkResource = {
      ...defaultApiState,
      loading: true
    };
  },
  PARTICIPANT_ROLES_SUCCESS(state: DocumentsState, payload: string[]) {
    state.participantRoles = payload;
    state.apiState.exportWkResource = {
      ...defaultApiState,
      success: true
    };
  },
  PARTICIPANT_ROLES_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.exportWkResource = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** Participant Invite
   */
  DOCUMENT_PARTICIPANT_INVITE_LOADING(state: DocumentsState) {
    state.apiState.inviteDocumentUserAsParticipant = {
      ...defaultApiState,
      loading: true
    };
  },
  DOCUMENT_PARTICIPANT_INVITE_SUCCESS(
    state: DocumentsState,
    payload: InviteDocumentParticipantsResponsePayload
  ) {
    state.invitedDocumentUser = payload;
    state.apiState.inviteDocumentUserAsParticipant = {
      ...defaultApiState,
      success: true
    };
  },
  DOCUMENT_PARTICIPANT_INVITE_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.inviteDocumentUserAsParticipant = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** Participant Invite update
   */
  UPDATE_DOCUMENT_PARTICIPANT_LOADING(state: DocumentsState) {
    state.apiState.updateDocumentParticipant = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_DOCUMENT_PARTICIPANT_SUCCESS(
    state: DocumentsState,
    payload: InviteDocumentParticipantsResponsePayload
  ) {
    state.invitedDocumentUser = payload;
    state.apiState.updateDocumentParticipant = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_DOCUMENT_PARTICIPANT_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.updateDocumentParticipant = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** Participant Invite update
   */
  DELETE_DOCUMENT_PARTICIPANT_LOADING(state: DocumentsState) {
    state.apiState.deleteDocumentParticipant = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_DOCUMENT_PARTICIPANT_SUCCESS(
    state: DocumentsState,
    payload: InviteDocumentParticipantsResponsePayload
  ) {
    state.invitedDocumentUser = payload;
    state.apiState.deleteDocumentParticipant = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_DOCUMENT_PARTICIPANT_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.deleteDocumentParticipant = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** Update Document Assignee
   */
  UPDATE_DOC_ASSIGNEE_LOADING(state: DocumentsState) {
    state.apiState.updateDocAssignee = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_DOC_ASSIGNEE_SUCCESS(
    state: DocumentsState,
    payload: UpdateDocAssigneeResponsePayload
  ) {
    if (state.documentDetail) {
      state.documentDetail.document.assignee = payload.assignee || undefined;
      state.documentDetail = { ...state.documentDetail };
    }
    if (state.documentByRevisionId) {
      state.documentByRevisionId.document.assignee =
        payload.assignee || undefined;
      state.documentByRevisionId = { ...state.documentByRevisionId };
    }
    state.apiState.updateDocAssignee = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_DOC_ASSIGNEE_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.updateDocAssignee = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** searchKeywordTag
   */
  SEARCH_KEYWORD_TAG_LOADING(state: DocumentsState) {
    state.apiState.searchKeywordTag = {
      ...defaultApiState,
      loading: true
    };
  },
  SEARCH_KEYWORD_TAG_SUCCESS(state: DocumentsState, payload: Keyword[]) {
    state.apiState.searchKeywordTag = {
      ...defaultApiState,
      success: true
    };
    state.keywordTags = payload;
  },
  SEARCH_KEYWORD_TAG_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.searchKeywordTag = {
      ...defaultApiState,
      error
    };
  },
  /*
   ** getMeshTree from MESH our db (csv based)
   */
  GET_MESH_TREE_LOADING(state: DocumentsState) {
    state.apiState.getMeshTree = {
      ...defaultApiState,
      loading: true
    };
    state.meshTree = undefined;
  },
  GET_MESH_TREE_SUCCESS(state: DocumentsState, payload: MeshTree) {
    state.apiState.getMeshTree = {
      ...defaultApiState,
      success: true
    };
    state.meshTree = Object.freeze(payload);
  },
  GET_MESH_TREE_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getMeshTree = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** getRdfMeshTags from MESH API
   */
  GET_RDF_MESH_TAGS_LOADING(state: DocumentsState) {
    state.apiState.getRdfMeshTags = {
      ...defaultApiState,
      loading: true
    };
    state.rdfMeshTags = undefined;
  },
  GET_RDF_MESH_TAGS_SUCCESS(state: DocumentsState, payload: MeshDto[]) {
    state.apiState.getRdfMeshTags = {
      ...defaultApiState,
      success: true
    };
    state.rdfMeshTags = payload;
  },
  GET_RDF_MESH_TAGS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getRdfMeshTags = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** get Ohs Assets
   */
  GET_OHS_ASSETS_LOADING(state: DocumentsState) {
    state.apiState.getOhsAssets = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_OHS_ASSETS_SUCCESS(state: DocumentsState, payload: OhsAsset[]) {
    state.apiState.getOhsAssets = {
      ...defaultApiState,
      success: true
    };
    state.ohsAssets = payload;
  },
  GET_OHS_ASSETS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getOhsAssets = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** Get Participants
   */
  GET_DOCUMENT_PARTICIPANTS_LOADING(state: DocumentsState) {
    state.apiState.getDocumentParticipants = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_DOCUMENT_PARTICIPANTS_SUCCESS(
    state: DocumentsState,
    payload: GetDocumentParticipantsResponsePayload
  ) {
    state.documentParticipants = payload;
    state.apiState.getDocumentParticipants = {
      ...defaultApiState,
      success: true
    };
  },
  GET_DOCUMENT_PARTICIPANTS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getDocumentParticipants = {
      ...defaultApiState,
      error
    };
  },
  /*
   **  Get Assigned Work
   */
  GET_MY_ASSIGNED_DOCS_LOADING(state: DocumentsState) {
    state.apiState.getMyAssignedDocuments = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_MY_ASSIGNED_DOCS_SUCCESS(
    state: DocumentsState,
    payload: GetMyAssignedDocumentsResponsePayload
  ) {
    state.apiState.getMyAssignedDocuments = {
      ...defaultApiState,
      success: true
    };
    state.myAssignedDocuments = payload;
  },
  GET_MY_ASSIGNED_DOCS_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getMyAssignedDocuments = {
      ...defaultApiState,
      error
    };
  },
  /*
   **  getAlignedEsByEsId
   */
  GET_ALIGNED_ES_BY_ES_ID_LOADING(state: DocumentsState) {
    state.apiState.getAlignedEsByEsId = {
      ...defaultApiState,
      loading: true
    };
    state.alignedEsByEsId = undefined;
  },
  GET_ALIGNED_ES_BY_ES_ID_SUCCESS(
    state: DocumentsState,
    result: DocumentIndexingPayload
  ) {
    state.apiState.getAlignedEsByEsId = {
      ...defaultApiState,
      success: true
    };
    state.alignedEsByEsId = result;
  },
  GET_ALIGNED_ES_BY_ES_ID_ERROR(state: DocumentsState, error: AxiosError) {
    state.apiState.getAlignedEsByEsId = {
      ...defaultApiState,
      error
    };
  }
};
