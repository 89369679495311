





















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import {
  CplusDocumentTypeDisplayName,
  CplusDocumentType,
  CplusDocumentTypeShortHand
} from '@/jbi-shared/types/document.types';
import { ProjectIndexingPayload } from '@/jbi-shared/types/search.types';
import { DocumentExportType } from '@/store/modules/documents/types/documents.types';
import { throttle } from 'lodash';
import truncate from 'truncate';
import SearchResultAssigneeInput from '@/components/listings/search/SearchResultAssigneeInput.vue';
import { saveAs } from 'file-saver';
import filenamify from 'filenamify';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';

export interface SearchResultListItemParam {
  key: string | number;
  title: string;
  body: string;
  documentType: CplusDocumentType;
  date: string;
  primaryResearchNode: string;
  secondaryResearchNodes: string[];
  plainTags?: string[];
  meshTags?: string[];
  collaborators: string[];
  owner: string | null;
  uniquePublicationId: string;
  to: Partial<Route>;
  isExporting: boolean;
  assigneeFullName: string;
  isLegacy: boolean;
  legacyBaseDocumentId?: number;
  isLatestLegacyVersion?: boolean;
  resourceUrl?: string;
  publishedAt: string;
  archivedAt: string;
  publicationStatus: RevisionPublicationStatus;
}

@Component({
  components: { SearchResultAssigneeInput }
})
export default class SearchResultListItem extends Vue {
  public isShowAllTags: boolean = false;
  @Prop() public project!: ProjectIndexingPayload;

  @Prop(String) public readonly title!: string;
  @Prop(String) public readonly body!: string;
  @Prop(String) public readonly documentType!: CplusDocumentType;
  @Prop(String) public readonly owner!: string;
  @Prop(String) public readonly uniquePublicationId!: string;
  @Prop(String) public readonly date!: string;
  @Prop({ type: Array })
  public readonly collaborators!: string[];
  @Prop(String) public readonly primaryResearchNode!: string;
  @Prop(Array) public readonly secondaryResearchNodes!: string[];
  @Prop(Array) public readonly plainTags!: string[];
  @Prop(Array) public readonly meshTags!: string[];
  @Prop(Object) public readonly to!: Route;
  @Prop(Boolean) public readonly isExporting!: boolean;
  @Prop(String) public readonly assigneeFullName!: string;
  @Prop(Boolean) public readonly isLegacy!: boolean;
  @Prop(Number) public readonly legacyBaseDocumentId!: number;
  @Prop(Boolean) public readonly isLatestLegacyVersion!: boolean;
  @Prop(Number)
  public readonly sourceLegacyDocumentLatestBaseDocumentId!: number;
  @Prop(Number)
  public readonly sourceLegacyDocumentGlobalBaseDocumentId!: number;
  @Prop(Number) public readonly migratedFromLegacyBaseDocumentId!: number;
  @Prop(Number) public readonly migratedToNewDocumentId!: number;
  @Prop(String) public readonly resourceUrl!: string;
  @Prop(String) public readonly publishedAt!: string;
  @Prop(String) public readonly archivedAt!: string;
  @Prop(String) public readonly publicationStatus!: RevisionPublicationStatus;

  get docTypeDisplayName(): string {
    return CplusDocumentTypeDisplayName[this.documentType].toUpperCase();
  }

  get allTags(): string[] {
    const formattedMeshTags = this.meshTags.map((tag) => {
      return '[MeSH] ' + tag;
    });
    return this.plainTags.concat(formattedMeshTags);
  }

  get truncatedTags(): string[] {
    return this.allTags.length > 3 ? this.allTags.slice(0, 3) : this.allTags;
  }

  get remainingTagCount(): number {
    return this.allTags.length > 3 ? this.allTags.length - 3 : 0;
  }

  public toggleShowTags(): void {
    this.isShowAllTags = !this.isShowAllTags;
  }

  public getSecondaryResearchNodes() {
    if (this.secondaryResearchNodes) {
      return this.secondaryResearchNodes.join(', ');
    }
    return '';
  }

  get handleDownloadPdf(): (...args: any) => any {
    // handle BPIS
    if (this.resourceUrl) {
      return () => {
        let fileName = this.resourceUrl.split('/').pop() || this.resourceUrl;
        fileName = filenamify(fileName);
        saveAs(this.resourceUrl, fileName);
      };
    }
    return throttle(
      () => this.$emit('export', 'pdf' as DocumentExportType),
      2000,
      { trailing: false }
    );
  }

  get formattedBody(): string {
    return truncate(this.body, 450);
  }

  get documentId(): number {
    return +this.to.params.documentId;
  }

  get assigneeName(): string {
    return this.assigneeFullName;
  }

  get isMigratableLegacy() {
    return (
      this.isLegacy &&
      this.isLatestLegacyVersion &&
      !this.migratedToNewDocumentId
    );
  }
  get isAlreadyMigratedLegacy() {
    return this.isLegacy && this.migratedToNewDocumentId;
  }
  get isViewOnlyLegacy() {
    return this.isLegacy && !this.isLatestLegacyVersion;
  }

  get isPublished() {
    return this.publicationStatus === RevisionPublicationStatus.Published;
  }
  get isArchived() {
    return this.publicationStatus === RevisionPublicationStatus.Archived;
  }

  get docTypeShortHand() {
    return CplusDocumentTypeShortHand[this.documentType];
  }

  get migratedToNewDocumentDocDisplayId() {
    if (!this.migratedToNewDocumentId) {
      return;
    }
    return `JBI-${this.docTypeShortHand}-${this.migratedToNewDocumentId}`;
  }

  get migratedToNewDocumentRoute() {
    if (!this.migratedToNewDocumentId) {
      return;
    }
    return {
      name: 'previewLatestPublishedVersion',
      params: {
        documentId: this.migratedToNewDocumentId
      }
    };
  }

  get startMigrationRoute() {
    return {
      name: 'legacy-document-migration',
      params: {
        latestBaseDocumentId: this.legacyBaseDocumentId
      }
    };
  }

  get isEsOrRp() {
    return [
      CplusDocumentType.EvidenceSummary,
      CplusDocumentType.RecommendedPractice
    ].includes(this.documentType);
  }
}
