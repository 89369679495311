


































import { Component, Vue } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
@Component({
  components: {
    Container
  }
})
export default class EditorAssignedProjectsPage extends Vue {}
