














































import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { AddNoteToProjectRequestPayload } from '@/store/modules/projects/types/projects.types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({})
export default class AddNoteDialog extends Vue {
  @Prop()
  public project!: ProjectListItemParam;

  @Action('projects/addNote')
  public addNote!: (payload: AddNoteToProjectRequestPayload) => void;

  @Action('projects/updateNote')
  public updateNote!: (payload: AddNoteToProjectRequestPayload) => void;

  public note = '';

  public handleAddNote(modal: any) {
    if (this.project.note) {
      this.updateNote({
        projectId: this.project.projectId,
        note: this.note
      });
    } else {
      this.addNote({
        projectId: this.project.projectId,
        note: this.note
      });
    }
    modal.close();
  }
}
