






































































































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import CitationForm from '@/views/DocumentEditor/components/tab/citations/CitationForm.vue';
import { ValidationObserver } from 'vee-validate';
import { Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import { CitationTypes } from '@/jbi-shared/types/citation.fields';
import {
  PendingCitationData,
  PendingDocumentReferences
} from '@/jbi-shared/types/document.types';
import { getCitationReferenceNumber } from '@/utils/citation-reference.util';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { StagingEditorComputedValuesMixin } from '../../mixins/staging-editor-computed-values.mixin';
import { StagingEditorStateMixin } from '../../mixins/staging-editor-state.mixin';
import ArchiveCitationValidation from './ArchiveCitationValidation.vue';
import StagingCitationForm from '../../../DocumentEditor/components/tab/citations/StagingCitationForm.vue';

@Component({
  components: {
    BaseModal,
    StagingCitationForm,
    ValidationObserver,
    ArchiveCitationValidation
  }
})
export default class StagingCitationCard extends mixins(
  StagingEditorComputedValuesMixin,
  StagingEditorStateMixin
) {
  public draggable = true;

  @Prop(Object) public citation!: PendingCitationData;
  @Prop(String) public citationReferenceNumber?: string;
  @Prop(Boolean) public activeCitation!: boolean;
  @Prop({ type: Boolean, default: false }) public showArchiveButton!: boolean;
  public isVisible: boolean = false;

  @State((state: RootState) => state.projects.apiState.editCitation.loading)
  public editCitationLoading!: boolean;

  get CitationTypes() {
    return CitationTypes;
  }

  get getAuthor(): string {
    return this.citation.content.author
      ? this.citation.content.author.split(' ').shift()
      : '';
  }

  get citationHasAuthor(): boolean {
    return !!this.citation.content.author;
  }

  get citationHasIssueDate(): boolean {
    return !!this.citation.content.issueDate;
  }

  get citationHasTitle(): boolean {
    return !!this.citation.content.title;
  }

  get referencedCitationIds() {
    return this.uniqUsedCitationIds.map((id) => parseInt(id));
  }

  public saveCitation(citationFields: {
    type: CitationTypes;
    [s: string]: string | any;
  }) {
    const newCitation = {
      id: citationFields.id,
      content: {
        plainText: citationFields.plainText,
        type: citationFields.type
      }
    };
    this.$emit('editCitation', newCitation);
  }

  public toggleCitationStatus() {
    if (this.activeCitation) {
      const citationObject:
        | PendingCitationData
        | undefined = this.activeCitations.find(
        (data) => data.id.toString() === this.citation.id.toString()
      );
      if (
        citationObject &&
        !this.usedCitationIds.includes(citationObject.id.toString())
      ) {
        this.activeCitations = this.activeCitations.filter((el) => {
          return el.id !== citationObject.id;
        });
        this.archivedCitations.push(citationObject);
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: ArchiveCitationValidation,
          hasModalCard: true,
          trapFocus: true,
          events: {
            'archive-anyway': () => {
              if (citationObject) {
                this.activeCitations = this.activeCitations.filter((el) => {
                  return el.id !== citationObject.id;
                });
                this.archivedCitations.push(citationObject);
              }
            }
          }
        });
      }
    } else {
      const citationObject:
        | PendingCitationData
        | undefined = this.archivedCitations.find(
        (data) => data.id.toString() === this.citation.id.toString()
      );
      this.archivedCitations = this.archivedCitations.filter((el) => {
        if (citationObject) {
          return el.id !== citationObject.id;
        }
      });
      if (citationObject) {
        this.activeCitations.push(citationObject);
      }
    }
  }
}
