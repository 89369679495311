import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EditorStateMixin } from './editor-state.mixin';
import { EditorComputedValuesMixin } from './editor-computed-values.mixin';
import { last } from 'lodash';

@Component({})
export class EditorUndoRedoChildMixin extends mixins(
  EditorStateMixin,
  EditorComputedValuesMixin
) {
  public undo() {
    if (!this.contentUndoStack.length) {
      return;
    }
    // remove current, push to redo stack
    this.contentRedoStack.push(this.contentUndoStack.pop()!);
    // get content to be restored
    const content = JSON.parse(last(this.contentUndoStack)!);
    this.isUndoingOrRedoing = true;
    this.contentKeys.forEach((key) => {
      // @ts-ignore
      this[key] = content[key];
    });
    // issue - the page scrolls during undo/redo document changes
    // cause - the page scroll happens due to rerendering of the editor component
    // solution - to resolve the scrolling issue, we get the current position before rerendering of the component
    // and set it back to the current position once the component is rerendered
    const element: HTMLElement | null = document.getElementById(
      'editor-container'
    );
    let currentPosition: number = 0;
    if (element) {
      currentPosition = element.scrollTop;
    }
    this.reRenderEditor();
    this.$nextTick().then(() => {
      this.isUndoingOrRedoing = false;
      if (element) {
        element.scrollTop = currentPosition;
      }
    });
  }

  public redo() {
    if (!this.contentRedoStack.length) {
      return;
    }
    this.contentUndoStack.push(last(this.contentRedoStack)!);
    const content = JSON.parse(this.contentRedoStack.pop()!);
    this.isUndoingOrRedoing = true;
    this.contentKeys.forEach((key) => {
      // @ts-ignore
      this[key] = content[key];
    });
    // issue - the page scrolls during undo/redo document changes
    // cause - the page scroll happens due to rerendering of the editor component
    // solution -to resolve the scrolling issue, we get the current position before rerendering of the component
    // and set it back to the current position once the component is rerendered
    const element: HTMLElement | null = document.getElementById(
      'editor-container'
    );
    let currentPosition: number = 0;
    if (element) {
      currentPosition = element.scrollTop;
    }
    this.reRenderEditor();
    this.$nextTick().then(() => {
      this.isUndoingOrRedoing = false;
      if (element) {
        element.scrollTop = currentPosition;
      }
    });
  }

  get canUndo() {
    return !this.isSaving && this.contentUndoStack.length > 1;
  }

  get canRedo() {
    return !this.isSaving && !!this.contentRedoStack.length;
  }
}
