








import { Prop, Vue, Component } from 'vue-property-decorator';

@Component({})
export default class EmptyListingBox extends Vue {
  @Prop(String) public text!: string;
}
