var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",class:_vm.$style.container},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading}}),_c('div',{class:_vm.$style.title},[_c('h1',{class:_vm.$style.versionHeader},[_vm._v("Version History")])]),_c('div',{class:[_vm.$style.versionHistoryList]},[_c('ul',[_vm._l((_vm.archivedAndPublishedRevisions),function(revision,i){return _c('VersionHistoryItem',{key:revision.id,attrs:{"createdAt":revision.createdAt,"revisionName":revision.name,"documentType":revision.projectDocument.documentType,"revisionId":revision.id,"userName":_vm.getUserFullNameFromRevision(revision),"index":i,"assignmentContractUrl":_vm.getContractUrlFromRevision(revision),"assignmentContractFileName":_vm.getContractFileNameFromRevision(revision),"tdrUrl":_vm.getTdrUrlFromRevision(revision),"tdrFileName":_vm.getTdrFileName(revision),"publicationId":revision.publicationId,"isSelected":_vm.revisions.length - i === +_vm.$route.params.versionId,"isEditing":revision.id === _vm.editingRevisionId,"publicationStatus":_vm.getPublicationStatus(revision),"publicationDate":_vm.getPublicationDate(revision),"publicationUser":_vm.getPublicationUserFullName(revision),"archived-note":revision.revisionPublicationStatus.archivedNote,"withdrawn-note":revision.revisionPublicationStatus.withdrawnNote,"latestVersionId":_vm.latestVersionId,"versionId":revision.versionId},on:{"select":function($event){_vm.$router.push({
            name: 'document-versions',
            params: {
              projectId: String(_vm.projectId),
              documentId: String(_vm.documentId),
              versionId: String(revision.versionId)
            }
          }),
            _vm.fetchCitations(revision.id)},"startEditing":function($event){return _vm.$emit('update:editingRevisionId', revision.id)},"doneEditing":function($event){_vm.$emit('update:editingRevisionId', null);
          _vm.$emit('update:revision', { id: revision.id, name: $event });},"restore":function (revisionName) { return _vm.$emit('restore', revision.id, revisionName); }}})}),_vm._l((_vm.legacyDocumentsFromRevisions),function(legacyDocument,i){return _c('VersionHistoryItem',{key:legacyDocument.baseDocumentId,attrs:{"createdAt":legacyDocument.originalDocument.DateCreated,"documentType":legacyDocument.convertedDocument.document.documentType,"legacyBaseDocumentId":legacyDocument.baseDocumentId,"index":_vm.revisions.length + i,"uniquePublicationId":("JBI" + (legacyDocument.baseDocumentId)),"isSelected":+legacyDocument.baseDocumentId === +_vm.$route.params.baseDocumentId,"isLegacy":true},on:{"select":function($event){_vm.$router.push({
            name: 'document-versions-legacy',
            params: {
              projectId: String(_vm.projectId),
              documentId: String(_vm.documentId),
              baseDocumentId: String(legacyDocument.baseDocumentId)
            }
          })}}})})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }