import { get as _get } from 'lodash';
import { opsToText } from './quill-delta.util';
import { Author } from '../jbi-shared/types/author.types';
import { Criterion } from '../jbi-shared/types/criterions.types';
import { FullDocumentRevisionObject } from '../jbi-shared/types/full-document-revision-object.types';

export interface ValidateDocumentPayload {
  documentTitle: string;
  searchDate: string;
  textSections: FullDocumentRevisionObject['revision']['sections']['textSections'];
  subSections: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  criterions?: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  authors?: FullDocumentRevisionObject['revision']['sections']['authorSubSections'];
}

export const validateEsFields = (payload: ValidateDocumentPayload) => {
  let validation: object = {};
  let index = 0;

  if (!payload.documentTitle) {
    validation = {
      ...validation,
      ...{ [index++]: { message: 'Document Title' } },
    };
  }

  if (!payload.searchDate) {
    validation = {
      ...validation,
      ...{ [index++]: { message: 'Search Date' } },
    };
  }

  if (payload.authors && payload.authors.length < 1) {
    validation = {
      ...validation,
      ...{ [index++]: { message: 'Authors Section' } },
    };
  }

  if (payload.subSections && payload.subSections.length < 1) {
    validation = {
      ...validation,
      ...{ [index++]: { message: 'Best Practice Recommendations' } },
    };
  }

  if (payload.textSections.length > 0) {
    payload.textSections.map((textSection) => {
      const { sectionTitle, sectionValue } = textSection;

      const getSectionValue = sectionValue
        ? opsToText(sectionValue).trim()
        : undefined;
      const getSectionTitle = sectionTitle
        ? opsToText(sectionTitle).trim()
        : undefined;

      if (!getSectionTitle) {
        validation = {
          ...validation,
          ...{ [index++]: { message: 'Section Title' } },
        };
      }

      if (!getSectionValue) {
        validation = {
          ...validation,
          ...{
            [index++]: {
              message: `${getSectionTitle ? getSectionTitle : 'Section Text'}`,
            },
          },
        };
      }
    });
  }

  return { error: validation };
};

export const validateRpFields = (payload: ValidateDocumentPayload) => {
  let validation: object = {};
  let index = 0;
  if (!payload.documentTitle) {
    validation = {
      ...validation,
      ...{ [index++]: { message: 'Document Title' } },
    };
  }
  if (!payload.searchDate) {
    validation = {
      ...validation,
      ...{ [index++]: { message: 'Search Date' } },
    };
  }
  // May or may not be needed, commented for now.
  //   if (payload.ohsAssetIds.length < 1) {
  //     validation = {
  //       ...validation,
  //       ...{ [index++]: { message: 'OHS Section' } },
  //     };
  //   }
  if (payload.textSections.length > 0) {
    payload.textSections.map((textSection) => {
      const { sectionTitle, sectionValue } = textSection;

      const getSectionValue = sectionValue
        ? opsToText(sectionValue).trim()
        : undefined;
      const getSectionTitle = sectionTitle
        ? opsToText(sectionTitle).trim()
        : undefined;

      if (!getSectionTitle) {
        validation = {
          ...validation,
          ...{ [index++]: { message: 'Section Title' } },
        };
      }

      if (!getSectionValue) {
        validation = {
          ...validation,
          ...{
            [index++]: {
              message: `${getSectionTitle ? getSectionTitle : 'Section Text'}`,
            },
          },
        };
      }
    });
  }

  return { error: validation };
};
