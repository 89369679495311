







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SearchFilterCheckboxes from '@/views/Search/components/SearchFilterCheckboxes.vue';
import SearchFilterRangeInput from '@/views/Search/components/SearchFilterRangeInput.vue';
import {
  SearchCheckboxOptions,
  StringInputOption
} from '@/jbi-shared/types/form.types';
import { useDocumentsState } from '@/utils/store.util';
import { SearchFacetsParams } from '@/store/modules/documents/types/documents.types';
import { startCase } from 'lodash';
import { Facets } from '../SearchPage.vue';

@Component({ components: { SearchFilterCheckboxes, SearchFilterRangeInput } })
export default class SearchFacets extends Vue {
  public facets: SearchFacetsParams = {
    authors: [],
    documentTypes: [],
    nodes: [],
    assignedUsers: []
  };
  @Prop(Array) public authors!: SearchCheckboxOptions[];

  @Prop(Array) public documentTypes!: SearchCheckboxOptions[];

  @Prop(Array) public nodes!: SearchCheckboxOptions[];

  @Prop(Array) public assignedUsers!: SearchCheckboxOptions[];

  @Prop(Object) public selectedFacets!: Facets;

  private get authorOptions(): StringInputOption[] {
    // Use to get existing facet
    this.facets.authors = this.selectedFacets.facetAuthors;

    return this.authors.map((author) => ({
      ...author,
      name: startCase(author.name || '')
    }));
  }

  private get documentTypeOptions(): SearchCheckboxOptions[] {
    // Use to get existing facet
    this.facets.documentTypes = this.selectedFacets.facetDocumentTypes;

    return this.documentTypes;
  }

  get assignedUserOptions(): SearchCheckboxOptions[] {
    // Use to get existing facet
    this.facets.assignedUsers = this.selectedFacets.facetAssignedUsers;

    return this.assignedUsers;
  }

  get nodeOptions(): SearchCheckboxOptions[] {
    // Use to get existing facet
    this.facets.nodes = this.selectedFacets.facetNodes;

    return this.nodes;
  }

  get isLoading(): boolean {
    return useDocumentsState.call(this).apiState.searchDocument.loading;
  }

  public transformCamelCaseToSentenceCase(optionName: string) {
    return optionName.replace(/([A-Z])/g, ' $1').trim();
  }

  public handleFacets(value: any) {
    this.$emit('input', this.facets);
  }

  // @Watch('facets', { deep: true })
  // public watchFilters(value: any) {
  //   this.$emit('input', this.facets);
  // }
}
