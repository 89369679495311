import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { Component, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { StagingAuthorData } from '@/jbi-shared/types/author.types';
import {
  PendingCitationData,
  PendingDocumentReferences,
  PendingTextSectionData
} from '@/jbi-shared/types/document.types';
import { PendingCriterionData } from '@/jbi-shared/types/criterions.types';
import { Citation } from '@/store/modules/documents/types/citations.types';
export type StagingSidebarTabOption = 'sections' | 'citations';

interface StagingEditorState {
  documentData: {
    dirtyTextSections: PendingTextSectionData[];
    dirtyBprs: FullDocumentRevisionObject['revision']['sections']['bprSection'];
    dirtyDocumentTitle: string;
    dirtySearchDate: string;
    unformattedAuthors: string[];
    dirtyAuthors: StagingAuthorData[];
    unformattedCriterions: string[];
    dirtyCriterions: PendingCriterionData[];
    dirtyOhs: number[];
    dirtyRelatedDocs: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];
    dirtyTagMaps?: DirtyTagMap[];
    dirtyReferences?: PendingDocumentReferences;
    dirtyCitations: PendingCitationData[];
    activeCitations: PendingCitationData[];
    archivedCitations: PendingCitationData[];
    tdrUri: string | undefined;
  };
  publishing: boolean;
  activeSidebarTab: StagingSidebarTabOption;
  citationSearch: string;
}

const initialState: StagingEditorState = {
  documentData: {
    dirtyTextSections: [],
    dirtyBprs: [],
    dirtyDocumentTitle: '',
    dirtySearchDate: '',
    unformattedAuthors: [],
    dirtyAuthors: [],
    unformattedCriterions: [],
    dirtyCriterions: [],
    dirtyOhs: [],
    dirtyRelatedDocs: [],
    dirtyTagMaps: [],
    dirtyReferences: { citationIds: [] },
    dirtyCitations: [],
    activeCitations: [],
    archivedCitations: [],
    tdrUri: undefined
  },
  publishing: false,
  activeSidebarTab: 'sections',
  citationSearch: ''
};

const editorState = Vue.observable(cloneDeep(initialState));

@Component<StagingEditorStateMixin>({})
export class StagingEditorStateMixin extends Vue implements StagingEditorState {
  get tdrUri() {
    return editorState.documentData.tdrUri;
  }

  set tdrUri(uri) {
    editorState.documentData.tdrUri = uri;
  }

  get documentData() {
    return editorState.documentData;
  }

  get publishing() {
    return editorState.publishing;
  }
  set publishing(v) {
    editorState.publishing = v;
  }

  get activeSidebarTab() {
    return editorState.activeSidebarTab;
  }
  set activeSidebarTab(v) {
    editorState.activeSidebarTab = v;
  }

  get citationSearch() {
    return editorState.citationSearch;
  }
  set citationSearch(v) {
    editorState.citationSearch = v;
  }

  get dirtyTextSections() {
    return editorState.documentData.dirtyTextSections;
  }

  set dirtyTextSections(pendingTextSectionData) {
    editorState.documentData.dirtyTextSections = pendingTextSectionData;
  }

  get dirtyBprs() {
    return editorState.documentData.dirtyBprs;
  }

  set dirtyBprs(v) {
    editorState.documentData.dirtyBprs = v;
  }

  get dirtyDocumentTitle() {
    return editorState.documentData.dirtyDocumentTitle;
  }

  set dirtyDocumentTitle(v) {
    editorState.documentData.dirtyDocumentTitle = v;
  }

  get dirtySearchDate() {
    return editorState.documentData.dirtySearchDate;
  }

  set dirtySearchDate(v) {
    editorState.documentData.dirtySearchDate = v;
  }

  get dirtyAuthors() {
    return editorState.documentData.dirtyAuthors;
  }

  set dirtyAuthors(v) {
    editorState.documentData.dirtyAuthors = v;
  }

  get unformattedAuthors() {
    return editorState.documentData.unformattedAuthors;
  }

  set unformattedAuthors(v) {
    editorState.documentData.unformattedAuthors = v;
  }

  get unformattedCriterions() {
    return editorState.documentData.unformattedCriterions;
  }

  set unformattedCriterions(v) {
    editorState.documentData.unformattedCriterions = v;
  }

  get dirtyCriterions() {
    return editorState.documentData.dirtyCriterions;
  }

  set dirtyCriterions(v) {
    editorState.documentData.dirtyCriterions = v;
  }

  get dirtyOhs() {
    return editorState.documentData.dirtyOhs;
  }

  set dirtyOhs(v) {
    editorState.documentData.dirtyOhs = v;
  }

  get dirtyReferences() {
    return editorState.documentData.dirtyReferences;
  }

  set dirtyReferences(v) {
    editorState.documentData.dirtyReferences = v;
  }

  get dirtyCitations() {
    return editorState.documentData.dirtyCitations;
  }

  set dirtyCitations(v) {
    editorState.documentData.dirtyCitations = v;
  }

  get activeCitations() {
    return editorState.documentData.activeCitations;
  }

  set activeCitations(v) {
    editorState.documentData.activeCitations = v;
  }

  get archivedCitations() {
    return editorState.documentData.archivedCitations;
  }

  set archivedCitations(v) {
    editorState.documentData.archivedCitations = v;
  }

  get dirtyRelatedDocs() {
    return editorState.documentData.dirtyRelatedDocs;
  }

  set dirtyRelatedDocs(relatedDocRecords) {
    // Map isValid flag
    relatedDocRecords.map((relatedDocRecord) => {
      relatedDocRecord.relatedDocs.isValid = Boolean(
        relatedDocRecord.relatedDocs.id
      );
    });
    editorState.documentData.dirtyRelatedDocs = relatedDocRecords;
  }

  get dirtyTagMaps() {
    return editorState.documentData.dirtyTagMaps;
  }

  set dirtyTagMaps(v) {
    editorState.documentData.dirtyTagMaps = v;
  }
}
