import { Citation } from '@/store/modules/documents/types/citations.types';
import { PendingCitationData } from '@/jbi-shared/types/document.types';

export const getCitationReferenceNumber = (
  citation: Citation | PendingCitationData,
  sortedReferenceCitationIds: string[] | number[],
): number | undefined => {
  const citationRefIndex = sortedReferenceCitationIds.findIndex(
    (value: string | number) => {
      return value.toString() === citation.id.toString();
    },
  );
  if (typeof citationRefIndex !== 'undefined' && citationRefIndex !== -1) {
    // Return reference number starting from 1 instead of array index starting from 0
    return citationRefIndex + 1;
  } else {
    return undefined;
  }
};
