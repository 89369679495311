
















import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import PaginatedDocumentList from './PaginatedDocumentList.vue';
import { GetUIStateMachineParentComponentMixin } from '@/utils/ui-state-machine-parent.mixin';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { CplusDocumentStatus } from '@/jbi-shared/types/document-status.types';
import { MyDocumentsMixin } from '../mixins/my-documents.mixin';
import { RootState } from '@/store/store';

@Component({
  components: {
    PaginatedDocumentList
  }
})
export default class CreatedDocuments extends mixins(
  GetUIStateMachineParentComponentMixin(
    'documents/getUserCreatedDocuments',
    'documents.userCreatedDocuments'
  ),
  MyDocumentsMixin(
    'documents/getUserCreatedDocuments',
    'documents.userCreatedDocuments'
  )
) {
  @Prop(Boolean) public isActive!: boolean;
  @Prop(String) public type!: string;

  public perPage = 10;
  public page = 1;
  public filter: CplusDocumentType | string = '';
  public filterStatus: CplusDocumentStatus | string = '';

  get loading() {
    return (this.$store.state as RootState).documents.apiState
      .getUserCreatedDocuments.loading;
  }

  public handleGetDocuments() {
    if (!this.isActive) {
      return;
    }
    return this.getDocuments({
      type: this.type,
      perPage: this.perPage,
      page: this.page,
      revisionStatus: this.filterStatus || undefined,
      documentType: this.filter || undefined
    });
  }

  public updateRouteQuery() {
    this.$router.push({
      query: {
        tab: this.type,
        documentType: this.filter,
        documentStatus: this.filterStatus,
        page: String(this.page),
        perPage: String(this.perPage)
      }
    });
  }

  public handleFilterInput(value: any) {
    this.filter = value;
    this.page = 1;
    this.updateRouteQuery();
  }

  public handleFilterStatusInput(value: any) {
    this.filterStatus = value;
    this.page = 1;
    this.updateRouteQuery();
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    if (!this.isActive) {
      return;
    }

    const { page, perPage, documentType, documentStatus } = value;
    this.perPage = +perPage || 50;
    this.page = +page || 1;
    (this.filter = documentType || ''),
      (this.filterStatus = documentStatus || ''),
      this.handleGetDocuments();
  }
}
