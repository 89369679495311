












































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FilteredEditorPayload } from '@/store/modules/editors/types/editors.types';
import { EditorRole, EditorRoleDetails } from '@/jbi-shared/types/editor.types';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';

@Component({
  components: {}
})
export default class EditorFilterBox extends Vue {
  @Prop(Object) public filterParams!: FilteredEditorPayload;

  @State((state: RootState) => state?.editors?.editorRoles)
  public editorRoles!: EditorRoleDetails[] | undefined;
}
