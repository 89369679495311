import { OicPayload } from '../store/modules/auth/types/auth.types';
import {
  startCase as _startCase,
  get as _get,
  filter as _filter,
} from 'lodash';
import Vue from 'vue';
import { useAuthState } from './store.util';
import {
  DocumentIndexingPayload,
  ProjectDocumentPayload,
  ProjectIndexingPayload,
} from '@/jbi-shared/types/search.types';
import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';

export const getInitialFromName = (name: string) => {
  const arr = name
    .split(' ')
    .map((s) => s.trim())
    .filter(Boolean)
    .filter((s) => s !== 'null')
    .filter((s) => s !== 'undefined')
    .map((s) => s[0]);
  return `${arr[0] || ''}${arr[1] || ''}`.toUpperCase();
};

export const getDisplayName = (oicPayload: OicPayload | null) => {
  if (!oicPayload) {
    return '';
  }
  const { given_name, middle_name, name, family_name } = oicPayload;
  let result = `${given_name} ${middle_name} ${family_name}`;
  result = result.trim();
  // replace multiple spaces with a single space
  result = result.replace(/ +(?= )/g, '');
  result = _startCase(result);
  if (result) {
    return result;
  }
  result = name || '';
  result = result.trim();
  result = _startCase(result);
  if (result) {
    return result;
  }
  return 'NA';
};

export const currentUserIdOwner = function (
  this: Vue,
  oicPayload: OicPayload | null,
) {
  const currentUserOicPayload = _get(
    useAuthState.call(this).authInfo,
    'oicPayload',
  );
  const currentUserId = _get(currentUserOicPayload, 'sub');
  const userId = _get(oicPayload, 'sub');
  return currentUserId && userId && currentUserId === userId;
};

export const getProjectMainDocument = (project: ProjectIndexingPayload) => {
  let filteredDocument: ProjectDocumentPayload = _filter(project.documents, {
    documentType:
      CplusDocumentType.EvidenceSummary ||
      CplusDocumentType.BestPracticeInformationSheet,
  }).pop() as ProjectDocumentPayload;
  if (!filteredDocument) {
    filteredDocument = project.documents[0] as ProjectDocumentPayload;
  }
  return filteredDocument;
};

export const getProjectOwner = (project: ProjectIndexingPayload) => {
  const filteredDocument: ProjectDocumentPayload = getProjectMainDocument(
    project,
  );
  return _startCase(filteredDocument.documentOwner).trim();
};

export const projectHasRp = (project: ProjectListItemParam) => {
  const rp = project.documents.find(
    (doc) => doc.documentType === CplusDocumentType.RecommendedPractice,
  );
  return !!rp;
};
