































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import dayjs from 'dayjs';
import { get as _get } from 'lodash';
import { RootState } from '@/store/store';
import { useUserName } from '@/utils/user.util';
import { useDocumentsState } from '@/utils/store.util';
import { DATE_TIME_FORMAT } from '../../../utils/date.util';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '../../DocumentEditor/mixins/editor-common.mixin';

@Component({
  components: {}
})
export default class PreviewHeader extends mixins(EditorCommonMixin) {
  public modalIsOpened = false;

  get revisionDate(): string {
    if (!this.documentDetail) {
      return '';
    }
    return dayjs(this.documentDetail.revision.createdAt).format(
      DATE_TIME_FORMAT
    );
  }
}
