










import { Component, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { DocumentListItemParam } from '@/components/listings/documents/documentListItem.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';

@Component({
  subscriptions() {
    return {};
  }
})
export default class BasePaginatorHoc extends mixins(PaginationMixin) {
  @Prop({ type: Function, required: true })
  public component!: VueConstructor;
  @Prop() public items!: Array<DocumentListItemParam | any>;
  @Prop({ type: Array })
  public selectedItems: any[] | undefined;
  @Prop() public selectAll: boolean | undefined;
  @Prop() public totalCount!: number;

  get totalNumberOfPage(): number {
    return Math.ceil(this.totalCount / this.perPage);
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.totalCount, this.page * this.perPage);
  }

  get componentProps() {
    return {
      ...this.$props,
      ...this.$attrs,
      items: this.items,
      page: this.page,
      totalNumberOfPage: this.totalNumberOfPage,
      totalCount: this.totalCount,
      perPage: this.perPage,
      isFirstPage: this.page === 1,
      isLastPage: this.page === this.totalNumberOfPage,
      startItemIndex: this.startItemIndex,
      endItemIndex: this.endItemIndex
    };
  }

  public goToPage(page: number) {
    if (page > 0 && page <= this.totalNumberOfPage) {
      this.page = page;
      this.$emit('paginate', {
        perPage: this.perPage,
        page: this.page
      });
      return this.page;
    }
  }

  public updatePerPage(perPage: number) {
    this.page = 1;
    this.perPage = perPage;
    this.$emit('paginate', {
      perPage: this.perPage,
      page: this.page
    });
  }
}
