
















import { Component, Vue, Prop } from 'vue-property-decorator';
import BorderlessDropdown from '@/components/base/BorderlessDropdown.vue';
import { StringInputOption } from '@/jbi-shared/types/form.types';

@Component({ components: { BorderlessDropdown } })
export default class SearchResultHeader extends Vue {
  @Prop(Number) public count!: number;
  @Prop(String) public term!: string;
  @Prop(Array) public sortOptions!: StringInputOption[];
}
