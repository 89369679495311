

























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  Validator,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import { ResearchNodeEntity } from '@/store/modules/documents/types/documents.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { projectsApi } from '@/api/projects.api';
import { ImportResearchNodePayload } from '@/store/modules/documents/types/nodes.types';
import { useAction } from '@/utils/store.util';
import { Job } from 'bull';
import { useUserEmail } from '@/utils/user.util';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class ImportResearchNodeForm extends Vue {
  @Prop() value!: string;

  public selectedFile: File | null = null;
  dropZoneName: string = `dropZone_researchNode`;
  fileSizeLimit = 5000000;
  public form = {
    name: ''
  };

  @State((state: RootState) => state.projects.importedResearchNodes)
  public importedResearchNodes!: ResearchNodeEntity[];

  @State(
    (state: RootState) => state.projects.apiState.importResearchNodes.success
  )
  public importedResearchNodeSuccess!: boolean;

  @State(
    (state: RootState) => state.projects.apiState.importResearchNodes.loading
  )
  public importResearchNodeLoading!: boolean;

  @State(
    (state: RootState) => state.projects.apiState.importResearchNodes.error
  )
  public importResearchNodeError!: boolean;

  get importResearchNodes(): (
    payload: ImportResearchNodePayload
  ) => Promise<Job> {
    return useAction.call(this, 'projects/importResearchNodes');
  }

  public isUnique(name: string) {
    return projectsApi.validateResearchNodeByName(name).then((response) => {
      return response.data;
    });
  }

  public mounted() {
    Validator.extend(
      'unique',
      (value): Promise<boolean> => {
        return this.isUnique(value);
      }
    );
  }

  async processFile(selectedFile: File) {
    if (!selectedFile) {
      return;
    }

    if (selectedFile.size > this.fileSizeLimit) {
      Toast.open({
        message: `File has exceeded size limitation.`,
        type: 'is-danger',
        position: 'is-top'
      });
      return;
    }

    await this.importResearchNodes({
      email: useUserEmail.call(this) || '',
      file: this.selectedFile
    });
    this.onImportResearchNodeSuccess();
  }

  public onImportResearchNodeSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: this.form.name + ` nodes successfully imported`
    });
    this.$emit('close');
    this.$emit('refresh');
  }
}
