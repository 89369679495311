






















































































































































































import { Component, Prop } from 'vue-property-decorator';
import SectionValueEditor from '@/components/editor/SectionEditor/SectionValueEditor.vue';
import OhsSectionEditor from '@/components/editor/SectionEditor/OhsSectionEditor/OhsSectionEditor.vue';
import PublicationSection from '@/components/editor/SectionEditor/PublicationSection/PublicationSection.vue';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import {
  CplusDocumentType,
  TagEntityTypeEnum
} from '@/jbi-shared/types/document.types';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { getArchivedPublications, Publication } from '@/utils/publication.util';
import {
  formatAuthorForDisplay,
  getRelatedDocFromSourceEvidenceSummary,
  getSourceEvidenceSummaryFromDocument
} from '@/jbi-shared/util/document.utils';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { getSourceEvidenceSummaryIdFromDocument } from '@/jbi-shared/util/document.utils';
import { Action, State } from 'vuex-class';
import { RootState } from '../../store/store';
import { ApiState } from '../../store/types/general.types';

export interface EditorFocusStatus {
  type: EditorFocusElementType;
  id: number;
}

export enum EditorFocusElementType {
  textSectionTitle = 'textSectionTitle',
  textSectionValue = 'textSectionValue',
  bprValue = 'bprValue',
  docTitle = 'docTitle'
}

@Component({
  components: {
    SectionValueEditor,
    OhsSectionEditor,
    PublicationSection,
    TagsEditor,

    // Lazy loading import is needed to avoid Circular References Between Components issues:
    // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    SectionTitleEditor: () =>
      import('@/components/editor/SectionEditor/SectionTitleEditor.vue'),
    DateSectionEditor: () =>
      import('@/components/editor/SectionEditor/DateSectionEditor.vue'),
    AuthorSectionEditor: () =>
      import(
        '@/components/editor/SectionEditor/AuthorSectionEditor/AuthorSectionEditor.vue'
      ),
    BprSectionEditor: () =>
      import(
        '@/components/editor/SectionEditor/BprSectionEditor/BprSectionEditor.vue'
      ),
    CriterionSectionEditor: () =>
      import(
        '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionSectionEditor.vue'
      ),
    RelatedDocsSectionEditor: () =>
      import(
        '@/components/editor/SectionEditor/RelatedDocsSectionEditor/RelatedDocsSectionEditor.vue'
      ),
    RelatedRPDocsSectionEditor: () =>
      import(
        '@/components/editor/SectionEditor/RelatedRPDocsSectionEditor/RelatedRPDocsSectionEditor.vue'
      ),
    TextSectionEditor: () =>
      import('@/components/editor/SectionEditor/TextSectionEditor.vue')
  }
})
export default class Editor extends mixins(ViewModeMixin, EditorCommonMixin) {
  @Prop({ default: () => [] })
  public dirtySectionsTagMaps!: DirtyTagMap[];

  // @Getter('documents/revisionId') public revisionId!: number;

  public focusStatus: EditorFocusStatus | null = null;

  get relatedRPDocs() {
    if (this.documentDetail) {
      return this.documentDetail.document.relatedRPDocumentData || [];
    } else {
      return [];
    }
  }

  get CplusDocumentType() {
    return CplusDocumentType;
  }

  get bprSectionTitle() {
    return textToOps('Best Practice Recommendations');
  }

  get ohsSectionTitle() {
    return textToOps('Occupational Health and Safety Considerations');
  }

  get criterionSectionTitle() {
    return textToOps('Audit Criteria');
  }

  get textToOps() {
    return textToOps;
  }

  get opsToText() {
    return opsToText;
  }

  get EditorFocusElementType() {
    return EditorFocusElementType;
  }

  get archivedPublications(): Publication[] {
    return getArchivedPublications(this.documentDetail!.document);
  }

  get TagEntityTypeEnum() {
    return TagEntityTypeEnum;
  }

  get sourceEvidenceSummaryId() {
    return getSourceEvidenceSummaryIdFromDocument(this.documentDetail);
  }

  get sourceEvidenceSummaryLink():
    | FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0]
    | undefined {
    if (!this.sourceEvidenceSummaryId) {
      return;
    }
    const es = getSourceEvidenceSummaryFromDocument(this.documentDetail);
    if (!es) {
      return;
    }
    return getRelatedDocFromSourceEvidenceSummary(es);
  }

  get currentDocNodes() {
    return [
      this.documentDetail?.project.primaryResearchNode,
      ...(this.documentDetail?.project.researchNodes || [])
    ]
      .filter(Boolean)
      .map((n) => n?.name)
      .filter(Boolean) as string[];
  }

  get currentDocAuthors() {
    return (this.documentDetail?.revision.sections.authorSubSections || [])
      .map((d) => d.content)
      .map(formatAuthorForDisplay);
  }

  get currentDocumentId() {
    return this.documentDetail?.document.id;
  }

  public getKeyFromSection(section: TextSection) {
    // generate unique string for each text section
    return `${section.documentSectionId} ${section.tempId} ${
      section?.documentSectionId
    } ${section.sectionTitle.toString()}`;
  }

  public isFocused(id: number, type: EditorFocusElementType): boolean {
    return JSON.stringify({ type, id }) === JSON.stringify(this.focusStatus);
  }

  public focusOn(id: number, type: EditorFocusElementType): void {
    if (this.isFocused(id, type)) {
      return;
    }
    this.focusStatus = {
      id,
      type
    };
  }

  public blurOn(id: number, type: EditorFocusElementType): void {
    if (!this.isFocused(id, type)) {
      return;
    }
    this.focusStatus = null;
  }

  public getTextSecProps(textSection: Partial<TextSection>) {
    return {
      textSection,
      focusStatus: this.focusStatus,
      focusOn: this.focusOn,
      blurOn: this.blurOn,
      dirtySectionsTagMaps: this.dirtySectionsTagMaps,
      dirtyTagMaps: this.dirtyTagMaps
    };
  }
  public getTextSecListeners(i: number) {
    return {
      'update:sectionTitle': ($event: any) =>
        this.$emit('update:sectionTitle', { index: i, value: $event }),

      'update:sectionValue': ($event: any) =>
        this.$emit('update:sectionValue', { index: i, value: $event }),

      'update:dirtyTagMaps': ($event: any) => (this.dirtyTagMaps = $event)
    };
  }
  public getTextSecIndex(ts: TextSection) {
    return this.dirtyTextSections.findIndex(
      ({ documentSectionId, tempId }) =>
        (documentSectionId !== 0 &&
          ts.documentSectionId === documentSectionId) ||
        (typeof tempId !== 'undefined' && ts.tempId === tempId)
    );
  }
}
