import { Machine } from 'xstate';

export enum UI_STATES {
  INITIALIZE = 'initialize',
  LOADING = 'loading',
  ERRORSCREEN = 'errorscreen',
  FETCHSUCCESS_WITHDATA = 'fetchsuccess',
  FETCHSUCCESS_NODATA = 'fetchempty',
}

export enum UI_EVENTS {
  FETCH = 'FETCH',
  FETCHERR = 'FETCHERR',
  FETCHDATA = 'FETCHDATA',
  FETCHNODATA = 'FETCHNODATA',
}

export const UIStateMachine = Machine({
  initial: UI_STATES.INITIALIZE,
  states: {
    [UI_STATES.INITIALIZE]: {
      on: { FETCH: UI_STATES.LOADING },
    },
    [UI_STATES.LOADING]: {
      on: {
        FETCHERR: UI_STATES.ERRORSCREEN,
        FETCHDATA: UI_STATES.FETCHSUCCESS_WITHDATA,
        FETCHNODATA: UI_STATES.FETCHSUCCESS_NODATA,
      },
    },
    [UI_STATES.ERRORSCREEN]: {},
    [UI_STATES.FETCHSUCCESS_WITHDATA]: {},
    [UI_STATES.FETCHSUCCESS_NODATA]: {},
  },
});
