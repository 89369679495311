




























































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { difference as _difference } from 'lodash';
import IconSelector from '@/components/editor/SectionEditor/SectionEditorToolbar__IconSelector.vue';
import Quill from 'quill';

export enum QuillToolbarFormat {
  size = 'size',
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  ordered = 'ordered',
  bullet = 'bullet',
  align = 'align',
  subscript = 'subscript',
  superscript = 'superscript',
  icons = 'icons'
}

@Component({ components: { IconSelector } })
export default class SectionEditorToolbar extends Vue {
  @Prop({ type: String, required: true })
  public readonly toolbarElemId!: string;

  @Prop(Boolean)
  public readonly toolbarSticky!: boolean;

  @Prop(Array)
  public readonly formats!: QuillToolbarFormat[];

  get QuillToolbarFormat() {
    return QuillToolbarFormat;
  }

  public allowFormat(...formatsCheck: QuillToolbarFormat[]) {
    if (!this.formats) {
      return true;
    }
    return _difference(formatsCheck, this.formats).length === 0;
  }
}
