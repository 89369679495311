





























import { DocumentExportType } from '@/store/modules/documents/types/documents.types';
import { useGetter } from '@/utils/store.util';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface BtnData {
  ext: string;
  label: string;
  callback: () => any;
  disabled?: boolean;
}

@Component({})
export default class ProjectEditorHeaderDocExportBtn extends Vue {
  @Prop(Boolean) public disabled!: boolean;
  @Prop(String) public title!: string;

  get exportBtns(): BtnData[] {
    return [
      {
        ext: '.pdf',
        label: 'Export as PDF',
        callback: () => this.$emit('export', 'pdf' as DocumentExportType)
      },
      {
        ext: '.docx',
        label: 'Export as MS Word',
        callback: () => this.$emit('export', 'docx' as DocumentExportType)
      }
    ];
  }

  get isExporting() {
    return useGetter.call(this, 'documents/isExporting');
  }
}
