


















import { Component, Prop, Vue } from 'vue-property-decorator';
import SearchResultList from '@/components/listings/search/SearchResultList.vue';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { useDocumentsState } from '@/utils/store.util';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedSearchResultList extends mixins(PaginationMixin) {
  @Prop(Array) public items!: string[];
  @Prop(Number) public totalCount!: number;

  get SearchResultList() {
    return SearchResultList;
  }
}
