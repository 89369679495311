import { CplusDocumentStatus } from '../../document-status.types';
import {
  CplusDocumentType,
  PendingBprSectionData,
  PendingCitationData,
  PendingDocumentReferences,
  PendingTextSectionData,
} from '../../document.types';
import { StagingAuthorData } from '../../author.types';
import {
  RelatedDocRecord,
  Tag,
} from '../../full-document-revision-object.types';
import { PendingCriterionData } from '../../criterions.types';

export interface ImportedEditorDocumentPayload {
  document?: {
    id: number;
    documentType: CplusDocumentType;
  };
  docType: string;
  name: string;
  project: {
    id: number;
    projectTitle: string;
  };
  signedUrl: string;
  storageUri: string;
  storageUrl: string;
  trackRevisionStatus: boolean;
}

export interface UpdatePendingDocumentRequestPayload {
  pendingDocumentId: number;
  projectId: number;
  documentId: number;
  searchDate?: string;
  documentTitle?: string;
  authorSection?: StagingAuthorData[];
  textSections?: PendingTextSectionData[];
  bprSection?: PendingBprSectionData[];
  criterionSection?: PendingCriterionData[];
  referenceSection?: PendingDocumentReferences;
  relatedDocSection?: RelatedDocRecord[];
  ohsSection?: any[];
  citations?: PendingCitationData[];
  activeCitations?: PendingCitationData[];
  archivedCitations?: PendingCitationData[];
  tags?: Tag[];
  tdrUri?: string;
  isValid?: boolean;
}

export interface FetchPendingDocCreationHistoryRequestPayload {
  page?: number;
  perPage?: number;
  createdAt?: string[];
  documentId?: string;
  selectedStatus?: PendingDocCreationJobStatus;
  localTimezone?: string;
}

export interface ProjectDocument {
  id: number;
  title: string;
  searchDate: null;
  documentType: CplusDocumentType;
  projectId: number;
  createdAt: string;
  updatedAt: string;
  assigneeId?: number;
  sourceLegacyDocumentLatestBaseDocumentId?: number;
  sourceLegacyDocumentGlobalBaseDocumentId?: number;
  documentStatus: CplusDocumentStatus;
}

export enum PendingDocCreationJobStatus {
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
}
