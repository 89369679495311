














































import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import DropdownMultiselect from '@/jbi-shared/vue-components/DropdownMultiselect.vue';
import {
  SearchCriterion,
  searchConditions,
  searchProps,
  StringIdName
} from '@/store/types/search.types';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({ components: { BaseMultiSelect, DropdownMultiselect } })
export default class SearchTerm extends Vue {
  @Prop() public criteria!: SearchCriterion[];

  private parseValues(value: string[]): StringIdName[] {
    const props: StringIdName[] = [];
    value.forEach((element) => {
      props.push({ id: element, name: element });
    });
    return props;
  }

  private get searchPropertyItems() {
    return searchProps;
  }

  private handleRemove(idx: number) {
    this.criteria.splice(idx, 1);
  }

  get searchConditionItems() {
    return [...searchConditions];
  }
}
