














import { Component, Prop } from 'vue-property-decorator';
// tslint:disable-next-line
import RelatedDocForm from '@/components/editor/SectionEditor/RelatedDocsSectionEditor/RelatedDocForm.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { isEqual } from 'lodash';
import normalizeUrl from 'normalize-url';
import { EditorCommonMixin } from '../../../../views/DocumentEditor/mixins/editor-common.mixin';
import ListSectionValueEditor from '@/components/editor/SectionEditor/ListSectionValueEditor.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {
    RelatedDocForm,
    ListSectionValueEditor
  }
})
export default class RelatedDocsSectionValueEditor extends mixins(
  ViewModeMixin,
  EditorCommonMixin
) {
  @Prop()
  public link!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0];
  @Prop(Number) public index!: number;
  @Prop(Boolean) public notEditable!: boolean;

  get linkHtml() {
    const { href } = this.$router.resolve({
      name: 'previewLatestPublishedVersion',
      params: {
        documentId: String(this.link.relatedDocs.id)
      }
    });
    return `<a href='${href}' target="_blank">${this.link.relatedDocs.title}</a>`.trim();
  }

  get isInvalidDocLink() {
    return !this.link.relatedDocs.id;
  }

  public openEditForm() {
    const modalTitle = this.isRp
      ? 'Supporting Evidence Summaries'
      : 'Related JBI Evidence Summaries';
    this.$buefy.modal.open({
      parent: this,
      component: RelatedDocForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle,
        link: this.link
      }
    });
  }

  public deleteMe() {
    this.dirtyRelatedDocs = this.dirtyRelatedDocs.filter(
      (rd) => !isEqual(rd, this.link)
    );
  }
}
