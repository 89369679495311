

















import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import WkResourceExportsHistoryItems from './WkResourceExportsHistoryItems.vue';
import { Pagination } from '@/store/modules/users/types/users.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { AddWkExportEventLogJobPayload } from '@/jbi-shared/types/wk-export.types.ts';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedWkResourceExportsHistoryItems extends mixins(
  PaginationMixin
) {
  @Prop() public wkExports!: AddWkExportEventLogJobPayload;
  @Prop() public totalCount!: number;

  get WkResourceExportsHistoryItems() {
    return WkResourceExportsHistoryItems;
  }
}
