import { useCurrentProjectId } from './document.util';
import { useAction } from './store.util';
import Vue from 'vue';

export const useCitations = function(this: Vue) {
  const projectId = useCurrentProjectId.call(this);
  const getCitations: (documentId: number) => void = useAction.call(
    this,
    'projects/getCitations',
  );

  const getCitationsForCurrentProject = () => getCitations(projectId);

  return {
    getCitationsForCurrentProject,
  };
};
