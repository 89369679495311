import { useWebsocket } from '@/utils/websocket.util';
import { ExportDocumentWorkerJobMetadata } from '@/store/modules/documents/types/worker-job.types';
import { EVENTS } from '@/store/modules/websocket/websocket.state';
import { useAction, useGetter } from './store.util';
import { saveAs } from 'file-saver';
import { SearchResultExportType } from '../store/modules/documents/types/documents.types';
import Vue from 'vue';
import { DocumentIndexingPayload } from '../jbi-shared/types/search.types';
import { get as _get } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import { Job, JobId } from 'bull';
import { WorkerJobStatus } from '../jbi-shared/types/cplus-worker.types';

const markWorkerJobAsCompleted = function(this: Vue, payload: Job) {
  return useAction.call(this, 'websocket/markWorkerJobAsCompleted')(payload);
};

const markWorkerJobAsErrored = function(this: Vue, payload: Job) {
  return useAction.call(this, 'websocket/markWorkerJobAsErrored')(payload);
};

const onExportSuccess = async function(
  this: Vue,
  job: Job,
  type: SearchResultExportType,
) {
  if (job.returnvalue.status === WorkerJobStatus.PROCESSED) {
    markWorkerJobAsCompleted.call(this, job);

    const { storageUrl } = job.returnvalue
      .metadata as ExportDocumentWorkerJobMetadata;
    const filename = `Search_Result - ${new Date().toISOString()}.${type}`;

    Toast.open({
      message: `Downloading .${type} file ...`,
      duration: 3000,
      queue: false,
    });
    saveAs(storageUrl, filename);
  } else {
    markWorkerJobAsErrored.call(this, job);
    Toast.open({
      message: `Error exporting. Please try again later.`,
      duration: 3000,
      type: 'is-danger',
      queue: false,
    });
  }
};

const onExportError = function(this: Vue, job: Job, error: Error) {
  markWorkerJobAsErrored.call(this, job);

  Toast.open({
    message: `Error exporting. Please try again later.`,
    duration: 3000,
    type: 'is-danger',
    queue: false,
  });

  return;
};

export const handleCsvExporting = async function(
  this: Vue,
  job: Job,
  type: SearchResultExportType,
) {
  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);
  const { id: jobId } = job;

  await connectToWs();

  Toast.open({
    message: `Preparing .${type} file for download ...`,
    duration: 1000,
    queue: false,
  });

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(EVENTS.EXPORT_CSV_EVENT)) as Job;

    await onExportSuccess.call(this, job, type);
    await disconnectWs();
  } catch (error) {
    onExportError.call(this, job, error);
    await disconnectWs();
  }
};
