






import {
  GetAssignmentZipUrlRequestPayload,
  GetAssignmentZipUrlResponsePayload,
} from '@/store/modules/admin/types/admin.types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { get as _get } from 'lodash';
import { isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';

@Component({ components: {} })
export default class AssignmentDownloadPage extends Vue {
  @Action('admin/getAssignmentZipUrl')
  public getAssignmentZipUrl!: (p: GetAssignmentZipUrlRequestPayload) => void;

  @State((state: RootState) => _get(state, 'admin.assignmentZipUrl'))
  public zipUrl!: GetAssignmentZipUrlResponsePayload;

  get zipFileToken(): string {
    return this.$route.params.token;
  }

  @Watch('zipUrl')
  @isDifferent
  public onZipUrlChange() {
    if (this.zipUrl) {
      const { zipUrl } = this.zipUrl;
      // Have to use this manual way of triggering download instead of file-saver saveAs()
      // because file-saver saveAs doesnt asynchronously trigger file download.
      // With file-saver, the window will close before download begins.
      const anchor = document.createElement('a');
      anchor.href = zipUrl;
      anchor.click();
      setTimeout(() => {
        window.close();
      }, 500);
    }
  }

  public mounted() {
    this.getAssignmentZipUrl({
      token: this.zipFileToken,
    });
  }
}
