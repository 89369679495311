import { ApiState, PaginatedApiResponse } from '@/store/types/general.types';
import { EditorDetails } from '@/jbi-shared/types/editor.types';
import {
  FilteredProjectQueryParam,
  ProjectListItemParam
} from '../../projects/types/projects.types';
import { CreatePendingDocumentPayload } from '@/jbi-shared/types/cplus-worker.types';
import {
  ImportedEditorDocumentPayload,
  PendingDocCreationJobStatus
} from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import { PendingDocument } from '@/jbi-shared/types/document.types';
import { PendingProjectDetails } from '@/jbi-shared/types/cplus-endpoints/admin/project.types';
import { DocumentDetail } from '../../documents/types/documents.types';

export interface ExportProjectListWorkerJobMetadata {
  storageUrl: string;
}

export enum AdminExportType {
  xlsx = 'xlsx',
  pdf = 'pdf'
}

export interface AssignProjectDetails {
  projectId: number;
  dueDate: string;
  title: string;
}

export interface ChangeDueDateRequestPayload {
  projectId: number;
  dueDate: string;
  adminEmail: string;
}

export interface ExportProjectListRequestPayload {
  filterParams: FilteredProjectQueryParam;
  sortCriteria: string;
  sortOrder: 'ASC' | 'DESC';
  exportType: 'xlsx' | 'pdf';
}

export interface ExportProjectListProject {
  projectId: number;
}

export interface ExportProjectAsDocxDialogPayload {
  includeAuditCriteria: boolean;
  includeRp: boolean;
  projectId: number;
  title: string;
}

export interface UnassignEditorFormPayload extends ProjectListItemParam {
  unassignNote: string;
}

export interface IncludedProjectOptions {
  id: number;
  name: string;
}

export interface AssignEditorFormValues {
  assignee: EditorDetails | null;
  allDueDate: Date;
  projects: AssignProjectDetails[];
  zipFileName: string | undefined;
  includedRps: IncludedProjectOptions[];
  includedAuditCriteria: IncludedProjectOptions[];
  note: string | undefined;
  contractStorageUri: string | undefined;
}

export interface GetAssignmentZipUrlRequestPayload {
  token: string;
}

export interface GetAssignmentZipUrlResponsePayload {
  zipUrl: string;
}

export interface AssignProjectEditorRequestPayload {
  adminEmail: string;
  editorId: string;
  editorEmail: string;
  editorName: string;
  projects: AssignProjectDetails[];
  zipFileName: string | undefined;
  includedRps: number[];
  includedAuditCriteria: number[];
  note: string | undefined;
  contractStorageUri: string | undefined;
}

export interface FetchPendingDocumentRequestPayload {
  projectId: number;
  documentId?: number | undefined | null;
}

export interface PendingDocumentUploadHistoryResponsePayload {
  projectDocumentId: number;
  uploadedDocInfo: {
    docType: string;
  };
  createdAt: string;
  processJobStatus: PendingDocCreationJobStatus;
  errorMessage: null | string;
  pendingDocument: null | {
    isPublished: boolean;
  };
}

export interface StaticUploadedFile {
  name: string;
  signedUrl: string;
  storageUrl: string;
  storageUri: string;
}
export interface UnassignProjectEditorRequestPayload {
  adminEmail: string;
  projectId: number;
  note: string | undefined;
}

export interface ResendDownloadLinkRequestPayload {
  projectId: number;
  adminEmail: string;
  includeRp: boolean;
  includeAuditCriteria: boolean;
}

export interface DownloadDocumentRequestPayload {
  projectId: number;
  includeRp: boolean;
  includeAuditCriteria: boolean;
}

export interface AdminApiState {
  assignProjectEditor: ApiState;
  unassignProjectEditor: ApiState;
  getAssignmentZipUrl: ApiState;
  changeDueDate: ApiState;
  resendDownloadLink: ApiState;
  exportProjectList: ApiState;
  getUploadedDocumentDetails: ApiState;
  createPendingDocuments: ApiState;
  updatePendingDocument: ApiState;
  publishPendingDocument: ApiState;
  fetchPendingProjectDetails: ApiState;
  fetchPendingDocumentCreationStatus: ApiState;
  fetchPendingDocumentUploadHistory: ApiState;
  fetchPendingDocument: ApiState;
  fetchExistingDocumentDetail: ApiState;
  downloadDocument: ApiState;
}

export interface AdminState {
  apiState: AdminApiState;
  assignmentZipUrl: GetAssignmentZipUrlResponsePayload | undefined;
  uploadedDocumentDetails: ImportedEditorDocumentPayload[] | undefined;
  pendingDocumentsCreated: CreatePendingDocumentPayload[] | undefined;
  pendingProjectDetails: PendingProjectDetails | undefined;
  pendingDocument: PendingDocument | undefined;
  existingDocumentDetail: DocumentDetail | undefined;
  pendingDocumentUploadHistory:
    | PaginatedApiResponse<PendingDocumentUploadHistoryResponsePayload>
    | undefined;
}
