export interface StringIdName {
  id: string;
  name: string;
}
export interface SearchCriterion {
  props: StringIdName[];
  condition: { id: string; name: string };
  // could be tags or mesh tags
  keyword: string;
}

export interface SearchCriterionPrimitive {
  props: string[];
  condition: string;
  // could be tags or mesh tags
  keyword: string;
}

export const searchProps: StringIdName[] = [
  // specify attributes for this
  { id: 'Title', name: 'Title' },
  // specify attributes for this
  { id: 'Keywords', name: 'Keywords' },
  // specify attributes for this
  { id: 'MeSH', name: 'MeSH' },
  { id: 'Full text', name: 'Full text' }
];

export const searchConditions: StringIdName[] = [
  { id: 'And', name: 'And' },
  { id: 'Or', name: 'Or' },
  { id: 'Not', name: 'Not' }
];
