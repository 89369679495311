




import { Component, Vue, Watch } from 'vue-property-decorator';
import { RootState } from '../../store/store';
import { GeneralRoles } from '../../jbi-shared/types/casbin-permission.types';
import { Action } from 'vuex-class';
import { isTruthy } from '../../jbi-shared/util/watcher.vue-decorator';

@Component({})
export default class RedirectionHandlerPage extends Vue {
  @Action('users/getMyRoles')
  public getMyRoles!: () => Promise<string[]>;

  get isLoggedIn(): boolean {
    return (this.$store.state as RootState).auth.isLoggedIn;
  }

  get myRoles(): string[] {
    return (this.$store.state as RootState).users.myRoles || [];
  }

  @Watch('myRoles', { immediate: true })
  public onMyRolesLoaded() {
    if (!this.myRoles.length) {
      return;
    }
    const isWkAdmin =
      this.myRoles.includes(GeneralRoles.WkAdmin) &&
      !this.myRoles.includes(GeneralRoles.Admin);
    if (isWkAdmin) {
      this.$router.replace({ name: 'wk-resource-export' });
    } else {
      this.$router.replace({ name: 'dashboard' });
    }
  }

  @Watch('isLoggedIn', { immediate: true })
  @isTruthy
  public onLoggedIn() {
    return this.getMyRoles();
  }
}
