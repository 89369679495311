import { useAction, useGetter } from '@/utils/store.util';
import { useWebsocket } from '@/utils/websocket.util';
import { EVENTS } from '../store/modules/websocket/websocket.state';
import Vue from 'vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { Job } from 'bull';
import { WorkerJobStatus } from '../jbi-shared/types/cplus-worker.types';

const displayToastError = () => {
  Toast.open({
    message: `Error processing. Please try again later.`,
    duration: 3000,
    type: 'is-danger',
  });
};

export const handleEditorDocumentUploading = async function (
  this: Vue,
  job: Job,
) {
  if (!job) {
    displayToastError();
  }

  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);
  const { id: jobId } = job;

  const markWorkerJobAsCompleted: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsCompleted',
  );
  const markWorkerJobAsErrored: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsErrored',
  );

  const updateEditorDocumentUploadingResponse: (
    returnValue: any,
  ) => void = useAction.call(
    this,
    'admin/updateEditorDocumentUploadingResponse',
  );

  await connectToWs();

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(EVENTS.UPLOAD_EDITOR_DOCUMENTS_EVENT)) as Job;
  } catch (error) {
    markWorkerJobAsErrored(job);
    displayToastError();
    await disconnectWs();
    return;
  }

  if (job.returnvalue) {
    markWorkerJobAsCompleted(job);
    Toast.open({
      queue: true,
      position: 'is-top',
      message: `Documents imported successfully.`,
    });
    updateEditorDocumentUploadingResponse(job.returnvalue);
  } else {
    markWorkerJobAsErrored(job);
    displayToastError();
  }
  await disconnectWs();

  return {};
};

export const handlePendingDocumentsCreation = async function (
  this: Vue,
  job: Job,
) {
  if (!job) {
    displayToastError();
  }

  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);
  const { id: jobId } = job;

  const markWorkerJobAsCompleted: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsCompleted',
  );
  const markWorkerJobAsErrored: (payload: Job) => void = useAction.call(
    this,
    'websocket/markWorkerJobAsErrored',
  );

  const upsertCreatePendingDocumentsResponse: (
    returnValue: any,
  ) => void = useAction.call(
    this,
    'admin/upsertCreatePendingDocumentsResponse',
  );

  const countProject: () => void = useAction.call(
    this,
    'projects/getProjectCountByProjectStatus',
  );

  await connectToWs();

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(EVENTS.CREATE_PENDING_DOCUMENTS_EVENT)) as Job;
  } catch (error) {
    markWorkerJobAsErrored(job);
    displayToastError();
    await disconnectWs();
    return;
  }

  if (job.returnvalue) {
    const { pendingDocuments: documents } = job.returnvalue;
    upsertCreatePendingDocumentsResponse(documents);
    const uniqueProjectIds: number[] = [];
    documents.forEach((doc: any) => {
      const projectId = doc.projectId;
      if (!uniqueProjectIds.includes(projectId)) {
        uniqueProjectIds.push(projectId);
      }
    });
    markWorkerJobAsCompleted(job);
    countProject();
    Toast.open({
      queue: true,
      position: 'is-top',
      message: `${documents.length} pending documents for ${uniqueProjectIds.length} projects have been created`,
    });
  } else {
    markWorkerJobAsErrored(job);
    displayToastError();
  }
  await disconnectWs();

  return {};
};
