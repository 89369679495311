import { NotificationProgrammatic as Notification } from 'buefy';

export function showStickyErrorNotification(errorMsg: string) {
  Notification.open({
    type: 'is-danger',
    position: 'is-top-right',
    message: errorMsg,
    indefinite: true,
    queue: false,
  });
}

export function getCompiledMessage(
  errorCode: string,
  errorMsg: string,
  errorId: string,
) {
  return `
    ${errorMsg}. <br>
    Error code: ${errorCode} <br>
    ErrorID: ${errorId}
  `;
}
