
















































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import UploadError from '@/components/uploader/UploadError.vue';
import UploadTimeout from '@/components/uploader/UploadTimeout.vue';
import UploadLoading from '@/components/uploader/UploadLoading.vue';
import {
  GetSignedUrlForUploadRequestPayload,
  GetSignedUrlForUploadResponsePayload
} from '@/store/modules/static-file/types/static-file.types';

@Component({
  components: {
    UploadError,
    UploadLoading,
    UploadTimeout
  }
})
export default class GenericMultipleFileUploader extends Vue {
  public isLoading: boolean = false;
  public isSuccess: boolean = false;
  public isFailed: boolean = false;
  public error = '';
  public newUploadedFile: boolean = false;
  public showTags: boolean = false;

  @Prop({
    default: () => {
      return [];
    }
  })
  public fileAttachment!: File[];

  public isFileDeleted: boolean = false;

  @Action('staticFile/getSignedUrlForUploads')
  public getSignedUrlForUploads!: (
    d: GetSignedUrlForUploadRequestPayload
  ) => Promise<GetSignedUrlForUploadResponsePayload>;

  get file(): File[] {
    return this.fileAttachment;
  }

  set file(value) {
    this.newUploadedFile = true;
    // this.$emit('update:fileAttachments', value);
  }

  @Watch('file')
  public async onFileSelected() {
    if (this.file.length === 0) {
      return;
    }

    if (this.isFileDeleted) {
      this.isFileDeleted = false;
    }

    if (this.newUploadedFile) {
      await Promise.all(
        this.file.map(async (file, index) => {
          const contentType = file.type;
          const fileSize = file.size;
          const fileName = file.name;

          if (fileSize > 50000000) {
            this.error = 'File size exceeded the 50MB limit';
            this.isFailed = true;
            return;
          }

          // Check if the file name contains special characters
          const format: RegExp = /[~`!#$%\^&*+=\\[\]\\';,/{}|\\"<>\?]/g;
          if (format.test(fileName)) {
            this.isFailed = true;
            this.error = 'Invalid file name,contains special characters';
            this.deleteDropFile(index);
            return;
          }

          const signedUrlData = await this.getSignedUrlForUploads({
            fileName,
            contentType
          });

          const {
            signedUrl: signedUrlForUpload,
            storageUrl,
            storageUri
          } = signedUrlData;

          const formData = new FormData();
          formData.append('file', file as Blob);

          await this.uploadToGcs(file, signedUrlForUpload);

          const subFileName = signedUrlData.storageUrl.substring(105);

          this.fileAttachment[index] = {
            ...this.fileAttachment[index],
            ...signedUrlData,
            name: subFileName
          };
        })
      );

      if (this.isFailed) {
        return;
      }
      this.$emit('update:fileAttachments', this.fileAttachment);
    }
  }

  public handleRetry() {
    this.isFailed = false;
    this.isSuccess = false;
    this.error = '';
  }

  public deleteDropFile(index: number) {
    if (this.fileAttachment.length !== 0) {
      this.fileAttachment.splice(index, 1);
      this.isFileDeleted = true;
    }
  }

  public uploadToGcs(blobOrFile: File | Blob, url: string) {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);

      xhr.onload = (e) => {
        this.isLoading = false;
        this.isSuccess = true;
        return resolve();
      };

      xhr.onerror = (error) => {
        this.isFailed = true;
        return reject(error);
      };

      xhr.send(blobOrFile);
    });
  }
}
