

















































































import Component, { mixins } from 'vue-class-component';
import { getFileNameFromUrl } from '@/jbi-shared/util/url.util';
import { Revision } from '@/store/modules/documents/types/revisions.types';
import { byCreatedAt } from '@/utils/sort.util';
import { Prop } from 'vue-property-decorator';
import VersionHistoryItem from '@/views/DocumentVersions/components/VersionHistoryItem.vue';
import { useDocumentsState } from '@/utils/store.util';
import { ConvertedLegacyDocumentPayload } from '@/jbi-shared/types/legacy-document.types';
import { getFullNameFromUser } from '@/jbi-shared/util/user.utils';
import { EditorCommonMixin } from '../../DocumentEditor/mixins/editor-common.mixin';
import { RootState } from '@/store/store';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import { Action } from 'vuex-class';
import { GetRevisionCitationsRequestPayload } from '../../../store/modules/documents/types/documents.types';
import { Citation } from '../../../store/modules/documents/types/citations.types';

@Component({ components: { VersionHistoryItem } })
export default class VersionHistory extends mixins(EditorCommonMixin) {
  @Prop(Number) public editingRevisionId!: number;
  @Prop(String) public versionName!: string;

  @Action('documents/getRevisionCitations')
  public getRevisionCitations!: (
    payload: GetRevisionCitationsRequestPayload
  ) => Citation[];

  public async fetchCitations(revisionId: number) {
    await this.getRevisionCitations({
      documentId: this.documentId,
      revisionId: revisionId
    });
  }

  get latestVersionId() {
    return this.revisionsByDocumentId?.latestVersionId;
  }

  get revisionsByDocumentId() {
    const result = useDocumentsState.call(this).revisionsByDocumentId;

    return result;
  }

  get revisions(): Revision[] {
    return (this.revisionsByDocumentId?.nonDraftRevisions || [])
      .slice()
      .sort(byCreatedAt);
  }

  get archivedAndPublishedRevisions(): Revision[] {
    return this.revisions.filter((revision) => {
      const publicationStatus = this.getPublicationStatus(revision);
      return publicationStatus !== RevisionPublicationStatus.Draft;
    });
  }

  get legacyDocumentsFromRevisions(): ConvertedLegacyDocumentPayload[] {
    return (this.revisionsByDocumentId?.legacyDocuments || [])
      .slice()
      .sort((a, b) => b.baseDocumentId - a.baseDocumentId);
  }

  get isLoading() {
    return (this.$store.state as RootState).documents.apiState
      .getRevisionsByDocumentId.loading;
  }

  get publishedRevisionIds() {
    return this.revisions
      .filter(({ publishedAt }) => publishedAt)
      .map(({ id }) => id);
  }

  public getUrlFromStorageUri(storageUri: string): string {
    return storageUri.replace('gs://', 'https://storage.googleapis.com/');
  }

  public getTdrUrlFromRevision(revision: Revision): string | undefined {
    if (!revision.tdr) {
      return;
    }

    return this.getUrlFromStorageUri(revision.tdr.storageUri);
  }

  public getTdrFileName(revision: Revision): string | undefined {
    if (!revision.tdr) {
      return;
    }

    return revision.tdr.title;
  }

  public getContractUrlFromRevision(revision: Revision): string | undefined {
    if (!revision.assignmentContract) {
      return;
    }

    return this.getUrlFromStorageUri(revision.assignmentContract.storageUri);
  }

  public getContractFileNameFromRevision(
    revision: Revision
  ): string | undefined {
    if (!revision.assignmentContract) {
      return;
    }
    return getFileNameFromUrl(revision.assignmentContract.storageUri);
  }

  public getUserFullNameFromRevision(revision: Revision) {
    return getFullNameFromUser(revision.user);
  }

  public getPublicationStatus(revision: Revision) {
    return revision.revisionPublicationStatus.status;
  }

  public getPublicationDate(revision: Revision) {
    const publicationStatus = this.getPublicationStatus(revision);

    if (
      publicationStatus === RevisionPublicationStatus.Published &&
      revision.publishedBy !== undefined
    ) {
      return revision.publishedAt;
    } else if (
      publicationStatus === RevisionPublicationStatus.Archived &&
      revision.archivedBy !== undefined
    ) {
      return revision.archivedAt;
    } else if (
      publicationStatus === RevisionPublicationStatus.Withdrawn &&
      revision.withdrawnBy !== undefined
    ) {
      return revision.withdrawnAt;
    }
  }

  public getPublicationUserFullName(revision: Revision) {
    const publicationStatus = this.getPublicationStatus(revision);

    if (
      publicationStatus === RevisionPublicationStatus.Published &&
      revision.publishedBy !== undefined
    ) {
      return getFullNameFromUser(revision.publishedBy);
    } else if (
      publicationStatus === RevisionPublicationStatus.Archived &&
      revision.archivedBy !== undefined
    ) {
      return getFullNameFromUser(revision.archivedBy);
    } else if (
      publicationStatus === RevisionPublicationStatus.Withdrawn &&
      revision.withdrawnBy !== undefined
    ) {
      return getFullNameFromUser(revision.withdrawnBy);
    }
  }
}
