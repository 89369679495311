// tslint:disable:variable-name
import { UIStateMachine, UI_STATES } from './ui-state-machine.util';
import { interpret } from 'xstate';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ApiState } from '@/store/types/general.types';
import { useApiState, useAction } from './store.util';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import { get as _get } from 'lodash';
import { ProvideReactive } from 'vue-property-decorator';

export const GetUIStateMachineParentComponentMixin = (
  vuexActionName: string,
  vuexStatePath: string,
) => {
  // temp fix: https://github.com/kaorun343/vue-property-decorator/issues/277#issuecomment-558594655
  @Component({ inject: [] })
  class UIStateMachineParentComponentMixin extends Vue {
    get ui_actionState(): ApiState {
      return useApiState.call(this, vuexActionName)!;
    }

    get ui_actionSuccess(): boolean {
      return this.ui_actionState.success;
    }

    get ui_actionLoading(): boolean {
      return this.ui_actionState.loading;
    }

    get ui_actionError(): any {
      return this.ui_actionState.error;
    }

    get ui_action() {
      return useAction.call(this, vuexActionName);
    }

    get ui_isStateLoading() {
      return this.uiState === UI_STATES.LOADING;
    }

    get ui_isStateInitialize() {
      return this.uiState === UI_STATES.INITIALIZE;
    }

    get ui_isStateSuccessWithData() {
      return this.uiState === UI_STATES.FETCHSUCCESS_WITHDATA;
    }

    public ui_stateService = interpret(UIStateMachine);

    @State((state: RootState) => _get(state, vuexStatePath))
    public ui_actionItems!: any;

    @ProvideReactive('uiState')
    private uiState = (UIStateMachine.initialState
      .value as unknown) as UI_STATES;

    @Watch('ui_actionSuccess')
    public ui_onSuccess(val: boolean) {
      if (val && this.ui_actionItems.items.length > 0) {
        this.ui_stateService.send('FETCHDATA');
      } else {
        this.ui_stateService.send('FETCHNODATA');
      }
    }

    @Watch('ui_actionLoading')
    public ui_onLoading(val: boolean) {
      if (val) {
        this.ui_stateService.send('FETCH');
      }
    }

    public created() {
      this.ui_stateService
        .onTransition(state => {
          this.uiState = (state.value as unknown) as UI_STATES;
        })
        .start();
    }
  }
  return UIStateMachineParentComponentMixin;
};
