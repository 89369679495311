import { BackendUserEntity } from './search.types';
import { CplusDocumentType } from './document.types';

export enum EVENT_LOG_TYPE {
  UPDATE_DOCUMENT_ASSIGNEE = 'UPDATE_DOCUMENT_ASSIGNEE',
  UPDATE_DOCUMENT_STATUS = 'UPDATE_DOCUMENT_STATUS',
}

export type EventPayload =
  | UpdateDocumentAssigneeEventLogPayload
  | UpdateDocumentStatusEventLogPayload;

export interface UpdateDocumentAssigneeEventLogPayload {
  eventType: EVENT_LOG_TYPE;
  documentId: number;
  documentTitle: string;
  documentType: CplusDocumentType;
  projectId: number;
  originator: BackendUserEntity;
  assignee: BackendUserEntity | null;
  eventOccuredAt: Date;
}

// Deprecated?
export interface UpdateDocumentStatusEventLogPayload {
  eventType: EVENT_LOG_TYPE;
  documentId: number;
  documentTitle: string;
  documentType: CplusDocumentType;
  projectId: number;
  originator: BackendUserEntity | null;
  eventOccuredAt: Date;
}
