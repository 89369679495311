





































































import { Component, Prop } from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { get as _get } from 'lodash';
import BaseTable from '@/components/base/BaseTable.vue';
import SortableTableHeader from '@/components/base/SortableTableHeader.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import TableActionDropdown from '@/components/base/TableActionDropdown.vue';
import { WkExportResourceDetail } from '../../jbi-shared/types/wk-export.types';

@Component({
  components: {
    BaseTable,
    SortableTableHeader,
    BaseLoading,
    BaseModal,
    BasePagination,
    TableActionDropdown
  }
})
export default class ExportResourceStatusList extends mixins(PaginationMixin) {
  @Prop() public items!: WkExportResourceDetail[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  public getDocumentEditorRouterLink(item: any) {
    if (item && item?.documentId) {
      const itemArray = item.documentId.split('-');
      return {
        name: 'editor',
        params: {
          projectId: parseInt(item.projectId, 10),
          documentId: parseInt(itemArray[2], 10)
        }
      };
    }
  }
}
