



















import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DashboardListingHeader extends Vue {
  @Prop(String) public label!: string;
}
