import { Criterion } from '@/jbi-shared/types/criterions.types';
import { DirtyTagMap } from '../../../store/modules/documents/types/documents.types';
import { isEqual, throttle } from 'lodash';
import { TagEntityTypeEnum } from '../../../jbi-shared/types/document.types';

export type SidebarTabOption =
  | 'sections'
  | 'citations'
  | 'media'
  | 'links'
  | 'tagging'
  | 'audit'
  | 'strategy'
  | 'appraisal';

function processTagMapAfterCriterionRearrangement(
  criterions: Criterion[],
  oldCriterions: Criterion[],
  tagMaps: DirtyTagMap[]
): DirtyTagMap[] {
  const ids = criterions.map(({ id, tempId }) => id || tempId);
  const prevIds = oldCriterions.map(({ id, tempId }) => id || tempId);
  const isRearranged =
    !isEqual(ids, prevIds) &&
    isEqual(ids.slice().sort(), prevIds.slice().sort());
  // change tagmap entity id, if rearranged
  if (isRearranged) {
    tagMaps = tagMaps.map((tm) => {
      if (tm.entityType === TagEntityTypeEnum.criterion) {
        // @ts-ignore
        const c = oldCriterions[tm.entityId - 1];
        const newI = criterions.findIndex(
          ({ id, tempId }) =>
            (id && c.id === id) || (tempId && c.tempId === tempId)
        );
        return { ...tm, entityId: newI + 1 };
      }
      return tm;
    });
  }
  return tagMaps;
}
export const processTagMapAfterCriterionRearrangementThrottle = throttle(
  processTagMapAfterCriterionRearrangement,
  200
);
