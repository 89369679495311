















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MeshTreeItem from '@/components/editor/SectionEditor/MeshTreeItem.vue';
import { useDocumentsState } from '../../../utils/store.util';

@Component({ components: { MeshTreeItem } })
export default class MeshTree extends Vue {
  @Prop(Array) public readonly activeMeshTreePaths!: string[];
  @Prop(Array) public readonly selectedPaths!: string[];

  get meshTree() {
    return useDocumentsState.call(this).meshTree
      ? useDocumentsState.call(this).meshTree!.content
      : null;
  }
}
