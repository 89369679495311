var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SuggestedDocumentModal',{attrs:{"selected":_vm.selected,"modalTitle":_vm.modalTitle,"suggestedDocumentsLabel":"SUGGESTED DOCUMENTS","currentDocAuthors":_vm.currentDocAuthors,"currentDocNodes":_vm.currentDocNodes,"currentDocumentId":_vm.currentDocumentId,"dirty-related-docs":_vm.dirtyRelatedDocs,"closeModal":function () { return _vm.$parent.close(); },"type":_vm.isEs
      ? _vm.SuggestedDocumentType.relatedDocuments
      : _vm.isRp
      ? _vm.SuggestedDocumentType.supportingEvidenceSummaries
      : undefined},scopedSlots:_vm._u([{key:"filteredSearchResult",fn:function(ref){
      var filteredSearchResult = ref.filteredSearchResult;
return _vm._l((filteredSearchResult),function(doc){return _c('RelatedDocSearchResultItem',{key:doc.documentId,attrs:{"document":doc,"checked":_vm.isSelected(doc)},on:{"toggle":function($event){return _vm.handleToggle(doc)}}})})}},{key:"otherSelectedDocs",fn:function(ref){
      var otherSelectedDocs = ref.otherSelectedDocs;
return _vm._l((otherSelectedDocs),function(doc){return _c('RelatedDocSearchResultItem',{key:doc.documentId,attrs:{"document":doc,"checked":_vm.isSelected(doc)},on:{"toggle":function($event){return _vm.handleToggle(doc)}}})})}},{key:"submitButton",fn:function(){return [_c('button',{staticClass:"button is-primary",attrs:{"disabled":!_vm.selected.length},on:{"click":function($event){$event.preventDefault();_vm.$emit('add', _vm.selected);
        _vm.$emit('close');}}},[_vm._v(" Add Document ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }