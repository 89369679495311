var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{class:_vm.$style.dropdown,attrs:{"aria-role":"list","position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('div',{attrs:{"role":"button"}},[_vm._t("default",null,{"active":active})],2)}}],null,true)},_vm._l((_vm.items),function(item,i){
var _obj;
return _c('b-dropdown-item',{key:item.id,attrs:{"aria-role":"listitem","has-link":""},nativeOn:{"mouseover":function($event){_vm.hoveredItemIndex = i},"mouseleave":function($event){_vm.hoveredItemIndex = -1}}},[_c('router-link',{class:[
        ( _obj = {}, _obj[_vm.$style.isPublished] = _vm.isPublishedDoc(item), _obj[_vm.$style.isDraft] = _vm.isDraftDoc(item), _obj[_vm.$style.isArchived] = _vm.isArchivedDoc(item), _obj['is-active'] = _vm.isActive(item.id), _obj )
      ],attrs:{"to":{
        name: 'editor',
        params: {
          documentId: item.id,
          projectId: _vm.projectId
        }
      },"title":item.title}},[_c('ProjectEditorHeaderTabTag',{attrs:{"active":_vm.hoveredItemIndex === i,"status":_vm.getDocumentStatus(item)}},[_vm._v(" "+_vm._s(_vm.getDocTypeDisplayNameFromDoc(item))+" ")]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }