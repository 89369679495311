









import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import normalizeUrl from 'normalize-url';
import dayjs from 'dayjs';
import { Publication } from '../../../../utils/publication.util';
import ListSectionValueEditor from '@/components/editor/SectionEditor/ListSectionValueEditor.vue';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  components: {
    ListSectionValueEditor
  }
})
export default class PublicationRecord extends mixins(ViewModeMixin) {
  @Prop() public publication!: Publication;
  @Prop(Number) public index!: number;

  get publicationHtml() {
    return `<a href='${normalizeUrl(this.publication.url)}' target="_blank">${
      this.publication.uniquePublicationId
    }</a> (Published ${dayjs(this.publication.publishedAt)
      .tz(this.publication.publishedAtTimezone)
      .format('D MMMM YYYY')})`;
  }
}
