

































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import Container from '@/components/Container.vue';
import { ToastProgrammatic } from 'buefy';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { PendingDocCreationJobStatus } from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import PaginatedPendingDocCreationJobList from '@/components/listings/pending-document-creations/PaginatedCreationList.vue';
import PendingDocCreationJobFilterBox from './components/pending-document-creations/PendingDocCreationJobFilterBox.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';

interface FilteredPendingDocCreationJobPayload {
  documentId: string;
  selectedStatus: string;
}

export interface PendingDocumentCreationStatus {
  projectDocumentId: number;
  uploadedDocInfo: {
    docType: string;
  };
  processJobStatus: PendingDocCreationJobStatus;
  errorMessage: string;
}

const initialState = {
  documentId: '',
  selectedStatus: ''
};

@Component({
  components: {
    DashboardHeader,
    Container,
    PendingDocCreationJobFilterBox,
    PaginatedPendingDocCreationJobList
  }
})
export default class PendingDocumentsCreationStatusPage extends Vue {
  @Action('admin/fetchPendingDocumentsCreationStatus')
  public fetchCreationStatus!: (
    id: string
  ) => Promise<PendingDocumentCreationStatus[]>;

  @State(
    (state: RootState) =>
      state.admin.apiState.fetchPendingDocumentCreationStatus
  )
  public fetchCreationStatusApi!: ApiState;

  public interval = setInterval(() => {
    this.fetchPendingDocumentsCreationStatus();
  }, 5000);
  public statuses: PendingDocumentCreationStatus[] = [];
  public filteredParams: FilteredPendingDocCreationJobPayload = {
    ...initialState
  };
  public page = 1;
  public perPage = 50;

  get creationId() {
    return this.$route.params.creationId;
  }

  get shouldStopFetching() {
    return (
      this.statuses.length > 0 &&
      /* Check that all statuses have been processed - no Pending job status. */
      !this.statuses.find(
        (status) =>
          status.processJobStatus === PendingDocCreationJobStatus.Pending
      )
    );
  }

  get filteredStatuses() {
    let statuses = this.statuses;
    if (this.filteredParams.documentId) {
      const docId = this.filteredParams.documentId.trim().toUpperCase();
      const documentIdNoRevision = /^(((JBI)-(ES|RP)-|(ES)-|(RP)-)(\d+)$)/gi;
      const isNumericId = (n: string) => !isNaN(Number(n));
      if (isNumericId(docId)) {
        statuses = statuses.filter(
          (status) => status.projectDocumentId === Number(docId)
        );
      } else if (documentIdNoRevision.test(docId)) {
        statuses = statuses.filter((status) => {
          const displayDocId = `JBI-${status.uploadedDocInfo.docType.toUpperCase()}-${
            status.projectDocumentId
          }-`;
          return displayDocId.includes(`${docId}-`);
        });
      } else {
        statuses = [];
      }
    }
    if (this.filteredParams.selectedStatus) {
      statuses = statuses.filter(
        (status) =>
          status.processJobStatus === this.filteredParams.selectedStatus
      );
    }
    return statuses;
  }

  get pendingJobs() {
    return this.statuses.filter(
      (status) =>
        status.processJobStatus === PendingDocCreationJobStatus.Pending
    );
  }

  get finishedJobs() {
    return this.statuses.filter(
      (status) =>
        status.processJobStatus !== PendingDocCreationJobStatus.Pending
    );
  }

  get successJobs() {
    return this.statuses.filter(
      (status) =>
        status.processJobStatus === PendingDocCreationJobStatus.Success
    );
  }

  get paginatedStatuses() {
    const start = this.perPage * (this.page - 1);
    const end = start + this.perPage;
    return this.filteredStatuses.slice(start, end);
  }

  public goToAdminDashboard() {
    this.$router.push({
      name: 'admin-dashboard'
    });
  }

  public handleReset() {
    this.filteredParams = {
      ...this.filteredParams,
      ...initialState
    };
    this.debounceUpdate();
  }

  public handleFilter(
    filterParam: Partial<FilteredPendingDocCreationJobPayload>
  ) {
    this.filteredParams = {
      ...this.filteredParams,
      ...filterParam
    };
    this.debounceUpdate();
  }

  @Debounce(500)
  public debounceUpdate() {
    this.page = 1;
    this.updateRouteQuery();
  }

  public updateRouteQuery() {
    this.$router.push({
      // @ts-ignore
      query: {
        page: String(this.page),
        perPage: String(this.perPage),
        ...this.filteredParams
      }
    });
  }

  public created() {
    this.fetchPendingDocumentsCreationStatus();
  }

  public fetchPendingDocumentsCreationStatus() {
    if (!this.creationId) {
      return;
    }

    this.fetchCreationStatus(this.creationId).then((statuses) => {
      if (statuses.length <= 0) {
        clearInterval(this.interval);
        ToastProgrammatic.open({
          message: `There are no uploaded documents pending creation.`,
          duration: 5000,
          type: 'is-danger',
          queue: false
        });
        return;
      }
      this.statuses = statuses;
    });
  }

  @Watch('shouldStopFetching')
  @isTruthy
  @isDifferent
  public stopFetching() {
    clearInterval(this.interval);
  }

  @Watch('$route.query', { immediate: true })
  public onRouteChange(value: any) {
    if (value) {
      const { page, perPage, selectedStatus, documentId } = value;
      this.perPage = +perPage || 50;
      this.page = +page || 1;

      this.filteredParams = {
        ...this.filteredParams,
        selectedStatus,
        documentId
      };
    }
  }

  @Watch('fetchCreationStatusApi.error')
  @isDifferent
  @isTruthy
  public onFetchCreationStatusApiError(error: AxiosError) {
    clearInterval(this.interval);
  }
}
