











import { Component, Prop } from 'vue-property-decorator';
import RelatedRPDocsSectionValueEditor from './RelatedRPDocsSectionValueEditor.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { DocumentData } from '../../../../jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {
    RelatedRPDocsSectionValueEditor
  }
})
export default class RelatedRPDocsSectionEditor extends mixins(ViewModeMixin) {
  public drag = false;
  @Prop() public relatedRPDocs!: DocumentData[];
}
