import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth/auth.module';
import documents from './modules/documents/documents.module';
import projects from './modules/projects/projects.module';
import websocket from './modules/websocket/websocket.module';
import users from './modules/users/users.module';
import staticFile from './modules/static-file/static-file.module';
import eventLogs from './modules/event-logs/event-logs.module';
import editors from './modules/editors/editors.module';
import admin from './modules/admin/admin.module';
import { DocumentsState } from './modules/documents/types/documents.types';
import { ProjectsState } from './modules/projects/types/projects.types';
import { AuthState } from './modules/auth/types/auth.types';
import { WebSocketState } from './modules/websocket/types/websocket.types';
import { errorLogger } from './plugins/error-logging.plugin';
import { UsersState } from './modules/users/types/users.types';
import { EventLogsState } from './modules/event-logs/types/event-logs.types';
import { EditorsState } from './modules/editors/types/editors.types';
import { AdminState } from './modules/admin/types/admin.types';

Vue.use(Vuex);

export interface RootState {
  documents: DocumentsState;
  projects: ProjectsState;
  auth: AuthState;
  websocket: WebSocketState;
  users: UsersState;
  eventLogs: EventLogsState;
  editors: EditorsState;
  admin: AdminState;
}

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    documents,
    websocket,
    projects,
    users,
    staticFile,
    eventLogs,
    editors,
    admin
  },
  mutations: {},
  actions: {},
  plugins: [
    errorLogger,
    createPersistedState({
      paths: [
        'auth.isLoggedIn',
        'auth.authInfo',
        'auth.jaasAccountDetail',
        'auth.myInfo',
        'users.myRoles'
      ],
      key: 'cplus-vuex'
    })
  ]
});
