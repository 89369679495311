


















































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import { Action, State } from 'vuex-class';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';

export interface InviteUserFormValues {
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
}

const initialFormValue = {
  email: '',
  username: '',
  firstName: '',
  lastName: ''
};

@Component({
  components: {
    ValidationProvider
  }
})
export default class InviteUserForm extends Vue {
  public form: InviteUserFormValues = {
    ...initialFormValue
  };

  @State((state: RootState) => state.users.apiState.inviteNewUser.success)
  public inviteNewUserSuccess!: boolean;

  public handleSubmit() {
    if (!this.form.email) {
      return;
    }
    this.$emit('submit', this.form);
  }

  @Watch('inviteNewUserSuccess')
  @isDifferent
  @isTruthy
  public watchInviteUserSuccess() {
    this.$emit('closeModal');
  }
}
