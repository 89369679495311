



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DirtyTagMap } from '../../../store/modules/documents/types/documents.types';
import { tagMapToUniqueKey } from '../../../jbi-shared/util/document.utils';
import { useDocumentsState } from '../../../utils/store.util';
import { MeshDto } from '../../../jbi-shared/util/mesh.util';
import BasePagination from '@/components/base/BasePagination.vue';

@Component({ components: { BasePagination } })
export default class MeshSearchResult extends Vue {
  @Prop() public meshSearch!: string;
  @Prop() public items!: DirtyTagMap[];

  public page = 1;
  public perPage = 10;

  get totalNumberOfPage() {
    return Math.ceil(this.items.length / this.perPage);
  }
  get paginatedItemsFirstIndex() {
    return (this.page - 1) * this.perPage + 1;
  }
  get paginatedItemsLastIndex() {
    return Math.min(this.items.length, this.page * this.perPage);
  }
  get paginatedItems(): DirtyTagMap[] {
    return this.items.slice(
      this.paginatedItemsFirstIndex - 1,
      this.paginatedItemsLastIndex
    );
  }

  get tagMapToUniqueKey() {
    return tagMapToUniqueKey;
  }

  get meshTree() {
    return useDocumentsState.call(this).meshTree
      ? useDocumentsState.call(this).meshTree!.content
      : null;
  }

  public getAllPaths(
    fullPath: string
  ): Array<{ path: string; keyword: string }> {
    let paths = fullPath.split('.');
    paths = paths.map((str, i) => {
      return paths.slice(0, i + 1).join('.');
    });
    return paths.map((path) => {
      return {
        path,
        keyword: this.meshTree?.[path]?.name
      };
    });
  }

  @Watch('page')
  public async onPageChanged() {
    await this.$nextTick();
    (this.$refs.items as Element[])[0].scrollIntoView({ behavior: 'smooth' });
  }

  @Watch('perPage')
  public onPageLimitChanged() {
    this.page = 1;
  }

  @Watch('meshSearch')
  public onSearchChange() {
    this.page = 1;
  }
}
