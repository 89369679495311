import { AxiosError, AxiosResponse } from 'axios';

export const axiosResponseInterceptor = (response: AxiosResponse) => {
  if (response.data) {
    response.data = response.data.data ? response.data.data : response.data;
  }
  return response;
};

export const axiosErrorInterceptor = (error: AxiosError) => {
  return Promise.reject(error);
};
