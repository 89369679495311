


















import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { useGetter } from '@/utils/store.util';
import {
  ProjectIndexingPayload,
  DocumentIndexingPayload
} from '@/jbi-shared/types/search.types';
import truncate from 'truncate';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin';
import { DATE_FORMAT } from '@/utils/date.util';
import { formatAuthorsForDisplay } from '../../../../jbi-shared/util/document.utils';
import SuggestedDocumentCard from '@/components/editor/SectionEditor/SuggestedDocumentCard.vue';

@Component({ components: { SuggestedDocumentCard } })
export default class RelatedDocSearchResultItem extends mixins(
  ScreenSizeMixin
) {
  @Prop() public document!: DocumentIndexingPayload;
  @Prop() public checked!: boolean;

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  get documentTitle(): string {
    if (!this.document) {
      return '';
    }
    return this.document.title;
  }

  get primaryResearchNode() {
    return this.document.primaryNode;
  }
  get secondaryResearchNodes() {
    return this.document.secondaryNodes.filter(Boolean).join(', ').trim();
  }

  get authors() {
    return (this.document.authors || []).join(', ');
  }
}
