import { ProjectsState } from './types/projects.types';
import { defaultApiState } from '@/store/types/general.types';

export const projectsState: ProjectsState = {
  apiState: {
    getMyProjects: defaultApiState,
    getAllProjects: defaultApiState,
    getProjectCountByProjectStatus: defaultApiState,
    createProject: defaultApiState,
    addNoteToProject: defaultApiState,
    updateProjectNote: defaultApiState,
    getNoteHistory: defaultApiState,
    getProjectDetail: defaultApiState,
    createSubDocument: defaultApiState,
    updateProject: defaultApiState,
    importResearchNodes: defaultApiState,
    getResearchNodes: defaultApiState,
    getAllResearchNodes: defaultApiState,
    getActiveResearchNodes: defaultApiState,
    createCitation: defaultApiState,
    getCitations: defaultApiState,
    editCitation: defaultApiState,
    deleteCitation: defaultApiState,
    uploadCitation: defaultApiState,
    acceptInvitation: defaultApiState,
    inviteUser: defaultApiState,
    resendUserInvitation: defaultApiState,
    removeUserFromProject: defaultApiState,
    createProjectFromLegacyDocument: defaultApiState,
    getPreMigrationStatus: defaultApiState
  },
  projects: undefined,
  projectCountByProjectStatus: undefined,
  projectNoteHistory: undefined,
  allProjects: undefined,
  projectDetail: undefined,
  createdProject: undefined,
  createdSubDocument: undefined,
  citationsOfProject: undefined,
  importedResearchNodes: undefined,
  activeResearchNodes: undefined,
  allResearchNodes: undefined,
  paginatedResearchNode: undefined,
  invitedUser: undefined,
  acceptedInvitedProject: undefined,
  preMigrationStatus: undefined,
  createdCitation: undefined
};
