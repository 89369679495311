import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home/HomePage.vue';
import AssignmentDownload from './views/Public/AssignmentDownloadPage.vue';
import Dashboard from './views/Dashboard/DashboardPage.vue';
import StagingAreaPage from './views/StagingArea/StagingAreaPage.vue';
import AdminDashboardPage from './views/AdminDashboard/AdminDashboardPage.vue';
import AdminProjectsPage from './views/AdminDashboard/ProjectsPage.vue';
import AdminEditorsPage from './views/AdminDashboard/EditorsPage.vue';
import PendingDocumentsCreationStatusPage from './views/AdminDashboard/PendingDocumentsCreationStatusPage.vue';
import UploadHistoryPage from './views/AdminDashboard/UploadHistoryPage.vue';
import AdminSettingsPage from '@/views/AdminSetting/AdminSettingsPage.vue';
import EditorsAssignedProjectPage from './views/AdminDashboard/EditorAssignedProjectsPage.vue';
import MyDocuments from './views/MyDocuments/MyDocumentsPage.vue';
import Oauth2Callback from './views/Oauth2Callback/Oauth2CallbackPage.vue';
import DocumentVersionRedirectionPage from '@/views/DocumentVersionRedirectionPage/DocumentVersionRedirectionPage.vue';
import DocumentVersionsPage from '@/views/DocumentVersions/DocumentVersionsPage.vue';
import DocumentPreviewPage from '@/views/DocumentPreview/DocumentPreviewPage.vue';
import AcceptProjectInvitationToken from '@/views/AcceptInvitationToken/AcceptProjectInvitationToken.vue';
import SearchPage from '@/views/Search/SearchPage.vue';
import AdminUserManagement from '@/views/AdminUserManagement/AdminUserManagement.page.vue';
import WkResourceExports from '@/views/WkResourceExports/WkResourceExports.page.vue';
import WkResourceExportsStatus from '@/views/WkResourceExportsStatus/WkResourceExportsStatus.page.vue';
import LegacyDocumentMigrationPage from '@/views/LegacyDocumentMigrationPage/LegacyDocumentMigration.page.vue';
import { requireAuth } from './router.guard';
import Unauthorized from './views/Unauthorized.vue';
import DocumentEditorPage from '@/views/DocumentEditor/DocumentEditorPage.vue';
import { GeneralRoles } from './jbi-shared/types/casbin-permission.types';
import { NavLink } from './jbi-shared/types/Nav';
import RedirectionHandlerPage from '@/views/RedirectionHandler/RedirectionHandlerPage.vue';
import AcceptSystemInvitationToken from '@/views/AcceptInvitationToken/AcceptSystemInvitationToken.vue';

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: 'exact-active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/oauth2/callback',
      name: 'oauth2callback',
      component: Oauth2Callback,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/assignment/download/:token',
      name: 'assignment-download',
      component: AssignmentDownload,
    },
    {
      path: '/redirecting',
      name: 'redirection-handler',
      component: RedirectionHandlerPage,
    },
    {
      path: '/staging/projects/:projectId/documents/:documentId?',
      name: 'staging-area',
      component: StagingAreaPage,
    },
    {
      path: '/admin/dashboard',
      name: 'admin-dashboard',
      component: AdminDashboardPage,
    },
    {
      path: '/admin/dashboard/projects',
      name: 'admin-dashboard-projects',
      component: AdminProjectsPage,
    },
    {
      path: '/admin/dashboard/upload-history',
      name: 'admin-upload-history',
      component: UploadHistoryPage,
    },
    {
      path: '/admin/dashboard/projects/pending-creation-status/:creationId',
      name: 'pending-document-creation-status',
      component: PendingDocumentsCreationStatusPage,
    },
    {
      path: '/admin/dashboard/editors',
      name: 'admin-dashboard-editors',
      component: AdminEditorsPage,
    },
    {
      path: '/admin/dashboard/editors/:editorId',
      name: 'editor-assigned-projects',
      component: EditorsAssignedProjectPage,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/documents',
      name: 'MyDocuments',
      component: MyDocuments,
    },
    {
      path: '/search',
      name: 'search',
      component: SearchPage,
    },
    {
      path: '/projects/:projectId/documents/:documentId/editor',
      name: 'editor',
      alias: ['/projects/:projectId/documents/:documentId'],
      component: DocumentEditorPage,
    },
    {
      path: '/projects/:projectId/documents/:documentId/versions',
      name: 'document-versions-redirect',
      component: DocumentVersionRedirectionPage,
    },
    {
      path: '/projects/:projectId/documents/:documentId/versions/:versionId',
      name: 'document-versions',
      component: DocumentVersionsPage,
    },
    {
      path:
        '/projects/:projectId/documents/:documentId/legacy-documents/:baseDocumentId',
      name: 'document-versions-legacy',
      component: DocumentVersionsPage,
    },
    {
      path: '/documents/JBI-:typeShorthand-:documentId-:publicationId/readonly',
      name: 'preview-publication',
      alias: [
        '/documents/JBI-:typeShorthand-:documentId-:publicationId/',
        '/documents/JBI-:typeShorthand-:documentId-:publicationId/readonly/*',
      ],
      component: DocumentPreviewPage,
    },
    {
      path: '/documents/:documentId/revisions/:revisionId/readonly',
      name: 'preview-revision',
      alias: [
        '/documents/:documentId/revisions/:revisionId/',
        '/documents/:documentId/revisions/:revisionId/readonly/*',
      ],
      component: DocumentPreviewPage,
    },
    {
      path: '/legacy-documents/:baseDocumentId/readonly',
      name: 'preview-legacy-documents',
      alias: [
        '/legacy-documents/:baseDocumentId/',
        '/legacy-documents/:baseDocumentId/readonly/*',
      ],
      component: DocumentPreviewPage,
    },
    {
      path: '/documents/:documentId',
      name: 'previewLatestPublishedVersion',
      component: DocumentPreviewPage,
    },
    {
      path: '/accept-project-invitation/:invitationToken',
      name: 'accept-project-invitation',
      component: AcceptProjectInvitationToken,
    },
    {
      path: '/invitations/accept',
      name: 'accept-invitations',
      component: AcceptSystemInvitationToken,
    },
    // for admin only
    {
      path: '/admin/setting',
      name: 'admin-setting',
      component: AdminSettingsPage,
    },
    {
      path: '/admin/user-management',
      name: 'admin-user-management',
      component: AdminUserManagement,
      beforeEnter: requireAuth,
      meta: {
        roles: [GeneralRoles.Admin],
      },
    },
    {
      path: '/resource-export',
      name: 'wk-resource-export',
      component: WkResourceExports,
      beforeEnter: requireAuth,
      meta: {
        roles: [GeneralRoles.Admin, GeneralRoles.WkAdmin],
      },
    },
    {
      path: '/resource-export/status/:wkExportId',
      name: 'wk-resource-export-status',
      component: WkResourceExportsStatus,
      beforeEnter: requireAuth,
      meta: {
        roles: [GeneralRoles.Admin, GeneralRoles.WkAdmin],
      },
    },
    {
      path: '/unauthorized',
      component: Unauthorized,
    },
    {
      path: '/legacy-document-migration/:latestBaseDocumentId',
      name: 'legacy-document-migration',
      component: LegacyDocumentMigrationPage,
      beforeEnter: requireAuth,
      meta: {
        roles: [GeneralRoles.Admin, GeneralRoles.User],
      },
    },
  ],
});

export default router;

export const navLinks: NavLink[] = [
  {
    label: `All Documents`,
    to: {
      name: 'MyDocuments',
    },
  },
  {
    label: 'Created',
    to: {
      name: 'MyDocuments',
      query: { tab: 'created' },
    },
  },
  {
    label: 'Assigned',
    to: {
      name: 'MyDocuments',
      query: { tab: 'assigned' },
    },
  },
];
