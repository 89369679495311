















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseTable from '@/components/base/BaseTable.vue';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';

@Component({
  components: {
    BaseTable
  }
})
export default class ProjectSelectedModal extends Vue {
  @Prop(Array)
  public selectedProjects!: ProjectListItemParam[];

  public expanded: boolean[] = this.selectedProjects.map((project) => false);
  public selectedItems: ProjectListItemParam[] = [...this.selectedProjects];

  get projectTotal() {
    return this.selectedItems.length;
  }

  public isSelected(item: ProjectListItemParam) {
    const selected = this.selectedItems.find(
      (project) => project.projectId === item.projectId
    );
    return !!selected;
  }

  public toggleExpand(index: number) {
    this.$set(this.expanded, index, !this.expanded[index]);
  }

  public updateSelected(item: ProjectListItemParam, selected: boolean) {
    if (selected) {
      const index = this.selectedProjects.findIndex(
        (project) => project.projectId === item.projectId
      );
      if (index !== -1) {
        this.selectedItems.push(this.selectedProjects[index]);
      }
    } else {
      const index = this.selectedItems.findIndex(
        (project) => project.projectId === item.projectId
      );
      this.selectedItems.splice(index, 1);
    }
  }

  public handleSubmit() {
    this.$emit('updateSelected', this.selectedItems);
    this.$emit('close');
  }
}
