




















































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { State, Action } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { RootState } from '@/store/store';
import {
  Citation,
  CreateCitationRequestPayload,
  EditCitationRequestPayload
} from '@/store/modules/documents/types/citations.types';
import { RemoveRevisionCitationRequestPayload } from '@/store/modules/documents/types/documents.types';
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import { ProjectsApiState } from '@/store/modules/projects/types/projects.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { CitationTypes } from '@/jbi-shared/types/citation.fields';
import { GetRevisionCitationsRequestPayload } from '../../../../../store/modules/documents/types/documents.types';
import { EditorComputedValuesMixin } from '../../../mixins/editor-computed-values.mixin';
import { updateCitationInOps } from '../../../../../utils/editor.util';
import { ToastProgrammatic as Toast } from 'buefy';

export interface FormFieldsProperties {
  type: string;
  name: string;
  rules: string;
  value: string;
  label: string;
  placeHolder: string;
}

export interface FormFields {
  types: {
    Book: FormFieldsProperties[];
    Generic: FormFieldsProperties[];
    'Book Section': FormFieldsProperties[];
    'Conference Paper': FormFieldsProperties[];
    'Journal Article': FormFieldsProperties[];
    Report: FormFieldsProperties[];
    WebPage: FormFieldsProperties[];
    Plain: FormFieldsProperties[];
  };
}

// tslint:disable:object-literal-key-quotes
const allFormFields: FormFields = {
  types: {
    Book: [
      {
        type: 'text',
        name: 'author',
        rules: 'required',
        value: '',
        label: 'Author(s)',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'title',
        rules: 'required',
        value: '',
        label: 'Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'edition',
        rules: '',
        value: '',
        label: 'Edition',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'seriesEditor',
        rules: '',
        value: '',
        label: 'Series Editor',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisherPlace',
        rules: '',
        value: '',
        label: 'Publisher Place',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisher',
        rules: '',
        value: '',
        label: 'Publisher',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'issued',
        rules: '',
        value: '',
        label: 'Issue Date',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'numberOfPages',
        rules: '',
        value: '',
        label: 'Number of Pages',
        placeHolder: ''
      }
    ],
    Generic: [
      {
        type: 'text',
        name: 'author',
        rules: 'required',
        value: '',
        label: 'Author(s)',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'title',
        rules: 'required',
        value: '',
        label: 'Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'edition',
        rules: '',
        value: '',
        label: 'Edition',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisherPlace',
        rules: '',
        value: '',
        label: 'Publisher Place',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisher',
        rules: '',
        value: '',
        label: 'Publisher',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'issued',
        rules: '',
        value: '',
        label: 'Issue Date',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'accessed',
        rules: '',
        value: '',
        label: 'Accessed Date',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'pages',
        rules: '',
        value: '',
        label: 'Pages',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'url',
        rules: '',
        value: '',
        label: 'URL',
        placeHolder: ''
      }
    ],
    'Book Section': [
      {
        type: 'text',
        name: 'author',
        rules: 'required',
        value: '',
        label: 'Author(s)',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'bookSectionTitle',
        rules: 'required',
        value: '',
        label: 'Book Section Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'title',
        rules: '',
        value: '',
        label: 'Book Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'editors',
        rules: '',
        value: '',
        label: 'Editors',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'volume',
        rules: '',
        value: '',
        label: 'Volume',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'edition',
        rules: '',
        value: '',
        label: 'Edition',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisherPlace',
        rules: '',
        value: '',
        label: 'Publisher Place',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisher',
        rules: '',
        value: '',
        label: 'Publisher',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'issued',
        rules: '',
        value: '',
        label: 'Issue Date',
        placeHolder: 'eg. 03/04/2004'
      },
      {
        type: 'text',
        name: 'page',
        rules: '',
        value: '',
        label: 'Page',
        placeHolder: ''
      }
    ],
    'Conference Paper': [
      {
        type: 'text',
        name: 'author',
        rules: 'required',
        value: '',
        label: 'Author(s)',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'title',
        rules: 'required',
        value: '',
        label: 'Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'editors',
        rules: '',
        value: '',
        label: 'Editor(s)',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'eventName',
        rules: '',
        value: '',
        label: 'Event Name',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'eventPlace',
        rules: '',
        value: '',
        label: 'Event Place',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'issued',
        rules: '',
        value: '',
        label: 'Issue Date',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisherPlace',
        rules: '',
        value: '',
        label: 'Publisher Place',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisherName',
        rules: '',
        value: '',
        label: 'Publisher Name',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'page',
        rules: '',
        value: '',
        label: 'Page',
        placeHolder: ''
      }
    ],
    'Journal Article': [
      {
        type: 'text',
        name: 'author',
        rules: 'required',
        value: '',
        label: 'Author(s)',
        placeHolder: ''
      },
      {
        type: 'number',
        name: 'issued',
        rules: 'required',
        value: '',
        label: 'Year',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'title',
        rules: 'required',
        value: '',
        label: 'Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'container-title',
        rules: '',
        value: '',
        label: 'Journal',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'volume',
        rules: '',
        value: '',
        label: 'Volume',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'issue',
        rules: '',
        value: '',
        label: 'Issue',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'page',
        rules: '',
        value: '',
        label: 'Pages',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'DOI',
        rules: '',
        value: '',
        label: 'DOI',
        placeHolder: ''
      }
    ],
    Report: [
      {
        type: 'text',
        name: 'author',
        rules: 'required',
        value: '',
        label: 'Author(s)',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'title',
        rules: 'required',
        value: '',
        label: 'Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'genre',
        rules: '',
        value: '',
        label: 'Genre',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisherPlace',
        rules: '',
        value: '',
        label: 'Publisher Place',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisher',
        rules: '',
        value: '',
        label: 'Publisher',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'issued',
        rules: '',
        value: '',
        label: 'Issue Date',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'reportNumber',
        rules: '',
        value: '',
        label: 'Report Number',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'documentNumber',
        rules: '',
        value: '',
        label: 'Document Number',
        placeHolder: ''
      }
    ],
    WebPage: [
      {
        type: 'text',
        name: 'author',
        rules: 'required',
        value: '',
        label: 'Author(s)',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'title',
        rules: 'required',
        value: '',
        label: 'Title',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'typeOfMedium',
        rules: '',
        value: '',
        label: 'Type Of Medium',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisherPlace',
        rules: '',
        value: '',
        label: 'Publisher Place',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'publisher',
        rules: '',
        value: '',
        label: 'Publisher',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'issued',
        rules: '',
        value: '',
        label: 'Issue Date',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'accessed',
        rules: '',
        value: '',
        label: 'Accessed Date',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'edition',
        rules: '',
        value: '',
        label: 'Edition',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'note',
        rules: '',
        value: '',
        label: 'Note',
        placeHolder: ''
      },
      {
        type: 'text',
        name: 'url',
        rules: '',
        value: '',
        label: 'URL',
        placeHolder: ''
      }
    ],
    Plain: [
      {
        type: 'textarea',
        name: 'plainText',
        rules: 'required',
        value: '',
        label: 'Plain Text',
        placeHolder: ''
      }
    ]
  }
};

type UserCitationTypes =
  | 'Book'
  | 'Generic'
  | 'Book Section'
  | 'Conference Paper'
  | 'Journal Article'
  | 'Report'
  | 'WebPage'
  | 'Plain';

const citationTypes: UserCitationTypes[] = Object.keys(
  allFormFields.types
) as UserCitationTypes[];

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class CitationForm extends mixins(EditorComputedValuesMixin) {
  @Prop(Object) public citation!: Citation;

  @Prop() public referencedCitationIds!: number[];

  @Prop({ default: false }) public isPendingCitation!: boolean;

  public selectedType: UserCitationTypes | null = 'Journal Article';

  public formFields: FormFieldsProperties[] = [];

  get citationTypes(): UserCitationTypes[] {
    if (this.isPendingCitation) {
      return ['Plain'] as UserCitationTypes[];
    } else {
      return citationTypes;
    }
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  @Action('projects/createCitation')
  public createCitation!: (payload: CreateCitationRequestPayload) => Citation;

  @Action('projects/editCitation')
  public editCitation!: (payload: EditCitationRequestPayload) => void;

  @Action('documents/removeRevisionCitation')
  public removeRevisionCitation!: (
    payload: RemoveRevisionCitationRequestPayload
  ) => void;

  @Action('documents/getRevisionCitations')
  public getRevisionCitations!: (
    payload: GetRevisionCitationsRequestPayload
  ) => Citation[];

  public created() {
    // init citation fields
    if (this.isEdit) {
      this.transformCitationToField();
    }
  }

  // Close modal on error
  @Watch('projectsApiState.createCitation.error')
  @Watch('projectsApiState.editCitation.error')
  @isDifferent
  @isTruthy
  public onCitationApiErrorResponse() {
    this.$emit('closeModal');
  }

  // Close modal on error and update Text sections
  @Watch('projectsApiState.createCitation.success')
  @Watch('projectsApiState.editCitation.success')
  @isDifferent
  @isTruthy
  public onCitationApiResponse() {
    if (this.isEdit && this.referencedCitationIds.includes(this.citation.id)) {
      // Update the edited citation with newly created citation

      this.removeRevisionCitation({
        documentId: this.documentId,
        revisionId: this.revisionId,
        citationId: this.citation.id
      });

      this.dirtyTextSections = this.dirtyTextSections.map((textSection) => {
        if (this.newlyCreatedCitation) {
          textSection.sectionValue = updateCitationInOps({
            ops: textSection.sectionValue!,
            citationId: this.citation.id,
            newCitationId: this.newlyCreatedCitation.id
          });
        }
        return { ...textSection };
      });
      this.dirtyBprs = this.dirtyBprs.map((subSection) => {
        if (this.newlyCreatedCitation) {
          subSection.content = updateCitationInOps({
            ops: subSection.content!,
            citationId: this.citation.id,
            newCitationId: this.newlyCreatedCitation.id
          });
        }
        return { ...subSection };
      });
    }

    const toastMessage: string = !this.isEdit
      ? `Citation Created Successfully`
      : `Citation Edited Successfully`;
    Toast.open({
      position: 'is-top',
      type: 'is-dark',
      message: toastMessage
    });

    // Fetch all revision citations
    this.getRevisionCitations({
      documentId: this.documentId,
      revisionId: this.revisionId
    });
    this.$emit('closeModal');
  }

  // Generate fields on citation type change
  @Watch('selectedType', { immediate: true })
  @isDifferent
  @isTruthy
  public onSelectedTypeChanged(type: UserCitationTypes) {
    const oldFormFields = this.formFields;
    const newFormFields = _cloneDeep(allFormFields.types[type]);
    this.formFields = newFormFields.map((formField) => {
      const oldField = oldFormFields.find(
        ({ label }) => label === formField.label
      );
      // Keep value when type change
      return oldField
        ? {
            ...formField,
            value: oldField.value
          }
        : formField;
    });
  }

  get isEdit(): boolean {
    return !!this.citation;
  }

  get citationPreview(): string {
    if (!this.formFields.length) {
      return '';
    }
    if (this.selectedType === 'Plain') {
      return _get(
        this.formFields.find((obj) => obj.name === 'plainText'),
        'value',
        ''
      );
    }
    const author = _get(
      this.formFields.find((obj) => obj.name === 'author'),
      'value',
      ''
    );
    const title = _get(
      this.formFields.find((obj) => obj.name === 'title'),
      'value',
      ''
    );
    if (!author && !title) {
      return '';
    }
    return author + ' . ' + title;
  }

  public async handleCitationFormSubmit() {
    const citation = this.transformFieldToCitation();
    // If pending editor or not cited, edit citation directly
    if (this.isEdit && !this.referencedCitationIds.includes(this.citation.id)) {
      return this.editCitation({
        projectId: this.projectId,
        citation
      });
    }

    const newCitation = await this.createCitation({
      projectId: this.projectId,
      citation,
      revisionId: this.revisionId
    });

    return newCitation;
  }

  public transformFieldToCitation() {
    let citationObject = {
      type: this.transformCitationFormTypeToCitationCSLType(
        this.selectedType as string
      )
    };
    if (this.isEdit) {
      citationObject = { ...citationObject, ...{ id: this.citation.id } };
    }
    this.formFields.forEach((obj) => {
      if (obj.name === 'author') {
        citationObject = {
          ...citationObject,
          ...{ [obj.name]: this.transformAuthorStringToAuthorArray(obj.value) }
        };
      } else {
        citationObject = { ...citationObject, ...{ [obj.name]: obj.value } };
      }
    });
    return citationObject;
  }

  public transformCitationToField() {
    if (!this.isEdit) {
      return [];
    }
    const citation = _cloneDeep(this.citation.content);
    this.selectedType = this.transformCitationCSLTypeToCitationFormType(
      citation.type
    );
    this.formFields = _cloneDeep(
      allFormFields.types[this.selectedType as UserCitationTypes]
    );
    this.formFields.forEach((field: FormFieldsProperties) => {
      field.value = citation[field.name];
    });
  }

  public transformCitationFormTypeToCitationCSLType(selectedType: string) {
    if (selectedType === 'Book') {
      return CitationTypes.BOOK;
    } else if (selectedType === 'Generic') {
      return CitationTypes.GENERIC;
    } else if (selectedType === 'Book Section') {
      return CitationTypes.BOOK_SECTION;
    } else if (selectedType === 'Conference Paper') {
      return CitationTypes.CONFERENCE_PAPER;
    } else if (selectedType === 'Journal Article') {
      return CitationTypes.JOURNAL_ARTICLE;
    } else if (selectedType === 'Report') {
      return CitationTypes.REPORT;
    } else if (selectedType === 'WebPage') {
      return CitationTypes.WEBPAGE;
    } else if (selectedType === 'Plain') {
      return CitationTypes.PLAIN;
    }
    return null;
  }

  public transformCitationCSLTypeToCitationFormType(selectedType: string) {
    if (selectedType === CitationTypes.BOOK) {
      return 'Book';
    } else if (selectedType === CitationTypes.GENERIC) {
      return 'Generic';
    } else if (selectedType === CitationTypes.BOOK_SECTION) {
      return 'Book Section';
    } else if (selectedType === CitationTypes.CONFERENCE_PAPER) {
      return 'Conference Paper';
    } else if (selectedType === CitationTypes.JOURNAL_ARTICLE) {
      return 'Journal Article';
    } else if (selectedType === CitationTypes.REPORT) {
      return 'Report';
    } else if (selectedType === CitationTypes.WEBPAGE) {
      return 'WebPage';
    } else if (selectedType === CitationTypes.PLAIN) {
      return 'Plain';
    }
    return null;
  }

  public transformAuthorStringToAuthorArray(authorsString: string) {
    const authorsArray = authorsString.split(',');
    return authorsArray.map((author) => {
      const authorArray = author.split(' ');
      const lastName: string = authorArray[authorArray.length - 1];
      let firstName: string = '';
      for (let i = 0; i < authorArray.length - 1; i++) {
        firstName =
          firstName === '' ? authorArray[i] : firstName + ' ' + authorArray[i];
      }
      return {
        given: firstName,
        family: lastName
      };
    });
  }
}
