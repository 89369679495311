import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, State } from 'vuex-class';
import { RootState } from '../store/store';
import {
  CheckPermissionRequestPayload,
  CheckPermissionResponsePayload,
} from '../store/modules/documents/types/documents.types';
import { get as _get } from 'lodash';
import { AuthInfo } from '../store/modules/auth/types/auth.types';
import { GeneralRoles } from '../jbi-shared/types/casbin-permission.types';

@Component({})
export class PermissionMixin extends Vue {
  get myRoles() {
    return this.$store.state.users.myRoles || [];
  }
  get isAdmin() {
    return this.myRoles.includes(GeneralRoles.Admin);
  }
  get isWkAdmin() {
    return this.myRoles.includes(GeneralRoles.WkAdmin);
  }
  // @State((state: RootState) => state.documents)
  // public documents!: string[];
  // @State((state: RootState) => state.auth.authInfo)
  // public authInfo!: AuthInfo;
  // @State(
  //   (state: RootState) => state.users.apiState.getMyRoles.loading,
  // )
  // public getMyRolesLoading!: AuthInfo;
  // @Action('users/getMyRoles')
  // public getMyRoles!: (
  //   payload: CheckPermissionRequestPayload,
  // ) => void;
  // get currentUsername() {
  //   return _get(this.authInfo, 'oicPayload.preferred_username', '');
  // }
  // get currentEmail() {
  //   return _get(this.authInfo, 'oicPayload.email', '');
  // }
  // get documentId(): number {
  //   return +this.$route.params.documentId;
  // }
  // get isDocumentReviewer() {
  //   if (this.myRoles) {
  //     const res = 'document_reviewer_' + this.documentId;
  //     return this.myRoles.some(o => o === res);
  //   }
  // }
  // get isDocumentAuthor() {
  //   if (this.myRoles) {
  //     const res = 'document_author_' + this.documentId;
  //     return this.myRoles.some(o => o === res);
  //   }
  // }
  // get isDocumentOwner() {
  //   if (this.myRoles) {
  //     const res = 'document_owner_' + this.documentId;
  //     return this.myRoles.some(o => o === res);
  //   }
  // }
  // public mounted() {
  //   if (!this.getMyRolesLoading) {
  //     return this.getMyRoles({
  //       documentId: this.documentId,
  //     });
  //   }
  // }
}
