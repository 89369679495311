
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { RootState } from '../../../store/store';
import { SingleUserSearchResult } from '../../../store/modules/users/types/users.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { mixins } from 'vue-class-component';
import {
  UpdateDocAssigneeRequestPayload,
  UpdateDocAssigneeResponsePayload
} from '../../../store/modules/documents/types/documents.types';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';

type User = Pick<SingleUserSearchResult, 'id' | 'fullName' | 'email'>;

@Component({})
export default class SearchResultAssigneeInput extends mixins(
  EditorCommonMixin
) {
  @Prop(Number) public readonly searchedDocumentId!: number;
  @Prop(String) public readonly existingAssigneeName!: string;
  @Prop(Boolean) public readonly invisible!: boolean;
  public searchText = '';
  public selectedUser: User | null = null;
  public isFocused = false;
  public assignedUserDisplayName: string = '';

  @Action('users/searchUsersByText')
  public searchUsersByText!: (text: string) => SingleUserSearchResult[];

  @Action('documents/updateDocAssignee')
  public updateDocAssignee!: (
    payload: UpdateDocAssigneeRequestPayload
  ) => Promise<UpdateDocAssigneeResponsePayload>;

  get users() {
    return (this.$store.state as RootState).users.searchUsersByTextResults;
  }

  @isDifferent
  @Watch('searchText', { immediate: true })
  @Debounce(500)
  public handleSearch(text: string) {
    this.searchUsersByText(text);
  }

  public created() {
    // set initial value
    this.assignedUserDisplayName = this.existingAssigneeName;
  }

  @Watch('selectedUser')
  @isDifferent
  public assignUserToDocument() {
    if (
      this.selectedUser &&
      this.selectedUser.id &&
      this.selectedUser.fullName !== this.assignedUserDisplayName
    ) {
      this.updateDocAssignee({
        documentId: this.searchedDocumentId,
        assigneeId: this.selectedUser!.id
      });
      this.assignedUserDisplayName = this.selectedUser.fullName;
    }
  }
}
