














































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ValidateDocumentErrorModal extends Vue {
  @Prop(String) public modalTitle!: string;
  @Prop() public error!: Array<{ [s: number]: string }>;
  @Prop({ type: String, default: '' }) public tdrError!: string;
  @Prop({ type: String, default: '' }) public action!: string;
}
