import { defaultApiState } from '@/store/types/general.types';
import { EditorsState } from './types/editors.types';

export const editorsState: EditorsState = {
  apiState: {
    getEditors: defaultApiState,
    fetchEditorRoles: defaultApiState,
    fetchEditorOptions: defaultApiState
  },
  editors: undefined,
  editorRoles: undefined,
  editorOptions: undefined
};
