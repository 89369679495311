










































import { mixins } from 'vue-class-component';
import { Component, Provide, Watch } from 'vue-property-decorator';
import { StagingEditorCommonMixin } from '../mixins/staging-editor-common.mixin';
import StagingEditorSideBar from './StagingEditorSideBar.vue';
import StagingEditorHeader from './StagingEditorHeader.vue';
import StagingEditor from './StagingEditor.vue';
import { EditorViewMode } from '@/utils/viewMode.mixin';
import StagingEditorSectionsTab from './tab/StagingEditorSectionsTab.vue';
import StagingEditorCitationsTab from './tab/StagingEditorCitationsTab.vue';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { UpdatePendingDocumentRequestPayload } from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import { PendingDocument } from '@/jbi-shared/types/document.types';
import Op from 'quill-delta/dist/Op';
import { opsToText } from '@/utils/quill-delta.util';
import { validateTextSection } from '@/utils/validate-pending.util';
import { isChrome } from '@/utils/browser.util';
import { chromeCitationPlaceholder } from '@/utils/editor.util';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  PendingDocumentCitationReference,
  PendingDocumentReferenceUpdate
} from '@/jbi-shared/types/document.types';
import { QuillCitationInsertionPayload } from '@/store/modules/documents/types/quill.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';
import PendingDocumentValidationErrorModal from './tab/PendingDocumentValidationErrorModal.vue';
import DocumentPublishValidationModal from '../../DocumentEditor/components/DocumentPublishValidationModal.vue';
import { uniqTagMap } from '@/jbi-shared/util/document.utils';
import { PartialDeep } from 'lodash';
import { GetRevisionCitationsRequestPayload } from '../../../store/modules/documents/types/documents.types';
import { Citation } from '../../../store/modules/documents/types/citations.types';
import { PendingTextSectionData } from '../../../jbi-shared/types/document.types';

@Component({
  components: {
    StagingEditorSideBar,
    StagingEditorSectionsTab,
    StagingEditorCitationsTab,
    StagingEditorHeader,
    StagingEditor,
    PendingDocumentValidationErrorModal
  }
})
export default class StagingEditorContainer extends mixins(
  StagingEditorCommonMixin
) {
  @Provide() public viewMode = EditorViewMode.editing;

  @State((state: RootState) => state.admin.pendingDocument)
  public pendingDocument!: PendingDocument;

  @State((state: RootState) => state.admin.apiState.fetchPendingDocument)
  public fetchPendingDocumentApi!: any;

  @State((state: RootState) => state.admin.apiState.publishPendingDocument)
  public publishPendingDocumentApi!: any;

  @Action('admin/updatePendingDocument')
  public updatePendingDocument!: (
    payload: PartialDeep<UpdatePendingDocumentRequestPayload>
  ) => void;

  @Action('admin/publishPendingDocument')
  public publishPendingDocument!: (documentId: number) => void;

  @Action('projects/getCitations')
  public getCitations!: (projectId: number) => void;

  @Action('documents/getRevisionsByDocumentId')
  public getRevisionsByDocumentId!: (documentId: number) => void;

  @Action('documents/getRevisionCitations')
  public getRevisionCitations!: (
    payload: GetRevisionCitationsRequestPayload
  ) => Citation[];

  public removeSection(textSection: PendingTextSectionData) {
    this.dirtyTextSections = this.dirtyTextSections.filter((section) => {
      return section.content.tempId != textSection.content.tempId;
    });
  }

  public isExpandedMiddleBar = false;

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  public created() {
    // Call API and get revisions of an ES doc to find the published version later
    this.getRevisionsByDocumentId(this.documentId);
  }

  public updated() {
    // the revisionId is updated once the document related API's are loaded
    // so we call this API once revisionId is updated
    if (this.existingDocumentDetail?.revision.id) {
      this.getRevisionCitations({
        documentId: this.documentId,
        revisionId: this.existingDocumentDetail?.revision.id
      });
    }
  }

  public selectedTab() {
    return this.$route.hash.substr(1) ? this.$route.hash.substr(1) : 'sections';
  }

  public toggleExpandMiddleBar() {
    this.isExpandedMiddleBar = !this.isExpandedMiddleBar;
  }

  public saveDocument() {
    const tagMaps: DirtyTagMap[] = uniqTagMap([...this.dirtyTagMaps!]);
    const payload: PartialDeep<UpdatePendingDocumentRequestPayload> = {
      pendingDocumentId: this.pendingDocument.id,
      projectId: this.pendingDocument.projectId,
      documentId: this.pendingDocument.documentId!,
      textSections: this.dirtyTextSections,
      searchDate: this.dirtySearchDate,
      documentTitle: this.dirtyDocumentTitle,
      authorSection: this.dirtyAuthors,
      bprSection: this.dirtyBprs,
      criterionSection: this.dirtyCriterions,
      relatedDocSection: this.dirtyRelatedDocs,
      referenceSection: this.dirtyReferences,
      ohsSection: this.dirtyOhs as any,
      citations: this.activeCitations,
      activeCitations: this.activeCitations,
      archivedCitations: this.archivedCitations,
      tags: tagMaps,
      tdrUri: this.tdrUri
    };

    this.updatePendingDocument(payload);
  }

  public publishDocument() {
    const revisions =
      (this.$store.state as RootState).documents.revisionsByDocumentId
        ?.revisions || [];
    const publishedRevisions = revisions.filter(
      (revision) => revision.publicationId
    );

    // There are some existing ES docs in our system don't have TDR in the published revision
    const latestPublishedRevision =
      publishedRevisions[publishedRevisions.length - 1];
    const isTdrSame = latestPublishedRevision
      ? latestPublishedRevision.tdr?.storageUri === this.tdrUri
      : false;

    if (!this.tdrUri) {
      this.$buefy.modal.open({
        parent: this,
        component: DocumentPublishValidationModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          modalTitle: 'Warning: Missing TDR'
        }
      });
    } else {
      this.$buefy.modal.open({
        parent: this,
        component: PendingDocumentValidationErrorModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          tdrError: isTdrSame
            ? 'Do not have any new TDR file in this version.'
            : ''
        },
        events: {
          'publish-anyway': () => {
            this.publishPendingDocument(this.pendingDocument.documentId!);
          }
        }
      });
    }
  }

  public updateSectionTitle(index: number, value: Op[]) {
    if (
      JSON.stringify(value) ===
        JSON.stringify(this.dirtyTextSections[index].content.sectionTitle) ||
      JSON.stringify(opsToText(value).trim()) ===
        JSON.stringify(
          opsToText(this.dirtyTextSections[index].content.sectionTitle!).trim()
        )
    ) {
      return;
    }

    // Clone textSection and update sectionTitle
    const updatedSection = JSON.parse(
      JSON.stringify(this.dirtyTextSections[index])
    );
    updatedSection.content.sectionTitle = value;

    this.dirtyTextSections = this.dirtyTextSections.map((textSection, i) =>
      i === index ? validateTextSection(updatedSection) : textSection
    );
  }

  public async updateSectionValue(index: number, value: Op[]) {
    if (
      JSON.stringify(value) ===
      JSON.stringify(this.dirtyTextSections[index].content.sectionValue)
    ) {
      return;
    }

    // Clone textSection and update sectionTitle
    const updatedSection = JSON.parse(
      JSON.stringify(this.dirtyTextSections[index])
    );
    updatedSection.content.sectionValue = value;

    this.dirtyTextSections = this.dirtyTextSections.map((textSection, i) =>
      i === index ? validateTextSection(updatedSection) : textSection
    );

    await this.$nextTick();
    this.updateCitationNumbers();
  }

  public async removeReferenceCitation(
    value: PendingDocumentCitationReference
  ) {
    const { label } = value;
    // Remove citation from all text section
    this.dirtyTextSections = this.dirtyTextSections.map((textSection) => {
      const updatedTextSection = _cloneDeep(textSection);
      const { sectionValue } = textSection.content;
      updatedTextSection.content.sectionValue = this.removeCitationOps(
        sectionValue || [],
        label.toString()
      );
      return updatedTextSection;
    });

    // Remove citation from references
    const referenceIndex = Number(label) - 1;
    const updatedReferences = [...this.dirtyReferences!.citationIds];
    updatedReferences.splice(referenceIndex, 1);
    this.dirtyReferences = {
      ...this.dirtyReferences,
      citationIds: updatedReferences
    };
  }

  public async updateReferenceCitation(value: PendingDocumentReferenceUpdate) {
    const { mode, label, previousCitationId, newCitationId } = value;
    // No changes, update nothing
    if (previousCitationId === newCitationId) {
      return;
    }

    // Check if new citation reference is in used
    const referenceCitationIds = [...this.dirtyReferences!.citationIds];
    const indexOfPreviousCitation = this.dirtyReferences!.citationIds.findIndex(
      (citationId) => citationId === previousCitationId
    );
    const indexOfNewCitation = this.dirtyReferences!.citationIds.findIndex(
      (citationId) => citationId === newCitationId
    );

    // New citation is one of existing reference
    switch (mode) {
      case 'merge':
        // Update text section's citation
        this.dirtyTextSections = this.dirtyTextSections.map((textSection) => {
          const updatedTextSection = _cloneDeep(textSection);
          const { sectionValue } = textSection.content;
          updatedTextSection.content.sectionValue = this.mergeCitationOps(
            sectionValue || [],
            label,
            previousCitationId,
            newCitationId
          );
          return updatedTextSection;
        });

        // Update references
        if (indexOfPreviousCitation !== -1) {
          referenceCitationIds[indexOfPreviousCitation] = newCitationId;
        }
        if (indexOfNewCitation !== -1) {
          referenceCitationIds.splice(indexOfNewCitation, 1);
        }
        break;
      case 'replace':
        // Update text section's citation
        this.dirtyTextSections = this.dirtyTextSections.map((textSection) => {
          const updatedTextSection = _cloneDeep(textSection);
          const { sectionValue } = textSection.content;
          updatedTextSection.content.sectionValue = this.replaceCitationOps(
            sectionValue || [],
            label,
            previousCitationId,
            newCitationId
          );
          return updatedTextSection;
        });

        // Update references
        if (indexOfPreviousCitation !== -1) {
          referenceCitationIds[indexOfPreviousCitation] = newCitationId;
        }

        // Clear new citation reference if it is in used previously
        if (this.dirtyReferences?.citationIds.includes(newCitationId)) {
          if (indexOfNewCitation !== -1) {
            referenceCitationIds[indexOfNewCitation] = '';
          }
        }
        break;
      default:
        break;
    }
    this.dirtyReferences = {
      ...this.dirtyReferences,
      citationIds: referenceCitationIds
    };
  }

  public replaceCitationOps(
    ops: Op[],
    label: string,
    previousCitationId: string,
    newCitationId: string
  ) {
    return ops!.map((op) => {
      const citation: QuillCitationInsertionPayload | undefined = _get(
        op,
        'insert.citation'
      );
      if (!citation) {
        return op;
      } else {
        // Replace previous label & citation id with new citation id. Existing new citation id becomes unassigned
        if (
          citation.label === label &&
          citation.citationId === previousCitationId
        ) {
          citation.citationId = newCitationId;
        } else if (citation.citationId === newCitationId) {
          citation.citationId = '';
        }
        return {
          ...op,
          insert: { citation }
        };
      }
    });
  }

  public mergeCitationOps(
    ops: Op[],
    label: string,
    previousCitationId: string,
    newCitationId: string
  ) {
    return ops!.map((op) => {
      const citation: QuillCitationInsertionPayload | undefined = _get(
        op,
        'insert.citation'
      );
      if (!citation) {
        return op;
      } else {
        // Update previous citation with new citation
        if (
          citation.citationId === previousCitationId ||
          citation.citationId === newCitationId
        ) {
          citation.citationId = newCitationId;
        }
        return {
          ...op,
          insert: { citation }
        };
      }
    });
  }

  public removeCitationOps(ops: Op[], label: string) {
    return ops!.filter((op) => {
      const citation: QuillCitationInsertionPayload | undefined = _get(
        op,
        'insert.citation'
      );
      if (!citation) {
        return true;
      }

      if (citation.label !== label) {
        return true;
      }

      return false;
    });
  }

  public onDragStart(ev: DragEvent) {
    const citationId = String(
      (ev.target as Element).getAttribute('data-citation-id')
    );
    if (!citationId) {
      ev.preventDefault();
      return;
    }

    // give it an empty string to quill to find index
    // ev.dataTransfer!.setData('text', `[${citationAuthor} ${citationYear}]`);
    if (isChrome) {
      ev.dataTransfer!.setData('text', chromeCitationPlaceholder);
    } else {
      ev.dataTransfer!.setData('text', '');
    }
    // @ts-ignore
    window.droppedCitationId = citationId;
    // disable ghost image when dragging, by setting the drag image to a transparent image
    ev.dataTransfer!.setDragImage(this.transparentImg, 0, 0);
  }

  @Watch('fetchPendingDocumentApi.success')
  @isDifferent
  @isTruthy
  public onFetchPendingDocumentSuccess() {
    this.getCitations(this.pendingDocument.projectId);
  }

  @Watch('publishPendingDocumentApi.success')
  @isDifferent
  @isTruthy
  public onPublishPendingDocumentSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      duration: 5000,
      message:
        'Document Published. </br> Note: Documents are being synced, There will be a slight delay in documents updates in Advanced Search.'
    });

    this.$router.push({
      path: `/projects/${this.pendingDocument.projectId}/documents/${this.pendingDocument.documentId}/editor`
    });
  }
}
