import { PendingBprData } from '../types/cplus-bpr.types';
import { CRITERION_TYPE } from '../types/criterions.types';
import { FullDocumentRevisionObject } from '../types/full-document-revision-object.types';
import { generateRandomId } from './document.utils';

export const generateDraftCriterion = (documentSectionId: number) =>
  ({
    criterionSubSectionId: 0,
    content: {
      type: CRITERION_TYPE.BOOLEAN,
      title: '',
      booleanOptions: ['Yes', 'No'],
    },
    tempId: generateRandomId(),
    bprs: [],
    documentSectionId,
  } as FullDocumentRevisionObject['revision']['sections']['criterionSection'][0]);

/*
 * Returns true if all linked BPR(s) for this criterion exists in the
 * pending document's BPR list.
 */
export const validateExistingCriterionLinkedBpr = (
  criterion: FullDocumentRevisionObject['revision']['sections']['criterionSection'][0],
  pendingBprs: PendingBprData[],
) => {
  let valid = true;
  const pendingBprIds = pendingBprs.map(
    (pendingBpr) => pendingBpr.bprSubSectionId,
  );
  criterion.bprs.forEach((linkedBpr) => {
    if (!pendingBprIds.includes(linkedBpr.bprSubSectionId)) {
      valid = false;
    }
  });
  return valid;
};
