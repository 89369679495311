import { EventLogsState, EventLog } from './types/event-logs.types';
import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';

export const eventLogsMutations = {
  GET_RECENT_EVENT_LOGS_LOADING(state: EventLogsState) {
    state.apiState.getRecentEventLogs = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_RECENT_EVENT_LOGS_SUCCESS(state: EventLogsState, payload: EventLog[]) {
    state.apiState.getRecentEventLogs = {
      ...defaultApiState,
      success: true
    };
    state.recentEventLogs = payload;
  },
  GET_RECENT_EVENT_LOGS_ERROR(state: EventLogsState, error: AxiosError) {
    state.apiState.getRecentEventLogs = {
      ...defaultApiState,
      error
    };
  }
};
