













































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { get as _get } from 'lodash';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import BaseTable from '@/components/base/BaseTable.vue';
import SortableTableHeader from '@/components/base/SortableTableHeader.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import TableActionDropdown from '@/components/base/TableActionDropdown.vue';
import { ApiState } from '@/store/types/general.types';
import { EditorDetails } from '@/jbi-shared/types/editor.types';
import EditorPositionsDialog from '@/views/AdminDashboard/components/EditorPositionsDialog.vue';
import { PendingDocCreationJobStatus } from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import { PendingDocumentCreationStatus } from '@/views/AdminDashboard/PendingDocumentsCreationStatusPage.vue';

@Component({
  components: {
    BaseTable,
    SortableTableHeader,
    BaseLoading,
    BaseModal,
    BasePagination,
    TableActionDropdown
  }
})
export default class PendingDocCreationJobList extends mixins(PaginationMixin) {
  @Prop() public items!: PendingDocumentCreationStatus[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  get PendingDocCreationJobStatus() {
    return PendingDocCreationJobStatus;
  }

  public getItemErrorMessage(item: PendingDocumentCreationStatus) {
    if (item.processJobStatus === PendingDocCreationJobStatus.Pending) {
      return '-';
    }
    return item.errorMessage || 'Pending document successfully created.';
  }

  public getDocumentEditorRouterLink(item: any) {
    if (item.processJobStatus !== PendingDocCreationJobStatus.Success) {
      return {
        name: 'editor',
        params: {
          projectId: item.uploadedDocInfo.project.id,
          documentId: item.projectDocumentId
        }
      };
    }
    return {
      name: 'staging-area',
      params: {
        projectId: item.uploadedDocInfo.project.id,
        documentId: item.projectDocumentId
      }
    };
  }
}
