



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { PendingDocumentError } from '@/jbi-shared/types/document.types';

@Component({ components: {} })
export default class StagingEditorError extends Vue {
  @Prop(Array)
  public pendingErrors!: PendingDocumentError[];

  get errorCount() {
    return this.pendingErrors.length;
  }

  public handleClick(sectionId: string) {
    // scroll section into view
    const target = document.getElementById(sectionId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
