





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ViewArchivedNoteModal extends Vue {
  @Prop({ type: String, default: '' }) public note!: string;
}
