import { OicPayload } from '../types/jaas-oauth2.types';
import { BackendUserEntity } from '../types/search.types';

export const getFullNameFromUser = (user: BackendUserEntity) => {
  if (!user) {
    return '';
  }
  const { oicPayload } = user;
  const givenName = oicPayload.given_name || '';
  const middleName = oicPayload.middle_name || '';
  const familyName = oicPayload.family_name || '';
  const name = oicPayload.name || '';
  let fullName = '';
  if (givenName) {
    fullName += givenName;
  }
  if (middleName) {
    fullName += ' ' + middleName;
  }
  if (familyName) {
    fullName += ' ' + familyName;
  }
  fullName = fullName.trim() || name;
  return fullName;
};

/**
 * This will return username as:
 * If oicPayload then, user's FirstName + LastName
 * else userEmail as username.
 */
export const getJBIUsername = (userEmail: string, oicPayload?: OicPayload) => {
  if (oicPayload && oicPayload.given_name && oicPayload.family_name) {
    const givenName = capitalizeFirstLetter(oicPayload.given_name);
    const familyName = capitalizeFirstLetter(oicPayload.family_name);
    return `${givenName} ${familyName} (${userEmail})`;
  }
  return userEmail;
};

export const capitalizeFirstLetter = (value: string) => {
  if (value.indexOf(' ') >= 0) {
    const name: string[] = value.split(/\s+/);
    const administratorName: string[] = [];
    name.map((item: string) => {
      administratorName.push(item[0].toUpperCase() + item.substring(1));
    });
    return administratorName.join(' ');
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};
