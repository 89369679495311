









































































































































































import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { StagingEditorCommonMixin } from '@/views/StagingArea/mixins/staging-editor-common.mixin';
import { mixins } from 'vue-class-component';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import { Component, Prop } from 'vue-property-decorator';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import {
  EditorFocusElementType,
  EditorFocusStatus
} from '@/components/editor/Editor.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import {
  CplusDocumentType,
  PendingTextSectionData
} from '@/jbi-shared/types/document.types';
import {
  formatAuthorForDisplay,
  getRelatedDocFromSourceEvidenceSummary
} from '@/jbi-shared/util/document.utils';
import { getArchivedPublications, Publication } from '@/utils/publication.util';
import StagingEditorError from './StagingEditorError.vue';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import SectionError from '@/components/editor/SectionEditor/SectionError.vue';
import SectionValueEditor from '@/components/editor/SectionEditor/SectionValueEditor.vue';
import {
  Author,
  PendingDocumentRawAuthorData
} from '@/jbi-shared/types/author.types';

dayjs.extend(isSameOrBefore);

@Component({
  components: {
    StagingEditorError,
    SectionError,
    SectionValueEditor,
    SectionTitleEditor: () =>
      import('@/components/editor/SectionEditor/SectionTitleEditor.vue'),
    DateSectionEditor: () =>
      import('@/components/editor/SectionEditor/DateSectionEditor.vue'),
    StagingAuthorSectionEditor: () =>
      import(
        '@/views/StagingArea/components/SectionEditor/StagingAuthorSectionEditor.vue'
      ),
    StagingBprSectionEditor: () =>
      import(
        '@/views/StagingArea/components/SectionEditor/BprEditor/StagingBprSectionEditor.vue'
      ),
    OhsSectionEditor: () =>
      import(
        '@/components/editor/SectionEditor/OhsSectionEditor/OhsSectionEditor.vue'
      ),
    StagingCriterionSectionEditor: () =>
      import(
        '@/views/StagingArea/components/SectionEditor/CriterionEditor/StagingCriterionSectionEditor.vue'
      ),
    StagingRelatedDocsSectionEditor: () =>
      import(
        '@/views/StagingArea/components/SectionEditor/RelatedDocEditor/StagingRelatedDocsSectionEditor.vue'
      ),
    PublicationSection: () =>
      import(
        '@/components/editor/SectionEditor/PublicationSection/PublicationSection.vue'
      ),
    StagingTextSectionEditor: () =>
      import(
        '@/views/StagingArea/components/SectionEditor/StagingTextSectionEditor.vue'
      ),
    StagingReferencesSection: () =>
      import(
        '@/views/StagingArea/components/SectionEditor/StagingReferencesSection.vue'
      ),
    RelatedRPDocsSectionEditor: () =>
      import(
        '@/components/editor/SectionEditor/RelatedRPDocsSectionEditor/RelatedRPDocsSectionEditor.vue'
      )
  }
})
export default class StagingEditor extends mixins(
  ViewModeMixin,
  StagingEditorCommonMixin
) {
  @Prop({ default: () => [] })
  public dirtySectionsTagMaps!: DirtyTagMap[];

  public focusStatus: EditorFocusStatus | null = null;

  get ohsSectionTitle() {
    return textToOps('Occupational Health and Safety Considerations');
  }

  get relatedRPDocs() {
    if (this.existingDocumentDetail) {
      return this.existingDocumentDetail.document.relatedRPDocumentData || [];
    } else {
      return [];
    }
  }

  /*
   * The search date has to be later than the existing document's search date.
   */
  get minValidSearchDate() {
    if (this.existingDocumentSearchDate) {
      const existingSearchDate = dayjs(this.existingDocumentSearchDate);
      return existingSearchDate.add(1, 'day').toDate();
    }
  }

  /*
   * The search date cannot be later than the pending document's creation date.
   */
  get maxValidSearchDate() {
    return dayjs(this.documentDetail?.uploadedAt).toDate();
  }

  public isFocused(id: number, type: EditorFocusElementType): boolean {
    return JSON.stringify({ type, id }) === JSON.stringify(this.focusStatus);
  }

  public focusOn(id: number, type: EditorFocusElementType): void {
    if (this.isFocused(id, type)) {
      return;
    }
    this.focusStatus = {
      id,
      type
    };
  }

  public blurOn(id: number, type: EditorFocusElementType): void {
    if (!this.isFocused(id, type)) {
      return;
    }
    this.focusStatus = null;
  }

  public getTextSecProps(textSection: Partial<TextSection>) {
    return {
      textSection,
      focusStatus: this.focusStatus,
      focusOn: this.focusOn,
      blurOn: this.blurOn,
      dirtySectionsTagMaps: this.dirtySectionsTagMaps,
      dirtyTagMaps: this.dirtyTagMaps,
      dirtyReferences: this.dirtyReferences,
      dirtyCitations: this.dirtyCitations,
      previousCitations: this.citationsOfProject?.citations
    };
  }
  public getTextSecListeners(i: number) {
    return {
      'update:sectionTitle': ($event: any) =>
        this.$emit('update:sectionTitle', { index: i, value: $event }),
      'update:sectionValue': ($event: any) =>
        this.$emit('update:sectionValue', { index: i, value: $event }),
      'update:referenceCitation': ($event: any) =>
        this.$emit('update:referenceCitation', $event),
      'update:removeCitation': ($event: any) =>
        this.$emit('update:removeCitation', $event),
      'update:dirtyTagMaps': ($event: any) => (this.dirtyTagMaps = $event)
    };
  }
  public getTextSecIndex(ts: PendingTextSectionData) {
    return this.dirtyTextSections.findIndex(
      (textSection: PendingTextSectionData) =>
        (typeof textSection.content.documentSectionId !== 'undefined' &&
          ts.content.documentSectionId ===
            textSection.content.documentSectionId) ||
        (typeof textSection.content.tempId !== 'undefined' &&
          ts.content.tempId === textSection.content.tempId)
    );
  }

  get textToOps() {
    return textToOps;
  }

  get opsToText() {
    return opsToText;
  }

  get EditorFocusElementType() {
    return EditorFocusElementType;
  }

  get archivedPublications(): Publication[] {
    if (!this.existingDocumentDetail) {
      return [];
    }
    return getArchivedPublications(this.existingDocumentDetail!.document);
  }

  get sourceEvidenceSummaryLink():
    | FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0]
    | undefined {
    const es = this.pendingProject?.projectDocuments?.find(
      (doc) => doc.documentType === CplusDocumentType.EvidenceSummary
    );
    if (!es) {
      return;
    }
    return getRelatedDocFromSourceEvidenceSummary(es);
  }

  get currentDocNodes() {
    return [
      this.pendingProject?.primaryResearchNode,
      ...(this.pendingProject?.researchNodes || [])
    ]
      .filter(Boolean)
      .map((n) => n?.name)
      .filter(Boolean) as string[];
  }

  get currentDocAuthors() {
    return (this.dirtyAuthors || []).map((d) => {
      if (d.isValid) {
        return formatAuthorForDisplay(d.content as Author);
      }
      return opsToText((d.content as PendingDocumentRawAuthorData).raw).trim();
    });
  }

  get currentDocumentId() {
    return this.documentDetail?.documentId;
  }
}
