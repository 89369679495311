import { Component, Vue } from 'vue-property-decorator';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { get as _get } from 'lodash';
import dayjs from 'dayjs';
import { getOwner, getCollaborators } from '@/utils/document.util';
import { DATE_FORMAT } from '@/utils/date.util';
import { GetUserDocumentsRequestPayload } from '@/store/modules/documents/types/documents.types';
import { PaginatedApiResponse, ApiState } from '@/store/types/general.types';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { DocumentListItemParam } from '@/components/listings/documents/documentListItem.types';

export const MyDocumentsMixin = (
  vuexActionName: string,
  vuexStatePath: string
) => {
  @Component({})
  class DocumentsMixin extends Vue {
    @Action(vuexActionName)
    public getDocuments!: (
      params: GetUserDocumentsRequestPayload
    ) => Promise<void>;

    @State((state: RootState) => _get(state, vuexStatePath))
    public paginatedDocuments!: PaginatedApiResponse<DocumentIndexingPayload>;

    get documents() {
      return {
        items: this.itemTransformer(_get(this.paginatedDocuments, 'items')),
        totalCount: _get(this.paginatedDocuments, 'total_count', 0)
      };
    }

    public itemTransformer(
      items: DocumentIndexingPayload[] | undefined
    ): DocumentListItemParam[] | undefined {
      if (items) {
        return items.map((item) => ({
          key: item.documentId,
          title: item.title || '(Title Not Provided)',
          documentType: item.documentType,
          date: dayjs(item.updatedAt).format(DATE_FORMAT),
          publicationStatus: item.publicationStatus,
          primaryResearchNode: item.primaryNode,
          secondaryResearchNodes: item.secondaryNodes,
          collaborators: getCollaborators(item),
          owner: getOwner(item),
          uniquePublicationId: item.uniquePublicationId,
          defaultDocDisplayId: item.defaultDocDisplayId,
          assigneeFullName: item.assigneeFullName,
          to: {
            name: 'editor',
            params: {
              documentId: String(item.documentId),
              projectId: String(item.projectId)
            }
          }
        }));
      }
    }
  }

  return DocumentsMixin;
};
