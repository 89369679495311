




























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FilteredProjectPayload } from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';
import { PendingDocCreationJobStatus } from '@/jbi-shared/types/cplus-endpoints/admin/document.types';

@Component({
  components: {}
})
export default class PendingDocCreationJobFilterBox extends Vue {
  @Prop(Object) public filterParams!: FilteredProjectPayload;

  get PendingDocCreationJobStatus() {
    return PendingDocCreationJobStatus;
  }
}
