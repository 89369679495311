











import { Component, Vue, Prop } from 'vue-property-decorator';
import PublicationRecord from './PublicationRecord.vue';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import Op from 'quill-delta/dist/Op';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { Publication } from '../../../../utils/publication.util';

@Component({
  components: {
    PublicationRecord
  }
})
export default class PublicationSection extends mixins(ViewModeMixin) {
  public drag = false;
  @Prop() public archivedPublications!: Publication[];
}
