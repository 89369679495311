

















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FilteredProjectPayload } from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';
import { PendingDocCreationJobStatus } from '@/jbi-shared/types/cplus-endpoints/admin/document.types';
import { FilteredPendingDocCreationHistoryParams } from '../../UploadHistoryPage.vue';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  components: {}
})
export default class PendingDocCreationHistoryFilterBox extends Vue {
  @Prop(Object) public filterParams!: FilteredPendingDocCreationHistoryParams;

  get PendingDocCreationJobStatus() {
    return PendingDocCreationJobStatus;
  }

  public dateToDisplayedDate(dates: string[]) {
    if (Array.isArray(dates) && dates.length > 1) {
      if (dates.some((d) => !d)) {
        alert(`Invalid Date Range ${JSON.stringify(dates)}`);
        return;
      }

      const startDate = dates?.[0]
        ? dayjs(dates?.[0]).format('DD MMM YYYY')
        : undefined;
      const endDate = dates?.[1]
        ? dayjs(dates?.[1]).format('DD MMM YYYY')
        : undefined;

      return `${startDate} - ${endDate}`;
    }
  }

  public guessLocalTimezone() {
    return dayjs.tz.guess();
  }
}
