














import { Component, Vue, Prop } from 'vue-property-decorator';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import { ProjectDocument } from '@/store/modules/projects/types/project-details.types';

@Component({})
export default class ProjectEditorHeaderTabTag extends Vue {
  @Prop(Boolean) public active!: boolean;
  @Prop(String) public status!: RevisionPublicationStatus;

  public isPublishedDoc(status: RevisionPublicationStatus) {
    return status === RevisionPublicationStatus.Published;
  }

  public isDraftDoc(status: RevisionPublicationStatus) {
    return status === RevisionPublicationStatus.Draft;
  }

  public isArchivedDoc(status: RevisionPublicationStatus) {
    return status === RevisionPublicationStatus.Archived;
  }
}
