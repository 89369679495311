import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
  QuillCitationInsertionPayload,
  QuillContainerElement
} from '@/store/modules/documents/types/quill.types';
import delay from 'delay';
import Op from 'quill-delta/dist/Op';
import cuid from 'cuid';
import Quill from 'quill';
import Delta from 'quill-delta';
import SectionEditorToolbar from '@/components/editor/SectionEditor/SectionEditorToolbar.vue';
import SectionEditorHeader from '@/components/editor/SectionEditor/SectionEditorHeader.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { QuillCitation } from './QuillCitation.module';
import { isEqual as _isEqual, omit as _omit, get as _get } from 'lodash';
import { Throttle } from '../../../jbi-shared/util/throttle.vue-decorator';
import { SectionValueEditorToolbarHandlingMixin } from './SectionValueEditorToolbarHandlingMixin.mixin';
import { take } from 'rxjs/operators';
import { EditorComputedValuesMixin } from '../../../views/DocumentEditor/mixins/editor-computed-values.mixin';
import { isDifferentDeep } from '../../../jbi-shared/util/watcher.vue-decorator';
import { isChrome } from '../../../utils/browser.util';
import { chromeCitationPlaceholder } from '../../../utils/editor.util';

Quill.register({
  'formats/citation': QuillCitation
});
Quill.debug('error');

export const getEditorOption = ({
  toolbarElemId,
  placeholder
}: {
  toolbarElemId: string;
  placeholder: string;
}) => ({
  modules: {
    toolbar: `#${toolbarElemId}`,
    keyboard: {
      bindings: {
        tab: false,
        handleTab: {
          key: 9,
          handler() {
            // Do nothing
          }
        }
      }
    }
  },
  placeholder
});

@Component({
  components: {
    SectionEditorToolbar,
    SectionEditorHeader
  },
  directives: {
    onClickaway
  }
})
export class SectionValueEditorMixin extends mixins(
  SectionValueEditorToolbarHandlingMixin,
  EditorComputedValuesMixin
) {
  @Prop(Array) public readonly sectionValue!: Op[];

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  @Prop({ type: String, default: `Insert text here ...` })
  public readonly placeholder!: string;

  @Prop({ type: Boolean, default: false })
  public readonly isFocused!: boolean;

  public toolbarElemId: string = cuid();

  public dropIndex: number = 0;

  public editorOption = getEditorOption({
    toolbarElemId: this.toolbarElemId,
    placeholder: this.placeholder
  });

  public selectionIndex: number = 0;

  public editorInstance(): Quill | undefined {
    if (!this.$refs.editor) {
      return;
    }
    return (this.$refs.editor as QuillContainerElement).quill;
  }

  public hasFocus(): boolean {
    return this.editorInstance() ? this.editorInstance()!.hasFocus() : false;
  }

  public blurEditor(): void {
    if (this.hasFocus()) {
      this.editorInstance()!.blur();
    }
  }

  public mounted() {
    // configure sanitization for copy-pasted content in Quill
    this.editorInstance()!.clipboard.addMatcher(
      Node.ELEMENT_NODE,
      this.sanitizeClipboardContent.bind(this)
    );

    this.setEditorContent();
    this.editorInstance()!.on(
      'editor-change',
      this.handleEditorChange.bind(this)
    );

    this.attachCustomDropHandler();
  }

  public beforeDestroy() {
    this.editorInstance()!.off(
      'editor-change',
      this.handleEditorChange.bind(this)
    );
  }

  public setEditorContent() {
    const ops = this.sectionValue;
    this.editorInstance()!.setContents(new Delta(ops));
  }

  public sanitizeClipboardContent(node: Element, delta: Delta) {
    /*
      this commented code is for forcing Quill to paste as plain text, leaving here as reference
    */
    // const plaintext = node.textContent || '';
    // return new Delta().insert(plaintext);

    // remove problematic attributes
    delta.ops = delta.ops.map((op) => _omit(op, 'attributes.color'));
    return delta;
  }

  public async quitEditing() {
    this.$emit('blur');
    this.editorInstance()!.setContents(new Delta(this.sectionValue));
  }

  public async handleDrop() {
    const originalContent = this.editorInstance()!.getContents();
    const citationId: string | number =
      // @ts-ignore
      window.droppedCitationId;

    this.editorInstance()!.insertEmbed(this.dropIndex, 'citation', {
      label: '-',
      citationId
    } as QuillCitationInsertionPayload);

    // wait for the parent to be updated once
    await this.$watchAsObservable('sectionValue').pipe(take(1)).toPromise();
    this.editorInstance()!.setContents(new Delta(this.sectionValue));
  }

  public handleClickAway() {
    this.$emit('blur');
  }

  public placeCursorAtTheEnd() {
    const len = this.editorInstance()!.getLength();
    this.editorInstance()!.setSelection(len, 0);
  }

  public handleIconInsertion(label: string) {
    if (!this.editorInstance()) {
      return;
    }
    this.editorInstance()!.insertText(this.selectionIndex, label);
    this.editorInstance()!.setSelection(this.selectionIndex + 1, 0);
  }

  @Throttle(300)
  public handleEditorChange(type: 'text-change' | 'selection-change') {
    // update selection index
    const range = this.editorInstance()!.getSelection();
    this.selectionIndex = range ? range.index : this.selectionIndex;

    if (type === 'text-change') {
      this.handleWeirdDuplicatedCitationQuillBug();
      this.removeExtraCharacterAfterCitationInChrome();
    }

    // update SectionValue
    this.$emit('update:sectionValue', this.editorInstance()!.getContents().ops);
  }

  // fix weird duplicated citations
  /*
    sample patterns for duplicated citations
    [
      {
        "insert": {
          "citation": {
            "citationId": "21",
            "label": "[1 1]"
          }
        }
      },
      {
        "insert": "\n"
      },
      {
        "insert": {
          "citation": {
            "citationId": "21",
            "label": "[1 1]"
          }
        }
      }
    ]
     */
  public handleWeirdDuplicatedCitationQuillBug() {
    const content: Delta = this.editorInstance()!.getContents();
    const newOps = content.ops
      .map((op, i) => {
        if (
          _get(op, 'insert.citation') &&
          _get(content.ops[i + 1], 'insert') === '\n' &&
          _get(content.ops[i + 2], 'insert.citation') &&
          _isEqual(
            _get(op, 'insert.citation'),
            _get(content.ops[i + 2], 'insert.citation')
          )
        ) {
          // remove dups
          content.ops[i + 1] = (null as unknown) as Op;
          content.ops[i + 2] = (null as unknown) as Op;
        }
        return op;
      })
      .filter(Boolean);
    if (newOps.length !== content.ops.length) {
      content.ops = newOps;
      this.editorInstance()!.setContents(content);
    }
  }

  public removeExtraCharacterAfterCitationInChrome() {
    if (!isChrome) {
      return;
    }
    let changed = false;
    const content: Delta = this.editorInstance()!.getContents();
    const newOps = content.ops.map((op, i) => {
      const insertObj = _get(content.ops[i + 1], 'insert');
      if (_get(op, 'insert.citation') && typeof insertObj === 'string') {
        if (
          // first character matched
          insertObj[0] === chromeCitationPlaceholder
        ) {
          content.ops[i + 1].insert = (content.ops[i + 1]
            .insert as string).substring(1);
          changed = true;
        } else if (
          // second character matched
          insertObj[1] === chromeCitationPlaceholder
        ) {
          content.ops[i + 1].insert = (content.ops[i + 1]
            .insert as string).substring(2);
          changed = true;
        }
      }
      return op;
    });
    if (changed) {
      content.ops = newOps;
      this.editorInstance()!.setContents(content);
    }
  }

  @isDifferentDeep
  @Watch('usedCitationIds')
  public async onCitationUsageChanged() {
    // update citation index when necessary
    const containerElem = _get(this.$refs.editor, '$el') as Element;
    if (!containerElem) {
      return;
    }
    this.setEditorContent();
    await this.$watchAsObservable('sectionValue').pipe(take(1)).toPromise();
    this.editorInstance()!.blur();
  }

  public attachCustomDropHandler() {
    this.editorInstance()!.root.addEventListener('drop', (e) => {
      let native;
      if (document.caretRangeFromPoint) {
        native = document.caretRangeFromPoint(e.clientX, e.clientY);
      } else if (document.caretPositionFromPoint) {
        const position = document.caretPositionFromPoint(e.clientX, e.clientY);
        native = document.createRange();
        native.setStart(position!.offsetNode, position!.offset);
        native.setEnd(position!.offsetNode, position!.offset);
      } else {
        return;
      }
      // @ts-ignore
      const normalized = this.editorInstance()!.selection.normalizeNative(
        native
      );
      // @ts-ignore
      const range = this.editorInstance()!.selection.normalizedToRange(
        normalized
      );
      this.dropIndex = range.index;
      this.handleDrop();
    });
  }
}
