












































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import Multiselect from 'vue-multiselect';
import { useAction, useDocumentsState, useApiState } from '@/utils/store.util';
import { MeshDto } from '@/jbi-shared/util/mesh.util';
import MeshConceptModal from '@/components/editor/SectionEditor/MeshConceptModal.vue';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { TagEntityTypeEnum, TagType } from '@/jbi-shared/types/document.types';
import { get as _get } from 'lodash';
import { compareTwoStrings } from 'string-similarity';
import { isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import { Action } from 'vuex-class';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';

@Component({ components: { Multiselect, MeshConceptModal } })
export default class MeshTagSearch extends mixins(EditorCommonMixin) {
  @Prop(Array) public value!: string[];

  @Action('documents/getRdfMeshTags')
  public getRdfMeshTags!: (payload: string) => void;

  public selectedTags: any[] = [];

  public search = '';

  get meshRawMeshTree() {
    return useDocumentsState.call(this).meshTree;
  }

  get getRdfMeshTagsState() {
    return useApiState.call(this, 'documents/getRdfMeshTags');
  }

  get rdfMeshTags() {
    return useDocumentsState.call(this).rdfMeshTags || [];
  }

  get searchedString() {
    return this.search || '';
  }

  // Filter MESH data by calling API to MESH RDF Server
  get filteredRdfMeshTag(): MeshDto[] {
    const limit = 20;
    const filteredMeshTags = this.rdfMeshTags;
    const displayMeshElements = filteredMeshTags.filter(
      (meshTag) => !this.selectedTags.includes(meshTag.keyword)
    );

    if (displayMeshElements.length > limit) {
      return displayMeshElements.slice(limit);
    }
    return displayMeshElements;
  }

  // Filter MESH tree from our database (xml file based)
  get filteredMeshTree() {
    let limit = 20;
    const displayMeshElements = [];
    // Filter Based on searched Query
    // For Peformance limiting it to 20 elements
    if (this.searchedString !== '') {
      for (let i = 0; i < limit; i = i + 1) {
        const filteredMeshTreeContent = this.meshRawMeshTree!.flatContent;
        const valueAlreadySelected = this.selectedTags.find(
          (element) => element.keyword === filteredMeshTreeContent[i].keyword
        );
        // Skip the value if already selected
        if (valueAlreadySelected) {
          limit = limit < filteredMeshTreeContent.length ? limit + 1 : limit;
        } else {
          const searchMatch =
            filteredMeshTreeContent[i].keyword
              .toString()
              .toLowerCase()
              .indexOf(this.searchedString.toLowerCase()) >= 0;
          if (searchMatch) {
            displayMeshElements.push({
              ...filteredMeshTreeContent[i]
            });
          } else {
            limit = limit < filteredMeshTreeContent.length ? limit + 1 : limit;
          }
        }
      }
      return displayMeshElements;
    } else if (this.meshRawMeshTree) {
      // Default Result Set of 20
      for (let i = 0; i < limit; i = i + 1) {
        const filteredMeshTreeContent = this.meshRawMeshTree!.flatContent;
        const valueAlreadySelected = this.selectedTags.find(
          (element) => element.keyword === filteredMeshTreeContent[i].keyword
        );
        // Skip the value if already selected
        if (valueAlreadySelected) {
          limit = limit < filteredMeshTreeContent.length ? limit + 1 : limit;
        } else {
          displayMeshElements.push({
            ...filteredMeshTreeContent[i]
          });
        }
      }
      return displayMeshElements;
    } else {
      return [];
    }
  }

  /**
   * This function is used by mesh modal child component only
   * Special map added in the end to transform the filtered result into TagMap
   * structure so that that the same Mesh search and tree modal can be reused
   * @param tagMaps
   * @param search
   * @param limit
   */
  public filterTag(tagMaps: any[], search = this.search, limit = 20) {
    return tagMaps
      .filter((option) => {
        if (option) {
          return (
            option.keyword
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
          );
        }
      })
      .sort((a, b) => {
        return (
          compareTwoStrings(b.keyword, search) -
          compareTwoStrings(a.keyword, search)
        );
      })
      .map((el) => {
        el.mesh = {
          ...el
        };
        return el;
      });
  }

  get meshTags(): Readonly<MeshDto[]> {
    return this.meshRawMeshTree!.flatContent;
  }

  public created() {
    if (!this.getRdfMeshTagsState!.loading && this.rdfMeshTags) {
      this.getRdfMeshTags('');
    }
  }

  public async mounted() {
    this.selectedTags = this.value;
  }

  // Update mesh tag in case query change to match with URL params
  @Watch('$route.query', { immediate: true })
  private onRoute(value: any, prevValue: any) {
    this.selectedTags = this.value;
  }

  @Watch('selectedTags')
  public updateMeshTags(values: any) {
    const meshTagStringArray = values.map(
      (value: any) => value.keyword || value
    );
    this.$emit('input', meshTagStringArray);
  }

  @isDifferent
  @Watch('search')
  @Debounce(500)
  public onSearch() {
    this.getRdfMeshTags(this.search);
  }

  public capitalizeWords(stringData: string) {
    return stringData
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  public addTagMap(e: any) {
    if (e.keyword !== '') {
      const existingValue = this.selectedTags.find(
        (op) => op.keyword === e.keyword
      );
      if (!existingValue) {
        this.selectedTags.push(e);
        this.search = '';
      }
    } else if (e.mesh) {
      // Re-transforming back from TagMap to MeshTag Structure when the selected tag comes form Mesh Modal
      const existingValue = this.selectedTags.find(
        (op) => op.keyword === e.mesh.keyword
      );
      if (!existingValue) {
        this.selectedTags.push(e.mesh);
      }
    }
  }

  public selectPath(e: string) {
    const meshTagObject = this.meshTags.find((op) => op?.path === e);
    if (meshTagObject) {
      this.addTagMap(meshTagObject);
    }
  }

  public openMeshModal() {
    const myModal = this.$buefy.modal.open({
      parent: this,
      component: MeshConceptModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        addTagMap: this.addTagMap,
        selectedPaths: [],
        selectPath: this.selectPath,
        allMeshOptions: this.meshRawMeshTree!.flatContent,
        filterTag: this.filterTag
      },
      events: {}
    });
  }

  get allMeshOptions(): DirtyTagMap[] {
    return this.meshTags!.map((mesh) => ({
      entityId: 1,
      entityType: TagEntityTypeEnum.document,
      tagType: TagType.MeSH,
      mesh
    }));
  }

  public handleFocus() {
    let dropdown: Element = _get(
      this,
      '$refs.input.$refs.autocomplete.$refs.dropdown'
    );
    if (dropdown) {
      dropdown = dropdown.querySelector('.dropdown-content')!;
    }
    if (dropdown) {
      this.$nextTick(() => {
        dropdown.scrollTop = 0;
      });
    }
  }
}
