

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import { isDifferent } from '@/jbi-shared/util/watcher.vue-decorator';
import AllDocuments from './components/AllDocuments.vue';
import AssignedDocuments from './components/AssignedDocuments.vue';
import CreatedDocuments from './components/CreatedDocuments.vue';
import { RootState } from '@/store/store';

export enum UserDocumentTabs {
  All = 'all',
  CREATED = 'created',
  ASSIGNED = 'assigned'
}

const tabs: string[] = [...Object.values(UserDocumentTabs)];

@Component({
  components: {
    Container,
    DashboardHeader,
    AllDocuments,
    AssignedDocuments,
    CreatedDocuments
  }
})
export default class MyDocumentsPage extends Vue {
  public currentTab = 0;

  get query() {
    return this.$route.query;
  }

  get UserDocumentTabs() {
    return UserDocumentTabs;
  }

  public created() {
    if (tabs.indexOf(this.query.tab as string) === -1) {
      this.$router.replace({
        query: {
          tab: tabs[0]
        }
      });
    }
    this.currentTab = tabs.indexOf(this.query.tab as string);
  }

  public handleCurrentTabInput(value: number) {
    this.currentTab = value;
    this.$router.push({
      query: {
        tab: tabs[value]
      }
    });
  }

  @Watch('query', { immediate: true })
  public onRouteChange(value: any) {
    const { tab } = value;
    this.currentTab = tabs.indexOf(tab as string);
  }
}
