import { get as _get } from 'lodash';
import normalizeUrl from 'normalize-url';

// handle DOI
// DOI usage reference :
// https://library.uic.edu/help/article/1966/what-is-a-doi-and-how-do-i-use-them-in-citations
export const doiToDoiLink = (doi: string | undefined) => {
  if (doi) {
    const isUrl = doi.startsWith('http') || doi.includes('doi.org');
    if (!isUrl) {
      doi = `http://doi.org/${doi}`;
    }
    doi = normalizeUrl(doi);
  }

  return doi;
};

// <sup>1</sup>
export const isSingleCitationFunc = (str: string) => !isNaN(Number(str));
// <sup>1-3</sup>
export const isRangeCitationFunc = (str: string) => {
  let charArr = String(str).split('');
  charArr = charArr.map((s) => s.trim());
  charArr = charArr.filter(Boolean);
  return (
    charArr.length === 3 &&
    charArr[1] === '-' &&
    !isNaN(Number(charArr[0])) &&
    !isNaN(Number(charArr[charArr.length - 1]))
  );
};
// <sup>1, 2, 5</sup>
export const isCommaSeperatedCitationFunc = (str: string) => {
  let charArr = String(str).split('');
  charArr = charArr.map((s) => s.trim());
  charArr = charArr.filter(Boolean);
  return (
    charArr.includes(',') &&
    !isNaN(Number(charArr[0])) &&
    !isNaN(Number(charArr[charArr.length - 1])) &&
    charArr
      .join('')
      .split(',')
      .map((s) => !isNaN(Number(s)))
  );
};

// Issue date will be in YYYY/MM/DD format
export const getDatePartsFromIssueDate = (issueDate : string) => {
  const dateValueArray = issueDate ? issueDate.split('/') : [];
  return [
    {
      'date-parts': [
        dateValueArray[0] || '',
        dateValueArray[1] || '',
        dateValueArray[2] || ''
      ]
    }
  ];
};
