import { Component } from 'vue-property-decorator';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { uniq as _uniq, uniqBy as _uniqBy } from 'lodash';
import { mixins } from 'vue-class-component';
import { EditorStateMixin } from './editor-state.mixin';
import {
  pipe as fp_pipe,
  filter as fp_filter,
  map as fp_map,
  flatten as fp_flatten
} from 'lodash/fp';
import { QuillCitationInsertionPayload } from '@/store/modules/documents/types/quill.types';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { RootState } from '@/store/store';
import { getReferenceSection } from '@/utils/editor.util';
import {
  CplusDocumentType,
  SectionType
} from '@/jbi-shared/types/document.types';
import { opsToText } from '@/utils/quill-delta.util';
import { EditorViewMode } from '@/utils/viewMode.mixin';
import { ConvertedLegacyDocumentPayload } from '@/jbi-shared/types/legacy-document.types';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@/utils/date.util';
import {
  getSourceEvidenceSummaryFromDocument,
  SuggestedDocumentType
} from '@/jbi-shared/util/document.utils';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';

@Component({})
export class EditorComputedValuesMixin extends mixins(EditorStateMixin) {
  get citationsOfProject() {
    return (this.$store.state as RootState).projects.citationsOfProject;
  }

  get citationsOfRevision() {
    return (this.$store.state as RootState).documents.citationsOfRevision || [];
  }

  get newlyCreatedCitation() {
    return (this.$store.state as RootState).projects.createdCitation;
  }

  get documentByRevisionId() {
    if (this.$route.name === 'preview-revision') {
      return (this.$store.state as RootState).documents.documentByRevisionId;
    }
  }

  get documentByVersionId() {
    if (this.$route.name === 'document-versions') {
      return (this.$store.state as RootState).documents.documentByVersionId;
    }
  }

  get documentByPublicationId() {
    if (this.$route.name === 'preview-publication') {
      return (this.$store.state as RootState).documents.documentByPublicationId;
    }
  }

  get allLegacyDocVersionsByBaseDocumentId() {
    if (this.$route.name === 'document-versions-legacy') {
      return (this.$store.state as RootState).documents
        .allLegacyDocVersionsByBaseDocumentId;
    }
  }

  get legacyContentByBaseDocumentId() {
    if (
      this.$route.name === 'document-versions-legacy' ||
      this.$route.name === 'preview-legacy-documents'
    ) {
      return (this.$store.state as RootState).documents
        .legacyContentByBaseDocumentId;
    }
  }

  get convertedLegacyDocumentDetailByBaseDocumentId():
    | ConvertedLegacyDocumentPayload['convertedDocument']
    | undefined {
    if (
      this.$route.name === 'document-versions-legacy' ||
      this.$route.name === 'preview-legacy-documents'
    ) {
      return this.legacyContentByBaseDocumentId
        ? this.legacyContentByBaseDocumentId.convertedDocument
        : undefined;
    }
  }

  get documentDetail() {
    const documentByRevisionId = this.documentByRevisionId;
    const documentByPublicationId = this.documentByPublicationId;
    const documentByVersionId = this.documentByVersionId;
    const documentDetail = (this.$store.state as RootState).documents
      .documentDetail;
    const convertedLegacyDocumentDetailByBaseDocumentId = this
      .convertedLegacyDocumentDetailByBaseDocumentId;
    if (this.$route.name === 'document-versions') {
      return documentByVersionId;
    } else if (this.$route.name === 'preview-publication') {
      return documentByPublicationId;
    } else if (this.$route.name === 'preview-revision') {
      return documentByRevisionId;
    } else if (this.$route.name === 'preview-legacy-documents') {
      return convertedLegacyDocumentDetailByBaseDocumentId;
    } else if (this.$route.name === 'document-versions-legacy') {
      return convertedLegacyDocumentDetailByBaseDocumentId;
    } else {
      return documentDetail;
    }
  }

  get projectDetail() {
    return (
      (this.$store.state as RootState).projects.projectDetail ||
      this.documentDetail?.project ||
      undefined
    );
  }

  get projectTitle() {
    return (
      (this.$store.state as RootState).projects.projectDetail?.projectTitle ||
      this.documentDetail?.project?.title ||
      ''
    );
  }

  get documentType(): CplusDocumentType {
    return (
      this.documentDetail?.document?.documentType ||
      this.documentByRevisionId?.document?.documentType ||
      this.documentByPublicationId?.document?.documentType ||
      this.documentByVersionId?.document?.documentType ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.document
        ?.documentType ||
      (('' as unknown) as CplusDocumentType)
    );
  }

  get documentTitle(): string {
    return (
      this.documentDetail?.document?.title ||
      this.documentByRevisionId?.document?.title ||
      this.documentByPublicationId?.document?.title ||
      this.documentByVersionId?.document?.title ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.document?.title ||
      ''
    );
  }

  get revisionName() {
    if (!this.documentDetail) {
      return '';
    }
    const { revision } = this.documentDetail;
    return revision.name || dayjs(revision.createdAt).format(DATE_TIME_FORMAT);
  }

  get allCitations(): Citation[] {
    const allCitations = _uniqBy(
      [
        ...(this.citationsOfProject?.citations || []),
        ...(this.documentDetail?.project?.citations || []),
        ...(this.documentByRevisionId?.project?.citations || []),
        ...(this.documentByPublicationId?.project?.citations || []),
        ...(this.documentByVersionId?.project?.citations || []),
        ...(this.convertedLegacyDocumentDetailByBaseDocumentId?.project
          .citations || [])
      ],
      (c) => c.id
    );
    return allCitations;
  }

  get citations(): Citation[] {
    return (this.citationsOfProject?.citations || []).filter((citation) => {
      return !citation.isDeleted;
    });
  }

  get usedCitationIds(): number[] {
    return fp_pipe(
      // don't check deleted
      fp_filter(({ deleted }: TextSection) => !deleted),
      fp_map('sectionValue'),
      fp_flatten,
      // check quill Delta content
      fp_map(
        // @ts-ignore
        (op) => op?.insert?.citation as QuillCitationInsertionPayload
      ),
      fp_filter(Boolean),
      // get citationId from Quill Delta content
      fp_map('citationId'),
      fp_map(Number)
    )([...this.dirtyTextSections, ...this.dirtyBprs]);
  }

  get uniqUsedCitationIds(): number[] {
    return _uniq(this.usedCitationIds);
  }

  get usedOhsAssets(): number[] | undefined {
    return this.dirtyOhs;
  }

  get ohsAssets() {
    return (this.$store.state as RootState).documents.ohsAssets;
  }

  get referenceSection(): Partial<TextSection> {
    const citationIds: number[] =
      this.uniqUsedCitationIds ||
      this.documentDetail?.revision.sections?.referenceSection?.citationIds ||
      this.documentByRevisionId?.revision.sections?.referenceSection
        ?.citationIds ||
      this.documentByPublicationId?.revision.sections?.referenceSection
        ?.citationIds ||
      this.documentByVersionId?.revision.sections?.referenceSection
        ?.citationIds ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision.sections
        ?.referenceSection?.citationIds ||
      [];
    return getReferenceSection(citationIds, this.allCitations);
  }

  get originalTagMaps(): DirtyTagMap[] {
    return (
      this.documentDetail?.revision?.tags ||
      this.documentByRevisionId?.revision?.tags ||
      this.documentByPublicationId?.revision?.tags ||
      this.documentByVersionId?.revision?.tags ||
      this.documentByVersionId?.revision?.tags ||
      []
    );
  }

  get originalDocumentTagMaps(): DirtyTagMap[] {
    return this.originalTagMaps.filter(
      ({ entityType }) => entityType === 'Document'
    );
  }

  get criterions():
    | FullDocumentRevisionObject['revision']['sections']['criterionSection']
    | undefined {
    return (
      this.documentDetail?.revision?.sections?.criterionSection ||
      this.documentByRevisionId?.revision?.sections?.criterionSection ||
      this.documentByPublicationId?.revision?.sections?.criterionSection ||
      this.documentByVersionId?.revision?.sections?.criterionSection ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision?.sections
        ?.criterionSection
    );
  }

  // get classBasedCriterions() {
  //   const criterions = this.criterions || [];
  //   return [];
  //   // FIXME:
  //   // return criterions.reduce(
  //   //   (arr, { documentSectionId, content, criterionSubSectionId, tempId }) => {
  //   //     const { type } = content.content;
  //   //     switch (type) {
  //   //       case CRITERION_TYPE.BOOLEAN:
  //   //         return [
  //   //           ...arr,
  //   //           new Criterion({
  //   //             id,
  //   //             content: new BooleanCriterionContent(content),
  //   //             subSections,
  //   //           }),
  //   //         ];
  //   //       case CRITERION_TYPE.RANGE:
  //   //         return [
  //   //           ...arr,
  //   //           new Criterion({
  //   //             id,
  //   //             content: new RangeCriterionContent(content),
  //   //             subSections,
  //   //           }),
  //   //         ];
  //   //       case CRITERION_TYPE.CHECKBOXES:
  //   //         return [
  //   //           ...arr,
  //   //           new Criterion({
  //   //             id,
  //   //             content: new CheckboxesCriterionContent(content),
  //   //             subSections,
  //   //           }),
  //   //         ];
  //   //       default:
  //   //         return arr;
  //   //     }
  //   //   },
  //   //   [] as Criterion[],
  //   // );
  // }

  get opsToText() {
    return opsToText;
  }

  get EditorViewMode() {
    return EditorViewMode;
  }

  get createdSubDocument() {
    return (this.$store.state as RootState).projects.createdSubDocument;
  }

  get currentUsername() {
    return this.authInfo?.oicPayload?.preferred_username;
  }

  get currentEmail() {
    return this.authInfo?.oicPayload?.email;
  }

  get projectId(): number {
    return this.documentDetail?.project?.id || +this.$route.params.projectId;
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get revisionId(): number {
    return (
      this.documentDetail?.revision?.id ||
      this.documentByRevisionId?.revision?.id ||
      this.documentByPublicationId?.revision?.id ||
      this.documentByVersionId?.revision?.id ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision?.id ||
      0
    );
  }

  get assigneeId(): number | undefined {
    return (
      this.documentDetail?.document?.assignee?.id ||
      this.documentByRevisionId?.document?.assignee?.id ||
      this.documentByPublicationId?.document?.assignee?.id ||
      this.documentByVersionId?.document?.assignee?.id ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.document?.assignee
        ?.id ||
      undefined
    );
  }

  get hasNewSection(): boolean {
    return this.dirtyTextSections.some((textSection) =>
      Boolean(textSection.tempId)
    );
  }

  get transparentImg(): Element {
    const transparentImg = new Image();
    transparentImg.src = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==`; // tslint:disable-line
    return transparentImg;
  }

  get authInfo() {
    return (this.$store.state as RootState).auth.authInfo;
  }

  get projectsApiState() {
    return (this.$store.state as RootState).projects.apiState;
  }
  get documentsApiState() {
    return (this.$store.state as RootState).documents.apiState;
  }

  get documentDetailLoaded() {
    return this.documentDetail;
  }

  get CplusDocumentType() {
    return CplusDocumentType;
  }

  get uniquePublicationIdOrDefaultDocDisplayId() {
    return this.documentDetail
      ? this.documentDetail.revision.uniquePublicationId ||
          this.documentDetail.document.defaultDocDisplayId
      : '';
  }

  get isEs() {
    return this.documentType === CplusDocumentType.EvidenceSummary;
  }
  get isRp() {
    return this.documentType === CplusDocumentType.RecommendedPractice;
  }
  get isBpis() {
    return this.documentType === CplusDocumentType.BestPracticeInformationSheet;
  }
  get isSr() {
    return this.documentType === CplusDocumentType.SystematicReview;
  }
  get isSrp() {
    return this.documentType === CplusDocumentType.SystematicReviewProtocol;
  }

  get isLegacy() {
    return this.documentDetail?.document?.isLegacy;
  }

  get documentPublicationStatus() {
    return (
      this.documentDetail?.revision?.publicationStatus ||
      this.documentByRevisionId?.revision?.publicationStatus ||
      this.documentByPublicationId?.revision?.publicationStatus ||
      this.documentByVersionId?.revision?.publicationStatus ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision
        ?.publicationStatus
    );
  }

  get publishedAt() {
    return (
      this.documentDetail?.revision?.publishedAt ||
      this.documentByRevisionId?.revision?.publishedAt ||
      this.documentByPublicationId?.revision?.publishedAt ||
      this.documentByVersionId?.revision?.publishedAt ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision?.publishedAt
    );
  }

  get publishedAtTimezone() {
    return (
      this.documentDetail?.revision?.publishedAtTimezone ||
      this.documentByRevisionId?.revision?.publishedAtTimezone ||
      this.documentByPublicationId?.revision?.publishedAtTimezone ||
      this.documentByVersionId?.revision?.publishedAtTimezone ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision
        ?.publishedAtTimezone
    );
  }

  get archivedAt() {
    return (
      this.documentDetail?.revision?.archivedAt ||
      this.documentByRevisionId?.revision?.archivedAt ||
      this.documentByPublicationId?.revision?.archivedAt ||
      this.documentByVersionId?.revision?.archivedAt ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision?.archivedAt
    );
  }

  get archivedAtTimezone() {
    return (
      this.documentDetail?.revision?.archivedAtTimezone ||
      this.documentByRevisionId?.revision?.archivedAtTimezone ||
      this.documentByPublicationId?.revision?.archivedAtTimezone ||
      this.documentByVersionId?.revision?.archivedAtTimezone ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision
        ?.archivedAtTimezone
    );
  }

  get withdrawnAt() {
    return (
      this.documentDetail?.revision?.withdrawnAt ||
      this.documentByRevisionId?.revision?.withdrawnAt ||
      this.documentByPublicationId?.revision?.withdrawnAt ||
      this.documentByVersionId?.revision?.withdrawnAt ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision?.withdrawnAt
    );
  }

  get withdrawnAtTimezone() {
    return (
      this.documentDetail?.revision?.withdrawnAtTimezone ||
      this.documentByRevisionId?.revision?.withdrawnAtTimezone ||
      this.documentByPublicationId?.revision?.withdrawnAtTimezone ||
      this.documentByVersionId?.revision?.withdrawnAtTimezone ||
      this.convertedLegacyDocumentDetailByBaseDocumentId?.revision
        ?.withdrawnAtTimezone
    );
  }

  get isPublished() {
    if (this.publishedAt && this.withdrawnAt) {
      return new Date(this.publishedAt) > new Date(this.withdrawnAt);
    } else {
      return Boolean(this.publishedAt);
    }
    // return this.publishedAt && this.archivedAt && this.withdrawnAt
    //   ? new Date(this.publishedAt) > new Date(this.archivedAt)
    //   : Boolean(this.publishedAt);
  }

  get isArchived() {
    if (this.publishedAt && this.archivedAt) {
      return new Date(this.archivedAt) > new Date(this.publishedAt);
    } else {
      return Boolean(this.archivedAt);
    }
    // return this.publishedAt && this.archivedAt
    //   ? new Date(this.publishedAt) < new Date(this.archivedAt)
    //   : Boolean(this.archivedAt);
  }

  get isWithdrawn() {
    if (this.publishedAt && this.withdrawnAt) {
      return new Date(this.withdrawnAt) > new Date(this.publishedAt);
    } else {
      return Boolean(this.withdrawnAt);
    }
  }

  get contentKeys() {
    return [
      'dirtyTextSections',
      'dirtyBprs',
      'dirtyDocumentTitle',
      'dirtySearchDate',
      'dirtyAuthors',
      'dirtyCriterions',
      'dirtyOhs',
      'dirtyRelatedDocs',
      'dirtyTagMaps'
    ];
  }

  get isSaving() {
    return (this.$store.state as RootState).documents.apiState.updateDocument
      .loading;
  }

  get alignedEsId() {
    const es = getSourceEvidenceSummaryFromDocument(this.documentDetail);
    if (!es) {
      return;
    }
    return es.id;
  }

  get alignedEs() {
    const es = (this.$store.state as RootState).documents.alignedEsByEsId;
    return this.isRp && es && es.documentId === this.alignedEsId
      ? es
      : undefined;
  }

  get SuggestedDocumentType() {
    return SuggestedDocumentType;
  }

  get documentSections() {
    return this.documentDetail?.revision?.documentSections || [];
  }

  get authorSectionId() {
    return (
      this.documentSections.find(
        (s) => s.sectionType === SectionType.AUTHOR_SECTION
      )?.id || 0
    );
  }
  get bprSectionId() {
    return (
      this.documentSections.find(
        (s) => s.sectionType === SectionType.BPR_LIST_SECTION
      )?.id || 0
    );
  }
  get criterionSectionId() {
    return (
      this.documentSections.find(
        (s) => s.sectionType === SectionType.CRITERION_LIST_SECTION
      )?.id || 0
    );
  }
  get relatedDocSectionId() {
    return (
      this.documentSections.find(
        (s) => s.sectionType === SectionType.RELATED_DOC_SECTION
      )?.id || 0
    );
  }
}
