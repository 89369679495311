












































import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { projectHasRp } from '@/utils/project.util';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ExportProjectAsDocxDialog extends Vue {
  @Prop()
  public project!: ProjectListItemParam;

  public includeAuditCriteria = false;
  public includeRp = false;

  public handleExportDocx(modal: any) {
    this.$emit('exportDocx', {
      includeAuditCriteria: this.includeAuditCriteria,
      includeRp: this.includeRp,
      projectId: this.project.projectId,
      title: this.project.projectTitle
    });
    modal.close();
  }

  public projectHasRp(project: ProjectListItemParam) {
    return projectHasRp(project);
  }

  public mounted() {
    this.includeRp = this.projectHasRp(this.project);
  }
}
