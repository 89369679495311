import { ApiState, PaginatedApiResponse } from '@/store/types/general.types';
import { Revision } from './revisions.types';
import {
  CplusDocumentType,
  Keyword,
  TagMap,
  Mesh
} from '@/jbi-shared/types/document.types';
import {
  DocumentIndexingPayload,
  BackendUserEntity
} from '@/jbi-shared/types/search.types';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import { MeshDto } from '@/jbi-shared/util/mesh.util';
import {
  ProjectDocument,
  CollaboratorInfo
} from '../../projects/types/project-details.types';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { ConvertedLegacyDocumentPayload } from '@/jbi-shared/types/legacy-document.types';
import { OhsAsset } from './ohs.types';
import { AddWkExportEventLogJobPayload } from '@/jbi-shared/types/wk-export.types';
import { SearchCheckboxOptions } from '@/jbi-shared/types/form.types';
import { PartialDeep } from 'lodash';
import { SearchCriterionPrimitive } from '@/store/types/search.types';
import { Citation } from '@/store/modules/documents/types/citations.types';

export interface DocumentSummary {
  id: number;
  documentTitle: string;
  uuid: null;
  metadata: null;
  createdAt: string;
  updatedAt: string;
  currentVersion: string;
  primaryResearchNode: ResearchNode;
  researchNodes: ResearchNode[];
}

export type UpdateDocumentRequestPayload = PartialDeep<
  FullDocumentRevisionObject
>;

export type UpdateRecommendedPracticeRequestPayload = UpdateDocumentRequestPayload;
export type UpdateEvidenceSummaryRequestPayload = UpdateDocumentRequestPayload;

export type UpdateBPISRequestPayload = PartialDeep<FullDocumentRevisionObject>;

export type UpdateSRRequestPayload = PartialDeep<FullDocumentRevisionObject>;

export type DocumentDetail = FullDocumentRevisionObject;

export interface Project {
  id: number;
  projectTitle: string;
  uuid: string;
  metadata: null;
  status: null;
  createdAt: string;
  updatedAt: string;
  projectDocuments: ProjectDocument[];
  primaryResearchNode: ResearchNode;
  researchNodes: ResearchNode[];
  documentOwner: User;
  collaborators: CollaboratorInfo[];
}

export type User = BackendUserEntity;
export type PublishDocAction =
  | 'Archive'
  | 'Publish'
  | 'Unarchive'
  | 'Withdraw'
  | 'Restore';

export interface DirtyTagMap
  extends Omit<TagMap, 'id' | 'keyword' | 'mesh' | 'revisionId'> {
  keyword?: Omit<Keyword, 'id'>;
  mesh?: Omit<Mesh, 'id'>;
  revisionId?: number;
  // for buefy taginput usage
  value?: string;
  isEntityIdTemp?: boolean;
}

export interface ResearchNode {
  id: number;
  name: string;
  isDeleted?: boolean;
}

export interface ResearchNodeEntity {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  creator?: BackendUserEntity;
  isDeleted?: boolean;
}

export interface DocumentType {
  EvidenceSummary: string;
  BestPracticeInformationSheet: string;
}

export interface DocumentsApiState {
  getRevisionCitations: ApiState;
  removeRevisionCitation: ApiState;
  getDocumentDetail: ApiState;
  updateDocument: ApiState;
  deleteDocument: ApiState;
  getDocumentByRevisionId: ApiState;
  getDocumentByVersionId: ApiState;
  getDocumentByPublicationId: ApiState;
  updateRevisionName: ApiState;
  exportDocument: ApiState;
  exportSearchResult: ApiState;
  restoreRevision: ApiState;
  getRevisionsByDocumentId: ApiState;
  getAllLegacyDocVersionsByBaseDocumentId: ApiState;
  getLegacyContentByBaseDocumentId: ApiState;
  searchDocument: ApiState;
  searchRelatedDocuments: ApiState;
  searchNewRelatedDocuments: ApiState;
  searchEsForRpRealignment: ApiState;
  updatePublishStatus: ApiState;
  getUserDocuments: ApiState;
  getUserCreatedDocuments: ApiState;
  getUserAssignedDocuments: ApiState;
  exportWkResource: ApiState;
  getWkExportStatus: ApiState;
  getWkExportDetails: ApiState;
  getAllWkExports: ApiState;
  inviteDocumentUserAsParticipant: ApiState;
  updateDocumentParticipant: ApiState;
  deleteDocumentParticipant: ApiState;
  searchKeywordTag: ApiState;
  getMeshTree: ApiState;
  getRdfMeshTags: ApiState;
  updateDocStatus: ApiState;
  updateDocAssignee: ApiState;
  getDocumentParticipants: ApiState;
  documentStatusAPIState: ApiState;
  getMyAssignedDocuments: ApiState;
  getOhsAssets: ApiState;
  getAlignedEsByEsId: ApiState;
}

export interface DocumentsState {
  citationsOfRevision: Citation[] | undefined;
  apiState: DocumentsApiState;
  documents: PaginatedApiResponse<DocumentSummary> | undefined;
  createdDocument: DocumentDetail | undefined;
  documentDetail: DocumentDetail | undefined;
  documentByRevisionId: DocumentDetail | undefined;
  documentByVersionId: DocumentDetail | undefined;
  documentByPublicationId: DocumentDetail | undefined;
  revisionsByDocumentId: RevisionsOfDocument | undefined;
  allLegacyDocVersionsByBaseDocumentId:
    | ConvertedLegacyDocumentPayload[]
    | undefined;
  legacyContentByBaseDocumentId: ConvertedLegacyDocumentPayload | undefined;
  documentSearchResult:
    | PaginatedApiResponse<DocumentIndexingPayload>
    | undefined;
  relatedDocumentSearchResult: DocumentIndexingPayload[] | undefined;
  esForAlignmentSearchResult: DocumentIndexingPayload[] | undefined;
  userDocuments: PaginatedApiResponse<DocumentIndexingPayload> | undefined;
  userCreatedDocuments:
    | PaginatedApiResponse<DocumentIndexingPayload>
    | undefined;
  userAssignedDocuments:
    | PaginatedApiResponse<DocumentIndexingPayload>
    | undefined;
  participantRoles: string[] | undefined;
  invitedDocumentUser: InviteDocumentParticipantsResponsePayload | undefined;
  keywordTags: Keyword[] | undefined;
  meshTree: MeshTree | undefined;
  rdfMeshTags: MeshDto[] | undefined;
  documentParticipants: GetDocumentParticipantsResponsePayload | undefined;
  myAssignedDocuments: GetMyAssignedDocumentsResponsePayload | undefined;
  ohsAssets: OhsAsset[] | undefined;
  allWkResourceExports:
    | PaginatedApiResponse<AddWkExportEventLogJobPayload>
    | undefined;
  alignedEsByEsId: DocumentIndexingPayload | undefined;
}

export interface GetDocumentByRevisionIdRequestPayload {
  revisionId: number;
  documentId: number;
}

export interface GetDocumentByVersionIdRequestPayload {
  versionId: number;
  documentId: number;
}

export interface GetDocumentByPublicationIdRequestPayload {
  publicationId: number;
  documentId: number;
}

export interface UpdateRevisionNameRequestPayload {
  documentId: number;
  revisionId: number;
  name: string;
}

export interface RemoveRevisionCitationRequestPayload {
  documentId: number;
  revisionId: number;
  citationId: number;
}

export interface GetRevisionCitationsRequestPayload {
  documentId: number;
  revisionId: number;
}

export enum DocumentExportType {
  docx = 'docx',
  pdf = 'pdf',
  json = 'json'
}

export enum SearchResultExportType {
  csv = 'csv'
}

export interface RestoreRevisionPayload {
  revisionId: number;
  documentId: number;
}

export interface RevisionsOfDocument {
  documentId: number;
  revisions: Revision[];
  legacyDocuments: ConvertedLegacyDocumentPayload[];
  latestVersionId: number;
  nonDraftRevisions: Revision[];
}

export interface ExportDocumentRequestPayload {
  documentId?: number;
  revisionId?: number;
  baseDocumentId?: number;
  exportType: DocumentExportType;
  includeAuditCriteria?: boolean;
}
export interface ExportSearchResultPayload {
  resultJson?: string;
  exportType: DocumentExportType;
}

export interface SearchFacetsParams {
  authors: SearchCheckboxOptions[];
  documentTypes: SearchCheckboxOptions[];
  nodes: SearchCheckboxOptions[];
  assignedUsers: SearchCheckboxOptions[];
}

export interface SearchDocumentPayload {
  text: string;
  authors?: string[];
  documentTypes?: string[];
  documentId?: string;
  nodes?: string[];
  publicationYearFrom?: number;
  publicationYearTo?: number;
  assignedUsers?: string[];
  meshTags?: string[];
  revisionStatus?: RevisionPublicationStatus;
  revisionStatuses?: RevisionPublicationStatus[]; // In advanced search page, we search for multiple statuses at once
  legacyFilter: LegacySearchFilters;
  perPage?: number;
  page?: number;
  facets?: SearchFacetsParams;
  plainTags?: string[];
  criteria?: SearchCriterionPrimitive[] | string;
  isAdvance?: boolean;
  hasCriteria?: boolean;
}

export type SearchRelatedDocumentsPayload = Pick<
  SearchDocumentPayload,
  'text' | 'nodes' | 'authors' | 'documentTypes'
>;

export type SearchEsForRpRealignmentPayload = Pick<
  SearchDocumentPayload,
  'text' | 'nodes' | 'authors'
>;

export enum LegacySearchFilters {
  'All Documents' = 'All Documents',
  'Standard Documents' = 'Standard Documents',
  'Legacy Documents' = 'Legacy Documents',
  'Migratable Legacy Documents' = 'Migratable Legacy Documents'
}

export interface SelectedAdvancedSearchParams {
  text: string;
  researchNodes: ResearchNode[];
  authors?: string[];
  documentTypes?: string[];
  nodes?: string[];
  publicationYearFrom?: number;
  publicationYearTo?: number;
  assignedUsers?: string[];
  revisionStatus?: RevisionPublicationStatus | undefined;
  legacyFilter: LegacySearchFilters;
  meshTags?: string[];
  perPage?: number;
  page?: number;
}

export interface SearchKeywordPayload {
  text: string;
  limit?: number;
}

export interface GetUserDocumentsRequestPayload {
  type: string;
  documentType?: CplusDocumentType | string | undefined;
  revisionStatus?: RevisionPublicationStatus | string | undefined;
  page?: number;
  perPage?: number;
}

export interface ExportWkResourceRequestPayload {
  startDate?: string;
  endDate?: string;
  documentId?: string;
  exportType?: DocumentExportType;
}

export interface InviteDocumentParticipantsRequestPayload {
  documentId: number;
  userEmail: string;
  participantRole: string;
}

export interface InviteDocumentParticipantsResponsePayload {
  participantRole: string;
  user_email: string;
  userAlreadyExist: boolean;
}

export interface CheckPermissionRequestPayload {
  documentId: number;
}

export interface CheckPermissionResponsePayload {
  permissions: string[];
}

export interface UpdateDocAssigneeRequestPayload {
  documentId: number;
  assigneeId: number | null;
}
export interface UpdateDocAssigneeResponsePayload {
  assignee: User | null;
}

export interface GetDocumentParticipantsRequestPayload {
  documentId: number;
}

export interface InvitedParticipants {
  email: string;
  role: string;
  invitePending: boolean;
}

export type GetDocumentParticipantsResponsePayload = InvitedParticipants[];

export interface MeshTree {
  id: number;
  version: number;
  publishedYear: number;
  content: any;
  flatContent: MeshDto[];
}

export interface GetMyAssignedDocumentsRequestPayload {
  perPage?: number;
  page?: number;
}

export type GetMyAssignedDocumentsResponsePayload = PaginatedApiResponse<
  DocumentIndexingPayload
>;
