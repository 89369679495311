

















import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class UploadError extends Vue {
  @Prop(String) private message!: string;
}
