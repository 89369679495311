













import { Component, Vue, Prop } from 'vue-property-decorator';
import AssignedWorks from '@/views/Dashboard/components/AssignedWorks.vue';
import ActivityLogs from '@/views/Dashboard/components/ActivityLogs.vue';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import Container from '@/components/Container.vue';
import RecentDocuments from '@/views/Dashboard/components/RecentDocuments.vue';
@Component({
  components: {
    Container,
    AssignedWorks,
    ActivityLogs,
    DashboardHeader,
    RecentDocuments
  }
})
export default class DashboardPage extends Vue {}
