var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs",class:_vm.$style.tabs},[_c('ul',_vm._l((_vm.documents),function(doc,i){
var _obj;
return _c('li',{key:doc.id,class:[
        ( _obj = {
          'is-active': _vm.isActive(doc.id)
        }, _obj[_vm.$style.isPublished] = _vm.isPublishedDoc(doc), _obj[_vm.$style.isDraft] = _vm.isDraftDoc(doc), _obj[_vm.$style.isArchived] = _vm.isArchivedDoc(doc), _obj ),
        !_vm.multiple ? _vm.$style.singleTabStyle : ''
      ],attrs:{"data-test":"project-document-tab"},on:{"mouseover":function($event){_vm.hoveredItemIndex = i},"mouseleave":function($event){_vm.hoveredItemIndex = -1}}},[_c('router-link',{attrs:{"to":{
          name: 'editor',
          params: {
            documentId: doc.id,
            projectId: _vm.projectId
          }
        },"title":doc.title}},[_c('ProjectEditorHeaderTabTag',{attrs:{"active":_vm.hoveredItemIndex === i || _vm.isActive(doc.id),"status":_vm.getDocumentStatus(doc)}},[_vm._v(" "+_vm._s(_vm.getDocTypeDisplayNameFromDoc(doc))+" ")]),_vm._v(" "+_vm._s(doc.title)+" ")],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }