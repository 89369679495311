import Vue from 'vue';
import Component from 'vue-class-component';
import { RootState } from '../store/store';
import { Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { isTruthy } from '../jbi-shared/util/watcher.vue-decorator';
import { GeneralRoles } from '../jbi-shared/types/casbin-permission.types';

@Component<WkAdminMixin>({})
export class WkAdminMixin extends Vue {
  get myRoles(): string[] {
    return (this.$store.state as RootState).users.myRoles || [];
  }

  get isLoggedIn(): boolean {
    return (this.$store.state as RootState).auth.isLoggedIn;
  }

  get isWkAdmin() {
    return (
      this.myRoles.includes(GeneralRoles.WkAdmin) &&
      !this.myRoles.includes(GeneralRoles.Admin)
    );
  }

  @Watch('myRoles', { immediate: true })
  @Watch('$route.name', { immediate: true })
  @Watch('isLoggedIn', { immediate: true })
  public onMyRolesLoaded() {
    if (!this.isLoggedIn) {
      return;
    }
    const isWkAdmin = this.isWkAdmin;
    const isWkRoute = String(this.$route.name).includes('wk-resource-export');
    if (isWkAdmin && !isWkRoute) {
      this.$router.replace({ name: 'wk-resource-export' });
    }
  }

  @Watch('isWkAdmin', { immediate: true })
  @isTruthy
  public onWkAdmin() {
    this.removeNav();
  }

  public removeNav() {
    // FIXME: should control display using v-if
    if (document.querySelector('nav.navbar .navbar-start')) {
      document.querySelector('nav.navbar .navbar-start')!.remove();
    } else {
      setTimeout(() => {
        this.removeNav();
      }, 50);
    }
  }
}
