










































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SectionValueEditorMixin } from '@/components/editor/SectionEditor/SectionValueEditor.mixin';
import { ViewModeMixin } from '@/utils/viewMode.mixin';

@Component({ components: {} })
export default class SectionValueEditor extends mixins(
  SectionValueEditorMixin,
  ViewModeMixin
) {
  @Prop({ type: Boolean, default: false })
  public hasError!: boolean;
}
