
















































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import {
  CplusDocumentType,
  CplusDocumentTypeShortHand
} from '@/jbi-shared/types/document.types';
import { DocumentDetail } from '@/store/modules/documents/types/documents.types';
import ProjectEditorHeaderTabTag from '@/views/DocumentEditor/components/ProjectEditorHeaderTabTag.vue';
import {
  GetProjectResponsePayload,
  ProjectDocument
} from '@/store/modules/projects/types/project-details.types';
import { get as _get, last } from 'lodash';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';

@Component({
  components: { ProjectEditorHeaderTabTag }
})
export default class ProjectEditorHeaderCollapseableTab extends Vue {
  @Prop()
  public items!: ProjectDocument[];

  public hoveredItemIndex = -1;

  get projectId() {
    return +this.$route.params.projectId;
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  public isActive(docId: number) {
    return this.documentId === docId;
  }

  public getDocTypeDisplayNameFromDoc(
    doc: GetProjectResponsePayload['projectDocuments'][0]
  ) {
    const { documentType } = doc;
    return CplusDocumentTypeShortHand[documentType];
  }

  getDocumentStatus(doc: ProjectDocument): RevisionPublicationStatus {
    return (
      _get(last(doc.revisions), 'revisionPublicationStatus.status') ||
      RevisionPublicationStatus.Draft
    );
  }

  public isPublishedDoc(doc: ProjectDocument) {
    return this.getDocumentStatus(doc) === RevisionPublicationStatus.Published;
  }

  public isDraftDoc(doc: ProjectDocument) {
    return this.getDocumentStatus(doc) === RevisionPublicationStatus.Draft;
  }

  public isArchivedDoc(doc: ProjectDocument) {
    return this.getDocumentStatus(doc) === RevisionPublicationStatus.Archived;
  }
}
