import { get as _get } from 'lodash';
import { TextSection } from '../store/modules/documents/types/text-sections.types';
import Delta from 'quill-delta';
import { Citation } from '../store/modules/documents/types/citations.types';
import { textToOps } from './quill-delta.util';
import Op from 'quill-delta/dist/Op';
import { QuillCitationInsertionPayload } from '../store/modules/documents/types/quill.types';
import {
  doiToDoiLink,
  getDatePartsFromIssueDate
} from '../jbi-shared/util/citation.util';
import * as Cite from 'citation-js';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  CitationTypes,
  PlainCitationFields
} from '../jbi-shared/types/citation.fields';

export const getReferenceSection: (
  citationIds: number[],
  allCitations: Citation[]
) => Partial<TextSection> = (citationIds, allCitations) => {
  const sectionTitle = textToOps('References');

  let sectionValueDelta = new Delta();

  if (allCitations) {
    citationIds
      .map((id) => allCitations.find((citation) => id === citation.id))
      .filter(Boolean)
      // @ts-ignore
      .forEach((citation: Citation) => {
        const rawCitation = _cloneDeep(citation);
        if (rawCitation.content.type === CitationTypes.PLAIN) {
          sectionValueDelta = sectionValueDelta
            .insert(
              `${(rawCitation.content as PlainCitationFields).plainText.trim()}`
            )
            .insert('\n', {
              list: 'ordered'
            });
          return;
        }
        // @ts-ignore
        const rawCitationObject = new Cite(rawCitation.content);

        // Special Fix for Vancouver format to display date, and support multiple types of formats
        rawCitationObject.data[0].issued = getDatePartsFromIssueDate(
          rawCitationObject?.data[0]?.issued
        );

        const formattedCitation = rawCitationObject.format('bibliography', {
          template: 'vancouver'
        });
        // handle DOI
        // DOI usage reference :
        // https://library.uic.edu/help/article/1966/what-is-a-doi-and-how-do-i-use-them-in-citations
        const doi: string | undefined = _get(rawCitation, 'content.DOI');
        let link = doiToDoiLink(doi);

        // Check if the rawCitationObject data has url
        // Replace link with rawCitationObject data url if link is invalid
        if (rawCitationObject.data[0]?.url && !link) {
          link = rawCitationObject.data[0]?.url;
        }

        sectionValueDelta = sectionValueDelta
          .insert(`${formattedCitation.trim().substr(3)}`, {
            link
          })
          .insert('\n', {
            list: 'ordered'
          });
      });
  }

  const sectionValue = sectionValueDelta.ops;

  return {
    sectionTitle,
    sectionValue
  };
};

export const htmlToPlainText = (html: string) => {
  return html.replace(/<[^>]*>?/gm, '');
};

interface CleanupCitationInOpsPayload {
  ops: Op[];
  availableCitations: Citation[];
}

export function cleanupCitationInOps({
  ops,
  availableCitations
}: CleanupCitationInOpsPayload): Op[] {
  return ops.filter((op) => {
    const citation: QuillCitationInsertionPayload | undefined = _get(
      op,
      'insert.citation'
    );
    if (citation) {
      const deleted = availableCitations.every(
        (c) => c.id !== +citation.citationId
      );
      if (deleted) {
        return false;
      }
    }
    return true;
  });
}

export function updateCitationInOps({
  ops,
  citationId,
  newCitationId
}: {
  ops: Op[];
  citationId: number;
  newCitationId: number;
}): Op[] {
  return ops.map((op) => {
    const citation: QuillCitationInsertionPayload | undefined = _get(
      op,
      'insert.citation'
    );
    if (citation && parseInt(citation.citationId) === citationId) {
      citation.citationId = newCitationId.toString();
    }
    return op;
  });
}

export function updateCitationInOpsInStagingEditor({
  ops,
  citationId,
  newCitationId
}: {
  ops: Op[];
  citationId: string;
  newCitationId: string;
}): Op[] {
  return ops.map((op) => {
    const citation: QuillCitationInsertionPayload | undefined = _get(
      op,
      'insert.citation'
    );
    // For some reasons
    // some of the citationId in dirtyTextSections of pending editor are strings and some are integer
    if (citation && parseInt(citation.citationId) === parseInt(citationId)) {
      citation.citationId = newCitationId;
    }
    return op;
  });
}

interface HandleCitationIndexInOpsPayload {
  ops: Op[];
  uniqUsedCitationIds: number[];
}

export const chromeCitationPlaceholder = '¯'; // it's a Macron https://en.wikipedia.org/wiki/Macron_(diacritic);

export function handleCitationLabelInOps({
  ops,
  uniqUsedCitationIds
}: HandleCitationIndexInOpsPayload): Op[] {
  return ops!.map((op) => {
    let citation: QuillCitationInsertionPayload | undefined = _get(
      op,
      'insert.citation'
    );
    if (citation) {
      citation = {
        ...citation,
        label: String(
          uniqUsedCitationIds.findIndex((id) => id === +citation!.citationId) +
            1
        )
      };
      return {
        ...op,
        insert: {
          citation
        }
      };
    }
    return op;
  });
}

export function formatAuthorNameForCitation(citation: Citation) {
  const authorString = citation.content.author;
  const authors: string[] = authorString.split(',');
  return authors.map((author) => {
    return {
      given: author
    };
  });
}
