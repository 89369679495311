






















































import { Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import RelatedDocsSectionValueEditor from './RelatedDocsSectionValueEditor.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
// tslint:disable-next-line
import RelatedDocForm from '@/components/editor/SectionEditor/RelatedDocsSectionEditor/RelatedDocForm.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DocumentIndexingPayload } from '../../../../jbi-shared/types/search.types';

@Component({
  components: {
    draggable,
    RelatedDocsSectionValueEditor,
    RelatedDocForm
  }
})
export default class RelatedDocsSectionEditor extends mixins(ViewModeMixin) {
  public drag = false;

  @Prop()
  public isRp!: boolean;
  @Prop()
  public isEs!: boolean;
  @Prop()
  public dirtyRelatedDocs!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];
  @Prop()
  public sourceEvidenceSummaryLink!:
    | FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0]
    | undefined;
  @Prop()
  public currentDocNodes!: string[];
  @Prop()
  public currentDocAuthors!: string[];
  @Prop()
  public currentDocumentId!: number;

  public openAddNewModal() {
    const modalTitle = this.isRp
      ? 'Supporting Evidence Summaries'
      : 'Related JBI Evidence Summaries';
    this.$buefy.modal.open({
      parent: this,
      component: RelatedDocForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle,
        isRp: this.isRp,
        isEs: this.isEs,
        currentDocNodes: this.currentDocNodes,
        currentDocAuthors: this.currentDocAuthors,
        currentDocumentId: this.currentDocumentId,
        dirtyRelatedDocs: this.dirtyRelatedDocs
      },
      events: {
        add: (selected: DocumentIndexingPayload[]) => {
          const newRelatedDoc = selected.map(
            (s) =>
              ({
                relatedDocs: {
                  id: s.documentId,
                  title: this.isEs
                    ? `${s.uniquePublicationId}-${s.title}`
                    : s.title
                }
              } as FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0])
          );

          const updatedRelatedDocs = [
            ...this.dirtyRelatedDocs,
            ...newRelatedDoc
          ];
          this.$emit('update:dirtyRelatedDocs', updatedRelatedDocs);
        }
      }
    });
  }

  get links() {
    return this.dirtyRelatedDocs;
  }
  set links(arr) {
    this.$emit('update:dirtyRelatedDocs', arr);
  }
}
