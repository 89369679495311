



















































import SuggestedDocumentModal from '@/components/form/SuggestedDocumentModal.vue';

import RelatedDocSearchResultItem from './RelatedDocSearchResultItem.vue';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '@/views/DocumentEditor/mixins/editor-common.mixin';
import { DocumentIndexingPayload } from '@/jbi-shared/types/search.types';
import { SuggestedDocumentType } from '@/jbi-shared/util/document.utils';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';

@Component({
  components: { RelatedDocSearchResultItem, SuggestedDocumentModal }
})
export default class RelatedDocForm extends mixins() {
  @Prop(String) public modalTitle!: string;
  @Prop()
  public isRp!: boolean;
  @Prop()
  public isEs!: boolean;
  @Prop()
  public currentDocNodes!: string[];
  @Prop()
  public currentDocAuthors!: string[];
  @Prop()
  public currentDocumentId!: number;
  @Prop()
  public dirtyRelatedDocs!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];

  public selected: DocumentIndexingPayload[] = [];

  get SuggestedDocumentType() {
    return SuggestedDocumentType;
  }

  public handleToggle(doc: DocumentIndexingPayload) {
    const selected = this.isSelected(doc);
    if (selected) {
      this.selected = this.selected.filter(
        ({ documentId }) => documentId !== doc.documentId
      );
    } else {
      this.selected.push(doc);
    }
  }

  public isSelected(doc: DocumentIndexingPayload) {
    const selected = this.selected.some((s) => s.documentId === doc.documentId);
    return selected;
  }
}
