









import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import SectionEditorToolbar from './SectionEditorToolbar.vue';

@Component({ components: { SectionEditorToolbar } })
export default class SectionEditorHeader extends Vue {
  @Prop(String) public readonly sectionName!: string;

  @Prop({ type: Boolean, default: false })
  public readonly invisible!: boolean;
}
