










































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { mixins } from 'vue-class-component';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  DownloadDocumentRequestPayload,
  ExportProjectAsDocxDialogPayload,
  UnassignEditorFormPayload
} from '@/store/modules/admin/types/admin.types';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { handleAdminDownloadDocument } from '@/utils/admin.util';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import BaseTable from '@/components/base/BaseTable.vue';
import SortableTableHeader from '@/components/base/SortableTableHeader.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import TableActionDropdown from '@/components/base/TableActionDropdown.vue';
import AddNoteDialog from '@/views/AdminDashboard/components/AddNoteDialog.vue';
import ExportProjectAsDocxDialog from '@/views/AdminDashboard/components/ExportProjectAsDocxDialog.vue';
import dayjs from 'dayjs';
import { ApiState } from '@/store/types/general.types';
import ProjectRow from './ProjectRow.vue';
import AssignEditorForm from '@/views/AdminDashboard/components/AssignEditorForm.vue';
import { GetEditorsResponsePayload } from '@/jbi-shared/types/cplus-endpoints/editor.types';
import { UnassignProjectEditorRequestPayload } from '@/store/modules/admin/types/admin.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { Job } from 'bull';
import { useUserEmail } from '@/utils/user.util';
import UnassignEditorForm from '@/views/AdminDashboard/components/UnassignEditorForm.vue';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';

@Component({
  components: {
    BaseTable,
    SortableTableHeader,
    BaseLoading,
    BaseModal,
    ProjectRow,
    BasePagination,
    TableActionDropdown
  }
})
export default class ProjectList extends mixins(PaginationMixin) {
  @Prop() public items!: ProjectListItemParam[];
  @Prop() public selectedItems!: ProjectListItemParam[];
  @Prop() public selectAll!: boolean;
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  public selectIdList: number[] = [];
  public unassignProjectTitle = '';
  public unassignEditorName = '';

  @Action('admin/downloadDocument')
  public downloadDocument!: (p: DownloadDocumentRequestPayload) => Promise<Job>;

  @Action('editors/getEditors')
  public getEditors!: () => void;

  @Action('admin/unassignProjectEditor')
  public unassignProjectEditor!: (
    payload: UnassignProjectEditorRequestPayload
  ) => void;

  @State((state: RootState) => state?.admin?.apiState?.unassignProjectEditor)
  public unassignProjectEditorApi!: ApiState;

  @State((state: RootState) => state?.projects?.apiState?.getAllProjects)
  public getAllProjects!: ApiState;

  @State((state: RootState) => state?.editors?.editors)
  public editors!: GetEditorsResponsePayload;

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }

  public checkAll(select: boolean) {
    this.$emit('allSelected', select);
    if (select) {
      this.items.forEach((item) => {
        this.$emit('addSelected', item);
      });
    } else {
      this.items.forEach((item) => {
        this.$emit('removeSelected', item);
      });
    }
  }

  @isDifferent
  @isTruthy
  @Watch('getAllProjects.success')
  public onGetAllProjectsSuccess() {
    this.$emit('allSelected', false);
  }

  public async handleDownloadDocument(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: ExportProjectAsDocxDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        project
      },
      events: {
        exportDocx: this.downloadDocumentCb
      }
    });
  }

  public async downloadDocumentCb(value: ExportProjectAsDocxDialogPayload) {
    const { includeAuditCriteria, includeRp, projectId, title } = value;
    const workerJob = await this.downloadDocument({
      projectId,
      includeRp,
      includeAuditCriteria
    });
    handleAdminDownloadDocument.call(this, workerJob, title);
  }

  public handleAssignProjectEditor(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: AssignEditorForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        projects: [project],
        editors: this.editors
      },
      events: {
        updateAssignee: (value: string) => {
          this.$emit('updateAssignee', value);
        },
        countAssignedProjects: (value: number) => {
          this.$emit('countAssignedProjects', value);
        }
      }
    });
  }

  public handleUnassignProjectEditor(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: UnassignEditorForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        item: project
      },
      events: {
        unassignProject: this.unassignProjectCb
      }
    });
  }

  public unassignProjectCb(value: UnassignEditorFormPayload) {
    if (this.userEmail) {
      this.unassignProjectTitle = value.projectTitle;
      this.unassignEditorName = value.editorName;

      this.unassignProjectEditor({
        adminEmail: this.userEmail,
        projectId: value.projectId,
        note: value.unassignNote ? value.unassignNote : undefined
      });
    }
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortCriteria === columnName) {
      return this.$emit('sort', {
        sortCriteria: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortCriteria: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortCriteria === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }

  @isDifferent
  @isTruthy
  @Watch('unassignProjectEditorApi.success')
  public onUnassignProjectEditorSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `${this.unassignProjectTitle} has been unassigned from ${this.unassignEditorName}`
    });
  }

  public formatDate(date: string) {
    return date ? dayjs(date).format('DD MMM YYYY') : '-';
  }

  public formatNote(note: string | undefined) {
    if (!note) {
      return '-';
    } else {
      return note.length < 26 ? note : note.slice(0, 25) + '...';
    }
  }

  public showAddNoteDialog(project: ProjectListItemParam) {
    this.$buefy.modal.open({
      parent: this,
      component: AddNoteDialog,
      hasModalCard: true,
      trapFocus: true,
      props: {
        project
      }
    });
  }
}
