



import Component, { mixins } from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { RootState } from '../../store/store';
import { useApiState, useAction } from '../../utils/store.util';
import {
  isDifferent,
  isTruthy
} from '../../jbi-shared/util/watcher.vue-decorator';

@Component({
  components: {}
})
export default class DocumentVersionsPage extends Vue {
  get revisionsByDocumentId() {
    return (this.$store.state as RootState).documents.revisionsByDocumentId;
  }

  get getRevisionsState() {
    return useApiState.call(this, 'documents/getRevisionsByDocumentId');
  }

  get getRevisionsByDocumentId(): (documentId: number) => void {
    return useAction.call(this, 'documents/getRevisionsByDocumentId');
  }

  get resetRevisionsState(): () => void {
    return useAction.call(this, 'documents/resetRevisionsState');
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get projectId(): number {
    return +this.$route.params.projectId;
  }

  public created() {
    this.resetRevisionsState();

    // load doc detail
    const { documentId } = this;

    this.getRevisionsByDocumentId(documentId);
  }

  @Watch('getRevisionsState.success')
  @isDifferent
  @isTruthy
  public onGetRevisionsSuccess(v: boolean, prevV: boolean) {
    let latestRevision = this.revisionsByDocumentId!.nonDraftRevisions.length;
    const latestVersionId = latestRevision
      ? this.revisionsByDocumentId!.nonDraftRevisions[latestRevision - 1]
          .versionId
      : this.revisionsByDocumentId!.latestVersionId;
    this.$router.replace({
      name: 'document-versions',
      params: {
        projectId: String(this.projectId),
        documentId: String(this.documentId),
        versionId: String(latestVersionId)
      }
    });
  }
}
