










import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/base/Header.vue';
import { handleRedirectDestination } from '@/utils/localstorage.util';
import { mixins } from 'vue-class-component';
import { SessionHandlerMixin } from '@/utils/session-handler.mixin';
import { ApiResponseErrorHandlerMixin } from '@/utils/api-response-error-handler-mixin.component';
import { WkAdminMixin } from '@/utils/wk-admin.mixin.ts';

@Component({
  components: { Header },
})
export default class App extends mixins(
  WkAdminMixin,
  SessionHandlerMixin,
  ApiResponseErrorHandlerMixin,
) {
  public created() {
    handleRedirectDestination(this.$router);
  }
}
