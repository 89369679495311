import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { StagingEditorComputedValuesMixin } from './staging-editor-computed-values.mixin';
import { StagingEditorStateMixin } from './staging-editor-state.mixin';

@Component({})
export class StagingEditorUndoRedoChildMixin extends mixins(
  StagingEditorStateMixin,
  StagingEditorComputedValuesMixin
) {}
