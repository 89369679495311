var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.toolbarElemId,ref:"editorToolbar",staticClass:"ql-toolbar ql-snow",class:( _obj = {}, _obj[_vm.$style.editorToolbar] = true, _obj[_vm.$style.editorToolbar__sticky] = _vm.toolbarSticky, _obj ),attrs:{"slot":"toolbar","id":_vm.toolbarElemId},slot:"toolbar"},[(_vm.allowFormat(_vm.QuillToolbarFormat.size))?_c('span',{staticClass:"ql-formats",class:_vm.$style.qlFormats},[(_vm.allowFormat(_vm.QuillToolbarFormat.size))?_c('select',{staticClass:"ql-size"}):_vm._e()]):_vm._e(),(
      _vm.allowFormat(_vm.QuillToolbarFormat.subscript) ||
      _vm.allowFormat(_vm.QuillToolbarFormat.superscript)
    )?_c('span',{staticClass:"ql-formats",class:_vm.$style.qlFormats},[(_vm.allowFormat(_vm.QuillToolbarFormat.subscript))?_c('button',{staticClass:"ql-script",attrs:{"value":"sub"}}):_vm._e(),(_vm.allowFormat(_vm.QuillToolbarFormat.superscript))?_c('button',{staticClass:"ql-script",attrs:{"value":"super"}}):_vm._e()]):_vm._e(),(
      _vm.allowFormat(_vm.QuillToolbarFormat.bold) ||
      _vm.allowFormat(_vm.QuillToolbarFormat.italic) ||
      _vm.allowFormat(_vm.QuillToolbarFormat.underline)
    )?_c('span',{staticClass:"ql-formats",class:_vm.$style.qlFormats},[(_vm.allowFormat(_vm.QuillToolbarFormat.bold))?_c('button',{staticClass:"ql-bold"}):_vm._e(),(_vm.allowFormat(_vm.QuillToolbarFormat.italic))?_c('button',{staticClass:"ql-italic"}):_vm._e(),(_vm.allowFormat(_vm.QuillToolbarFormat.underline))?_c('button',{staticClass:"ql-underline"}):_vm._e()]):_vm._e(),(
      _vm.allowFormat(_vm.QuillToolbarFormat.ordered) ||
      _vm.allowFormat(_vm.QuillToolbarFormat.bullet)
    )?_c('span',{staticClass:"ql-formats",class:_vm.$style.qlFormats},[(_vm.allowFormat(_vm.QuillToolbarFormat.ordered))?_c('button',{staticClass:"ql-list",attrs:{"value":"ordered"}}):_vm._e(),(_vm.allowFormat(_vm.QuillToolbarFormat.bullet))?_c('button',{staticClass:"ql-list",attrs:{"value":"bullet"}}):_vm._e(),_c('select',{staticClass:"ql-align"})]):_vm._e(),(_vm.allowFormat(_vm.QuillToolbarFormat.icons))?_c('IconSelector',_vm._g({attrs:{"parentStyle":_vm.$style}},_vm.$listeners)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }