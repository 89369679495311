var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',[_vm._v("Document ID")]),_c('th',[_vm._v("File Name")]),_c('th',[_vm._v("Message")]),_c('th',[_vm._v("Status")])])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('tr',{key:'creation-job-status-' + index},[_c('td',[_c('router-link',{class:_vm.$style.link,attrs:{"to":_vm.getDocumentEditorRouterLink(item)}},[_vm._v(" JBI-"+_vm._s(item.uploadedDocInfo.docType.toUpperCase())+"-"+_vm._s(item.projectDocumentId)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(item.uploadedDocInfo.name.length > 50 ? item.uploadedDocInfo.name.substring(0, 50) + '...' : item.uploadedDocInfo.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getItemErrorMessage(item))+" ")]),_c('td',[_c('span',{class:[
            item.processJobStatus === _vm.PendingDocCreationJobStatus.Pending
              ? _vm.$style.pendingStatus
              : '',
            item.processJobStatus === _vm.PendingDocCreationJobStatus.Success
              ? _vm.$style.successStatus
              : '',
            item.processJobStatus === _vm.PendingDocCreationJobStatus.Error
              ? _vm.$style.errorStatus
              : ''
          ]},[_vm._v(" "+_vm._s(item.processJobStatus)+" ")])])])})},proxy:true},{key:"footer",fn:function(){return [_c('tr',[(_vm.pagination)?_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_c('p',{staticClass:"p-2 has-text-centered"},[[_vm._v("No records found")]],2)],1):_vm._e()])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }