import Vue from 'vue';
import Component from 'vue-class-component';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { NotificationProgrammatic as Notification } from 'buefy';
import { Subject } from 'rxjs';
import { MutationPayload } from 'vuex';
import { DialogProgrammatic as Dialog } from 'buefy';
import { userIsInactive } from './session-handler.mixin';
import { getCompiledMessage, showStickyErrorNotification } from './error.util';
import { overridedErrorComponentRoute } from './override-error-in-component';

@Component({})
/**
 * Class ApiResponseErrorHandlerMixin
 */
export class ApiResponseErrorHandlerMixin extends Vue {
  /**
   * showingErrorDialog: boolean
   * showing Error Dialog flag
   */
  public showingErrorDialog: boolean = false;

  /**
   * created
   * Main function for error handling
   */
  public created() {
    const store = this.$store;
    const mutation$ = new Subject<MutationPayload>();
    store.subscribe((mutation) => {
      const { type } = mutation;
      if (type.endsWith('_ERROR')) {
        const error: AxiosError = mutation.payload;
        const status = _get(error, 'response.status') as number;
        const isForbidden = status === 403;
        const isUnauthorized = status === 401;
        const isInternalError = status >= 500;
        const isNetworkError = !status;
        const isValidationError = status === 422;
        const isPageNotFound = status === 404;
        const errorPayload = _get(error, 'response.data.errors');

        if (isUnauthorized) {
          return;
        }

        if (this.locallyOverridedError(type)) {
          return;
        }

        if (userIsInactive) {
          return;
        }

        if (isForbidden) {
          this.showErrorDialogAndRedirect(
            `You do not have access to this resource.
            Please contact the COnNECT+ system administrator to obtain access.`,
          );
          return;
        }

        if (isPageNotFound) {
          this.showErrorDialogAndRedirect(
            `The requested URL was not found on this server. Please contact the COnNECT+ system administrator`,
          );
          return;
        }

        if (errorPayload) {
          errorPayload.forEach((payload: any) => {
            const { errorId, errorCode, errorMessage } = payload;
            const compiledErrorMessage = getCompiledMessage(
              errorCode,
              errorMessage,
              errorId,
            );
            showStickyErrorNotification(compiledErrorMessage);
          });
          return;
        }

        if (isInternalError) {
          this.showErrorNotification(`Server Error. Please try again later.`);
        } else if (isNetworkError) {
          this.showErrorNotification(
            `The request timed out and may be taking too long to complete. Please try again later.`,
          );
        } else if (isValidationError) {
          this.showErrorNotification(
            `Your request cannot be processed due to validation errors.`,
          );
        }
      }
    });
  }

  /**
   * This function lets you disable the global api error handler which is
   * needed when you wish to override the global error handling behaviour in a specific component.
   *
   * To use this, add the necessary data to the overridedErrorComponentRoute object.
   * Note: Be sure to manually handle the specified error type in the specified component.
   */
  public locallyOverridedError(type: any) {
    if (this.$route.name) {
      const compRoute = overridedErrorComponentRoute.find(
        (x) => x.routeName === this.$route.name,
      );
      if (compRoute) {
        return compRoute.errorTypes.includes(type);
      }
    }
    return false;
  }

  /**
   * errorDialogDisplayCb
   * Error dialog display call back
   */
  public errorDialogDisplayCb() {
    this.showingErrorDialog = false;
    this.$router.push('/');
  }

  /**
   * showErrorDialogAndRedirect
   * Show error dialog and redirect to dashboard
   * @param alertMsg
   */
  public showErrorDialogAndRedirect(alertMsg: string) {
    if (this.showingErrorDialog) {
      return;
    }
    this.showingErrorDialog = true;
    Dialog.alert({
      message: alertMsg,
      onConfirm: this.errorDialogDisplayCb,
      onCancel: this.errorDialogDisplayCb,
    });
  }

  /**
   * showErrorNotification
   * Show error notification on top
   * @param alertMsg
   */
  public showErrorNotification(alertMsg: string) {
    Notification.open({
      type: 'is-danger',
      position: 'is-top',
      message: alertMsg,
      duration: 5000,
    });
  }
}
