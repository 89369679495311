



































import { CplusDocumentType } from '@/jbi-shared/types/document.types';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { StagingEditorCommonMixin } from '../mixins/staging-editor-common.mixin';
import StagingDocumentNavigationTabs from './StagingDocumentNavigationTabs.vue';
import StagingEditorHeaderDocInfo from './StagingEditorHeaderDocInfo.vue';

@Component({
  components: {
    // IconButton,
    // DocumentNavigationTabs,
    // ProjectEditorHeaderDocInfo,
    // ProjectEditorHeaderCollapseableTab,
    StagingDocumentNavigationTabs,
    StagingEditorHeaderDocInfo
  }
})
export default class StagingEditorHeader extends mixins(
  StagingEditorCommonMixin
) {
  get displayedDocuments() {
    return this.pendingDocuments.map((doc) => {
      let tabDisplayTitle;
      if (!doc.documentId) {
        tabDisplayTitle = 'Template Recommended Practice';
      } else {
        const existingProjectDocument = this.pendingProjectExistingProjectDocuments.find(
          (projectDocument) => projectDocument.id === doc.documentId
        );
        tabDisplayTitle = existingProjectDocument?.title;
      }
      return {
        ...doc,
        tabDisplayTitle
      };
    });
  }
}
