import { documentsState } from './documents.state';
import { documentsGetters } from './documents.getters';
import { documentsActions } from './documents.actions';
import { documentsMutations } from './documents.mutations';
import { ModuleTree } from 'vuex';
import { RootState } from '@/store/store';

export default {
  namespaced: true,
  state: documentsState,
  getters: documentsGetters,
  actions: documentsActions,
  mutations: documentsMutations
} as ModuleTree<RootState>;
