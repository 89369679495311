









import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import dayjs from 'dayjs';
import ListSectionValueEditor from '@/components/editor/SectionEditor/ListSectionValueEditor.vue';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DocumentData } from '../../../../jbi-shared/types/full-document-revision-object.types';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  components: {
    ListSectionValueEditor
  }
})
export default class RelatedRPDocsSectionValueEditor extends mixins(
  ViewModeMixin
) {
  @Prop() public relatedDoc!: DocumentData;
  @Prop(Number) public index!: number;

  get documentName() {
    return `${this.relatedDoc?.defaultDocDisplayId}-${this.relatedDoc?.title}`;
  }
}
