import { opsToText } from '@/utils/quill-delta.util';
import {
  PendingSectionError,
  PendingSectionNotice,
  PendingTextSectionData,
  TextSectionDefaultTitle
} from '@/jbi-shared/types/document.types';

export function validateTextSection(
  textSection: PendingTextSectionData
): PendingTextSectionData {
  const sectionTitle = opsToText(textSection.content.sectionTitle).trim();
  const sectionValue = opsToText(textSection.content.sectionValue).trim();

  if (textSection.errors) {
    // Check sectionValue
    if (sectionValue && sectionValue.length > 0) {
      // Remove error
      textSection.errors = textSection.errors.filter(
        (error) => error.code !== PendingSectionError.SECTION_VALUE_EMPTY
      );
    } else {
      // Append error
      if (
        !textSection.errors?.find(
          (error) => error.code === PendingSectionError.SECTION_VALUE_EMPTY
        )
      ) {
        textSection.errors.push({
          code: PendingSectionError.SECTION_VALUE_EMPTY,
          message: `Section value "${sectionTitle}" is empty`
        });
      }
    }
  }

  if (textSection.notices) {
    const defaultTitles = (Object.values(
      TextSectionDefaultTitle
    ) as string[]).map((title) => title.toLowerCase());
    // Check sectionTitle
    if (defaultTitles.includes(sectionTitle.toLowerCase())) {
      // Remove error
      textSection.notices = textSection.notices.filter(
        (notice) => notice.code !== PendingSectionNotice.SECTION_TITLE_NOT_MATCH
      );
    } else {
      // Replace error
      textSection.notices = textSection.notices.filter(
        (notice) => notice.code !== PendingSectionNotice.SECTION_TITLE_NOT_MATCH
      );
    }
  }

  // Update isValid flag
  textSection.isValid = textSection.errors?.length === 0;

  return textSection;
}
