














import { Component, Prop } from 'vue-property-decorator';
import { Criterion } from '@/jbi-shared/types/criterions.types';
// tslint:disable-next-line
import CriterionForm from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { startCase as _startCase } from 'lodash';
import ListSectionValueEditor from '@/components/editor/SectionEditor/ListSectionValueEditor.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    CriterionForm,
    ListSectionValueEditor
  }
})
export default class CriterionSectionValueEditor extends mixins(ViewModeMixin) {
  @Prop()
  public criterion!: FullDocumentRevisionObject['revision']['sections']['criterionSection'][0];
  @Prop(Number) public index!: number;
  @Prop({ required: true })
  public subSections!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public criterionSectionId!: number;
  @Prop()
  public dirtyCriterions!: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  @Prop({
    default: () => {
      return true;
    }
  })
  public editable!: boolean;
  @Prop({ type: Boolean, default: false })
  public isStaging!: boolean;
  @Prop({ type: Boolean, default: false })
  public isNew!: boolean;
  @Prop({ type: Boolean, default: false })
  public isInvalid!: boolean;

  get content() {
    return this.criterion.content;
  }

  public openEditModal() {
    this.$buefy.modal.open({
      parent: this,
      component: CriterionForm,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Edit Criterion',
        criterion: this.criterion,
        subSections: this.subSections,
        dirtyTagMaps: this.dirtyTagMaps,
        dirtyCriterions: this.dirtyCriterions,
        criterionSectionId: this.criterionSectionId
      },
      events: {
        'update:criterions': (
          e: FullDocumentRevisionObject['revision']['sections']['criterionSection']
        ) => this.$emit('update:criterions', e),
        'update:dirtyTagMaps': (e: DirtyTagMap[]) =>
          this.$emit('update:dirtyTagMaps', e)
      }
    });
  }
}
