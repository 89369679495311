

























import { Component, Prop } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import ProjectList from './ProjectList.vue';
import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedProjectList extends mixins(PaginationMixin) {
  @Prop() public items!: ProjectListItemParam[];
  @Prop() public selectedProjects!: ProjectListItemParam[];
  @Prop() public selectAll!: boolean;
  @Prop() public totalCount!: number;
  @Prop() public sortCriteria!: string;
  @Prop() public sortOrder!: 'ASC' | 'DESC';
  @Prop() public selectable!: boolean;
  @Prop() public pagination!: boolean;

  get ProjectList() {
    return ProjectList;
  }
}
