import {
  UsersState,
  Pagination,
  SingleUserSearchResult,
  AcceptUserInvite,
  VerifyUserInviteResponse,
  User,
  Invitation
} from './types/users.types';
import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';
import { OicPayload } from '@/jbi-shared/types/jaas-oauth2.types';

export const usersMutations = {
  INVITE_NEW_USER_LOADING(state: UsersState) {
    state.apiState.inviteNewUser = {
      ...defaultApiState,
      loading: true
    };
  },
  INVITE_NEW_USER_SUCCESS(state: UsersState, payload: any) {
    state.apiState.inviteNewUser = {
      ...defaultApiState,
      success: true
    };
  },
  INVITE_NEW_USER_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.inviteNewUser = {
      ...defaultApiState,
      error
    };
  },

  GET_USERS_LOADING(state: UsersState) {
    state.apiState.getUsers = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_USERS_SUCCESS(state: UsersState, payload: Pagination<User>) {
    state.apiState.getUsers = {
      ...defaultApiState,
      success: true
    };
    state.users = payload;
  },
  GET_USERS_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.getUsers = {
      ...defaultApiState,
      error
    };
  },

  GET_USER_INVITATIONS_LOADING(state: UsersState) {
    state.apiState.getUserInvitations = {
      ...defaultApiState,
      loading: true
    };
    state.userInvitations = undefined;
  },
  GET_USER_INVITATIONS_SUCCESS(state: UsersState, payload: Invitation[]) {
    state.apiState.getUserInvitations = {
      ...defaultApiState,
      success: true
    };
    state.userInvitations = payload;
  },
  GET_USER_INVITATIONS_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.getUserInvitations = {
      ...defaultApiState,
      error
    };
  },

  REMOVE_USERS_INVITATION_LOADING(state: UsersState) {
    state.apiState.removeUserInvitation = {
      ...defaultApiState,
      loading: true
    };
  },
  REMOVE_USERS_INVITATION_SUCCESS(state: UsersState) {
    state.apiState.removeUserInvitation = {
      ...defaultApiState,
      success: true
    };
  },
  REMOVE_USERS_INVITATION_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.removeUserInvitation = {
      ...defaultApiState,
      error
    };
  },
  RESEND_USER_INVITATION_LOADING(state: UsersState) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      loading: true
    };
  },
  RESEND_USER_INVITATION_SUCCESS(state: UsersState) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      success: true
    };
  },
  RESEND_USER_INVITATION_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      error
    };
  },
  SEARCH_USER_BY_TEXT_LOADING(state: UsersState) {
    state.apiState.searchUsersByText = {
      ...defaultApiState,
      loading: true
    };
  },
  SEARCH_USER_BY_TEXT_SUCCESS(
    state: UsersState,
    payload: SingleUserSearchResult[]
  ) {
    state.apiState.searchUsersByText = {
      ...defaultApiState,
      success: true
    };
    state.searchUsersByTextResults = payload;
  },
  SEARCH_USER_BY_TEXT_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.searchUsersByText = {
      ...defaultApiState,
      error
    };
  },

  GET_MY_ROLES_LOADING(state: UsersState) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_MY_ROLES_SUCCESS(state: UsersState, payload: string[]) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      success: true
    };
    state.myRoles = payload;
  },
  GET_MY_ROLES_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      error
    };
  },
  GET_OIC_PAYLOAD_LOADING(state: UsersState) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_OIC_PAYLOAD_SUCCESS(state: UsersState, payload: OicPayload) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      success: true
    };
    state.oicPayload = payload;
  },
  GET_OIC_PAYLOAD_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.getMyRoles = {
      ...defaultApiState,
      error
    };
  },
  RESET_MY_ROLES(state: UsersState, error: AxiosError) {
    state.apiState.getMyRoles = {
      ...defaultApiState
    };
    state.myRoles = undefined;
  },
  UPDATE_STATUS_LOADING(state: UsersState) {
    state.apiState.updateStatus = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_STATUS_SUCCESS(state: UsersState) {
    state.apiState.updateStatus = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_STATUS_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.updateStatus = {
      ...defaultApiState,
      error
    };
  },
  ACCEPT_USER_INVITE_LOADING(state: UsersState) {
    state.apiState.acceptUserInvite = {
      ...defaultApiState,
      loading: true
    };
  },
  ACCEPT_USER_INVITE_SUCCESS(state: UsersState, payload: any) {
    state.apiState.acceptUserInvite = {
      ...defaultApiState,
      success: true
    };
    state.acceptUserInvite = payload.data;
  },
  ACCEPT_USER_INVITE_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.acceptUserInvite = {
      ...defaultApiState,
      error
    };
  },
  VERIFY_USER_INVITE_LOADING(state: UsersState) {
    state.apiState.verifyUserInvite = {
      ...defaultApiState,
      loading: true
    };
  },
  VERIFY_USER_INVITE_SUCCESS(state: UsersState, payload: any) {
    state.apiState.verifyUserInvite = {
      ...defaultApiState,
      success: true
    };
    state.verifyUserInvite = payload.data;
  },
  VERIFY_USER_INVITE_ERROR(state: UsersState, error: AxiosError) {
    state.apiState.verifyUserInvite = {
      ...defaultApiState,
      error
    };
  }
};
