














































import { ProjectListItemParam } from '@/store/modules/projects/types/projects.types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RootState } from '@/store/store';
import { get as _get } from 'lodash';
import { ApiState } from '@/store/types/general.types';
import { Action, State } from 'vuex-class';
import BaseTable from '@/components/base/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { GetProjectNoteHistoryResponsePayload } from '@/store/modules/projects/types/projects.types';
import dayjs from 'dayjs';

@Component({
  components: {
    BaseTable,
    BaseLoading
  }
})
export default class NoteHistoryDialog extends Vue {
  @Action('projects/getNoteHistory')
  public getNoteHistory!: (p: number) => void;

  @State((state: RootState) => _get(state, 'projects.apiState.getNoteHistory'))
  public getNoteHistoryApi!: ApiState;

  @State((state: RootState) => _get(state, 'projects.projectNoteHistory'))
  public projectNoteHistory!: GetProjectNoteHistoryResponsePayload;

  @Prop()
  public project!: ProjectListItemParam;

  get noteHistory() {
    return _get(this.projectNoteHistory, 'noteHistory');
  }

  public formatDateTime(date: string) {
    return date ? dayjs(date).format('DD MMM YYYY, h:mm A') : '-';
  }

  public mounted() {
    this.getNoteHistory(this.project.projectId);
  }
}
