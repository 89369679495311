import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

export enum EditorViewMode {
  editing = 'editing',
  version = 'version',
  public = 'public',
}

@Component({})
export class ViewModeMixin extends Vue {
  @Inject() public readonly viewMode!: EditorViewMode;

  get isVersionMode() {
    return this.viewMode === EditorViewMode.version;
  }

  get isPublicMode() {
    return this.viewMode === EditorViewMode.public;
  }

  get editorIsDisabled() {
    return this.isVersionMode || this.isPublicMode;
  }
}
