













import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { difference as _difference } from 'lodash';
import Quill from 'quill';

@Component({})
export default class IconSelector extends Vue {
  @Prop(Object)
  public readonly parentStyle!: object;

  public mounted() {
    // @ts-ignore
    this.$refs.container.addEventListener(
      'click',
      this.handleIconInsertion.bind(this)
    );
  }

  public beforeDestroy() {
    // @ts-ignore
    this.$refs.container.removeEventListener(
      'click',
      this.handleIconInsertion.bind(this)
    );
  }

  public handleIconInsertion(e: MouseEvent) {
    const elem = (e.target as unknown) as Element;
    if (elem.classList.contains('ql-picker-item')) {
      e.stopPropagation();
      e.preventDefault();
      // @ts-ignore
      const label = e?.target?.dataset?.label;
      this.$emit('insertIcon', label);
    }
  }
}
