




























































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { QuillContainerElement } from '@/store/modules/documents/types/quill.types';
import Delta from 'quill-delta';

@Component({})
export default class BprListValueDisplay extends mixins(ViewModeMixin) {
  @Prop(Number) public index!: number;
  @Prop(Array) public content!: any;
  @Prop(Boolean) public editable!: boolean;
  @Prop({ type: Boolean, default: true }) public showEdit!: boolean;
  @Prop({ type: Boolean, default: false }) public isHighlighted!: boolean;
  @Prop({ type: Boolean, default: false }) public isStaging!: boolean;

  get editorOption() {
    return {
      modules: {
        toolbar: false
      },
      readOnly: true
    };
  }

  public editorInstance() {
    return (this.$refs.bprEditor as QuillContainerElement).quill;
  }

  public setEditorContent() {
    // Clone data to avoid affecting to props, we shouldn't change props directly
    let bprContent = JSON.parse(JSON.stringify(this.content));
    // HACK: We will have the data like this if we have an empty value in Microsoft word
    // [{"insert":"\n"}] or []
    // When you setContents to Quill editor, if your data only has "\n", it will show the placeholder "Insert text here..."
    if (
      !bprContent.length ||
      (bprContent.length > 0 && !bprContent[0].insert.trim())
    ) {
      bprContent = [{ insert: '\n\n' }];
    }

    this.editorInstance().setContents(new Delta(bprContent));
  }

  public mounted() {
    this.setEditorContent();
    this.editorInstance().enable(false);
  }

  @Watch('content')
  private onContentUpdate() {
    this.setEditorContent();
  }
}
