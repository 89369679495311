





























import { Component, Vue } from 'vue-property-decorator';
import BaseNav from '@/jbi-shared/vue-components/BaseNav.vue';
import { NavLink } from '@/jbi-shared/types/Nav';
import { useUserName, useUserEmail } from '@/utils/user.util';
import { useAction, useGetter, useAuthState } from '@/utils/store.util';
import { JAAS_LOGIN_URL, redirectToJaasLogin } from '@/utils/login.util';
import { GeneralRoles } from '@/jbi-shared/types/casbin-permission.types';
import { Permission } from '@/store/modules/auth/types/auth.types';
import { NavBarLinks } from '@/jbi-shared/types/navbar.types';
import { navBarLinks } from '@/utils/navbar-links.types';
import CplusBaseNav from '@/jbi-shared/vue-components/CplusBaseNav.vue';
import BaseNavDropdown from '@/jbi-shared/vue-components/BaseNavDropdown.vue';
import { RootState } from '../../store/store';

@Component({ components: { BaseNav, BaseNavDropdown, CplusBaseNav } })
export default class Header extends Vue {
  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  get signOut() {
    return useAction.call(this, 'auth/signOut');
  }

  get JAAS_LOGIN_URL(): string {
    return JAAS_LOGIN_URL;
  }

  get redirectToJaasLogin() {
    return redirectToJaasLogin;
  }

  get isAdmin(): boolean {
    const permissions = (this.$store.state as RootState).users.myRoles || [];
    return permissions.some((e) => e === GeneralRoles.Admin);
  }

  get navBarLinks(): NavBarLinks[] {
    return navBarLinks;
  }

  get navLinks(): NavLink[] {
    const links: NavLink[] = [
      {
        label: `Sign Out`,
        onClick: this.signOut,
        class: `has-text-primary has-text-weight-semibold`
      }
    ];

    if (this.isAdmin) {
      const administratorSetting = {
        label: `Settings`,
        to: {
          name: 'admin-setting'
        },
        class: ``
      } as NavLink;
      links.unshift(administratorSetting);

      const administratorDashboard = {
        label: `Administrator Dashboard`,
        to: {
          name: 'admin-dashboard'
        },
        class: ``
      } as NavLink;
      links.unshift(administratorDashboard);

      const resourceExportLink = {
        label: `Document/Resource Exporter`,
        to: {
          name: 'wk-resource-export'
        },
        class: ``
      } as NavLink;
      links.unshift(resourceExportLink);

      const userAdministrationLink = {
        label: `User Administration`,
        to: {
          name: 'admin-user-management'
        },
        class: ``
      } as NavLink;
      links.unshift(userAdministrationLink);
    }

    return links;
  }

  get userName(): string | undefined {
    return useUserName.call(this);
  }

  get userEmail(): string | undefined {
    return useUserEmail.call(this);
  }
}
