

































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import {
  WkExportStatus,
  WkExportResourceDetail,
  WkExportResourceDetailPayload,
  FetchWkExportResourceDetailRequestPayload
} from '../../jbi-shared/types/wk-export.types';
import {
  isTruthy,
  isDifferent
} from '../../jbi-shared/util/watcher.vue-decorator';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import Container from '@/components/Container.vue';
import WkResourceExportStatusFilterBox from './wkResourceExportStatusFilterBox.vue';
import PaginateWkExportResouceList from './paginatedWkExportResourceList.vue';
import { Debounce } from '../../jbi-shared/util/debounce.vue-decorator';

const initialState = {
  documentId: '',
  projectName: '',
  status: ''
};

const searchState = {
  documentId: '',
  projectName: '',
  status: ''
};

@Component<WkResourceExportStatus>({
  components: {
    DashboardHeader,
    Container,
    WkResourceExportStatusFilterBox,
    PaginateWkExportResouceList
  }
})
export default class WkResourceExportStatus extends Vue {
  public status: WkExportStatus | null = null;
  public allJobCount: number = 0;
  public allJobCountStatic: number = 0;
  public successfulExportCount: number = 0;
  public failedExportCount: number = 0;
  public allExportJobDetails: WkExportResourceDetail[] = [];
  public allExportJobDetailsStatic: WkExportResourceDetail[] = [];
  public percentageString: string = 'Exported 0.00% of the Document(s)';
  public interval = setInterval(() => {
    this.fetchStatus();
  }, 10000);

  public filteredParams: any = {
    ...initialState
  };

  public page = 1;
  public perPage = 50;

  @Action('documents/getWkExportStatus')
  public getWkExportStatus!: (id: number) => Promise<WkExportStatus>;

  @Action('documents/getWkExportDetails')
  public getWkExportDetails!: ({
    wkExportId,
    page,
    perPage
  }: FetchWkExportResourceDetailRequestPayload) => Promise<
    WkExportResourceDetailPayload
  >;

  get wkExportId() {
    return Number(this.$route.params.wkExportId);
  }

  get shouldStopFetching() {
    return (
      this.status &&
      ['completed', 'failed'].includes(this.status.eventPayload.status)
    );
  }

  get isDone() {
    return this.shouldStopFetching;
  }

  get isDonewithExportStatus() {
    const params = {
      wkExportId: this.wkExportId,
      page: this.page,
      perPage: this.perPage,
      documentId: this.filteredParams.documentId
        ? searchState?.documentId?.trim()
        : this.filteredParams?.documentId?.trim(),
      projectName: this.filteredParams.projectName
        ? searchState?.projectName?.trim()
        : this.filteredParams?.projectName?.trim(),
      status: this.filteredParams.status.trim()
        ? searchState?.status?.trim()
        : this.filteredParams?.status?.trim()
    };
    this.getWkExportDetails(params).then((data) => {
      this.successfulExportCount = data?.completedJobs?.length || 0;
      this.failedExportCount = data?.failedJobs?.length || 0;
      this.allExportJobDetails = data?.allJobsData || [];
      this.allExportJobDetailsStatic = data?.allJobsData || [];
      this.allJobCount = Number(data?.allJobsCount);
      this.allJobCountStatic = Number(data?.allJobsCount);
      if (this.allJobCount !== 0) {
        const exportPercentage: string = parseFloat(
          ((this.successfulExportCount / this.allJobCount) * 100).toString()
        ).toFixed(2);
        this.percentageString = `Exported ${exportPercentage}% of the Document(s)`;
      }
    });
    return this.shouldStopFetching;
  }

  public created() {
    this.fetchStatus();
  }

  public fetchStatus() {
    // FIXME: we check the wkExportId to hide the error alert on document export page for now
    if (this.wkExportId) {
      this.getWkExportStatus(this.wkExportId).then(
        (status) => (this.status = status)
      );
    }
  }

  get filterQueryParams() {
    return {
      ...this.filteredParams
    };
  }

  public handleDownload(url: string) {
    window.open(url);
  }

  public handleReset() {
    this.allExportJobDetails = this.allExportJobDetailsStatic;
    this.allJobCount = this.allJobCountStatic;
    searchState.documentId = '';
    searchState.projectName = '';
    searchState.status = '';
    this.filteredParams = {
      ...this.filteredParams,
      ...initialState
    };
    this.updateRouteQuery();
  }

  public handleFilter(filterParam: Partial<any>) {
    if (filterParam.documentId) {
      searchState.documentId = filterParam.documentId;
    }
    if (filterParam.projectName) {
      searchState.projectName = filterParam.projectName;
    }
    if (filterParam.status) {
      searchState.status = filterParam.status;
    }
    this.allJobCount = this.allExportJobDetails.length;
    this.filteredParams = {
      ...this.filteredParams,
      ...filterParam
    };
    this.updateRouteQuery();
  }

  public updateRouteQuery() {
    const params = {
      wkExportId: this.wkExportId,
      page: this.page,
      perPage: this.perPage,
      documentId: this.filteredParams.documentId
        ? searchState?.documentId?.trim()
        : this.filteredParams?.documentId?.trim(),
      projectName: this.filteredParams.projectName
        ? searchState?.projectName?.trim()
        : this.filteredParams?.projectName?.trim(),
      status: this.filteredParams.status.trim()
        ? searchState?.status?.trim()
        : this.filteredParams?.status?.trim()
    };

    this.getWkExportDetails(params).then((data) => {
      this.allExportJobDetails = data?.allJobsData || [];
      this.allJobCount = Number(data?.allJobsCount);
    });
  }

  @Watch('shouldStopFetching')
  @isTruthy
  @isDifferent
  public stopFetching() {
    clearInterval(this.interval);
  }

  public updateFilteredParams(filterParams: any) {
    this.filteredParams = {
      ...filterParams
    };
  }
}
