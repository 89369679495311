

































import Component, { mixins } from 'vue-class-component';
import ActivityLogItem from './ActivityLogItem.vue';
import DashboardListingHeader from '@/components/dashboard/DashboardListingHeader.vue';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin';
import { Action } from 'vuex-class';
import { EventLog } from '@/store/modules/event-logs/types/event-logs.types';
import { RootState } from '@/store/store';
import BaseLoading from '@/components/base/BaseLoading.vue';

@Component({
  components: {
    ActivityLogItem,
    DashboardListingHeader,
    BaseLoading
  }
})
export default class ActivityLogs extends mixins(ScreenSizeMixin) {
  @Action('eventLogs/getRecentEventLogs')
  public getRecentEventLogs!: () => EventLog[];

  get getRecentEventLogsLoading() {
    return (this.$store.state as RootState).eventLogs.apiState
      .getRecentEventLogs.loading;
  }

  get recentEventLogs() {
    return (this.$store.state as RootState).eventLogs.recentEventLogs || [];
  }

  get displayedEventLogs() {
    return this.recentEventLogs.slice() || [];
  }

  get emptyText() {
    return `You don't have any activity yet.`;
  }

  public created() {
    this.getRecentEventLogs();
  }
}
