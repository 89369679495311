import { Component, Vue, Watch } from 'vue-property-decorator';
import { Criterion } from '@/jbi-shared/types/criterions.types';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '../../../store/modules/documents/types/documents.types';
import { cloneDeep, get as _get, PartialDeep } from 'lodash';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import {
  SidebarTabOption,
  processTagMapAfterCriterionRearrangementThrottle
} from './editor-state.util';
import { Author } from '../../../jbi-shared/types/author.types';

interface EditorState {
  dirtyTextSections: FullDocumentRevisionObject['revision']['sections']['textSections'];
  dirtyBprs: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  dirtyDocumentTitle: string;
  dirtySearchDate: string;
  dirtyAuthors: FullDocumentRevisionObject['revision']['sections']['authorSubSections'];
  dirtyCriterions: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  dirtyOhs: number[];
  dirtyRelatedDocs: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];
  publishing: boolean;
  activeSidebarTab: SidebarTabOption;
  citationSearch: string;
  ohsSearch: string;
  tempDirtyOhs: number[];
  sectionSearchValue: string;
  dirtyTagMaps: DirtyTagMap[];
  // for re-rendering EditorContainer
  editorContainerKey: number;
  // for re-rendering Editor
  editorKey: number;
  contentUndoStack: string[];
  contentRedoStack: string[];
  serializedContent: string;
  isUndoingOrRedoing: boolean;
  tdrUri: string | undefined;
}

const initialState: EditorState = {
  dirtyTextSections: [],
  dirtyBprs: [],
  dirtyDocumentTitle: '',
  dirtySearchDate: '',
  dirtyAuthors: [],
  dirtyCriterions: [],
  dirtyOhs: [],
  dirtyRelatedDocs: [],
  publishing: false,
  activeSidebarTab: 'sections',
  citationSearch: '',
  ohsSearch: '',
  tempDirtyOhs: [],
  sectionSearchValue: '',
  dirtyTagMaps: [],
  editorContainerKey: Math.random(),
  editorKey: Math.random(),
  contentUndoStack: [],
  contentRedoStack: [],
  serializedContent: '',
  isUndoingOrRedoing: false,
  tdrUri: undefined
};

const editorState = Vue.observable(cloneDeep(initialState));

@Component<EditorStateMixin>({})
export class EditorStateMixin extends Vue implements EditorState {
  get tdrUri() {
    return editorState.tdrUri;
  }

  set tdrUri(uri) {
    editorState.tdrUri = uri;
  }

  get dirtyTextSections() {
    return editorState.dirtyTextSections;
  }
  set dirtyTextSections(v) {
    editorState.dirtyTextSections = v;
  }

  get dirtyBprs() {
    return editorState.dirtyBprs;
  }
  set dirtyBprs(v) {
    editorState.dirtyBprs = v;
  }

  get dirtyDocumentTitle() {
    return editorState.dirtyDocumentTitle;
  }
  set dirtyDocumentTitle(v) {
    editorState.dirtyDocumentTitle = v;
  }

  get dirtySearchDate() {
    return editorState.dirtySearchDate;
  }
  set dirtySearchDate(v) {
    editorState.dirtySearchDate = v;
  }

  get dirtyAuthors() {
    return editorState.dirtyAuthors;
  }
  set dirtyAuthors(v) {
    editorState.dirtyAuthors = v;
  }

  get dirtyCriterions() {
    return editorState.dirtyCriterions;
  }
  set dirtyCriterions(v) {
    editorState.dirtyCriterions = v;
  }

  get tempDirtyOhs() {
    return editorState.tempDirtyOhs;
  }

  set tempDirtyOhs(v) {
    editorState.tempDirtyOhs = v;
  }

  get dirtyOhs() {
    return editorState.dirtyOhs;
  }
  set dirtyOhs(v) {
    editorState.dirtyOhs = v;
  }

  get dirtyRelatedDocs() {
    return editorState.dirtyRelatedDocs;
  }
  set dirtyRelatedDocs(v) {
    editorState.dirtyRelatedDocs = v;
  }

  get publishing() {
    return editorState.publishing;
  }
  set publishing(v) {
    editorState.publishing = v;
  }

  get activeSidebarTab() {
    return editorState.activeSidebarTab;
  }
  set activeSidebarTab(v) {
    editorState.activeSidebarTab = v;
  }

  get citationSearch() {
    return editorState.citationSearch;
  }
  set citationSearch(v) {
    editorState.citationSearch = v;
  }

  get ohsSearch() {
    return editorState.ohsSearch;
  }
  set ohsSearch(v) {
    editorState.ohsSearch = v;
  }

  get sectionSearchValue() {
    return editorState.sectionSearchValue;
  }
  set sectionSearchValue(v) {
    editorState.sectionSearchValue = v;
  }

  get dirtyTagMaps() {
    return editorState.dirtyTagMaps;
  }
  set dirtyTagMaps(v) {
    editorState.dirtyTagMaps = v;
  }

  get editorState() {
    return editorState;
  }

  get editorContainerKey() {
    return editorState.editorContainerKey;
  }

  set editorContainerKey(v) {
    editorState.editorContainerKey = v;
  }

  get editorKey() {
    return editorState.editorKey;
  }

  set editorKey(v) {
    editorState.editorKey = v;
  }

  get contentUndoStack() {
    return editorState.contentUndoStack;
  }

  set contentUndoStack(v) {
    editorState.contentUndoStack = v;
  }

  get contentRedoStack() {
    return editorState.contentRedoStack;
  }

  set contentRedoStack(v) {
    editorState.contentRedoStack = v;
  }

  get serializedContent() {
    return editorState.serializedContent;
  }

  set serializedContent(v) {
    editorState.serializedContent = v;
  }

  get isUndoingOrRedoing() {
    return editorState.isUndoingOrRedoing;
  }

  set isUndoingOrRedoing(v) {
    editorState.isUndoingOrRedoing = v;
  }

  public resetEditorState() {
    editorState.activeSidebarTab = initialState.activeSidebarTab;
    editorState.citationSearch = initialState.citationSearch;
    editorState.sectionSearchValue = initialState.sectionSearchValue;
  }

  public resetEditorUndoRedoState() {
    editorState.isUndoingOrRedoing = initialState.isUndoingOrRedoing;
    editorState.contentUndoStack = cloneDeep(initialState.contentUndoStack);
    editorState.contentRedoStack = cloneDeep(initialState.contentRedoStack);
    editorState.serializedContent = initialState.serializedContent;
  }

  public reRenderEditor() {
    this.editorKey = Math.random();
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyCriterions')
  public onCriterionsChanged(v: Criterion[], prevV: Criterion[]) {
    this.dirtyTagMaps =
      processTagMapAfterCriterionRearrangementThrottle(
        v,
        prevV,
        this.dirtyTagMaps
      ) || this.dirtyTagMaps;
  }
}
