import { useWebsocket } from '@/utils/websocket.util';
import { ToastProgrammatic as Toast } from 'buefy';
import { EVENTS } from '@/store/modules/websocket/websocket.state';
import {
  AdminExportType,
  ExportProjectListWorkerJobMetadata,
} from '@/store/modules/admin/types/admin.types';
import { useAction } from './store.util';
import { WorkerJobStatus } from '@/jbi-shared/types/cplus-worker.types';
import Vue from 'vue';
import { Job } from 'bull';

const markWorkerJobAsCompleted = function (this: Vue, payload: Job) {
  return useAction.call(this, 'websocket/markWorkerJobAsCompleted')(payload);
};

const markWorkerJobAsErrored = function (this: Vue, payload: Job) {
  return useAction.call(this, 'websocket/markWorkerJobAsErrored')(payload);
};

const onExportSuccess = async function (
  this: Vue,
  job: Job,
  type: AdminExportType,
) {
  if (job.returnvalue.status === WorkerJobStatus.PROCESSED) {
    markWorkerJobAsCompleted.call(this, job);

    const { storageUrl } = job.returnvalue
      .metadata as ExportProjectListWorkerJobMetadata;
    const filename = `ProjectList - ${new Date().toISOString()}.${type}`;

    Toast.open({
      message: `Downloading .${type} file ...`,
      duration: 3000,
      queue: false,
    });
    saveAs(storageUrl, filename);
  } else {
    markWorkerJobAsErrored.call(this, job);
    Toast.open({
      message: `Error exporting. Please try again later.`,
      duration: 3000,
      type: 'is-danger',
      queue: false,
    });
  }
};

const onAdminDownloadSuccess = async function (
  this: Vue,
  job: Job,
  projectTitle: string,
) {
  if (job.returnvalue.status === WorkerJobStatus.PROCESSED) {
    markWorkerJobAsCompleted.call(this, job);

    const { storageUrl } = job.returnvalue.metadata;

    const filename = `${projectTitle}.zip`;

    Toast.open({
      message: `Zipping and Download .docx file(s)...`,
      duration: 3000,
      queue: false,
    });
    saveAs(storageUrl, filename);
  } else {
    markWorkerJobAsErrored.call(this, job);
    Toast.open({
      message: `Error exporting. Please try again later.`,
      duration: 3000,
      type: 'is-danger',
      queue: false,
    });
  }
};

const onExportError = function (this: Vue, job: Job, error: Error) {
  markWorkerJobAsErrored.call(this, job);

  Toast.open({
    message: `Error exporting. Please try again later.`,
    duration: 3000,
    type: 'is-danger',
    queue: false,
  });

  return;
};

export const handleAdminDownloadDocument = async function (
  this: Vue,
  job: Job,
  projectTitle: string,
) {
  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);
  const { id: jobId } = job;

  await connectToWs();

  Toast.open({
    message: `Preparing .docx file(s) for download ...`,
    duration: 1000,
    queue: false,
  });

  joinRoom(jobId);

  try {
    const event = EVENTS.ADMIN_DOWNLOAD_DOCUMENT;

    job = (await listenOnceTo(event)) as Job;

    await onAdminDownloadSuccess.call(this, job, projectTitle);
    await disconnectWs();
  } catch (error) {
    onExportError.call(this, job, error);
    await disconnectWs();
  }
};

export const handleAdminExporting = async function (
  this: Vue,
  job: Job,
  type: AdminExportType,
) {
  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);
  const { id: jobId } = job;

  await connectToWs();

  Toast.open({
    message: `Preparing .${type} file for download ...`,
    duration: 1000,
    queue: false,
  });

  joinRoom(jobId);

  try {
    const event =
      type === AdminExportType.xlsx
        ? EVENTS.EXPORT_ADMIN_PROJECT_LIST_XLSX
        : EVENTS.EXPORT_ADMIN_PROJECT_LIST_PDF;

    job = (await listenOnceTo(event)) as Job;

    await onExportSuccess.call(this, job, type);
    await disconnectWs();
  } catch (error) {
    onExportError.call(this, job, error);
    await disconnectWs();
  }
};
