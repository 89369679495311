







































































































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { useGetter } from '@/utils/store.util';
import { ProjectIndexingPayload } from '@/jbi-shared/types/search.types';
import truncate from 'truncate';
import { ScreenSizeMixin } from '@/utils/screen-size-mixin.ts';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../utils/date.util';
import { EventLog } from '../../../store/modules/event-logs/types/event-logs.types';
import { EVENT_LOG_TYPE } from '../../../jbi-shared/types/cplus-event-log.types';
import { getFullNameFromUser } from '../../../jbi-shared/util/user.utils';
import { CplusDocumentTypeDisplayName } from '../../../jbi-shared/types/document.types';
import { generateDefaultDocDisplayId } from '@/jbi-shared/util/document.utils.ts';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';

@Component({})
export default class ActivityLogItem extends mixins(ScreenSizeMixin) {
  @Prop() public eventLog!: EventLog;

  public today = new Date();

  get formatTime() {
    dayjs.extend(relativeTime);
    return (
      dayjs().from(dayjs(this.eventLog.eventOccuredAt), true) + ' ' + 'ago'
    );
  }

  public formatDate(s: string) {
    return dayjs(s).format(DATE_FORMAT);
  }

  get EVENT_LOG_TYPE() {
    return EVENT_LOG_TYPE;
  }

  get getFullNameFromUser() {
    return getFullNameFromUser;
  }

  get generateDefaultDocDisplayId() {
    return generateDefaultDocDisplayId;
  }

  get CplusDocumentTypeDisplayName() {
    return CplusDocumentTypeDisplayName;
  }

  public get initials(): string {
    if (!this.eventLog.originator) {
      return 'N/A';
    }
    const fullName = getFullNameFromUser(this.eventLog.originator);
    return fullName
      .split(' ')
      .map((s) => s[0])
      .filter(Boolean)
      .map((s) => String(s).toUpperCase())
      .join('');
  }

  public get uniqueDocId(): string {
    if (!this.eventLog.eventPayload) {
      return 'N/A';
    }
    const documentId = this.eventLog.eventPayload.documentId;
    const type = this.eventLog.eventPayload.documentType;
    const docId = generateDefaultDocDisplayId({ documentId, type });
    return docId;
  }

  get projectTitle(): string {
    if (!this.eventLog) {
      return '';
    }
    return 'title';
  }
}
