import { useWebsocket } from '@/utils/websocket.util';
import { ExportDocumentWorkerJobMetadata } from '@/store/modules/documents/types/worker-job.types';
import { EVENTS } from '@/store/modules/websocket/websocket.state';
import { useAction } from './store.util';
import Vue from 'vue';
import { get as _get } from 'lodash';
import { Job } from 'bull';
import { WorkerJobStatus } from '../jbi-shared/types/cplus-worker.types';
import printJS from 'print-js';

const markWorkerJobAsCompleted = function(this: Vue, payload: Job) {
  return useAction.call(this, 'websocket/markWorkerJobAsCompleted')(payload);
};

const markWorkerJobAsErrored = function(this: Vue, payload: Job) {
  return useAction.call(this, 'websocket/markWorkerJobAsErrored')(payload);
};

const onSuccess = async function(this: Vue, job: Job) {
  if (job.returnvalue.status === WorkerJobStatus.PROCESSED) {
    markWorkerJobAsCompleted.call(this, job);

    const { storageUrl } = job.returnvalue
      .metadata as ExportDocumentWorkerJobMetadata;

    printJS({
      printable: storageUrl,
    });
  } else {
    markWorkerJobAsErrored.call(this, job);
  }
};

const onError = function(this: Vue, job: Job, error: Error) {
  markWorkerJobAsErrored.call(this, job);
  return;
};

export const handlePrintPdf = async function(this: Vue, job: Job) {
  const {
    connectToWs,
    disconnectWs,
    joinRoom,
    listenOnceTo,
  } = useWebsocket.call(this);

  const { id: jobId } = job;

  await connectToWs();

  joinRoom(jobId);

  try {
    job = (await listenOnceTo(EVENTS.EXPORT_PDF_EVENT)) as Job;

    await onSuccess.call(this, job);
  } catch (error) {
    onError.call(this, job, error);
  } finally {
    await disconnectWs();
  }
};
