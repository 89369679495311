var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.createProjectWrapper},[_c('div',{class:_vm.$style.labelWrapper},[_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Project Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Project Title","type":errors.length ? 'is-danger' : undefined,"message":errors.length
            ? errors[0]
            : "Your project title can be changed later in 'Project Settings'."}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.projectTitle),expression:"form.projectTitle"}],class:['textarea', _vm.$style.title],attrs:{"data-test":"project-title-input","maxlength":"200","rows":"2"},domProps:{"value":(_vm.form.projectTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "projectTitle", $event.target.value)}}})])]}}])}),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Document Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Document Type","data-test":"project-type-selector","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('BaseMultiSelect',{attrs:{"options":_vm.documentTypeOptions,"data-test":"project-type-selector","openDirection":"top","maxHeight":130},model:{value:(_vm.form.documentType),callback:function ($$v) {_vm.$set(_vm.form, "documentType", $$v)},expression:"form.documentType"}})],1)]}}])}),_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.getResearchNodesLoading,"can-cancel":true}}),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Primary Field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.getResearchNodesError)?_c('b-field',{attrs:{"label":"Primary Field","type":"is-danger","message":"Error. Please Try Again Later."}}):_c('b-field',{attrs:{"label":"Primary Field","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('BaseMultiSelect',{attrs:{"options":_vm.researchNodes,"data-test":"project-primary-node-selector","openDirection":"top","maxHeight":130},model:{value:(_vm.form.primaryResearchNode),callback:function ($$v) {_vm.$set(_vm.form, "primaryResearchNode", $$v)},expression:"form.primaryResearchNode"}})],1)]}}])}),(_vm.getResearchNodesError)?_c('b-field',{attrs:{"label":"Secondary Fields","type":"is-danger","message":"Error. Please Try Again Later."}}):_c('b-field',{attrs:{"label":"Secondary Fields"}},[_c('BaseMultiSelect',{attrs:{"options":_vm.secondaryResearchNodesOptions,"disabled":!_vm.form.primaryResearchNode,"data-test":"project-secondary-node-selector","openDirection":"top","maxHeight":220},model:{value:(_vm.form.researchNodes),callback:function ($$v) {_vm.$set(_vm.form, "researchNodes", $$v)},expression:"form.researchNodes"}})],1),(_vm.form.documentType && _vm.uploadeableDocumentType.includes(_vm.form.documentType.id))?_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"File Uploader"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
return [_c('b-field',{attrs:{"label":_vm.getSelectedFormDocType() === _vm.CplusDocumentType.EvidenceSummary ? 'Technical Development Report (TDR)' : 'File Uploader',"message":_vm.getSelectedFormDocType() === _vm.CplusDocumentType.EvidenceSummary ? "You can change technical development report file in 'Project Settings'." : ''}},[_c('GenericFileUploader',{attrs:{"acceptedFormats":_vm.getSelectedFormDocType() === _vm.CplusDocumentType.EvidenceSummary ? _vm.TdrFormats : undefined},on:{"uploaded":function ($event) {validate($event); _vm.getSelectedFormDocType() === _vm.CplusDocumentType.EvidenceSummary ? _vm.setUploadedTdrStorageUri($event.storageUri) : _vm.setUploadedUri($event.storageUri);}}})],1)]}}],null,false,3482817503)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }