


















































import { Component, Provide, Watch } from 'vue-property-decorator';
import EditorSideBar from '@/views/DocumentEditor/components/EditorSideBar.vue';
import EditorSectionsManagementTab from '@/views/DocumentEditor/components/tab/EditorSectionsManagementTab.vue';
import EditorCitationsManagementTab from '@/views/DocumentEditor/components/tab/EditorCitationsManagementTab.vue';
import ProjectEditorHeader from './components/ProjectEditorHeader.vue';
import { Action, State } from 'vuex-class';
import { isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';
import Op from 'quill-delta/dist/Op';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import {
  DirtyTagMap,
  DocumentDetail,
  DocumentExportType,
  ExportDocumentRequestPayload,
  UpdateDocumentRequestPayload,
  UpdateEvidenceSummaryRequestPayload,
  UpdateRecommendedPracticeRequestPayload,
  PublishDocAction
} from '@/store/modules/documents/types/documents.types';
import Editor from '@/components/editor/Editor.vue';
import ExportDocumentAsDocxDialog from '@/views/DocumentEditor/components/ExportDocumentAsDocxDialog.vue';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import {
  chromeCitationPlaceholder,
  cleanupCitationInOps
} from '@/utils/editor.util';
import { handleDocumentExporting } from '@/utils/export-docx.util';
import { PublishDocumentPayload } from '@/store/modules/projects/types/projects.types';
import { EditorViewMode } from '@/utils/viewMode.mixin';
import {
  isDifferent,
  isFiltered,
  isTruthy
} from '@/jbi-shared/util/watcher.vue-decorator';
import {
  CplusDocumentType,
  CplusDocumentAction,
  ParticipantRoles
} from '@/jbi-shared/types/document.types';
import { CollaboratorInfo } from '@/store/modules/projects/types/project-details.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { mixins } from 'vue-class-component';
import { InviteUserMixin, InviteUserValues } from './mixins/invite-user.mixin';
import { PermissionMixin } from '@/utils/permission.mixin';
import { generateRandomId, uniqTagMap } from '@/jbi-shared/util/document.utils';
import { Job } from 'bull';
import { EditorCommonMixin } from './mixins/editor-common.mixin';
import { RootState } from '@/store/store';
import { isChrome } from '@/utils/browser.util';
import {
  ValidateDocumentPayload,
  validateEsFields,
  validateRpFields
} from '@/utils/validate-document.util';
import ValidateDocumentErrorModal from './components/ValidateDocumentErrorModal.vue';
import DocumentPublishValidationModal from './components/DocumentPublishValidationModal.vue';
import { RevisionPublicationStatus } from '@/jbi-shared/types/document-status.types';
import DocumentPublishModal from './components/DocumentPublishModal.vue';
import {
  GetRevisionCitationsRequestPayload,
  RemoveRevisionCitationRequestPayload
} from '../../store/modules/documents/types/documents.types';
import { Citation } from '../../store/modules/documents/types/citations.types';
import { FullDocumentRevisionObject } from '../../jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {
    EditorSideBar,
    EditorSectionsManagementTab,
    EditorCitationsManagementTab,
    Editor,
    ProjectEditorHeader,
    DocumentPublishModal
  }
})
export default class DocumentEditor extends mixins(
  InviteUserMixin,
  PermissionMixin,
  EditorCommonMixin
) {
  public documentStatusNotification: string = 'Document Published';
  public isCitationEdit: boolean = false;

  @Provide() public viewMode = EditorViewMode.editing;

  @State((state: RootState) => state.projects.apiState.uploadCitation.error)
  public uploadCitationError!: boolean;

  @State((state: RootState) => state.documents.apiState.updateDocument.loading)
  public updateDocumentLoading!: boolean;

  @Action('documents/getDocumentDetail')
  public getDocumentDetail!: (id: number) => void;

  @Action('documents/updateDocument')
  public updateDocument!: (
    payload:
      | UpdateRecommendedPracticeRequestPayload
      | UpdateEvidenceSummaryRequestPayload
  ) => void;

  @Action('projects/getCitations')
  public getCitations!: (documentId: number) => void;

  @Action('documents/removeRevisionCitation')
  public removeRevisionCitation!: (
    payload: RemoveRevisionCitationRequestPayload
  ) => void;

  @Action('projects/deleteCitation')
  public deleteCitation!: ({
    projectId,
    citationId
  }: {
    projectId: number;
    citationId: number;
  }) => void;

  @Action('documents/deleteDocument')
  public deleteDocument!: (payload: DocumentDetail) => void;

  @Action('documents/exportDocument')
  public exportDocument!: (p: ExportDocumentRequestPayload) => Promise<Job>;

  @Action('documents/updatePublishStatus')
  public updatePublishStatus!: (payload: PublishDocumentPayload) => void;

  @Action('documents/getRevisionsByDocumentId')
  public getRevisionsByDocumentId!: (documentId: number) => void;

  @Action('documents/getRevisionCitations')
  public getRevisionCitations!: (
    payload: GetRevisionCitationsRequestPayload
  ) => Citation[];

  public selectedTab() {
    return this.$route.hash.substr(1) ? this.$route.hash.substr(1) : 'sections';
  }

  public revisionApiCalled = false;

  // TODO: Look for a better way to map tags to text section
  @Watch('uploadCitationError')
  @isTruthy
  public watchUploadCitationError() {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Citation import error, Try again later`
    });
  }

  public created() {
    // on document loaded, load citations
    this.getCitations(this.projectId);
    this.getRevisionsByDocumentId(this.documentId);
    this.resetEditorState();
  }

  public updated() {
    // the revisionId is updated once the document related API's are loaded
    // so we call this API once revisionId is updated
    if (!this.revisionApiCalled && this.revisionId) {
      this.revisionApiCalled = true;

      this.getRevisionCitations({
        documentId: this.documentId,
        revisionId: this.revisionId
      });
    }
  }

  @Watch('documentsApiState.updateDocument.success')
  @isDifferent
  @isTruthy
  public onUpdateDocumentSuccess() {
    this.publishing = false;
    Toast.open({
      message: `Document successfully updated.`,
      position: 'is-top',
      type: 'is-dark'
    });
    // if has new section
    // request getDocumentDetail so that we get the id for new doc
    if (this.hasNewSection) {
      this.getDocumentDetail(this.documentId);
    }

    // if has deleted section
    // remove it after updated
    this.dirtyTextSections = this.dirtyTextSections.filter((textSection) => {
      // skip non-deleted item
      if (!textSection.deleted) {
        return true;
      }

      // store is source of truth
      // if deleted item aren't in store , remove it
      return this.documentDetail!.revision.sections.textSections.some(
        (d) => d.documentSectionId === textSection.documentSectionId
      );
    });

    this.dirtyBprs = this.dirtyBprs.filter((subSection) => {
      if (!subSection.deleted) {
        return true;
      }

      // store is source of truth
      // if deleted item aren't in store , remove it
      return this.documentDetail!.revision.sections.bprSection?.some(
        (d) => d.documentSectionId === subSection.documentSectionId
      );
    });
  }

  @Watch('documentsApiState.updateDocStatus.success')
  @isDifferent
  @isTruthy
  public onDocumentStatusUpdate() {
    Toast.open({
      message: `Document Status Successfully Updated`,
      position: 'is-top',
      type: 'is-dark'
    });
  }

  @Watch('documentsApiState.updateDocStatus.error')
  @isDifferent
  @isTruthy
  public onDocumentStatusUpdateFailure() {
    Toast.open({
      message: `Failed to update Document Status`,
      position: 'is-top',
      type: 'is-danger'
    });
  }

  @Watch('documentsApiState.updateDocument.error')
  @isDifferent
  @isTruthy
  public onUpdateDocumentError() {
    this.publishing = false;
    Toast.open({
      message: `Failed to Update Document. Please try again!`,
      position: 'is-top',
      type: 'is-danger'
    });
  }

  @Watch('documentsApiState.removeRevisionCitation.success')
  @isDifferent
  @isTruthy
  public async onDeleteCitationSuccess() {
    if (!this.isCitationEdit) {
      Toast.open({
        message: `Citation Deleted Successfully`,
        position: 'is-top',
        type: 'is-dark'
      });
    }

    await this.getRevisionCitations({
      documentId: this.documentId,
      revisionId: this.revisionId
    });

    // delete deleted citation in section
    this.dirtyTextSections = this.dirtyTextSections.map((textSection) => {
      textSection.sectionValue = cleanupCitationInOps({
        ops: textSection.sectionValue!,
        availableCitations: this.citationsOfRevision
      });
      return { ...textSection };
    });
    this.dirtyBprs = this.dirtyBprs.map((subSection) => {
      subSection.content = cleanupCitationInOps({
        ops: subSection.content!,
        availableCitations: this.citationsOfRevision
      });
      return { ...subSection };
    });
  }

  @Watch('projectsApiState.deleteCitation.error')
  @isDifferent
  @isTruthy
  public onDeleteCitationError() {
    Toast.open({
      message: `Error! Please try again later!`,
      position: 'is-top',
      type: 'is-danger'
    });
  }

  @Watch('projectsApiState.createCitation.error')
  @isDifferent
  @isTruthy
  public onCreateCitationError() {
    Toast.open({
      position: 'is-top',
      type: 'is-danger',
      message: `Fail to Create Citation. Pls Try Again.`
    });
  }

  @Watch('projectsApiState.editCitation.success')
  @isDifferent
  @isTruthy
  public onEditCitationSuccess() {
    Toast.open({
      position: 'is-top',
      type: 'is-dark',
      message: `Citation Edited Successfully`
    });

    // this.getCitations(this.projectId);
    this.getRevisionCitations({
      documentId: this.documentId,
      revisionId: this.revisionId
    });
  }

  @Watch('projectsApiState.editCitation.error')
  @isDifferent
  @isTruthy
  public onEditCitationError() {
    Toast.open({
      position: 'is-top',
      type: 'is-danger',
      message: `Fail to Edit Citation. Pls Try Again.`
    });
  }

  // on new sub Doc created
  @Watch('projectsApiState.createSubDocument.success')
  @isDifferent
  @isTruthy
  @isFiltered<boolean>((v, prevV) => !prevV && v)
  public onCreateSubDocumentSuccess() {
    this.$router.push({
      name: 'editor',
      params: {
        documentId: this.createdSubDocument!.projectDocumentId.toString(),
        projectId: this.createdSubDocument!.projectId.toString()
      }
    });
  }

  @Watch('documentsApiState.updatePublishStatus.success')
  @isTruthy
  public updatePublishStatusSuccess() {
    // Get revisions for document after publishing
    this.getRevisionsByDocumentId(this.documentId);

    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      duration: 5000,
      message: `${this.documentStatusNotification}. </br> Note: Documents are being synced, There will be a slight delay in documents updates in Advanced Search.`
    });
  }

  @Action('projects/getProject')
  public getProject!: (projectId: number) => void;

  public validateBeforePublish(text: PublishDocAction) {
    const payload: ValidateDocumentPayload = {
      documentTitle: this.dirtyDocumentTitle,
      searchDate: this.dirtySearchDate,
      authors: this.dirtyAuthors,
      textSections: this.dirtyTextSections,
      subSections: this.dirtyBprs,
      criterions: this.dirtyCriterions
      // ohsAssetIds: this.usedOhsAssets,
      // relatedDocs: this.dirtyRelatedDocs,
    };

    const revisions =
      (this.$store.state as RootState).documents.revisionsByDocumentId
        ?.revisions || [];
    const publishedRevisions = revisions.filter(
      (revision) => revision.publicationId
    );

    // There are some existing ES docs in our system don't have TDR in the published revision
    const latestPublishedRevision =
      publishedRevisions[publishedRevisions.length - 1];
    const isTdrSame = latestPublishedRevision
      ? latestPublishedRevision.tdr?.storageUri === this.tdrUri
      : false;

    const { error } =
      this.documentType === CplusDocumentType.RecommendedPractice
        ? validateRpFields(payload)
        : validateEsFields(payload);

    if (
      !this.tdrUri &&
      this.documentType === CplusDocumentType.EvidenceSummary
    ) {
      this.$buefy.modal.open({
        parent: this,
        component: DocumentPublishValidationModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          modalTitle: 'Warning: Missing TDR'
        }
      });
    } else if ((!_isEmpty(error) || isTdrSame) && text === 'Publish') {
      this.$buefy.modal.open({
        parent: this,
        component: ValidateDocumentErrorModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          modalTitle: 'Warning: Incomplete Data',
          error,
          tdrError: isTdrSame
            ? 'Do not have any new TDR file in this version.'
            : '',
          action: text
        },
        events: {
          'publish-anyway': () => {
            this.publishDocument(text);
          }
        }
      });
    } else {
      this.publishDocument(text);
    }
  }

  public saveDocument(params?: { dirtyDocTagMaps: DirtyTagMap[] }) {
    const { dirtyDocTagMaps = this.originalDocumentTagMaps } = params || {};
    this.publishing = true;

    const tagMaps: DirtyTagMap[] = uniqTagMap([
      ...dirtyDocTagMaps,
      ...this.dirtyTagMaps
    ]).map((tagMap) => ({
      ...tagMap,
      revisionId: this.revisionId
    }));

    let revisionCitationIds: number[] = this.citationsOfRevision.map(
      (citation: Citation) => citation.id
    );

    // Add sortOrder to the textSections
    const textSections: FullDocumentRevisionObject['revision']['sections']['textSections'] = this.dirtyTextSections.map(
      (dirtyTextSection, index) => {
        return {
          ...dirtyTextSection,
          sortOrder: index
        };
      }
    );

    const payload: UpdateDocumentRequestPayload = {
      revision: {
        id: this.revisionId,
        searchDate: this.dirtySearchDate,
        tags: tagMaps,
        sections: {
          textSections: textSections,
          referenceSection: {
            documentSectionId: this.documentDetail?.revision?.sections
              ?.criterionSection?.[0]?.documentSectionId,
            citationIds: revisionCitationIds
          },
          ohsSection: {
            documentSectionId: this.documentDetail?.revision?.sections
              ?.ohsSection?.documentSectionId,
            ohsAssetIds: this.usedOhsAssets
          },
          bprSection: this.dirtyBprs,
          criterionSection: this.dirtyCriterions,
          relatedDocSubSections: this.dirtyRelatedDocs,
          authorSubSections: this.dirtyAuthors
        },
        documentSections: this.documentSections,
        tdrUri: this.tdrUri
      },
      document: {
        id: this.documentDetail!.document.id,
        title: this.dirtyDocumentTitle,
        documentType: this.documentType
      },
      project: {}
    };

    this.updateDocument(payload);
  }

  public publishDocument(text: PublishDocAction) {
    // If we restore a withdrawn document, we will create a new draft version with the current document content
    if (text === CplusDocumentAction.Restore) {
      const message: string =
        'This document is in End-of-life status (Withdrawn). \nAre you sure you want to restore this document? A new draft version of the document will be created upon restoration';
      return this.restoreWithdrawnDocument(text, message);
    }

    let message: string = `Publishing this document will make it available to external users and systems. Are you sure?`;
    let publishStatus: RevisionPublicationStatus =
      RevisionPublicationStatus.Published;
    let optionalMessage: string = '';
    let archivedNote: string;
    let withdrawnNote: string;

    switch (text) {
      case CplusDocumentAction.Archive:
        message = `This document will no longer be available for general users of the system once archived. Are you sure you want to archive this document?`;
        publishStatus = RevisionPublicationStatus.Archived;
        optionalMessage =
          'Optional: For better collaboration, add a note to clarify with other team members on why you archived this document';
        break;
      case CplusDocumentAction.UnArchive:
        message = `Unarchiving this document will make it available to external users and systems. Are you sure?`;
        publishStatus = RevisionPublicationStatus.Published;
        break;
      case CplusDocumentAction.Withdraw:
        message = `This document will no longer be available for general users of the system once withdrawn. Are you sure you want to withdraw this document?`;
        publishStatus = RevisionPublicationStatus.Withdrawn;
        optionalMessage =
          'Optional: For better collaboration, add a note to clarify with other team members on why you withdraw this document';
        break;
    }

    this.documentStatusNotification = `Document ${publishStatus}`;

    this.$buefy.modal.open({
      parent: this,
      component: DocumentPublishModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        headerTitle: `${text} This Document?`,
        state: text,
        message,
        optionalMessage
      },
      events: {
        'publish-anyway': async (data: { messageNote: string | undefined }) => {
          if (text === CplusDocumentAction.Archive && data.messageNote) {
            archivedNote = data.messageNote;
          } else if (
            text === CplusDocumentAction.Withdraw &&
            data.messageNote
          ) {
            withdrawnNote = data.messageNote;
          }

          await this.updatePublishStatus({
            documentId: this.documentDetail!.document.id,
            publishStatus,
            revisionId: this.revisionId,
            archivedNote,
            withdrawnNote
          });
          await this.getProject(this.projectId);
        }
      }
    });
  }

  public restoreWithdrawnDocument(text: string, message: string) {
    this.$buefy.modal.open({
      parent: this,
      component: DocumentPublishModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        headerTitle: `Warning: Restore Document?`,
        state: text,
        message
      },
      events: {
        'publish-anyway': async (data: {
          archivedNote: string | undefined;
          withdrawnNote: string | undefined;
        }) => {
          this.saveDocument();
        }
      }
    });
  }

  public updateSectionTitle(index: number, value: Op[]) {
    const unchanged =
      _isEqual(value, this.dirtyTextSections[index].sectionTitle) ||
      _isEqual(
        opsToText(value).trim(),
        opsToText(this.dirtyTextSections[index].sectionTitle!).trim()
      );
    if (unchanged) {
      return;
    }

    this.dirtyTextSections = this.dirtyTextSections.map((textSection, i) =>
      i === index ? { ...textSection, sectionTitle: value } : textSection
    );
  }

  public async updateSectionValue(index: number, value: Op[]) {
    const unchanged = _isEqual(
      value,
      this.dirtyTextSections[index].sectionValue
    );
    if (unchanged) {
      return;
    }

    this.dirtyTextSections = this.dirtyTextSections.map((textSection, i) =>
      i === index ? { ...textSection, sectionValue: value } : textSection
    );

    await this.$nextTick();
    this.updateCitationNumbers();
  }

  public handleInviteUser(values: InviteUserValues) {
    const { email, roleId } = values;
    this.inviteUser({
      email,
      roleId,
      projectId: this.projectId
    });
  }

  public handleResendUserInvitation(collaboratorInfo: CollaboratorInfo) {
    this.resendUserInvitation({
      projectId: this.projectId,
      userEmail: collaboratorInfo.collaborator.email,
      participantRole: ParticipantRoles.author
    });
  }

  public handleRemoveUserFromProject(collaboratorInfo: CollaboratorInfo) {
    this.removeUserFromProject({
      projectId: this.projectId,
      userId: collaboratorInfo.collaborator.id
    });
  }

  public addNewSection(): void {
    const tempId = generateRandomId();
    const newTextSection: TextSection = {
      sectionTitle: textToOps('New Section'),
      sectionValue: [{ insert: '\n' }],
      tempId,
      documentSectionId: 0,
      sortOrder: this.dirtyTextSections.length
    };
    this.dirtyTextSections.push(newTextSection);

    Toast.open({
      message: 'New section has been added',
      position: 'is-top',
      type: 'is-dark'
    });
  }

  public removeSection(textSection: TextSection) {
    this.dirtyTextSections = this.dirtyTextSections
      // completely remove section that's not yet created in the backend
      .filter((d) => !(d === textSection && d.tempId))
      // add deleted tag so that backend knows which to delete
      .map((d) => (d === textSection ? { ...d, deleted: true } : d));
  }

  public onDragStart(ev: DragEvent) {
    const citationId = String(
      (ev.target as Element).getAttribute('data-citation-id')
    );
    const citationAuthor = String(
      (ev.target as Element).getAttribute('data-author')
    );
    const citationTitle = String(
      (ev.target as Element).getAttribute('data-title')
    );
    if (!citationId) {
      ev.preventDefault();
      return;
    }

    // give it an empty string to quill to find index
    // ev.dataTransfer!.setData('text', `[${citationAuthor} ${citationYear}]`);
    if (isChrome) {
      ev.dataTransfer!.setData('text', chromeCitationPlaceholder);
    } else {
      ev.dataTransfer!.setData('text', '');
    }
    // @ts-ignore
    window.droppedCitationId = citationId;

    // disable ghost image when dragging, by setting the drag image to a transparent image
    ev.dataTransfer!.setDragImage(this.transparentImg, 0, 0);
  }

  public handleDeleteCitationClick(data: {
    citationId: number;
    isEdit: boolean;
  }) {
    if (!data.isEdit) {
      if (!confirm(`Are you sure that you want to remove this citation?`)) {
        return;
      }

      this.removeRevisionCitation({
        documentId: this.documentId,
        revisionId: this.revisionId,
        citationId: data.citationId
      });
    }
    this.isCitationEdit = data.isEdit;
    // this.deleteCitation({
    //   projectId: this.projectId,
    //   citationId,
    // });
  }

  public handleDeleteDocumentClick() {
    const deleteConfirmed = confirm(
      'Are you sure you want to delete this document?'
    );
    if (deleteConfirmed) {
      this.deleteDocument({
        ...this.documentDetail
      } as DocumentDetail);
    }
  }

  public async handleExport(exportType: DocumentExportType) {
    if (
      exportType === DocumentExportType.docx &&
      this.documentType === CplusDocumentType.EvidenceSummary
    ) {
      this.$buefy.modal.open({
        parent: this,
        component: ExportDocumentAsDocxDialog,
        hasModalCard: true,
        trapFocus: true,
        props: {
          title: this.documentTitle
        },
        events: {
          exportDocx: this.handleDocxExport
        }
      });
    } else {
      const workerJob = await this.exportDocument({
        documentId: this.documentId,
        revisionId: this.revisionId,
        exportType
      });
      handleDocumentExporting.call(
        this,
        workerJob,
        this.documentDetail!,
        exportType
      );
    }
  }

  public async handleDocxExport(value: { includeAuditCriteria: boolean }) {
    const { includeAuditCriteria } = value;
    const workerJob = await this.exportDocument({
      documentId: this.documentId,
      revisionId: this.revisionId,
      exportType: DocumentExportType.docx,
      includeAuditCriteria
    });
    handleDocumentExporting.call(
      this,
      workerJob,
      this.documentDetail!,
      DocumentExportType.docx
    );
  }

  public handleDiscard() {
    if (confirm(`All unsaved change will be discarded. Are you sure?`)) {
      this.resetEditorContent(this.documentDetail!);
      this.editorState.editorContainerKey = Math.random();
      return;
    }
  }
}
