




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DocumentPublishModal extends Vue {
  @Prop({ type: String, default: '' }) public headerTitle!: string;
  @Prop({ type: String, default: '' }) public state!: string;
  @Prop({ type: String, default: '' }) public message!: string;
  @Prop({ type: String, default: '' }) public optionalMessage!: string;

  public note: string = '';
}
