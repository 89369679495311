<template>
  <div>
    <b-dropdown aria-role="list" position="is-bottom-left">
      <b-button :class="['is-block']" outlined slot="trigger">
        <div :class="$style.exportBtn">
          <span>Export</span>
          <b-icon icon="chevron-down"></b-icon>
        </div>
      </b-button>

      <b-dropdown-item aria-role="listitem" @click="$emit('exportToPdf')">
        PDF
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="$emit('exportToXlsx')">
        XLSX
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ExportProjectDataButton extends Vue {}
</script>

<style lang="scss" module>
.container {
  width: fit-content;
}

.exportBtn {
  display: flex;
}
</style>
