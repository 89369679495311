





































































import Component from 'vue-class-component';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import { Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import CitationForm from '@/views/DocumentEditor/components/tab/citations/CitationForm.vue';
import { Citation } from '@/store/modules/documents/types/citations.types';
import { ValidationObserver } from 'vee-validate';
import CitationUploader from '@/views/DocumentEditor/components/tab/citations/CitationUploader.vue';
import { ApiState } from '@/store/types/general.types';
import { get as _get } from 'lodash';
import StagingCitationCard from '@/views/StagingArea/components/tab/StagingEditorCitationsTab_CitationCard.vue';
import CitationCard from '@/views/DocumentEditor/components/tab/EditorCitationsManagementTab_CitationCard.vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { StagingEditorComputedValuesMixin } from '../../mixins/staging-editor-computed-values.mixin';
import { PendingCitationData } from '@/jbi-shared/types/document.types';
import { StagingEditorStateMixin } from '../../mixins/staging-editor-state.mixin';
import { getCitationReferenceNumber } from '@/utils/citation-reference.util';

@Component({
  // @ts-ignore
  components: {
    BaseModal,
    CitationForm,
    ValidationObserver,
    CitationUploader,
    CitationCard,
    StagingCitationCard
  }
})
export default class StagingCitationArchived extends mixins(
  StagingEditorComputedValuesMixin,
  StagingEditorStateMixin
) {
  @Prop(String) public citationSearchValue!: string;
  @Prop(String) public activeCitation!: boolean;

  @State((state: RootState) => state.projects.apiState.createCitation.loading)
  public createCitationLoading!: boolean;

  @State((state: RootState) => state.projects.apiState.editCitation.loading)
  public editCitationLoading!: boolean;

  @State((state: RootState) => state.projects.apiState.uploadCitation)
  public uploadCitationApiState!: ApiState;

  @Prop(Boolean)
  public isExpandedMiddleBar!: boolean;

  get citations(): Citation[] {
    const citations: Citation[] = this.citationsOfRevision || [];
    return citations.map((citation) => {
      const transformedCitation = _cloneDeep(citation);
      transformedCitation.content.author = this.transformAuthorArrayToString(
        transformedCitation.content.author
      );
      return transformedCitation;
    });
  }

  get referencedCitationIds(): number[] {
    return this.uniqUsedCitationIds.map((id) => parseInt(id));
  }

  public expandCitationTab() {
    this.$emit('expandMiddleBar');
  }

  public citationReferenceNumber(
    citation: Citation | PendingCitationData
  ): string | undefined {
    const citationReferenceNumber = getCitationReferenceNumber(
      citation,
      this.dirtyReferences?.citationIds || []
    );
    if (!citationReferenceNumber) {
      return;
    }
    return citationReferenceNumber + '.';
  }

  get filteredCitations(): PendingCitationData[] {
    // Map Citation[] to PendingCitationData[]
    const filteredPreviousCitations: PendingCitationData[] = this.filteredPreviousCitations.map(
      (citation) => {
        return {
          id: citation.id.toString(),
          content: { ...citation.content }
        };
      }
    );
    return this.filteredDirtyCitations.concat(filteredPreviousCitations);
  }

  get filteredDirtyCitations(): PendingCitationData[] {
    if (this.archivedCitations) {
      const existingCitationIds: string[] = this.citations.map((citation) =>
        citation.id.toString()
      );
      if (this.citationSearchValue) {
        const data = this.archivedCitations;
        return data
          .filter((dirtyCitation) => {
            return !existingCitationIds.includes(dirtyCitation.id.toString());
          })
          .filter((citation) => {
            const citationText = citation.content.plainText.toLowerCase();
            return citationText.includes(
              this.citationSearchValue.toLowerCase()
            );
          });
      } else {
        return this.archivedCitations.filter((dirtyCitation) => {
          return !existingCitationIds.includes(dirtyCitation.id.toString());
        });
      }
    } else {
      return [];
    }
  }

  get filteredPreviousCitations(): PendingCitationData[] {
    const data: PendingCitationData[] = this.archivedCitations;
    return (
      data
        // filter based on search
        .filter((citation) => {
          if (citation.content.plainText) {
            const citationText = citation.content.plainText.toLowerCase();
            const author: string = _get(citation, 'content.author', '');
            const title: string = _get(citation, 'content.title', '');
            return (
              author
                .toLowerCase()
                .includes(this.citationSearchValue.toLowerCase()) ||
              title
                .toLowerCase()
                .includes(this.citationSearchValue.toLowerCase()) ||
              citationText.includes(this.citationSearchValue.toLowerCase())
            );
          } else {
            const author: string = _get(citation, 'content.author', '');
            const title: string = _get(citation, 'content.title', '');
            return (
              author
                .toLowerCase()
                .includes(this.citationSearchValue.toLowerCase()) ||
              title
                .toLowerCase()
                .includes(this.citationSearchValue.toLowerCase())
            );
          }
        })
        // format date
        .map((c) => ({
          ...c,
          issueDate: this.getYearFromDate(c.content.issueDate)
        }))
    );
  }

  public transformAuthorArrayToString(authors: any[]) {
    let authorString: string = '';
    if (Array.isArray(authors)) {
      for (const author of authors) {
        const authorValue: string =
          (author.given ? author.given + ' ' : '') +
          (author.family ? author.family : '');
        authorString =
          authorString !== ''
            ? authorString + ', ' + authorValue
            : authorString + authorValue;
      }
    }
    return authorString;
  }

  public getYearFromDate(date: string | number): string {
    if (String(date).length === 4) {
      return String(date);
    }
    return dayjs(date).format('YYYY');
  }

  public updateCitation(updatedCitation: PendingCitationData) {
    // Uploaded citations do not exist in database
    // Hence this citation data has to be updated at pending document data level

    // archivedCitations and dirtyCitations cannot be edited(store varibales)
    // We remove the existing citation and add the updated citation on edit
    this.archivedCitations = this.archivedCitations.filter((citation) => {
      return citation.id !== updatedCitation.id;
    });
    this.dirtyCitations = this.dirtyCitations.filter((citation) => {
      return citation.id !== updatedCitation.id;
    });
    this.archivedCitations.push(updatedCitation);
    this.dirtyCitations.push(updatedCitation);
  }

  get previousCitations(): PendingCitationData[] {
    const data: PendingCitationData[] = this.filteredPreviousCitations;
    const existingCitationIds: any[] = this.filteredDirtyCitations.map(
      (citation) => citation.id.toString()
    );
    return data.filter((dirtyCitation) => {
      return !existingCitationIds.includes(dirtyCitation.id);
    });
  }
}
