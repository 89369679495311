





















































































































































































































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';
import {
  ResearchNode,
  DocumentType
} from '@/store/modules/documents/types/documents.types';
import { isEqual as _isEqual } from 'lodash';
import { InputOption, StringInputOption } from '@/jbi-shared/types/form.types';
import { DocumentFormMixin } from '@/utils/documentForm.mixin';
import {
  CplusDocumentType,
  CplusDocumentTypeDisplayName,
  ParticipantRoles
} from '@/jbi-shared/types/document.types';
import GenericFileUploader, {
  UploadFileFormat
} from '@/components/form/GenericFileUploader.vue';
import { useProjectsState } from '../../utils/store.util';

export interface CreateProjectFormValues {
  projectTitle: string;
  title: string;
  primaryResearchNode: InputOption | null;
  researchNodes: InputOption[];
  documentType: StringInputOption | null;
  storageUri: string;
  tdrStorageUri: string;
}

const initialFormValue: CreateProjectFormValues = {
  projectTitle: '',
  title: '',
  primaryResearchNode: null,
  researchNodes: [],
  documentType: null,
  storageUri: '',
  tdrStorageUri: ''
};

@Component({
  components: {
    ValidationProvider,
    BaseMultiSelect,
    GenericFileUploader
  }
})
export default class CreateProjectForm extends mixins(DocumentFormMixin) {
  @State((state: RootState) => state.projects.activeResearchNodes)
  public researchNodes!: ResearchNode[];

  public form: CreateProjectFormValues = {
    ...initialFormValue
  };

  public TdrFormats: UploadFileFormat[] = [
    {
      fileExtension: '.docx',
      contentType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
  ];

  public handleSubmit() {
    this.$emit('submit', this.form);
  }

  public setUploadedUri(uri: string) {
    this.form.storageUri = uri;
  }

  public setUploadedTdrStorageUri(uri: string) {
    this.form.tdrStorageUri = uri;
  }

  public getSelectedFormDocType() {
    return this.form.documentType?.id;
  }

  get uploadeableDocumentType() {
    return [
      CplusDocumentType.EvidenceSummary,
      CplusDocumentType.BestPracticeInformationSheet,
      CplusDocumentType.SystematicReview,
      CplusDocumentType.SystematicReviewProtocol
    ];
  }

  get documentTypeOptions(): StringInputOption[] {
    return Object.values(CplusDocumentType)
      .filter((type) =>
        [
          CplusDocumentType.EvidenceSummary,
          CplusDocumentType.BestPracticeInformationSheet,
          CplusDocumentType.SystematicReview,
          CplusDocumentType.SystematicReviewProtocol
        ].includes(type)
      )
      .map((type) => ({
        name: CplusDocumentTypeDisplayName[type],
        id: type
      }));
  }

  get secondaryResearchNodesOptions(): ResearchNode[] {
    if (!this.form.primaryResearchNode) {
      return [];
    }
    return this.researchNodes.filter(
      (node: ResearchNode) => node.id !== this.form.primaryResearchNode!.id
    );
  }

  get CplusDocumentType() {
    return CplusDocumentType;
  }

  get getResearchNodesLoading() {
    return useProjectsState.call(this).apiState.getActiveResearchNodes.loading;
  }

  get getResearchNodesError() {
    return useProjectsState.call(this).apiState.getActiveResearchNodes.error;
  }

  get inviteRoleOptions(): InputOption[] {
    const potentialParticipantList: InputOption[] = Object.values(
      ParticipantRoles
    ).map((d, i) => ({ id: i, name: d } as InputOption));
    return potentialParticipantList.slice(1, 3);
  }
}
