import Quill from 'quill';
import { QuillCitationInsertionPayload } from '@/store/modules/documents/types/quill.types';

const Embed = Quill.import('blots/embed');
export class QuillCitation extends Embed {
  public static create({ label, citationId }: QuillCitationInsertionPayload) {
    const node: Element = super.create(label);
    node.textContent = `${label}`;
    node.setAttribute('data-citation-id', String(citationId));
    node.setAttribute('data-label', label);
    node.setAttribute('contenteditable', 'false');
    return node;
  }

  public static value(domNode: Element) {
    const { citationId, label } = (domNode as any).dataset;
    return { citationId, label };
  }
}
QuillCitation.blotName = 'citation';
QuillCitation.className = 'quill-citation';
QuillCitation.tagName = 'span';
