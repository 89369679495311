






















































































































































import { opsToText } from '@/utils/quill-delta.util';
import Op from 'quill-delta/dist/Op';
import Component, { mixins } from 'vue-class-component';
import draggable from 'vuedraggable';
import { StagingEditorCommonMixin } from '../../mixins/staging-editor-common.mixin';
import StagingEditorSectionCard from './StagingEditorSectionCard.vue';
import { getArchivedPublications, Publication } from '@/utils/publication.util';
import { DocumentData } from '../../../../jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {
    draggable,
    StagingEditorSectionCard
  }
})
export default class StagingEditorSectionsTab extends mixins(
  StagingEditorCommonMixin
) {
  public drag = false;
  public sectionSearchValue = '';

  get isSearching(): boolean {
    return !!this.sectionSearchValue;
  }

  get searchResultIsEmpty(): boolean {
    return (
      this.isSearching &&
      (!this.filteredSections || !this.filteredSections.length)
    );
  }

  get filteredSections() {
    return (
      this.dirtyTextSections
        // filter based on search
        .filter((sections) => {
          return opsToText(sections.content.sectionTitle as Op[])
            .toLowerCase()
            .includes(this.sectionSearchValue.toLowerCase());
        })
    );
  }

  public dragOptions = {
    animation: 200,
    group: 'description',
    disabled: false,
    ghostClass: 'draggable__ghost',
    chosenClass: 'draggable__chosen',
    dragClass: 'draggable__drag',
    forceFallback: true
  };

  public updateSectionSortOrder() {
    this.dirtyTextSections = this.dirtyTextSections.map((section, index) => {
      return {
        ...section,
        sortOrder: index
      };
    });
  }

  get archivedPublications(): Publication[] {
    if (!this.existingDocumentDetail) {
      return [];
    }

    return getArchivedPublications(this.existingDocumentDetail!.document);
  }

  get relatedRPDocs(): DocumentData[] {
    return this.existingDocumentDetail?.document.relatedRPDocumentData
      ? this.existingDocumentDetail?.document.relatedRPDocumentData
      : [];
  }

  public hasError(sectionId: string) {
    return Boolean(
      this.documentErrors.errors.find((error) => error.sectionId === sectionId)
    );
  }
}
