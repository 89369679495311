































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import SectionValueEditor from '@/components/editor/SectionEditor/SectionValueEditor.vue';
import DateSectionEditor from '@/components/editor/SectionEditor/DateSectionEditor.vue';
import CriterionSectionEditor from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionSectionEditor.vue';
import SectionTitleEditor from '@/components/editor/SectionEditor/SectionTitleEditor.vue';
import RelatedDocsSectionEditor from '@/components/editor/SectionEditor/RelatedDocsSectionEditor/RelatedDocsSectionEditor.vue';
import RelatedRPDocsSectionEditor from '@/components/editor/SectionEditor/RelatedRPDocsSectionEditor/RelatedRPDocsSectionEditor.vue';
import PublicationSection from '@/components/editor/SectionEditor/PublicationSection/PublicationSection.vue';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import { textToOps } from '@/utils/quill-delta.util';
import { useAction } from '@/utils/store.util';
import { EditorViewMode } from '@/utils/viewMode.mixin';
import {
  CplusDocumentType,
  CplusDocumentTypeDisplayName,
  CplusDocumentTypeShorterDisplayName
} from '@/jbi-shared/types/document.types';
import {
  formatAuthors,
  formatAuthorsForCitedDisplay,
  getSourceEvidenceSummaryFromDocument,
  getRelatedDocFromSourceEvidenceSummary,
  getSourceEvidenceSummaryIdFromDocument
} from '@/jbi-shared/util/document.utils';
import { DocumentDetail } from '@/store/modules/documents/types/documents.types';
import dayjs from 'dayjs';
import {
  DateOrString,
  FullDocumentRevisionObject
} from '@/jbi-shared/types/full-document-revision-object.types';
import { Publication } from '@/utils/publication.util';
import BprSectionEditor from '@/components/editor/SectionEditor/BprSectionEditor/BprSectionEditor.vue';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '../../DocumentEditor/mixins/editor-common.mixin';
import { DocumentData } from '../../../jbi-shared/types/full-document-revision-object.types';

@Component({
  components: {
    SectionValueEditor,
    SectionTitleEditor,
    RelatedDocsSectionEditor,
    PublicationSection,
    BprSectionEditor,
    DateSectionEditor,
    CriterionSectionEditor,
    RelatedRPDocsSectionEditor
  }
})
export default class PreviewDocument extends mixins(EditorCommonMixin) {
  @Prop({ required: true })
  public textSections!: FullDocumentRevisionObject['revision']['sections']['textSections'];

  @Prop({ type: Boolean, default: false })
  public readOnly!: boolean;

  @Prop({ type: Array, required: true })
  public relatedDocs!: FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'];

  @Prop({ type: Array, required: true })
  public archivedPublications!: Publication[];

  public getKeyFromSection(section: TextSection) {
    // generate unique string for each text section
    return `${section.documentSectionId} ${section.tempId} ${
      section?.documentSectionId
    } ${JSON.stringify(section.sectionTitle)}`;
  }

  get relatedRPDocs(): DocumentData[] {
    if (this.documentDetail) {
      return this.documentDetail.document.relatedRPDocumentData || [];
    } else {
      return [];
    }
  }

  get getOhs(): () => Promise<any> {
    return useAction.call(this, 'documents/getOhsAssets');
  }

  get bprSectionTitle() {
    return textToOps('Best Practice Recommendations');
  }

  get subSections() {
    return this.documentDetail?.revision?.sections?.bprSection || [];
  }

  get documentPublishedDate() {
    if (this.documentDetail && this.documentDetail.revision.publishedAt) {
      return dayjs(this.documentDetail.revision.publishedAt).format(
        `DD/MM/YYYY`
      );
    }
    return '';
  }

  get documentPublishedYear() {
    if (!this.documentDetail) {
      return '';
    }
    return (
      this.getYearFromDate(this.documentDetail!.revision.publishedAt) ||
      this.getYearFromDate(this.documentDetail!.revision.createdAt)
    );
  }

  get formattedAuthorForCitation() {
    if (this.documentType !== CplusDocumentType.EvidenceSummary) {
      return 'JBI';
    }
    const authors =
      this.documentDetail?.revision?.sections?.authorSubSections?.map(
        (d) => d.content
      ) || [];
    return formatAuthorsForCitedDisplay(authors);
  }

  get versionId(): number {
    return +this.$route.params.versionId;
  }

  get documentId(): number {
    return +this.$route.params.documentId;
  }

  get revisionId(): number {
    return +this.$route.params.revisionId;
  }

  get selectedOhsAssets() {
    if (this.ohsAssets) {
      return this.ohsAssets.filter((value) => {
        if (value.id) {
          return this.dirtyOhs.includes(value.id);
        }
      });
    }
  }

  get sourceEvidenceSummaryId() {
    return getSourceEvidenceSummaryIdFromDocument(this.documentDetail);
  }

  get sourceEvidenceSummaryLink():
    | FullDocumentRevisionObject['revision']['sections']['relatedDocSubSections'][0]
    | undefined {
    if (!this.sourceEvidenceSummaryId) {
      return;
    }
    const es = getSourceEvidenceSummaryFromDocument(this.documentDetail);
    if (!es) {
      return;
    }
    return getRelatedDocFromSourceEvidenceSummary(es);
  }

  get EditorViewMode() {
    return EditorViewMode;
  }

  get textToOps() {
    return textToOps;
  }

  public getDocTypeDisplayNameFromDoc(doc: DocumentDetail) {
    return CplusDocumentTypeDisplayName[doc.document.documentType];
  }

  public getDocTypeShorterDisplayNameFromDoc(doc: DocumentDetail) {
    return CplusDocumentTypeShorterDisplayName[doc.document.documentType];
  }

  public getYearFromDate(date: DateOrString | undefined | null) {
    return date ? new Date(date).getFullYear() : date;
  }

  get formattedAuthors(): string[] {
    return formatAuthors(
      this.documentDetail?.revision?.sections?.authorSubSections?.map(
        (d) => d.content
      ) || []
    );
  }

  get criterionSectionTitle() {
    return textToOps('Audit Criteria');
  }

  public async mounted() {
    await this.getOhs();
  }
}
