









import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class Container extends Vue {}
