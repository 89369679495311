import { Component, Watch, Vue } from 'vue-property-decorator';
import {
  textSectionHasChanged,
  hasBprContentChanged
} from '../../../utils/quill-delta.util';
import {
  isEqual as _isEqual,
  orderBy as _orderBy,
  uniq as _uniq,
  omit as _omit,
  cloneDeep as _cloneDeep,
  fromPairs,
  toPairs
} from 'lodash';
import { TagMap } from '../../../jbi-shared/types/document.types';
import { Throttle } from '../../../jbi-shared/util/throttle.vue-decorator';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { RootState } from '../../../store/store';
import { mixins } from 'vue-class-component';
import { tagMapToUniqueKey } from '@/jbi-shared/util/document.utils';
import { Criterion } from '../../../jbi-shared/types/criterions.types';
import { EditorStateMixin } from './editor-state.mixin';
import { EditorComputedValuesMixin } from './editor-computed-values.mixin';

const state = Vue.observable({
  textSectionHasChanged: false,
  subSectionsHasChanged: false,
  docTitleHasChanged: false,
  searchDateHasChanged: false,
  criterionHasChanged: false,
  relatedDocsHasChanged: false,
  tagsHasChanged: false,
  authorsHasChanged: false,
  ohsHasChanged: false,
  tdrHasChanged: false
});

@Component({})
export class EditorChangeDetectorMixin extends mixins(
  EditorStateMixin,
  EditorComputedValuesMixin
) {
  get contentHasChanged(): boolean {
    // Log Changed state for debugging
    // tslint:disable
    // console.log(
    //   fromPairs(toPairs(state).filter(([key, value]) => value)),
    //   'contentHasChanged',
    // );

    return (
      state.textSectionHasChanged ||
      state.subSectionsHasChanged ||
      state.docTitleHasChanged ||
      state.searchDateHasChanged ||
      state.criterionHasChanged ||
      state.relatedDocsHasChanged ||
      state.tagsHasChanged ||
      state.authorsHasChanged ||
      state.ohsHasChanged ||
      state.tdrHasChanged
    );
  }

  @isDifferent
  @isTruthy
  @Watch('tdrUri')
  @Watch('documentDetail')
  @Throttle(300)
  public onTdrChange() {
    state.tdrHasChanged = this.tdrUri !== this.documentDetail?.revision?.tdrUri;
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyTextSections')
  @Throttle(300)
  public onTsChanged() {
    const newTsArr = _cloneDeep(this.dirtyTextSections);
    const oldTsArr = this.documentDetail?.revision?.sections?.textSections
      ? _cloneDeep(this.documentDetail?.revision?.sections?.textSections)
      : [];
    // console.log(JSON.stringify({ newTsArr, oldTsArr }));

    /*
      We need to trim textSection value before compare because the text value return
      from the Quill editor has character "\n" at the end of line
      - https://quilljs.com/docs/api/#setcontents
      - https://quilljs.com/docs/api/#getcontents

      The reason we need to do this way is because the initial value
      of Question section (What is the best available evidence regarding…) when create a document doesn't have "\n" at the end of text (existing data)
      You can check this initial data in sql function create_project_with_document
      So when we click to the editor of Question section, our system detects change because the returned value has "\n"
    */
    oldTsArr.map((section) => {
      if (
        section.sectionValue.length === 1 &&
        typeof section.sectionValue[0].insert === 'string'
      ) {
        section.sectionValue[0].insert = section.sectionValue[0].insert.trim();
      }
    });

    newTsArr.map((section) => {
      if (
        section.sectionValue.length === 1 &&
        typeof section.sectionValue[0].insert === 'string'
      ) {
        section.sectionValue[0].insert = section.sectionValue[0].insert.trim();
      }
    });

    state.textSectionHasChanged = textSectionHasChanged([newTsArr, oldTsArr]);
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyBprs')
  @Throttle(300)
  public onSsChanged() {
    const newSsArr = this.dirtyBprs;
    const oldSsArr = this.documentDetail?.revision?.sections?.bprSection || [];
    state.subSectionsHasChanged = hasBprContentChanged([newSsArr, oldSsArr]);
  }

  @isDifferent
  @Watch('dirtyDocumentTitle')
  @Watch('documentDetail')
  @Throttle(300)
  public onDocTitleChanged() {
    const newT = String(this.dirtyDocumentTitle).trim();
    const oldT = this.documentDetail?.document?.title?.trim();
    state.docTitleHasChanged = newT !== oldT;
  }

  @isDifferent
  @Watch('dirtySearchDate')
  @Watch('documentDetail')
  @Throttle(300)
  public onSDateChanged() {
    const newD = this.dirtySearchDate;
    const oldD = this.documentDetail?.revision?.searchDate;
    state.searchDateHasChanged = newD !== oldD;
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyCriterions')
  @Throttle(300)
  public onCChanged() {
    const a = this.dirtyCriterions;
    let b = this.documentDetail?.revision?.sections?.criterionSection || [];
    // b = b.map((c: Criterion) => _omit(c, 'tempId', 'revisionId'));
    state.criterionHasChanged =
      a.length !== b.length ||
      !_isEqual(JSON.parse(JSON.stringify(a)), JSON.parse(JSON.stringify(b)));
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyRelatedDocs')
  @Throttle(300)
  public onRDChanged() {
    const a = this.dirtyRelatedDocs;
    const b =
      this.documentDetail?.revision?.sections?.relatedDocSubSections || [];

    state.relatedDocsHasChanged =
      a.length !== b.length ||
      !_isEqual(JSON.parse(JSON.stringify(a)), JSON.parse(JSON.stringify(b)));
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyOhs')
  @Throttle(300)
  public onOhsChanged() {
    const currentOhsSelected = this.dirtyOhs;
    const previousOhsSelected =
      this.documentDetail?.revision?.sections?.ohsSection?.ohsAssetIds || [];

    state.ohsHasChanged =
      currentOhsSelected.length !== previousOhsSelected.length ||
      !_isEqual(
        JSON.parse(JSON.stringify(currentOhsSelected)),
        JSON.parse(JSON.stringify(previousOhsSelected))
      );
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyTagMaps')
  @Throttle(300)
  public onTMChanged() {
    let a = this.dirtyTagMaps.map((tm) => tagMapToUniqueKey(tm)).sort();
    a = _uniq(a);
    let b = ((this.documentDetail?.revision?.tags || []) as TagMap[])
      .map((tm) => tagMapToUniqueKey(tm))
      .sort();
    b = _uniq(b);
    state.tagsHasChanged = JSON.stringify(a) !== JSON.stringify(b);
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyAuthors')
  @Throttle(300)
  public onAChanged() {
    const a = this.dirtyAuthors;
    const b = this.documentDetail?.revision?.sections?.authorSubSections || [];
    state.authorsHasChanged =
      a.length !== b.length ||
      !_isEqual(JSON.parse(JSON.stringify(a)), JSON.parse(JSON.stringify(b)));
  }
}
