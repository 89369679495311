import { DateOrString } from '../jbi-shared/types/full-document-revision-object.types';

interface ObjWithCreatedAt {
  createdAt: DateOrString;
  [s: string]: any;
}
export const byCreatedAt: (
  a: ObjWithCreatedAt,
  b: ObjWithCreatedAt,
) => number = (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt);
