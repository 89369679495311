


















































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import { StringInputOption, InputOption } from '@/jbi-shared/types/form.types';
import { cloneDeep } from 'lodash';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { set as _set } from 'lodash';
import { uniq as _uniq, isEqual as _isEqual, pick as _pick } from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { opsToText, textToOps } from '../../../../utils/quill-delta.util';
import { mixins } from 'vue-class-component';
import { generateRandomId } from '../../../../jbi-shared/util/document.utils';
import { TagEntityTypeEnum } from '../../../../jbi-shared/types/document.types';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { EditorCommonMixin } from '../../../../views/DocumentEditor/mixins/editor-common.mixin';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import cuid from 'cuid';
import SectionEditorToolbar, {
  QuillToolbarFormat
} from '@/components/editor/SectionEditor/SectionEditorToolbar.vue';
import SectionEditorHeader from '@/components/editor/SectionEditor/SectionEditorHeader.vue';
import Quill from 'quill';
import Delta from 'quill-delta';
import { QuillContainerElement } from '@/store/modules/documents/types/quill.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseMultiSelect,
    TagsEditor,
    SectionEditorToolbar,
    SectionEditorHeader
  }
})
export default class SubSectionForm extends mixins() {
  @Prop(String) public modalTitle!: string;
  @Prop({
    default() {
      return {
        tempId: generateRandomId(),
        content: textToOps('')
      };
    }
  })
  public subSection!: FullDocumentRevisionObject['revision']['sections']['bprSection'][0];
  @Prop()
  public subSections!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public bprSectionId!: number;

  public dirtySubSection = cloneDeep({
    ...this.subSection
  });
  public bprFormDirtyTagMaps = cloneDeep(this.dirtyTagMaps) || [];

  public bprToolbarId = cuid();

  public bprEditorOptions = {
    modules: {
      toolbar: `#${this.bprToolbarId}`
    },
    placeholder: ''
  };

  public editorInstance(): Quill | undefined {
    if (!this.$refs.bprEditor) {
      return;
    }
    return (this.$refs.bprEditor as QuillContainerElement).quill;
  }

  get quillToolbarFormat() {
    return QuillToolbarFormat;
  }

  get dirtyContent() {
    return opsToText(this.dirtySubSection.content || []);
  }

  set dirtyContent(v: string) {
    this.dirtySubSection.content = textToOps(v);
    this.dirtySubSection.documentSectionId = this.bprSectionId;
  }

  public reset() {
    this.dirtySubSection = cloneDeep(this.subSection);
  }

  public async addSubSection() {
    this.dirtySubSection.content = this.editorInstance()!.getContents().ops;
    const dirtySubSection = this.dirtySubSection;
    const updatedBprs = [...this.subSections, dirtySubSection];
    const updatedDirtyTagMaps = this.bprFormDirtyTagMaps;
    this.$emit('update:bprs', updatedBprs);
    this.$emit('update:dirtyTagMaps', updatedDirtyTagMaps);
    this.$emit('close');
  }

  public async editSubSection() {
    this.dirtySubSection.content = this.editorInstance()!.getContents().ops;
    const dirtySubSection = this.dirtySubSection;
    /* Searches the subSections array for the current subSection element
     * and replaces it with the updated data. */
    const updatedBprs = this.subSections.map((dc) =>
      (dc.bprSubSectionId &&
        dc.bprSubSectionId === dirtySubSection.bprSubSectionId) ||
      (dc.tempId && dc.tempId === dirtySubSection.tempId)
        ? dirtySubSection
        : dc
    );
    const updatedDirtyTagMaps = this.bprFormDirtyTagMaps;
    this.$emit('update:bprs', updatedBprs);
    this.$emit('update:dirtyTagMaps', updatedDirtyTagMaps);
    this.$emit('close');
  }

  get isNew() {
    return this.subSections.every((dc) => !_isEqual(dc, this.subSection));
  }

  get TagEntityTypeEnum() {
    return TagEntityTypeEnum;
  }

  @Watch('dirtyTagMaps', { immediate: true })
  public onDirtyTagMaps() {
    this.bprFormDirtyTagMaps = cloneDeep(this.dirtyTagMaps);
  }

  public mounted() {
    this.editorInstance()!.setContents(new Delta(this.dirtySubSection.content));
  }
}
