var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"label":"Tags"}},[_c('TagsEditor',{attrs:{"entityId":_vm.subSection.bprSubSectionId || _vm.subSection.tempId,"entityType":_vm.TagEntityTypeEnum.bpr_section},model:{value:(_vm.bprFormDirtyTagMaps),callback:function ($$v) {_vm.bprFormDirtyTagMaps=$$v},expression:"bprFormDirtyTagMaps"}})],1),_c('SectionEditorHeader',{scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('SectionEditorToolbar',{ref:"bprEditorToolbar",attrs:{"formats":[
              _vm.quillToolbarFormat.superscript,
              _vm.quillToolbarFormat.subscript
            ],"toolbarElemId":_vm.bprToolbarId}})]},proxy:true}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Best Practice Recommendation"}},[_c('div',{class:_vm.$style.quillEditorWrapper},[_c('quill-editor',{ref:"bprEditor",class:[_vm.$style.quillEditorWrapper],attrs:{"options":_vm.bprEditorOptions},on:{"dragover":function($event){$event.preventDefault();}},model:{value:(_vm.dirtyContent),callback:function ($$v) {_vm.dirtyContent=$$v},expression:"dirtyContent"}})],1)])],1),_c('footer',{staticClass:"modal-card-foot"},[(_vm.isNew)?_c('button',{staticClass:"button is-primary",attrs:{"disabled":!validator.valid},on:{"click":function($event){validator.validate().then(function (validated) {
            if (validated === false) { return; }
            _vm.addSubSection();
          })}}},[_vm._v(" Add Best Practice Recommendation ")]):_c('button',{staticClass:"button is-primary",attrs:{"disabled":validator.invalid},on:{"click":function($event){validator.validate().then(function (validated) {
            if (validated === false) { return; }
            _vm.editSubSection();
          })}}},[_vm._v(" Edit Best Practice Recommendation ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }