






import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
  useAction,
  useProjectsState,
  useGetter,
  useAuthState
} from '@/utils/store.util';
import { AcceptInvitationRequestPayload } from '@/store/modules/projects/types/projects.types';
import { redirectToJaasLogin } from '@/utils/login.util';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import retry from 'async-retry';
import { AxiosError } from 'axios';
import { get as _get } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';

@Component({})
export default class AcceptProjectInvitationToken extends Vue {
  get acceptInvitation(): (payload: AcceptInvitationRequestPayload) => void {
    return useAction.call(this, 'projects/acceptInvitation');
  }

  get acceptInvitationSuccess() {
    return useProjectsState.call(this).apiState.acceptInvitation.success;
  }

  get acceptInvitationError() {
    return useProjectsState.call(this).apiState.acceptInvitation.error;
  }

  get acceptedInvitedProject() {
    return useProjectsState.call(this).acceptedInvitedProject;
  }

  get isLoggedIn(): boolean {
    return useAuthState.call(this).isLoggedIn;
  }

  get invitationToken(): string {
    return this.$route.params.invitationToken;
  }

  public async mounted() {
    try {
      await retry(
        async (bail) => {
          // if anything throws, we retry
          await this.handleAcceptInvitationFlow();
        },
        {
          retries: 5,
          factor: 0.5
        }
      );
    } catch (error) {
      redirectToJaasLogin({ destination: this.$route.fullPath });
    }
  }

  public async handleAcceptInvitationFlow() {
    if (this.isLoggedIn) {
      this.acceptInvitation({ token: this.invitationToken });
    } else {
      throw new Error();
    }
  }

  @Watch('acceptInvitationSuccess')
  @isDifferent
  @isTruthy
  public onSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Project Invitation Accepted Successfully`
    });

    if (this.acceptedInvitedProject) {
      this.$router.push({
        name: 'editor',
        params: {
          documentId: String(
            this.acceptedInvitedProject.projectDocuments[0].id
          ),
          projectId: String(this.acceptedInvitedProject.id)
        }
      });
    } else {
      this.$router.push('/');
    }
  }

  @Watch('acceptInvitationError')
  @isDifferent
  @isTruthy
  public onError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: `Error Accepting Project Invitation. Error: ${_get(
        error.response,
        'data.message'
      )}`
    });
    this.$router.push('/');
  }
}
