









































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { RootState } from '../../../store/store';
import { SingleUserSearchResult } from '../../../store/modules/users/types/users.types';
import { Debounce } from '../../../jbi-shared/util/debounce.vue-decorator';
import { get as _get, result as _result } from 'lodash';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { mixins } from 'vue-class-component';
import { EditorCommonMixin } from '../mixins/editor-common.mixin';
import { getFullNameFromUser } from '../../../jbi-shared/util/user.utils';
import {
  UpdateDocAssigneeRequestPayload,
  UpdateDocAssigneeResponsePayload
} from '../../../store/modules/documents/types/documents.types';
import { ToastProgrammatic as Toast } from 'buefy';
import { pluckNewValue } from '../../../jbi-shared/util/pluck.rx-operator';
import { combineLatest, Observable, merge } from 'rxjs';
import {
  filter,
  tap,
  map,
  mergeMap,
  take,
  distinctUntilChanged,
  startWith
} from 'rxjs/operators';
import delay from 'delay';
import { BackendUserEntity } from '@/jbi-shared/types/search.types';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { distinctUntilDeepChanged } from '@/jbi-shared/util/distinctUntilDeepChanged.rx-operator';

type User = Pick<SingleUserSearchResult, 'id' | 'fullName' | 'email'>;

@Component<AssigneeInput>({
  subscriptions() {
    return {
      myDomKey: merge(
        this.$reactiveValueObservable('selectedUser').pipe(
          distinctUntilDeepChanged()
        )
      ).pipe(
        map(() => Math.random()),
        startWith(Math.random())
      )
    };
  }
})
export default class AssigneeInput extends mixins(EditorCommonMixin) {
  public searchText = '';
  public selectedUser: User | null = null;
  public inputFieldValue: string = '';
  public isFocused = false;
  public myDomKey!: number;

  @Action('users/searchUsersByText')
  public searchUsersByText!: (text: string) => SingleUserSearchResult[];

  @Action('documents/updateDocAssignee')
  public updateDocAssignee!: (
    payload: UpdateDocAssigneeRequestPayload
  ) => Promise<UpdateDocAssigneeResponsePayload>;

  @Action('auth/getMe')
  public getMe!: () => void;

  get users() {
    const users =
      (this.$store.state as RootState).users.searchUsersByTextResults || [];
    return users.filter((u) => u.id !== this.myUserId);
  }

  get searchUsersByTextLoading() {
    return (this.$store.state as RootState).users.apiState.searchUsersByText
      .loading;
  }
  get updateDocAssigneeSuccess() {
    return (this.$store.state as RootState).documents.apiState.updateDocAssignee
      .success;
  }
  get updateDocAssigneeError() {
    return (this.$store.state as RootState).documents.apiState.updateDocAssignee
      .error;
  }

  get showUnassigned() {
    return !this.isFocused && !this.selectedUser;
  }

  get myInfo() {
    return (this.$store.state as RootState).auth.myInfo;
  }
  get myUserId(): number {
    return _get(this.myInfo, 'id') || 0;
  }
  get myEmail(): string {
    return _get(this.myInfo, 'email') || '';
  }
  get myFullname(): string {
    if (!this.myInfo) {
      return '';
    }
    return getFullNameFromUser(this.myInfo);
  }

  get iAmAssigned(): boolean {
    return this.myUserId === _get(this.selectedUser, 'id');
  }

  public assignToMe() {
    this.selectedUser = {
      id: this.myUserId,
      email: this.myEmail,
      fullName: this.myFullname
    };
  }

  @isDifferent
  @Watch('searchText', { immediate: true })
  @Debounce(500)
  public handleSearch(text: string) {
    this.searchUsersByText(text);
  }

  @Watch('isFocused')
  public async watchIsFocused(value: boolean) {
    const editor = document.getElementById('editor-container');
    if (value) {
      // @ts-ignore
      editor.style['pointer-events'] = 'none';
    } else {
      await delay(500);
      // @ts-ignore
      editor.style['pointer-events'] = 'auto';
    }
  }

  public async mounted() {
    await this.getMe();
  }

  public created() {
    // set initial value
    this.$reactiveValueObservable<FullDocumentRevisionObject>('documentDetail')
      .pipe(
        // @ts-ignore
        filter(Boolean),
        map(
          (documentDetail: FullDocumentRevisionObject) =>
            documentDetail?.document?.assignee
        ),
        filter(
          (assignee: BackendUserEntity) => typeof assignee !== 'undefined'
        ),
        take(1)
      )
      .subscribe((assignee) => {
        this.selectedUser = assignee
          ? {
              id: assignee.id,
              email: assignee.email,
              fullName: getFullNameFromUser(assignee)
            }
          : null;
        this.inputFieldValue = assignee ? getFullNameFromUser(assignee) : '';
      });

    // handle when to updateDocAssignee
    const selectedUser$: Observable<User | null> = this.$watchAsObservable(
      'selectedUser'
    ).pipe(pluckNewValue());

    combineLatest(selectedUser$)
      .pipe(
        // only trigger when it's changed
        filter(([selectedUser]) => {
          const selectedUserId = Number(_get(selectedUser, 'id'));
          const assigneeId = Number(this.assigneeId);
          return selectedUserId !== assigneeId;
        }),
        map(([selectedUser]) => selectedUser),
        map((selectedUser) => Number(_get(selectedUser, 'id'))),
        distinctUntilChanged((x, y) => {
          if (isNaN(x) && isNaN(y)) {
            return true;
          }
          return x === y;
        }),
        mergeMap((selectedUserId) => {
          return this.updateDocAssignee({
            assigneeId: selectedUserId || null,
            documentId: this.documentId
          });
        }),
        map((d) => d.assignee)
      )
      .subscribe((assignee) => null);
  }

  @isDifferent
  @isTruthy
  @Watch('updateDocAssigneeSuccess')
  public onUpdateSuccess() {
    const message = this.selectedUser
      ? this.iAmAssigned
        ? `Assigned to Me Successfully`
        : `Assigned to "${this.selectedUser.fullName}" Successfully`
      : 'Assignee Removed Successfully';
    Toast.open({
      message,
      position: 'is-top',
      type: 'is-dark'
    });
  }

  @isDifferent
  @isTruthy
  @Watch('updateDocAssigneeError')
  public onUpdateError() {
    const message = this.selectedUser
      ? `Failed to Assigned to "${this.selectedUser.fullName}". Please try again.`
      : 'Fail to Remove Assignee. Please try again.';
    Toast.open({
      message,
      position: 'is-top',
      type: 'is-danger'
    });
  }
}
