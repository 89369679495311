import { get as _get } from 'lodash';
import Op from 'quill-delta/dist/Op';

export class Author {
  public title: string;
  public firstName: string;
  public lastName: string;
  public qualification: string;

  constructor(params?: Author) {
    this.title = _get(params, 'title') || '';
    this.firstName = _get(params, 'firstName') || '';
    this.lastName = _get(params, 'lastName') || '';
    this.qualification = _get(params, 'qualification') || '';
  }
}

export interface StagingAuthorData {
  isValid?: boolean;
  documentSectionId?: number;
  authorSubSectionId?: number;
  content: Author | PendingDocumentRawAuthorData;
  tempId?: string;
}

export interface PendingDocumentRawAuthorData {
  raw: Op[];
}
